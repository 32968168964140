import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { getAllFirmwareList } from "service/gatewayService";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import { faker } from "@faker-js/faker";
import { isHttpSuccess } from "utils/functions";

const UpdateTabVersionSelected = ({
    defaultVersion,
    curVersion,
    setVersion,
}: any) => {
    const [firmwareList, setFirmwareList] = useState<any>([]);
    const [modalVersion, setModalVersion] = useState("");
    const [modalDetails, setModalDetails] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [logDetailModalShow, setLogDetailModalShow] = useState(false);

    useEffect(() => {
        (async () => {
            const firmwareResponse = await getAllFirmwareList();
            if (isHttpSuccess(firmwareResponse.status)) {
                setFirmwareList(firmwareResponse.data.firmware);
            }
            setIsLoading(false);
        })();

        return () => {
            setFirmwareList([]);
            setIsLoading(false);
        }
    }, []);

    const showLog = (version: string, description: any) => {
        setModalVersion(version);
        setModalDetails(description);
        setLogDetailModalShow(true);
    };

    const renderModalDetails = () => {
        if (modalDetails) {
            const lst = modalDetails.map((i: any) => {
                return (
                    <React.Fragment key={faker.datatype.uuid()}>
                        <p className="mb-1">{i}</p>
                    </React.Fragment>
                );
            });
            return lst;
        }
    };

    const closeLogModal = () => {
        setLogDetailModalShow(false);
    };

    return (
        <ContentWrapper isLoading={isLoading}>
            <div className="scrollingContainer">
                {firmwareList
                    .filter((i: any) => i.version !== defaultVersion)
                    .map((i: any) => {
                        return (
                            <React.Fragment key={i.version}>
                                <div className="update-option">
                                    <Form.Check
                                        name="version"
                                        custom
                                        type="radio"
                                        id={`${i.version}`}
                                        label={`V ${i.version}`}
                                        checked={curVersion === i.version}
                                        className="float-left"
                                        onChange={(e) => {
                                            setVersion(e.target.id);
                                        }}
                                    />
                                    <Button
                                        variant="light float-right"
                                        onClick={() => {
                                            showLog(i.version, i.description);
                                        }}
                                    >
                                        Learn More
                                    </Button>
                                </div>
                            </React.Fragment>
                        );
                    })}
            </div>

            <Modal
                centered
                show={logDetailModalShow}
                onHide={closeLogModal}
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        V {modalVersion} Log
                    </Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={closeLogModal}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Modal.Header>
                <Modal.Body className="mt-1 mb-3">
                    {renderModalDetails()}
                </Modal.Body>
            </Modal>
        </ContentWrapper>
    );
};

export default UpdateTabVersionSelected;
