import { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import PermissionList, {
    DefaultPermissions,
} from "components/organizations/PermissionList";
import RolesService from "service/rolesService";
import { AppContext } from "context/appContext";
import {
    DefaultMaxCharacter,
    DefaultMinCharacter,
    HttpStatus,
    Patterns,
} from "constant";
import OrganisationService from "service/organisationService";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import { isHttpSuccess, isValidText } from "utils/functions";

const NewRole = () => {
    const { storeData } = useContext(AppContext);
    const history = useHistory();
    const [selectedPermissions, setSelectedPermissions] =
        useState(DefaultPermissions);
    const [roleName, setRoleName] = useState("");
    const { organization } = storeData;
    const { currentOrgId } = organization;
    const params = useParams() as { [key: string]: any };
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);
    const [isForbiddenResource, updateIsForbiddenResource] = useState(false);
    const { orgId } = params;

    useEffect(() => {
        if (orgId) {
            const fetchOrg = async () => {
                updateIsForbiddenResource(false);
                const res: any = await OrganisationService.getOrgById(orgId);

                if (res.status === HttpStatus.FORBIDDEN) {
                    updateIsForbiddenResource(true);
                }
            };
            fetchOrg();
        }

        return () => { updateIsForbiddenResource(false) }
    }, [orgId]);

    /**
     * Handle permission change
     * @param { object } data:  The permissions of object
     * @return { void }
     */
    const handlePermissionsChange = (data: any) => {
        setSelectedPermissions(data);
    };

    /**
     * Handle change role name
     * @param { object } e - input event object
     * @return { void }
     */
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRoleName(e.target.value);
    };

    /**
     * Handle submit
     * @return { void }
     */
    const handleSave = async () => {
        if (
            !(
                roleName.length >= DefaultMinCharacter &&
                roleName.length <= DefaultMaxCharacter
            )
        ) {
            setSimpleModalData({
                type: "error",
                body: "Role name must be between 1-32 characters.",
            } as SimpleModalDataType);
            return;
        }

        const body = {
            name: roleName,
            permissions: selectedPermissions,
        };
        const roleRes: any = await RolesService.createNewRole(orgId, body);

        if (isHttpSuccess(roleRes.status)) {
            setSimpleModalData({
                resObj: roleRes,
                body: "New role has been created successfully.",
            } as SimpleModalDataType);

            setRoleName("");
            setSelectedPermissions(DefaultPermissions);
            history.push(`/organisation/${organization.currentOrgId}`);
        } else {
            setSimpleModalData({
                resObj: roleRes,
            } as SimpleModalDataType);
        }
    };

    /**
     * Handle cancel create
     * @return { void }
     */
    const handleCancel = () => {
        history.push(`/organisation/${organization.currentOrgId}`);
    };

    return (
        <ContentWrapper
            simpleModalData={simpleModalData}
            isForbiddenResource={isForbiddenResource}
        >
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm="12" className="role-details-header">
                            <h5 className="page-title">New Role</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item
                                    href={`/organisation/${currentOrgId}`}
                                >
                                    Organisation
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    New Role
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <div>
                                <div className="form-box mb-4">
                                    <Form.Group>
                                        <Form.Label>Role Name</Form.Label>
                                        <Form.Control
                                            autoFocus
                                            type="text"
                                            required
                                            value={roleName}
                                            onChange={handleNameChange}
                                            aria-label="role-name"
                                            isInvalid={
                                                !isValidText(
                                                    "roleName",
                                                    { roleName },
                                                    { roleName: "" },
                                                    Patterns.orgNamePattern
                                                )
                                            }
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Role must be between{" "}
                                            {DefaultMinCharacter}-
                                            {DefaultMaxCharacter} characters
                                            long, and not contain leading and
                                            trailing space.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>

                                <h5 className="w-100 mb-3">Permissions</h5>

                                <PermissionList
                                    values={selectedPermissions}
                                    onChange={handlePermissionsChange}
                                />
                            </div>
                            <Button
                                variant="secondary"
                                className="mt-4 mr-2"
                                onClick={handleCancel}
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="primary"
                                className="mt-4"
                                onClick={handleSave}
                            >
                                SAVE
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ContentWrapper>
    );
};

export default NewRole;
