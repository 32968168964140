import * as crypto from "crypto-js";
import { JWT_SECRET_KEY } from "./config";
import LocalStorageService from "service/localStorageService";
import { getWallet } from "service/subscriptionService";
import { ResponseDto, DashboardTagOptions } from "types";
import moment from "moment";
import _ from "lodash";
import {
    DefaultMinCharacter,
    DefaultMaxCharacter,
    ServerResReplaceDict,
    HttpStatus,
} from "constant";
import { AxiosError, AxiosResponse } from "axios";
import { Dashboard } from "generated/models/Dashboard";

const base64Encode = function (str: string) {
    return window.btoa(str);
};

const urlEncode = function (str: string) {
    return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/\+=$/, "");
};

export const jwtEncode = function (deviceToken: any, service: any) {
    // get time
    // https://www.epochconverter.com/
    const iat = Math.floor(Date.now() / 1000); // epoch time in seconds
    const exp = iat + 10; // plus 10 seconds

    // get JWT header
    const headerData = JSON.stringify({
        alg: "HS256",
        typ: "JWT",
    });

    // get JWT payload
    const payloadData = JSON.stringify({
        username: deviceToken,
        password: service,
        iat: iat,
        exp: exp,
    });

    // get JWT = header.payload.signature
    // https://jwt.io/
    const secret: any = JWT_SECRET_KEY;
    const header = urlEncode(base64Encode(headerData));
    const payload = urlEncode(base64Encode(payloadData));
    const signature = urlEncode(
        crypto.enc.Base64.stringify(
            crypto.HmacSHA256(header + "." + payload, secret)
        )
    );
    const jwt = header + "." + payload + "." + signature;
    return jwt;
};

export const generateUUID = () => {
    let dt = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
            const r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
    );
    return uuid;
};

export const orgIdQueryString = () => {
    return `?organization_id=${orgId()}`;
};

export const orgIdQuery = () => {
    return {
        organization_id: orgId(),
    };
};

export const orgId = () => {
    return LocalStorageService.getItem("orgId");
};

export function createResponseStandard<T = any>(
    res: AxiosResponse
): ResponseDto<T> {
    return {
        status: res.status,
        data: res.data.data || res.data,
        message: res?.data?.message,
        total: res?.data?.total,
    };
}

export function createErrorResponseStandard<T = any>(
    error: AxiosError | any
): ResponseDto<T> {
    return {
        status: error.response
            ? error.response.status
            : HttpStatus.INTERNAL_SERVER_ERROR,
        data: error.response ? error.response.data : null,
        error,
        message:
            _.get(error, "response.data.message") ??
            _.get(error, "response.data.description", null),
    };
}

/**
 * Create status for created property
 * @param { number } dateNumber - Date in number
 * @param { string } dateFormat - Date format (default: "DD/MM/YYYY")
 * @returns { string }
 */
export const formatDate = (
    dateNumber: number | string | Date,
    dateFormat = "DD/MM/YYYY"
): string => {
    if (!dateNumber) return "-";
    return moment
        .utc(dateNumber)
        .utcOffset(moment().utcOffset())
        .format(dateFormat);
};

/**
 * Generate data count number
 * @param {number} number - Length of Data
 * @returns {string}
 */
export const generateCountNumber = (number: number) => {
    if (number < 10) return `0${number}`;
    return number;
};

export const dbUUID = () => {
    return LocalStorageService.getItem("currDbUUID");
};

export const tagOptions = (dashboards: Dashboard[]): DashboardTagOptions[] => {
    return dashboards.map(({ uuid, name, icon_color }: Dashboard) => ({
        key: uuid,
        text: name,
        value: name,
        label: {
            color: icon_color,
            empty: false,
            circular: true,
        },
    }));
};

export const getAllTokens = () => {
    let total = 0;
    const fetchWallet = async () => {
        const response: any = await getWallet();
        if (response.status === 200) {
            const { tokens: wallet } = response.data.data;
            for (const token of wallet) {
                total += token.amount;
            }
            return total;
        } else {
            return total;
        }
    };
    return fetchWallet();
};

/**
 * Convert number of minutes to hours for gateway charts
 * @param { any } timeValue  - Number of minutes
 * @returns { string } - In two decimal place.
 */
export const convertMinutesToHours = (timeValue: any) => {
    let hours: any = Number(timeValue) / 60;
    if (Number(hours) % 1 !== 0) {
        hours = hours.toFixed(2);
    }
    return String(hours);
};

/**
 * Convert chart data with >= 1 dp to 2dp
 * @param array - Array of chart data
 * @returns {Array} - Array of 2dp chart data
 */
export const convertChartToTwoDecimal = (array: any = []) => {
    const updatedArr = array.map((a: any) => {
        if (Number(a[1]) % 1 === 0) {
            return [a[0], a[1]];
        } else {
            return [a[0], Number(a[1]).toFixed(2).toString()];
        }
    });

    return updatedArr;
};

export const formatUnitWithSymbol = (unit: any) => {
    if (unit === "C") {
        return `°C`;
    }
    return unit;
};

export const renderCountdown = (countdown: any) => {
    const mins = Math.floor(countdown / 60);
    const secs = countdown % 60;

    return `${mins < 10 ? "0" : ""}${mins}:${secs < 10 ? "0" : ""}${secs}`;
};

export const calculateLastDuration = (
    lastTimeValue: any,
    lastTimeSelection: any
) => {
    if (typeof lastTimeValue === "number") {
        return lastTimeValue * 1000;
    } else {
        const { hours }: any = lastTimeSelection.find(
            (t: any) => t.time === lastTimeValue
        );
        return Number(moment().format("x")) - hours * 60 * 60 * 1000;
    }
};

export const combineResultArrays = (res: any) => {
    const combinedValues = res.data.data.result.reduce(
        (acc: any, curr: any) => {
            acc.push(...curr.values);
            return acc;
        },
        []
    );

    combinedValues.sort((a: any, b: any) => {
        return a[0] - b[0];
    });

    const uniqueCombinedValues: any = [];
    const timestamps: any = [];
    for (const value of combinedValues) {
        if (!timestamps.includes(value[0])) {
            timestamps.push(value[0]);
            uniqueCombinedValues.push([value[0], value[1]]);
        }
    }

    _.set(res, "data.data.result[0].values", uniqueCombinedValues);
};

export const getPrimaryUnit = (appSensor: any) => {
    for (const unit of appSensor?.UNITS || []) {
        if (unit === "BINARY") return "";
        if (appSensor?.[unit]?.["PRI"] === "TRUE") {
            return appSensor[unit]["SYMBOL"];
        }
    }
};
export const getPrimaryUnitKey = (appSensor: any) => {
    for (const unit of appSensor.UNITS || []) {
        if (appSensor[unit]["PRI"] === "TRUE") {
            return unit.toLowerCase();
        }
    }
};

export const getUnitSymbolArray = (appSensor: any) => {
    return appSensor.UNITS.reduce((acc: any, unitName: string) => {
        if (["BINARY", "COMPASS"].includes(unitName)) {
            acc.push("");
        } else {
            acc.push(appSensor[unitName].SYMBOL);
        }
        return acc;
    }, []);
};

export const containsWhitespace = (str: string) => {
    return /\s/.test(str);
};

export const addDecimalPlace = (data: any, accuracy: any) => {
    return parseFloat(data).toFixed(parseInt(accuracy));
};

export const inputNumberWithMinMax = (event: any) => {
    const max = Number(event?.target?.max || Number.MAX_SAFE_INTEGER);
    const min = Number(event?.target?.min || -Number.MAX_SAFE_INTEGER);

    if (parseFloat(String(event.target.value + event.key)) > max) {
        event.target.value = max;
    }

    if (parseFloat(String(event.target.value + event.key)) < min) {
        event.target.value = min;
    }
};

export const getDaysInMonths = (month: any) => {
    const date = new Date();
    const currentYear = date.getFullYear();
    return new Date(currentYear, month, 0).getDate();
};

export const getPrimaryUnitName = (appSensor: any) => {
    if (!appSensor?.UNITS) return "";
    for (const unit of appSensor?.UNITS || []) {
        if (appSensor?.[unit]?.["PRI"] === "TRUE") {
            return appSensor[unit];
        }
    }
    return "";
};
declare global {
    interface String {
        fill: (data: { [key: string]: any }) => string;
        capitalize: () => string;
        isNumeric: () => boolean;
        toNumFixedDown(digits: number): string;
        toHex: () => string;
    }

    interface Number {
        toMoment(): moment.Moment;
    }

    interface Array<T> {
        pluck: (key: string, fields?: string[]) => { [key: string]: T };
    }

    var getId: () => string;

    var objectFluent: (obj: any, data: any) => any;

    interface HTMLFormElement {
        toData: <T = {}>() => T;
    }
}

// eslint-disable-next-line no-extend-native
String.prototype.fill = function (data) {
    // @ts-ignore
    const str = this.replace(/\{([a-zA-Z0-9_]+)\}/g, function (matched) {
        const key = matched.replace("{", "").replace("}", "");
        return _.get(data, key, matched);
    });

    return String(str);
};

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
    return (
        this.toLowerCase().charAt(0).toUpperCase() + this.toLowerCase().slice(1)
    );
};

// eslint-disable-next-line no-extend-native
Array.prototype.pluck = function (key: string, fields?: string[]) {
    return this.reduce((acc: any, curr: any) => {
        if (fields) {
            if (fields.includes(curr[key])) {
                acc[curr[key]] = curr;
            }
        } else {
            acc[curr[key]] = curr;
        }
        return acc;
    }, {});
};

global.getId = () =>
    (Math.random() + 1).toString(32).substring(8) +
    Date.now().toString(32).substring(5);

global.objectFluent = (obj: any, data: any) => {
    for (let key in obj) {
        if (typeof obj[key] === "function") {
            obj[key] = obj[key](data[key], data);
        } else {
            obj[key] = data[key] ?? obj[key];
        }
    }

    return obj;
};

// eslint-disable-next-line no-extend-native
String.prototype.isNumeric = function () {
    const str = this;
    if (typeof str != "string") return false;
    return !isNaN(Number(str)) && !isNaN(parseFloat(str));
};

declare module "moment" {
    interface Moment {
        toSeconds(): number;
    }
}

// eslint-disable-next-line no-extend-native
Number.prototype.toMoment = function () {
    return moment(
        `${Math.floor(Number(this) / 3600)}:${Math.floor(
            (Number(this) % 3600) / 60
        )}:${(Number(this) % 3600) % 60}`,
        "HH:mm:ss"
    );
};

// https://stackoverflow.com/a/4912870/7746224
// eslint-disable-next-line no-extend-native
String.prototype.toNumFixedDown = function (digits = 0) {
    const str = this;
    const re = new RegExp("([-]?\\d+\\.\\d{" + digits + "})(\\d)"),
        m = str.toString().match(re);
    return (m ? m[1] : str.valueOf()).replace(/^0+\B/g, "");
};

// eslint-disable-next-line no-extend-native
moment.prototype.toSeconds = function () {
    return this.hours() * 60 * 60 + this.minutes() * 60 + this.seconds();
};

export const humanReadableFileSize = (bytes: any, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }

    const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
};

export const getFirstSubscriptionInfo = (subscriptionList: any) => {
    const firstSub = _.orderBy(subscriptionList, ["create_time"], ["asc"])[0];
    if (firstSub) {
        const firstSubDate: any = new Date(firstSub.create_time);
        return {
            startMonth: firstSubDate.getMonth() + 1,
            startYear: firstSubDate.getFullYear(),
        };
    }
    return {
        startMonth: 1,
        startYear: moment().year(),
    };
};

export const calcPercentDiff = (curr: number, prev: number) => {
    if (curr === 0 && prev === 0) return 0;
    if (prev === 0) return 100;
    return ((curr - prev) / prev) * 100;
};

export const isValidValueInForm = (
    path: string,
    data: any,
    defaultData: any,
    regex?: any
) => {
    const text = _.get(data, path, "");
    const defaultValue = _.get(defaultData, path, "");
    const textTrim = text.trim();
    const textLength = text.length;

    return (text && _.isEqual(defaultValue, text)) || regex
        ? regex.test(textTrim)
        : textLength >= DefaultMinCharacter &&
              textLength <= DefaultMaxCharacter;
};

export const checkIfReachable = (
    lastTimestamp: any,
    reportingIntvl: any = 5
) => {
    // If checking GW, reporting intvl is 60
    // If checking LDSU, use reporting intvl from config API, by default is 5

    const now = moment().unix();
    const n = 1.5;
    const offset = 30;

    if (now - lastTimestamp <= reportingIntvl * n + offset) {
        return true;
    }
    return false;
};

export const convertServerParam = (serverParam: string) => {
    if (ServerResReplaceDict[serverParam]) {
        return ServerResReplaceDict[serverParam];
    }
    return serverParam;
};

export const isSensor = (CLS: number) => CLS < 32768;
export const isActuator = (CLS: number) => CLS >= 32768;

// if CLS < sensor range => sensor
// if CLS > sensor range => actuator
// Current actuator are all 32768
export const clsToAttributeTable = () => {
    let clsNameObj: any = {};
    for (const [key, value] of Object.entries(
        LocalStorageService.getItem("metricInfo").cls
    )) {
        clsNameObj[key] = (value as any).name;
    }
    return clsNameObj;
};

export const appToAttributeTable = () => {
    let appNameObj: any = {};
    for (const [key, value] of Object.entries(
        LocalStorageService.getItem("metricInfo").app
    )) {
        appNameObj[key] = (value as any).name;
    }
    return appNameObj;
};

// eslint-disable-next-line no-extend-native
HTMLFormElement.prototype.toData = function () {
    const data: any = {};
    const formData = new FormData(this);
    formData.forEach((value, key) => {
        data[key] = value;
    });

    return data;
};

// eslint-disable-next-line no-extend-native
String.prototype.toHex = function () {
    var hex, i;

    var result = "";
    for (i = 0; i < this.length; i++) {
        hex = this.charCodeAt(i).toString(16);
        result += ("000" + hex).slice(-4);
    }

    return result;
};

export const isHttpSuccess = (status: number) => status >= 200 && status < 300;

export const parseColorToHex = (color: string) => {
    let hexColor = color;
    if (color?.length === 9) {
        const a = color.substring(1, 3);
        hexColor = `#${color.substring(3)}${a}`;
    }

    return hexColor;
};
export const isValidText = (
    path: string,
    data = {},
    defaultData = {},
    regex?: RegExp
) => isValidValueInForm(path, data, defaultData, regex);

export const padTimeFormat = (num: string) => {
    return (Number(num) < 10 ? "0" : "") + Number(num);
};

export const displaySensorType = (sensor: any) => {
    let name = sensor?.sensor_type;
    if (sensor?.APP) {
        name = sensor.APP[sensor.SAID].NAME;
    }
    return name ?? "-";
};
