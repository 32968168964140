import { TERMS_OF_SERVICE_LINK } from "constant";
import { Container, Row, Col } from "react-bootstrap";

const About = () => {
    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <h5 className="page-title">About</h5>
                    <Col sm="12" className="mt-2">
                        <div className="empty-widget p-2">
                            <Row>
                                <Col sm={12}></Col>
                            </Row>
                            <Col sm="12">
                                <a
                                    className="mt-2 mb-2 btn btn-primary"
                                    href={TERMS_OF_SERVICE_LINK}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Terms of Service
                                </a>
                            </Col>
                            <Col sm="12">
                                <a
                                    className="mt-2 mb-2 btn btn-primary"
                                    href="https://brtsys.com/privacy-policy-2/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy Policy
                                </a>
                            </Col>
                            <Col sm="12">
                                <a
                                    className="mt-2 mb-2 btn btn-primary"
                                    href="https://brtsys.com/software-license-agreement/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Licenses
                                </a>
                            </Col>
                            <Col sm="12">
                                <h6>Version: 2.0.0_3.0.7</h6>
                            </Col>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default About;
