import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import * as _ from "lodash";
import {
    useCreateDashboardStore,
    CreateChartState,
    CHART_NAME_PATTERN,
    CHART_ERROR_MESSAGE,
} from ".";
import CreateChartModal from "./modal/CreateChart";
import useOrganization from "hooks/organization";

export enum OtherChartFor {
    Notification = "Count of emails, SMS, and notifications sent",
    Event = "Count of Events enabled vs triggered",
}

export const OtherChartFields = [
    {
        checked: {
            name: "Notification.checked",
            label: OtherChartFor.Notification,
            value: "notification",
        },
        input: {
            name: "Notification.name",
            label: "",
            value: "Count of Email, SMS, Notification",
        },
    },
    {
        checked: {
            name: "Event.checked",
            label: OtherChartFor.Event,
            value: "event",
        },
        input: {
            name: "Event.name",
            label: "",
            value: "Count of Events",
        },
    },
];

const OtherChartCreateData: CreateChartState = {
    title: "Creating Charts...",
    data: [],
    columns: [
        {
            key: "name",
            name: "Chart Name",
        },
        {
            key: "chart_for",
            name: "Type",
        },
    ],
    transform: (data: any) => ({
        attributes: [
            {
                organization_id: useOrganization.getState().orgId,
                type_:
                    data.chart_for === OtherChartFor.Notification
                        ? "COUNT_ALERT"
                        : "COUNT_ALERT_TRIGGER",
            },
        ],
        chart: "",
        name: data.name,
        source: "OTHER",
    }),
    validate() {
        return this.data.map((item) => ({
            path: "",
            isValid: CHART_NAME_PATTERN.test(item.name.trim()),
            message: CHART_ERROR_MESSAGE,
        }));
    },
};

const DashboardCreateOtherComponent: React.FunctionComponent = () => {
    const [formValues, setFormValues] = React.useState<{
        [key: string]: any;
    }>({
        "Notification.checked": true,
        "Notification.name": OtherChartFields[0].input.value,
        "Event.checked": true,
        "Event.name": OtherChartFields[1].input.value,
    });
    const { createModalShow, setCreateModalShow, createData } =
        useCreateDashboardStore();

    React.useEffect(() => {
        const createData: any[] = [];
        setCreateModalShow(false);

        const formValuesObject = {} as any;

        for (const key in formValues) {
            _.set(formValuesObject, String(key), formValues[key]);
        }

        for (const chart_for in formValuesObject) {
            const chartForValue = formValuesObject[chart_for];
            if (chartForValue.checked) {
                createData.push({
                    name: chartForValue.name,
                    chart_for:
                        OtherChartFor[chart_for as keyof typeof OtherChartFor],
                });
            }
        }

        useCreateDashboardStore.setState({
            createData: {
                ...OtherChartCreateData,
                data: createData,
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues]);

    return (
        <React.Fragment>
            <Row>
                <Col sm="12">
                    <p>Other chart options</p>
                </Col>
            </Row>

            <Row className="mt-4 cstm-table">
                <Col sm={12}>
                    <Form id="chart-edit-form">
                        <div className="table-head" key="head">
                            <Row className="no-checkbox">
                                <Col sm={4}>Chart Options</Col>
                                <Col sm={6}>Chart Name</Col>
                            </Row>
                        </div>
                        {OtherChartFields.map((row, index) => (
                            <div className="table-row" key={index}>
                                <Row className="no-checkbox align-items-center">
                                    <Col sm={4}>
                                        <Form.Check
                                            type="checkbox"
                                            id={row.checked.name}
                                            label={row.checked.label}
                                            checked={
                                                formValues[row.checked.name] ??
                                                true
                                            }
                                            onChange={(e) => {
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    [row.checked.name]:
                                                        e.target.checked,
                                                }));
                                            }}
                                            custom
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Form.Control
                                            type="text"
                                            name={row.input.name}
                                            value={
                                                formValues?.[row.input.name] ??
                                                row.input.value
                                            }
                                            onChange={(e) => {
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    [row.input.name]:
                                                        e.target.value,
                                                }));
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </Form>
                </Col>
            </Row>
            <CreateChartModal
                show={createModalShow && !_.isEmpty(createData)}
                onHide={() => setCreateModalShow(false)}
            />
        </React.Fragment>
    );
};

export default React.memo(DashboardCreateOtherComponent);
