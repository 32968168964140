import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import SelectRoleModal from "components/modals/SelectRoleModal/SelectRoleModal";
import OrganisationService from "service/organisationService";
import UserGroupsService from "service/userGroupsService";
import { useAppContext } from "context/appContext";
import { storeOrgRolesAction } from "store/actions";
import { HttpStatus, ORG_GROUP_NAME_MESSAGE, Patterns } from "constant";
import "assets/css/CreateGroup.css";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import { isHttpSuccess, isValidText } from "utils/functions";
import { RoleDto } from "types";
import { get, keyBy } from "lodash";
import { showErrorAlert, showSuccessAlert } from "utils/alert";

const NewGroup = () => {
    const history = useHistory();
    const params: any = useParams();
    const { storeData, storeDispatchActions } = useAppContext();
    const [groupName, setGroupName] = useState("");
    const [roleIds, setRoleIds] = useState<string[]>([]);
    const [roles, setRoles] = useState<RoleDto[]>([]);
    const [showRolesModal, setShowRolesModal] = useState(false);
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    const [isValidName, setIsValidName] = useState(false);

    const { organization } = storeData;
    const { currentOrgRoles, currentOrgId } = organization;
    const { orgId } = params;

    useEffect(() => {
        if (currentOrgRoles.length === 0 && orgId) {
            OrganisationService.getOrgRoles(orgId).then(
                ({ status, data = [] }) => {
                    if (status === HttpStatus.OK) {
                        const roles = (
                            data as Array<any>
                        ).toCamelCase<RoleDto>();
                        storeDispatchActions(
                            storeOrgRolesAction({
                                orgRoles: roles,
                            })
                        );
                        setRoles(roles.filter((i) => !i.isDefault));
                    } else if (status === HttpStatus.FORBIDDEN) {
                        setIsForbiddenResource(true);
                    }
                }
            );
        }
    }, [orgId, storeDispatchActions, currentOrgRoles]);

    useEffect(() => {
        if (currentOrgRoles.length > 0) {
            const filteredRoles = currentOrgRoles.filter((i) => !i.isDefault);
            setRoles(filteredRoles);
        }
    }, [currentOrgRoles]);

    const handleNameChange = (e: any) => {
        setGroupName(e.target.value);
        setIsValidName(
            isValidText(
                "name",
                { name: e.target.value },
                { name: "" },
                Patterns.orgNamePattern
            )
        );
    };

    const handleCloseSelectRoleModal = (data?: string[]) => {
        setShowRolesModal(false);
        data && setRoleIds(data);
    };

    const handleRemoveRole = (id: string) => {
        const ids = roleIds.filter((roleId) => roleId !== id);
        setRoleIds(ids);
    };

    const handleCreateGroup = async () => {
        const newGroupRes = await UserGroupsService.createNewGroup(
            orgId,
            groupName
        );

        let message = "";

        if (isHttpSuccess(newGroupRes.status)) {
            showSuccessAlert({
                message: "New Group created successfully.",
            });
            setGroupName("");

            if (roleIds.length === 0) {
                return history.push(
                    `/organisation/${organization.currentOrgId}`
                );
            }
            const assignRolesRes = await UserGroupsService.addRoles(
                orgId,
                newGroupRes.data.uuid,
                roleIds
            );

            if (isHttpSuccess(assignRolesRes.status)) {
                showSuccessAlert({
                    message: "The Role(s) has been attached successfully.",
                });

                history.push(`/organisation/${organization.currentOrgId}`);
            } else {
                message = get(
                    assignRolesRes,
                    "data.description",
                    "Unable to attach role(s), Please try again."
                );
                showErrorAlert({
                    message,
                });
            }
        } else {
            message = get(
                newGroupRes,
                "data.description",
                "Unable to create Member group, Please try again."
            );
            showErrorAlert({
                message,
            });
        }
    };

    const handleCancel = () => {
        history.push(`/organisation/${organization.currentOrgId}`);
    };

    const renderSelectedRoles = () => {
        const roleInObj: any = keyBy(roles, "uuid");

        return roleIds.map((roleId: string) => (
            <Row
                key={roleId}
                className="pl-4 pr-2 py-2 mb-2 align-items-center"
            >
                <Col md={{ order: 1 }} xs={{ span: 10, order: 1 }}>
                    {roleInObj[roleId].name}
                </Col>
                <Col
                    md={{ order: 2 }}
                    xs={{ span: 10, order: 3 }}
                    className="role-type"
                >
                    {roleInObj[roleId].isDefault
                        ? "Default Role"
                        : "Custom Role"}
                </Col>
                <Col
                    md={{ span: 1, order: 3 }}
                    xs={{ span: 2, order: 2 }}
                    className=""
                >
                    <Button
                        variant=""
                        className="remove-role-button float-right"
                        onClick={() => handleRemoveRole(roleId)}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Col>
            </Row>
        ));
    };

    return (
        <ContentWrapper isForbiddenResource={isForbiddenResource}>
            <div className="page-content">
                <Container>
                    <Row>
                        <Col sm="12" className="group-detail-head">
                            <h5 className="page-title">New Group</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item
                                    href={`/organisation/${currentOrgId}`}
                                >
                                    Organisation
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    New Group
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <div>
                                <div className="form-box mb-4">
                                    <Form.Group>
                                        <Form.Label>Group Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            value={groupName}
                                            onChange={handleNameChange}
                                            aria-label="group-name"
                                            isInvalid={!isValidName}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {ORG_GROUP_NAME_MESSAGE}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <h5 className="w-100 mb-3">Roles</h5>
                                <div className="form-box">
                                    <Button
                                        variant="primary"
                                        onClick={() => setShowRolesModal(true)}
                                    >
                                        + ADD ROLES
                                    </Button>

                                    <div className="selected-roles mt-3">
                                        {renderSelectedRoles()}
                                    </div>
                                </div>
                            </div>
                            <Button
                                variant="secondary"
                                className="mt-4 mr-2"
                                onClick={handleCancel}
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="primary"
                                className="mt-4"
                                disabled={!isValidName}
                                onClick={handleCreateGroup}
                            >
                                SAVE
                            </Button>
                        </Col>
                    </Row>
                </Container>

                <SelectRoleModal
                    roles={roles}
                    values={roleIds}
                    show={showRolesModal}
                    canRemoveSelected
                    onClose={handleCloseSelectRoleModal}
                />
            </div>
        </ContentWrapper>
    );
};

export default NewGroup;
