import { Col, Form } from "react-bootstrap";
import FormDeviceValueControl from "components/form-control/FormDeviceValueControl";
import { useActuatorConfigurationContext } from "context/ActuatorConfigurationContext";
import { useEffect } from "react";

export const AnalogConfig = () => {
    const {
        editedActuator,
        editedActuator: { mode, activate_value, deactivate_value },
        setEditedActuator,
        actuator: { value },
        isIOControllerState: {
            unit,
            minmax,
            format,
            accuracy,
            isGenericAnalogOutput,
        },
    } = useActuatorConfigurationContext();

    useEffect(() => {
        setEditedActuator({
            ...editedActuator,
            mode: "ANALOG",
            activate_value: activate_value || 0,
            deactivate_value: deactivate_value || 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, setEditedActuator, activate_value, deactivate_value]);

    const renderAnalogConfig = () => {
        if (isGenericAnalogOutput) {
            return (
                <>
                    <Form.Row>
                        <Col lg={2}>
                            <Form.Label>Activation Voltage</Form.Label>
                        </Col>
                        <Col lg={10} xs={12} className="mb-2">
                            <Form.Group className="mb-0">
                                <FormDeviceValueControl
                                    value={activate_value}
                                    type="text"
                                    {...{
                                        format,
                                        accuracy,
                                        min: minmax[0],
                                        max: minmax[1],
                                    }}
                                    aria-label="activate-value"
                                    onChange={(e: any) => {
                                        setEditedActuator({
                                            ...editedActuator,
                                            activate_value: e.target.value,
                                        });
                                    }}
                                />
                                <div className="analog-config-unit">{unit}</div>
                            </Form.Group>
                            <Col
                                className="mt-2 text-primary-blue-4"
                                lg={12}
                            >{`Minimum is ${minmax[0]}${unit} and maximum is ${minmax[1]}${unit}.`}</Col>
                        </Col>
                    </Form.Row>{" "}
                    <Form.Row>
                        <Col lg={2}>
                            <Form.Label>Deactivation Voltage</Form.Label>
                        </Col>
                        <Col lg={10} xs={12} className="mb-2">
                            <Form.Group className="mb-0">
                                <FormDeviceValueControl
                                    value={deactivate_value}
                                    type="text"
                                    {...{
                                        format,
                                        accuracy,
                                        min: minmax[0],
                                        max: minmax[1],
                                    }}
                                    aria-label="deactivate-value"
                                    onChange={(e: any) => {
                                        setEditedActuator({
                                            ...editedActuator,
                                            deactivate_value: e.target.value,
                                        });
                                    }}
                                />
                                <div className="analog-config-unit">{unit}</div>
                            </Form.Group>
                            <Col
                                className="mt-2 text-primary-blue-4"
                                lg={12}
                            >{`Minimum is ${minmax[0]}${unit} and maximum is ${minmax[1]}${unit}.`}</Col>
                        </Col>
                    </Form.Row>
                </>
            );
        } else {
            return (
                <Form.Row>
                    <Col>
                        <Form.Label>Value</Form.Label>
                    </Col>
                    <Col lg={10} xs={12} className="mb-2">
                        <Form.Group className="mb-0">
                            <FormDeviceValueControl
                                value={value}
                                type="text"
                                {...{
                                    format,
                                    accuracy,
                                    min: minmax[0],
                                    max: minmax[1],
                                }}
                                aria-label="value"
                                onChange={(e: any) => {
                                    // Dimmer not supported now
                                }}
                            />
                            <div className="analog-config-unit">{unit}</div>
                        </Form.Group>
                        <Col
                            className="mt-2 text-primary-blue-4"
                            lg={12}
                        >{`Minimum is ${minmax[0]}${unit} and maximum is ${minmax[1]}${unit}.`}</Col>
                    </Col>
                </Form.Row>
            );
        }
    };

    return <>{renderAnalogConfig()}</>;
};

export default AnalogConfig;
