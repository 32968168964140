import * as dotenv from "dotenv";
import { resolve } from "path";
import { get } from "lodash"
dotenv.config();
let path;
switch (process.env.NODE_ENV) {
    case "test":
        path = resolve(`${__dirname}/../../.env.test`);
        break;
    case "production":
        path = `${__dirname}/../../.env.production`;
        break;
    default:
        path = resolve(__dirname, "../.env");
}
dotenv.config({ path: path });

export const JWT_SECRET_KEY = process.env.REACT_APP_JWT_SECRET_KEY;

export const DISABLE_MISSING_DATA = get(process.env,"REACT_APP_DISABLE_MISSING_DATA", "false") === "true";