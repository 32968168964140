import { useEffect, useState } from "react";
import { Form, FormControlProps } from "react-bootstrap";
import { styled } from "@mui/system";

const StyledContainer = styled(Form.Group)`
    margin-bottom: 0;
    width: ${(props) => (props.width ? props.width : "100%")};
`;

export interface FormDeviceValueControlProps extends FormControlProps {
    width?: string;
    format?: string;
    accuracy?: number;
    min?: number;
    max?: number;
}

function FormDeviceValueControl(props: FormDeviceValueControlProps) {
    const {
        /** format, */ accuracy = 0,
        min,
        max,
        onChange,
        value,
        width,
    } = props;
    const [errorMessage, setErrorMessage] = useState("");
    const placeholder =
        [undefined, ""].indexOf(min?.toString()) <= -1 ||
        [undefined, ""].indexOf(max?.toString()) <= -1
            ? `${min} .. ${max} [${accuracy}d.p]`
            : ``;
    useEffect(() => {
        getExpectedValue(value?.toString() || "");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [min, max, accuracy, value]);
    const getExpectedValue = (value: string): string | number | null => {
        if (["-", ""].indexOf(value) >= 0 || value.match(/\.$/)) {
            setErrorMessage(`Value must be number`);
        } else if (value.isNumeric()) {
            if (
                ((max === 0 || !!max) && Number(value) > max) ||
                ((min === 0 || !!min) && Number(value) < min)
            ) {
                setErrorMessage(placeholder);
            } else {
                setErrorMessage(``);
            }
            value = value.toNumFixedDown(accuracy);
        } else {
            return null;
        }
        return value;
    };
    return (
        <>
            <StyledContainer width={width}>
                <Form.Control
                    {...{
                        ...props,
                        placeholder,
                        onChange: (e: any) => {
                            const value = e.target.value.toString();
                            const newValue = getExpectedValue(value);
                            if (newValue === null) {
                                return e.preventDefault();
                            }
                            e.target.value = newValue;
                            return onChange?.(e);
                        },
                        isInvalid: !!errorMessage,
                    }}
                />
                <Form.Control.Feedback type="invalid">
                    {errorMessage}
                </Form.Control.Feedback>
            </StyledContainer>
        </>
    );
}

export default FormDeviceValueControl;
