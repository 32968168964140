export const ERROR_NOT_POSITIVE_INTEGER = "error_not_positive_integer";
export const ERROR_NOT_INTEGER = "error_not_integer";
export const ERROR_NO_INTERVAL = "error_no_interval";
export const ERROR_INVALID_DATE = "error_invalid_date";
export const ERROR_INVALID = "invalid";
export const ERROR_EMPTY = "error_empty";
export const ERROR_INCORRECT_VALUE = "error_incorrect_value";
export const ERROR_DUPLICATE = "duplicate";
export const ERROR_TIME_RANGE = "End time must be later than start time.";
export const ERROR_BEFORE_OR_AFTER =
    "'After' time must be later than 'Before' time.";

export const TRY_AGAIN_ERROR_MESSAGE = `Something went wrong when performing this action. Please try again.`;
export const ACTION_FAIL_MESSAGE =
    "Unable to perform this action. Please try again.";

export const OrgValidationMessage: { [key: string]: string } = {
    name: `Organisation Name must be between 
        {MinChar}-
        {MaxChar} characters
        long, and not contain leading and
        trailing space.`,
};

export const SensorDefaultMessage = {
    activationHeader: "Sensor alert activated",
    activationMessage: "Sensor alert activated message",
    deactivationHeader: "Sensor alert deactivated",
    deactivationMessage: "Sensor deactivation alert message",
};

export const ForbiddenErrorMessage =
    "Permission denied. You have no permission to see this page.";

export const PermissionDeniedMessage = "Permission Denied!";

export const ErrorEmptyDashboardMessage =
    "Dashboard must have a color and name must be between 1-64 characters.";

export const SCAN_REQUEST_MESSAGE = `Scan request sent. Please refresh the device list to reflect the updated list.`;

export const RESTART_REQUEST_MESSAGE = `Restart request sent. Please standby while the gateway restarts.`;

export const DASHBOARD_CHART_LIMIT_MESSAGE = `You are trying to add charts more than the maximum limit. The maximum number of charts that any dashboard can contain is 100. Please remove some charts before adding.`;

export const NEW_ACTION_MESSAGE = `New action has been added.`;

export const UPDATE_ACTION_MESSAGE = `The action has been updated.`;

export const PHONE_NUMBER_VALIDATION_MESSAGE = `Phone number should be in valid format, starting with "+" and followed by numbers only.`;

export const CHART_DUPLICATE_COLOR_MESSAGE = `Unable to add new chart. Chart color must all be unique.`;

export const GATEWAY_SCAN_MESSAGE =
    "Scan can be performed with or without a port power cycle. Scanning with a port power cycle will cause sensors and actuators to undergo a power cycle.";

export const PORT_POWERED_ON_SCAN_MESSAGE = GATEWAY_SCAN_MESSAGE;

export const PORT_POWERED_OFF_SCAN_MESSAGE = `Scan in a powered off port can be performed only with a port power cycle.`;

export const PORT_FAULTY_SCAN_MESSAGE = `Please ensure that the fault has been removed before proceeding. A port power cycle will be performed during scan.`;

export const TURN_PORT_POWER_OFF_MESSAGE = `This will cause sensors and actuators to lose power. By confirming, a request to turn off the LDSBus port will be sent to Gateway. Do you want to continue?`;

export const TURN_PORT_POWER_ON_MESSAGE = `By confirming, a request to turn on the LDSBus port will be sent to the Gateway. Do you want to continue?`;

export const TOGGLE_PORT_POWER_SUCCESS_MESSAGE = `The request was sent successfully. The change will take some time to process and would require refreshing to retrieve the updated power status.`;

export const OFFLOAD_REMINDER_MESSAGE = `Please make sure that gateway sensor data has been downloaded before deleting the gateway`;

export const FAILED_ACTION_MESSAGE =
    "Failed to perform action. Please try again.";

export const ROLE_NAME_MESSAGE = `Role must be between 1-32 characters long, and not contain leading and trailing space.`;

export const ORG_GROUP_NAME_MESSAGE = `Group name must be between 1-32 characters long, and not contain leading and trailing space.`;

export const GATEWAY_NAME_MESSAGE = `Please enter a valid name for gateway (min 1 char and max 32 chars, do not include special characters)`;

export const LDSU_NAME_MESSAGE = `LDSU Name must be between 1-32 characters long, and not contain leading and trailing space.`;

export const GATEWAY_GROUP_NAME_MESSAGE = `Please enter a valid name for gateway group (min 1 char and max 32 chars, do not include special characters)`;

export const DASHBOARD_NAME_MESSAGE =
    "Dashboard name must be between 1-64 characters long, and not contain leading and trailing space.";

export const CREATED_DASHBOARD_MESSAGE =
    "Dashboard has been created successfully.";

export const ACTUATOR_INVALID_MESSAGE =
    "Unable to save. T1 and T2 must be greater than 0 sec. And T1+T2 must not exceed 1h.";

export const NOT_FOUND_PHONE_NUMBER_MESSAGE =
    "Found a problem with mobile number";

export const SMS_RECIPIENT_MESSAGE =
    "Added members with registered mobile number will receive SMS";

export const EVENT_ACTION_NO_RECIPIENT_MESSAGE =
    "Please ensure there is at least one recipient.";

export const SIGN_UP_VERIFY_CODE_MESSAGE =
    "Invalid verification code provided, please try again.";

export const CHART_DELETE_SUCCESS_MESSAGE = `Chart has been deleted successfully.`;

export const ACTUATOR_NAME_MESSAGE = `Please enter a valid name for Actuator without special characters and ensure it is no longer than 32 characters.`;

export const ACTUATOR_NAME_SUCCESS_MESSAGE =
    "Actuator name has been updated successfully.";

export const ACTUATOR_CONFIG_ACTIVATION_SUCCESS_MESSAGE =
    "Actuator configuration has been {state} successfully.";

export const ACTUATOR_CONFIG_SUCCESS_MESSAGE =
    "Actuator configuration has been updated successfully.";

export const UPDATE_REPORT_RATE_SUCCESS_MESSAGE = `Sensor report rate has been updated successfully.`;

export const PASSWORD_ERROR_MESSAGE =
    "Password must be between 8 - 32 characters. Inclusive of an uppercase character, lowercase character, special character and number.";

export const SEND_VERIFY_CODE_SUCCESS_MESSAGE =
    "A new Verification Code has been sent!";

export const SUBSCRIPTION_CANCELLATION_CONFIRMATION_MESSAGE = `Your subscription will be cancelled immediately. You may continue to use the services until the end of your billing period on {billing_period_end_date}. Note that by cancelling, your existing purchased tokens will expire in the next billing cycle on {next_billing_date}.`;

export const DELETE_GATEWAY_SUCCESS_MESSAGE =
    "Device unregistered successfully.";

export const DELETE_LDSUS_SUCCESS_MESSAGE =
    "{ldsuNo} LDSU(s) are deleted successfully.";

export const DELETE_LDSU_SUCCESS_MESSAGE =
    "LDSU has been deleted successfully.";

export const SAME_PASSWORD_ERROR_MESSAGE = `New password is the same as the current password. Please enter a different new password.`;

export const ENTER_CURRENT_PASSWORD_MESSAGE = `Please enter current password.`;

export const ENTER_NEW_PASSWORD_MESSAGE = `Please enter new password.`;

export const CONFIRM_NEW_PASSWORD_MESSAGE = `Please confirm new password.`;

export const PASSWORD_DO_NOT_MATCH_ERROR_MESSAGE =
    "Password entered in 'New password' and 'Confirm password' fields do not match.";

export const FETCH_GATEWAY_FAIL_MESSAGE =
    "Unable to fetch gateways. Please try again.";

export const SCAN_GATEWAY_FAIL_MESSAGE =
    "Unable to scan. Please try again later.";
