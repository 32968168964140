import { Form, Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopTimePicker from "@mui/lab/DesktopTimePicker";
import TextField from "@mui/material/TextField";
import moment from "moment";

type SetDelayModalProps = {
    delay?: number | string;
    isOpen?: boolean;
    onClose?: () => void;
    onSave?: (data: string | number | undefined) => void;
    onError?: (error: string) => void;
    [key: string]: any;
};

const delayFormat = "HH:mm:ss";
const [minSecond, maxSecond] = [1, 3600];

export const defaultDelayValue = 1;
export const timeToSecond = (time: moment.Moment) =>
    moment.duration(time.format(delayFormat)).asSeconds();
export const secondToTime = (time: number) =>
    moment(
        `${Math.floor(time / 3600)}
        :${Math.floor((time % 3600) / 60)}
        :${(time % 3600) % 60}`,
        delayFormat
    );
export const secondToTimeString = (time: number) =>
    secondToTime(time).format(delayFormat);

const SetDelayModal = (props: SetDelayModalProps) => {
    const { delay, isOpen, onClose, onSave, onError } = props;

    const [delayValue, setDelayValue] = React.useState<number | string>(
        delay || ""
    );

    const [invalidTimeErr, setInvalidTimeErr] = useState<string>("");

    React.useEffect(() => {
        setDelayValue(delay || "");
    }, [delay]);

    const closeModalHandler = () => {
        setDelayValue(delay || "");
        onClose && onClose();
    };

    const timePickerChangeHandler = (value: any) => {
        setInvalidTimeErr("");
        if (value?._isValid) {
            setDelayValue(timeToSecond(value));
        } else {
            setInvalidTimeErr("Invalid time format");
            setDelayValue(0);
        }
    };

    const timeInputChangeHandler = (e: any) => {
        timePickerChangeHandler(moment(e.target.value, delayFormat));
    };

    return (
        <Modal
            show={isOpen}
            onHide={() => {
                closeModalHandler();
                setInvalidTimeErr("");
            }}
            animation={false}
            centered
            size="sm"
        >
            <Modal.Header>
                <Modal.Title>Set Delay</Modal.Title>
                <Button
                    variant=""
                    className="close-button"
                    onClick={() => {
                        closeModalHandler();
                        setInvalidTimeErr("");
                    }}
                >
                    <span className="material-icons">close</span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form className="float-left delay-event__picker">
                    <LocalizationProvider dateAdapter={DateAdapter}>
                        <DesktopTimePicker
                            ampm={false}
                            ampmInClock={false}
                            views={["hours", "minutes", "seconds"]}
                            inputFormat={delayFormat}
                            mask="__:__:__"
                            value={secondToTime(Number(delayValue || 0))}
                            onChange={timePickerChangeHandler}
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    label=""
                                    onChange={timeInputChangeHandler}
                                    InputProps={{
                                        ...props.InputProps,
                                        inputProps: {
                                            ...props?.inputProps,
                                            "aria-label": "delay-time",
                                        },
                                    }}
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <span className="invalid-time-error-msg">{invalidTimeErr}</span>
                <Button
                    variant="primary"
                    disabled={!!invalidTimeErr}
                    onClick={() => {
                        const value = Number(delayValue);
                        if (value < minSecond || value > maxSecond) {
                            onError &&
                                onError(
                                    `Please enter a duration of between ${minSecond}-${maxSecond} secs.`
                                );

                            return;
                        }

                        onSave && onSave(value);
                        onClose && onClose();
                    }}
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default React.memo(SetDelayModal);
