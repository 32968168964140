import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    InputGroup,
    Button,
    Form,
} from "react-bootstrap";

import EventService from "service/eventService";
import OrganisationService from "service/organisationService";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import SelectRecipientModal from "components/modals/SelectRecipientModal";
import { isHttpSuccess, orgId } from "utils/functions";
import { AppContext } from "context/appContext";

import "assets/css/event.css";
import { ActionPatch, ActionPost } from "generated/models";
import { CharacterLimit } from "constant";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import { NEW_ACTION_MESSAGE, UPDATE_ACTION_MESSAGE } from "constant/messages";
import { getMemberName } from "utils/organisationFunction";
import { MemberDto, ResponseDto } from "types";
import { get, isEmpty } from "lodash";

const SendEmail = () => {
    const params: any = useParams();
    const history: any = useHistory();
    const [emailContent, setEmailContent] = useState({
        subject: "",
        message: "",
    });

    const [initialMemberList, setInitialMemberList] = useState<Array<any>>([]);
    const [emailList, setEmailList] = useState<Array<MemberDto>>([]);

    const [isPageLoading, setIsPageLoading] = useState(true);

    const [selectRecipientShow, setSelectRecipientShow] = useState(false);

    const { storeData } = useContext(AppContext);
    const { isDefault, owner } = storeData.organization.currentOrgInfo;
    const { givenName, familyName } = storeData.user.userData;

    useEffect(() => {
        if (params.actionId) {
            const fetchAction = async () => {
                const response = await EventService.readOneAction(
                    params.eventId,
                    params.actionId
                );
                if (isHttpSuccess(response.status)) {
                    const { content, recipients, subject } =
                        response.data.resource;

                    setEmailContent({
                        subject: subject,
                        message: content,
                    });

                    const recipientListRes: any = [];
                    const members =
                        await OrganisationService.getAvailableOrgMembers(
                            orgId()
                        );

                    if (!isEmpty(members) && !isEmpty(recipients)) {
                        for (const savedRecipient of recipients) {
                            const tempRecipient = members.find((member) => {
                                return (
                                    member?.username?.toLowerCase() ===
                                    savedRecipient?.toLowerCase()
                                );
                            });
                            recipientListRes.push(tempRecipient);
                        }
                    }

                    setEmailList(recipientListRes);
                    setInitialMemberList(members);
                    setIsPageLoading(false);
                }
            };
            fetchAction();
        } else {
            fetchOrg();
        }

        return () => {
            setEmailContent({
                subject: "",
                message: "",
            });
            setIsPageLoading(false);
            setEmailList([]);
            setInitialMemberList([]);
        };
    }, [params.actionId, params.eventId]);

    const fetchOrg = async () => {
        const members = await OrganisationService.getAvailableOrgMembers(
            orgId()
        );

        setInitialMemberList(members);
        setIsPageLoading(false);
    };

    const updateField = (e: any) => {
        setEmailContent({
            ...emailContent,
            [e.target.name]: e.target.value,
        });
    };

    const handleButtonAction = async () => {
        let emailListPayload: any;

        if (
            !emailContent.subject.trim().length ||
            emailContent.subject.trim().length >
                CharacterLimit.EVENT_EMAIL_SUBJECT_MAX
        ) {
            showErrorAlert({
                message: `Subject field is required and cannot exceed ${CharacterLimit.EVENT_EMAIL_SUBJECT_MAX} characters in length.`,
            });
            return;
        }

        if (
            !emailContent.message.trim().length ||
            emailContent.message.trim().length >
                CharacterLimit.EVENT_EMAIL_MESSAGE_MAX
        ) {
            showErrorAlert({
                message: `Message field is required and cannot exceed ${CharacterLimit.EVENT_EMAIL_MESSAGE_MAX} characters in length.`,
            });
            return;
        }

        if (emailList.length === 0 && !isDefault) {
            showErrorAlert({
                message: `Please ensure there is at least one recipient.`,
            });
            return;
        }

        if (isDefault) {
            emailListPayload = [owner];
        } else {
            emailListPayload = emailList.map((email) => {
                return email.username;
            });
        }

        let response: ResponseDto<any>;
        const body = {
            type_: "EMAIL",
            resource: {
                recipients: emailListPayload,
                subject: emailContent.subject,
                content: emailContent.message,
            },
        } as ActionPost;
        if (params.actionId) {
            response = await EventService.updateAction(
                params.eventId,
                params.actionId,
                {
                    type_: body.type_,
                    resource: body.resource,
                } as ActionPatch
            );
        } else {
            response = await EventService.createAction(params.eventId, body);
        }

        if (isHttpSuccess(response.status)) {
            showSuccessAlert({
                message: params.actionId
                    ? UPDATE_ACTION_MESSAGE
                    : NEW_ACTION_MESSAGE,
            });
            backToEventPage();
        } else {
            showErrorAlert({
                title: get(response, "data.title", "Error"),
                message: get(
                    response,
                    "data.description",
                    "Unable to save the Email action"
                ),
            });
        }
    };

    const backToEventPage = () => {
        history.push(`/event-details/${params.eventId}`);
    };

    const deleteEmailFromList = (email: string) => {
        const clone = [...emailList];
        setEmailList(clone.filter((item) => item.username !== email));
    };

    const renderEmailRecipient = () => {
        if (isDefault) {
            return (
                <div className="added-item recipients-added">
                    <p className="mb-0 mr-4 added-item-recipient">{`${givenName} ${familyName}`}</p>{" "}
                    <h6 className="ginfo">{owner}</h6>
                </div>
            );
        }

        if (emailList) {
            const lst = emailList.map((i) => {
                return (
                    <React.Fragment key={i.uuid}>
                        <div className="added-item recipients-added">
                            <p className="mb-0 added-item-recipient">
                                {getMemberName(i)}
                            </p>{" "}
                            <h6 className="ginfo">{i.username}</h6>
                            <span
                                className="material-icons recipient-close-btn"
                                onClick={() => {
                                    deleteEmailFromList(i.username);
                                }}
                            >
                                close
                            </span>
                        </div>
                    </React.Fragment>
                );
            });
            return lst;
        }
    };

    return (
        <ContentWrapper isLoading={isPageLoading}>
            <Container>
                <Row>
                    <Col sm="12" className="event-detail-head">
                        <h5 className="page-title overflow-text">Send Email</h5>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Breadcrumb className="w-100">
                            <Breadcrumb.Item href="/events">
                                Events
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                href={`/event-details/${params.eventId}`}
                            >
                                Event Details
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Action</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="condition-detail-box">
                    <Col>
                        <div className="form-box mb-3">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                                placeholder="Enter Subject Here"
                                name="subject"
                                value={emailContent.subject}
                                onChange={updateField}
                            />
                            <Form.Label className="mt-4">Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="message"
                                placeholder="Enter Message Here"
                                rows={3}
                                value={emailContent.message}
                                onChange={updateField}
                            />
                        </div>
                        <div className="form-box mb-3">
                            <h5 className="mb-4">Recipients</h5>
                            <Form.Row>
                                <Col md="5" sm="12">
                                    <InputGroup>
                                        <Button
                                            variant="primary"
                                            className="ml-1"
                                            hidden={isDefault}
                                            onClick={() => {
                                                setSelectRecipientShow(true);
                                            }}
                                        >
                                            + Add
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <div className="recipients email">
                                    <Row>
                                        <Col className="mt-3 ml-2">
                                            {renderEmailRecipient()}
                                        </Col>
                                    </Row>
                                </div>
                            </Form.Row>
                        </div>
                        <Button
                            variant="secondary"
                            className="mr-2"
                            onClick={backToEventPage}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="primary"
                            className="pl-4 pr-4"
                            onClick={handleButtonAction}
                        >
                            {params.actionId ? "UPDATE" : "ADD"}
                        </Button>
                    </Col>
                </Row>
            </Container>
            {/* Select Recipient Modal */}
            <SelectRecipientModal
                show={selectRecipientShow}
                onClose={() => setSelectRecipientShow(false)}
                recipientMode={"Email"}
                emailList={emailList}
                setEmailList={setEmailList}
                initialMemberList={initialMemberList}
            />
        </ContentWrapper>
    );
};

export default SendEmail;
