import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "./context/auth";

const PublicRoute = ({ component: Component, ...rest }: any) => {
    const { context: { authTokens } } = useAuth();

    return (
        <Route
            {...rest}
            render={(props) =>
                !authTokens ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/dashboard",
                            state: { referer: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default PublicRoute;
