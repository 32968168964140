import {
    ACTION_FAIL_MESSAGE,
    CheckoutType,
    SUBSCRIPTION_CANCELLATION_CONFIRMATION_MESSAGE,
    SUBSCRIPTION_DATE_FORMAT,
    SubscriptionDict,
    TRY_AGAIN_ERROR_MESSAGE,
} from "constant";
import { useSubscriptionContext } from "context/subscriptionContext";
import { capitalize, inRange, orderBy } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Row, Col, Button, Modal, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
    cancelSubscription,
    getAllPlans,
    updateSubscriptionAddonById,
} from "service/subscriptionService";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { renderPlanStatus } from "utils/subscriptionFunctions";
import { isHttpSuccess } from "utils/functions";
import { BraintreeId, SubscriptionStatus } from "constant/enums";

const endLabelObj: any = {
    [SubscriptionStatus.CANCELED]: "Ends On",
    [SubscriptionStatus.EXPIRED]: "Expired On",
    [SubscriptionStatus.PAST_DUE]: "Past Due On",
};

const CurrentPlanInformation = () => {
    // ========================= CONTEXT/STATES =========================

    const {
        subscription: {
            plan: { addons = [], amount: subscriptionAmount = "0" } = {},
            billing_period_end_date,
            billing_day_of_month,
            next_billing_date,
            uuid,
            status,
            billing_period_start_date,
        },
        setSubscription,
    }: any = useSubscriptionContext();

    const history = useHistory();
    const [isActionPending, setIsActionPending] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [planUUID, setPlanUUID] = useState("");

    // ========================= DEFINITIONS =========================
    const MODAL_ERROR = "err";
    const MODAL_CONFIRM = "confirm";
    const MODAL_RESUBSCRIPTION_CONFIRM = "resubscriptionConfirm";
    const MODAL_SUBSCRIPTION_CONFIRM = "subscriptionConfirm";
    const MODAL_CANCELLATION_CONFIRM = "cancellationConfirm";
    const MODAL_CANCELLATION_PRECONFIRM = "cancellationPreconfirm";
    const MODAL_REMOVE_ADDON_PRECONFIRM = "removeAddonPreconfirm";
    const MODAL_SUCCESS = "success";
    const MODAL_PENDING = "pending";

    // ========================= USEEFFECT =========================

    useEffect(() => {
        const fetchPlans = async () => {
            const response: any = await getAllPlans();

            if (isHttpSuccess(response.status)) {
                const planUUID_ = response.data.find(
                    ({ bt_id }: { bt_id: string }) => bt_id === "monthly_plan"
                ).uuid;
                setPlanUUID(planUUID_);
            }
        };
        fetchPlans();
    }, []);

    // ========================= FUNCTIONS =========================

    const handleCancelSubscription = async () => {
        setModalShow(false);
        setIsActionPending(true);

        const response: any = await cancelSubscription(uuid);

        if (isHttpSuccess(response.status)) {
            setIsActionPending(false);
            showSuccessAlert({
                message: "Subscription cancelled successfully.",
            });
            setSubscription(response.data);
        } else {
            setIsActionPending(false);
            showErrorAlert({
                message: TRY_AGAIN_ERROR_MESSAGE,
            });
        }
    };

    const removePurchasedToken = async () => {
        setModalShow(false);
        setIsActionPending(true);
        const purchasedTokenAddonUUID = addons.find(
            ({ bt_id }: { bt_id: string }) => bt_id === BraintreeId.TOKEN_ADDON
        ).uuid;

        try {
            const response = await updateSubscriptionAddonById({
                subscriptionId: uuid,
                addonId: purchasedTokenAddonUUID,
                action: "remove",
            });
            if (isHttpSuccess(response.status)) {
                setIsActionPending(false);
                setSubscription(response.data);
                showSuccessAlert({ message: `Add-on removed successfully.` });
            } else {
                setIsActionPending(false);
                showErrorAlert(getAPIError(response, ACTION_FAIL_MESSAGE));
            }
        } catch (err) {}
    };

    const handleSubscribeNow = () => {
        history.push("/checkout/billing-address", {
            checkoutType: CheckoutType.PLAN,
            planUUID: planUUID,
        });
    };

    const showSubscribeConfirmation = () => {
        setModalShow(true);
        setModalType(MODAL_SUBSCRIPTION_CONFIRM);
        setModalContent(
            `Your new subscription will begin today, ${moment().format(
                SUBSCRIPTION_DATE_FORMAT
            )}. Cancel anytime. Plan automatically renews until cancelled.`
        );
    };

    // ========================= MODAL POPUP =========================

    const showRemovePurchasedTokenConfirmation = (totalAmount: string) => {
        setModalShow(true);
        setModalType(MODAL_REMOVE_ADDON_PRECONFIRM);
        setModalContent(
            `Your Purchased Tokens add-on will be canceled immediately. Note that by cancelling, you will not be charged (USD ${totalAmount}) and credited additional purchased tokens from next billing date.`
        );
    };

    const showSubscriptionCancellationConfirmation = () => {
        setModalShow(true);
        setModalType(MODAL_CANCELLATION_PRECONFIRM);
        setModalContent("Do you want to cancel subscription?");
    };

    const confirmToCancelSubscription = () => {
        setModalType(MODAL_CANCELLATION_CONFIRM);
        setModalContent(
            SUBSCRIPTION_CANCELLATION_CONFIRMATION_MESSAGE.fill({
                billing_period_end_date: moment(billing_period_end_date).format(
                    SUBSCRIPTION_DATE_FORMAT
                ),
                next_billing_date: moment(next_billing_date)
                    .add(1, "M")
                    .format(SUBSCRIPTION_DATE_FORMAT),
            })
        );
    };

    const renderModalIcon = () => {
        if (
            [
                MODAL_ERROR,
                MODAL_CONFIRM,
                MODAL_PENDING,
                MODAL_CANCELLATION_PRECONFIRM,
            ].includes(modalType)
        ) {
            return <span className="material-icons">warning</span>;
        } else if (
            [
                MODAL_RESUBSCRIPTION_CONFIRM,
                MODAL_CANCELLATION_CONFIRM,
                MODAL_SUBSCRIPTION_CONFIRM,
                MODAL_REMOVE_ADDON_PRECONFIRM,
            ].includes(modalType)
        ) {
            return <span className="material-icons">help_outline</span>;
        }
        return <span className="material-icons">done</span>;
    };

    const renderModalTitle = () => {
        if (modalType === MODAL_ERROR) {
            return <h3 className="mb-3">Error</h3>;
        } else if (
            [MODAL_CONFIRM, MODAL_CANCELLATION_PRECONFIRM].includes(modalType)
        ) {
            return <h3 className="mb-3">Confirmation</h3>;
        } else if (modalType === MODAL_SUBSCRIPTION_CONFIRM) {
            return <h3 className="mb-3">Confirm Subscription</h3>;
        } else if (modalType === MODAL_CANCELLATION_CONFIRM) {
            return <h3 className="mb-3">Cancel subscription?</h3>;
        } else if (modalType === MODAL_PENDING) {
            return <h3 className="mb-3">Pending</h3>;
        } else if (modalType === MODAL_REMOVE_ADDON_PRECONFIRM) {
            return <h3 className="mb-3">Remove Add-on?</h3>;
        }
        return <h3 className="mb-3">Success</h3>;
    };

    const renderModalButton = () => {
        if ([MODAL_ERROR, MODAL_PENDING].includes(modalType)) {
            return (
                <Button variant="primary" onClick={() => setModalShow(false)}>
                    OK
                </Button>
            );
        } else if (modalType === MODAL_SUBSCRIPTION_CONFIRM) {
            return (
                <>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setModalShow(false);
                        }}
                    >
                        CANCEL
                    </Button>

                    <Button variant="primary" onClick={handleSubscribeNow}>
                        SUBSCRIBE NOW
                    </Button>
                </>
            );
        } else if (modalType === MODAL_CANCELLATION_PRECONFIRM) {
            return (
                <>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setModalShow(false);
                        }}
                    >
                        NO
                    </Button>

                    <Button
                        variant="primary"
                        onClick={confirmToCancelSubscription}
                    >
                        YES
                    </Button>
                </>
            );
        } else if (modalType === MODAL_CANCELLATION_CONFIRM) {
            return (
                <>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setModalShow(false);
                        }}
                    >
                        KEEP SUBSCRIPTION
                    </Button>

                    <Button variant="danger" onClick={handleCancelSubscription}>
                        CANCEL SUBSCRIPTION
                    </Button>
                </>
            );
        } else if (modalType === MODAL_REMOVE_ADDON_PRECONFIRM) {
            return (
                <>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setModalShow(false);
                        }}
                    >
                        KEEP ADD-ON
                    </Button>

                    <Button variant="danger" onClick={removePurchasedToken}>
                        CANCEL ADD-ON
                    </Button>
                </>
            );
        }
    };

    const renderStartDate = (bt_id: string, create_time: string) => {
        if (bt_id === BraintreeId.MONTHLY_PLAN) {
            return moment(create_time).format(SUBSCRIPTION_DATE_FORMAT);
        }

        const addOnCreatedYear = moment(create_time).year();
        const addOnCreatedMonth = moment(create_time).month() + 1;
        const addOnCreatedDay = moment(create_time).date();

        let billingYear = addOnCreatedYear;
        let billingMonth = addOnCreatedMonth;

        // NOTE: If addon created day has past billing date of that month, use next month's billing date
        if (billing_day_of_month <= addOnCreatedDay) {
            const billingDate = moment(
                `${addOnCreatedYear}-${addOnCreatedMonth}`,
                "YYYY-M"
            ).add(1, "M");
            billingYear = moment(billingDate).year();
            billingMonth = moment(billingDate).month() + 1; // moment.month() returns month in 0 to 11
        }

        const numberOfDaysInBillingMonth = moment(
            `${billingYear}-${billingMonth}`,
            "YYYY-M"
        ).daysInMonth();

        const billingDayIsValid = inRange(
            billing_day_of_month,
            1,
            numberOfDaysInBillingMonth
        );

        if (billingDayIsValid) {
            return moment(
                `${billingYear}-${billingMonth}-${billing_day_of_month}`,
                "YYYY-M-D"
            ).format(SUBSCRIPTION_DATE_FORMAT);
        } else {
            return moment(
                `${billingYear}-${billingMonth}-${numberOfDaysInBillingMonth}`,
                "YYYY-M-D"
            ).format(SUBSCRIPTION_DATE_FORMAT);
        }
    };

    const renderEndDate = () => {
        if (status === SubscriptionStatus.PAST_DUE) {
            return moment(billing_period_start_date).format(
                SUBSCRIPTION_DATE_FORMAT
            );
        } else {
            return moment(billing_period_end_date).format(
                SUBSCRIPTION_DATE_FORMAT
            );
        }
    };

    const renderCurrentPlanAddons = () => {
        const onlyAllocatedAndPurchasedAddons = addons.filter(
            ({
                content: {
                    token: { type_ },
                },
            }: {
                content: { token: { type_: string } };
            }) => type_ !== "BONUS"
        );
        const sortedAddons = orderBy(
            onlyAllocatedAndPurchasedAddons,
            ["create_time"],
            ["asc"]
        );

        return sortedAddons.map(
            ({
                bt_id,
                amount,
                content,
                create_time,
                quantity,
                name,
            }: {
                bt_id: string;
                amount: string;
                content: any;
                create_time: string;
                quantity: number;
                name: string;
            }) => {
                return (
                    <div key={bt_id} className="d-flex flex-column h-100 w-100">
                        <Row className="currentPlanRow">
                            <Col sm={5}>
                                <div className="currentPlanTitle">
                                    {bt_id === BraintreeId.MONTHLY_PLAN
                                        ? "Monthly Plan"
                                        : "Add-on"}{" "}
                                    - {renderPlanStatus(status)}
                                </div>
                                <div>{SubscriptionDict[bt_id]}</div>
                                {bt_id !== BraintreeId.MONTHLY_PLAN &&
                                    status === SubscriptionStatus.ACTIVE && (
                                        <div>
                                            {
                                                <span
                                                    className="removeAddon"
                                                    onClick={() => {
                                                        showRemovePurchasedTokenConfirmation(
                                                            (
                                                                Number(amount) *
                                                                quantity
                                                            ).toFixed(2)
                                                        );
                                                    }}
                                                >
                                                    Remove Add-on
                                                </span>
                                            }
                                        </div>
                                    )}
                            </Col>
                            <Col sm={2}>
                                <div className="currentPlanTitle">
                                    Monthly {capitalize(content.token.type_)}{" "}
                                    Tokens
                                </div>
                                <div>
                                    {bt_id !== BraintreeId.MONTHLY_PLAN &&
                                        `${quantity} x `}
                                    {content.token.amount.toLocaleString()}
                                </div>
                            </Col>

                            <Col sm={2}>
                                <div className="currentPlanTitle">
                                    {moment()
                                        .startOf("day")
                                        .isAfter(
                                            moment(
                                                renderStartDate(
                                                    bt_id,
                                                    create_time
                                                )
                                            )
                                        )
                                        ? "Started On"
                                        : "Starts On"}
                                </div>

                                <div>
                                    {" "}
                                    {bt_id === BraintreeId.TOKEN_ADDON &&
                                    status === SubscriptionStatus.CANCELED &&
                                    moment(
                                        renderStartDate(bt_id, create_time)
                                    ).isAfter(moment(billing_period_end_date))
                                        ? "Canceled Prior to Start Date"
                                        : renderStartDate(bt_id, create_time)}
                                </div>
                            </Col>
                            {status === SubscriptionStatus.ACTIVE ? (
                                <Col sm={2}>
                                    <div className="currentPlanTitle">
                                        Renewal
                                    </div>
                                    <div>Monthly</div>
                                </Col>
                            ) : (
                                <Col sm={2}>
                                    <div className="currentPlanTitle">
                                        {endLabelObj[status]}
                                    </div>

                                    <div>
                                        {" "}
                                        {bt_id === BraintreeId.TOKEN_ADDON &&
                                        status ===
                                            SubscriptionStatus.CANCELED &&
                                        moment(
                                            renderStartDate(bt_id, create_time)
                                        ).isAfter(
                                            moment(billing_period_end_date)
                                        )
                                            ? "-"
                                            : renderEndDate()}
                                    </div>
                                </Col>
                            )}

                            <Col sm={1} className="text-right">
                                <div className="currentPlanTitle">
                                    Per Month
                                </div>
                                <div>
                                    {bt_id === BraintreeId.MONTHLY_PLAN
                                        ? `USD ${subscriptionAmount}`
                                        : `USD ${(
                                              Number(amount) * quantity
                                          ).toFixed(2)}`}
                                </div>
                            </Col>
                        </Row>
                    </div>
                );
            }
        );
    };

    const renderCurrentPlanSummary = () => {
        const monthlyTotal: number = addons.reduce(
            (
                acc: number,
                { amount, quantity }: { amount: string; quantity: number }
            ) => {
                acc += Number(amount) * quantity;
                return acc;
            },
            Number(subscriptionAmount)
        );
        return (
            <div className="d-flex flex-column h-100 w-100">
                <Row className="currentPlanRow current-plan-footer">
                    <Col sm={9}></Col>
                    <Col sm={2}>
                        <div className="current-plan-footer-label">
                            Next Billing Date
                        </div>
                        <div>
                            {" "}
                            {moment(next_billing_date).format(
                                SUBSCRIPTION_DATE_FORMAT
                            )}
                        </div>
                    </Col>
                    <Col sm={1} className="text-right">
                        <div className="current-plan-footer-label">
                            {" "}
                            Monthly Total
                        </div>
                        <div>
                            USD {monthlyTotal.toFixed(2).toLocaleString()}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    const renderCurrentPlanButton = () => {
        if (status === SubscriptionStatus.ACTIVE) {
            return (
                <Button
                    onClick={showSubscriptionCancellationConfirmation}
                    variant="danger"
                >
                    CANCEL SUBSCRIPTION{" "}
                </Button>
            );
        } else if (
            status === SubscriptionStatus.EXPIRED ||
            (status === SubscriptionStatus.CANCELED &&
                moment() > moment(billing_period_end_date))
        ) {
            return (
                <Button onClick={showSubscribeConfirmation} variant="primary">
                    SUBSCRIBE{" "}
                </Button>
            );
        }
    };
    return (
        <Row className="manage-subscription-info mt-5">
            <Col className="subscr-details" xs={6}>
                <p>Current Plan</p>
                <p className="period">Manage your subscription</p>
            </Col>
            <Col xs={6} className="cancel-subscription">
                {renderCurrentPlanButton()}
            </Col>
            {renderCurrentPlanAddons()}
            {status === SubscriptionStatus.ACTIVE && renderCurrentPlanSummary()}
            <Modal
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`no-header ${
                    [
                        MODAL_SUCCESS,
                        MODAL_REMOVE_ADDON_PRECONFIRM,
                        MODAL_SUBSCRIPTION_CONFIRM,
                    ].includes(modalType)
                        ? "primary"
                        : "danger"
                }`}
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">{renderModalIcon()}</div>
                    {renderModalTitle()}
                    <p className="mb-4">{modalContent}</p>
                    {renderModalButton()}
                </Modal.Body>
            </Modal>
            <Modal
                centered
                show={isActionPending}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header"
            >
                <Modal.Body className="text-center mt-3 mb-5 mr-4">
                    <Spinner
                        className="centered-spinner"
                        animation="border"
                        variant="primary"
                    />
                </Modal.Body>
            </Modal>
        </Row>
    );
};

export default CurrentPlanInformation;
