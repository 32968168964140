/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContentType, HttpClient, RequestParams } from "../http-client";
import {
  ActionOrderPatch,
  ActionPatch,
  ActionPost,
  CloneEventByIdData,
  ConditionOrderPatch,
  ConditionPatch,
  ConditionPost,
  CreateEventActionData,
  CreateEventConditionData,
  CreateEventData,
  DeleteEventActionByIdData,
  DeleteEventByIdData,
  DeleteEventConditionByIdData,
  EventPatch,
  EventPost,
  GetConditionsByEventIdData,
  GetEventActionByEventIdData,
  GetEventActionByIdData,
  GetEventByIdData,
  GetEventConditionByIdData,
  GetEventsByOrganizationIdData,
  GetEventsByOrganizationIdParamsBy,
  GetEventsByOrganizationIdParamsFilter,
  GetEventsByOrganizationIdParamsOrder,
  UpdateEventActionByIdData,
  UpdateEventActionOrderByIdData,
  UpdateEventByIdData,
  UpdateEventConditionByIdData,
  UpdateEventConditionsByEventIdData,
} from "../models";

export class Events<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags event
   * @name GetEventsByOrganizationId
   * @summary Get all events of specified organization
   * @request GET:/events
   * @secure
   */
  getEventsByOrganizationId = (
    query: {
      /** Organization ID */
      organization_id: string;
      /** Offset for paginated result */
      offset?: number;
      /** Limit for paginated result */
      limit?: number;
      /** Filtering option */
      filter?: GetEventsByOrganizationIdParamsFilter;
      /** Event name for filtering */
      name?: string;
      /** Gateway ID for filtering */
      gateway_id?: string;
      /** Device (LDSU) ID for filtering */
      device_id?: string;
      /** SAID for filtering */
      said?: number;
      /** Recipient for filtering */
      recipient?: string;
      /** Ordering column */
      by?: GetEventsByOrganizationIdParamsBy;
      /** Ordering method */
      order?: GetEventsByOrganizationIdParamsOrder;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<GetEventsByOrganizationIdData, void>({
      path: `/events`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags event
   * @name CreateEvent
   * @summary Create new event
   * @request POST:/events
   * @secure
   */
  createEvent = (data: EventPost, params: RequestParams = {}) =>
    this.http.request<CreateEventData, void>({
      path: `/events`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags event
   * @name DeleteEventById
   * @summary Delete event info by uuid
   * @request DELETE:/events/{event_uuid}
   * @secure
   */
  deleteEventById = (eventUuid: string, params: RequestParams = {}) =>
    this.http.request<DeleteEventByIdData, void>({
      path: `/events/${eventUuid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags event
   * @name GetEventById
   * @summary Get event info by uuid
   * @request GET:/events/{event_uuid}
   * @secure
   */
  getEventById = (eventUuid: string, params: RequestParams = {}) =>
    this.http.request<GetEventByIdData, void>({
      path: `/events/${eventUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags event
   * @name UpdateEventById
   * @summary Update event info by uuid
   * @request PATCH:/events/{event_uuid}
   * @secure
   */
  updateEventById = (eventUuid: string, data: EventPatch, params: RequestParams = {}) =>
    this.http.request<UpdateEventByIdData, void>({
      path: `/events/${eventUuid}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags event
   * @name CloneEventById
   * @summary Duplicate an event by uuid
   * @request POST:/events/{event_uuid}/copy
   * @secure
   */
  cloneEventById = (eventUuid: string, params: RequestParams = {}) =>
    this.http.request<CloneEventByIdData, void>({
      path: `/events/${eventUuid}/copy`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags condition
   * @name GetConditionsByEventId
   * @summary Get all conditions of specified event
   * @request GET:/events/{event_uuid}/condition
   * @secure
   */
  getConditionsByEventId = (eventUuid: string, params: RequestParams = {}) =>
    this.http.request<GetConditionsByEventIdData, void>({
      path: `/events/${eventUuid}/condition`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags condition
   * @name CreateEventCondition
   * @summary Create new condition
   * @request POST:/events/{event_uuid}/condition
   * @secure
   */
  createEventCondition = (eventUuid: string, data: ConditionPost, params: RequestParams = {}) =>
    this.http.request<CreateEventConditionData, void>({
      path: `/events/${eventUuid}/condition`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags condition
   * @name UpdateEventConditionsByEventId
   * @summary Update conditions' order
   * @request PATCH:/events/{event_uuid}/condition/order
   * @secure
   */
  updateEventConditionsByEventId = (eventUuid: string, data: ConditionOrderPatch, params: RequestParams = {}) =>
    this.http.request<UpdateEventConditionsByEventIdData, void>({
      path: `/events/${eventUuid}/condition/order`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags condition
   * @name DeleteEventConditionById
   * @summary Delete condition info by uuid
   * @request DELETE:/events/{event_uuid}/condition/{condition_uuid}
   * @secure
   */
  deleteEventConditionById = (eventUuid: string, conditionUuid: string, params: RequestParams = {}) =>
    this.http.request<DeleteEventConditionByIdData, void>({
      path: `/events/${eventUuid}/condition/${conditionUuid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags condition
   * @name GetEventConditionById
   * @summary Get condition info by uuid
   * @request GET:/events/{event_uuid}/condition/{condition_uuid}
   * @secure
   */
  getEventConditionById = (eventUuid: string, conditionUuid: string, params: RequestParams = {}) =>
    this.http.request<GetEventConditionByIdData, void>({
      path: `/events/${eventUuid}/condition/${conditionUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags condition
   * @name UpdateEventConditionById
   * @summary Update condition info by uuid
   * @request PATCH:/events/{event_uuid}/condition/{condition_uuid}
   * @secure
   */
  updateEventConditionById = (
    eventUuid: string,
    conditionUuid: string,
    data: ConditionPatch,
    params: RequestParams = {},
  ) =>
    this.http.request<UpdateEventConditionByIdData, void>({
      path: `/events/${eventUuid}/condition/${conditionUuid}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags action
   * @name GetEventActionByEventId
   * @summary Get all actions of specified event
   * @request GET:/events/{event_uuid}/action
   * @secure
   */
  getEventActionByEventId = (eventUuid: string, params: RequestParams = {}) =>
    this.http.request<GetEventActionByEventIdData, void>({
      path: `/events/${eventUuid}/action`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags action
   * @name CreateEventAction
   * @summary Create new action
   * @request POST:/events/{event_uuid}/action
   * @secure
   */
  createEventAction = (eventUuid: string, data: ActionPost, params: RequestParams = {}) =>
    this.http.request<CreateEventActionData, void>({
      path: `/events/${eventUuid}/action`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags action
   * @name UpdateEventActionOrderById
   * @summary Update actions' order
   * @request PATCH:/events/{event_uuid}/action/order
   * @secure
   */
  updateEventActionOrderById = (eventUuid: string, data: ActionOrderPatch, params: RequestParams = {}) =>
    this.http.request<UpdateEventActionOrderByIdData, void>({
      path: `/events/${eventUuid}/action/order`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags action
   * @name DeleteEventActionById
   * @summary Delete action info by uuid
   * @request DELETE:/events/{event_uuid}/action/{action_uuid}
   * @secure
   */
  deleteEventActionById = (eventUuid: string, actionUuid: string, params: RequestParams = {}) =>
    this.http.request<DeleteEventActionByIdData, void>({
      path: `/events/${eventUuid}/action/${actionUuid}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags action
   * @name GetEventActionById
   * @summary Get action info by uuid
   * @request GET:/events/{event_uuid}/action/{action_uuid}
   * @secure
   */
  getEventActionById = (eventUuid: string, actionUuid: string, params: RequestParams = {}) =>
    this.http.request<GetEventActionByIdData, void>({
      path: `/events/${eventUuid}/action/${actionUuid}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags action
   * @name UpdateEventActionById
   * @summary Update action info by uuid
   * @request PATCH:/events/{event_uuid}/action/{action_uuid}
   * @secure
   */
  updateEventActionById = (eventUuid: string, actionUuid: string, data: ActionPatch, params: RequestParams = {}) =>
    this.http.request<UpdateEventActionByIdData, void>({
      path: `/events/${eventUuid}/action/${actionUuid}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
