import { Col } from "react-bootstrap";

export const NoInformationColumn = () => {
    return (
        <Col className={`d-flex align-items-center text-danger`}>
            <span className="material-icons mr-2">error_outline</span>
            {""}
            Information not found
        </Col>
    );
};
