import React, { KeyboardEvent } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import { MultipleSensorChartType } from "constant";

import deleteSvg from "assets/svg/delete.svg";

import { MultipleChartBlockProps, MultipleChartColumnData } from "types/Charts";
import { MultipleChartColumn } from "./MultipleChartColumn";

export const MultipleChartBlock: React.FC<MultipleChartBlockProps> = React.memo(
    (props) => {
        const {
            blockKey,
            onDelete,
            onChange,
            defaultValue,
            isSingleUpdate,
            isEdit,
            onSubmitMultipleChart,
        } = props;
        const [formValues, setFormValues] =
            React.useState<MultipleChartColumnData>({
                multipleChartType: "same",
                chart_name: "Multiple sensor chart",
                data: {},
            });
        const [eventTrigger, setEventTrigger] = React.useState<any>();

        React.useEffect(() => {
            defaultValue && setFormValues(defaultValue);
        }, [defaultValue]);

        React.useEffect(() => {
            eventTrigger && onChange(formValues);
            setEventTrigger(undefined);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [formValues]);

        return (
            <div className="multiple-chart-block rounded p-4">
                <Row>
                    <Col sm={11}>
                        <Row>
                            <Col sm={12} className="mb-3">
                                <p>Chart Name</p>
                                <Form.Control
                                    type="text"
                                    className="multiple-chart-name"
                                    placeholder="Enter chart name"
                                    value={formValues.chart_name || ""}
                                    onChange={(event) => {
                                        setFormValues({
                                            ...formValues,
                                            chart_name: event.target.value,
                                        });
                                        setEventTrigger(event);
                                    }}
                                    onKeyPress={(
                                        e: KeyboardEvent<HTMLInputElement>
                                    ) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            if (onSubmitMultipleChart)
                                                onSubmitMultipleChart(e);
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={12}>
                                <p>Select sensor type</p>
                                <Row>
                                    {Object.keys(MultipleSensorChartType).map(
                                        (key) => (
                                            <Col sm={6} key={key}>
                                                <Form.Check
                                                    className={`multiple-chart-block__select-radio mb-1 mb-lg-0 ${
                                                        formValues.multipleChartType ===
                                                            key &&
                                                        "multiple-chart-block__select-radio--active"
                                                    }`}
                                                    checked={
                                                        formValues.multipleChartType ===
                                                        key
                                                    }
                                                    type="radio"
                                                    label={
                                                        MultipleSensorChartType[
                                                            key as keyof typeof MultipleSensorChartType
                                                        ]
                                                    }
                                                    name={`multiple-sensor-type-${blockKey.key}`}
                                                    id={`multiple-sensor-type-${key}-${blockKey.key}`}
                                                    value={key}
                                                    custom
                                                    onChange={(event) => {
                                                        setFormValues(
                                                            (prev: any) => ({
                                                                ...prev,
                                                                multipleChartType:
                                                                    key,
                                                                data: {},
                                                            })
                                                        );
                                                        setEventTrigger(event);
                                                    }}
                                                />
                                            </Col>
                                        )
                                    )}
                                </Row>
                                <hr className="my-4 w-100" />
                            </Col>

                            {Array.from(
                                Array(
                                    formValues.multipleChartType === "same"
                                        ? 1
                                        : 2 // Set number columns
                                ).keys()
                            ).map((column) => (
                                <MultipleChartColumn
                                    key={formValues.multipleChartType + column}
                                    isEdit={isEdit}
                                    column={column}
                                    data={formValues}
                                    onChange={(data) => {
                                        setFormValues((prev: any) => ({
                                            ...prev,
                                            data: {
                                                ...prev.data,
                                                [column]: data,
                                            },
                                        }));
                                        setEventTrigger("change");
                                    }}
                                />
                            ))}
                        </Row>
                    </Col>
                    <Col
                        sm={1}
                        className="delete-block d-flex align-items-center"
                    >
                        {!isSingleUpdate && (
                            <img
                                src={deleteSvg}
                                onClick={() => onDelete?.(blockKey)}
                                className="multiple-chart-block--delete"
                                alt="delete"
                            />
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
);
