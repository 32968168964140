import { OrganisationRoleEvent } from "generated/models";

export interface OrganisationDto {
    isDefault: boolean;
    owner: string;
    name: string;
    ownerName?: string[];
    uuid: string;
    createTime: Date,
    groups: any[];
    members: any[];
    roles: any[];
}

export interface MemberGroupDto {
    createTime: Date;
    isDefault: boolean;
    modifyTime: Date;
    name: string;
    uuid: string;
    members: MemberDto[];
    roles: RoleDto[];
}

export enum MemberStatus {
    Pending = "Pending",
    Accepted = "Accepted",
    Declined = "Declined",
    Owner = "Owner"
}

export interface MemberDto {
    createTime: Date;
    groups: MemberGroupDto[];
    modifyTime: Date;
    status: MemberStatus;
    username: string;
    name: string[]; 
    uuid: string;
    organisation?: OrganisationDto;
    phoneNumberVerified?: boolean;
    inviter: string;
}

export interface RoleDto {
    createTime: Date;
    groups?: MemberGroupDto[];
    modifyTime: Date;
    name: string;
    isDefault: boolean;
    uuid: string;
    permission: OrganisationPermissions;
}

export interface PermissionDto {
    name: string;
    description: string;
    subDescription: string;
    value: Permission;
    key: PermissionKey
}

export interface OrganisationPermissions {
    event: Permission;
    gateway: Permission;
    dashboard: Permission;
    organisation: Permission;
}

export type Permission = OrganisationRoleEvent;
export type PermissionKey = keyof OrganisationPermissions;