import { Row, Col, Form, Alert } from "react-bootstrap";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { get, orderBy } from "lodash";
import EventService from "service/eventService";
import ActionEmail from "assets/svg/event-action-email.svg";
import ActionSMS from "assets/svg/event-action-sms.svg";
import ActionNotification from "assets/svg/event-action-notification.svg";
import ActionActuator from "assets/svg/event-action-actuator.svg";
import moment from "moment";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { faker } from "@faker-js/faker";
import { isHttpSuccess } from "utils/functions";
import {
    FAILED_ACTION_MESSAGE,
    HttpStatus,
    PermissionDeniedMessage,
} from "constant";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";

const { FORBIDDEN } = HttpStatus;
export const RelatedEvents = () => {
    const params: any = useParams();
    const { gatewayId, ldsuId, said } = params;
    const [eventList, setEventList]: any = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        const fetchEvents = async () => {
            const { data, status } = await EventService.readEvent({
                gateway_id: gatewayId,
                device_id: ldsuId,
                said: said,
                filter: "action",
            });

            if (isHttpSuccess(status)) {
                setEventList(data);
            } else if (status === FORBIDDEN) {
                setErrorMessage(
                    get(data, "description", PermissionDeniedMessage)
                );
            }
        };
        fetchEvents();
    }, [gatewayId, ldsuId, said]);

    const toggleEventStatus = async (
        name: string,
        event_id: string,
        active: boolean
    ) => {
        const res = await EventService.updateEvent(event_id, {
            activate: !active,
            name: name,
        });
        if (isHttpSuccess(res.status)) {
            const updatedEventList = eventList.map((e: any) => {
                if (event_id === e.uuid) {
                    e.activate = !e.activate;
                }
                return e;
            });
            setEventList(updatedEventList);
            showSuccessAlert({
                message: `Event has been ${
                    active ? "disabled" : "enabled"
                } successfully.`,
            });
        } else {
            showErrorAlert(getAPIError(res, FAILED_ACTION_MESSAGE));
        }
    };

    const renderEventList = () => {
        if (eventList.length > 0) {
            return renderEvents();
        } else {
            if (errorMessage) {
                return (
                    <Alert
                        variant="danger"
                        role="alert"
                        className="mt-3 text-center"
                    >
                        {errorMessage}
                    </Alert>
                );
            }

            return <div className="mt-5 text-center">No related event</div>;
        }
    };

    const renderEvents = () => {
        const sortedEventListByDate = orderBy(
            eventList,
            (e: any) => moment(e.created_at),
            "asc"
        );

        const events = sortedEventListByDate.map(
            (event: any, index: number) => {
                const renderActionIcon = () => {
                    const actions: any = [];

                    for (const action of event.actions) {
                        const { type_ } = action;
                        if (type_ === "EMAIL" && !actions.includes("EMAIL")) {
                            actions.push("EMAIL");
                        } else if (
                            type_ === "SMS" &&
                            !actions.includes("SMS")
                        ) {
                            actions.push("SMS");
                        } else if (
                            type_ === "PUSH" &&
                            !actions.includes("PUSH")
                        ) {
                            actions.push("PUSH");
                        } else if (
                            type_ === "ACTUATOR" &&
                            !actions.includes("ACTUATOR")
                        ) {
                            actions.push("ACTUATOR");
                        }
                    }
                    // eslint-disable-next-line array-callback-return
                    return actions.map((action: any) => {
                        if (action === "EMAIL")
                            return (
                                <img
                                    src={ActionEmail}
                                    alt="action-email"
                                    key={faker.datatype.uuid()}
                                />
                            );
                        else if (action === "SMS")
                            return (
                                <img
                                    src={ActionSMS}
                                    alt="action-sms"
                                    key={faker.datatype.uuid()}
                                />
                            );
                        else if (action === "PUSH")
                            return (
                                <img
                                    src={ActionNotification}
                                    alt="action-notification"
                                    key={faker.datatype.uuid()}
                                />
                            );
                        else if (action === "ACTUATOR")
                            return (
                                <img
                                    src={ActionActuator}
                                    alt="action-actuator"
                                    key={faker.datatype.uuid()}
                                />
                            );
                    });
                };

                const renderEventCondition = () => {
                    if (!event.conditions.length)
                        return (
                            <Col className="text-secondary-red-1">
                                No Conditions added
                            </Col>
                        );
                    else if (event.conditions.length === 1)
                        return <Col>Single Condition</Col>;
                    else {
                        let slots: any = [];
                        for (let condition of event.conditions) {
                            if (!slots.includes(condition.slot)) {
                                slots.push(condition.slot);
                            }
                        }
                        if (slots.length > 1) {
                            return <Col>AND and OR Conditions</Col>;
                        }

                        return <Col>AND Conditions</Col>;
                    }
                };

                return (
                    <div
                        className="table-row related-event-condition"
                        key={event.uuid}
                    >
                        <Row className="no-checkbox">
                            <Col>
                                <Link
                                    to={{
                                        pathname: `/event-details/${event.uuid}`,
                                    }}
                                >
                                    {event.name}
                                </Link>
                            </Col>
                            {renderEventCondition()}
                            <Col className="event-actions-col">
                                <div className="event-actions">
                                    {renderActionIcon()}
                                </div>
                            </Col>
                            <Col md={{ span: 1 }}>
                                <HoverAuthorizeTooltip permission="event:update">
                                    <span className="d-inline-block">
                                        <Form.Check
                                            type="switch"
                                            id={`switch_${event.uuid}`}
                                            label=""
                                            checked={event.activate}
                                            onChange={() => {
                                                toggleEventStatus(
                                                    event.name,
                                                    event.uuid,
                                                    event.activate
                                                );
                                            }}
                                        />
                                    </span>
                                </HoverAuthorizeTooltip>
                            </Col>

                            <Col
                                md={{ order: "last", span: 1 }}
                                xs={{ span: 1, order: 3 }}
                            >
                                <Link
                                    to={{
                                        pathname: `/event-details/${event.uuid}`,
                                    }}
                                >
                                    <i className="material-icons right">
                                        keyboard_arrow_right
                                    </i>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                );
            }
        );
        return events;
    };

    return (
        <>
            <Row>
                <Col className="mt-5">
                    <h5>Related Events</h5>
                </Col>
            </Row>
            <Row className="cstm-table events-list mt-4">
                <Col sm={12}>
                    <div className="table-head">
                        <Row className="no-checkbox">
                            <Col>Event Name</Col>
                            <Col>Event Conditions</Col>
                            <Col>Event Actions</Col>

                            <Col md={{ span: 1 }}>Status</Col>
                            <Col md={{ span: 1 }}></Col>
                        </Row>
                    </div>
                    {renderEventList()}
                </Col>
            </Row>
        </>
    );
};

export default RelatedEvents;
