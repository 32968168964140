import { useState, useEffect } from "react";
import { getMetricInfo, MetricInfoResponse } from "service/jsonService";
import create from "zustand/vanilla";

declare global {
    interface Window {
        __metricInfo: MetricInfoResponse;
    }
}

export const metricStore = create<{
    metricInfo: MetricInfoResponse;
    findMetricInfo: (cls: string, appId?: string, unit?: string) => any;
}>((set, get) => ({
    metricInfo: {
        cls: {},
        app: {},
    } as any,
    findMetricInfo: (cls, appId, unit) => {
        let metric = get().metricInfo?.cls?.[cls]?.metric;
        let mqttQueryString = "";

        if (metric === "app" && appId) {
            if (unit && unit[0] === "") {
                mqttQueryString = Object.values(
                    get().metricInfo.app[appId].metrics
                )[0];
            } else {
                mqttQueryString =
                    get().metricInfo.app[appId].metrics[
                        String(unit || "").toLowerCase()
                    ];
            }
        } else {
            mqttQueryString = metric;
        }
        return mqttQueryString;
    },
}));

export const useMetricDefined = () => {
    const [data, setData] = useState<MetricInfoResponse>({
        cls: {},
        app: {},
    } as any);

    const [CLStoAttributeTable, setCLStoAttributeTable] = useState<
        Record<string, string>
    >({});
    const [appIDtoAttributeTable, setAppIDtoAttributeTable] = useState<
        Record<string, string>
    >({});

    useEffect(() => {
        if (window.__metricInfo) {
            setData(window.__metricInfo);
            return;
        } else {
            getMetricInfo().then((res) => {
                setData(res);
                metricStore.setState({ metricInfo: res });
                window.__metricInfo = res;
            });
        }
    }, []);

    useEffect(() => {
        const cls_metricKV: Record<string | number, string> = {};
        const app_metricKV: Record<string | number, string> = {};

        Object.keys(data.cls).forEach((key) => {
            cls_metricKV[key] = data.cls[key].name;
        });
        Object.keys(data.app).forEach((key) => {
            app_metricKV[key] = data.app[key].name;
        });

        setCLStoAttributeTable(cls_metricKV);
        setAppIDtoAttributeTable(app_metricKV);
    }, [data]);

    const findMetricInfo = (metric: string, unit?: string) => {
        metric = metric.toLowerCase();
        const metricInfo = data.cls[metric] || data.app[metric];

        if (metricInfo?.metrics) {
            return (
                metricInfo.metrics[unit!] ||
                metricInfo.metrics["na"] ||
                Object.values(metricInfo.metrics)[0] ||
                ""
            );
        }

        if (metricInfo?.metric) {
            return metricInfo.metric;
        }
    };

    return { data, findMetricInfo, CLStoAttributeTable, appIDtoAttributeTable };
};
