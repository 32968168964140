import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    InputGroup,
    Button,
    Form,
} from "react-bootstrap";

import EventService from "service/eventService";
import OrganisationService from "service/organisationService";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import SelectRecipientModal from "components/modals/SelectRecipientModal";
import { isHttpSuccess, orgId } from "utils/functions";
import { AppContext } from "context/appContext";

import "assets/css/event.css";
import { ActionPatch, ActionPost } from "generated/models";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import { CharacterLimit } from "constant";
import {
    EVENT_ACTION_NO_RECIPIENT_MESSAGE,
    NEW_ACTION_MESSAGE,
    NOT_FOUND_PHONE_NUMBER_MESSAGE,
    SMS_RECIPIENT_MESSAGE,
    UPDATE_ACTION_MESSAGE,
} from "constant/messages";
import { MemberDto, ResponseDto } from "types";
import { getMemberName } from "utils/organisationFunction";
import SMSRecipientNoVerified from "components/gateways/SMSRecipientNoVerified";
import { get, isEmpty } from "lodash";

const SendSMS = () => {
    const history: any = useHistory();
    const params: any = useParams();
    const [message, setMessage] = useState("");

    const [initialMemberList, setInitialMemberList] = useState<
        Array<MemberDto>
    >([]);
    const [smsList, setSMSList] = useState<Array<MemberDto>>([]);

    const [isPageLoading, setIsPageLoading] = useState(true);

    const [selectRecipientShow, setSelectRecipientShow] = useState(false);

    const { storeData } = useContext(AppContext);
    const { isDefault, owner } = storeData.organization.currentOrgInfo;
    const { givenName, familyName, phoneNumber, phoneNumberVerified } =
        storeData.user.userData;

    useEffect(() => {
        if (params.actionId) {
            const fetchAction = async () => {
                const response = await EventService.readOneAction(
                    params.eventId,
                    params.actionId
                );
                if (isHttpSuccess(response.status)) {
                    const { content, recipients } = response.data.resource;

                    setMessage(content);

                    const recipientListRes: any = [];
                    const members =
                        await OrganisationService.getAvailableOrgMembers(
                            orgId()
                        );

                    if (!isEmpty(members) && !isEmpty(recipients)) {
                        for (const savedRecipient of recipients) {
                            const tempRecipient = members.find((member) => {
                                return (
                                    member?.username?.toLowerCase() ===
                                    savedRecipient?.toLowerCase()
                                );
                            });
                            recipientListRes.push(
                                toCamelCaseObject(tempRecipient)
                            );
                        }

                        setInitialMemberList(members);
                    }
                    setSMSList(recipientListRes);

                    setIsPageLoading(false);
                }
            };
            fetchAction();
        } else {
            fetchOrg();
        }

        return () => {
            setMessage("");
            setSMSList([]);
            setInitialMemberList([]);
            setIsPageLoading(false);
        };
    }, [params.actionId, params.eventId]);

    const fetchOrg = async () => {
        const members = await OrganisationService.getAvailableOrgMembers(
            orgId()
        );

        setInitialMemberList(members);
        setIsPageLoading(false);
    };

    const handleButtonAction = async () => {
        let smsListPayload: string[] = [];

        if (
            !message.trim().length ||
            message.trim().length > CharacterLimit.EVENT_SMS_MESSAGE_MAX
        ) {
            showErrorAlert({
                message: `Message field is required and cannot exceed ${CharacterLimit.EVENT_SMS_MESSAGE_MAX} characters in length.`,
            });
            return;
        }

        if (isDefault) {
            if (!phoneNumberVerified) {
                showErrorAlert({
                    message: EVENT_ACTION_NO_RECIPIENT_MESSAGE,
                });
                return;
            } else {
                smsListPayload = [owner];
            }
        } else {
            if (smsList.length === 0 && !isDefault) {
                showErrorAlert({
                    message: EVENT_ACTION_NO_RECIPIENT_MESSAGE,
                });
                return;
            }

            smsListPayload = smsList.map((email: MemberDto) => {
                return email.username;
            });
        }

        let response: ResponseDto<any>;
        const body: ActionPost = {
            type_: "SMS",
            resource: {
                recipients: smsListPayload,
                content: message,
            },
        };

        const { actionId, eventId } = params;

        if (params.actionId) {
            response = await EventService.updateAction(eventId, actionId, {
                type_: body.type_,
                resource: body.resource,
            } as ActionPatch);
        } else {
            response = await EventService.createAction(params.eventId, body);
        }

        if (isHttpSuccess(response.status)) {
            showSuccessAlert({
                message: params.actionId
                    ? UPDATE_ACTION_MESSAGE
                    : NEW_ACTION_MESSAGE,
            });
            backToEventPage();
        } else {
            showErrorAlert({
                title: get(response, "data.title", "Error"),
                message: get(
                    response,
                    "data.description",
                    "Unable to save the SMS action"
                ),
            });
        }
    };

    const backToEventPage = () => {
        history.push(`/event-details/${params.eventId}`);
    };

    const deleteSMSFromList = (email: string) => {
        const clone = [...smsList];
        setSMSList(clone.filter((item) => item.username !== email));
    };

    const renderSMSRecipient = () => {
        if (isDefault) {
            if (phoneNumberVerified) {
                return (
                    <div className="added-item recipients-added">
                        <p className="mb-0 mr-4 added-item-recipient">{`${givenName} ${familyName}`}</p>{" "}
                        <h6 className="ginfo">{phoneNumber}</h6>
                    </div>
                );
            }

            return <SMSRecipientNoVerified />;
        }

        if (smsList) {
            const lst = smsList.map((i) => {
                return (
                    <React.Fragment key={i.uuid}>
                        <div className="added-item recipients-added">
                            <p className="mb-0 added-item-recipient">
                                {getMemberName(i)}
                            </p>{" "}
                            <h6 className="ginfo">{i.username}</h6>
                            <span
                                className="material-icons recipient-close-btn"
                                onClick={() => {
                                    deleteSMSFromList(i.username);
                                }}
                            >
                                close
                            </span>
                            {!i.phoneNumberVerified && (
                                <span className="d-flex align-items-center ml-0">
                                    <i className="material-icons">
                                        error_outline
                                    </i>
                                    <span className="phone-number-error-font-size">
                                        {NOT_FOUND_PHONE_NUMBER_MESSAGE}
                                    </span>
                                </span>
                            )}
                        </div>
                    </React.Fragment>
                );
            });
            return lst;
        }
    };

    return (
        <ContentWrapper isLoading={isPageLoading}>
            <Container>
                <Row>
                    <Col sm="12" className="event-detail-head">
                        <h5 className="page-title overflow-text">Send SMS</h5>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Breadcrumb className="w-100">
                            <Breadcrumb.Item href="/events">
                                Events
                            </Breadcrumb.Item>
                            <Breadcrumb.Item
                                href={`/event-details/${params.eventId}`}
                            >
                                Event Details
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>Action</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row className="condition-detail-box">
                    <Col>
                        <div className="form-box mb-3">
                            <Form.Label>Message</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter Message Here"
                                rows={3}
                                value={message}
                                onChange={(e: any) => {
                                    setMessage(e.target.value);
                                }}
                            />
                        </div>
                        <div className="form-box mb-3">
                            <h5 className="mb-4">Recipients</h5>
                            <Form.Row>
                                <Col md="5" sm="12">
                                    <InputGroup>
                                        <Button
                                            variant="primary"
                                            className="ml-1"
                                            hidden={isDefault}
                                            onClick={() => {
                                                setSelectRecipientShow(true);
                                            }}
                                        >
                                            + Add
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <div className="recipients sms">
                                    <Row>
                                        <Col className="mt-3 ml-2">
                                            {renderSMSRecipient()}
                                        </Col>
                                    </Row>
                                    {!isDefault && (
                                        <Row className="recipient-msg">
                                            <Col className="mt-12 ml-2 recipientFooter">
                                                {SMS_RECIPIENT_MESSAGE}
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </Form.Row>
                        </div>
                        <Button
                            variant="secondary"
                            className="mr-2"
                            onClick={backToEventPage}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="primary"
                            className="pl-4 pr-4"
                            onClick={handleButtonAction}
                        >
                            {params.actionId ? "UPDATE" : "ADD"}
                        </Button>
                    </Col>
                </Row>
            </Container>
            {/* Select Recipient Modal */}
            <SelectRecipientModal
                show={selectRecipientShow}
                recipientMode={"SMS"}
                smsList={smsList}
                setSMSList={setSMSList}
                onClose={() => setSelectRecipientShow(false)}
                initialMemberList={initialMemberList}
            />
        </ContentWrapper>
    );
};

export default SendSMS;
