import createVanilla from "zustand/vanilla";
import { persist } from "zustand/middleware";
import type { AxiosResponse } from "axios";
import { HttpStatus } from "constant";
import * as _ from "lodash";
import { useAppContext } from "context/appContext";
import { useEffect, useState } from "react";

export const useOrganizationVanilla = createVanilla<{
    orgId: string;
    setOrgId: (orgId: string) => void;
    organizationList: any[];
    setOrganizationList: (organizationList: any[]) => void;
    isChangeOrganization: boolean | undefined;
    setIsChangeOrganization: (
        isChangeOrganization: boolean | undefined
    ) => void;
    errorPermission: boolean;
    setErrorPermission: (errorPermission: boolean) => void;
}>()(
    persist(
        (set) => ({
            orgId: "",
            setOrgId: (orgId) => set(() => ({ orgId })),
            organizationList: [],
            setOrganizationList: (organizationList) =>
                set(() => ({ organizationList })),
            isChangeOrganization: undefined,
            setIsChangeOrganization: (isChangeOrganization) =>
                set(() => ({ isChangeOrganization })),
            errorPermission: false,
            setErrorPermission: (errorPermission) =>
                set(() => ({ errorPermission })),
        }),
        {
            name: "organizationState",
        }
    )
);

export const changeToOrganization = createVanilla<{
    toOrganization: any;
}>((set) => ({
    toOrganization: {},
}));

type OrganizationIdPatternProps = {
    pattern: RegExp;
    key: string;
    someKey?: string;
};

const getOrganizationIdPattern: OrganizationIdPatternProps[] = [
    {
        pattern: /^\/gateways\/registry/,
        key: "data.data[0].organization_id",
    },
    {
        pattern: /^\/gateways\/[^/]*\/registry/,
        key: "data.data.organization_id",
    },
    {
        pattern: /^\/gateways\/[^/]*\/info/,
        key: "data.data.registry.organization_id",
    },
    {
        pattern: /^\/events\/[^/]*/,
        key: "data.data.organization_id",
        someKey: "data.data[0].organization_id",
    },
    {
        pattern: /^\/organizations\/[^/]*/,
        key: "data.data.id",
    },
    {
        pattern: /^\/organizations\/[^/]*\/users/,
        key: "data.data[0].organizationID",
    },
    {
        pattern: /^\/user-groups\/[^/]*/,
        key: "data.data.organizationID",
    },
    {
        pattern: /^\/roles\/[^/]*/,
        key: "data.data.organizationID",
    },
    {
        pattern: /^\/members\/[^/]*/,
        key: "data.data.organizationID",
    },
];

export const setOrganizationFromResponse = (response: AxiosResponse<any>) => {
    if (response.config.method !== "get") return;
    const urlObj = new URL(response.config.url!);
    useOrganizationVanilla.getState().setErrorPermission(false);

    // Get organization list
    if (urlObj.pathname.match(/(\/organisation)$/)) {
        const { member = [], owner = [] } = response.data.data || {}
        useOrganizationVanilla
            .getState()
            .setOrganizationList([...owner, ...member].toCamelCase());
    }

    // Redirect to personal acct if access subscription from other org
    const windowUrlObj = new URL(window.location.href);
    const subscriptionPaths = [
        "/manage-subscription",
        "/checkout",
        "checkout/billing-address",
        "/billing-history",
        "/billing-address",
    ];

    if (
        subscriptionPaths.includes(windowUrlObj.pathname) &&
        urlObj.pathname.match(/\/subscriptions/)
    ) {
        const primaryOrgId = useOrganizationVanilla
            .getState()
            .organizationList.reduce((acc: any, { isPrimary, id }: any) => {
                if (isPrimary) {
                    acc += id;
                }
                return acc;
            }, "");
        useOrganizationVanilla.getState().setOrgId(primaryOrgId);
    }

    const pattern = getOrganizationIdPattern.find((pattern) => {
        return pattern.pattern.test(urlObj.pathname);
    });

    if (!pattern) return;

    if (
        response.status === HttpStatus.FORBIDDEN &&
        !urlObj.pathname.match(/^\/organisation/)
    ) {
        useOrganizationVanilla.getState().setErrorPermission(true);
    }

    const value =
        _.get(response, pattern.key!) || _.get(response, pattern.someKey!);

    if (value && _.isEmpty(changeToOrganization.getState().toOrganization)) {
        useOrganizationVanilla.getState().setOrgId(value);
    }
};

export const useOrganisation = () => {
    const [ownerOrgIds , setOwnerOrgIds] = useState<string[]>([]);
    const { 
        storeData: { 
            organization: {
                orgList,
                currentOrgId,
                currentOrgInfo
            },
            user: {
                userData: {
                    email
                }
            }
        }
    } = useAppContext();

    useEffect(() => {
        const getOwnerOrgs = () => {
            const ordIds = orgList.reduce((ids: string[], org) => {
                if(org.isDefault || org.owner === email){
                    ids.push(org.uuid);
                }

                return ids;
            }, [])
            
            return ordIds;
        }

        const orgIds = getOwnerOrgs();
        setOwnerOrgIds(orgIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrgId, currentOrgInfo, setOwnerOrgIds]);

    

    return { ownerOrgIds, currentOrgId };
}

export default useOrganizationVanilla;


