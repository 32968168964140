import { useEffect, useState, KeyboardEvent } from "react";

import { useParams } from "react-router-dom";
import { Col, Button, Form } from "react-bootstrap";
import {
    updateActuatorCommand,
    getSensorLastReading,
    toggleActuatorActivation,
} from "service/gatewayService";
import refreshIcon from "assets/svg/refresh-green.svg";
import {
    HttpStatus,
    LEVEL_MODE,
    Patterns,
    TRY_AGAIN_ERROR_MESSAGE,
    CalendarFormat,
    ACTUATOR_NAME_MESSAGE,
    ACTUATOR_CONFIG_ACTIVATION_SUCCESS_MESSAGE,
    ACTUATOR_NAME_SUCCESS_MESSAGE,
} from "constant";
import moment from "moment";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { useActuatorConfigurationContext } from "context/ActuatorConfigurationContext";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { isHttpSuccess } from "utils/functions";

const ActuatorInfo = () => {
    const params: any = useParams();
    const { gatewayId, ldsuId, busNumber, said } = params;
    const {
        actuator,
        actuator: { name, activate_ts, auto, mode },
        editedActuator,
        setActuator,
        setEditedActuator,
        gatewayName,
        ldsuName,
        type,
        editMode,
        isIOControllerState: { unit, isAnalog, appId },
    } = useActuatorConfigurationContext();
    const [errorMsg, setErrorMsg] = useState("");
    const [editName, setEditName] = useState(name);
    const [editNameMode, setEditNameMode] = useState(false);

    const { gatewayNamePattern } = Patterns;

    const [statusState, setStatusState]: any = useState({
        status: "",
        timestamp: "",
    });

    const [isRefreshing, setIsRefreshing] = useState(false);

    const ACTIVATE = "activated";
    const DEACTIVATE = "deactivated";

    useEffect(() => {
        setEditName(name);
    }, [name]);

    useEffect(() => {
        const getStatus = async () => {
            const statusRes: any = await getSensorLastReading(
                params.gatewayId,
                params.ldsuId
            );
            if (statusRes.status === HttpStatus.OK) {
                const { value, ts } = statusRes.data;
                setStatusState({
                    status: value,
                    timestamp: ts,
                });
            }
        };
        getStatus();
    }, [params.gatewayId, params.ldsuId]);

    const renderStatus = () => {
        const { timestamp, status } = statusState;
        const { said } = params;

        const renderHighLow = () => {
            switch (status[said]) {
                case 1:
                    return (
                        <>
                            <i className="material-icons circle online">
                                circle
                            </i>
                            <span className="reading">High</span>
                        </>
                    );
                case 0:
                    return (
                        <>
                            <i className="material-icons circle offline">
                                circle
                            </i>
                            <span className="reading">Low</span>
                        </>
                    );
                default:
                    return (
                        <span className="reading">
                            {status[said]} {unit}
                        </span>
                    );
            }
        };

        if (timestamp) {
            const timeString = moment(timestamp, "X").calendar(CalendarFormat);
            return (
                <>
                    {renderHighLow()}
                    {` ${timeString}`}
                </>
            );
        } else {
            return <>Offline</>;
        }
    };

    const saveName = async () => {
        setErrorMsg("");
        const nameTrim = editName.trim();
        if (name === nameTrim) {
            setEditNameMode(false);
            setEditName(name);
            return;
        }

        if (!gatewayNamePattern.test(nameTrim)) {
            setErrorMsg(ACTUATOR_NAME_MESSAGE);
            return;
        }

        const configBody = {
            name: nameTrim,
        };

        const updateNameRes = await updateActuatorCommand(
            gatewayId,
            ldsuId,
            said,
            configBody
        );

        if (isHttpSuccess(updateNameRes.status)) {
            showSuccessAlert({
                message: ACTUATOR_NAME_SUCCESS_MESSAGE,
            });
            setActuator({ ...actuator, name: nameTrim });
            setEditedActuator({ ...editedActuator, name: nameTrim });
            setEditNameMode(false);
        } else {
            showErrorAlert(getAPIError(updateNameRes, TRY_AGAIN_ERROR_MESSAGE));
        }
    };

    const refreshOneStatus = async () => {
        setIsRefreshing(true);
        const { data, status } = await getSensorLastReading(
            params.gatewayId,
            params.ldsuId
        );

        if (isHttpSuccess(status)) {
            setIsRefreshing(false);
            const { value, ts } = data;

            setStatusState({
                status: value,
                timestamp: ts,
            });
        }
        setIsRefreshing(false);
    };

    const renderLastActivated = () => {
        if (activate_ts) {
            const timeString = moment(activate_ts, "X").calendar(
                CalendarFormat
            );
            return (
                <p>
                    Last activated
                    {` ${timeString[0].toLowerCase() + timeString.slice(1)}`}
                </p>
            );
        }
    };

    const checkBeforeConfigure = (state: string) => {
        if (editMode) {
            showErrorAlert({
                message: (
                    <>
                        <span className="check-before-configure-msg">
                            Save configuration
                        </span>{" "}
                        before {state}
                    </>
                ),
            });
            return true;
        }
        return false;
    };

    const abort = async () => {
        if (checkBeforeConfigure("Abort")) return;

        const abortRes = await toggleActuatorActivation({
            gatewayId: params.gatewayId,
            deviceId: ldsuId,
            said,
            activate: false,
            immediately: true,
        });

        if (isHttpSuccess(abortRes.status)) {
            showSuccessAlert({
                message: ACTUATOR_CONFIG_ACTIVATION_SUCCESS_MESSAGE.fill({
                    state: "aborted",
                }),
            });
        } else {
            showErrorAlert(getAPIError(abortRes, TRY_AGAIN_ERROR_MESSAGE));
        }
    };

    const toggleActivation = async (activation_state: string) => {
        if (
            checkBeforeConfigure(
                activation_state === ACTIVATE ? "Activate" : "Deactivate"
            )
        ) {
            return;
        }

        const res = await toggleActuatorActivation({
            gatewayId: params.gatewayId,
            deviceId: ldsuId,
            said,
            activate: activation_state === ACTIVATE,
        });

        if (isHttpSuccess(res.status)) {
            const { activate_ts } = res.data;

            if (activation_state === ACTIVATE) {
                setActuator({ ...actuator, activate_ts: activate_ts });
            }

            showSuccessAlert({
                message: ACTUATOR_CONFIG_ACTIVATION_SUCCESS_MESSAGE.fill({
                    state: activation_state,
                }),
            });
        } else {
            showErrorAlert(getAPIError(res, TRY_AGAIN_ERROR_MESSAGE));
        }
    };

    return (
        <ContentWrapper>
            <Col lg={4} xs={12}>
                <div className="actuator-summary">
                    <HoverAuthorizeTooltip permission="gateway:update">
                        <Button
                            variant="primary"
                            className="mr-1 pl-2 pr-2"
                            disabled={auto}
                            onClick={() => {
                                toggleActivation(ACTIVATE);
                            }}
                        >
                            Activate
                        </Button>
                    </HoverAuthorizeTooltip>
                    <HoverAuthorizeTooltip permission="gateway:update">
                        <Button
                            variant="danger"
                            className="mr-1 pl-2 pr-2"
                            disabled={auto}
                            onClick={() => {
                                toggleActivation(DEACTIVATE);
                            }}
                        >
                            Deactivate
                        </Button>
                    </HoverAuthorizeTooltip>
                    <HoverAuthorizeTooltip permission="gateway:update">
                        <Button
                            className="btn btn-abort mr-1 pl-2 pr-2"
                            hidden={mode === LEVEL_MODE || isAnalog}
                            disabled={auto}
                            onClick={abort}
                        >
                            Abort
                        </Button>
                    </HoverAuthorizeTooltip>
                    <div className="last-active float-right text-right mt-2">
                        {renderLastActivated()}
                    </div>

                    {auto && (
                        <p className="mt-3">
                            This actuator can only be activated/deactivated by
                            Events as the 'Control' setting is set to 'Auto'
                        </p>
                    )}
                </div>
                <div className="actuator-summary mt-2">
                    <div className="sensor-info">
                        <h5 className="float-left">Status</h5>
                    </div>

                    <div className="last-active float-right text-right">
                        <p>
                            {renderStatus()}
                            <span>
                                <img
                                    className={
                                        isRefreshing
                                            ? "bigRefresh refresh-animation"
                                            : "bigRefresh"
                                    }
                                    src={refreshIcon}
                                    alt="refresh-status"
                                    onClick={refreshOneStatus}
                                />
                            </span>
                        </p>
                    </div>
                </div>
                <div className="form-box mt-2">
                    <Form.Row>
                        <Col md="12">
                            <Form.Label>Actuator Name</Form.Label>
                        </Col>
                        <Col lg={8} xs={12} className="mb-2">
                            <Form.Group className="mb-0">
                                <Form.Control
                                    type="text"
                                    required
                                    aria-label="name"
                                    value={editName}
                                    disabled={!editNameMode}
                                    onChange={(e: any) => {
                                        setEditName(e.target.value);
                                    }}
                                    onKeyPress={(
                                        e: KeyboardEvent<HTMLInputElement>
                                    ) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            saveName();
                                        }
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg={4} xs={12}>
                            {editNameMode ? (
                                <Button
                                    type="button"
                                    className="w-100"
                                    onClick={saveName}
                                >
                                    Save
                                </Button>
                            ) : (
                                <HoverAuthorizeTooltip permission="gateway:update">
                                    <Button
                                        type="button"
                                        className="w-100"
                                        onClick={() => {
                                            setEditNameMode(true);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </HoverAuthorizeTooltip>
                            )}
                        </Col>
                        <Col>
                            <span className="text-danger" role="alert">
                                {errorMsg}
                            </span>
                        </Col>
                    </Form.Row>
                </div>
                <div className="form-box mt-2 ">
                    <Form.Row>
                        <Col md="12">
                            <div className="descriptor-item">
                                <h6 className="float-left">Type</h6>
                                <h6 className="ginfo float-right">{type}</h6>
                            </div>
                            {appId && (
                                <div className="descriptor-item">
                                    <h6 className="float-left">
                                        IO Controller App ID
                                    </h6>
                                    <h6 className="ginfo float-right">
                                        {appId}
                                    </h6>
                                </div>
                            )}

                            <div className="descriptor-item">
                                <h6 className="float-left">LDSU Name</h6>
                                <h6 className="ginfo float-right">
                                    {ldsuName}
                                </h6>
                            </div>
                            <div className="descriptor-item">
                                <h6 className="float-left">LDSU ID</h6>
                                <h6 className="ginfo float-right">{ldsuId}</h6>
                            </div>
                            <div className="descriptor-item">
                                <h6 className="float-left">LDSU Bus Port</h6>
                                <h6 className="ginfo float-right">
                                    {busNumber}
                                </h6>
                            </div>
                            <div className="descriptor-item">
                                <h6 className="float-left">Gateway Name</h6>
                                <h6 className="ginfo float-right">
                                    {gatewayName}
                                </h6>
                            </div>
                        </Col>
                    </Form.Row>
                </div>
            </Col>
        </ContentWrapper>
    );
};

export default ActuatorInfo;
