import { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Form,
    Button,
    Modal,
    Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import addressImg from "assets/svg/address.svg";
import paymentImg from "assets/svg/payment.svg";
import dropin from "braintree-web-drop-in";
import "assets/css/subscription.css";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import AccountService from "service/accountService";

import {
    getClientToken,
    activateSubscription,
    buyAddon,
    getSubscriptionPrice,
    getAddonPrice,
    getWalletStatus,
    getSubscriptionById,
    getAddress,
} from "service/subscriptionService";

import "assets/css/subscription.css";
import PromoCode from "components/subscriptions/Promocode";
import EditCustomer from "components/subscriptions/EditCustomer";
import { getAllTokens, isHttpSuccess } from "utils/functions";
import { AppContext } from "context/appContext";
import { storeTokenBalanceAction } from "store/actions";
import { CheckoutType, HttpStatus, TERMS_OF_SERVICE_LINK } from "constant";
import { showErrorAlert } from "utils/alert";
import { BillingAddressProp } from "types/Subscription";
import { get } from "lodash";
import { subscriptionPeriodDetail } from "utils/subscriptionFunctions";

const Checkout = (props: any) => {
    const { billing_cycles } = props?.location?.state?.customer || 0;
    const history = useHistory();
    const location: any = useLocation();
    const { state } = location;
    const { storeData, storeDispatchActions } = useContext(AppContext);
    const {
        organization,
        user: {
            userData: { email },
        },
        subscription: { currentSubscriptionId },
    } = storeData;
    const billingStartDate = state?.billingStartDate;

    const [brainTreeInstance, setBrainTreeInstance] = useState(Object);

    const [checkedTerms, updateCheckedTerms] = useState(false);

    const [bundleQty, setBundleQty] = useState(state?.bundleQty);

    const [pageLoaded, setPageLoaded] = useState(false);
    const [transactionLoaded, setTransactionLoaded] = useState(true);
    const [price, setPrice] = useState<any>({
        amount: "",
        total: "",
        tax: "",
        discount: "",
        addon: "",
        tax_rate: "",
    });
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [errorType, setErrorType] = useState("");
    const MODAL_RESUBSCRIPTION_SUCCESS = "resubscription_success";
    const MODAL_SUCCESS = "success";
    const MODAL_ERROR = "error";
    const INVALID = "invalid";
    const TRANSACTION_FAIL = "transactionFailed";
    const { CREATED } = HttpStatus;

    const [currentAddress, setCurrentAddress] = useState<BillingAddressProp>({
        first_name: "",
        last_name: "",
        city: "",
        street: "",
        line3: "",
        country: "",
        code: "",
        extended: "",
        region: "",
    });

    const [currentSubscriptionUUID, setCurrentSubscriptionUUID] = useState(
        currentSubscriptionId || ""
    );

    const [selectedPromocode, setSelectedPromocode] = useState({
        promocode_name: "",
        description: "",
    });

    const addonUUID = state?.addonUUID;
    const subscriptionUUID = state?.subscriptionUUID;
    const [refreshKey, setRefreshKey] = useState(0);

    const bundleInfo = {
        amount: state?.bundleInfo?.amount,
        tokenQty: state?.bundleInfo?.tokenQty,
    };

    const checkoutType = state?.checkoutType;

    const getTokenbalance = async () => {
        const total: any = await getAllTokens();
        storeDispatchActions(
            storeTokenBalanceAction({ tokenBalance: total, isLoaded: true })
        );
    };

    const createDropin = async (clientToken: any) => {
        dropin.create(
            {
                authorization: clientToken,
                container: "#braintree-drop-in-div",
                threeDSecure: true,
                card: { vault: { vaultCard: false } },
                preselectVaultedPaymentMethod: false,
            },
            (error: any, instance: any) => {
                if (error) {
                    setRefreshKey(refreshKey + 1);
                } else {
                    setBrainTreeInstance(instance);
                    setTransactionLoaded(true);
                }
            }
        );
    };
    useEffect(() => {
        const fetch = async () => {
            const response: any = await getClientToken();

            if (isHttpSuccess(response.status)) {
                const clientToken = response.data?.data;

                createDropin(clientToken);

                const [addressRes, userRes]: any = await Promise.all([
                    getSubscriptionById(
                        subscriptionUUID || currentSubscriptionId
                    ),
                    AccountService.getAccountInfo(),
                ]);

                if (
                    isHttpSuccess(addressRes.status) &&
                    isHttpSuccess(userRes.status)
                ) {
                    let currentAddress: any = addressRes?.data?.data?.address;
                    if (checkoutType === CheckoutType.ADDON) {
                        const address: any = await getAddress();
                        if (isHttpSuccess(address.status)) {
                            currentAddress = get(address, "data.data[0]", {});
                        }
                    }
                    const {
                        first_name,
                        last_name,
                        city,
                        street,
                        line3,
                        country,
                        code,
                        extended,
                        region,
                    } = currentAddress;

                    setCurrentAddress({
                        first_name,
                        last_name,
                        city,
                        street,
                        line3,
                        country,
                        code,
                        extended,
                        region,
                    });

                    let priceRes: any;

                    if (checkoutType === CheckoutType.PLAN) {
                        if (subscriptionUUID) {
                            setCurrentSubscriptionUUID(
                                subscriptionUUID || currentSubscriptionId
                            );

                            priceRes = await getSubscriptionPrice(
                                subscriptionUUID,
                                country
                            );
                        }
                    } else if (checkoutType === CheckoutType.ADDON) {
                        priceRes = await getAddonPrice({
                            addon_uuid: state.addonUUID,
                            country,
                            quantity: bundleQty,
                            coupon: selectedPromocode.promocode_name,
                        });
                    }

                    if (isHttpSuccess(priceRes?.status)) {
                        setPrice(priceRes.data);
                    }
                    setPageLoaded(true);
                } else {
                    setPageLoaded(true);
                    setModalType(MODAL_ERROR);
                    setErrorType(INVALID);
                    setModalContent("Something went wrong. Please try again.");
                    return;
                }
            } else {
                setPageLoaded(true);
                setModalType(MODAL_ERROR);
                setErrorType(INVALID);
                setModalContent("Something went wrong. Please try again.");
                return;
            }
        };
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshKey]);

    const qtyField: any = document.querySelector("#qtyField");
    qtyField?.addEventListener("keydown", (e: any) => {
        if (
            !(
                (e.keyCode > 95 && e.keyCode < 106) ||
                (e.keyCode > 47 && e.keyCode < 58) ||
                e.keyCode === 8
            )
        ) {
            e.preventDefault();
        }
    });

    qtyField?.addEventListener("paste", (e: any) => {
        const pastedData = e.clipboardData.getData("text/plain");
        if (pastedData.match(/[^0-9]/)) e.preventDefault();
    });

    const calculatePrice = async (quantity: string) => {
        if (Number(quantity) < 1 || Number(quantity) > 100) {
            return;
        }
        const priceRes: any = await getAddonPrice({
            addon_uuid: state.addonUUID,
            country: currentAddress.country,
            quantity,
            coupon: selectedPromocode.promocode_name,
        });
        if (priceRes.status === 200) {
            setPrice(priceRes.data);
            setBundleQty(quantity);
        } else {
            showErrorAlert({ message: priceRes?.response?.data?.description });
        }
    };

    const renderTotalBox = () => {
        switch (checkoutType) {
            case CheckoutType.PLAN:
                return <span>USD {price.total}</span>;
            case CheckoutType.ADDON:
                if (bundleQty) {
                    return <span>USD {Number(price.total).toFixed(2)}</span>;
                } else {
                    return <span>USD 0.00</span>;
                }
        }
    };

    const renderModalIcon = () => {
        if (modalType === MODAL_ERROR) {
            return <span className="material-icons">warning</span>;
        } else if (
            modalType === MODAL_SUCCESS ||
            modalType === MODAL_RESUBSCRIPTION_SUCCESS
        ) {
            return <span className="material-icons">done</span>;
        }
        return;
    };

    const renderModalTitle = () => {
        if (modalType === MODAL_ERROR && errorType === TRANSACTION_FAIL) {
            return <h3 className="mb-3">Payment Failed!</h3>;
        } else if (modalType === MODAL_ERROR && errorType === INVALID) {
            return <h3 className="mb-3">Error</h3>;
        } else if (modalType === MODAL_SUCCESS) {
            return <h3 className="mb-3">Payment Successful!</h3>;
        } else if (
            state?.status === "resubscription" &&
            modalType === MODAL_RESUBSCRIPTION_SUCCESS
        ) {
            return <h3 className="mb-3">Resubscription Successful!</h3>;
        }
        return <h3 className="mb-3 pt-5">Terms of Service</h3>;
    };

    const proceedToMainSubscriptionPageOnSuccess = async () => {
        setModalShow(false);
        getTokenbalance();
        await getWalletStatus(organization.currentOrgId);
        history.push("/manage-subscription");
    };

    const renderModalButton = () => {
        if (modalType === MODAL_ERROR && errorType === TRANSACTION_FAIL) {
            return (
                <Button
                    variant="secondary"
                    onClick={() => {
                        setModalShow(false);
                    }}
                >
                    Try Again
                </Button>
            );
        } else if (modalType === MODAL_ERROR && errorType === INVALID) {
            return (
                <Button
                    variant="primary"
                    onClick={() => {
                        setModalShow(false);
                    }}
                >
                    OK
                </Button>
            );
        } else if (
            modalType === MODAL_SUCCESS ||
            modalType === MODAL_RESUBSCRIPTION_SUCCESS
        ) {
            return (
                <Button
                    variant="primary"
                    onClick={async () => {
                        proceedToMainSubscriptionPageOnSuccess();
                    }}
                >
                    OK
                </Button>
            );
        }
        return (
            <Button
                variant="primary"
                onClick={() => {
                    setModalShow(false);
                }}
            >
                OK
            </Button>
        );
    };

    const renderTaxInfo = () => {
        return (
            <p>
                {price.tax_rate * 100}% GST
                {checkoutType === CheckoutType.PLAN ? (
                    <span>USD {price.tax}</span>
                ) : (
                    <span>
                        USD{" "}
                        {price.tax.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                        })}
                    </span>
                )}
            </p>
        );
    };

    const isTOSChecked = () => {
        if (!checkedTerms) {
            showErrorAlert({
                message:
                    "Please agree with the terms of service before proceeding with the purchase.",
            });

            return false;
        }
        return true;
    };

    const makeTransaction = () => {
        setTransactionLoaded(false);

        const { first_name, last_name, street, extended, country, code } =
            currentAddress;

        const threeDSecureParameters = {
            amount: price.total,
            email: email,
            billingAddress: {
                givenName: first_name,
                surname: last_name,
                streetAddress: street,
                extendedAddress: extended,
                locality: country,
                postalCode: code,
            },
        };

        if (brainTreeInstance) {
            brainTreeInstance?.requestPaymentMethod(
                { threeDSecure: threeDSecureParameters },
                (error: any, payload: any) => {
                    if (error) {
                        brainTreeInstance.clearSelectedPaymentMethod();
                        setTransactionLoaded(true);
                        setModalShow(true);
                        setModalType(MODAL_ERROR);
                        setErrorType(TRANSACTION_FAIL);
                        setModalContent(error.message);
                    } else {
                        if (payload.type === "CreditCard") {
                            const { liabilityShifted } = payload;

                            if (!liabilityShifted) {
                                brainTreeInstance.clearSelectedPaymentMethod();

                                setModalShow(true);
                                setModalType(MODAL_ERROR);
                                setErrorType(INVALID);
                                setModalContent(
                                    "Authentication unsuccessful. Please try again with another payment method."
                                );

                                brainTreeInstance.teardown();
                                setRefreshKey(refreshKey + 1);
                                return;
                            }
                        }

                        const paymentMethodNonce = payload.nonce;

                        const pay = async () => {
                            if (checkoutType === CheckoutType.PLAN) {
                                const subscriptionActivation = async (
                                    subscription_uuid: string
                                ) => {
                                    const response: any =
                                        await activateSubscription(
                                            subscription_uuid,
                                            paymentMethodNonce
                                        );

                                    if (response.status === CREATED) {
                                        setTransactionLoaded(true);
                                        setModalShow(true);
                                        if (state.status !== "resubscription") {
                                            setModalType(MODAL_SUCCESS);
                                            setModalContent(
                                                "Your transaction is successful."
                                            );
                                        } else {
                                            setModalType(
                                                MODAL_RESUBSCRIPTION_SUCCESS
                                            );
                                            setModalContent(
                                                "Your resubscription is successful."
                                            );
                                        }
                                    } else {
                                        brainTreeInstance.clearSelectedPaymentMethod();
                                        setTransactionLoaded(true);
                                        setModalShow(true);
                                        setModalType(MODAL_ERROR);
                                        setErrorType(TRANSACTION_FAIL);
                                        setModalContent(
                                            "Please try a different payment method."
                                        );
                                    }
                                };

                                subscriptionActivation(currentSubscriptionUUID);
                            } else {
                                if (
                                    parseInt(bundleQty) <= 0 ||
                                    bundleQty === ""
                                ) {
                                    setModalShow(true);
                                    setModalType(MODAL_ERROR);
                                    setErrorType(INVALID);
                                    setModalContent(
                                        "You have not selected any item to purchase."
                                    );
                                    setTransactionLoaded(true);
                                    return;
                                }

                                if (!Number.isInteger(Number(bundleQty))) {
                                    setModalShow(true);
                                    setModalType(MODAL_ERROR);
                                    setErrorType(INVALID);
                                    setModalContent(
                                        "Purchase quantity is not allowed."
                                    );
                                    setTransactionLoaded(true);
                                    return;
                                }

                                if (Number(bundleQty) > 10000) {
                                    setModalShow(true);
                                    setModalType(MODAL_ERROR);
                                    setErrorType(INVALID);
                                    setModalContent(
                                        "Unable to purchase more than 10,000,000,000 tokens per transaction."
                                    );
                                    setTransactionLoaded(true);
                                    return;
                                }

                                const addonBody: any = {
                                    quantity: bundleQty,
                                    nonce: paymentMethodNonce,
                                    address_uuid: state?.addressUUID,
                                    coupon: selectedPromocode.promocode_name,
                                };

                                if (!selectedPromocode.promocode_name) {
                                    delete addonBody.coupon;
                                }

                                const response: any = await buyAddon(
                                    state?.addonUUID,
                                    addonBody
                                );

                                if (response.status === CREATED) {
                                    setTransactionLoaded(true);
                                    setModalShow(true);
                                    setModalType(MODAL_SUCCESS);
                                    setModalContent(
                                        "Your transaction is successful."
                                    );
                                } else {
                                    brainTreeInstance.clearSelectedPaymentMethod();
                                    setTransactionLoaded(true);
                                    setModalShow(true);
                                    setModalType(MODAL_ERROR);
                                    setErrorType(TRANSACTION_FAIL);
                                    setModalContent(
                                        "Please try a different payment method."
                                    );
                                }
                            }
                        };
                        pay();
                    }
                }
            );
        }
    };

    return (
        <>
            <ContentWrapper isLoading={!pageLoaded}>
                <div className="page-content subscription-page">
                    <Container fluid>
                        <Row>
                            <Col sm="12">
                                <h5 className="page-title">Checkout</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Breadcrumb className="w-100">
                                    <Breadcrumb.Item>
                                        <Link to="/manage-subscription">
                                            Subscriptions
                                        </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <Link
                                            to={{
                                                pathname:
                                                    "/checkout/billing-address",
                                                state: {
                                                    ...state,
                                                    bundleQty: bundleQty,
                                                    cancelledSubscriptionUUID:
                                                        state?.subscriptionUUID,
                                                },
                                            }}
                                        >
                                            Billing Address
                                        </Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Checkout
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8}>
                                {checkoutType === CheckoutType.PLAN && (
                                    <EditCustomer state={state} />
                                )}
                                <div className="form-box mb-3">
                                    <div className="address-title position-relative">
                                        <img
                                            src={addressImg}
                                            className="address-img"
                                            alt="address"
                                        />
                                        <h5>Billing Address</h5>
                                        <p>
                                            Address is required according to
                                            regulatory requirement.
                                        </p>

                                        <Link
                                            to={{
                                                pathname:
                                                    "/checkout/billing-address",
                                                state: {
                                                    ...state,
                                                    bundleQty: bundleQty,
                                                },
                                            }}
                                            className="link billing-address-edit"
                                        >
                                            Edit
                                        </Link>
                                        <div className="mt-3 billing-ad-info">
                                            {currentAddress.first_name}{" "}
                                            {currentAddress.last_name}
                                        </div>
                                        <div className="mt-3 billing-ad-info">{`${
                                            currentAddress.city
                                        }, ${currentAddress.street},  ${
                                            currentAddress.extended
                                                ? `${currentAddress.extended}, `
                                                : ""
                                        }${currentAddress.line3}, ${
                                            currentAddress.country
                                        }, ${currentAddress.code}`}</div>
                                    </div>
                                </div>
                                <div className="form-box mb-3">
                                    <div className="payment-title">
                                        <img
                                            src={paymentImg}
                                            className="address-img"
                                            alt="payment"
                                        />
                                        <h5>Payment Method</h5>
                                        <p>Credit or Debit Card</p>

                                        <div
                                            className="pt-5"
                                            id={"braintree-drop-in-div"}
                                        ></div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="form-box">
                                    <h5 className="bill-title">
                                        {state?.status !== "resubscription"
                                            ? "You are buying"
                                            : "You are resubscribing for"}
                                    </h5>

                                    {checkoutType === CheckoutType.PLAN ? (
                                        <div className="float-left w-100">
                                            <div className="monthly-charge">
                                                <div className="d-flex justify-content-between">
                                                    <h6>Monthly Charge</h6>
                                                    <div>
                                                        <h5>
                                                            USD {price.amount}
                                                            /mo
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="billing-start-text">
                                                Billing Starts:{" "}
                                                {billingStartDate}
                                            </span>
                                            <p>
                                                Subscription Period:{" "}
                                                {subscriptionPeriodDetail(
                                                    billingStartDate,
                                                    billing_cycles
                                                )}
                                            </p>
                                        </div>
                                    ) : (
                                        <>
                                            <div className="float-left w-100">
                                                <div className="monthly-charge">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <h6>
                                                                Bundle of
                                                                1,000,000
                                                                Purchased Tokens
                                                            </h6>
                                                            <p>No expiry</p>
                                                        </div>
                                                        <div>
                                                            <h5>
                                                                USD{" "}
                                                                {
                                                                    bundleInfo.amount
                                                                }
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="qty qty-checkout">
                                                <Form className="form">
                                                    <Form.Label className="float-left">
                                                        <span className="default-white-color">
                                                            Qty
                                                        </span>{" "}
                                                        (Max 100)
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        className="float-left"
                                                        min={1}
                                                        max={100}
                                                        maxLength={3}
                                                        id="qtyField"
                                                        value={bundleQty}
                                                        onKeyDown={(e: any) => {
                                                            if (
                                                                e.key ===
                                                                    "Backspace" &&
                                                                e.target.value
                                                                    .length ===
                                                                    1
                                                            ) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            calculatePrice(
                                                                e.target.value
                                                            );
                                                        }}
                                                    ></Form.Control>
                                                </Form>
                                                <div className="total">
                                                    <h5>
                                                        {Number(
                                                            Number(bundleQty) *
                                                                Number(
                                                                    bundleInfo.tokenQty
                                                                )
                                                        ).toLocaleString()}{" "}
                                                        Tokens
                                                    </h5>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <PromoCode
                                        bundleQty={
                                            bundleQty || state?.bundleQty
                                        }
                                        plan={state?.plan}
                                        subscriptionUUID={subscriptionUUID}
                                        setSelectedPromocode={
                                            setSelectedPromocode
                                        }
                                        country={currentAddress.country}
                                        setPrice={setPrice}
                                        checkoutType={checkoutType}
                                        addonUUID={addonUUID}
                                        selectedPromocode={selectedPromocode}
                                    />

                                    <div className="amount-box">
                                        <p>
                                            Sub Total{" "}
                                            {checkoutType ===
                                            CheckoutType.PLAN ? (
                                                <span>USD {price.amount}</span>
                                            ) : (
                                                <span>
                                                    USD{" "}
                                                    {Number(
                                                        price.addon
                                                    ).toLocaleString(
                                                        undefined,
                                                        {
                                                            minimumFractionDigits: 2,
                                                        }
                                                    )}
                                                </span>
                                            )}
                                        </p>

                                        <p>
                                            Discount{" "}
                                            {checkoutType ===
                                            CheckoutType.PLAN ? (
                                                <span>
                                                    {" "}
                                                    - USD{" "}
                                                    {Number(
                                                        price.discount
                                                    ).toFixed(2)}
                                                </span>
                                            ) : (
                                                <span>
                                                    - USD{" "}
                                                    {bundleQty > 0
                                                        ? Number(
                                                              price.discount
                                                          ).toFixed(2)
                                                        : `0.00`}
                                                </span>
                                            )}
                                        </p>
                                        {currentAddress.country ===
                                            "Singapore" && renderTaxInfo()}
                                    </div>

                                    {checkoutType === CheckoutType.PLAN ? (
                                        <>
                                            {state?.status !==
                                            "resubscription" ? (
                                                <>
                                                    <p className="font-weight-bold">
                                                        Billing starts from
                                                        today {billingStartDate}{" "}
                                                        and will renew
                                                        automatically every
                                                        month.
                                                    </p>

                                                    <p>
                                                        Payments won’t be
                                                        refunded for partial
                                                        billing periods. Cancel
                                                        anytime.
                                                    </p>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    <div className="total-box">
                                        <h5>Total {renderTotalBox()}</h5>
                                    </div>
                                </div>
                                <Form.Check
                                    className="check-term"
                                    type="checkbox"
                                    id="customControlAutosizing"
                                    custom
                                    onChange={() => {
                                        updateCheckedTerms(!checkedTerms);
                                    }}
                                    label={
                                        <Form.Label>
                                            I agree to the{" "}
                                            <a
                                                href={TERMS_OF_SERVICE_LINK}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="link"
                                            >
                                                Terms of Service
                                            </a>{" "}
                                        </Form.Label>
                                    }
                                />
                                <Button
                                    variant="primary"
                                    className="proceed"
                                    onClick={() => {
                                        if (!isTOSChecked()) return;

                                        makeTransaction();
                                    }}
                                >
                                    {/* PAY */}
                                    PAY
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Modal
                    centered
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="example-modal-sizes-title-sm"
                    className={`no-header ${
                        modalType === MODAL_SUCCESS ||
                        modalType === MODAL_RESUBSCRIPTION_SUCCESS
                            ? "primary"
                            : "danger"
                    }`}
                >
                    <Modal.Body className="text-center mt-3 mb-3">
                        <div className="modal-icon-box">
                            {renderModalIcon()}
                        </div>
                        {renderModalTitle()}
                        <p className="mb-4">{modalContent}</p>
                        {renderModalButton()}
                    </Modal.Body>
                </Modal>

                <Modal
                    centered
                    show={!transactionLoaded}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="example-modal-sizes-title-sm"
                    className="no-header"
                >
                    <Modal.Body className="text-center mt-3 mb-5 mr-4">
                        <Spinner
                            className="centered-spinner"
                            animation="border"
                            variant="primary"
                        />
                    </Modal.Body>
                </Modal>
            </ContentWrapper>
        </>
    );
};

export default Checkout;
