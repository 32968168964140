import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";

import moment from "moment";
import { TimeInputProps } from "types/TimeInput";
import { padTimeFormat } from "utils/functions";
import { Patterns } from "constant";

const convertFormatType = (unit: "HH" | "mm" | "ss") => {
    switch (unit) {
        case "HH":
            return "hours";
        case "mm":
            return "minutes";
        case "ss":
            return "seconds";
    }
};

const timeUnitLabel = (timeUnit: string) => {
    switch (timeUnit) {
        case "hours":
            return "Hour(s)";
        case "minutes":
            return "Minute(s)";
        case "seconds":
            return "Second(s)";
    }
};

const TimeInputSeparate = (props: Omit<TimeInputProps, "formatType">) => {
    const { format, value, onChange, id, timeValue, setTimeValue, ...rest } =
        props;
    const layout = format.split(":") as ("HH" | "mm" | "ss")[];

    useEffect(() => {
        const seconds = moment(
            `${timeValue.hours}:${timeValue.minutes}:${timeValue.seconds}`,
            "HH:mm:ss"
        ).toSeconds();

        onChange?.(seconds, new Event("change", { bubbles: false }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeValue.hours, timeValue.minutes, timeValue.seconds]);

    return (
        <div className="d-flex flex-row align-items-center">
            {layout.map((item) => {
                const formatKey = convertFormatType(item);
                const value = timeValue[formatKey];

                return (
                    <React.Fragment key={id}>
                        <TextField
                            label=""
                            placeholder="00"
                            type="number"
                            value={padTimeFormat(value) || ""}
                            InputLabelProps={{
                                shrink: false,
                            }}
                            onKeyUp={(e: any) => {
                                // Valid input is number only
                                !/\d+/.test(e.target.value) &&
                                    (e.target.value = "");
                            }}
                            onChange={(e) => {
                                if (
                                    ["seconds", "minutes"].includes(
                                        formatKey
                                    ) &&
                                    !Patterns.timePattern.test(
                                        padTimeFormat(e.target.value)
                                    )
                                )
                                    return;
                                const newValue: any = {
                                    [formatKey]: padTimeFormat(e.target.value),
                                };

                                setTimeValue((prev: any) => ({
                                    ...prev,
                                    ...newValue,
                                }));
                            }}
                            {...rest}
                        />
                        <span className="mx-3">{timeUnitLabel(formatKey)}</span>
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default TimeInputSeparate;
