import { Button, Modal, Form, Row, Col, Card } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MomentUtils from "@date-io/moment";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { TextField } from "@mui/material";
import moment from "moment";
import styled from "@emotion/styled";
import styledConst from "styles";
import { get, lowerCase, map, union, uniqBy, upperFirst } from "lodash";
import { getSensorConfig, downloadSensorStorage } from "service/gatewayService";
import { ForbiddenErrorMessage, HttpStatus, InputDateTimeFormat } from "constant";
import { showSuccessAlert, showErrorAlert } from "utils/alert";

export interface Props {
    show: boolean;
    panel: any;
    deviceConfig?: any;
    onClose?: () => void;
}

type Device = {
    ldsuName: string;
    name?: string;
    sensorName: string;
    id: string;
    gatewayId: string;
    deviceId: string;
    said: number;
};

const DownloadModal = styled(Modal)`
    .modal-dialog {
        max-width: 635px;
    }
`;

const FormControl = styled(Form.Group)`
    .MuiFormControl-root {
        display: flex;
        background-color: #f0f0f0 !important;

        .MuiOutlinedInput-notchedOutline {
            border: none;
        }

        .MuiOutlinedInput-input {
            color: #4c4c4c !important;
        }
    }
`;

const Box = styled(Card)`
    border: 2px solid #eaeaea;
`;

const DeviceItem = styled(Card)`
    margin-bottom: 1rem;
    border: 2px solid #eaeaea;

    .card-body {
        display: flex;
        justify-content: space-between;
    }
`;

const DeviceInfo = styled.div`
    color: ${styledConst.Primary_Blue_2};

    .subtext {
        font-size: 0.75rem;
        color: #828fab;
        opacity: 0.75;
    }
`;

const DeviceControl = styled.div`
    align-self: center;
`;

const CheckBox = styled(Form.Check)`
    .custom-control-label {
        color: ${styledConst.Primary_Blue_3};

        &::before {
            background-color: #dddddd;
            border-color: #dddddd;
        }
    }
`;

const DownloadChartModalComponent = (props: Props) => {
    const [showDeviceModal, setShowDeviceModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [startDateTime, setStartDateTime] = useState<any>(
        moment().subtract(24, "hours")
    );
    const [endDateTime, setEndDateTime] = useState<any>(moment());
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [devices, setDevices] = useState<Device[]>([]);
    const [errMsg, setErrMsg] = useState("");

    const { show, panel, onClose } = props;

    const getSourceName = () => {
        return panel ? upperFirst(lowerCase(panel.source)) : "";
    };

    const getDevicesInfo = () => {
        const { attributes } = panel;
        const promises = uniqBy(attributes, (v: any) =>
            [v.device_id, v.said].join()
        ).map(({ gateway_id, device_id, said }: any) =>
            getSensorConfig(gateway_id, device_id, said)
        );

        Promise.all(promises).then((results: any[]) => {
            const _devices = results.map((res: any, index) => {
                const { gateway_name, port, sensor_name } = get(
                    res,
                    "data.data",
                    res.data
                );

                const {
                    gateway_id: gatewayId,
                    device_id: id,
                    said,
                } = attributes[index];

                return {
                    ldsuName: `${gateway_name} - LDS BUS ${port}`,
                    sensorName: attributes[index].name,
                    name: sensor_name,
                    id: `${id}_${said}`,
                    gatewayId,
                    deviceId: id,
                    said,
                };
            });

            setSelectedIds(map(_devices, "id"));
            setDevices(_devices);
        });
    };

    const validate = () => {
        if (!endDateTime.isAfter(startDateTime)) {
            setErrMsg("End time must be after start time");
        } else if (endDateTime.isAfter(moment())) {
            setErrMsg("End time must be before future time");
        } else {
            setErrMsg("");
        }
    };

    const isDisabledDownload = () => {
        return selectedIds.length === 0 || errMsg !== "";
    };

    const handleStartDateChange = (newValue?: any) => {
        newValue && setStartDateTime(moment(newValue._d));
    };

    const handleEndDateChange = (newValue?: any) => {
        newValue && setEndDateTime(moment(newValue._d));
    };

    const handleDownloadClick = () => {
        setShowDeviceModal(false);
        setShowConfirmModal(true);
    };

    const handleConfirmClick = async () => {
        const _devices = devices
            .filter((item) => selectedIds.includes(item.id))
            .map(({ gatewayId: gateway_id, deviceId: device_id, said }) => ({
                gateway_id,
                device_id,
                said,
            }));
        const res = await downloadSensorStorage({
            start: moment(startDateTime).unix(),
            end: moment(endDateTime).unix(),
            attributes: _devices,
        });

        if (res.status === HttpStatus.CREATED) {
            handleCloseModal();
            showSuccessAlert({
                message:
                    "An email will be sent to you shortly once it is ready to download.",
            });
        } else if (res.status === HttpStatus.FORBIDDEN) {
            showErrorAlert({
                message: ForbiddenErrorMessage,
            });
        } else {
            showErrorAlert({
                message:
                    res.data?.message ||
                    "Unable to download data. Please try again.",
            });
        }
    };

    const handleCloseModal = () => {
        setShowDeviceModal(false);
        setShowConfirmModal(false);
        setSelectedIds(map(devices, "id"));
        onClose && onClose();
    };

    const handleSelectDevice = (
        e: React.ChangeEvent<HTMLInputElement>,
        device: Device | string
    ) => {
        const { checked } = e.target;
        let ids = selectedIds;
        const _device = device as Device;

        if (checked) {
            if (device === "all") {
                ids = map(devices, "id");
            } else {
                ids = union(selectedIds, [_device.id]);
            }
        } else {
            if (device === "all") {
                ids = [];
            } else {
                ids = ids.filter((id: string) => id !== _device.id);
            }
        }

        setSelectedIds(ids);
    };

    useEffect(() => {
        setShowDeviceModal(show);
    }, [show]);

    useEffect(
        () => {
            if (panel) {
                getDevicesInfo();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [panel]
    );

    useEffect(() => {
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDateTime, endDateTime]);

    return (
        <>
            <DownloadModal
                className=""
                show={showDeviceModal}
                onHide={handleCloseModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Download {getSourceName()} Data
                    </Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={handleCloseModal}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div>
                            <Form.Label className="text-primary-blue-3 mb-3">
                                Date/time Range
                            </Form.Label>
                            <Box body>
                                <LocalizationProvider dateAdapter={MomentUtils}>
                                    <Row>
                                        <Col>
                                            <FormControl>
                                                <Form.Label className="text-primary-blue-3">
                                                    From
                                                </Form.Label>
                                                <DateTimePicker
                                                    renderInput={(props) => (
                                                        <TextField
                                                            {...props}
                                                            label=""
                                                            InputProps={{
                                                                ...props.InputProps,
                                                                inputProps: {
                                                                    ...props?.inputProps,
                                                                    "aria-label":
                                                                        "start-time",
                                                                },
                                                            }}
                                                            onChange={(e) =>
                                                                handleStartDateChange(
                                                                    moment(
                                                                        e.target
                                                                            .value,
                                                                        InputDateTimeFormat
                                                                    )
                                                                )
                                                            }
                                                            InputLabelProps={{
                                                                shrink: false,
                                                            }}
                                                        />
                                                    )}
                                                    PopperProps={{
                                                        placement: "top",
                                                    }}
                                                    value={startDateTime}
                                                    inputFormat={InputDateTimeFormat}
                                                    onChange={(newValue: any) =>
                                                        handleStartDateChange(
                                                            newValue
                                                        )
                                                    }
                                                    maxDateTime={moment()}
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col>
                                            <FormControl>
                                                <Form.Label className="text-primary-blue-3">
                                                    To
                                                </Form.Label>
                                                <DateTimePicker
                                                    renderInput={(props) => (
                                                        <TextField
                                                            {...props}
                                                            label=""
                                                            InputLabelProps={{
                                                                shrink: false,
                                                            }}
                                                            InputProps={{
                                                                ...props.InputProps,
                                                                inputProps: {
                                                                    ...props?.inputProps,
                                                                    "aria-label":
                                                                        "end-time",
                                                                },
                                                            }}
                                                            onChange={(e) =>
                                                                handleEndDateChange(
                                                                    moment(
                                                                        e.target
                                                                            .value,
                                                                        InputDateTimeFormat
                                                                    )
                                                                )
                                                            }
                                                        />
                                                    )}
                                                    PopperProps={{
                                                        placement: "top",
                                                    }}
                                                    value={endDateTime}
                                                    inputFormat={InputDateTimeFormat}
                                                    onChange={(newValue: any) =>
                                                        handleEndDateChange(
                                                            newValue
                                                        )
                                                    }
                                                    minDateTime={moment(
                                                        startDateTime
                                                    )}
                                                    maxDateTime={moment()}
                                                />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {errMsg && (
                                            <Col>
                                                <p
                                                    role="alert"
                                                    className="text-danger"
                                                >
                                                    {errMsg}
                                                </p>
                                            </Col>
                                        )}
                                    </Row>
                                </LocalizationProvider>
                            </Box>
                        </div>

                        <div className="mt-4">
                            <Box border="light" className="border-2">
                                <div className="d-flex justify-content-between">
                                    <Form.Label className="text-primary-blue-3 mb-3">
                                        {getSourceName()}
                                        {panel?.source === "SENSOR" && "(s)"}
                                    </Form.Label>
                                    {devices.length > 1 && (
                                        <CheckBox
                                            custom
                                            type="checkbox"
                                            id="device-all"
                                            label="All"
                                            checked={
                                                selectedIds.length > 0 &&
                                                selectedIds.length ===
                                                    devices.length
                                            }
                                            onChange={(e) =>
                                                handleSelectDevice(e, "all")
                                            }
                                        />
                                    )}
                                </div>
                            </Box>
                            {devices.map((device, index) => (
                                <DeviceItem body key={device.id}>
                                    <DeviceInfo>
                                        <Row className="device-name">
                                            {device.name}
                                        </Row>
                                        <Row className="subtext device-ldsu">
                                            {device.ldsuName}
                                        </Row>
                                        <Row className="subtext device-id">
                                            UUID: {device.deviceId}
                                        </Row>
                                        <Row className="subtext sensor-name">
                                            {device.sensorName}
                                        </Row>
                                    </DeviceInfo>
                                    <DeviceControl>
                                        {devices.length > 1 && (
                                            <CheckBox
                                                custom
                                                type="checkbox"
                                                id={`device-${index}`}
                                                checked={selectedIds.includes(
                                                    device.id
                                                )}
                                                onChange={(e) =>
                                                    handleSelectDevice(
                                                        e,
                                                        device
                                                    )
                                                }
                                            />
                                        )}
                                    </DeviceControl>
                                </DeviceItem>
                            ))}
                        </div>
                    </Form>
                    <Button
                        variant="primary"
                        className="btn-create float-right"
                        disabled={isDisabledDownload()}
                        onClick={handleDownloadClick}
                    >
                        Download
                    </Button>
                </Modal.Body>
            </DownloadModal>
            <Modal
                centered
                show={showConfirmModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard="false"
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header primary"
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">
                        <span className="material-icons outlined">
                            help_outline
                        </span>
                    </div>
                    <h3 className="mb-3">Confirmation</h3>
                    <p className="mb-4">
                        By confirming, an email will be sent to your email
                        address with instructions on how to download. Do you
                        want to continue?
                    </p>

                    <Button variant="danger" onClick={handleCloseModal}>
                        NO
                    </Button>
                    <Button variant="primary" onClick={handleConfirmClick}>
                        YES
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
};

export const DownloadChartModal = memo(DownloadChartModalComponent);
