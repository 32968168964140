import { useEffect } from "react";
import { getAllJSON, getMetricInfo } from "service/jsonService";

const JsonPackage = () => {
    useEffect(() => {
        const fetch = async () => {
            getAllJSON();
            getMetricInfo();
        };
        fetch();
    }, []);
    return <></>;
};
export default JsonPackage;
