import { Dispatch, useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import TimeInput from "./TimeInput";
import { Actuator } from "components/gateways/actuators/ActuatorConfiguration";
import { TimeFormat } from "types/TimeInput";

export type MinuteSecondPickerProps = {
    editedActuator: Actuator;
    setEditedActuator: Dispatch<React.SetStateAction<[]>>;
    hasCycles?: boolean;
    cycleIndex?: number;
    format?: TimeFormat;
};

const MinuteSecondPicker = (props: MinuteSecondPickerProps) => {
    const {
        editedActuator: { timing },
        setEditedActuator,
        hasCycles,
        cycleIndex,
        format,
    } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentTarget, setCurrentTarget] = useState<"t1" | "t2" | null>(
        null
    );

    useEffect(() => {
        setEditedActuator((prevState) => ({
            ...prevState,
            timing: timing || [],
        }));
    }, [setEditedActuator, timing]);

    const getError = (target: "t1" | "t2") => {
        if (currentTarget === target) {
            return (timing?.[0]?.[0] || 0) + (timing?.[0]?.[1] || 0) > 60 * 60; // Condition for acceptable t1 and t2 values is that their sum is less than 3600 seconds
        }
        return false;
    };

    const renderMinuteSecondFields = () => {
        if (hasCycles) {
            const i = Number(cycleIndex) - 1;
            return (
                <>
                    <Col
                        xl={1}
                        lg={12}
                        sm={12}
                        className="timeFields minuteSecondsFieldLabel"
                    >
                        <Form.Label className="mt-2 ">
                            {cycleIndex}-T1
                        </Form.Label>
                    </Col>
                    <Col xl={4} lg={12} sm={12} className="timeFields">
                        <TimeInput
                            className="minuteSecondsField"
                            error={getError("t1")}
                            id={`time-input-t1-${cycleIndex}`}
                            format={format || "mm:ss"}
                            formatType="split"
                            value={timing?.[i]?.[0]}
                            inputProps={{
                                min: 0,
                            }}
                            onChange={(a) => {
                                if (timing) {
                                    const updatedTiming = [...timing];
                                    updatedTiming[i] = [
                                        a,
                                        timing?.[i]?.[1] ?? 0,
                                    ];
                                    setEditedActuator((prevState) => ({
                                        ...prevState,
                                        timing: updatedTiming,
                                    }));
                                }
                            }}
                        />
                    </Col>
                    <Col
                        xl={1}
                        lg={12}
                        sm={21}
                        className="minuteSecondsFieldLabel"
                    >
                        <Form.Label className="mt-2 ">
                            {cycleIndex}-T2
                        </Form.Label>
                    </Col>
                    <Col xl={4} lg={12} sm={12} className="timeFields">
                        <TimeInput
                            className="minuteSecondsField"
                            error={getError("t2")}
                            id={`time-input-t2-${cycleIndex}`}
                            format={format || "mm:ss"}
                            formatType="split"
                            value={timing?.[i]?.[1]}
                            inputProps={{
                                min: 0,
                            }}
                            onChange={(b) => {
                                if (timing) {
                                    const updatedTiming = [...timing];
                                    updatedTiming[i] = [
                                        timing?.[i]?.[0] ?? 0,
                                        b,
                                    ];
                                    setEditedActuator((prevState) => ({
                                        ...prevState,
                                        timing: updatedTiming,
                                    }));
                                }
                            }}
                        />
                    </Col>
                </>
            );
        } else {
            return (
                <>
                    <Form.Row className="mt-4 mb-1 ">
                        <Col md={1} className="minuteSecondsFieldLabel">
                            <Form.Label className="mt-2 ">T1</Form.Label>
                        </Col>
                        <Col md={7}>
                            <TimeInput
                                className="minuteSecondsField"
                                error={getError("t1")}
                                id={`time-input-t1-${cycleIndex}`}
                                format={format || "mm:ss"}
                                formatType="split"
                                value={timing?.[0]?.[0] || 0}
                                inputProps={{
                                    min: 0,
                                }}
                                onChange={(a) => {
                                    setEditedActuator((prevState) => ({
                                        ...prevState,
                                        timing: [[a, timing?.[0]?.[1] ?? 0]],
                                    }));
                                }}
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row className="mt-1 mb-1">
                        <Col md={1} className="minuteSecondsFieldLabel">
                            <Form.Label className="mt-2 ">T2</Form.Label>
                        </Col>
                        <Col md={7}>
                            <TimeInput
                                className="minuteSecondsField"
                                error={getError("t2")}
                                id={`time-input-t2-${cycleIndex}`}
                                format={format || "mm:ss"}
                                formatType="split"
                                value={timing?.[0]?.[1] || 0}
                                inputProps={{
                                    min: 0,
                                }}
                                onChange={(b) => {
                                    setEditedActuator((prevState) => ({
                                        ...prevState,
                                        timing: [[timing?.[0]?.[0] ?? 0, b]],
                                    }));
                                }}
                            />
                        </Col>
                    </Form.Row>
                </>
            );
        }
    };

    return <>{renderMinuteSecondFields()}</>;
};

export default MinuteSecondPicker;
