import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { get, trim } from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import DashboardCreateModal, { DashboardCreateModalProps } from ".";
import { useCreateDashboardStore } from "..";
import { createSingleSensorPanel } from "service/dashboardService";
import { HttpStatus } from "constant";
import { showErrorAlert } from "utils/alert";
import { displaySensorType } from "utils/functions";

const CreateChartModal: React.FunctionComponent<DashboardCreateModalProps> = (
    props
) => {
    const { show } = props;
    const { createData, dashboardId } = useCreateDashboardStore();
    const [createdList, setCreatedList] = React.useState<any[]>([]);
    const history = useHistory();

    React.useEffect(() => {
        if (createData && createData.data.length && show) {
            setCreatedList([]);

            (async () => {
                for await (const data of createData.data) {
                    const chartData = createData.transform?.(data) as any;

                    const res: any = await createSingleSensorPanel(
                        dashboardId,
                        {
                            ...chartData,
                            name: trim(chartData.name.replace(/\s+/g, " ")),
                        }
                    );

                    if (res.status === HttpStatus.CREATED) {
                        setCreatedList((prev) => [...prev, data]);
                    } else {
                        showErrorAlert({
                            message: get(res, "data.description", ""),
                        });
                    }
                }
            })();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    if (!createData) {
        return <></>;
    }

    const getColumnData = ({ chartData, column }: any) => {
        if (column.key === "sensor_type") {
            return displaySensorType(chartData);
        }
        return get(chartData, column.key);
    };

    return (
        <DashboardCreateModal
            title={createData.title || "Create Chart"}
            dialogClassName="wide-modal"
            {...props}
        >
            <Row className="mt-4 cstm-table">
                <Col sm={12}>
                    <div className="table-head px-3 border-0">
                        <Row className="no-checkbox text-gray">
                            {createData.columns.map((column, index) => (
                                <Col key={index}>{column.name}</Col>
                            ))}
                            <Col md={{ span: 1 }}></Col>
                        </Row>
                    </div>

                    {createData.data.map((chartData: any, index: number) => {
                        return (
                            <div key={index} className="table-row px-3">
                                <Row className="no-checkbox align-items-center">
                                    {createData.columns.map((column, index) => (
                                        <Col key={index}>
                                            {column.render
                                                ? column.render(chartData)
                                                : getColumnData({
                                                      chartData,
                                                      column,
                                                  })}
                                        </Col>
                                    ))}
                                    <Col
                                        md={{ span: 1 }}
                                        className="d-flex justify-content-end align-items-center green"
                                    >
                                        {createdList.length - 1 >= index ? (
                                            <span className="material-icons">
                                                check_circle
                                            </span>
                                        ) : (
                                            <CircularProgress
                                                variant="indeterminate"
                                                size={20}
                                                thickness={6}
                                                color="inherit"
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        );
                    })}
                </Col>
            </Row>
            <Row className="mt-4">
                <Col
                    sm={12}
                    className="d-flex justify-content-center align-items-center"
                >
                    <div className="green d-flex align-items-center">
                        {createdList.length < createData.data.length ? (
                            <CircularProgress
                                variant="determinate"
                                size={24}
                                thickness={6}
                                value={Math.ceil(
                                    (createdList.length /
                                        createData.data.length) *
                                        100
                                )}
                                color="inherit"
                            />
                        ) : (
                            <span className="material-icons fs-38">
                                check_circle
                            </span>
                        )}
                    </div>
                    <strong className="text-gray ml-2">
                        {createdList.length}/{createData.data.length} chart(s)
                        are successfully created ...
                    </strong>
                </Col>

                {createdList.length === createData.data.length && (
                    <Col
                        sm={12}
                        className="d-flex justify-content-center align-items-center mt-3"
                    >
                        <Button
                            variant="primary"
                            onClick={() => {
                                useCreateDashboardStore.setState({
                                    createData: undefined,
                                });

                                history.push(`/dashboard`);
                            }}
                        >
                            OK
                        </Button>
                    </Col>
                )}
            </Row>
        </DashboardCreateModal>
    );
};

export default React.memo(CreateChartModal);
