import { Form } from "react-bootstrap";
import { useActuatorConfigurationContext } from "context/ActuatorConfigurationContext";
import { DeactivateOption } from "constant";
import { DeactivateOptions } from "./DeactivateOptions";
import ActuatorCount from "./ActuatorCount";
import PolarityOptions from "./PolarityOptions";
import CyclesInput from "./CyclesInput";

export const PWM = () => {
    const {
        editedActuator: { mode },
    } = useActuatorConfigurationContext();

    return (
        <>
            <PolarityOptions />
            <CyclesInput />

            <hr />
            <Form.Label className="mt-3">Number of cycles</Form.Label>
            <ActuatorCount />

            <DeactivateOptions options={DeactivateOption[`${mode}`]} />
        </>
    );
};

export default PWM;
