import { useState, useEffect, FC } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { get, size, omit, keyBy } from "lodash";
import { useCreateDashboardStore, ColorPicker } from ".";
import DashboardCreateSelectSensorModal from "./modal/SelectDevices";

import { COLORS } from "constant";
import { MultipleChartColumnProps } from "types/Charts";
import { getSensorTypes } from "utils/gatewayFunctions";
import { SensorType } from "types";
import { displaySensorType } from "utils/functions";

const maxDevicesAllowed = 8;

export const MultipleChartColumn: FC<MultipleChartColumnProps> = (props) => {
    const { column, onChange, data, isEdit = false } = props;

    const [showSelectModal, setShowSelectModal] = useState(false);
    const [selectedDevices, setSelectedDevices] = useState<any>({});

    const [maxDevices, setMaxDevices] = useState<number>(maxDevicesAllowed);
    const [sensorTypeSelector, setSensorTypeSelector] = useState<SensorType[]>(
        []
    );

    const [sensorType, setSensorType] = useState<string>(
        get(data, `data[${column}].sensor_type`, "") ||
            get(sensorTypeSelector, `[${column}].id`, "")
    );
    const [eventTrigger, setEventTrigger] = useState<any>();
    const [otherSensorType, setOtherSensorType] = useState<string>("");
    const [canDelete, setCanDelete] = useState(true);
    const { deviceList } = useCreateDashboardStore();

    const getSensorTypeName = (type: string) => {
        return get(keyBy(sensorTypeSelector, "id"), `[${type}].name`, "");
    };

    useEffect(() => {
        !sensorTypeSelector.length &&
            setSensorTypeSelector(
                getSensorTypes(
                    deviceList.filter((device) => !device.isActuator)
                )
            );

        if (isEdit) {
            const total: number = Object.keys(get(data, "data", {})).reduce(
                (current: any, key) => {
                    current += size(get(data, `data.${key}.devices`, {}));
                    return current;
                },
                0
            );

            setCanDelete(total > 1);
        }

        Object.keys(get(data, "data", {}))
            .filter((key) => Number(key) !== column)
            .forEach((key) => {
                setMaxDevices(
                    maxDevicesAllowed -
                        size(get(data, `data.${key}.devices`, {}))
                );

                setSensorTypeSelector(
                    getSensorTypes(
                        deviceList.filter((device) => !device.isActuator)
                    )
                );
            });

        setSelectedDevices(data.data[column]?.devices || {});

        setSensorType(
            get(data, `data[${column}].sensor_type`, "") ||
                get(sensorTypeSelector, `[${column}].id`, "")
        );

        if (data.multipleChartType === "different") {
            const otherColumn = Number(column) === 0 ? 1 : 0;

            setOtherSensorType(
                get(data, `data[${otherColumn}].sensor_type`, "") ||
                    get(sensorTypeSelector, `[${otherColumn}].id`, "")
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, deviceList, sensorTypeSelector.toString()]);

    useEffect(() => {
        eventTrigger &&
            onChange({
                devices: selectedDevices,
                sensor_type: sensorType,
            });
        setEventTrigger(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDevices]);

    return (
        <Col sm={12} lg={6}>
            <div className="multiple-chart-block__panel rounded p-3">
                <p>
                    {"Select sensor type {column}".fill({
                        column:
                            data.multipleChartType === "same"
                                ? ""
                                : String(column + 1),
                    })}
                </p>

                <Form.Control
                    as="select"
                    value={sensorType}
                    onChange={(event) => {
                        setSelectedDevices({});
                        setSensorType(event.target.value);
                        setEventTrigger(event);
                    }}
                    aria-label="Select sensor type"
                >
                    {sensorTypeSelector.map(({ id, name }) => (
                        <option
                            key={id}
                            value={id}
                            disabled={[otherSensorType, sensorType].includes(
                                id
                            )}
                        >
                            {name}
                        </option>
                    ))}
                </Form.Control>

                <p className="mt-4">
                    {getSensorTypeName(sensorType)} sensors on this chart
                </p>
                <Button
                    variant="primary"
                    onClick={() => setShowSelectModal(true)}
                >
                    Select Sensors
                </Button>
                <DashboardCreateSelectSensorModal
                    show={showSelectModal}
                    onHide={() => setShowSelectModal(false)}
                    values={selectedDevices}
                    onSave={(event, devices) => {
                        let dataDevices: any[] = [];
                        Object.values(data.data).forEach((column) => {
                            dataDevices = [
                                ...dataDevices,
                                ...Object.values(column.devices),
                            ];
                        });
                        let newDeviceCount = 0;

                        for (let device_id in devices) {
                            devices[device_id].color =
                                devices[device_id].color ||
                                COLORS[dataDevices?.length + newDeviceCount++];
                        }
                        setSelectedDevices(devices);
                        setEventTrigger(event);
                    }}
                    deviceType="Sensor"
                    sensorTypes={[sensorType]}
                    maxDevices={maxDevices}
                />

                <div className="cstm-table mt-4">
                    {size(selectedDevices) !== 0 && (
                        <div className="table-head" key="head">
                            <Row className="no-checkbox">
                                <Col>Sensor</Col>
                                <Col>Type</Col>
                                <Col md={{ span: 2 }}>Color</Col>
                                <Col md={{ span: 1 }}></Col>
                            </Row>
                        </div>
                    )}

                    {Object.keys(selectedDevices).map((key, index: number) => {
                        const sensor = selectedDevices[key];
                        return (
                            <div key={key} className="table-row">
                                <Row className="no-checkbox">
                                    <Col
                                        className={`d-flex align-items-center ${
                                            !sensor.sensor_type
                                                ? "text-danger"
                                                : ""
                                        }`}
                                    >
                                        {!sensor.sensor_type && (
                                            <span className="material-icons mr-2">
                                                error_outline
                                            </span>
                                        )}
                                        {selectedDevices[key].device_name ??
                                            "Information not found"}
                                    </Col>
                                    <Col className="d-flex align-items-center">
                                        {displaySensorType(
                                            selectedDevices[key]
                                        ) ?? selectedDevices["undefined"].name}
                                    </Col>
                                    <Col md={{ span: 2 }}>
                                        <ColorPicker
                                            style={{
                                                backgroundColor: "#242D41",
                                            }}
                                            defaultValue={
                                                selectedDevices[key].color ||
                                                COLORS[index]
                                            }
                                            onChange={(event, color) => {
                                                setEventTrigger(event);
                                                setSelectedDevices(
                                                    (prev: any) => ({
                                                        ...prev,
                                                        [key]: {
                                                            ...prev[key],
                                                            color,
                                                        },
                                                    })
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col
                                        md={{ span: 1 }}
                                        className="d-flex justify-content-end align-items-center"
                                    >
                                        {canDelete && (
                                            <i
                                                className="material-icons cursor-pointer mr-2 remove-button-red"
                                                onClick={(event) => {
                                                    setSelectedDevices(
                                                        omit(
                                                            selectedDevices,
                                                            key
                                                        )
                                                    );
                                                    setEventTrigger(event);
                                                }}
                                            >
                                                close
                                            </i>
                                        )}
                                    </Col>
                                </Row>
                            </div>
                        );
                    })}
                </div>
            </div>
        </Col>
    );
};
