import React, { memo, useState } from "react";
import { GridStack, GridStackOptions } from "gridstack";
import "assets/css/gridstack.css";
import { debounce } from "lodash";

export const CELL_HEIGHT = 4;
export const GAP = 8;
export const GRIDSTACK_COLUMNS = 12; // 12 columns in gridstack

export const loadGridStack = (
    element: string,
    options: { [key: string]: any } = {}
): Promise<GridStack> => {
    return new Promise((resolve) => {
        const ele = document?.querySelector(element) as any;

        const loaded = setInterval(() => {
            if (ele) {
                clearInterval(loaded);
                const gridGap = options?.gap ?? GAP;
                delete options?.gap;
                const reRender = options?.reRender;
                delete options?.reRender;
                options.cellHeight = options?.cellHeight || CELL_HEIGHT + "px";
                options.margin = gridGap / 2;

                const gridOptions: GridStackOptions = {
                    resizable: {},
                    removable: false,
                    disableResize: true,
                    ...options,
                };

                const setGsHeight = (ele: HTMLElement) => {
                    if (!ele.getAttribute("gs-h") && reRender !== false) {
                        let gsH = Math.ceil(
                            (ele.offsetHeight + gridGap) /
                                parseInt(options.cellHeight || CELL_HEIGHT)
                        );

                        if (options.setItemHeight) {
                            gsH =
                                options.setItemHeight(ele, options) /
                                CELL_HEIGHT;
                        }

                        ele.setAttribute("gs-h", String(gsH));
                        const childEle = ele.firstElementChild as HTMLElement;
                        childEle.style.height = `${
                            gsH * parseInt(options.cellHeight || CELL_HEIGHT) -
                            gridGap
                        }px`;
                    }
                };

                ele?.querySelectorAll(".grid-stack-item").forEach(setGsHeight);
                let grid: GridStack;
                try {
                    grid = GridStack.addGrid(ele, gridOptions);
                } catch (error) {
                    return;
                }

                const elementSorting = () => {
                    const data: GridStackDataProps[] = [];
                    const elementList: {
                        [key: number]: HTMLElement;
                    } = {};

                    for (const item of ele.querySelectorAll?.(
                        ".grid-stack-item"
                    ) || []) {
                        elementList[Number(item.getAttribute("gs-y"))] =
                            item as HTMLElement;
                        data.push({
                            id: item.id,
                            w: Number(item.getAttribute("gs-w")),
                            h: Number(item.getAttribute("gs-h")),
                            x: Number(item.getAttribute("gs-x")),
                            y: Number(item.getAttribute("gs-y")),
                        });
                    }

                    // Re-render element sorted
                    if (reRender !== false) {
                        ele.innerHTML = "";
                        Object.values(elementList).forEach((item) =>
                            ele.appendChild(item)
                        );
                    }

                    return data;
                };

                const gridChangeHandler = debounce(
                    (event: Event, items?: any) => {
                        options?.onChange &&
                            options?.onChange({
                                data: elementSorting(),
                                event,
                                items,
                                gridstack: grid,
                            });
                    },
                    200
                );

                grid.on("change", gridChangeHandler);
                grid.on("dropped", gridChangeHandler);
                grid.on("removed", gridChangeHandler);

                resolve(grid);
            }
        }, 10);
    });
};

type GridStackDataProps = {
    id: number | string;
    w: number;
    h: number;
    x: number;
    y: number;
    [key: string]: any;
};

export type GridStackChangeEvent = {
    data: GridStackDataProps[];
    event: Event;
    items: any;
    gridstack: GridStack;
};

type GridStackElementProps = {
    id: string;
    itemCount: number;
    cellHeight?: number;
    gap?: number;
    options?: { [key: string]: any };
    setItemHeight?: (element: HTMLElement, options: any) => void;
    onChange?: (result: GridStackChangeEvent) => void;
    onResizeStop?: (event: Event, items?: any) => void;
    [key: string]: any;
};

const GridStackElement: React.FunctionComponent<GridStackElementProps> = (
    props
) => {
    const {
        id,
        itemCount,
        cellHeight,
        gap,
        options,
        setItemHeight,
        onChange,
        children,
        className,
        reRender,
        onResizeStop,
        onInit,
        ...rest
    } = props;

    const [grid, setGrid] = useState<GridStack>();
    const [numberItems, setNumberItems] = useState<number>(0);

    React.useEffect(() => {
        if (numberItems !== itemCount) {
            grid?.destroy(false);
            setNumberItems(itemCount);
        }
        if (id && itemCount > 0) {
            loadGridStack("#" + id, {
                ...options,
                reRender,
                cellHeight,
                gap,
                onChange,
                onResizeStop,
            }).then((grid) => setGrid(grid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, itemCount, className]);

    return (
        <div id={id} className={`grid-stack ${className || ""}`} {...rest}>
            {children}
        </div>
    );
};

// By old dashboard panel size logic
export const getItemWidth = (screenWidth: number, defaultWidth?: number) => {
    let columns: 1 | 2 | 3 | 4 = 1;

    if (screenWidth > 1800) {
        columns = 4;
    } else if (screenWidth > 1400) {
        columns = 3;
    } else if (screenWidth > 768) {
        columns = 2;
    }

    const itemWidth = GRIDSTACK_COLUMNS / columns;

    return defaultWidth ? Math.max(itemWidth, defaultWidth) : itemWidth;
};

export default memo(GridStackElement);
