import { capitalize } from "lodash";
import { BillingAddressProp } from "types/Subscription";
import { showErrorAlert } from "./alert";
import {
    MaxBillingAddressChar,
    MinBillingAddressChar,
    Patterns,
    SUBSCRIPTION_DATE_FORMAT,
} from "constant";
import moment from "moment";

export const renderPlanStatus = (subscriptionStatus: string) => {
    if (subscriptionStatus === "ACTIVE") {
        return (
            <span className="default-green-color">
                {capitalize(subscriptionStatus)}
            </span>
        );
    } else if (["CANCELED", "EXPIRED"].includes(subscriptionStatus)) {
        return (
            <span className="default-red-color">
                {capitalize(subscriptionStatus)}
            </span>
        );
    } else if (subscriptionStatus === "PAST_DUE") {
        return <span className="default-red-color">Past Due</span>;
    }
};

export const initBillingAddress = () => {
    return {
        first_name: "",
        last_name: "",
        street: "",
        extended: "",
        line3: "",
        city: "",
        code: "",
        country: "",
        region: "",
    };
};

const renderErrModal = (message: string) => {
    showErrorAlert({ message: message });
};

const meetBillingAddressCriteria = (billingAddress: any, field: string) => {
    if (
        !Patterns.asciiPattern.test(billingAddress[field]) ||
        billingAddress?.[field]?.trim().length < MinBillingAddressChar ||
        billingAddress?.[field]?.trim().length > MaxBillingAddressChar
    )
        return false;
    return true;
};

export const isBillingAddressFormValid = (
    billingAddress: BillingAddressProp
) => {
    if (!meetBillingAddressCriteria(billingAddress, "first_name")) {
        renderErrModal(
            `First name should consist of ${MinBillingAddressChar} to ${MaxBillingAddressChar} ASCII characters only.`
        );
        return false;
    }

    if (!meetBillingAddressCriteria(billingAddress, "last_name")) {
        renderErrModal(
            `Last name should consist of ${MinBillingAddressChar} to ${MaxBillingAddressChar} ASCII characters only.`
        );
        return false;
    }

    if (!meetBillingAddressCriteria(billingAddress, "street")) {
        renderErrModal(
            `Street should consist of ${MinBillingAddressChar} to ${MaxBillingAddressChar} ASCII characters only.`
        );
        return false;
    }

    if (
        billingAddress?.extended?.trim() &&
        !meetBillingAddressCriteria(billingAddress, "extended")
    ) {
        renderErrModal(
            `Building should not exceed ${MaxBillingAddressChar} ASCII characters.`
        );
        return false;
    }

    if (!meetBillingAddressCriteria(billingAddress, "line3")) {
        renderErrModal(
            `Floor, Unit should consist of ${MinBillingAddressChar} to ${MaxBillingAddressChar} ASCII characters only.`
        );
        return false;
    }

    if (!meetBillingAddressCriteria(billingAddress, "city")) {
        renderErrModal(
            `City should consist of ${MinBillingAddressChar} to ${MaxBillingAddressChar} ASCII characters only.`
        );
        return false;
    }

    if (!Patterns.zipCodePattern.test(billingAddress?.code)) {
        renderErrModal(
            "Zip/postal code should consist of 4 - 9 valid characters."
        );
        return false;
    }

    if (!billingAddress?.country?.trim()) {
        renderErrModal("Please select a country.");
        return false;
    }
    return true;
};

export const subscriptionPeriodDetail = (
    billing_start_date: any,
    billing_cycles: number
) => {
    return (
        <>
            {billing_start_date} -{" "}
            {moment()
                .add(billing_cycles, "M")
                .subtract(1, "days")
                .format(SUBSCRIPTION_DATE_FORMAT)}{" "}
            {`(${billing_cycles} ${billing_cycles > 1 ? "Months" : "Month"})`}
        </>
    );
};
