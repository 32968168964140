import React, { useState, useReducer, useEffect, useMemo } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { styled } from "@mui/system";

import "utils/authorize-action";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import { AuthContext } from "context/auth";
import { AppContext } from "context/appContext";

import Home from "pages/Home";
import SignUp from "pages/SignUp";
import ForgotPassword from "pages/ForgotPassword";
import NotFound from "pages/NotFound";
import About from "pages/About";
import LoadingPage from "pages/Loading";

import Dashboard from "pages/Dashboard";

import {
    AllGateway,
    GatewayGroups,
    Standalone,
} from "pages/gateways/GatewayList";
import GatewayGroupDetails from "pages/gateways/GatewayGroupDetails";
import GatewayDetails from "pages/gateways/GatewayDetails";
import AddNewGroup from "pages/gateways/AddNewGroup";
import LDSBus from "pages/gateways/LDSBus";
import Uart from "pages/gateways/Uart";
import LdsuDetails from "pages/gateways/LdsuDetails";
import SensorDetails from "pages/gateways/SensorDetails";
import ActuatorDetails from "pages/gateways/ActuatorDetails";
import AddGateWay from "pages/gateways/AddGateway";

import ChangePassword from "pages/profiles/ChangePassword";
import UserProfile from "pages/profiles/UserProfile";
import Invitations from "pages/profiles/invitations/Invitations";

import OrganizationDetails from "pages/organizations/OrganizationDetails";
import EditOrganization from "pages/organizations/EditOrganization";
import InviteUsers from "pages/organizations/InviteMembers";
import NewGroup from "pages/organizations/NewGroup";
import NewRole from "pages/organizations/NewRole";
import MembersDetails from "pages/organizations/MembersDetails";
import GroupsDetails from "pages/organizations/GroupsDetails";
import RolesDetails from "pages/organizations/RolesDetails";

import EventsList from "pages/events/EventList";
import EventDetails from "pages/events/EventDetails";
import ConditionDetails from "pages/events/ConditionDetails";
import ActionDetails from "pages/events/ActionDetails";

import Subscriptions from "pages/subscriptions/Subscriptions";
import Checkout from "pages/subscriptions/Checkout";
import CheckoutBillingAddress from "pages/subscriptions/CheckoutBillingAddress";
import ManageSubscription from "pages/subscriptions/ManageSubscription";

import JsonPackage from "components/JsonPackage";

import LocalStorageService from "service/localStorageService";
import AppReducer from "store/reducer";

import DashboardCreate from "./components/dashboard/create";
import DashboardUpdate from "./components/dashboard/update";
import DashboardPanelUpdate from "./components/dashboard/update/single";
import "react-toastify/dist/ReactToastify.css";
import styledConst from "styles";
import SubscriptionProvider from "context/subscriptionContext";
import AccountSettings from "pages/profiles/AccountSettings";
import TerminateAccount, {
    TerminateSuccess,
} from "pages/profiles/terminate-account";
import DownloadData from "pages/profiles/DownloadData";
import Settings from "pages/settings/Settings";
import ManageAccessKeys from "pages/settings/ManageAccessKeys";
import DefaultAppState from "store/state";

export const StyledToastContainer = styled(ToastContainer)`
    .Toastify__toast-body {
        .description {
            color: ${styledConst.Primary_Blue_4};
            font-size: 11px;
            b {
                color: ${styledConst.Primary_Blue_3};
            }
            word-break: break-word;
        }
        align-items: flex-start;
    }

    .Toastify__toast-icon {
        padding-top: 2px;
    }

    .Toastify__toast--error {
        background: ${styledConst.errorBackground};
        .Toastify__toast-body {
            align-items: flex-start;

            .content {
                margin-top: -5px;
            }

            .description {
                margin-top: 0.6rem;
            }

            .title,
            svg {
                color: ${styledConst.Secondary_Red};
                font-size: 14px;
            }
        }
        .Toastify__close-button {
            color: ${styledConst.Secondary_Red};
        }
    }

    .Toastify__toast--success {
        background: ${styledConst.successBackground};
        .Toastify__toast-body {
            .title,
            svg {
                color: ${styledConst.Primary_Green_1};
                font-size: 14px;
            }
        }
        .Toastify__close-button {
            color: ${styledConst.Primary_Green_1};
        }
    }

    .Toastify__toast--info {
        background: ${styledConst.infoBackground};
        .Toastify__toast-body {
            .title,
            svg {
                color: ${styledConst.Primary_Blue};
                font-size: 14px;
            }
        }
        .Toastify__close-button {
            color: ${styledConst.Primary_Blue};
        }
    }
`;

const checkIsNotLoad = () => {
    const ret = ["listOfJSON", "metricInfo"].find((k) => {
        return !LocalStorageService.getItem(k);
    });
    return ret === undefined ? false : !!ret;
};

const App = () => {
    const existingTokens = LocalStorageService.getItem("tokens") || "";
    const [authTokens, setAuthTokens] = useState(existingTokens);
    const [state, dispatch] = useReducer(AppReducer, DefaultAppState);
    const store = React.useMemo(() => [state, dispatch], [state]);
    const [isResourceLoaded, setIsResourceLoaded] = useState(!checkIsNotLoad());

    const setTokens = (data: string) => {
        LocalStorageService.setItem("tokens", data);
        setAuthTokens(data);
    };

    useEffect(() => {
        const handleStoreChange = (event: any) => {
            if (event.key === "lastLoggedOrgId") {
                window.location.reload();
            }
            const isNotLoad = checkIsNotLoad();
            setIsResourceLoaded(!isNotLoad);
        };
        window.addEventListener("storage", handleStoreChange, true);
        return () => {
            window.removeEventListener("storage", handleStoreChange);
        };
    });

    const memoizedTokenObject = useMemo(
        () => ({ authTokens, setAuthTokens: setTokens }),
        [authTokens]
    );

    const memoizedStoreObject = useMemo(
        () => ({ storeData: store[0], storeDispatchActions: store[1] }),
        [store]
    );

    return (
        <AuthContext.Provider value={memoizedTokenObject}>
            <AppContext.Provider value={memoizedStoreObject}>
                <SubscriptionProvider>
                    <StyledToastContainer />
                    <JsonPackage />
                    <Router>
                        <Switch>
                            <PublicRoute path="/sign-up" component={SignUp} />
                            <PublicRoute
                                path="/forgot-password"
                                component={ForgotPassword}
                            />

                            {!isResourceLoaded && (
                                <Route component={LoadingPage} />
                            )}

                            <PrivateRoute
                                exact
                                path="/dashboard"
                                component={Dashboard}
                            />
                            <PrivateRoute
                                exact
                                path="/dashboard/:dashboardId/new-charts"
                                component={DashboardCreate}
                            />

                            <PrivateRoute
                                exact
                                path="/dashboard/:dashboardId/update"
                                component={DashboardUpdate}
                            />

                            <PrivateRoute
                                exact
                                path="/dashboard/:dashboardId/update/:panelId"
                                component={DashboardPanelUpdate}
                            />
                            <PrivateRoute
                                path="/gateways-all"
                                component={AllGateway}
                            />
                            <PrivateRoute
                                path="/gateways"
                                component={Standalone}
                            />
                            <PrivateRoute
                                path="/gateways-add"
                                component={AddGateWay}
                            />
                            <PrivateRoute
                                path="/gateways-group"
                                component={GatewayGroups}
                            />
                            <PrivateRoute
                                path="/new-group"
                                component={AddNewGroup}
                            />
                            <PrivateRoute
                                exact
                                path="/gateway-details/:gatewayId"
                                component={GatewayDetails}
                            />
                            <PrivateRoute
                                path="/gate-group-details/:groupId"
                                component={GatewayGroupDetails}
                            />
                            <PrivateRoute
                                exact
                                path="/gateway-details/:gatewayId/LDSBus/:busNumber"
                                component={LDSBus}
                            />
                            <PrivateRoute
                                path="/gateway-details/:gatewayId/uart"
                                component={Uart}
                            />
                            <PrivateRoute
                                path="/gateway-details/:gatewayId/LDSBus/:busNumber/:ldsuId"
                                component={LdsuDetails}
                            />
                            <PrivateRoute
                                path="/sensor-details/:gatewayId/LDSBus/:busNumber/:ldsuId/:said"
                                component={SensorDetails}
                            />
                            <PrivateRoute
                                path="/actuator-details/:gatewayId/:busNumber/:ldsuId/:said"
                                component={ActuatorDetails}
                            />

                            <PrivateRoute
                                path="/events"
                                component={EventsList}
                            />
                            <PrivateRoute
                                exact
                                path="/event-details/:eventId"
                                component={EventDetails}
                            />
                            <PrivateRoute
                                path="/event-details/:eventId/condition/:condition/:conditionId?"
                                component={ConditionDetails}
                            />
                            <PrivateRoute
                                path="/event-details/:eventId/action/:action/:actionId?"
                                component={ActionDetails}
                            />

                            <PrivateRoute
                                path="/create-organisation"
                                component={EditOrganization}
                            />
                            <PrivateRoute
                                path="/edit-organisation/:orgId"
                                component={EditOrganization}
                            />
                            <PrivateRoute
                                path="/invite-users/:orgId"
                                component={InviteUsers}
                            />
                            <PrivateRoute
                                path="/new-org-group/:orgId"
                                component={NewGroup}
                            />
                            <PrivateRoute
                                path="/new-org-role/:orgId"
                                component={NewRole}
                            />
                            <PrivateRoute
                                path="/organisation/:orgId/member/:memberId"
                                component={MembersDetails}
                            />
                            <PrivateRoute
                                path="/organisation/:orgId/group/:groupId"
                                component={GroupsDetails}
                            />
                            <PrivateRoute
                                path="/organisation/:orgId/role/:roleId"
                                component={RolesDetails}
                            />
                            <PrivateRoute
                                path="/organisation/:orgId"
                                component={OrganizationDetails}
                            />
                            <PrivateRoute
                                exact
                                path="/settings"
                                component={Settings}
                            />
                            <PrivateRoute
                                path="/settings/manage-api-access-keys"
                                component={ManageAccessKeys}
                            />
                            <PrivateRoute path="/about" component={About} />

                            <PrivateRoute
                                path="/manage-subscription"
                                component={Subscriptions}
                            />
                            <PrivateRoute
                                path="/manage-subscription-details"
                                component={ManageSubscription}
                            />

                            <PrivateRoute
                                exact
                                path="/checkout"
                                component={Checkout}
                            />
                            <PrivateRoute
                                path="/checkout/billing-address"
                                component={CheckoutBillingAddress}
                            />

                            <PrivateRoute
                                path="/user/settings"
                                component={AccountSettings}
                            />
                            <PrivateRoute
                                path="/user/profile"
                                component={UserProfile}
                            />
                            <PrivateRoute
                                path="/user/change-password"
                                component={ChangePassword}
                            />
                            <PrivateRoute
                                path="/user/invitations"
                                component={Invitations}
                            />
                            <PrivateRoute
                                path="/user/terminate"
                                component={TerminateAccount}
                            />
                            <Route
                                path="/user/terminate-success"
                                component={TerminateSuccess}
                            />
                            <PrivateRoute
                                path="/user/download-data"
                                component={DownloadData}
                            />
                            <PublicRoute path="/" component={Home} />
                            <Route component={NotFound} />
                        </Switch>
                    </Router>
                </SubscriptionProvider>
            </AppContext.Provider>
        </AuthContext.Provider>
    );
};

export default App;
