import { useState, useEffect, isValidElement } from 'react'

export const useIntersection = (element: { current: Element; }, rootMargin: any) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            }, { rootMargin }
        );

        element.current && observer.observe(element.current);
        
        if (isValidElement(element?.current)) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            return () => observer.unobserve(element?.current);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isVisible;
};
// https://www.webtips.dev/webtips/react-hooks/element-in-viewport
