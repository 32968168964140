import { InputGroup, FormControl, Form } from "react-bootstrap";
import _ from "lodash";

import { TimeScheduleTemplate } from "components/events/ConditionDetail/TimeScheduleTemplate";
import { RangeOfRecurrenceTemplate } from "components/modals/RangeOfRecurrenceTemplate";

import "assets/css/event.css";
import { HelpText } from "components/modals/EventModals/HelpTextModal";
import { EventConfigLimit, Patterns } from "constant";
import { ChangeEvent } from "react";

const Weekly = ({
    priTime,
    setPriTime,
    secTime,
    setSecTime,
    timeList,
    setTimeList,
    timeRangeList,
    setTimeRangeList,
    selectedInterval,
    setSelectedInterval,
    duration,
    setDuration,
    selectedTimeOption,
    setSelectedTimeOption,
    errorType,
    setErrorType,
    checkboxState,
    setCheckboxState,
    repeatInterval,
    setRepeatInterval,
    setRecurrenceRange,
    recurrenceRange,
    renderErrorMsg,
}: any) => {
    const SUNDAY = "sunday";
    const MONDAY = "monday";
    const TUESDAY = "tuesday";
    const WEDNESDAY = "wednesday";
    const THURSDAY = "thursday";
    const FRIDAY = "friday";
    const SATURDAY = "saturday";

    const onChangeCheckbox = (day: any) => {
        switch (day) {
            case SUNDAY:
                setCheckboxState({
                    ...checkboxState,
                    sunday: !checkboxState.sunday,
                });
                break;
            case MONDAY:
                setCheckboxState({
                    ...checkboxState,
                    monday: !checkboxState.monday,
                });
                break;
            case TUESDAY:
                setCheckboxState({
                    ...checkboxState,
                    tuesday: !checkboxState.tuesday,
                });
                break;
            case WEDNESDAY:
                setCheckboxState({
                    ...checkboxState,
                    wednesday: !checkboxState.wednesday,
                });
                break;
            case THURSDAY:
                setCheckboxState({
                    ...checkboxState,
                    thursday: !checkboxState.thursday,
                });
                break;
            case FRIDAY:
                setCheckboxState({
                    ...checkboxState,
                    friday: !checkboxState.friday,
                });
                break;
            case SATURDAY:
                setCheckboxState({
                    ...checkboxState,
                    saturday: !checkboxState.saturday,
                });
                break;
        }
    };

    const renderDayOfWeek = (day: any) => {
        let checkBoxState: any;

        switch (day) {
            case SUNDAY:
                checkBoxState = checkboxState.sunday;
                break;
            case MONDAY:
                checkBoxState = checkboxState.monday;
                break;
            case TUESDAY:
                checkBoxState = checkboxState.tuesday;
                break;
            case WEDNESDAY:
                checkBoxState = checkboxState.wednesday;
                break;
            case THURSDAY:
                checkBoxState = checkboxState.thursday;
                break;
            case FRIDAY:
                checkBoxState = checkboxState.friday;
                break;
            case SATURDAY:
                checkBoxState = checkboxState.saturday;
                break;
        }

        return (
            <div className="weekday">
                <div
                    className={
                        checkBoxState ? "weekday-option show" : "weekday-option"
                    }
                >
                    <Form.Check
                        name="selected-gateway"
                        id={day}
                        custom
                        type="switch"
                        checked={checkBoxState}
                        onChange={() => {
                            onChangeCheckbox(day);
                        }}
                        label={
                            <span className="ml-4">{_.capitalize(day)}</span>
                        }
                    />
                </div>
                <div
                    className={
                        checkBoxState ? "weekday-box show" : "weekday-box"
                    }
                >
                    <TimeScheduleTemplate
                        priTime={priTime}
                        setPriTime={setPriTime}
                        secTime={secTime}
                        setSecTime={setSecTime}
                        selectedTimeOption={selectedTimeOption}
                        setSelectedTimeOption={setSelectedTimeOption}
                        selectedInterval={selectedInterval}
                        setSelectedInterval={setSelectedInterval}
                        duration={duration}
                        setDuration={setDuration}
                        timeList={timeList}
                        setTimeList={setTimeList}
                        timeRangeList={timeRangeList}
                        setTimeRangeList={setTimeRangeList}
                        errorType={errorType}
                        setErrorType={setErrorType}
                        day={day}
                        renderErrorMsg={renderErrorMsg}
                        recurrence={"weekly"}
                    />
                </div>
            </div>
        );
    };

    const handleRepeatFieldInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (
            e.target.value &&
            !Patterns.repeatWeeklyPattern.test(e.target.value)
        )
            return;
        setRepeatInterval({
            ...repeatInterval,
            week: e.target.value,
        });
    };

    return (
        <>
            <div className="form-box mb-3">
                <h5 className="mb-4">Repeat Every</h5>
                <InputGroup className="mt-2">
                    <FormControl
                        value={repeatInterval.week}
                        onChange={handleRepeatFieldInput}
                    />
                    <InputGroup.Text>Week(s)</InputGroup.Text>
                </InputGroup>
                <HelpText
                    min={1}
                    max={EventConfigLimit.REPEAT_WEEKLY_MAX}
                    timeUnit="week"
                />

                {renderErrorMsg("weekly", "repeat")}
            </div>
            <div className="form-box mb-3">
                <h5 className="mb-4">On Every</h5>
                {renderDayOfWeek(SUNDAY)}
                {renderDayOfWeek(MONDAY)}
                {renderDayOfWeek(TUESDAY)}
                {renderDayOfWeek(WEDNESDAY)}
                {renderDayOfWeek(THURSDAY)}
                {renderDayOfWeek(FRIDAY)}
                {renderDayOfWeek(SATURDAY)}
            </div>
            <RangeOfRecurrenceTemplate
                recurrence={"weekly"}
                recurrenceRange={recurrenceRange}
                setRecurrenceRange={setRecurrenceRange}
                renderErrorMsg={renderErrorMsg}
            />
        </>
    );
};

export default Weekly;
