import { useState, useEffect } from "react";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { Container, Row, Col, Breadcrumb, Button, Form } from "react-bootstrap";

import Daily from "./Daily";
import Weekly from "./Weekly";
import Monthly from "./Monthly";
import Yearly from "./Yearly";

import moment, { Moment } from "moment";
import momentTZ from "moment-timezone";
import { isEmpty } from "lodash";
import EventService from "service/eventService";
import DefaultModal from "components/modals/ModalTemplate";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import {
    DaysOfWeek,
    MonthsMap,
    TimeSchedule,
    DAILY,
    WEEKLY,
    MONTHLY,
    YEARLY,
    ERROR_NOT_POSITIVE_INTEGER,
    ERROR_NO_INTERVAL,
    ERROR_INVALID_DATE,
    ERROR_NO_DURATION,
    ERROR_INVALID,
    ERROR_EMPTY,
    ERROR_INCORRECT_VALUE,
    HttpStatus,
    ERROR_INVALID_BEFORE_AFTER_DATE,
    START_OF_DAY_AT_FIRST_MINUTE,
    ERROR_BEFORE_OR_AFTER,
} from "constant";

import "assets/css/event.css";
interface StartTime {
    default: Moment;
    sunday: Moment;
    monday: Moment;
    tuesday: Moment;
    wednesday: Moment;
    thursday: Moment;
    friday: Moment;
    saturday: Moment;
}
interface TimeArray {
    [key: string]: Array<string>;
}

const Recurrence = () => {
    const params: any = useParams();
    const history: any = useHistory();
    const location: any = useLocation();

    const selectedOperator = location?.state?.selectedOperator;
    const slotCount = location?.state?.slotCount;
    const selectedSlotIndex = location?.state?.selectedSlotIndex;

    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [errorArray, setErrorArray]: any = useState([]);
    const [isPageLoading, updateIsPageLoading] = useState(true);
    const [invalidBeforeAfterDateMsg, setInvalidBeforeAfterDateMsg] =
        useState("");

    // IN CURRENT COMPONENT
    const [selectedRepeatOption, setSelectedRepeatOption] = useState(DAILY);
    const [repeatInterval, setRepeatInterval]: any = useState({
        day: "",
        week: "",
        month: "",
        year: "",
    });
    const [currentRecurrenceDetail, setCurrentRecurrenceDetail]: any = useState(
        {}
    );

    // FOR DAILY AND WEEKLY
    const [selectedTimeOption, setSelectedTimeOption]: any = useState({
        default: TimeSchedule.ALL_DAY,
        sunday: TimeSchedule.ALL_DAY,
        monday: TimeSchedule.ALL_DAY,
        tuesday: TimeSchedule.ALL_DAY,
        wednesday: TimeSchedule.ALL_DAY,
        thursday: TimeSchedule.ALL_DAY,
        friday: TimeSchedule.ALL_DAY,
        saturday: TimeSchedule.ALL_DAY,
    });

    const defaultStartTime: StartTime = {
        default: START_OF_DAY_AT_FIRST_MINUTE,
        sunday: START_OF_DAY_AT_FIRST_MINUTE,
        monday: START_OF_DAY_AT_FIRST_MINUTE,
        tuesday: START_OF_DAY_AT_FIRST_MINUTE,
        wednesday: START_OF_DAY_AT_FIRST_MINUTE,
        thursday: START_OF_DAY_AT_FIRST_MINUTE,
        friday: START_OF_DAY_AT_FIRST_MINUTE,
        saturday: START_OF_DAY_AT_FIRST_MINUTE,
    };

    const [priTime, setPriTime] = useState<any>(defaultStartTime);

    const [secTime, setSecTime] = useState<any>(defaultStartTime);

    const [selectedInterval, setSelectedInterval]: any = useState({
        default: "hours",
        sunday: "hours",
        monday: "hours",
        tuesday: "hours",
        wednesday: "hours",
        thursday: "hours",
        friday: "hours",
        saturday: "hours",
    });

    const [duration, setDuration]: any = useState({
        default: "",
        sunday: "",
        monday: "",
        tuesday: "",
        wednesday: "",
        thursday: "",
        friday: "",
        saturday: "",
    });

    const defaultTimeArray: TimeArray = {
        default: [],
        sunday: [],
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
    };

    const [timeList, setTimeList] = useState<TimeArray>(defaultTimeArray);
    const [timeRangeList, setTimeRangeList] =
        useState<TimeArray>(defaultTimeArray);

    const [errorType, setErrorType]: any = useState({
        day: "",
        errorType: "",
    });
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);
    const [checkboxState, setCheckboxState]: any = useState({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
    });

    // FOR MONTHLY AND YEARLY
    const [allDates, setAllDates]: any = useState([]);

    // RANGE OF RECURRENCE
    const [recurrenceRange, setRecurrenceRange] = useState<any>({
        daily: {
            startDate: moment().startOf("day"),
            endDate: moment().startOf("day"),
            endDateEnabled: false,
        },
        weekly: {
            startDate: moment().startOf("day"),
            endDate: moment().startOf("day"),
            endDateEnabled: false,
        },
        monthly: {
            startDate: moment().startOf("day"),
            endDate: moment().startOf("day"),
            endDateEnabled: false,
        },
        yearly: {
            startDate: moment().startOf("day"),
            endDate: moment().startOf("day"),
            endDateEnabled: false,
        },
    });

    // PROPS TO PASS TO CHILDREN
    const updatePriTime = (priTime: any) => {
        setPriTime(priTime);
    };

    const updateSecTime = (secTime: any) => {
        setSecTime(secTime);
    };

    const updateSelectedTimeOption = (selectedTimeOption: any) => {
        setError({});
        setErrorType({});
        setSelectedTimeOption(selectedTimeOption);
    };

    const updateDuration = (duration: any) => {
        setDuration(duration);
    };

    const updateSelectedInterval = (interval: any) => {
        setSelectedInterval(interval);
    };

    const updateTimeList = (timeList: any) => {
        setTimeList(timeList);
    };

    const updateTimeRangeList = (timeRangeList: any) => {
        setTimeRangeList(timeRangeList);
    };

    const updateErrorType = (errorType: any) => {
        setErrorType(errorType);
    };

    const updateRepeatInterval = (interval: any) => {
        setRepeatInterval(interval);
    };

    const updateCheckboxState = (newCheckboxState: any) => {
        setCheckboxState(newCheckboxState);
    };

    const updateAllDates = (allDates: any) => {
        setAllDates(allDates);
    };

    const updateRecurrenceRange = (recurrenceRange: any) => {
        setRecurrenceRange(recurrenceRange);
    };

    const [error, setError]: any = useState({
        component: "",
        errorType: "",
        day: "",
        month: "",
    });

    useEffect(() => {
        if (params.conditionId) {
            const fetchCondition = async () => {
                const response = await EventService.readOneCondition(
                    params.conditionId,
                    params.eventId
                );

                const {
                    resource: {
                        date2: {
                            repeat = "",
                            interval = 0,
                            daily: {
                                ranges = [],
                                points = [],
                                schedule = "",
                                interval: daily_time_schedule_interval = 0,
                            } = {},
                            weekly: { weekdays = {} } = {},
                            monthly = {},
                            yearly = {},
                        } = {},
                    } = {},
                } = response.data || {};

                if (response.status === HttpStatus.OK) {
                    // Set Current Slot
                    setCurrentRecurrenceDetail(response.data);
                    // Set operator (daily, weekly, monthly ...)
                    setSelectedRepeatOption(repeat);

                    // Depending on operator ( Set repeated interval and time schedule)

                    switch (repeat) {
                        case DAILY:
                            setRepeatInterval({
                                ...repeatInterval,
                                day: interval,
                            });
                            setSelectedTimeOption({
                                ...selectedTimeOption,
                                default: schedule,
                            });

                            switch (schedule) {
                                case TimeSchedule.BEFORE:
                                    setPriTime({
                                        ...defaultStartTime,
                                        default: moment(
                                            ranges[0][1],
                                            "HH:mm:ss"
                                        ),
                                    });
                                    break;
                                case TimeSchedule.AFTER:
                                    setPriTime({
                                        ...defaultStartTime,
                                        default: moment(
                                            ranges[0][0],
                                            "HH:mm:ss"
                                        ),
                                    });
                                    break;
                                case TimeSchedule.BEFORE_OR_AFTER:
                                    setPriTime({
                                        ...defaultStartTime,
                                        default: moment(
                                            ranges[0][1],
                                            "HH:mm:ss"
                                        ),
                                    });
                                    setSecTime({
                                        ...defaultStartTime,
                                        default: moment(
                                            ranges[1][0],
                                            "HH:mm:ss"
                                        ),
                                    });
                                    break;
                                case TimeSchedule.EVERY:
                                    setDuration({
                                        default: daily_time_schedule_interval,
                                    });

                                    break;
                                case TimeSchedule.AT:
                                    setTimeList({
                                        ...defaultTimeArray,
                                        default: points,
                                    });
                                    break;
                                case TimeSchedule.BETWEEN:
                                    setTimeRangeList({
                                        ...defaultTimeArray,
                                        default: ranges,
                                    });
                                    break;
                            }

                            const dailyEndDateEnabled =
                                "end" in response.data.resource.date2;
                            const dailyStartDate =
                                moment(
                                    response.data.resource.date2.start,
                                    "YYYY-MM-DD"
                                ) || moment().startOf("day");
                            const dailyEndDate = dailyEndDateEnabled
                                ? moment(
                                      response.data.resource.date2.end,
                                      "YYYY-MM-DD"
                                  )
                                : moment().startOf("day");

                            setRecurrenceRange({
                                ...recurrenceRange,
                                daily: {
                                    ...recurrenceRange[DAILY],
                                    startDate: dailyStartDate,

                                    endDate: dailyEndDate,

                                    endDateEnabled: dailyEndDateEnabled,
                                },
                            });

                            break;

                        case WEEKLY:
                            setRepeatInterval({
                                ...repeatInterval,
                                week: interval,
                            });

                            const newCheckboxObj: any = { ...checkboxState };
                            const newTimeOptionObj: any = {
                                ...selectedTimeOption,
                            };
                            const newPriTimeObj: any = { ...priTime };
                            const newSecTimeObj: any = { ...secTime };
                            const newTimeListObj: any = { ...timeList };
                            const newTimeRangeListObj: any = {
                                ...timeRangeList,
                            };
                            const newDurationObj: any = { ...duration };

                            let t: any;
                            for (t in weekdays) {
                                const { schedule, points, ranges, interval } =
                                    weekdays[t];

                                newCheckboxObj[DaysOfWeek[t - 1]] = true;
                                newTimeOptionObj[DaysOfWeek[t - 1]] = schedule;

                                switch (schedule) {
                                    case TimeSchedule.BEFORE:
                                        newPriTimeObj[DaysOfWeek[t - 1]] =
                                            moment(ranges[0][1], "HH:mm:ss");
                                        break;
                                    case TimeSchedule.AFTER:
                                        newPriTimeObj[DaysOfWeek[t - 1]] =
                                            moment(ranges[0][0], "HH:mm:ss");
                                        break;
                                    case TimeSchedule.BEFORE_OR_AFTER:
                                        newPriTimeObj[DaysOfWeek[t - 1]] =
                                            moment(ranges[0][1], "HH:mm:ss");
                                        newSecTimeObj[DaysOfWeek[t - 1]] =
                                            moment(ranges[1][0], "HH:mm:ss");
                                        break;
                                    case TimeSchedule.AT:
                                        newTimeListObj[DaysOfWeek[t - 1]] =
                                            points;

                                        break;
                                    case TimeSchedule.BETWEEN:
                                        newTimeRangeListObj[DaysOfWeek[t - 1]] =
                                            ranges;
                                        break;
                                    case TimeSchedule.EVERY:
                                        newDurationObj[DaysOfWeek[t - 1]] =
                                            interval;
                                        break;
                                }
                            }

                            setCheckboxState(newCheckboxObj);
                            setSelectedTimeOption(newTimeOptionObj);
                            setPriTime(newPriTimeObj);
                            setSecTime(newSecTimeObj);
                            setTimeList(newTimeListObj);
                            setTimeRangeList(newTimeRangeListObj);
                            setDuration(newDurationObj);

                            const weeklyEndDateEnabled =
                                "end" in response.data.resource.date2;
                            const weeklyStartDate =
                                moment(
                                    response.data.resource.date2.start,
                                    "YYYY-MM-DD"
                                ) || moment().startOf("day");
                            const weeklyEndDate = weeklyEndDateEnabled
                                ? moment(
                                      response.data.resource.date2.end,
                                      "YYYY-MM-DD"
                                  )
                                : moment().startOf("day");

                            setRecurrenceRange({
                                ...recurrenceRange,
                                weekly: {
                                    ...recurrenceRange[WEEKLY],
                                    startDate: weeklyStartDate,

                                    endDate: weeklyEndDate,

                                    endDateEnabled: weeklyEndDateEnabled,
                                },
                            });

                            break;

                        case MONTHLY:
                            const allExistingDates: any = [];
                            let priTimeMonthly: any = moment().startOf("day");
                            let secTimeMonthly: any = moment().startOf("day");
                            let timeListMonthly: any = [];
                            let timeRangeListMonthly: any = [];
                            let durationMonthly;

                            setRepeatInterval({
                                ...repeatInterval,
                                month: interval,
                            });
                            for (const d in monthly.days) {
                                const { ranges, points, schedule, interval } =
                                    monthly.days[d];

                                switch (schedule) {
                                    case TimeSchedule.BEFORE:
                                        priTimeMonthly = moment(
                                            ranges[0][1],
                                            "HH:mm:ss"
                                        );

                                        break;
                                    case TimeSchedule.AFTER:
                                        priTimeMonthly = moment(
                                            ranges[0][0],
                                            "HH:mm:ss"
                                        );

                                        break;

                                    case TimeSchedule.BEFORE_OR_AFTER:
                                        priTimeMonthly = moment(
                                            ranges[0][1],
                                            "HH:mm:ss"
                                        );
                                        secTimeMonthly = moment(
                                            ranges[1][0],
                                            "HH:mm:ss"
                                        );

                                        break;
                                    case TimeSchedule.BETWEEN:
                                        timeRangeListMonthly = ranges;
                                        break;
                                    case TimeSchedule.AT:
                                        timeListMonthly = points;
                                        break;
                                    case TimeSchedule.EVERY:
                                        durationMonthly = interval;
                                        break;
                                }

                                const dateObj = {
                                    type: "monthly_recurrence:day",
                                    day: d,
                                    selectedTimeOption: schedule,
                                    priTime: priTimeMonthly,
                                    secTime: secTimeMonthly,
                                    timeList: timeListMonthly,
                                    timeRangeList: timeRangeListMonthly,
                                    duration: durationMonthly,
                                };
                                allExistingDates.push(dateObj);
                            }

                            for (const d in monthly.weekdays) {
                                const { ranges, points, schedule, interval } =
                                    monthly.weekdays[d];
                                switch (schedule) {
                                    case TimeSchedule.BEFORE:
                                        priTimeMonthly = moment(
                                            ranges[0][1],
                                            "HH:mm:ss"
                                        );

                                        break;
                                    case TimeSchedule.AFTER:
                                        priTimeMonthly = moment(
                                            ranges[0][0],
                                            "HH:mm:ss"
                                        );

                                        break;

                                    case TimeSchedule.BEFORE_OR_AFTER:
                                        priTimeMonthly = moment(
                                            ranges[0][1],
                                            "HH:mm:ss"
                                        );
                                        secTimeMonthly = moment(
                                            ranges[1][0],
                                            "HH:mm:ss"
                                        );

                                        break;
                                    case TimeSchedule.BETWEEN:
                                        timeRangeListMonthly = ranges;
                                        break;
                                    case TimeSchedule.AT:
                                        timeListMonthly = points;
                                        break;
                                    case TimeSchedule.EVERY:
                                        durationMonthly = interval;
                                        break;
                                }

                                const dateObj = {
                                    type: "monthly_recurrence:firstLast",
                                    day: d,
                                    selectedTimeOption: schedule,
                                    priTime: priTimeMonthly,
                                    secTime: secTimeMonthly,
                                    timeList: timeListMonthly,
                                    timeRangeList: timeRangeListMonthly,
                                    duration: durationMonthly,
                                };
                                allExistingDates.push(dateObj);
                            }
                            setAllDates(allExistingDates);

                            const monthlyEndDateEnabled =
                                "end" in response.data.resource.date2;
                            const monthlyStartDate =
                                moment(
                                    response.data.resource.date2.start,
                                    "YYYY-MM-DD"
                                ) || moment().startOf("day");
                            const monthlyEndDate = monthlyEndDateEnabled
                                ? moment(
                                      response.data.resource.date2.end,
                                      "YYYY-MM-DD"
                                  )
                                : moment().startOf("day");
                            setRecurrenceRange({
                                ...recurrenceRange,
                                monthly: {
                                    ...recurrenceRange[MONTHLY],
                                    startDate: monthlyStartDate,

                                    endDate: monthlyEndDate,

                                    endDateEnabled: monthlyEndDateEnabled,
                                },
                            });

                            break;

                        case YEARLY:
                            const allExistingYearlyDates: any = [];
                            let priTimeYearly: any = moment().startOf("day");
                            let secTimeYearly: any = moment().startOf("day");
                            let timeListYearly: any = [];
                            let timeRangeListYearly: any = [];
                            let durationYearly;
                            setRepeatInterval({
                                ...repeatInterval,
                                year: interval,
                            });

                            for (const m in yearly.months) {
                                for (const d in yearly.months[m].days) {
                                    const {
                                        months: {
                                            [m]: {
                                                days: {
                                                    [d]: {
                                                        points = null,
                                                        ranges = null,
                                                        schedule = "",
                                                    } = {},
                                                } = {},
                                            } = {},
                                        },
                                    } = yearly;
                                    switch (schedule) {
                                        case TimeSchedule.BEFORE:
                                            priTimeYearly = moment(
                                                ranges[0][1],
                                                "HH:mm:ss"
                                            );

                                            break;
                                        case TimeSchedule.AFTER:
                                            priTimeYearly = moment(
                                                ranges[0][0],
                                                "HH:mm:ss"
                                            );

                                            break;

                                        case TimeSchedule.BEFORE_OR_AFTER:
                                            priTimeYearly = moment(
                                                ranges[0][1],
                                                "HH:mm:ss"
                                            );
                                            secTimeYearly = moment(
                                                ranges[1][0],
                                                "HH:mm:ss"
                                            );

                                            break;
                                        case TimeSchedule.BETWEEN:
                                            timeRangeListYearly = ranges;
                                            break;
                                        case TimeSchedule.AT:
                                            timeListYearly = points;
                                            break;
                                        case TimeSchedule.EVERY:
                                            durationYearly = interval;
                                            break;
                                    }

                                    const dateObj = {
                                        type: "yearly_recurrence:day",
                                        day: d,
                                        month: MonthsMap[m].toLowerCase(),
                                        selectedTimeOption: schedule,
                                        priTime: priTimeYearly,
                                        secTime: secTimeYearly,
                                        timeList: timeListYearly,
                                        timeRangeList: timeRangeListYearly,
                                        duration: durationYearly,
                                    };

                                    allExistingYearlyDates.push(dateObj);
                                }

                                for (const d in yearly.months[m].weekdays) {
                                    const {
                                        months: {
                                            [m]: {
                                                weekdays: {
                                                    [d]: {
                                                        ranges = null,
                                                        schedule = "",
                                                    } = {},
                                                } = {},
                                            } = {},
                                        },
                                    } = yearly;

                                    switch (schedule) {
                                        case TimeSchedule.BEFORE:
                                            priTimeYearly = moment(
                                                ranges[0][1],
                                                "HH:mm:ss"
                                            );

                                            break;
                                        case TimeSchedule.AFTER:
                                            priTimeYearly = moment(
                                                ranges[0][0],
                                                "HH:mm:ss"
                                            );

                                            break;

                                        case TimeSchedule.BEFORE_OR_AFTER:
                                            priTimeYearly = moment(
                                                ranges[0][1],
                                                "HH:mm:ss"
                                            );
                                            secTimeYearly = moment(
                                                ranges[1][0],
                                                "HH:mm:ss"
                                            );

                                            break;
                                        case TimeSchedule.BETWEEN:
                                            timeRangeListYearly = ranges;
                                            break;
                                        case TimeSchedule.AT:
                                            timeListYearly = points;
                                            break;
                                        case TimeSchedule.EVERY:
                                            durationYearly = interval;
                                            break;
                                    }

                                    const dateObj = {
                                        type: "yearly_recurrence:firstLast",
                                        day: d,
                                        month: MonthsMap[m].toLowerCase(),
                                        selectedTimeOption: schedule,
                                        priTime: priTimeYearly,
                                        secTime: secTimeYearly,
                                        timeList: timeListYearly,
                                        timeRangeList: timeRangeListYearly,
                                        duration: durationYearly,
                                    };

                                    allExistingYearlyDates.push(dateObj);
                                }
                            }
                            setAllDates(allExistingYearlyDates);

                            const yearlyEndDateEnabled =
                                "end" in response.data.resource.date2;
                            const yearlyStartDate =
                                moment(
                                    response.data.resource.date2.start,
                                    "YYYY-MM-DD"
                                ) || moment().startOf("day");
                            const yearlyEndDate = yearlyEndDateEnabled
                                ? moment(
                                      response.data.resource.date2.end,
                                      "YYYY-MM-DD"
                                  )
                                : moment().startOf("day");

                            setRecurrenceRange({
                                ...recurrenceRange,
                                yearly: {
                                    ...recurrenceRange[YEARLY],
                                    startDate: yearlyStartDate,

                                    endDate: yearlyEndDate,

                                    endDateEnabled: yearlyEndDateEnabled,
                                },
                            });

                            break;
                    }
                    updateIsPageLoading(false);
                }
            };
            fetchCondition();
        } else {
            updateIsPageLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderErrorMsg = (
        component: any,
        field: any,
        day?: any,
        month?: any
    ) => {
        if (component === error.component) {
            switch (field) {
                case "repeat":
                    switch (error.errorType) {
                        case ERROR_NO_INTERVAL:
                            return (
                                <Col sm="12" className="mt-2">
                                    <p className="text-danger">
                                        Please enter an interval.
                                    </p>
                                </Col>
                            );
                        case ERROR_NOT_POSITIVE_INTEGER:
                            return (
                                <Col sm="12" className="mt-2">
                                    <p className="text-danger">
                                        Please enter a whole number that is
                                        greater than 0.
                                    </p>
                                </Col>
                            );
                    }
                    break;
                case "range":
                    switch (error.errorType) {
                        case ERROR_INVALID_DATE:
                            return (
                                <Col sm="12" className="mt-2">
                                    <p className="text-danger">
                                        Invalid date format.
                                    </p>
                                </Col>
                            );
                    }
                    break;

                case "timeSchedule":
                    if (month) {
                        if (error.month === month && error.day === day) {
                            switch (error.errorType) {
                                case ERROR_NO_DURATION:
                                    return (
                                        <Col sm="12" className="mt-2">
                                            <p className="text-danger">
                                                {ERROR_NO_DURATION}
                                            </p>
                                        </Col>
                                    );

                                case ERROR_INVALID:
                                    return (
                                        <Col sm="12" className="mt-2">
                                            <p className="text-danger">
                                                Invalid time format.
                                            </p>
                                        </Col>
                                    );
                                case ERROR_EMPTY:
                                    return (
                                        <Col sm="12" className="mt-2">
                                            <p className="text-danger">
                                                Please set a time schedule.
                                            </p>
                                        </Col>
                                    );
                                case ERROR_INCORRECT_VALUE:
                                    return (
                                        <Col sm="12" className="mt-2">
                                            <p className="text-danger">
                                                The value needs to be at least 5
                                                seconds.
                                            </p>
                                        </Col>
                                    );
                                case ERROR_INVALID_BEFORE_AFTER_DATE:
                                    return (
                                        <Col sm="12" className="mt-2">
                                            <p className="text-danger">
                                                {invalidBeforeAfterDateMsg}
                                            </p>
                                        </Col>
                                    );
                                case ERROR_BEFORE_OR_AFTER:
                                    return (
                                        <Col sm="12" className="mt-2">
                                            <p className="text-danger">
                                                {ERROR_BEFORE_OR_AFTER}
                                            </p>
                                        </Col>
                                    );
                            }
                            break;
                        }
                    } else if (error.day === day) {
                        switch (error.errorType) {
                            case ERROR_NO_DURATION:
                                return (
                                    <Col sm="12" className="mt-2">
                                        <p className="text-danger">
                                            {ERROR_NO_DURATION}
                                        </p>
                                    </Col>
                                );

                            case ERROR_INVALID:
                                return (
                                    <Col sm="12" className="mt-2">
                                        <p className="text-danger">
                                            Invalid time format.
                                        </p>
                                    </Col>
                                );
                            case ERROR_EMPTY:
                                return (
                                    <Col sm="12" className="mt-2">
                                        <p className="text-danger">
                                            Please set a time schedule.
                                        </p>
                                    </Col>
                                );
                            case ERROR_INCORRECT_VALUE:
                                return (
                                    <Col sm="12" className="mt-2">
                                        <p className="text-danger">
                                            The value needs to be at least 5
                                            seconds.
                                        </p>
                                    </Col>
                                );
                            case ERROR_INVALID_BEFORE_AFTER_DATE:
                                return (
                                    <Col sm="12" className="mt-2">
                                        <p className="text-danger">
                                            {invalidBeforeAfterDateMsg}
                                        </p>
                                    </Col>
                                );
                            case ERROR_BEFORE_OR_AFTER:
                                return (
                                    <Col sm="12" className="mt-2">
                                        <p className="text-danger">
                                            {ERROR_BEFORE_OR_AFTER}
                                        </p>
                                    </Col>
                                );
                        }
                        break;
                    }
            }
        }
    };

    const handleAddUpdateCondition = async () => {
        let response: any;
        let range: any;
        let repeatedOption: any;
        let repeatFieldInterval: any;
        setInvalidBeforeAfterDateMsg("");

        let slot = 0;
        if (selectedOperator === "AND") {
            slot = selectedSlotIndex;
        } else if (selectedOperator === "OR") {
            slot = slotCount;
        }

        switch (selectedRepeatOption) {
            case DAILY:
                repeatedOption = DAILY;
                repeatFieldInterval = "day";

                break;
            case WEEKLY:
                repeatedOption = WEEKLY;
                repeatFieldInterval = "week";
                break;
            case MONTHLY:
                repeatedOption = MONTHLY;
                repeatFieldInterval = "month";
                break;
            case YEARLY:
                repeatedOption = YEARLY;
                repeatFieldInterval = "year";
                break;
        }

        // ERROR VALIDATION
        if (!repeatInterval[repeatFieldInterval]) {
            setError({
                component: selectedRepeatOption,
                errorType: ERROR_NO_INTERVAL,
            });
            return;
        }

        if (
            !Number.isInteger(Number(repeatInterval[repeatFieldInterval])) ||
            Number(repeatInterval[repeatFieldInterval]) < 1
        ) {
            setError({
                component: selectedRepeatOption,
                errorType: ERROR_NOT_POSITIVE_INTEGER,
            });
            return;
        }

        if (
            recurrenceRange[selectedRepeatOption].endDateEnabled &&
            recurrenceRange[selectedRepeatOption].startDate >=
                recurrenceRange[selectedRepeatOption].endDate
        ) {
            setModalShow(true);
            setModalType("err");
            setModalContent(`"End by" date should be later than "Start" date.`);
            return;
        }

        switch (selectedRepeatOption) {
            case DAILY:
                switch (selectedTimeOption.default) {
                    case TimeSchedule.EVERY:
                        if (!duration.default) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_NO_DURATION,
                                day: "default",
                            });
                            return;
                        }

                        if (
                            selectedInterval.default === "seconds" &&
                            Number(duration.default) < 5
                        ) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_INCORRECT_VALUE,
                                day: "default",
                            });
                            return;
                        }
                        break;
                    case TimeSchedule.BEFORE:
                    case TimeSchedule.AFTER:
                    case TimeSchedule.BEFORE_OR_AFTER:
                        setInvalidBeforeAfterDateMsg("");
                        if (
                            !moment(
                                priTime.default.format("HH:mm:ss"),
                                "HH:mm:ss"
                            ).isAfter(moment().startOf("day")) ||
                            !moment(
                                secTime.default.format("HH:mm:ss"),
                                "HH:mm:ss"
                            ).isAfter(moment().startOf("day"))
                        ) {
                            if (
                                selectedTimeOption.default ===
                                TimeSchedule.BEFORE
                            ) {
                                setInvalidBeforeAfterDateMsg(
                                    "The time entered for 'Before' must be later than 12:00 AM"
                                );
                            }

                            if (
                                selectedTimeOption.default ===
                                TimeSchedule.AFTER
                            ) {
                                setInvalidBeforeAfterDateMsg(
                                    "The time entered for 'After' must be later than 12:00 AM"
                                );
                            }

                            if (
                                selectedTimeOption.default ===
                                TimeSchedule.BEFORE_OR_AFTER
                            ) {
                                setInvalidBeforeAfterDateMsg(
                                    "The time entered for 'Before' and 'After' must be later than 12:00 AM"
                                );
                            }

                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_INVALID_BEFORE_AFTER_DATE,
                                day: "default",
                            });
                            return;
                        }

                        if (
                            selectedTimeOption.default ===
                                TimeSchedule.BEFORE_OR_AFTER &&
                            priTime.default.isSameOrAfter(secTime.default)
                        ) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_BEFORE_OR_AFTER,
                                day: "default",
                            });
                            return;
                        }

                        if (
                            !priTime.default._isValid ||
                            !secTime.default._isValid
                        ) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_INVALID,
                                day: "default",
                            });
                            return;
                        }
                        break;
                    case TimeSchedule.AT:
                        if (!timeList.default.length) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_EMPTY,
                                day: "default",
                            });
                            return;
                        }
                        break;
                    case TimeSchedule.BETWEEN:
                        if (!timeRangeList.default.length) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_EMPTY,
                                day: "default",
                            });
                            return;
                        }
                        break;
                }
                break;
            case WEEKLY:
                const checked = [];

                const list = [
                    "sunday",
                    "monday",
                    "tuesday",
                    "wednesday",
                    "thursday",
                    "friday",
                    "saturday",
                ];
                for (let d = 0; d < list.length; d++) {
                    if (checkboxState[list[d]]) {
                        checked.push(list[d]);
                    }
                    switch (selectedTimeOption[list[d]]) {
                        case TimeSchedule.EVERY:
                            if (checkboxState[list[d]]) {
                                if (!duration[list[d]]) {
                                    setError({
                                        component: selectedRepeatOption,
                                        errorType: ERROR_NO_DURATION,
                                        day: list[d],
                                    });
                                    return;
                                }
                                if (
                                    selectedInterval[list[d]] === "seconds" &&
                                    Number(duration[list[d]]) < 5
                                ) {
                                    setError({
                                        component: selectedRepeatOption,
                                        errorType: ERROR_INCORRECT_VALUE,
                                        day: list[d],
                                    });
                                    return;
                                }
                            }
                            break;
                        case TimeSchedule.BEFORE:
                        case TimeSchedule.AFTER:
                        case TimeSchedule.BEFORE_OR_AFTER:
                            setInvalidBeforeAfterDateMsg("");
                            if (checkboxState[list[d]]) {
                                if (
                                    !moment(
                                        priTime[list[d]].format("HH:mm:ss"),
                                        "HH:mm:ss"
                                    ).isAfter(moment().startOf("day")) &&
                                    selectedTimeOption[list[d]] ===
                                        TimeSchedule.BEFORE
                                ) {
                                    setInvalidBeforeAfterDateMsg(
                                        "The time entered for 'Before' must be later than 12:00 AM"
                                    );

                                    setError({
                                        component: selectedRepeatOption,
                                        errorType:
                                            ERROR_INVALID_BEFORE_AFTER_DATE,
                                        day: list[d],
                                    });
                                    return;
                                }

                                if (
                                    !moment(
                                        priTime[list[d]].format("HH:mm:ss"),
                                        "HH:mm:ss"
                                    ).isAfter(moment().startOf("day")) &&
                                    selectedTimeOption[list[d]] ===
                                        TimeSchedule.AFTER
                                ) {
                                    setInvalidBeforeAfterDateMsg(
                                        "The time entered for 'After' must be later than 12:00 AM"
                                    );

                                    setError({
                                        component: selectedRepeatOption,
                                        errorType:
                                            ERROR_INVALID_BEFORE_AFTER_DATE,
                                        day: list[d],
                                    });
                                    return;
                                }

                                if (
                                    (!moment(
                                        priTime[list[d]].format("HH:mm:ss"),
                                        "HH:mm:ss"
                                    ).isAfter(moment().startOf("day")) ||
                                        !moment(
                                            secTime[list[d]].format("HH:mm:ss"),
                                            "HH:mm:ss"
                                        ).isAfter(moment().startOf("day"))) &&
                                    selectedTimeOption[list[d]] ===
                                        TimeSchedule.BEFORE_OR_AFTER
                                ) {
                                    setInvalidBeforeAfterDateMsg(
                                        "The time entered for 'Before' and 'After' must be later than 12:00 AM"
                                    );

                                    setError({
                                        component: selectedRepeatOption,
                                        errorType:
                                            ERROR_INVALID_BEFORE_AFTER_DATE,
                                        day: list[d],
                                    });
                                    return;
                                }

                                if (
                                    selectedTimeOption[list[d]] ===
                                        TimeSchedule.BEFORE_OR_AFTER &&
                                    priTime[list[d]].isSameOrAfter(
                                        secTime[list[d]]
                                    )
                                ) {
                                    setError({
                                        component: selectedRepeatOption,
                                        errorType: ERROR_BEFORE_OR_AFTER,
                                        day: list[d],
                                    });
                                    return;
                                }

                                if (
                                    !priTime[list[d]]._isValid ||
                                    !secTime[list[d]]._isValid
                                ) {
                                    setError({
                                        component: selectedRepeatOption,
                                        errorType: ERROR_INVALID,
                                        day: list[d],
                                    });
                                    return;
                                }
                            }
                            break;
                        case TimeSchedule.AT:
                            if (checkboxState[list[d]]) {
                                if (!timeList[list[d]].length) {
                                    setError({
                                        component: selectedRepeatOption,
                                        errorType: ERROR_EMPTY,
                                        day: list[d],
                                    });
                                    return;
                                }
                            }
                            break;
                        case TimeSchedule.BETWEEN:
                            if (checkboxState[list[d]]) {
                                if (!timeRangeList[list[d]].length) {
                                    setError({
                                        component: selectedRepeatOption,
                                        errorType: ERROR_EMPTY,
                                        day: list[d],
                                    });
                                    return;
                                }
                            }
                            break;
                    }
                }
                if (checked.length === 0) {
                    setModalShow(true);
                    setModalType("err");
                    setModalContent("Please select a recurring day.");
                    return;
                }
                break;
            case MONTHLY:
                const monthlyDates = allDates.filter((m: any) => {
                    return m.type.includes("monthly");
                });

                for (let d = 0; d < monthlyDates.length; d++) {
                    if (
                        monthlyDates[d].selectedTimeOption ===
                        TimeSchedule.EVERY
                    ) {
                        if (!monthlyDates[d].duration) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_NO_DURATION,
                                day: monthlyDates[d].day,
                            });
                            return;
                        }

                        if (
                            monthlyDates[d].selectedInterval === "seconds" &&
                            Number(monthlyDates[d].duration) < 5
                        ) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_INCORRECT_VALUE,
                                day: monthlyDates[d].day,
                            });
                            return;
                        }
                    } else if (
                        [
                            TimeSchedule.BEFORE,
                            TimeSchedule.AFTER,
                            TimeSchedule.BEFORE_OR_AFTER,
                        ].includes(monthlyDates[d].selectedTimeOption)
                    ) {
                        if (
                            monthlyDates[d].selectedTimeOption ===
                                TimeSchedule.BEFORE &&
                            !moment(
                                monthlyDates[d].priTime.format("HH:mm:ss"),
                                "HH:mm:ss"
                            ).isAfter(moment().startOf("day"))
                        ) {
                            setInvalidBeforeAfterDateMsg(
                                "The time entered for 'Before' must be later than 12:00 AM"
                            );

                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_INVALID_BEFORE_AFTER_DATE,
                                day: monthlyDates[d].day,
                            });
                            return;
                        }

                        if (
                            monthlyDates[d].selectedTimeOption ===
                                TimeSchedule.AFTER &&
                            !moment(
                                monthlyDates[d].priTime.format("HH:mm:ss"),
                                "HH:mm:ss"
                            ).isAfter(moment().startOf("day"))
                        ) {
                            setInvalidBeforeAfterDateMsg(
                                "The time entered for 'After' must be later than 12:00 AM"
                            );

                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_INVALID_BEFORE_AFTER_DATE,
                                day: monthlyDates[d].day,
                            });
                            return;
                        }

                        if (
                            (!moment(
                                monthlyDates[d].priTime.format("HH:mm:ss"),
                                "HH:mm:ss"
                            ).isAfter(moment().startOf("day")) ||
                                !moment(
                                    monthlyDates[d].secTime.format("HH:mm:ss"),
                                    "HH:mm:ss"
                                ).isAfter(moment().startOf("day"))) &&
                            monthlyDates[d].selectedTimeOption ===
                                TimeSchedule.BEFORE_OR_AFTER
                        ) {
                            setInvalidBeforeAfterDateMsg(
                                "The time entered for 'Before' and 'After' must be later than 12:00 AM"
                            );

                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_INVALID_BEFORE_AFTER_DATE,
                                day: monthlyDates[d].day,
                            });
                            return;
                        }

                        if (
                            monthlyDates[d].selectedTimeOption ===
                                TimeSchedule.BEFORE_OR_AFTER &&
                            monthlyDates[d].priTime.isSameOrAfter(
                                monthlyDates[d].secTime
                            )
                        ) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_BEFORE_OR_AFTER,
                                day: monthlyDates[d].day,
                            });
                            return;
                        }

                        if (
                            !monthlyDates[d].priTime._isValid ||
                            !monthlyDates[d].secTime._isValid
                        ) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_INVALID,
                                day: monthlyDates[d].day,
                            });
                            return;
                        }
                    } else if (
                        monthlyDates[d].selectedTimeOption === TimeSchedule.AT
                    ) {
                        if (!monthlyDates[d].timeList.length) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_EMPTY,
                                day: monthlyDates[d].day,
                            });
                            return;
                        }
                    } else if (
                        monthlyDates[d].selectedTimeOption ===
                        TimeSchedule.BETWEEN
                    ) {
                        if (!monthlyDates[d].timeRangeList.length) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_EMPTY,
                                day: monthlyDates[d].day,
                            });
                            return;
                        }
                    }
                }

                break;
            case YEARLY:
                const yearlyDates = allDates.filter((y: any) => {
                    return y.type.includes("yearly");
                });

                for (let d = 0; d < yearlyDates.length; d++) {
                    if (
                        yearlyDates[d].selectedTimeOption === TimeSchedule.EVERY
                    ) {
                        if (!yearlyDates[d].duration) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_NO_DURATION,
                                day: yearlyDates[d].day,
                                month: yearlyDates[d].month,
                            });
                            return;
                        }

                        if (
                            yearlyDates[d].selectedInterval === "seconds" &&
                            Number(yearlyDates[d].duration) < 5
                        ) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_INCORRECT_VALUE,
                                day: yearlyDates[d].day,
                                month: yearlyDates[d].month,
                            });
                            return;
                        }
                    } else if (
                        [
                            TimeSchedule.BEFORE,
                            TimeSchedule.AFTER,
                            TimeSchedule.BEFORE_OR_AFTER,
                        ].includes(yearlyDates[d].selectedTimeOption)
                    ) {
                        if (
                            yearlyDates[d].selectedTimeOption ===
                                TimeSchedule.BEFORE &&
                            !moment(
                                yearlyDates[d].priTime.format("HH:mm:ss"),
                                "HH:mm:ss"
                            ).isAfter(moment().startOf("day"))
                        ) {
                            setInvalidBeforeAfterDateMsg(
                                "The time entered for 'Before' must be later than 12:00 AM"
                            );

                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_INVALID_BEFORE_AFTER_DATE,
                                day: yearlyDates[d].day,
                                month: yearlyDates[d].month,
                            });
                            return;
                        }

                        if (
                            yearlyDates[d].selectedTimeOption ===
                                TimeSchedule.AFTER &&
                            !moment(
                                yearlyDates[d].priTime.format("HH:mm:ss"),
                                "HH:mm:ss"
                            ).isAfter(moment().startOf("day"))
                        ) {
                            setInvalidBeforeAfterDateMsg(
                                "The time entered for 'After' must be later than 12:00 AM"
                            );

                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_INVALID_BEFORE_AFTER_DATE,
                                day: yearlyDates[d].day,
                                month: yearlyDates[d].month,
                            });
                            return;
                        }

                        if (
                            (!moment(
                                yearlyDates[d].priTime.format("HH:mm:ss"),
                                "HH:mm:ss"
                            ).isAfter(moment().startOf("day")) ||
                                !moment(
                                    yearlyDates[d].secTime.format("HH:mm:ss"),
                                    "HH:mm:ss"
                                ).isAfter(moment().startOf("day"))) &&
                            yearlyDates[d].selectedTimeOption ===
                                TimeSchedule.BEFORE_OR_AFTER
                        ) {
                            setInvalidBeforeAfterDateMsg(
                                "The time entered for 'Before' and 'After' must be later than 12:00 AM"
                            );

                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_INVALID_BEFORE_AFTER_DATE,
                                day: yearlyDates[d].day,
                                month: yearlyDates[d].month,
                            });
                            return;
                        }

                        if (
                            yearlyDates[d].selectedTimeOption ===
                                TimeSchedule.BEFORE_OR_AFTER &&
                            yearlyDates[d].priTime.isSameOrAfter(
                                yearlyDates[d].secTime
                            )
                        ) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_BEFORE_OR_AFTER,
                                day: yearlyDates[d].day,
                                month: yearlyDates[d].month,
                            });
                            return;
                        }

                        if (
                            !yearlyDates[d].priTime._isValid ||
                            !yearlyDates[d].secTime._isValid
                        ) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_INVALID,
                                day: yearlyDates[d].day,
                                month: yearlyDates[d].month,
                            });
                            return;
                        }
                    } else if (
                        yearlyDates[d].selectedTimeOption === TimeSchedule.AT
                    ) {
                        if (!yearlyDates[d].timeList.length) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_EMPTY,
                                day: yearlyDates[d].day,
                                month: yearlyDates[d].month,
                            });
                            return;
                        }
                    } else if (
                        yearlyDates[d].selectedTimeOption ===
                        TimeSchedule.BETWEEN
                    ) {
                        if (!yearlyDates[d].timeRangeList.length) {
                            setError({
                                component: selectedRepeatOption,
                                errorType: ERROR_EMPTY,
                                day: yearlyDates[d].day,
                                month: yearlyDates[d].month,
                            });
                            return;
                        }
                    }
                }
                break;
        }

        if (!params.conditionId) {
            let body: any;

            switch (selectedRepeatOption) {
                case DAILY:
                    const dailyEndDateEnabled =
                        recurrenceRange[repeatedOption].endDateEnabled;

                    body = {
                        slot: slot,
                        type_: `DATETIME`,
                        resource: {
                            time_zone: momentTZ.tz.guess(),
                            date2: {
                                start: recurrenceRange[
                                    repeatedOption
                                ].startDate.format(`YYYY-MM-DD`),
                                ...(dailyEndDateEnabled
                                    ? {
                                          end: recurrenceRange[
                                              repeatedOption
                                          ].endDate.format(`YYYY-MM-DD`),
                                      }
                                    : {}),
                                repeat: DAILY,
                                interval: Number(repeatInterval.day),
                                [DAILY]: EventService.getEventTimeRange(
                                    selectedTimeOption.default,
                                    priTime.default,
                                    secTime.default,
                                    timeList.default,
                                    timeRangeList.default,
                                    duration.default
                                ),
                            },
                        },
                    };

                    break;
                case WEEKLY:
                    const weeklyEndDateEnabled =
                        recurrenceRange[repeatedOption].endDateEnabled;

                    let d: any;
                    const weekdayObj: any = {};

                    for (d of DaysOfWeek) {
                        if (checkboxState[d]) {
                            weekdayObj[DaysOfWeek.indexOf(d) + 1] =
                                EventService.getEventTimeRange(
                                    selectedTimeOption[d],
                                    priTime[d],
                                    secTime[d],
                                    timeList[d],
                                    timeRangeList[d],
                                    duration[d]
                                );
                        }
                    }

                    body = {
                        slot: slot,
                        type_: `DATETIME`,
                        resource: {
                            time_zone: momentTZ.tz.guess(),
                            date2: {
                                start: recurrenceRange[
                                    repeatedOption
                                ].startDate.format(`YYYY-MM-DD`),
                                ...(weeklyEndDateEnabled
                                    ? {
                                          end: recurrenceRange[
                                              repeatedOption
                                          ].endDate.format(`YYYY-MM-DD`),
                                      }
                                    : {}),
                                repeat: WEEKLY,
                                interval: Number(repeatInterval.week),
                                [WEEKLY]: {
                                    weekdays: weekdayObj,
                                },
                            },
                        },
                    };

                    break;
                case MONTHLY:
                    const monthlyEndDateEnabled =
                        recurrenceRange[repeatedOption].endDateEnabled;

                    const monthlyWeekdayObj: any = {};
                    const monthlyDayObj: any = {};
                    // filter out monthly dates using filter function
                    const monthlyDates = allDates.filter((m: any) => {
                        return m.type.includes("monthly");
                    });

                    for (const d of monthlyDates) {
                        if (d.type === "monthly_recurrence:day") {
                            // monthly_recurrence:day
                            monthlyDayObj[d.day] =
                                EventService.getEventTimeRange(
                                    d.selectedTimeOption,
                                    d.priTime,
                                    d.secTime,
                                    d.timeList,
                                    d.timeRangeList,
                                    d.duration
                                );
                        } else {
                            // monthly_recurrence:firstLast

                            monthlyWeekdayObj[d.day] =
                                EventService.getEventTimeRange(
                                    d.selectedTimeOption,
                                    d.priTime,
                                    d.secTime,
                                    d.timeList,
                                    d.timeRangeList,
                                    d.duration
                                );
                        }
                    }

                    if (!monthlyDates.length) {
                        setModalShow(true);
                        setModalType("err");
                        setModalContent("Please select a recurring day.");
                        return;
                    }

                    body = {
                        slot: slot,
                        type_: `DATETIME`,
                        resource: {
                            time_zone: momentTZ.tz.guess(),
                            date2: {
                                start: recurrenceRange[
                                    repeatedOption
                                ].startDate.format(`YYYY-MM-DD`),
                                ...(monthlyEndDateEnabled
                                    ? {
                                          end: recurrenceRange[
                                              repeatedOption
                                          ].endDate.format(`YYYY-MM-DD`),
                                      }
                                    : {}),
                                repeat: MONTHLY,
                                interval: Number(repeatInterval.month),
                                [MONTHLY]: {
                                    weekdays: monthlyWeekdayObj,
                                    days: monthlyDayObj,
                                },
                            },
                        },
                    };

                    // remove either weekdays or days if empty {}
                    if (isEmpty(body.resource.date2[MONTHLY].weekdays))
                        delete body.resource.date2[MONTHLY].weekdays;
                    if (isEmpty(body.resource.date2[MONTHLY].days))
                        delete body.resource.date2[MONTHLY].days;

                    break;
                case YEARLY:
                    const yearlyEndDateEnabled =
                        recurrenceRange[repeatedOption].endDateEnabled;

                    const yearlyMonthObj: any = {};
                    // filter out yearly dates using filter function
                    const yearlyDates = allDates.filter((m: any) => {
                        return m.type.includes("yearly");
                    });

                    for (const d of yearlyDates) {
                        const monthlyVal: any = Object.keys(MonthsMap).find(
                            (key: any) =>
                                MonthsMap[key].toLowerCase() === d.month
                        );

                        // yearly_recurrence:day
                        if (d.type === "yearly_recurrence:day") {
                            yearlyMonthObj[monthlyVal] = {
                                // ... yearlyMonthObj,
                                // eslint-disable-next-line no-useless-computed-key
                                ["days"]: {
                                    ...yearlyMonthObj[monthlyVal]?.["days"],
                                    [d.day]: EventService.getEventTimeRange(
                                        d.selectedTimeOption,
                                        d.priTime,
                                        d.secTime,
                                        d.timeList,
                                        d.timeRangeList,
                                        d.duration
                                    ),
                                },
                            };
                        } else {
                            // yearly_recurrence:firstLast

                            yearlyMonthObj[monthlyVal] = {
                                // ... yearlyMonthObj,
                                // eslint-disable-next-line no-useless-computed-key
                                ["weekdays"]: {
                                    ...yearlyMonthObj[monthlyVal]?.["weekdays"],
                                    [d.day]: EventService.getEventTimeRange(
                                        d.selectedTimeOption,
                                        d.priTime,
                                        d.secTime,
                                        d.timeList,
                                        d.timeRangeList,
                                        d.duration
                                    ),
                                },
                            };
                        }
                    }

                    if (!yearlyDates.length) {
                        setModalShow(true);
                        setModalType("err");
                        setModalContent("Please select a recurring day.");
                        return;
                    }

                    body = {
                        slot: slot,
                        type_: `DATETIME`,
                        resource: {
                            time_zone: momentTZ.tz.guess(),
                            date2: {
                                start: recurrenceRange[
                                    repeatedOption
                                ].startDate.format(`YYYY-MM-DD`),
                                ...(yearlyEndDateEnabled
                                    ? {
                                          end: recurrenceRange[
                                              repeatedOption
                                          ].endDate.format(`YYYY-MM-DD`),
                                      }
                                    : {}),
                                repeat: YEARLY,
                                interval: Number(repeatInterval.year),
                                [YEARLY]: {
                                    months: yearlyMonthObj,
                                },
                            },
                        },
                    };

                    break;
            }
            response = await EventService.createCondition(params.eventId, body);
        } else {
            let body: any;

            switch (selectedRepeatOption) {
                case DAILY:
                    switch (selectedTimeOption.default) {
                        case TimeSchedule.ALL_DAY:
                        case TimeSchedule.EVERY:
                            range = [];
                            break;
                        case TimeSchedule.BEFORE:
                        case TimeSchedule.AFTER:
                            range = [priTime.default.format("HH:mm:ss")];
                            break;
                        case TimeSchedule.BEFORE_OR_AFTER:
                            range = [
                                priTime.default.format("HH:mm:ss"),
                                secTime.default.format("HH:mm:ss"),
                            ];
                            break;
                        case TimeSchedule.AT:
                            range = timeList.default;
                            break;
                        case TimeSchedule.BETWEEN:
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            range = timeRangeList.default;
                            break;
                    }

                    const dailyEndDateEnabled =
                        recurrenceRange[selectedRepeatOption].endDateEnabled;
                    const dailyStartDate =
                        recurrenceRange[selectedRepeatOption].startDate.format(
                            `YYYY-MM-DD`
                        );
                    const dailyEndDate =
                        recurrenceRange[selectedRepeatOption].endDate.format(
                            `YYYY-MM-DD`
                        );

                    body = {
                        slot: currentRecurrenceDetail.slot,
                        type_: "DATETIME",
                        resource: {
                            time_zone: momentTZ.tz.guess(),
                            date2: {
                                start: dailyStartDate,
                                ...(dailyEndDateEnabled
                                    ? { end: dailyEndDate }
                                    : {}),
                                repeat: DAILY,
                                interval: Number(repeatInterval.day),
                                [DAILY]: EventService.getEventTimeRange(
                                    selectedTimeOption.default,
                                    priTime.default,
                                    secTime.default,
                                    timeList.default,
                                    timeRangeList.default,
                                    duration.default
                                ),
                            },
                        },
                    };

                    break;
                case WEEKLY:
                    const weeklyEndDateEnabled =
                        recurrenceRange[repeatedOption].endDateEnabled;
                    const weeklyStartDate =
                        recurrenceRange[selectedRepeatOption].startDate.format(
                            `YYYY-MM-DD`
                        );
                    const weeklyEndDate =
                        recurrenceRange[selectedRepeatOption].endDate.format(
                            `YYYY-MM-DD`
                        );

                    let d: any;
                    const weekdayObj: any = {};

                    for (d of DaysOfWeek) {
                        if (checkboxState[d]) {
                            weekdayObj[DaysOfWeek.indexOf(d) + 1] =
                                EventService.getEventTimeRange(
                                    selectedTimeOption[d],
                                    priTime[d],
                                    secTime[d],
                                    timeList[d],
                                    timeRangeList[d],
                                    duration[d]
                                );
                        }
                    }

                    body = {
                        slot: slot,
                        type_: `DATETIME`,
                        resource: {
                            time_zone: momentTZ.tz.guess(),
                            date2: {
                                start: weeklyStartDate,
                                ...(weeklyEndDateEnabled
                                    ? { end: weeklyEndDate }
                                    : {}),
                                repeat: WEEKLY,
                                interval: Number(repeatInterval.week),
                                [WEEKLY]: {
                                    weekdays: weekdayObj,
                                },
                            },
                        },
                    };

                    break;
                case MONTHLY:
                    const monthlyWeekdayObj: any = {};
                    const monthlyDayObj: any = {};
                    // filter out monthly dates using filter function
                    const monthlyDates = allDates.filter((m: any) => {
                        return m.type.includes("monthly");
                    });

                    for (const d of monthlyDates) {
                        if (d.type === "monthly_recurrence:day") {
                            // monthly_recurrence:day
                            monthlyDayObj[d.day] =
                                EventService.getEventTimeRange(
                                    d.selectedTimeOption,
                                    d.priTime,
                                    d.secTime,
                                    d.timeList,
                                    d.timeRangeList,
                                    d.duration
                                );
                        } else {
                            // monthly_recurrence:firstLast
                            monthlyWeekdayObj[d.day] =
                                EventService.getEventTimeRange(
                                    d.selectedTimeOption,
                                    d.priTime,
                                    d.secTime,
                                    d.timeList,
                                    d.timeRangeList,
                                    d.duration
                                );
                        }
                    }

                    if (!monthlyDates.length) {
                        setModalShow(true);
                        setModalType("err");
                        setModalContent("Please select a recurring day.");
                        return;
                    }
                    const monthlyEndDateEnabled =
                        recurrenceRange[repeatedOption].endDateEnabled;
                    const monthlyStartDate =
                        recurrenceRange[selectedRepeatOption].startDate.format(
                            `YYYY-MM-DD`
                        );
                    const monthlyEndDate =
                        recurrenceRange[selectedRepeatOption].endDate.format(
                            `YYYY-MM-DD`
                        );
                    body = {
                        slot: slot,
                        type_: `DATETIME`,
                        resource: {
                            time_zone: momentTZ.tz.guess(),
                            date2: {
                                start: monthlyStartDate,
                                ...(monthlyEndDateEnabled
                                    ? { end: monthlyEndDate }
                                    : {}),
                                repeat: MONTHLY,
                                interval: Number(repeatInterval.month),
                                [MONTHLY]: {
                                    weekdays: monthlyWeekdayObj,
                                    days: monthlyDayObj,
                                },
                            },
                        },
                    };

                    // remove either weekdays or days if empty {}
                    if (isEmpty(body.resource.date2[MONTHLY].weekdays))
                        delete body.resource.date2[MONTHLY].weekdays;
                    if (isEmpty(body.resource.date2[MONTHLY].days))
                        delete body.resource.date2[MONTHLY].days;

                    break;
                case YEARLY:
                    const yearlyMonthObj: any = {};
                    // filter out yearly dates using filter function
                    const yearlyDates = allDates.filter((m: any) => {
                        return m.type.includes("yearly");
                    });

                    for (const d of yearlyDates) {
                        const monthlyVal: any = Object.keys(MonthsMap).find(
                            (key: any) =>
                                MonthsMap[key].toLowerCase() === d.month
                        );

                        // yearly_recurrence:day
                        if (d.type === "yearly_recurrence:day") {
                            yearlyMonthObj[monthlyVal] = {
                                // ... yearlyMonthObj,
                                // eslint-disable-next-line no-useless-computed-key
                                ["days"]: {
                                    ...yearlyMonthObj[monthlyVal]?.["days"],
                                    [d.day]: EventService.getEventTimeRange(
                                        d.selectedTimeOption,
                                        d.priTime,
                                        d.secTime,
                                        d.timeList,
                                        d.timeRangeList,
                                        d.duration
                                    ),
                                },
                            };
                        } else {
                            // yearly_recurrence:firstLast

                            yearlyMonthObj[monthlyVal] = {
                                // ... yearlyMonthObj,
                                // eslint-disable-next-line no-useless-computed-key
                                ["weekdays"]: {
                                    ...yearlyMonthObj[monthlyVal]?.["weekdays"],
                                    [d.day]: EventService.getEventTimeRange(
                                        d.selectedTimeOption,
                                        d.priTime,
                                        d.secTime,
                                        d.timeList,
                                        d.timeRangeList,
                                        d.duration
                                    ),
                                },
                            };
                        }
                    }

                    if (!yearlyDates.length) {
                        setModalShow(true);
                        setModalType("err");
                        setModalContent("Please select a recurring day.");
                        return;
                    }
                    const yearlyEndDateEnabled =
                        recurrenceRange[repeatedOption].endDateEnabled;
                    const yearlyStartDate =
                        recurrenceRange[selectedRepeatOption].startDate.format(
                            `YYYY-MM-DD`
                        );
                    const yearlyEndDate =
                        recurrenceRange[selectedRepeatOption].endDate.format(
                            `YYYY-MM-DD`
                        );
                    body = {
                        slot: slot,
                        type_: `DATETIME`,
                        resource: {
                            time_zone: momentTZ.tz.guess(),
                            date2: {
                                start: yearlyStartDate,
                                ...(yearlyEndDateEnabled
                                    ? { end: yearlyEndDate }
                                    : {}),
                                repeat: YEARLY,
                                interval: Number(repeatInterval.year),
                                [YEARLY]: {
                                    months: yearlyMonthObj,
                                },
                            },
                        },
                    };

                    break;
            }

            response = await EventService.updateCondition(
                params.eventId,
                params.conditionId,
                {
                    ...body,
                }
            );
        }
        if (response.status >= 200 && response.status <= 299) {
            closeCondition();
        } else {
            if (response.data?.errors) {
                const errors: any = [];
                for (const err of response.data?.errors || []) {
                    !errors.includes(...err.value) &&
                        errors.push(`Time Schedule: ${[...err.value]}`);
                }
                setModalShow(true);
                setModalType("err");
                setErrorArray(errors);
            } else {
                setSimpleModalData({
                    resObj: response,
                } as SimpleModalDataType);
            }
        }
    };

    const closeCondition = () => {
        history.push(`/event-details/${params.eventId}`);
    };

    const renderRepeatOption = () => {
        switch (selectedRepeatOption) {
            case DAILY:
                return (
                    <Daily
                        priTime={priTime}
                        setPriTime={updatePriTime}
                        secTime={secTime}
                        setSecTime={updateSecTime}
                        selectedTimeOption={selectedTimeOption}
                        setSelectedTimeOption={updateSelectedTimeOption}
                        selectedInterval={selectedInterval}
                        setSelectedInterval={updateSelectedInterval}
                        duration={duration}
                        setDuration={updateDuration}
                        timeList={timeList}
                        setTimeList={updateTimeList}
                        timeRangeList={timeRangeList}
                        setTimeRangeList={updateTimeRangeList}
                        errorType={errorType}
                        setErrorType={updateErrorType}
                        repeatInterval={repeatInterval}
                        setRepeatInterval={updateRepeatInterval}
                        day={"default"}
                        setRecurrenceRange={updateRecurrenceRange}
                        recurrenceRange={recurrenceRange}
                        renderErrorMsg={renderErrorMsg}
                    />
                );

            case WEEKLY:
                return (
                    <Weekly
                        priTime={priTime}
                        setPriTime={updatePriTime}
                        secTime={secTime}
                        setSecTime={updateSecTime}
                        selectedTimeOption={selectedTimeOption}
                        setSelectedTimeOption={updateSelectedTimeOption}
                        selectedInterval={selectedInterval}
                        setSelectedInterval={updateSelectedInterval}
                        duration={duration}
                        setDuration={updateDuration}
                        timeList={timeList}
                        setTimeList={updateTimeList}
                        timeRangeList={timeRangeList}
                        setTimeRangeList={updateTimeRangeList}
                        errorType={errorType}
                        setErrorType={updateErrorType}
                        repeatInterval={repeatInterval}
                        setRepeatInterval={updateRepeatInterval}
                        checkboxState={checkboxState}
                        setCheckboxState={updateCheckboxState}
                        setRecurrenceRange={updateRecurrenceRange}
                        recurrenceRange={recurrenceRange}
                        renderErrorMsg={renderErrorMsg}
                    />
                );
            case MONTHLY:
                return (
                    <Monthly
                        allDates={allDates}
                        setAllDates={updateAllDates}
                        repeatInterval={repeatInterval}
                        setRepeatInterval={updateRepeatInterval}
                        setRecurrenceRange={updateRecurrenceRange}
                        recurrenceRange={recurrenceRange}
                        renderErrorMsg={renderErrorMsg}
                        onChangeTimeOption={(date: any) => {
                            setError({
                                component: selectedRepeatOption,
                                errorType: "",
                                day: date.day,
                            });
                        }}
                    />
                );

            case YEARLY:
                return (
                    <Yearly
                        allDates={allDates}
                        setAllDates={updateAllDates}
                        repeatInterval={repeatInterval}
                        setRepeatInterval={updateRepeatInterval}
                        setRecurrenceRange={updateRecurrenceRange}
                        recurrenceRange={recurrenceRange}
                        renderErrorMsg={renderErrorMsg}
                        onChangeTimeOption={(date: any) => {
                            setError({
                                component: selectedRepeatOption,
                                errorType: "",
                                day: date.day,
                                month: date.month,
                            });
                        }}
                    />
                );
        }
    };

    return (
        <>
            <ContentWrapper
                isLoading={isPageLoading}
                simpleModalData={simpleModalData}
            >
                <Container>
                    <Row>
                        <Col sm="12" className="event-detail-head">
                            <h5 className="page-title overflow-text">
                                Recurrence
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item>
                                    <Link to="/events">Events</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link
                                        to={`/event-details/${params.eventId}`}
                                    >
                                        Event Details
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Condition
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row className="action-detail-box">
                        <Col>
                            <div className="form-box  mb-3">
                                <h5 className="mb-4">Repeat</h5>
                                <Form.Row>
                                    <Col sm={2}>
                                        <Form.Check
                                            name="repeat"
                                            id={DAILY}
                                            value={DAILY}
                                            checked={
                                                selectedRepeatOption === DAILY
                                            }
                                            custom
                                            type="radio"
                                            label={
                                                <span className="ml-2">
                                                    Daily
                                                </span>
                                            }
                                            onChange={(e: any) => {
                                                setSelectedRepeatOption(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Check
                                            name="repeat"
                                            id={WEEKLY}
                                            value={WEEKLY}
                                            checked={
                                                selectedRepeatOption === WEEKLY
                                            }
                                            custom
                                            type="radio"
                                            label={
                                                <span className="ml-2">
                                                    Weekly
                                                </span>
                                            }
                                            onChange={(e: any) => {
                                                setSelectedRepeatOption(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Check
                                            name="repeat"
                                            id={MONTHLY}
                                            value={MONTHLY}
                                            checked={
                                                selectedRepeatOption === MONTHLY
                                            }
                                            custom
                                            type="radio"
                                            label={
                                                <span className="ml-2">
                                                    Monthly
                                                </span>
                                            }
                                            onChange={(e: any) => {
                                                setSelectedRepeatOption(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Check
                                            name="repeat"
                                            id={YEARLY}
                                            value={YEARLY}
                                            checked={
                                                selectedRepeatOption === YEARLY
                                            }
                                            custom
                                            type="radio"
                                            label={
                                                <span className="ml-2">
                                                    Yearly
                                                </span>
                                            }
                                            onChange={(e: any) => {
                                                setSelectedRepeatOption(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Col>
                                </Form.Row>
                            </div>

                            {renderRepeatOption()}

                            <Button
                                variant="secondary"
                                className="mr-2"
                                onClick={closeCondition}
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="primary"
                                className="pl-4 pr-4"
                                onClick={handleAddUpdateCondition}
                            >
                                {params.conditionId ? "UPDATE" : "ADD"}
                            </Button>
                        </Col>
                    </Row>
                    <DefaultModal
                        modalShow={modalShow}
                        setModalShow={setModalShow}
                        modalType={modalType}
                        modalContent={modalContent}
                        okAction={() => {
                            setModalShow(false);
                        }}
                        errorArray={errorArray}
                    />
                </Container>
            </ContentWrapper>
        </>
    );
};

export default Recurrence;
