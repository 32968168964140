import { ReactElement, cloneElement, useState, useEffect, AllHTMLAttributes } from 'react';
import { Overlay, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { canAccess as accessPermission } from 'utils/authorize-action';
import { PermissionDeniedMessage } from 'constant';

interface Props extends AllHTMLAttributes<HTMLElement>{
    message?: string
    permission: string,
    className?: string,
    placement?: 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 
                'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 
                'left' | 'left-start'
    children?: ReactElement[] | ReactElement
}

export const HoverAuthorizeTooltip = (props: Props) => {

    const  { 
        children,
        message = PermissionDeniedMessage, 
        className = "", 
        permission, 
        placement ="auto", 
        style, 
        ...rest 
    } = props;
    const [canAccess, setCanAccess] = useState(true);

    useEffect(() => {
        
        if (window.authorizationConfig && permission) {
            setCanAccess(accessPermission(permission))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission, window.authorizationConfig]);

    const  PermissionTooltip = () => {
        return(
            <Tooltip id="tooltip-permission">{message}</Tooltip>
        )
    }
    
    return (
        <>
        { !canAccess && 
            <OverlayTrigger placement={placement} overlay={PermissionTooltip()}>
                <span className={`permission-container ${className}`} style={{...style, cursor: "no-drop"}} {...rest}>
                    {children && !Array.isArray(children) && cloneElement(children, {"data-can": permission})}
                    {Array.isArray(children) && children.map(el => cloneElement(el, {"data-can": permission}))}
                </span>
            </OverlayTrigger>
        }

        { canAccess && 
            <span className={`${className}`}>
                {children && !Array.isArray(children) && cloneElement(children, {"data-can": permission})}
                {Array.isArray(children) && children.map(el => cloneElement(el, {"data-can": permission}))}
            </span>
        }
        </>
    )

}

export const OverlayAuthorizeTooltip = (props: any) =>{
    const { placement, show, target, message = PermissionDeniedMessage} = props;

    return (
    <Overlay target={target} show={show} placement={placement}>
        {(props) => (<Tooltip id="overlay-authorize" {...props}>
            {message}
        </Tooltip>
        )}
    </Overlay>
    )
}


export default HoverAuthorizeTooltip;