import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import addressImg from "assets/svg/address.svg";
import "assets/css/subscription.css";
import { Countries } from "types/Subscription";
import { faker } from "@faker-js/faker";
import pycountry from "utils/pycountry_list.json";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";

const BillingAddressCheckoutForm = (props: any) => {
    const { billingAddress, setBillingAddress } = props;

    const location = useLocation();
    const isCheckout = location.pathname === "/checkout/billing-address";
    const tooltip = (
        <Tooltip id="tooltip">Editing country is not allowed</Tooltip>
    );

    const updateFields = (e: any) => {
        setBillingAddress({
            ...billingAddress,
            [e.target.name]: e.target.value,
        });
    };

    let listOfCountries: any = Object.keys(pycountry);

    const memoizedRenderCountries = useMemo(() => {
        let countryOption: any = listOfCountries
            .sort()
            .map((country: string) => {
                return (
                    <option value={country} key={faker.datatype.uuid()}>
                        {country}
                    </option>
                );
            });
        countryOption.unshift(
            <option value="" key="empty">
                --- Select a country ---
            </option>
        );
        return countryOption;
    }, [listOfCountries]);

    const renderRegions = (selectedCountry: string) => {
        let regionOption: any = [];
        const pycountries: Countries = pycountry;

        listOfCountries.forEach((i: any) => {
            if (i === selectedCountry) {
                regionOption = pycountries[selectedCountry];
            }
        });

        const sortedRegionOption = regionOption.sort().map((region: any) => (
            <option value={region} key={faker.datatype.uuid()}>
                {region}
            </option>
        ));
        sortedRegionOption.unshift(
            <option value="" key="empty">
                --- Select a region ---
            </option>
        );
        return sortedRegionOption;
    };

    return (
        <div className="form-box mb-3">
            <div className="address-title">
                <img src={addressImg} className="address-img" alt="address" />
                <h5>Billing Address</h5>
                <p>Address is required according to regulatory requirement.</p>
            </div>
            <Form>
                <Form.Group className="mb-4">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        aria-label="first_name"
                        name="first_name"
                        onChange={updateFields}
                        value={billingAddress.first_name}
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        aria-label="last_name"
                        name="last_name"
                        onChange={updateFields}
                        value={billingAddress.last_name}
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Street</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        aria-label="street"
                        name="street"
                        onChange={updateFields}
                        value={billingAddress.street}
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Building (Optional)</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        aria-label="building"
                        name="extended"
                        value={billingAddress.extended}
                        onChange={updateFields}
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Floor, Unit</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        aria-label="floor_unit"
                        name="line3"
                        onChange={updateFields}
                        value={billingAddress.line3}
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        aria-label="city"
                        name="city"
                        onChange={updateFields}
                        value={billingAddress.city}
                    />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label>Zip/Postal Code</Form.Label>
                    <Form.Control
                        type="text"
                        required
                        aria-label="zipcode"
                        name="code"
                        onChange={updateFields}
                        value={billingAddress.code}
                    />
                </Form.Group>
                {isCheckout ? (
                    <Form.Group className="mb-4">
                        <Form.Label>Country</Form.Label>
                        <Form.Control
                            as="select"
                            required
                            aria-label="country"
                            name="country"
                            value={billingAddress.country}
                            onChange={(e: any) => {
                                setBillingAddress({
                                    ...billingAddress,
                                    region: "",
                                    [e.target.name]: e.target.value,
                                });
                            }}
                        >
                            {memoizedRenderCountries}
                        </Form.Control>
                    </Form.Group>
                ) : (
                    <OverlayTrigger placement="auto-start" overlay={tooltip}>
                        <Form.Group className="mb-4">
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                as="select"
                                required
                                aria-label="country"
                                name="country"
                                disabled
                                className="not-allowed-input"
                                value={billingAddress.country}
                            >
                                {memoizedRenderCountries}
                            </Form.Control>
                        </Form.Group>
                    </OverlayTrigger>
                )}
                <Form.Group className="mb-4">
                    <Form.Label>State/Province (Optional)</Form.Label>
                    <Form.Control
                        as="select"
                        required
                        aria-label="state"
                        name="region"
                        value={billingAddress.region}
                        onChange={(e: any) => {
                            setBillingAddress({
                                ...billingAddress,
                                [e.target.name]: e.target.value,
                            });
                        }}
                    >
                        {renderRegions(billingAddress.country)}
                    </Form.Control>
                </Form.Group>
            </Form>
        </div>
    );
};

export default BillingAddressCheckoutForm;
