import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    FormControl,
    InputGroup,
    Button,
    Modal,
} from "react-bootstrap";
import dlt from "assets/svg/delete.svg";
import {
    deleteRegistry,
    getAllRegistry,
    getRegistryGroups,
    deleteRegistryGroup,
    getGWReading,
} from "service/gatewayService";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import { checkActive } from "utils/gatewayFunctions";

import { HoverAuthorizeTooltip } from "components/authorize/AuthorizeTooltip";
import { canAccess } from "utils/authorize-action";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";

import useCollectSort from "hooks/useCollectSort";
import { isHttpSuccess } from "utils/functions";
import { FETCH_GATEWAY_FAIL_MESSAGE, HttpStatus } from "constant";
import { useOrganisation } from "hooks/organization";

const AllGateway = () => {
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [allRegistry, setAllRegistry] = useState<Array<any>>([]);
    const [initialRegistryList, setInitialRegistryList] = useState<Array<any>>(
        []
    );
    const [gatewayToDelete, setGatewayToDelete] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const { data: registryCollect, sortIcon } = useCollectSort(allRegistry);
    const { currentOrgId } = useOrganisation();
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);

    useEffect(() => {
        const fetch = async () => {
            if (currentOrgId) {
                const res = await getAllRegistry(currentOrgId);
                if (isHttpSuccess(res.status)) {
                    if (res.data.length > 0) {
                        const hbRes: any = await getGWReading();

                        const lastData = hbRes.data.map((i: any) => ({
                            heartbeat: i.heartbeat,
                            gateway_id: i.gateway_id,
                        }));

                        // Push the heartbeat data to the individual registry
                        const cloned = res.data;

                        for (let i = 0; i < cloned.length; i++) {
                            for (let j = 0; j < lastData.length; j++) {
                                if (
                                    cloned[i].gateway_id ===
                                    lastData[j].gateway_id
                                ) {
                                    cloned[i].heartbeat = lastData[j].heartbeat;
                                }
                            }
                        }

                        setAllRegistry(cloned);
                        setInitialRegistryList(cloned);
                    }
                } else if (res.status === HttpStatus.FORBIDDEN) {
                    setIsForbiddenResource(true);
                } else {
                    showErrorAlert(
                        getAPIError(res, FETCH_GATEWAY_FAIL_MESSAGE)
                    );
                }
                setIsPageLoading(false);
            }
        };

        if (!canAccess("gateway:read")) {
            setIsPageLoading(false);
            setIsForbiddenResource(true);
        } else {
            fetch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrgId]);

    const searchGateway = (value: any) => {
        if (value !== "") {
            const queryRegistryList: any = [];
            for (const i of initialRegistryList) {
                if (i.name.toLowerCase().includes(value.toLowerCase())) {
                    queryRegistryList.push(i);
                }
            }
            setAllRegistry(queryRegistryList);
        } else {
            setAllRegistry(initialRegistryList);
        }
    };

    const openDeleteConfirmModal = (gatewayName: string) => {
        setModalShow(true);
        setModalContent(
            `Are you sure you want to delete ${gatewayName}?
            This cannot be undone.`
        );
    };

    const closeConfirmModal = () => {
        setModalShow(false);
    };

    const handleDeleteGateway = async () => {
        closeConfirmModal();
        const response = await deleteRegistry(gatewayToDelete);

        if (isHttpSuccess(response.status)) {
            const cloned = [...allRegistry].filter((i: any) => {
                return i.gateway_id !== gatewayToDelete;
            });
            const initialCloned = [...initialRegistryList].filter((i: any) => {
                return i.gateway_id !== gatewayToDelete;
            });
            setAllRegistry(cloned);
            setInitialRegistryList(initialCloned);

            showSuccessAlert({
                message: `Device unregistered successfully.`,
            });
        } else {
            setSimpleModalData({
                resObj: response,
            } as SimpleModalDataType);
        }
    };

    const renderGroupDetails = (groupDetails: any) => {
        if (groupDetails) {
            return <span>{groupDetails.name}</span>;
        }
        return <span className="standalone">Standalone</span>;
    };

    const renderRegistryItems = () =>
        registryCollect.map((p: any) => {
            return (
                <React.Fragment key={p.gateway_id}>
                    <div className="table-row" key={p.gateway_id}>
                        <Row className="no-checkbox">
                            <div className="gatewayList-row">
                                <div className="gatewayList-row-middle">
                                    <div className="gatewayList-row-name">
                                        <Link
                                            to={{
                                                pathname: `/gateway-details/${p.gateway_id}`,
                                            }}
                                            dangerouslySetInnerHTML={{
                                                __html: p.name.replace(
                                                    / /g,
                                                    "&nbsp;"
                                                ),
                                            }}
                                        ></Link>
                                    </div>
                                    <div className="gatewayList-row-uuid">
                                        <span className="reading-label">
                                            UUID:{" "}
                                        </span>
                                        {p.gateway_id}
                                    </div>
                                    <div className="gatewayList-row-last-active">
                                        {checkActive(p.heartbeat, !!p.info)}
                                    </div>
                                    <div className="gatewayList-row-group">
                                        <span className="reading-label">
                                            Group:{" "}
                                        </span>
                                        {renderGroupDetails(p.group)}
                                    </div>
                                </div>
                                <div className="gatewayList-row-end">
                                    <div className="gatewayList-row-delete-action">
                                        <HoverAuthorizeTooltip permission="gateway:delete">
                                            <img
                                                src={dlt}
                                                alt="delete"
                                                className="btn"
                                                onClick={() => {
                                                    setGatewayToDelete(
                                                        p.gateway_id
                                                    );
                                                    openDeleteConfirmModal(
                                                        p.name
                                                    );
                                                }}
                                            />
                                        </HoverAuthorizeTooltip>
                                    </div>
                                    <div className="gatewayList-row-detail-action">
                                        <Link
                                            to={{
                                                pathname: `/gateway-details/${p.gateway_id}`,
                                            }}
                                        >
                                            <i className="material-icons right">
                                                keyboard_arrow_right
                                            </i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </React.Fragment>
            );
        });

    const renderEmpty = useMemo(
        () => (
            <Row>
                <Col sm="12" className="mt-2">
                    <div className="empty-widget text-center">
                        <h3 className="mb-3">No Gateways Added</h3>
                        <h5 className="mb-3">Add a New Gateway</h5>
                        <Link
                            to="/gateways-add"
                            className="btn btn-primary mt-3"
                        >
                            + Add Gateway
                        </Link>
                    </div>
                </Col>
            </Row>
        ),
        []
    );

    return (
        <ContentWrapper
            isForbiddenResource={isForbiddenResource}
            title="All Gateway"
            isLoading={isPageLoading}
            simpleModalData={simpleModalData}
        >
            <div className="page-content gateway-list-page">
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <h5 className="page-title">All Gateways</h5>
                        </Col>
                    </Row>

                    {initialRegistryList.length === 0 && renderEmpty}

                    {initialRegistryList.length > 0 && (
                        <>
                            <Row>
                                <Col
                                    sm={12}
                                    className="d-md-flex justify-content-end order-md-2 order-1"
                                >
                                    <HoverAuthorizeTooltip permission="gateway:create">
                                        <Link
                                            to="/gateways-add"
                                            className="btn btn-primary add-gateway mr-3"
                                            title="You do not have permission"
                                        >
                                            + Add Gateway
                                        </Link>
                                    </HoverAuthorizeTooltip>

                                    <div className="search">
                                        <InputGroup>
                                            <FormControl
                                                type="text"
                                                placeholder="Search for.."
                                                aria-label="search-gateway"
                                                aria-describedby="button-addon2"
                                                onChange={(e) =>
                                                    searchGateway(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="cstm-table mt-4">
                                <Col sm={12}>
                                    <div className="table-head">
                                        <Row className="no-checkbox">
                                            <Col>
                                                Gateway Name {sortIcon("name")}
                                            </Col>
                                            <Col>
                                                UUID {sortIcon("gateway_id")}
                                            </Col>
                                            <Col>
                                                Last Active{" "}
                                                {sortIcon("heartbeat")}
                                            </Col>
                                            <Col>
                                                Group {sortIcon("group.name")}
                                            </Col>
                                            <Col sm="1">Action</Col>
                                            <Col sm="1"></Col>
                                        </Row>
                                    </div>
                                    <div>{renderRegistryItems()}</div>
                                    <div
                                        className={
                                            allRegistry.length === 0
                                                ? "mt-5 text-center d-block"
                                                : "mt-5 text-center d-none"
                                        }
                                    >
                                        No gateway found.
                                    </div>
                                </Col>
                            </Row>
                        </>
                    )}
                    <Modal
                        centered
                        show={modalShow}
                        onHide={closeConfirmModal}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="example-modal-sizes-title-sm"
                        className="no-header danger"
                    >
                        <Modal.Body className="text-center mt-3 mb-3">
                            <div className="modal-icon-box">
                                <span className="material-icons">delete</span>
                            </div>
                            <h3 className="mb-3">Confirmation</h3>
                            <p className="mb-4">{modalContent}</p>
                            <Button
                                variant="secondary"
                                onClick={closeConfirmModal}
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="danger"
                                onClick={handleDeleteGateway}
                            >
                                DELETE
                            </Button>
                        </Modal.Body>
                    </Modal>
                </Container>
            </div>
        </ContentWrapper>
    );
};

const GatewayGroups = () => {
    const [gatewayGroups, setGatewayGroups] = useState<Array<any>>([]);
    const [initialGatewayGroups, setInitialGatewayGroups] = useState<
        Array<any>
    >([]);
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [gatewayGroupToDelete, setGatewayGroupToDelete] = useState({
        name: "",
        uuid: "",
    });
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const { data: gatewayGroupsCollection, sortIcon } =
        useCollectSort(gatewayGroups);
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);

    useEffect(() => {
        const fetch = async () => {
            const response = await getRegistryGroups();
            if (isHttpSuccess(response.status)) {
                // API will return an empty array of data if there is no gateway
                // API will return an array with the name "ungrouped", those are your standalone gateways
                // Filtering the standalone gateways
                const lst = response.data?.filter(
                    (i: any) => i.name !== "ungrouped"
                );
                setGatewayGroups(lst);
                setInitialGatewayGroups(lst);
            } else if (response.status === HttpStatus.FORBIDDEN) {
                setIsForbiddenResource(true);
            } else {
                showErrorAlert(
                    getAPIError(
                        response,
                        "Unable to fetch gateway groups. Please try again."
                    )
                );
            }
            setIsPageLoading(false);
        };

        if (!canAccess("gateway:read")) {
            setIsPageLoading(false);
            setIsForbiddenResource(true);
        } else {
            fetch();
        }
    }, []);

    const openConfirmationDeleteModal = (name: string) => {
        setModalShow(true);
        setModalContent(`Are you sure you want to delete ${name}?`);
    };

    const closeConfirmModal = () => {
        setModalShow(false);
    };

    const handleDelete = async () => {
        closeConfirmModal();
        const response = await deleteRegistryGroup(gatewayGroupToDelete.uuid);

        if (isHttpSuccess(response.status)) {
            const cloned: any = [...gatewayGroups].filter((i: any) => {
                return i.uuid !== gatewayGroupToDelete.uuid;
            });
            const initialCloned: any = [...initialGatewayGroups].filter(
                (i: any) => {
                    return i.uuid !== gatewayGroupToDelete.uuid;
                }
            );
            setGatewayGroups(cloned);
            setInitialGatewayGroups(initialCloned);
            showSuccessAlert({
                message: `${gatewayGroupToDelete.name} deleted successfully.`,
            });
        } else {
            setSimpleModalData({
                resObj: response,
            } as SimpleModalDataType);
        }
    };

    const renderGatewayGroups = () => {
        const lst = gatewayGroupsCollection.map((gateway) => {
            return (
                <div className="table-row" key={gateway.uuid}>
                    <Row className="no-checkbox">
                        <div className="gatewayList-row">
                            <div className="gatewayList-row-middle">
                                <div className="gatewayList-row-group-name">
                                    <Link
                                        to={{
                                            pathname: `/gate-group-details/${gateway.uuid}`,
                                            state: {
                                                groupName: gateway.name,
                                            },
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: gateway.name.replace(
                                                / /g,
                                                "&nbsp;"
                                            ),
                                        }}
                                    ></Link>
                                </div>
                                <div className="gatewayList-row-group-members">
                                    <span className="reading-label">
                                        Members:{" "}
                                    </span>
                                    {gateway.gateways?.length}
                                </div>
                            </div>
                            <div className="gatewayList-row-end">
                                <div className="gatewayList-row-delete-action">
                                    <HoverAuthorizeTooltip permission="gateway:delete">
                                        <img
                                            src={dlt}
                                            alt="delete"
                                            className="btn"
                                            onClick={() => {
                                                setGatewayGroupToDelete({
                                                    name: gateway.name,
                                                    uuid: gateway.uuid,
                                                });
                                                openConfirmationDeleteModal(
                                                    gateway.name
                                                );
                                            }}
                                        />
                                    </HoverAuthorizeTooltip>
                                </div>
                                <div className="gatewayList-row-detail-action">
                                    <Link
                                        to={{
                                            pathname: `/gate-group-details/${gateway.uuid}`,
                                            state: {
                                                groupName: gateway.name,
                                            },
                                        }}
                                    >
                                        {" "}
                                        <i className="material-icons right">
                                            keyboard_arrow_right
                                        </i>{" "}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            );
        });
        return lst;
    };

    const searchGroupGateway = (query: string) => {
        if (query !== "") {
            const filtered: any = [];
            initialGatewayGroups.forEach((i) => {
                if (i.name.toLowerCase().includes(query.toLowerCase())) {
                    filtered.push(i);
                }
            });
            setGatewayGroups(filtered);
        } else {
            setGatewayGroups(initialGatewayGroups);
        }
    };

    const renderNoContent = () => {
        return (
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm="12">
                            <h5 className="page-title">Gateway Groups</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12" className="mt-2">
                            <div className="empty-widget text-center">
                                <h3 className="mb-3">
                                    No Gateway Groups Added
                                </h3>
                                <h5 className="mb-3">
                                    Add a New Gateway Group
                                </h5>
                                <HoverAuthorizeTooltip permission="gateway:create">
                                    <Link
                                        to="/new-group"
                                        className="btn btn-primary mt-3"
                                    >
                                        + New Group
                                    </Link>
                                </HoverAuthorizeTooltip>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };

    return (
        <ContentWrapper
            simpleModalData={simpleModalData}
            isForbiddenResource={isForbiddenResource}
            title="Gateway Groups"
            isLoading={isPageLoading}
        >
            {initialGatewayGroups.length === 0 ? (
                renderNoContent()
            ) : (
                <div className="page-content gateway-list-page">
                    <Container fluid>
                        <Row>
                            <Col sm={12}>
                                <h5 className="page-title">Gateway Groups</h5>
                            </Col>
                        </Row>

                        <Row>
                            <Col
                                sm={12}
                                className="d-md-flex justify-content-end order-md-2 order-1"
                            >
                                <HoverAuthorizeTooltip permission="gateway:create">
                                    <Link
                                        to="/new-group"
                                        className="btn btn-primary add-gateway mr-3"
                                    >
                                        + New Group
                                    </Link>
                                </HoverAuthorizeTooltip>
                                <div className="search">
                                    <InputGroup>
                                        <FormControl
                                            type="text"
                                            placeholder="Search for.."
                                            aria-describedby="button-addon2"
                                            aria-label="search-group"
                                            onChange={(e) => {
                                                searchGroupGateway(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row className="cstm-table mt-4">
                            <Col sm={12}>
                                <div className="table-head">
                                    <Row className="no-checkbox">
                                        <Col>Group Name {sortIcon("name")}</Col>
                                        <Col>
                                            Members{" "}
                                            {sortIcon("gateways.length")}
                                        </Col>

                                        <Col sm="1">Action</Col>
                                        <Col sm="1"></Col>
                                    </Row>
                                </div>
                                {renderGatewayGroups()}
                                <div
                                    className={
                                        gatewayGroups.length === 0
                                            ? "mt-5 text-center d-block"
                                            : "mt-5 text-center d-none"
                                    }
                                >
                                    No gateway group found.
                                </div>
                            </Col>
                        </Row>
                        <Modal
                            centered
                            show={modalShow}
                            onHide={closeConfirmModal}
                            backdrop="static"
                            keyboard={false}
                            aria-labelledby="example-modal-sizes-title-sm"
                            className="no-header danger"
                        >
                            <Modal.Body className="text-center mt-3 mb-3">
                                <div className="modal-icon-box">
                                    <span className="material-icons">
                                        delete
                                    </span>
                                </div>
                                <h3 className="mb-3">Confirmation</h3>
                                <p className="mb-4">{modalContent}</p>
                                <>
                                    <Button
                                        variant="secondary"
                                        onClick={closeConfirmModal}
                                    >
                                        CANCEL
                                    </Button>

                                    <Button
                                        variant="danger"
                                        onClick={() => {
                                            handleDelete();
                                        }}
                                    >
                                        DELETE
                                    </Button>
                                </>
                            </Modal.Body>
                        </Modal>
                    </Container>
                </div>
            )}
        </ContentWrapper>
    );
};

const Standalone = () => {
    const history = useHistory();
    const [standaloneGatewayList, setStandaloneGatewayList] = useState<
        Array<any>
    >([]);
    const [initialStandaloneGatewayList, setInitialStandaloneGatewayList] =
        useState<Array<any>>([]);
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    const [gatewayToDelete, setGatewayToDelete] = useState("");
    const { data: standaloneGatewayListCollect, sortIcon } = useCollectSort(
        standaloneGatewayList
    );

    useEffect(() => {
        const fetch = async () => {
            const res: any = await getAllRegistry();
            if (isHttpSuccess(res.status)) {
                if (res.data.length > 0) {
                    // Filter to get registry that is standalone or no group
                    const standaloneLst = res.data.filter((i: any) => !i.group);

                    const hbRes: any = await getGWReading();
                    const lastData: any = hbRes.data.map((i: any) => {
                        const items = {
                            heartbeat: i.heartbeat,
                            gateway_id: i.gateway_id,
                        };
                        return items;
                    });

                    // Push the heartbeat data to the individual registry
                    const cloned = standaloneLst;
                    for (let i = 0; i < cloned.length; i++) {
                        for (let j = 0; j < lastData.length; j++) {
                            if (
                                cloned[i].gateway_id === lastData[j].gateway_id
                            ) {
                                cloned[i].heartbeat = lastData[j].heartbeat;
                            }
                        }
                    }
                    setStandaloneGatewayList(cloned);
                    setInitialStandaloneGatewayList(cloned);
                }
            } else if (res.status === HttpStatus.FORBIDDEN) {
                setIsForbiddenResource(true);
            } else {
                showErrorAlert(getAPIError(res, FETCH_GATEWAY_FAIL_MESSAGE));
            }
            setIsPageLoading(false);
        };

        if (!canAccess("gateway:read")) {
            setIsPageLoading(false);
            setIsForbiddenResource(true);
        } else {
            fetch();
        }
    }, [history]);

    const searchGateway = (query: string) => {
        if (query !== "") {
            const filtered = initialStandaloneGatewayList.filter((gateway) =>
                gateway.name.toLowerCase().includes(query.toLowerCase())
            );
            setStandaloneGatewayList(filtered);
        } else {
            setStandaloneGatewayList(initialStandaloneGatewayList);
        }
    };

    const openDeleteConfirmModal = (gatewayName: string) => {
        setModalShow(true);
        setModalContent(
            `Are you sure you want to delete ${gatewayName}?
            This cannot be undone.`
        );
    };

    const closeConfirmModal = () => {
        setModalShow(false);
    };

    const handleDeleteGateway = async () => {
        closeConfirmModal();
        try {
            const response = await deleteRegistry(gatewayToDelete);
            if (isHttpSuccess(response.status)) {
                const cloned = [...standaloneGatewayList].filter((i: any) => {
                    return i.gateway_id !== gatewayToDelete;
                });
                const initialCloned = [...initialStandaloneGatewayList].filter(
                    (i: any) => {
                        return i.gateway_id !== gatewayToDelete;
                    }
                );
                setStandaloneGatewayList(cloned);
                setInitialStandaloneGatewayList(initialCloned);
                showSuccessAlert({
                    message: `Device unregistered successfully.`,
                });
            } else {
                setSimpleModalData({
                    resObj: response,
                } as SimpleModalDataType);
            }
        } catch (err: any) {
            setSimpleModalData({
                resObj: err,
            } as SimpleModalDataType);
        }
    };

    const renderStandalone = () => {
        const lst = standaloneGatewayListCollect.map((gateway) => {
            return (
                <div className="table-row" key={gateway.gateway_id}>
                    <Row className="no-checkbox">
                        <div className="gatewayList-row">
                            <div className="gatewayList-row-middle">
                                <div className="gatewayList-row-standalone-gateway-name">
                                    <Link
                                        to={{
                                            pathname: `/gateway-details/${gateway.gateway_id}`,
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: gateway.name.replace(
                                                / /g,
                                                "&nbsp;"
                                            ),
                                        }}
                                    ></Link>
                                </div>
                                <div className="gatewayList-row-standalone-gateway-uuid">
                                    {" "}
                                    <span className="reading-label">UUID:</span>
                                    {gateway.gateway_id}
                                </div>
                                <div className="gatewayList-row-standalone-gateway-last-active">
                                    {" "}
                                    {checkActive(
                                        gateway.heartbeat,
                                        !!gateway.info
                                    )}
                                </div>
                            </div>
                            <div className="gatewayList-row-end">
                                <div className="gatewayList-row-delete-action">
                                    {" "}
                                    <HoverAuthorizeTooltip permission="gateway:delete">
                                        <img
                                            src={dlt}
                                            alt="delete"
                                            className="btn"
                                            onClick={() => {
                                                setGatewayToDelete(
                                                    gateway.gateway_id
                                                );
                                                openDeleteConfirmModal(
                                                    gateway.name
                                                );
                                            }}
                                        />
                                    </HoverAuthorizeTooltip>
                                </div>
                                <div className="gatewayList-row-detail-action">
                                    <Link
                                        to={{
                                            pathname: `/gateway-details/${gateway.gateway_id}`,
                                        }}
                                    >
                                        <i className="material-icons right">
                                            keyboard_arrow_right
                                        </i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            );
        });
        return lst;
    };

    return (
        <ContentWrapper
            isLoading={isPageLoading}
            isForbiddenResource={isForbiddenResource}
            title="Standalone Gateways"
            simpleModalData={simpleModalData}
        >
            <div className="page-content gateway-list-page">
                <Container fluid>
                    <Row>
                        <Col sm={12}>
                            <h5 className="page-title">Standalone Gateways</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            sm={12}
                            className="d-md-flex justify-content-end order-md-2 order-1"
                        >
                            <HoverAuthorizeTooltip permission="gateway:create">
                                <Link
                                    to="/gateways-add"
                                    className="btn btn-primary add-gateway mr-3"
                                >
                                    + Add Gateway
                                </Link>
                            </HoverAuthorizeTooltip>
                            <div className="search">
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        placeholder="Search for.."
                                        aria-label="search-gateway"
                                        aria-describedby="button-addon2"
                                        onChange={(e) => {
                                            searchGateway(e.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row className="cstm-table mt-4">
                        <Col sm={12}>
                            <div className="table-head">
                                <Row className="no-checkbox">
                                    <Col>Gateway Name {sortIcon("name")}</Col>
                                    <Col>UUID {sortIcon("gateway_id")}</Col>
                                    <Col>
                                        Last Active {sortIcon("heartbeat")}
                                    </Col>

                                    <Col sm="1">Action</Col>
                                    <Col sm="1"></Col>
                                </Row>
                            </div>
                            {renderStandalone()}
                            <div
                                className={
                                    standaloneGatewayList.length === 0
                                        ? "mt-5 text-center d-block"
                                        : "mt-5 text-center d-none"
                                }
                            >
                                No gateway found.
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                centered
                show={modalShow}
                onHide={closeConfirmModal}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header danger"
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">
                        <span className="material-icons">delete</span>
                    </div>
                    <h3 className="mb-3">Confirmation</h3>
                    <p className="mb-4">{modalContent}</p>
                    <>
                        <Button variant="secondary" onClick={closeConfirmModal}>
                            CANCEL
                        </Button>
                        <Button variant="danger" onClick={handleDeleteGateway}>
                            DELETE
                        </Button>
                    </>
                </Modal.Body>
            </Modal>
        </ContentWrapper>
    );
};

export { AllGateway, GatewayGroups, Standalone };
