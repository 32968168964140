import { useState, useContext, useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import { Container, Row } from "react-bootstrap";
import LeftContent from "../components/authentication/LeftContent";
import LoginForm from "../components/authentication/LoginForm";
import EnterMFACode from "../components/authentication/EnterMFACode";
import { useAuth } from "context/auth";
import { login, submitMFACode } from "service/authenticationService";
import AccountService from "service/accountService";
import { getSubscriptionInfo } from "service/subscriptionService";
import { getAllTokens, isHttpSuccess } from "utils/functions";
import LocalStorageService from "service/localStorageService";
import AxiosInstance from "service/axiosInstance";
import { AppContext } from "context/appContext";
import {
    changeOrganizationAction,
    verifyOrgOwnerAction,
    updateIsUserOrgLoadedAction,
    storeUserAction,
    storeTokenBalanceAction,
    updateSubscriptionStatus,
    storeOrgMembersAction,
    storeOrgGroupsAction,
    storeOrgRolesAction,
    storeOrganizationAction,
} from "store/actions";
import DefaultModal from "components/modals/ModalTemplate";
import { getAllJSON, getMetricInfo } from "service/jsonService";
import { SecondsToCountdown, SubscriptionStatus } from "constant";
import { RespondAuthName } from "generated/models";
import { OrganisationDto } from "types";
import { fetchOrgList } from "utils/organisationFunction";
import { showErrorAlert } from "utils/alert";

const Home = () => {
    const { storeDispatchActions } = useContext(AppContext);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoginWithPhoneNumber, setIsLoginWithPhoneNumber] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {
        context: { setAuthTokens },
    } = useAuth();
    const [errorMessage, setErrorMessage] = useState("");
    const [isMFAEnable, setIsMFAEnable] = useState(false);
    const [mfaType, setMfaType] =
        useState<RespondAuthName>("SOFTWARE_TOKEN_MFA");
    const [session, setSession] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");
    const location: any = useLocation();
    const history: any = useHistory();

    useEffect(() => {
        if (
            window.location.pathname === "/callback" &&
            window.location.search === "?session=expired"
        ) {
            showErrorAlert({
                title: "Session expired!",
                message: "Your session has expired. Please login again.",
            });
        }
    }, []);

    const [countdown, setCountdown] = useState(SecondsToCountdown);

    const getSubscriptionStatus = async () => {
        const subscriptionRes: any = await getSubscriptionInfo();
        if (isHttpSuccess(subscriptionRes.status)) {
            for (const subscription of subscriptionRes.data) {
                if (
                    subscription.type_ === "SUBSCRIPTION" &&
                    [
                        SubscriptionStatus.ACTIVE,
                        SubscriptionStatus.CANCELED,
                        SubscriptionStatus.PENDING,
                    ].includes(subscription.status)
                ) {
                    storeDispatchActions(
                        updateSubscriptionStatus({
                            isSubscribed: true,
                            hasSubscribed: true,
                        })
                    );
                    return;
                } else {
                    storeDispatchActions(
                        updateSubscriptionStatus({
                            isSubscribed: false,
                            hasSubscribed: true,
                        })
                    );
                }
            }
        }
    };

    const getTokenBalance = async () => {
        const total: any = await getAllTokens();
        storeDispatchActions(
            storeTokenBalanceAction({ tokenBalance: total, isLoaded: true })
        );
    };

    const getUserOrgs = async (callback: () => void) => {
        const orgList = await fetchOrgList();

        let orgId = LocalStorageService.getItem("lastLoggedOrgId");

        const currentOrg =
            orgList.find((org: OrganisationDto) => org.uuid === orgId) ||
            orgList.find((org: OrganisationDto) => org.isDefault);

        if (currentOrg) {
            orgId = currentOrg.uuid;

            LocalStorageService.setItem("orgId", orgId);
            LocalStorageService.removeItem("lastLoggedOrgId");

            storeDispatchActions(storeOrganizationAction({ orgList }));

            storeDispatchActions(
                changeOrganizationAction({
                    orgInfo: currentOrg,
                })
            );
            storeDispatchActions(
                updateIsUserOrgLoadedAction({ isLoaded: true })
            );
            storeDispatchActions(
                verifyOrgOwnerAction({
                    isOwner: email === currentOrg.owner,
                })
            );

            storeDispatchActions(
                storeOrgMembersAction({
                    orgMembers: currentOrg.members,
                })
            );

            storeDispatchActions(
                storeOrgGroupsAction({
                    orgGroups: currentOrg.groups,
                })
            );

            storeDispatchActions(
                storeOrgRolesAction({
                    orgRoles: currentOrg.roles,
                })
            );

            callback();
        } else {
            alert(
                "You do not have primary organisation, please contact Technical support."
            );
        }
    };

    const loginValidation = () => {
        let isValid = true;

        if (!password.trim() || !email.trim()) isValid = false;

        return isValid;
    };

    const handleLogin = async () => {
        if (!loginValidation()) return;

        setIsLoading(true);
        try {
            let _email = email.toLocaleLowerCase();
            const response: any = await login({
                email: _email,
                password: password,
            });
            const { status, data } = response;
            if (isHttpSuccess(status)) {
                if (data.ChallengeName === "SMS_MFA") {
                    setIsLoading(false);
                    setIsError(false);
                    setErrorMessage("");
                    setEmail(_email);
                    setSession(data.Session);
                    setMfaType(data.ChallengeName);
                    setCountdown(SecondsToCountdown);
                    setModalShow(true);
                    setModalType("resendConfirmOk");
                    setModalContent(`A new code has been sent!`);
                    setIsMFAEnable(true);
                    return;
                }
                AxiosInstance.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${data.AccessToken}`;

                if (isLoginWithPhoneNumber) {
                    const userRes = await AccountService.getAccountInfo();

                    if (isHttpSuccess(userRes.status)) {
                        _email = userRes.data.email;
                        storeDispatchActions(
                            storeUserAction({
                                userData: userRes.data,
                                isLoaded: true,
                            })
                        );
                    } else {
                        throw Error(`${userRes.status}`);
                    }
                }

                getAllJSON();
                getMetricInfo();
                getUserOrgs(() => {
                    setIsLoading(false);
                    setAuthTokens(data);
                    setIsLoggedIn(true);
                    window.loadAuthorizationConfig();
                    if (
                        location.state?.referer?.pathname ===
                            "/billing-history" &&
                        location.state.referer.search &&
                        !location.state.referer.search.includes("callback")
                    ) {
                        history.push(
                            `/billing-history?callback&${location.state.referer.search.slice(
                                1
                            )}`
                        );
                    }
                });
                getTokenBalance();
                getSubscriptionStatus();
            } else if (status === 400) {
                setIsLoading(false);
                setIsError(true);
                setErrorMessage(data.description);
            } else {
                if (data.errors) {
                    setIsLoading(false);
                    setIsError(true);
                    setErrorMessage(
                        data.errors[Object.keys(data.errors)[0]][0]
                    );
                    return;
                } else if (data.description) {
                    if (data.description.includes("parameter is invalid.")) {
                        const errMsg = data.description.split("invalid.")[1];
                        setIsLoading(false);
                        setIsError(true);
                        setErrorMessage(errMsg);
                        return;
                    }
                    setIsLoading(false);
                    setIsError(true);
                    setErrorMessage(data.description);
                    return;
                }
                throw Error("404");
            }
        } catch (error: any) {
            setIsLoading(false);
            setIsError(true);
            setErrorMessage(
                error.message === "404"
                    ? "Incorrect email address or password."
                    : "Unable to login."
            );
        }
    };

    const handelSubmitMFACode = async (code: string) => {
        setErrorMessage("");
        const MFAResponse = await submitMFACode({
            name: mfaType,
            email: email,
            session: session,
            code: code,
        });

        const { status, data } = MFAResponse;

        if (status === 200) {
            AxiosInstance.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${data.AccessToken}`;

            await AccountService.getAccountInfo();

            getUserOrgs(() => {
                setIsLoading(false);
                setAuthTokens(data);
                setIsLoggedIn(true);
            });
        } else {
            setErrorMessage(
                data.description || " Invalid OTP code. Please try again."
            );
        }
    };

    const handleBackToLogin = () => {
        setIsError(false);
        setErrorMessage("");
        setIsMFAEnable(false);
    };

    const DesktopComponent = (
        <>
            <section>
                <Container fluid>
                    <Row className="flex-row-reverse">
                        {!isMFAEnable ? (
                            <LoginForm
                                handleLogin={handleLogin}
                                isLoading={isLoading}
                                isLoggedIn={isLoggedIn}
                                setEmail={setEmail}
                                setPassword={setPassword}
                                setIsLoginWithPhoneNumber={
                                    setIsLoginWithPhoneNumber
                                }
                                isError={isError}
                                errorMessage={errorMessage}
                            />
                        ) : (
                            <EnterMFACode
                                onSubmit={handelSubmitMFACode}
                                onBack={handleBackToLogin}
                                errorMessage={errorMessage}
                                handleLogin={handleLogin}
                                countdown={countdown}
                                setCountdown={setCountdown}
                            />
                        )}
                        <LeftContent></LeftContent>
                    </Row>
                </Container>
            </section>
            <DefaultModal
                modalShow={modalShow}
                modalType={modalType}
                modalContent={modalContent}
                okAction={() => {
                    setModalShow(false);
                }}
            />
        </>
    );

    return DesktopComponent;
};

export default Home;
