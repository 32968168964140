import { useState, useEffect, MouseEvent } from "react";
import { Link, useParams } from "react-router-dom";
import {
    Row,
    Col,
    InputGroup,
    FormControl,
    Form,
    Modal,
    Spinner,
} from "react-bootstrap";
import { cloneDeep } from "lodash";

import { updateAlertNotification } from "service/gatewayService";

import refreshIcon from "assets/svg/refresh-green.svg";
import { HoverAuthorizeTooltip } from "components/authorize/AuthorizeTooltip";
import { WalletAlertComponent, walletModalTrigger } from "hooks/wallet";
import useCollectSort from "hooks/useCollectSort";
import { formatReading } from "utils/gatewayFunctions";
import { isHttpSuccess } from "utils/functions";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import moment from "moment";
import { CalendarFormat } from "constant";

const SensorList = (props: any) => {
    const {
        sensors,
        alertUUIDList,
        setSensorLength,
        setAlertUUIDList,
        refreshAllSensorList,
        refreshOneSensor,
        isRefreshing,
    } = props;
    const [sensorList, setSensorList] = useState<Array<any>>([]);
    const [initialSensorList, setInitialSensorList] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState(false);

    const params: any = useParams();
    const { data: sensorCollect, sortIcon } = useCollectSort(sensorList);
    useEffect(() => {
        const fetch = () => {
            setSensorList(sensors);
            setInitialSensorList(sensors);
            setSensorLength(sensors.length);
        };
        fetch();
    }, [sensors, setSensorLength]);

    const updateAlertStatus = async (source: string, said: string) => {
        setIsLoading(true);

        let thisAlertUUID;
        let thisAlertStatus;
        let thisGatewayUUID;
        let thisIndex: any;
        for (let i = 0; i < alertUUIDList.length; i++) {
            if (
                source === alertUUIDList[i].source &&
                said === alertUUIDList[i].said
            ) {
                thisAlertUUID = alertUUIDList[i].uuid;
                thisAlertStatus = alertUUIDList[i].status;
                thisGatewayUUID = alertUUIDList[i].gatewayUUID;
                thisIndex = i;
            }
        }
        if (thisAlertUUID) {
            // if this index have alert, allow patch
            const updateResponse: any = await updateAlertNotification(
                thisGatewayUUID,
                source,
                said,
                thisAlertUUID,
                { enabled: !thisAlertStatus }
            );
            if (isHttpSuccess(updateResponse.status)) {
                const newAlertList = cloneDeep(alertUUIDList);
                newAlertList[thisIndex].status = !thisAlertStatus;
                setAlertUUIDList(newAlertList);

                showSuccessAlert({
                    message: `Alert has been ${
                        thisAlertStatus ? "disabled" : "enabled"
                    }. It will take up to 2 minutes for the operation to take effect.`,
                });
            } else {
                showErrorAlert({
                    title: updateResponse.data.title,
                    message: updateResponse.data.description,
                });
            }
            setIsLoading(false);
        } else {
            // else, tell user to configure the sensor first.
            setIsLoading(false);
            showErrorAlert({
                message: `Sensor is not yet configured.`,
            });
        }
    };

    const returnCheckboxStatus = (source: string, said: number) => {
        for (let i = 0; i < alertUUIDList.length; i++) {
            if (
                source === alertUUIDList[i].source &&
                said === alertUUIDList[i].said
            ) {
                return alertUUIDList[i].status;
            }
        }
        return false;
    };

    const returnDisabledStatus = (source: string, said: number) => {
        for (let i = 0; i < alertUUIDList.length; i++) {
            if (
                source === alertUUIDList[i].source &&
                said === alertUUIDList[i].said
            ) {
                return false;
            }
        }
        return true;
    };

    const renderUnits = (units: string) => {
        if (units === "C") {
            return "°C";
        } else if (units === "%") {
            return units;
        } else {
            return " " + units;
        }
    };

    const renderDateTime = (reading: any) => {
        if (reading) {
            return moment(reading.timestamp, "X").calendar(CalendarFormat);
        } else {
            return <>Offline</>;
        }
    };

    const handleRefreshSensor = async (
        e: MouseEvent<HTMLImageElement>,
        sensor: any,
        index: number
    ) => {
        e.currentTarget.classList.add("refresh-state");
        await refreshOneSensor(sensor.ldsu_uuid, sensor.SAID, index);

        document
            .querySelectorAll(".refresh-sensor")
            [index].classList.remove("refresh-state");
    };

    const renderSensors = () => {
        const lst = sensorCollect.map((i: any, index: number) => {
            return (
                <div className="table-row" key={`${i.ldsu_uuid}-${i.SAID}`}>
                    <Row className="no-checkbox">
                        <Col md xs={{ span: 11, order: 2 }}>
                            <Link
                                to={{
                                    pathname: `/sensor-details/${params.gatewayId}/LDSBus/${params.busNumber}/${i.ldsu_uuid}/${i.SAID}`,
                                    state: {
                                        sensorDetails: i,
                                        gatewayName: props.gatewayName,
                                        portNumber: props.portNumber,
                                        UUID: props.UUID,
                                    },
                                }}
                            >
                                {i.sensor_name}
                            </Link>
                        </Col>
                        <Col
                            md={{ order: 3, offset: 0 }}
                            xs={{ span: 11, offset: 0, order: 4 }}
                        >
                            {i.sensor_type}
                        </Col>
                        <Col
                            className="reading"
                            md={{ order: 4, offset: 0, span: 1 }}
                            xs={{ span: 11, offset: 0, order: 4 }}
                        >
                            <span className="reading-label">Reading: </span>
                            {i.readings
                                ? formatReading(i.readings.value, i.accuracy) +
                                  renderUnits(i.unit)
                                : "-"}
                        </Col>
                        <Col
                            md={{ offset: 0, span: 1 }}
                            xs={{ span: 11, offset: 0, order: 5 }}
                        >
                            <span className="reading-label">Min: </span>
                            {i.readings
                                ? formatReading(i.readings.lowest, i.accuracy)
                                : "-"}
                        </Col>
                        <Col
                            md={{ offset: 0, span: 1 }}
                            xs={{ span: 11, offset: 0, order: 6 }}
                        >
                            <span className="reading-label">Max: </span>
                            {i.readings
                                ? formatReading(i.readings.highest, i.accuracy)
                                : "-"}
                        </Col>
                        <Col
                            md={{ offset: 0, span: 2 }}
                            xs={{ span: 10, offset: 0, order: 7 }}
                        >
                            {renderDateTime(i.readings)}
                            <img
                                className="mr-2 refresh-sensor"
                                src={refreshIcon}
                                alt="refresh"
                                onClick={(e) =>
                                    handleRefreshSensor(e, i, index)
                                }
                            ></img>
                        </Col>

                        <Col md={{ span: 1 }} xs={{ span: 2, order: "last" }}>
                            <HoverAuthorizeTooltip permission="gateway:update">
                                <div>
                                    <Form.Check
                                        type="switch"
                                        id={`switch-${i.ldsu_uuid}-${i.SAID}`}
                                        label=""
                                        checked={returnCheckboxStatus(
                                            i.ldsu_uuid,
                                            i.SAID
                                        )}
                                        disabled={returnDisabledStatus(
                                            i.ldsu_uuid,
                                            i.SAID
                                        )}
                                        onChange={(e: any) => {
                                            if (
                                                !returnCheckboxStatus(
                                                    i.ldsu_uuid,
                                                    i.SAID
                                                )
                                            ) {
                                                walletModalTrigger(() =>
                                                    updateAlertStatus(
                                                        i.ldsu_uuid,
                                                        i.SAID
                                                    )
                                                );
                                            } else {
                                                updateAlertStatus(
                                                    i.ldsu_uuid,
                                                    i.SAID
                                                );
                                            }
                                        }}
                                    />
                                </div>
                            </HoverAuthorizeTooltip>
                        </Col>

                        <Col
                            md={{ span: 1, order: "last" }}
                            xs={{ span: 1, order: 3 }}
                        >
                            <Link
                                to={{
                                    pathname: `/sensor-details/${params.gatewayId}/LDSBus/${params.busNumber}/${i.ldsu_uuid}/${i.SAID}`,
                                    state: {
                                        sensorDetails: i,
                                        gatewayName: props.gatewayName,
                                        portNumber: props.portNumber,
                                        UUID: props.UUID,
                                    },
                                }}
                            >
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </Link>
                        </Col>
                    </Row>
                </div>
            );
        });
        if (lst.length === 0) {
            lst.push(
                <div
                    key={"no-sensor"}
                    className="mt-5 text-center"
                >
                    No Sensors to display
                </div>
            );
        }
        return lst;
    };

    const searchSensor = (value: string) => {
        if (value) {
            const filteredSensor = [];
            for (const i of initialSensorList) {
                if (i.sensor_name.toLowerCase().includes(value.toLowerCase())) {
                    filteredSensor.push(i);
                }
            }
            setSensorList(filteredSensor);
            setSensorLength(filteredSensor.length);
        } else {
            setSensorList(initialSensorList);
            setSensorLength(sensors.length);
        }
    };

    return (
        <>
            <Row className="mt-4">
                <Col className="table-head-options">
                    <div className="search float-right">
                        <InputGroup>
                            <FormControl
                                type="text"
                                placeholder="Search.."
                                aria-describedby="button-addon2"
                                onChange={(e) => searchSensor(e.target.value)}
                            />
                        </InputGroup>
                    </div>
                </Col>
            </Row>
            <Row className="cstm-table mt-4">
                <Col sm={12}>
                    <WalletAlertComponent showWarningWhen="LowBalance" />
                </Col>
                <Col sm={12}>
                    <div className="table-head">
                        <Row className="no-checkbox">
                            <Col>Sensor Name {sortIcon("sensor_name")}</Col>
                            <Col>Type {sortIcon("sensor_type")}</Col>
                            <Col sm="1">
                                Reading {sortIcon("readings.value")}
                            </Col>
                            <Col sm="1">Min {sortIcon("readings.lowest")}</Col>
                            <Col sm="1">Max {sortIcon("readings.highest")}</Col>
                            <Col sm="2">
                                Last Active
                                <img
                                    className={isRefreshing ? "mr-2 bigRefresh refresh-animation" : "mr-2 bigRefresh"}
                                    onClick={refreshAllSensorList}
                                    src={refreshIcon}
                                    alt="refresh"
                                ></img>
                                {sortIcon("readings.timestamp")}
                            </Col>

                            <Col sm="1">Alert</Col>
                            <Col sm="1"></Col>
                        </Row>
                    </div>
                    {renderSensors()}
                </Col>
            </Row>

            <Modal
                centered
                show={isLoading}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header"
            >
                <Modal.Body className="text-center mt-3 mb-5 mr-4">
                    <Spinner
                        className="centered-spinner"
                        animation="border"
                        variant="primary"
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SensorList;
