import { ChangeEvent, useState } from "react";
import {
    Row,
    Col,
    InputGroup,
    Button,
    Form,
    FormControl,
    Modal,
} from "react-bootstrap";

import { MonthYearTimeScheduleTemplate } from "components/events/ConditionDetail/MonthYearTimeScheduleTemplate";
import { RangeOfRecurrenceTemplate } from "components/modals/RangeOfRecurrenceTemplate";
import {
    DaysMap,
    EventConfigLimit,
    Patterns,
    START_OF_DAY_AT_FIRST_MINUTE,
} from "constant";
import { showErrorAlert } from "utils/alert";

import "assets/css/event.css";
import { HelpText } from "components/modals/EventModals/HelpTextModal";
import { RepeatDateTime } from "types/Event";
import { RemoveIcon } from "components/common";

const listOfDates = () => {
    let options = [];
    for (let i = 1; i <= 31; i++) {
        options.push(<option key={i}>{i}</option>);
    }
    return options;
};

const Monthly = ({
    allDates,
    setAllDates,
    repeatInterval,
    setRepeatInterval,
    setRecurrenceRange,
    recurrenceRange,
    renderErrorMsg,
    onChangeTimeOption,
}: any) => {
    const [onDayOption, setOnDayOption] = useState("1");
    const [firstLastOption, setFirstLastOption] = useState("first");
    const [weekdayOption, setWeekdayOption] = useState("day");
    const [checkedOption, setCheckedOption] = useState("checked_day");

    const [monthDayModalShow, setMonthDayModalShow] = useState(false);
    const handleCloseMonthDayModal = () => setMonthDayModalShow(false);

    const addDay = () => {
        let newMonthlyDate: any = {};
        switch (checkedOption) {
            case "checked_day":
                newMonthlyDate = {
                    type: "monthly_recurrence:day",
                    day: onDayOption,
                    selectedTimeOption: "all_day",
                    priTime: START_OF_DAY_AT_FIRST_MINUTE,
                    secTime: START_OF_DAY_AT_FIRST_MINUTE,
                    timeList: [],
                    timeRangeList: [],
                    selectedInterval: "hours",
                    duration: "",
                };

                break;
            case "checked_first_last":
                // if first/last day
                if (weekdayOption === "day") {
                    newMonthlyDate = {
                        type: "monthly_recurrence:day",
                        day: firstLastOption === "first" ? "1" : "-1",
                        selectedTimeOption: "all_day",
                        priTime: START_OF_DAY_AT_FIRST_MINUTE,
                        secTime: START_OF_DAY_AT_FIRST_MINUTE,
                        timeList: [],
                        timeRangeList: [],
                        selectedInterval: "hours",
                        duration: "",
                    };
                } else {
                    const weekdayVal: any = Object.keys(DaysMap).find(
                        (key) => DaysMap[key].toLowerCase() === weekdayOption
                    );
                    newMonthlyDate = {
                        type: "monthly_recurrence:firstLast",
                        day:
                            firstLastOption === "first"
                                ? weekdayVal
                                : weekdayVal * -1,
                        selectedTimeOption: "all_day",
                        priTime: START_OF_DAY_AT_FIRST_MINUTE,
                        secTime: START_OF_DAY_AT_FIRST_MINUTE,
                        timeList: [],
                        timeRangeList: [],
                        selectedInterval: "hours",
                        duration: "",
                    };
                }

                break;
        }

        // Check date is exists or not
        const invalid = allDates.some(({ type, day }: any) => {
            return type === newMonthlyDate.type && day === newMonthlyDate.day;
        });

        if (invalid) {
            return showErrorAlert({
                message: "You can not add two schedule times in same day!",
            });
        }

        setAllDates([...allDates, newMonthlyDate]);
    };

    const deleteSelection = (indexToDelete: number) => {
        // eslint-disable-next-line array-callback-return
        const updatedAllDates = allDates.filter((d: any, index: number) => {
            if (indexToDelete !== index) {
                return d;
            }
        });
        setAllDates(updatedAllDates);
    };

    const renderMonthDates = () => {
        const renderDateTitle = (monthDate: any) => {
            // if checked option - on day is chosen
            if (monthDate.type === "monthly_recurrence:day") {
                switch (monthDate.day) {
                    case "-1":
                        return <span>Last day of the month</span>;
                    case "1":
                    case "21":
                    case "31":
                        return <span>{monthDate.day}st of the month</span>;
                    case "2":
                    case "22":
                        return <span>{monthDate.day}nd of the month</span>;
                    case "3":
                    case "23":
                        return <span>{monthDate.day}rd of the month</span>;
                    default:
                        return <span>{monthDate.day}th of the month</span>;
                }
            }

            // if on first last is chosen

            let reformatText: any;

            if (monthDate.day > 0) {
                reformatText = `First ${DaysMap[monthDate.day]}`;
            } else {
                reformatText = `Last ${DaysMap[Number(monthDate.day * -1)]}`;
            }
            return <span>{reformatText} of the month</span>;
        };
        // eslint-disable-next-line array-callback-return
        return allDates.map((monthDate: RepeatDateTime, index: number) => {
            if (monthDate.type.includes("monthly_recurrence")) {
                return (
                    <div className="monthday" key={`${monthDate.type}_${index}`}>
                        <div className="monthday-date">
                            {renderDateTitle(monthDate)}
                            <RemoveIcon
                                onClick={() => {
                                    deleteSelection(index);
                                }}
                                className="float-right"
                            />
                        </div>
                        <div className="monthday-box">
                            <MonthYearTimeScheduleTemplate
                                allDates={allDates}
                                monthDate={monthDate}
                                setAllDates={setAllDates}
                                index={index}
                                renderErrorMsg={renderErrorMsg}
                                recurrence={"monthly"}
                                onChangeTimeOption={onChangeTimeOption}
                            />
                        </div>
                    </div>
                );
            }
        });
    };

    const handleRepeatFieldInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (
            e.target.value &&
            !Patterns.repeatMonthlyPattern.test(e.target.value)
        )
            return;
        setRepeatInterval({
            ...repeatInterval,
            month: e.target.value,
        });
    };

    return (
        <>
            <div className="form-box mb-3">
                <h5 className="mb-4">Repeat Every</h5>
                <InputGroup className="mt-2">
                    <FormControl
                        value={repeatInterval.month}
                        onChange={handleRepeatFieldInput}
                    />
                    <InputGroup.Text>Month(s)</InputGroup.Text>
                </InputGroup>
                <HelpText
                    min={1}
                    max={EventConfigLimit.REPEAT_MONTHLY_MAX}
                    timeUnit="month"
                />
                {renderErrorMsg("monthly", "repeat")}
            </div>
            <div className="form-box mb-3">
                <h5 className="mb-4">On</h5>

                <Button
                    variant="primary"
                    className="mb-3"
                    onClick={() => setMonthDayModalShow(true)}
                >
                    Add a Day
                </Button>
                {renderMonthDates()}
            </div>
            <RangeOfRecurrenceTemplate
                recurrence={"monthly"}
                recurrenceRange={recurrenceRange}
                setRecurrenceRange={setRecurrenceRange}
                renderErrorMsg={renderErrorMsg}
            />

            <Modal
                show={monthDayModalShow}
                onHide={() => setMonthDayModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Add a month day
                    </Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={handleCloseMonthDayModal}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className="add-a-day">
                            <div className="modal-option">
                                <Form.Check
                                    name="monthDay"
                                    id={"checked_day"}
                                    checked={checkedOption === "checked_day"}
                                    onChange={(e: any) => {
                                        setCheckedOption("checked_day");
                                    }}
                                    custom
                                    type="radio"
                                    className="float-left"
                                    label={
                                        <Row className="mt-2 mb-2">
                                            <Col sm={2}>
                                                <h4 className="mt-2">Day</h4>{" "}
                                            </Col>
                                            <Col sm={5}>
                                                <Form.Control
                                                    value={onDayOption}
                                                    onChange={(e: any) => {
                                                        setOnDayOption(
                                                            e.target.value
                                                        );
                                                    }}
                                                    as="select"
                                                    custom
                                                >
                                                    {listOfDates()}
                                                </Form.Control>
                                            </Col>
                                        </Row>
                                    }
                                />
                            </div>
                            <div className="modal-option mt-3">
                                <Form.Check
                                    name="monthDay"
                                    id={"checked_first_last"}
                                    checked={
                                        checkedOption === "checked_first_last"
                                    }
                                    onChange={(e: any) => {
                                        setCheckedOption("checked_first_last");
                                    }}
                                    custom
                                    type="radio"
                                    className="float-left"
                                    label={
                                        <Row className="mt-2 mb-1">
                                            <Col sm={2}>
                                                <h4 className="mt-2">The</h4>{" "}
                                            </Col>
                                            <Col sm={5}>
                                                <Form.Control
                                                    as="select"
                                                    custom
                                                    className="mb-2"
                                                    value={firstLastOption}
                                                    onChange={(e: any) => {
                                                        setFirstLastOption(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="first">
                                                        First
                                                    </option>
                                                    <option value="last">
                                                        Last
                                                    </option>
                                                </Form.Control>
                                            </Col>
                                            <Col sm={5}>
                                                <Form.Control
                                                    as="select"
                                                    custom
                                                    value={weekdayOption}
                                                    onChange={(e: any) => {
                                                        setWeekdayOption(
                                                            e.target.value
                                                        );
                                                    }}
                                                >
                                                    <option value="day">
                                                        Day
                                                    </option>
                                                    <option value="sunday">
                                                        Sunday
                                                    </option>
                                                    <option value="monday">
                                                        Monday
                                                    </option>
                                                    <option value="tuesday">
                                                        Tuesday
                                                    </option>
                                                    <option value="wednesday">
                                                        Wednesday
                                                    </option>
                                                    <option value="thursday">
                                                        Thursday
                                                    </option>
                                                    <option value="friday">
                                                        Friday
                                                    </option>
                                                    <option value="saturday">
                                                        Saturday
                                                    </option>
                                                </Form.Control>
                                            </Col>
                                        </Row>
                                    }
                                />
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="float-right"
                        onClick={() => {
                            addDay();
                            setMonthDayModalShow(false);
                        }}
                    >
                        ADD
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Monthly;
