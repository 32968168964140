import { RoleDto, DashboardTagOptions } from "types";
import {
    CHANGE_ORGANIZATION,
    STORE_ORGANIZATION,
    STORE_USER,
    STORE_ORG_MEMBERS,
    STORE_ORG_GROUPS,
    STORE_ORG_ROLES,
    STORE_ORG_ACTIVE_TAB,
    STORE_SYSTEM_PERMISSIONS,
    VERIFY_ORG_OWNER,
    RESTORE_DATA,
    UPDATE_MODAL_DATA,
    IS_USER_ORG_LOADED,
    STORE_DASHBOARD,
    CHANGE_DASHBOARD,
    STORE_PANELS,
    STORE_TOKEN_BALANCE,
    UPDATE_SUBSCRIPTION_STATUS,
    STORE_SELECTED_TAB,
    STORE_SUBSCRIPTION_LIST,
    STORE_SUBSCRIPTION_ID,
} from "./actions";
import defaultState from "./state";
import { UserDto } from "types/Account";

export const dashboardReducer = (state: any, action: any) => {
    switch (action.type) {
        case STORE_DASHBOARD: {
            const { dashboardList, targetId } = action.payload;

            const currentDashboard = dashboardList.find(
                (item: DashboardTagOptions) => item.key === targetId
            );

            const newSate = {
                ...state,
                dashboardList,
                currentDashboard: currentDashboard ?? dashboardList[0],
            };

            return newSate;
        }
        case CHANGE_DASHBOARD:
            return {
                ...state,
                currentDashboard: action.payload.dashboard || {},
            };
        case STORE_PANELS:
            return {
                ...state,
                panels: action.payload.panels,
            };
        case RESTORE_DATA:
            return {
                ...state,
                ...defaultState.dashboard,
            };
        case STORE_SELECTED_TAB:
            return {
                ...state,
                currentSelectedTab: action.payload,
            };
        default:
            return state;
    }
};

export const organizationReducer = (state: any, action: any) => {
    switch (action.type) {
        case CHANGE_ORGANIZATION:
            return {
                ...state,
                currentOrgId: action.payload.orgInfo.uuid,
                currentOrgInfo: toCamelCaseObject(action.payload.orgInfo),
            };
        case STORE_ORGANIZATION: {
            const newState = {
                ...state,
                orgList: action.payload.orgList.toCamelCase(),
            };
            return newState;
        }
        case STORE_ORG_MEMBERS: {
            const newState = {
                ...state,
                currentOrgMembers: action.payload.orgMembers,
            };
            return newState;
        }
        case STORE_ORG_GROUPS: {
            const newState = {
                ...state,
                currentOrgGroups: (
                    action.payload.orgGroups || []
                ).toCamelCase(),
            };
            return newState;
        }
        case STORE_ORG_ROLES: {
            const newState = {
                ...state,
                currentOrgRoles: (action.payload.orgRoles || [])
                    .map((r: RoleDto) => ({
                        ...r,
                        createTime: new Date(r.createTime),
                        modifyTime: new Date(r.modifyTime),
                    }))
                    .sort(
                        (a: RoleDto, b: RoleDto) =>
                            a.createTime.getTime() - b.createTime.getTime()
                    ),
            };
            return newState;
        }
        case VERIFY_ORG_OWNER: {
            const newState = {
                ...state,
                currentUserIsOwner: action.payload.isOwner,
            };
            return newState;
        }
        case STORE_ORG_ACTIVE_TAB: {
            const newState = {
                ...state,
                currentActiveTab: action.payload.tab,
            };
            return newState;
        }
        case IS_USER_ORG_LOADED:
            return {
                ...state,
                isUserOrgLoaded: action.payload.isLoaded,
            };
        case RESTORE_DATA:
            return {
                ...state,
                ...defaultState.organization,
                currentOrgId: state.currentOrgId,
            };
        default:
            return state;
    }
};

export const userReducer = (state: any, action: any) => {
    switch (action.type) {
        case STORE_USER: {
            const newState = {
                ...state,
                userData: toCamelCaseObject<UserDto>(action.payload.userData),
                isLoaded: action.payload.isLoaded || false,
            };
            return newState;
        }
        case RESTORE_DATA:
            return {
                ...state,
                ...defaultState.user,
            };
        default:
            return state;
    }
};

export const subscriptionReducer = (state: any, action: any) => {
    switch (action.type) {
        case STORE_SUBSCRIPTION_ID: {
            const newState = {
                ...state,
                currentSubscriptionId: action.payload,
            };
            return newState;
        }
        case STORE_SUBSCRIPTION_LIST: {
            const newState = {
                ...state,
                subList: action.payload,
            };
            return newState;
        }
        case STORE_TOKEN_BALANCE: {
            const newState = {
                ...state,
                tokenBalance: action.payload.tokenBalance,
                isLoaded: action.payload.isLoaded,
            };
            return newState;
        }

        case UPDATE_SUBSCRIPTION_STATUS: {
            const newState = {
                ...state,
                isSubscribed: action.payload.isSubscribed,
                hasSubscribed: action.payload.hasSubscribed,
            };
            return newState;
        }

        case RESTORE_DATA:
            return {
                ...state,
                ...defaultState.subscription,
            };
        default:
            return state;
    }
};

export const systemPerReducer = (state: any, action: any) => {
    switch (action.type) {
        case STORE_SYSTEM_PERMISSIONS: {
            return {
                ...state,
                loaded: action.payload.loaded,
                permissions: action.payload.permissions,
            };
        }
        case RESTORE_DATA:
            return {
                ...state,
                ...defaultState.systemPermissions,
            };
        default:
            return state;
    }
};

export const globalModalReducer = (state: any, action: any) => {
    switch (action.type) {
        case UPDATE_MODAL_DATA: {
            return {
                ...state,
                isShow: action.payload.isShow,
                mainMessage: action.payload.mainMessage,
                subMessage: action.payload.subMessage || [],
                redirectTo: action.payload.redirectTo || "",
            };
        }
        case RESTORE_DATA:
            return {
                ...state,
                ...defaultState.globalModal,
            };
        default:
            return state;
    }
};

// Function combine all app ruduces
const combineReducers = (slices: any) => (state: any, action: any) =>
    Object.keys(slices).reduce(
        (acc, prop) => ({
            ...acc,
            [prop]: slices[prop](acc[prop], action),
        }),
        state
    );

const AppReducer = combineReducers({
    dashboard: dashboardReducer,
    organization: organizationReducer,
    user: userReducer,
    systemPermissions: systemPerReducer,
    globalModal: globalModalReducer,
    subscription: subscriptionReducer,
});

export default AppReducer;
