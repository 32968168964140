import { HoverAuthorizeTooltip } from "components/authorize/AuthorizeTooltip";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import moment from "moment";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
    clearGatewayData,
    getIsDeletionTaskRunning,
} from "service/gatewayService";
import dltWhiteIcon from "assets/svg/delete-white.svg";
import refreshtIcon from "assets/svg/refresh-green.svg";
import { showErrorAlert } from "../../utils/alert";
import DefaultModal from "components/modals/ModalTemplate";

export const ClearDataUI = ({ gatewayId }: { gatewayId: string }) => {
    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isDeletionTaskRunning, setIsDeletionTaskRunning] = useState(true);
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);

    const fetchAll = () => {
        setIsLoading(true);
        getIsDeletionTaskRunning(gatewayId)
            .then((isRunning: boolean) => {
                setIsDeletionTaskRunning(isRunning);
                setIsLoading(false);
            })
            .catch(() => {
                showErrorAlert({
                    message: "Refresh status error, Please try again!",
                });
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchAll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshThisGateway = async () => {
        fetchAll();
    };

    const handleClearData = () =>
        clearGatewayData(gatewayId, {
            start: 1,
            end: moment().unix(),
        })
            .then(() => {
                setModalShow(false);
                fetchAll();
            })
            .catch((err) => {
                setSimpleModalData({
                    resObj: err,
                } as SimpleModalDataType);
            });

    return (
        <ContentWrapper simpleModalData={simpleModalData}>
            <div className="storage-button mt-5">
                <HoverAuthorizeTooltip permission="gateway:update">
                    <Button
                        variant="danger"
                        disabled={isDeletionTaskRunning}
                        onClick={() => {
                            setModalShow(true);
                        }}
                    >
                        <img
                            src={dltWhiteIcon}
                            className="mr-2 ml-2"
                            alt="delete"
                        />{" "}
                        Clear Data
                    </Button>
                </HoverAuthorizeTooltip>
            </div>
            {isDeletionTaskRunning && (
                <div className="storage-button mt-5">
                    <div className="refresh-zone">
                        <p className="header">Clear data request</p>
                        <p>
                            Data clearing is in-progress...
                            <img
                                className={
                                    isLoading
                                        ? "btn-refresh refresh-animation"
                                        : "btn-refresh"
                                }
                                src={refreshtIcon}
                                alt="refresh"
                                onClick={refreshThisGateway}
                            ></img>
                        </p>
                        <p>This may take a few hours to complete.</p>
                    </div>
                </div>
            )}

            <DefaultModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                modalType="updateConfirm"
                modalTitle="Confirm"
                modalContent={
                    <>
                        Do you want to clear all the data related to this
                        gateway?
                    </>
                }
                resendFunction={handleClearData}
            />
        </ContentWrapper>
    );
};
