import { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router";
import debounce from 'lodash/debounce';
import PermissionService from "service/permissionService";
import { OrganisationPermissions } from "types";
import { useAppContext } from "context/appContext";
import { storeSystemPermissions } from "store/actions";
import { HttpStatus } from "constant";
import { DefaultPermissions } from "components/organizations/PermissionList";

export function usePermissions(orgId?: string) {
    const [permissions, setPermissions] = useState<OrganisationPermissions>(DefaultPermissions);
    const [isLoaded, setIsLoaded] = useState(false);
    const { 
        storeData: { 
            organization: {
                currentOrgId,
            },
            systemPermissions
        },
        storeDispatchActions
    } = useAppContext();

    useEffect(() => {
        const _orgId = orgId || currentOrgId;
        if(orgId === currentOrgId && systemPermissions.loaded){
            setPermissions(systemPermissions.permissions);
            setIsLoaded(systemPermissions.loaded);
        } else {
            !systemPermissions.loaded && _orgId && PermissionService.getDefaultPermissions(_orgId)
            .then(({status, data}) => {
                if (status === HttpStatus.OK) {
                    storeDispatchActions(
                        storeSystemPermissions({
                            loaded: true,
                            permissions: data,
                        })
                    );

                    setPermissions(data);
                    setIsLoaded(true);
                }
            });
        }
    },[systemPermissions, currentOrgId, orgId, storeDispatchActions]);

    return { permissions, isLoaded };
}

export function useDebounce(handle: Function) {
    return useMemo(() => debounce((...args: any) => handle(...args), 300),[handle]);
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}


