import { useEffect, useState, FormEvent } from "react";
import {
    Form,
    Button,
    Modal,
    FormControl,
    InputGroup,
    Spinner,
} from "react-bootstrap";
import {
    getAllCoupons,
    updateCoupon,
    getSubscriptionPrice,
    getAddonPrice,
    getCouponInfoByName,
} from "service/subscriptionService";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import { showErrorAlert } from "utils/alert";

import { CheckoutType } from "constant";
import { get } from "lodash";
import { isHttpSuccess } from "utils/functions";

const PromoCode = (props: any) => {
    const {
        plan,
        subscriptionUUID,
        setSelectedPromocode,
        country,
        setPrice,
        checkoutType,
        addonUUID,
        selectedPromocode,
        bundleQty,
    } = props;
    const [couponList, setCouponList] = useState([]);
    const [secretPromocode, setSecretPromocode] = useState("");
    const [selectPromocodeShow, setSelectPromocodeShow] = useState(false);
    const [isActionPending, setIsActionPending] = useState(false);

    useEffect(() => {
        let ignore = false;

        (async () => {
            const allCouponsRes: any = await getAllCoupons();

            if (isHttpSuccess(allCouponsRes.status) && !ignore) {
                const { data } = allCouponsRes;
                setCouponList(data);
            }
        })();

        return () => {
            ignore = true;
            setCouponList([]);
        };
    }, []);

    const applyAvailablePromocode = async (
        promoCode: string,
        description: string
    ) => {
        setIsActionPending(true);
        switch (checkoutType) {
            case CheckoutType.PLAN: {
                const addCouponRes: any = await updateCoupon({
                    subscription_uuid: subscriptionUUID,
                    add: [`${promoCode}`],
                    remove: [],
                });

                if (isHttpSuccess(addCouponRes.status)) {
                    const updatedPrice: any = await getSubscriptionPrice(
                        subscriptionUUID,
                        country
                    );

                    if (isHttpSuccess(updatedPrice.status)) {
                        const {
                            amount,
                            total,
                            tax,
                            discount,
                            addon,
                            tax_rate,
                        } = updatedPrice.data;

                        setPrice({
                            amount: amount,
                            total: total,
                            tax: tax,
                            discount: discount,
                            addon: addon,
                            tax_rate: tax_rate,
                        });
                        setSelectedPromocode({
                            promocode_name: promoCode,
                            description: description,
                        });
                        setSelectPromocodeShow(false);
                    } else {
                        removePromocode(promoCode);

                        showErrorAlert({
                            title: updatedPrice.data.title,
                            message: updatedPrice.data.description,
                        });

                        setIsActionPending(false);

                        return;
                    }
                } else {
                    showErrorAlert({
                        title: addCouponRes.data.title,
                        message: addCouponRes.data.description,
                    });
                }
                setIsActionPending(false);
                break;
            }
            case CheckoutType.ADDON: {
                const addonPrice: any = await getAddonPrice({
                    country,
                    addon_uuid: addonUUID,
                    coupon: promoCode,
                    quantity: bundleQty,
                });

                if (isHttpSuccess(addonPrice.status)) {
                    setSelectPromocodeShow(false);
                    setSelectedPromocode({
                        description,
                        promocode_name: promoCode,
                    });
                    const { amount, total, tax, discount, addon, tax_rate } =
                        addonPrice.data;

                    setPrice({
                        amount,
                        total,
                        tax,
                        discount,
                        addon,
                        tax_rate,
                    });
                } else {
                    showErrorAlert({
                        title: addonPrice.data.title,
                        message: addonPrice.data.description,
                    });
                }
                setIsActionPending(false);
                break;
            }
        }
    };

    const applySecretPromocode = async (e: FormEvent) => {
        e.preventDefault();
        setIsActionPending(true);
        if (!secretPromocode.trim()) {
            setIsActionPending(false);

            showErrorAlert({ message: "Please enter a Promocode." });

            return;
        }

        switch (checkoutType) {
            case CheckoutType.PLAN: {
                const [secretPromocodeRes, addSecretCodeRes]: any =
                    await Promise.all([
                        getCouponInfoByName(secretPromocode),
                        updateCoupon({
                            subscription_uuid: subscriptionUUID,
                            add: [`${secretPromocode}`],
                            remove: [],
                        }),
                    ]);

                if (
                    isHttpSuccess(secretPromocodeRes.status) &&
                    isHttpSuccess(addSecretCodeRes.status)
                ) {
                    const { usages } = secretPromocodeRes.data ?? {};

                    const updatedPrice: any = await getSubscriptionPrice(
                        subscriptionUUID,
                        country
                    );

                    if (isHttpSuccess(updatedPrice.status)) {
                        const {
                            amount,
                            total,
                            tax,
                            discount,
                            addon,
                            tax_rate,
                        } = updatedPrice.data;

                        setPrice({
                            amount: amount,
                            total: total,
                            tax: tax,
                            discount: discount,
                            addon: addon,
                            tax_rate: tax_rate,
                        });
                        const description = usages?.[0] ?? "";
                        setSelectedPromocode({
                            promocode_name: secretPromocode,
                            description: description,
                        });
                        setSelectPromocodeShow(false);
                    } else {
                        removePromocode(secretPromocode);
                        showErrorAlert({
                            title: updatedPrice.data.title,
                            message: updatedPrice.data.description,
                        });

                        setIsActionPending(false);
                        return;
                    }
                    setSecretPromocode("");
                } else {
                    showErrorAlert({
                        title: addSecretCodeRes.data.title,
                        message: addSecretCodeRes.data.description,
                    });

                    setIsActionPending(false);
                    return;
                }
                setIsActionPending(false);
                break;
            }
            case CheckoutType.ADDON: {
                const [secretPromocodeAddonRes, addonPrice]: any =
                    await Promise.all([
                        getCouponInfoByName(secretPromocode),
                        getAddonPrice({
                            addon_uuid: addonUUID,
                            country,
                            coupon: secretPromocode,
                            quantity: bundleQty,
                        }),
                    ]);

                if (
                    isHttpSuccess(secretPromocodeAddonRes.status) &&
                    isHttpSuccess(addonPrice.status)
                ) {
                    const { usages } = secretPromocodeAddonRes.data ?? {};
                    const description = usages?.[0] ?? "";
                    setSelectPromocodeShow(false);
                    setSelectedPromocode({
                        promocode_name: secretPromocode,
                        description: description,
                    });

                    if (isHttpSuccess(addonPrice.status)) {
                        const {
                            amount,
                            total,
                            tax,
                            discount,
                            addon,
                            tax_rate,
                        } = addonPrice.data;

                        setPrice({
                            amount: amount,
                            total: total,
                            tax: tax,
                            discount: discount,
                            addon: addon,
                            tax_rate,
                        });
                    }
                    setSecretPromocode("");
                } else {
                    const resp = !isHttpSuccess(secretPromocodeAddonRes.status)
                        ? secretPromocodeAddonRes
                        : addonPrice;

                    showErrorAlert({
                        title: resp.data.title,
                        message: resp.data.description,
                    });
                }
                setIsActionPending(false);
                break;
            }
        }
    };

    const removePromocode = async (promoCode: string) => {
        switch (checkoutType) {
            case CheckoutType.PLAN: {
                const removeCouponRes: any = await updateCoupon({
                    subscription_uuid: subscriptionUUID,
                    add: [],
                    remove: [`${promoCode}`],
                });

                if (isHttpSuccess(removeCouponRes.status)) {
                    setSelectedPromocode({
                        promocode_name: "",
                        description: "",
                    });

                    const updatedPrice: any = await getSubscriptionPrice(
                        subscriptionUUID,
                        country
                    );

                    if (isHttpSuccess(updatedPrice.status)) {
                        setPrice(updatedPrice.data);
                    }
                }
                break;
            }
            case CheckoutType.ADDON: {
                const updatedPrice: any = await getAddonPrice({
                    addon_uuid: addonUUID,
                    country,
                    coupon: "",
                    quantity: bundleQty,
                });

                if (isHttpSuccess(updatedPrice.status)) {
                    setPrice(updatedPrice.data);
                }
                setSelectedPromocode({
                    promocode_name: "",
                    description: "",
                });
                break;
            }
        }
    };

    const renderSelectedPromocode = () => {
        if (selectedPromocode?.promocode_name)
            return (
                <div className="enter-promocode">
                    <div className="w-100 float-left mb-2">
                        <h6>
                            Promocode: {selectedPromocode.promocode_name}{" "}
                            Applied
                        </h6>
                        <span
                            className="material-icons float-right cancel-promo"
                            aria-label="remove-promocode"
                            onClick={() => {
                                removePromocode(
                                    selectedPromocode.promocode_name
                                );
                            }}
                        >
                            cancel
                        </span>
                    </div>

                    <p className="m-0">{selectedPromocode.description}</p>
                </div>
            );
    };

    const renderPromocode = () => {
        const promocode = couponList.map((c: any) => {
            if (plan?.name === c.match_plan.name || c.match_plan.any) {
                return (
                    <div className="modal-option" key={c.uuid}>
                        <div
                            className="float-left"
                            aria-label="coupon-item-name"
                        >
                            <h5>{c.name}</h5>
                            <p>{get(c, "usages[0]", "")}</p>
                        </div>
                        <Button
                            variant=""
                            onClick={() => {
                                applyAvailablePromocode(
                                    c.name,
                                    get(c, "usages[0]", "")
                                );
                            }}
                            aria-label="coupon-item-button"
                        >
                            APPLY
                        </Button>
                    </div>
                );
            }
            return "";
        });
        return promocode;
    };

    const closePromocodeModal = () => {
        setSelectPromocodeShow(false);
        setSecretPromocode("");
    };

    return (
        <ContentWrapper>
            {!selectedPromocode?.promocode_name ? (
                <div className="enter-promocode">
                    <h6>Promocode</h6>
                    <Button
                        variant=""
                        className="enter-promo-btn"
                        onClick={() => setSelectPromocodeShow(true)}
                    >
                        Select or Enter code
                    </Button>
                </div>
            ) : (
                ""
            )}
            {renderSelectedPromocode()}

            <Modal
                show={selectPromocodeShow}
                onHide={closePromocodeModal}
                animation={false}
                centered
                aria-label="promocode-modal"
            >
                <Modal.Header>
                    <Modal.Title>Select Promocode</Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={closePromocodeModal}
                    >
                        <span
                            className="material-icons"
                            aria-label="promocode-modal-close-button"
                        >
                            close
                        </span>
                    </Button>
                </Modal.Header>

                <Modal.Body>
                    <Form
                        className="floar-left"
                        onSubmit={applySecretPromocode}
                    >
                        <InputGroup>
                            <FormControl
                                type="text"
                                placeholder="Enter Promocode"
                                value={secretPromocode}
                                onChange={(e: any) => {
                                    setSecretPromocode(e.target.value);
                                }}
                            ></FormControl>
                            <Button
                                variant="primary"
                                className="ml-2"
                                type="submit"
                                aria-label="secret-apply-button"
                            >
                                APPLY
                            </Button>
                        </InputGroup>

                        <div className="scroll-list float-left">
                            <div className="promocode-list">
                                <p className="mt-3">
                                    Promocodes available to you
                                </p>
                                {renderPromocode()}
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                centered
                show={isActionPending}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header"
            >
                <Modal.Body className="text-center mt-3 mb-5 mr-4">
                    <Spinner
                        className="centered-spinner"
                        animation="border"
                        variant="primary"
                    />
                </Modal.Body>
            </Modal>
        </ContentWrapper>
    );
};

export default PromoCode;
