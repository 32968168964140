import { InputGroup, FormControl } from "react-bootstrap";
import { TimeScheduleTemplate } from "components/events/ConditionDetail/TimeScheduleTemplate";
import { RangeOfRecurrenceTemplate } from "components/modals/RangeOfRecurrenceTemplate";

import "assets/css/event.css";
import { HelpText } from "components/modals/EventModals/HelpTextModal";
import { EventConfigLimit, Patterns } from "constant";
import { ChangeEvent } from "react";

const Daily = ({
    priTime,
    setPriTime,
    secTime,
    setSecTime,
    timeList,
    setTimeList,
    timeRangeList,
    setTimeRangeList,
    selectedInterval,
    setSelectedInterval,
    duration,
    setDuration,
    selectedTimeOption,
    setSelectedTimeOption,
    errorType,
    setErrorType,
    repeatInterval,
    setRepeatInterval,
    day,
    setRecurrenceRange,
    recurrenceRange,
    renderErrorMsg,
}: any) => {
    const handleRepeatFieldInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value && !Patterns.repeatDailyPattern.test(e.target.value))
            return;
        setRepeatInterval({
            ...repeatInterval,
            day: e.target.value,
        });
    };
    return (
        <>
            <div className="form-box mb-3">
                <h5 className="mb-4">Repeat Every</h5>
                <InputGroup className="mt-2">
                    <FormControl
                        value={repeatInterval.day}
                        onChange={handleRepeatFieldInput}
                    />
                    <InputGroup.Text>Day(s)</InputGroup.Text>
                </InputGroup>
                <HelpText
                    min={1}
                    max={EventConfigLimit.REPEAT_DAILY_MAX}
                    timeUnit="day"
                />

                {renderErrorMsg("daily", "repeat")}
            </div>
            <div className="form-box mb-3">
                <h5 className="mb-4">Time Schedule</h5>
                <TimeScheduleTemplate
                    priTime={priTime}
                    setPriTime={setPriTime}
                    secTime={secTime}
                    setSecTime={setSecTime}
                    selectedTimeOption={selectedTimeOption}
                    setSelectedTimeOption={setSelectedTimeOption}
                    selectedInterval={selectedInterval}
                    setSelectedInterval={setSelectedInterval}
                    duration={duration}
                    setDuration={setDuration}
                    timeList={timeList}
                    setTimeList={setTimeList}
                    timeRangeList={timeRangeList}
                    setTimeRangeList={setTimeRangeList}
                    errorType={errorType}
                    setErrorType={setErrorType}
                    day={day}
                    renderErrorMsg={renderErrorMsg}
                    recurrence={"daily"}
                />
            </div>
            <RangeOfRecurrenceTemplate
                recurrence={"daily"}
                recurrenceRange={recurrenceRange}
                setRecurrenceRange={setRecurrenceRange}
                renderErrorMsg={renderErrorMsg}
            />
        </>
    );
};

export default Daily;
