import { useEffect, useState, useContext, memo, useRef } from "react";
import ReactECharts from "echarts-for-react";
import { AppContext } from "context/appContext";
import { Dropdown, Button, Modal } from "react-bootstrap";
import { deletePanelV2 } from "service/dashboardService";
import { getOneRegistry } from "service/gatewayService";
import { getOnlineOfflineValue } from "service/proqueryService";
import {
    CHART_DELETE_SUCCESS_MESSAGE,
    ForbiddenErrorMessage,
    HttpStatus,
    LastTimeSelectionStatusChart,
} from "constant";
import { convertMinutesToHours } from "utils/functions";
import { EditDropdownLink } from "components/dashboard/update";
import { faker } from "@faker-js/faker";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import ChartBox from "./ChartBox";
import { showSuccessAlert } from "utils/alert";

const StatusChart = (props: any) => {
    const {
        panel,
        selectedDashboardUUID,
        handleOnChangeDashboardUUID,
        zoomLevel,
    } = props;
    const panelToken = panel.token[0] || "";
    const thisGatewayId = panel.attributes[0]?.gateway_id;
    const thisPanelId = panel.uuid;
    const chartType = panel.chart;
    const { OK, NO_CONTENT, FORBIDDEN, PAYMENT_REQUIRED } = HttpStatus;
    const { storeData } = useContext(AppContext);
    const { organization } = storeData;
    const { currentOrgInfo } = organization;
    const { uuid: currOrgID, owner } = currentOrgInfo;
    // ----------------- delete model -----------------
    const [deleteModalState, setDeleteModalState] = useState<any>({
        isVisible: false,
        errMess: "",
    });
    const [gatewayName, setGatewayName] = useState("");
    const lastData = LastTimeSelectionStatusChart.map((i) => i.time);
    const [lastDataValue, setLastDataValue] = useState("1 Day");
    const [timeValue, setTimeValue] = useState("1d");
    const [onlineTime, setOnlineTime] = useState("0");
    const [offlineTime, setOfflineTime] = useState("0");
    const [totalTime, setTotalTime] = useState("1440");

    const [isOutOfPaymentToken, updateIsOutOfPaymentToken] = useState({
        state: false,
        msg: "",
    });
    const [boxWidth, setBoxWidth] = useState(100);

    const boxElementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const box = boxElementRef.current;
        if (box) {
            setBoxWidth(box.clientWidth - 80);
            const resizeObserver = new ResizeObserver((entries) => {
                setBoxWidth(entries[0]?.target.clientWidth - 80);
            });

            resizeObserver.observe(box);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [boxElementRef]);

    useEffect(() => {
        if (!currOrgID || !owner) return;
        const fetch = async () => {
            const registryRes: any = await getOneRegistry(thisGatewayId);
            if (registryRes.status === OK) {
                const { name } = registryRes.data;
                setGatewayName(name);
            }
        };
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [panel, currOrgID, owner, thisGatewayId]);

    useEffect(() => {
        const fetch = async () => {
            const onlineOfflineRes: any = await getOnlineOfflineValue(
                thisGatewayId,
                timeValue,
                panelToken
            );

            if (onlineOfflineRes.status === OK) {
                const value =
                    onlineOfflineRes.data?.data?.result[0]?.value[1] || "0";
                setOnlineTime(convertMinutesToHours(value));
                setOfflineTime(
                    convertMinutesToHours(Number(totalTime) - Number(value))
                );
            } else {
                if (onlineOfflineRes.response?.status === FORBIDDEN) {
                    const status =
                        onlineOfflineRes.response.headers["x-authpoint-status"];
                    const { description } =
                        onlineOfflineRes.response?.data || "";
                    switch (Number(status)) {
                        case PAYMENT_REQUIRED:
                            updateIsOutOfPaymentToken({
                                state: true,
                                msg: description,
                            });

                            break;
                    }
                }
            }
        };
        if (isOutOfPaymentToken.state) {
            return;
        }
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOutOfPaymentToken, thisGatewayId, timeValue, totalTime, panelToken]);

    const setShowDeleteModal = () => {
        setDeleteModalState({
            ...deleteModalState,
            isVisible: true,
        });
    };
    const handleCloseDeleteModal = () => {
        setDeleteModalState({
            ...deleteModalState,
            isVisible: false,
        });
    };

    const handleDeletePanel = async () => {
        deletePanelV2(selectedDashboardUUID, thisPanelId).then(
            (result: any) => {
                if (result.status === NO_CONTENT) {
                    handleOnChangeDashboardUUID(selectedDashboardUUID);
                    setDeleteModalState({
                        ...deleteModalState,
                        isVisible: false,
                    });
                    showSuccessAlert({
                        message: CHART_DELETE_SUCCESS_MESSAGE,
                    });
                } else if (result.response?.status === FORBIDDEN) {
                    setDeleteModalState({
                        ...deleteModalState,
                        errMess: ForbiddenErrorMessage,
                    });
                }
            }
        );
    };
    const handleLastData = (value: any) => {
        setLastDataValue(value);
        for (const i of LastTimeSelectionStatusChart) {
            if (i.time === value) {
                setTimeValue(i.dataValue);
                setTotalTime(i.totalMinutes);
            }
        }
    };
    const option: any = {
        backgroundColor: "#2F3A51",
        responsive: true,
        maintainAspectRatio: false,
        tooltip: {
            trigger: "item",
        },
        title: {
            show: true,
            left: 14,
            top: 15,
            text: panel.name || `${gatewayName} Status Chart`,
            textStyle: {
                color: "#ffffff",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 18,
                overflow: "truncate",
                ellipsis: "...",
                width: boxWidth,
            },
            subtext: `Online/Offline Status \n\n{s2|Online - ${onlineTime} Hours}\n\n{s3|Offline - ${offlineTime} Hours}`,
            subtextStyle: {
                color: "#828FAB",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 14,
                overflow: "truncate",
                ellipsis: "...",
                rich: {
                    s1: {
                        color: "#828fab",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: 14,
                    },
                    s2: {
                        color: "#FC6464",
                        fontSize: 14,
                        fontFamily: "Roboto",
                        fontWeight: "bold",
                    },
                    s3: {
                        color: "#9A6363",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                    },
                    s4: {
                        color: "#FFFFFF",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: "Roboto",
                    },
                    s5: {
                        color: "#828fab",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                        fontSize: 18,
                    },
                },
            },
        },
        legend: {
            show: false,
        },
        color: ["#FC6464", "#9A6363", "#3E4B67"],
        series: [
            {
                type: "pie",
                radius:
                    chartType.toUpperCase() === "PIE" ? "55%" : ["30%", "55%"],
                minAngle: offlineTime === "0" || onlineTime === "0" ? 0 : 5,
                top: 60,
                avoidLabelOverlap: true,
                label: {
                    formatter: "{b|{b} -} {d|{d}%}",
                    show: true,
                    position: "outside",
                    fontSize: 12,
                    rich: {
                        b: {
                            lineHeight: 22,
                            align: "center",
                        },
                        d: {
                            lineHeight: 22,
                            align: "center",
                        },
                        e: {
                            lineHeight: 10,
                            align: "center",
                        },
                    },
                },
                labelLine: {
                    show: true,
                    length: 5,
                    length2: 20,
                    showAbove: true,
                },
                data: [
                    {
                        value: onlineTime,
                        name: "Online",
                    },
                    {
                        value: offlineTime,
                        name: "Offline",
                    },
                ],
            },
        ],
    };

    const notEnoughPaymentTokenOption: any = {
        backgroundColor: "#2F3A51",
        responsive: true,
        maintainAspectRatio: false,
        tooltip: {
            trigger: "item",
        },
        title: {
            show: true,
            left: 14,
            top: 15,
            text: `${gatewayName}`,
            textStyle: {
                color: "#ffffff",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 18,
                overflow: "truncate",
                ellipsis: "...",
            },
            subtext: `Online/Offline Status`,
            subtextStyle: {
                color: "#828FAB",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 14,
                overflow: "truncate",
                ellipsis: "...",
            },
        },
    };

    return (
        <>
            <ChartBox
                className={"widget-box temperature"}
                style={{
                    height: zoomLevel <= 0.5 ? "30vh" : 400,
                    width: "100%",
                }}
                ref={boxElementRef}
            >
                <div className="restricted-chart">
                    {isOutOfPaymentToken.state ? isOutOfPaymentToken.msg : ""}
                </div>
                <div className="widget">
                    <Dropdown alignRight>
                        <Dropdown.Toggle variant="" className="more-icon">
                            <i className="material-icons">more_vert</i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <HoverAuthorizeTooltip permission="dashboard:update">
                                <EditDropdownLink panelId={panel.uuid} />
                            </HoverAuthorizeTooltip>
                            <HoverAuthorizeTooltip permission="dashboard:delete">
                                <Dropdown.Item onClick={setShowDeleteModal}>
                                    Delete
                                </Dropdown.Item>
                            </HoverAuthorizeTooltip>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown alignRight>
                        <Dropdown.Toggle variant="" className="last-data">
                            {lastDataValue}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {lastData.map((item) => {
                                return (
                                    <Dropdown.Item
                                        key={faker.datatype.uuid()}
                                        onClick={() => handleLastData(item)}
                                        value={item}
                                    >
                                        {item}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>

                    <ReactECharts
                        className="chart-box"
                        option={
                            isOutOfPaymentToken.state
                                ? notEnoughPaymentTokenOption
                                : option
                        }
                        theme={"dark"}
                        notMerge={true}
                    />
                </div>
            </ChartBox>
            <Modal
                centered
                show={deleteModalState.isVisible}
                onHide={() => {
                    setDeleteModalState({
                        errMess: "",
                        isVisible: false,
                    });
                }}
                backdrop="static"
                keyboard="false"
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header danger"
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">
                        <span className="material-icons">delete</span>
                    </div>
                    <h3 className="mb-3">Confirm Delete</h3>
                    <p className="mb-4">
                        Do you really want to delete this chart? <br />
                        This cannot be undone.
                    </p>

                    <Button
                        variant="secondary"
                        onClick={() => {
                            handleCloseDeleteModal();
                            setDeleteModalState({
                                errMess: "",
                                isVisible: false,
                            });
                        }}
                    >
                        CANCEL
                    </Button>
                    <Button variant="danger" onClick={handleDeletePanel}>
                        DELETE
                    </Button>
                    <div className="text-danger">
                        {deleteModalState.errMess}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default memo(StatusChart);
