import { memo } from "react";
import BasicSensorChart from "./BasicSensorChart";
import styledConst from "styles";

function SensorChart(props: any) {
    return (
        <BasicSensorChart
            {...{
                ...props,
                customEChartData: ({
                    liveData,
                    chartState,
                    liveTime,
                    liveDate,
                    sensorUnit,
                    min,
                    max,
                    accuracy,
                }: any) => {
                    return [
                        {
                            path: "title.subtext",
                            value: `{s6|${chartState.sensorType}}\n{s2|${
                                (isNaN(liveData) || liveData === "NaN"
                                    ? "-"
                                    : liveData) +
                                (sensorUnit === undefined ? "" : sensorUnit)
                            }} {s5|    Min/Max} {s3| ${
                                !isNaN(min) ? min.toFixed(accuracy) : "-"
                            }/${!isNaN(max) ? max.toFixed(accuracy) : "-"}${
                                sensorUnit || ""
                            }}\n{s1|${liveDate + " " + liveTime}}`,
                        },
                        {
                            path: "title.subtextStyle.rich",
                            value: {
                                s1: {
                                    color: styledConst.Primary_Blue_4,
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    fontSize: 14,
                                    height: 25,
                                },
                                s2: {
                                    color: chartState.chartColor,
                                    fontSize: 22,
                                    fontWeight: "bold",
                                },
                                s3: {
                                    color: "white",
                                    fontSize: 15,
                                    fontFamily: "Roboto",
                                    height: 25,
                                },
                                s4: {
                                    fontSize: 6,
                                },
                                s5: {
                                    color: styledConst.Primary_Blue_4,
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    fontSize: 15,
                                    height: 25,
                                },
                                s6: {
                                    color: styledConst.Primary_Blue_4,
                                    fontFamily: "Roboto",
                                    fontWeight: 400,
                                    fontSize: 14,
                                    height: 25,
                                },
                            },
                        },
                    ];
                },
                onDelete: props.handleOnChangeDashboardUUID,
            }}
        />
    );
}

export default memo(SensorChart);
