import { PasswordField } from "components/common/form";
import DefaultModal from "components/modals/ModalTemplate";
import {
    ACTION_FAIL_MESSAGE,
    PASSWORD_DO_NOT_MATCH_ERROR_MESSAGE,
    PASSWORD_ERROR_MESSAGE,
    Patterns,
    SEND_VERIFY_CODE_SUCCESS_MESSAGE,
    SecondsToCountdown,
} from "constant";
import { get } from "lodash";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
    changePassword,
    sendForgotPasswordEmail,
} from "service/authenticationService";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { isHttpSuccess, renderCountdown } from "utils/functions";

type ResetPasswordFormProps = {
    email: string;
    onSuccess: () => void;
};

const ResetPasswordForm = ({ email, onSuccess }: ResetPasswordFormProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [modalConfirmShow, setModalConfirmShow] = useState(false);
    const [countdown, setCountdown] = useState(SecondsToCountdown);
    const [resetFormValue, setResetFormValue] = useState({
        code: "",
        password: "",
        confirmPassword: "",
    });

    const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const {
            target: { name, value },
        } = e;
        setResetFormValue({
            ...resetFormValue,
            [name]: value,
        });
    };

    const openConfirmationResendModal = () => {
        setModalConfirmShow(true);
    };

    const handleResendCode = async () => {
        const response: any = await sendForgotPasswordEmail(email);

        setModalConfirmShow(false);

        if (isHttpSuccess(response.status)) {
            setCountdown(SecondsToCountdown);
            showSuccessAlert({
                message: SEND_VERIFY_CODE_SUCCESS_MESSAGE,
            });
        } else {
            showErrorAlert(getAPIError(response, ACTION_FAIL_MESSAGE));
        }

        setIsLoading(false);
    };

    const handleSubmit = async () => {
        const { code, password, confirmPassword } = resetFormValue;
        setIsLoading(true);

        if (!code) {
            setErrorMessage("Verification code cannot be empty!");
        } else if (!Patterns.passwordPattern.test(password)) {
            setErrorMessage(PASSWORD_ERROR_MESSAGE);
        } else if (password !== confirmPassword) {
            setErrorMessage(PASSWORD_DO_NOT_MATCH_ERROR_MESSAGE);
        } else {
            const response = await changePassword({
                email: email,
                password: password,
                code,
            });

            if (isHttpSuccess(response.status)) {
                onSuccess?.();
            } else {
                setErrorMessage(
                    get(response, "data.description", ACTION_FAIL_MESSAGE)
                );
            }
        }

        setIsLoading(false);
    };

    const onKeyDownHandler = (e: KeyboardEvent<HTMLFormElement>) => {
        const keyCode = get(e, "which", e.key);
        if (keyCode === 13) {
            e.preventDefault();
            handleSubmit();
        }
    };

    useEffect(() => {
        if (countdown > 0) {
            const timer = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => {
                clearInterval(timer);
            };
        }
    }, [countdown, setCountdown]);

    return (
        <>
            <Col lg="6" sm="12" className="login-form">
                <div className="login-box">
                    <div className="login-table">
                        <div className="login-text">
                            <h4>Forgot Password</h4>
                        </div>
                        <div className="mt-4 mb-5">
                            A Verification code has been sent to your email
                            address in order to reset the password.
                        </div>
                        <Form name="reset" onKeyDown={onKeyDownHandler}>
                            <Form.Group>
                                <Form.Label>Verification Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Enter Code"
                                    aria-label="verify-code"
                                    name="code"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>New Password</Form.Label>
                                <PasswordField
                                    placeholder="Password"
                                    required
                                    aria-label="password"
                                    name="password"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label>Confirm Password</Form.Label>
                                <PasswordField
                                    placeholder="Confirm Password"
                                    required
                                    aria-label="confirm-password"
                                    name="confirmPassword"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>

                            {isLoading ? (
                                <Button variant="primary"> Loading </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    onClick={handleSubmit}
                                >
                                    SUBMIT
                                </Button>
                            )}

                            <div className="loginf">
                                {errorMessage && (
                                    <p role="alert">{errorMessage}</p>
                                )}
                            </div>
                        </Form>

                        <div className="mt-5">
                            Didn’t get code?{" "}
                            {countdown > 0 ? (
                                <span className="pl-1 green">
                                    {renderCountdown(countdown)}
                                </span>
                            ) : (
                                <Link
                                    to=""
                                    className="link"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        openConfirmationResendModal();
                                    }}
                                >
                                    Resend code
                                </Link>
                            )}
                            {/* <div className="mt-5">Didn’t get code? <a className="link" onClick={() => setModalShow(true)} >Resend code</a>  */}
                        </div>
                    </div>
                </div>
            </Col>
            <DefaultModal
                modalShow={modalConfirmShow}
                setModalShow={setModalConfirmShow}
                modalType="updateConfirm"
                modalTitle="Confirm"
                modalContent={
                    <p className="mb-4">
                        Do you want to resend Verification Code?
                    </p>
                }
                modalIcon="forward_to_inbox"
                resendFunction={handleResendCode}
            />
        </>
    );
};

export default ResetPasswordForm;
