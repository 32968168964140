import { Container, Row, Col, Form, Button } from "react-bootstrap";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import { Stack, TextField } from "@mui/material";
import MomentUtils from "@mui/lab/AdapterMoment";
import moment from "moment";
import { HttpStatus, InputDateTimeFormat } from "constant";
import { downloadStorage, getOwnedRegistry } from "service/gatewayService";
import { get, size, map, values, omit } from "lodash";
import DashboardCreateSelectGatewayModal from "components/dashboard/create/modal/SelectGateways";
import { useCreateDashboardStore } from "components/dashboard/create";
import downloadIcon from "assets/svg/download.svg";
import styledConst from "styles";
import { useModal } from "components/modals/ModalTemplate";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import { useOrganisation } from "hooks/organization";
import { isHttpSuccess } from "utils/functions";

const Box = styled.div`
    padding: 20px;
    background-color: #3e4b67;
    width: 100%;
    border-radius: 5px;
`;

const DeleteIcon = styled.span`
    color: ${styledConst.Secondary_Red};
    cursor: pointer;
`;

const CustomRow = styled.div`
    padding: 0.75rem 1rem;
    background-color: #3e4b67;
    border-radius: 5px;
    width: 100%;
`;

const MutedText = styled.span`
    color: ${styledConst.Primary_Blue_4};
    font-size: 12px;
`;

enum DownloadOptions {
    All = "all",
    ByDate = "by-day",
}

const DownloadData = () => {
    const [downloadOption, setDownloadOption] = useState(
        DownloadOptions.ByDate
    );
    const [startDateTime, setStartDateTime] = useState<any>(
        moment().subtract(24, "hours")
    );
    const [endDateTime, setEndDateTime] = useState<any>(moment());
    const [selectedGateways, setSelectedGateways] = useState<{}>({});
    const [modalShow, setModalShow] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const { ownerOrgIds } = useOrganisation();
    const {
        openModal,
        modal: ActionModal,
        setModalShow: setActionModalShow,
    } = useModal();

    const isValid = () => {
        return size(selectedGateways) > 0 && !errMsg;
    };

    const handleStartDateChange = (newValue?: any) => {
        newValue && setStartDateTime(moment(newValue._d));
    };

    const handleEndDateChange = (newValue?: any) => {
        newValue && setEndDateTime(moment(newValue._d));
    };

    const removeGateway = (gateway_id: string) => {
        setSelectedGateways(omit(selectedGateways, [gateway_id]));
    };

    const validate = () => {
        if (!endDateTime.isAfter(startDateTime)) {
            setErrMsg("End time must be after start time");
        } else if (endDateTime.isAfter(moment())) {
            setErrMsg("End time must be before future time");
        } else {
            setErrMsg("");
        }
    };

    const onSelectedGateway = async (data: Record<string, any>) => {
        setSelectedGateways(data);
    };

    const downloadData = () => {
        const isDownloadAll = downloadOption === DownloadOptions.All;
        const params = {
            start: isDownloadAll ? 0 : moment(startDateTime).unix(),
            end: isDownloadAll ? moment().unix() : moment(endDateTime).unix(),
            attributes: map(
                values(selectedGateways),
                ({ gateway_id }: any) => ({
                    gateway_id,
                })
            ),
        };

        downloadStorage(params).then(({ data, status }) => {
            if (isHttpSuccess(status)) {
                showSuccessAlert({
                    message:
                        "An email will be sent to you shortly once it is ready to download.",
                });
            } else {
                showErrorAlert({
                    title: get(data, "title", "Error"),
                    message: get(data, "description"),
                });
            }

            setActionModalShow(false);
            setSelectedGateways({});
        });
    };

    const confirmDownload = () => {
        openModal({
            key: "download_data_confirm",
            modalType: "updateConfirm",
            modalTitle: "Confirmation",
            modalIcon: "help_outline",
            modalContent: (
                <span
                    dangerouslySetInnerHTML={{
                        __html: `By confirming, an email will be sent to your email address with instructions on how to download. 
                        <br/>Do you want to continue?<br/>`,
                    }}
                />
            ),
            resendFunction: downloadData,
        });
    };

    useEffect(() => {
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDateTime, endDateTime]);

    useEffect(() => {
        const fetch = async () => {
            const res = await getOwnedRegistry(ownerOrgIds);

            let allRegistry: any[] = [];
            if (res.status === HttpStatus.OK) {
                const data = get(res, "data", []);
                allRegistry = map(data, (gateway) => {
                    let groupName = get(gateway, "group", "Standalone");
                    groupName = groupName === null ? "Standalone" : groupName;
                    return {
                        ...gateway,
                        groupName,
                    };
                }).filter(({ info }: { info: any }) => info);
            }

            useCreateDashboardStore.setState({
                allRegistry,
            });
        };
        ownerOrgIds.length && fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownerOrgIds]);

    return (
        <div className="page-content">
            <Container>
                <Row>
                    <Col sm={12}>
                        <h3 className="page-title">Download Data</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col sm="12">
                        <div className="form-box">
                            <Row>
                                <Col
                                    md={{
                                        span: 6,
                                        offset: 3,
                                    }}
                                    sm={{
                                        span: 8,
                                        offset: 2,
                                    }}
                                    xs={{
                                        span: 10,
                                        offset: 1,
                                    }}
                                >
                                    <div className="mb-2">
                                        Select data to include
                                    </div>
                                    <Box>
                                        <Form.Check
                                            name="download-all-data"
                                            custom
                                            type="radio"
                                            value={DownloadOptions.All}
                                            id="download-all"
                                            checked={
                                                downloadOption ===
                                                DownloadOptions.All
                                            }
                                            onChange={() =>
                                                setDownloadOption(
                                                    DownloadOptions.All
                                                )
                                            }
                                            label={
                                                <span className="ml-2">
                                                    Download all data
                                                </span>
                                            }
                                        />
                                    </Box>
                                    <Box className="mt-2">
                                        <Form.Check
                                            name="date-range"
                                            custom
                                            type="radio"
                                            value={DownloadOptions.ByDate}
                                            id="date-range"
                                            checked={
                                                downloadOption ===
                                                DownloadOptions.ByDate
                                            }
                                            onChange={() =>
                                                setDownloadOption(
                                                    DownloadOptions.ByDate
                                                )
                                            }
                                            label={
                                                <span className="ml-2">
                                                    Pick date range
                                                </span>
                                            }
                                        />

                                        {downloadOption ===
                                            DownloadOptions.ByDate && (
                                            <div className="mt-3">
                                                <LocalizationProvider
                                                    dateAdapter={MomentUtils}
                                                >
                                                    <Stack spacing={3}>
                                                        <div className="dateTimeFieldWrapper">
                                                            <div className="dateTimeField">
                                                                From
                                                            </div>

                                                            <DateTimePicker
                                                                disabled={
                                                                    downloadOption !==
                                                                    DownloadOptions.ByDate
                                                                }
                                                                renderInput={(
                                                                    props
                                                                ) => (
                                                                    <TextField
                                                                        {...props}
                                                                        label=""
                                                                        InputLabelProps={{
                                                                            shrink: false,
                                                                        }}
                                                                        InputProps={{
                                                                            ...props.InputProps,
                                                                            inputProps:
                                                                                {
                                                                                    ...props?.inputProps,
                                                                                    "aria-label":
                                                                                        "start-time",
                                                                                },
                                                                        }}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handleStartDateChange(
                                                                                moment(
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                    InputDateTimeFormat
                                                                                )
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                                PopperProps={{
                                                                    placement:
                                                                        "top",
                                                                }}
                                                                value={
                                                                    startDateTime
                                                                }
                                                                inputFormat="DD/MM/yyyy hh:mm A"
                                                                onChange={(
                                                                    newValue
                                                                ) => {
                                                                    handleStartDateChange(
                                                                        newValue
                                                                    );
                                                                }}
                                                                maxDateTime={moment()}
                                                            />
                                                        </div>

                                                        <div className="dateTimeFieldWrapper">
                                                            <div className="dateTimeField">
                                                                To
                                                            </div>
                                                            <DateTimePicker
                                                                disabled={
                                                                    downloadOption !==
                                                                    DownloadOptions.ByDate
                                                                }
                                                                renderInput={(
                                                                    props
                                                                ) => (
                                                                    <TextField
                                                                        {...props}
                                                                        label=""
                                                                        InputLabelProps={{
                                                                            shrink: false,
                                                                        }}
                                                                        InputProps={{
                                                                            ...props.InputProps,
                                                                            inputProps:
                                                                                {
                                                                                    ...props?.inputProps,
                                                                                    "aria-label":
                                                                                        "end-time",
                                                                                },
                                                                        }}
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handleEndDateChange(
                                                                                moment(
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                                    InputDateTimeFormat
                                                                                )
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                                PopperProps={{
                                                                    placement:
                                                                        "top",
                                                                }}
                                                                value={
                                                                    endDateTime
                                                                }
                                                                inputFormat="DD/MM/yyyy hh:mm A"
                                                                onChange={(
                                                                    newValue
                                                                ) => {
                                                                    handleEndDateChange(
                                                                        newValue
                                                                    );
                                                                }}
                                                                minDateTime={moment(
                                                                    startDateTime
                                                                )}
                                                                maxDateTime={moment()}
                                                            />
                                                        </div>
                                                        {errMsg && (
                                                            <div className="dateTimeFieldWrapper">
                                                                <div className="dateTimeField" />
                                                                <p
                                                                    role="alert"
                                                                    className="text-danger"
                                                                >
                                                                    {errMsg}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </Stack>
                                                </LocalizationProvider>
                                            </div>
                                        )}
                                    </Box>

                                    <div className="mt-5 mb-3">
                                        <div className="mb-2">Gateways</div>
                                        <Button
                                            onClick={() => setModalShow(true)}
                                        >
                                            Select Gateways
                                        </Button>
                                        <DashboardCreateSelectGatewayModal
                                            show={modalShow}
                                            onHide={() => setModalShow(false)}
                                            values={selectedGateways}
                                            onSave={(event, data) =>
                                                onSelectedGateway(data)
                                            }
                                        />
                                    </div>

                                    {size(selectedGateways) > 0 && (
                                        <div>
                                            {map(
                                                values(selectedGateways),
                                                ({ name, gateway_id }: any) => (
                                                    <CustomRow
                                                        className="px-3 mb-2"
                                                        key={gateway_id}
                                                    >
                                                        <Row className="no-checkbox">
                                                            <Col>
                                                                <div>
                                                                    {name}
                                                                </div>
                                                                <MutedText>
                                                                    UUID:{" "}
                                                                    {gateway_id}
                                                                </MutedText>
                                                            </Col>
                                                            <Col
                                                                md={{
                                                                    span: 1,
                                                                    order: "last",
                                                                }}
                                                                xs={{
                                                                    span: 1,
                                                                    order: 2,
                                                                }}
                                                            >
                                                                <DeleteIcon
                                                                    className="material-icons"
                                                                    aria-label="delete-gateway"
                                                                    onClick={() => {
                                                                        removeGateway(
                                                                            gateway_id
                                                                        );
                                                                    }}
                                                                >
                                                                    close
                                                                </DeleteIcon>
                                                            </Col>
                                                        </Row>
                                                    </CustomRow>
                                                )
                                            )}
                                        </div>
                                    )}
                                    <Button
                                        className="w-100 mt-5"
                                        disabled={!isValid()}
                                        onClick={confirmDownload}
                                    >
                                        <img
                                            src={downloadIcon}
                                            alt=""
                                            className="mr-1"
                                        />
                                        Download Data
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            {ActionModal}
        </div>
    );
};

export default DownloadData;
