import { Form, Button } from "react-bootstrap";
import { CheckoutType, billingStartDate } from "constant";

import "assets/css/subscription.css";
import { showErrorAlert } from "utils/alert";
import { subscriptionPeriodDetail } from "utils/subscriptionFunctions";

const PurchasePreview = (props: any) => {
    const {
        checkoutType,
        location,
        bundleInfo,
        setBundleQty,
        bundleQty,
        saveAddress,
        customer,
        monthlyChargePreview,
    } = props;

    const verifyAndSaveAddress = () => {
        if (location?.state?.checkoutType === CheckoutType.ADDON) {
            if (parseInt(bundleQty) <= 0 || bundleQty === "") {
                showErrorAlert({
                    message: "You have not selected any item to purchase.",
                });
                return;
            }

            if (!Number.isInteger(Number(bundleQty))) {
                showErrorAlert({
                    message: "Purchase quantity is not allowed.",
                });

                return;
            }

            if (Number(bundleQty) > 10000) {
                showErrorAlert({
                    message:
                        "Unable to purchase more than 10,000,000,000 tokens per transaction.",
                });

                return;
            }
        }

        saveAddress();
    };

    const renderPreview = () => {
        if (checkoutType === CheckoutType.PLAN) {
            if (!customer.verified) return "";
            return (
                <div className="form-box">
                    <h5 className="bill-title">You are buying</h5>
                    <div className="float-left w-100">
                        <div className="monthly-charge">
                            <div className="d-flex justify-content-between">
                                <h6>Monthly Charge</h6>
                                <div>
                                    <h5>
                                        USD{" "}
                                        {location?.state
                                            ?.monthlyChargePreview ||
                                            monthlyChargePreview}
                                        /mo
                                    </h5>
                                </div>
                            </div>
                            <span className="text-primary-blue-4">
                                Billing Starts: {billingStartDate}
                            </span>
                            <p>
                                Subscription Period:{" "}
                                {subscriptionPeriodDetail(
                                    billingStartDate,
                                    customer.billing_cycles
                                )}
                            </p>
                            <span className="font-weight-500 text-primary-blue-4">
                                Billing starts from today ({billingStartDate}),
                                and will renew automatically every month.
                            </span>
                            <p>
                                Payments won’t be refunded for partial billing
                                periods. Cancel anytime.
                            </p>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="form-box">
                    <h5 className="bill-title">You are buying</h5>
                    <div className="float-left w-100">
                        <div className="monthly-charge">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6>
                                        Bundle of 1,000,000 Purchased Tokens
                                    </h6>
                                    <p>No expiry</p>
                                </div>
                                <div>
                                    <h5>USD {bundleInfo.amount}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="qty qty-checkout">
                            <Form className="form">
                                <Form.Label className="float-left">
                                    <span className="default-white-color">
                                        Qty
                                    </span>{" "}
                                    (Max 100)
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    className="float-left"
                                    min={1}
                                    max={100}
                                    maxLength={3}
                                    id="qtyField"
                                    value={bundleQty}
                                    onKeyDown={(e: any) => {
                                        if (
                                            e.key === "Backspace" &&
                                            e.target.value.length === 1
                                        ) {
                                            e.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (
                                            Number(e.target.value) < 1 ||
                                            Number(e.target.value) > 100
                                        ) {
                                            return;
                                        }
                                        setBundleQty(e.target.value);
                                    }}
                                ></Form.Control>
                            </Form>
                            <div className="total">
                                <h5>
                                    {Number(
                                        Number(bundleQty) *
                                            Number(bundleInfo.tokenQty)
                                    ).toLocaleString()}{" "}
                                    Tokens
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            {renderPreview()}

            {(checkoutType === CheckoutType.ADDON ||
                (checkoutType === CheckoutType.PLAN && customer.verified)) && (
                <Button
                    variant="primary"
                    className="proceed"
                    disabled={
                        checkoutType === CheckoutType.PLAN && !customer.verified
                    }
                    onClick={() => {
                        verifyAndSaveAddress();
                    }}
                >
                    NEXT
                </Button>
            )}
        </>
    );
};

export default PurchasePreview;
