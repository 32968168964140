import type { AxiosResponse } from "axios";
import { setOrganizationFromResponse } from "./organization";
import { gatewayReadingStatusStore } from "./gatewayReading";
import { useWalletStore } from "./wallet";

// Create axios response interceptor
export const axiosResponseHook = (response: AxiosResponse<any>) => {
    if (response) {
        const url = response.config.url?.startsWith("http")
            ? response.config.url
            : `${response.config.baseURL}${response.config.url}`;

        response.config.url = url;
        // Set organization from response
        setOrganizationFromResponse(response);

        // Set gateway reading status from response
        gatewayReadingStatusStore.getState().setGatewayReadingData(response);
        gatewayReadingStatusStore.getState().setGatewayConfigData(response);

        // Set wallet from response
        useWalletStore.getState().setWallet(response);
    }

    return response;
};

export default axiosResponseHook;
