import { Col, Form } from "react-bootstrap";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";

import DateAdapter from "@mui/lab/AdapterMoment";
import TextField from "@mui/material/TextField";

export const RangeOfRecurrenceTemplate = ({
    recurrence,
    recurrenceRange,
    setRecurrenceRange,
    renderErrorMsg,
}: any) => {
    const START_DATE = "start_date";
    const END_DATE = "end_date";
    const ENABLE_END_DATE = true;

    const renderDate = (startOrEnd: any, enableEndDate?: any) => {
        const handleChange = (newValue: any) => {
            switch (startOrEnd) {
                case START_DATE:
                    setRecurrenceRange({
                        ...recurrenceRange,
                        [recurrence]: {
                            ...recurrenceRange[recurrence],
                            startDate: newValue,
                        },
                    });
                    break;
                case END_DATE:
                    setRecurrenceRange({
                        ...recurrenceRange,
                        [recurrence]: {
                            ...recurrenceRange[recurrence],
                            endDate: newValue,
                        },
                    });
                    break;
            }
        };
        if (enableEndDate) {
            return (
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <DesktopDatePicker
                        label="Date desktop"
                        inputFormat="DD/MM/yyyy"
                        value={recurrenceRange[recurrence].endDate}
                        onChange={(newValue: any) => {
                            handleChange(newValue);
                        }}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                label=""
                                InputLabelProps={{
                                    shrink: false,
                                }}
                            />
                        )}
                    />
                </LocalizationProvider>
            );
        }

        return (
            <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                    label="Date desktop"
                    inputFormat="DD/MM/yyyy"
                    value={recurrenceRange[recurrence].startDate}
                    onChange={(newValue: any) => {
                        handleChange(newValue);
                    }}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            label=""
                            InputLabelProps={{
                                shrink: false,
                            }}
                        />
                    )}
                />
            </LocalizationProvider>
        );
    };

    return (
        <div className="form-box mb-3">
            <h5 className="mb-4">Range of Recurrence</h5>
            <Form.Row>
                <Col sm="2">Start </Col>
                <Col sm="3">{renderDate(START_DATE)}</Col>
            </Form.Row>
            <Form.Row className="mt-2">
                <Col sm="2" className="mt-2">
                    End{" "}
                </Col>

                <Col sm={2}>
                    <Form.Check
                        name="end"
                        id={"no_end"}
                        custom
                        checked={
                            recurrenceRange[recurrence].endDateEnabled === false
                        }
                        type="radio"
                        className="mt-2"
                        label={<span className="ml-2">No end date</span>}
                        onChange={(e: any) => {
                            setRecurrenceRange({
                                ...recurrenceRange,
                                [recurrence]: {
                                    ...recurrenceRange[recurrence],
                                    endDateEnabled: false,
                                },
                            });
                        }}
                    />
                </Col>
                <Col sm={2}>
                    <Form.Check
                        name="end"
                        id={"end_by"}
                        custom
                        checked={
                            recurrenceRange[recurrence].endDateEnabled === true
                        }
                        type="radio"
                        className="mt-2"
                        label={<span className="ml-2">End by</span>}
                        onChange={(e: any) => {
                            setRecurrenceRange({
                                ...recurrenceRange,
                                [recurrence]: {
                                    ...recurrenceRange[recurrence],
                                    endDateEnabled: true,
                                },
                            });
                        }}
                    />
                </Col>

                <Col sm="3">
                    {recurrenceRange[recurrence].endDateEnabled
                        ? renderDate(END_DATE, ENABLE_END_DATE)
                        : ""}
                </Col>
            </Form.Row>
            {renderErrorMsg(recurrence, "range")}
        </div>
    );
};
