import { useState, useEffect, useRef } from "react";
import {
    Row,
    Col,
    Form,
    Button,
    FormControl,
    InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDebounce } from "hooks/common";
import { ReactComponent as DeleteIcon } from "assets/svg/delete-white.svg";
import dlt from "assets/svg/delete.svg";
import { FAILED_ACTION_MESSAGE } from "constant";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { RoleDto } from "types";
import useCollectSort from "hooks/useCollectSort";
import { isHttpSuccess } from "utils/functions";
import { get } from "lodash";

const defaultSelectIds: Array<string> = [];
const defaultSearchRef: any = {};
const RolesList = (props: any) => {
    const { orgId, rolesData, onDelete } = props;
    const [roles, setRoles] = useState<RoleDto[]>([]);

    const [selectedIds, setSelectedIds] = useState(defaultSelectIds);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const searchRef = useRef(defaultSearchRef);
    const customDebounce = useDebounce((value: string) => {
        const _searchStr = value
            .toLowerCase()
            .replace(/\s{2,}/, "")
            .trim();

        if (!_searchStr) setRoles(rolesData);

        const results = rolesData.filter((role: any) => {
            const _name = role.name.toLowerCase();
            return _name.includes(_searchStr);
        });
        setRoles(results);
    });
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);
    const { data: roleCollect, sortIcon } = useCollectSort(roles);

    useEffect(() => {
        setRoles(rolesData);
    }, [rolesData]);

    const handleSearchInput = (e: any) => {
        const { value } = e.target;
        customDebounce(value);
    };

    const handleSelectAll = (e: any) => {
        const { checked } = e.target;
        let deleteIds: Array<string> = [];

        if (checked) {
            deleteIds = roles
                .filter((role: any) => !role.isDefault)
                .reduce(
                    (results: Array<string>, item) => [...results, item.uuid],
                    []
                );
        } else {
            deleteIds = [];
        }

        setSelectedIds(deleteIds);
        setIsSelectAll(checked);
    };

    const handleSelectDeleteRole = (e: any, id: string) => {
        const { checked } = e.target;
        let deleteIds: Array<string> = [...selectedIds];

        if (checked) {
            deleteIds = [...deleteIds, id];
        } else {
            deleteIds = deleteIds.filter((deleteId: string) => deleteId !== id);
        }

        setSelectedIds(deleteIds);
        setIsSelectAll(deleteIds.length === roles.length - 2);
    };

    const handleDelete = (role?: RoleDto) => {
        let message = `Do you want to delete ${selectedIds.length} selected role(s)?`;
        let title = "Delete Role(s)?";
        if (role) {
            message = `Do you want to delete role <strong>${role.name}</strong>?`;
            title = "Delete Role?";
        }

        setSimpleModalData({
            resObj: {
                data: {
                    title,
                },
                message,
            },
            icon: "delete",
            renderModalButtons: (setErrModalVisible: any) => {
                return (
                    <>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setErrModalVisible(false);
                                handleCloseConfirmModal("cancel");
                            }}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => {
                                setErrModalVisible(false);
                                handleCloseConfirmModal("ok", role);
                            }}
                        >
                            DELETE
                        </Button>
                    </>
                );
            },
        } as SimpleModalDataType);
    };

    const handleCloseConfirmModal = async (
        status = "cancel",
        role?: RoleDto
    ) => {
        if (status === "ok") {
            const requestList = (role ? [role.uuid] : selectedIds).map((id) =>
                onDelete(id)
            );
            const deleteResponses = await Promise.all(requestList);
            const isAllDeleteSuccess = deleteResponses.every((item: any) =>
                isHttpSuccess(item.status)
            );
            let mainMessage = "success";
            let subMessage = `${selectedIds.length} role(s) deleted successfully.`;
            let title = "Success";

            if (role) {
                subMessage = "Role has been deleted successfully.";
            }

            if (isAllDeleteSuccess) {
                setSelectedIds([]);
                setIsSelectAll(false);
            } else {
                mainMessage = "error";
                subMessage = get(
                    deleteResponses[0],
                    "data.description",
                    FAILED_ACTION_MESSAGE
                );
                title = get(deleteResponses[0], "data.title", "Error");
            }

            setSimpleModalData({
                type: mainMessage,
                body: subMessage,
                title,
            } as SimpleModalDataType);
        }
    };

    return (
        <ContentWrapper simpleModalData={simpleModalData}>
            <Row>
                <Col
                    md={{ span: 4, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    className="mt-4"
                >
                    <HoverAuthorizeTooltip
                        permission="organisation:delete"
                        className="px-2 px-sm-3"
                    >
                        <Button
                            variant="danger"
                            onClick={() => handleDelete()}
                            className={
                                selectedIds.length > 0
                                    ? "opacity-btn-enable"
                                    : "opacity-btn-disable"
                            }
                            hidden={!selectedIds.length}
                        >
                            <DeleteIcon className="delete-icon" /> (
                            {selectedIds.length})
                        </Button>
                    </HoverAuthorizeTooltip>
                </Col>
                <Col
                    md={{ span: 8, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    className="d-md-flex justify-content-end table-head-options mt-4"
                >
                    <HoverAuthorizeTooltip
                        permission="organisation:create"
                        className="mr-3 mb-3"
                    >
                        <Link
                            to={`/new-org-role/${orgId}`}
                            className="btn btn-primary"
                        >
                            + NEW ROLE
                        </Link>
                    </HoverAuthorizeTooltip>
                    <div className="search">
                        <InputGroup>
                            <FormControl
                                type="text"
                                placeholder="Search.."
                                aria-describedby="button-addon2"
                                ref={searchRef}
                                onChange={handleSearchInput}
                            />
                        </InputGroup>
                    </div>
                </Col>
            </Row>
            <Row className="cstm-table">
                <Col sm={12}>
                    <div className="table-head">
                        <Row>
                            <Col sm="1">
                                <HoverAuthorizeTooltip permission="organisation:delete">
                                    <span className="d-inline-block">
                                        <Form.Check
                                            className="mr-sm-2"
                                            type="checkbox"
                                            id="select-all-roles"
                                            checked={isSelectAll}
                                            onChange={handleSelectAll}
                                            custom
                                        />
                                    </span>
                                </HoverAuthorizeTooltip>
                            </Col>
                            <Col>Role Name {sortIcon("name")}</Col>
                            <Col>Type {sortIcon("isDefault")}</Col>
                            <Col sm="1">Action</Col>
                            <Col sm="1"></Col>
                        </Row>
                    </div>
                    {roleCollect.map((role, index: number) => (
                        <div className="table-row" key={role.uuid}>
                            <Row>
                                <Col xs={{ span: 1, order: 1 }}>
                                    <Form.Check
                                        className="mr-sm-2"
                                        type="checkbox"
                                        id={`role-${index}`}
                                        custom
                                        checked={selectedIds.includes(
                                            role.uuid
                                        )}
                                        disabled={role.isDefault}
                                        onChange={(e) =>
                                            handleSelectDeleteRole(e, role.uuid)
                                        }
                                    />
                                </Col>
                                <Col
                                    className="overflow-text"
                                    md
                                    xs={{ span: 10, order: 2 }}
                                >
                                    <Link
                                        to={`/organisation/${orgId}/role/${role.uuid}`}
                                    >
                                        {role.name}
                                    </Link>
                                </Col>
                                <Col
                                    md={{ offset: 0 }}
                                    xs={{ span: 10, offset: 1, order: 4 }}
                                >
                                    <span className="reading-label">
                                        Type:{" "}
                                    </span>
                                    {role.isDefault ? (
                                        <span className="default">
                                            Default Role
                                        </span>
                                    ) : (
                                        <span className="custom">
                                            Custom Role
                                        </span>
                                    )}
                                </Col>

                                <Col xs={{ span: 1, order: "last" }}>
                                    {role.isDefault ? (
                                        ""
                                    ) : (
                                        <HoverAuthorizeTooltip permission="organisation:delete">
                                            <span
                                                className="btn"
                                                onClick={() =>
                                                    handleDelete(role)
                                                }
                                            >
                                                <img
                                                    src={dlt}
                                                    alt="Delete Role"
                                                />
                                            </span>
                                        </HoverAuthorizeTooltip>
                                    )}
                                </Col>
                                <Col
                                    md={{ span: 1, order: "last" }}
                                    xs={{ span: 1, order: 3 }}
                                >
                                    <Link
                                        to={`/organisation/${orgId}/role/${role.uuid}`}
                                    >
                                        <i className="material-icons right">
                                            keyboard_arrow_right
                                        </i>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    ))}

                    {roles.length === 0 && (
                        <div className="table-row">
                            <Row>
                                <Col className="text-center">
                                    No items to manage here.
                                </Col>
                            </Row>
                        </div>
                    )}
                </Col>
            </Row>
        </ContentWrapper>
    );
};

export default RolesList;
