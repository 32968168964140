import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    Breadcrumb,
    Modal,
} from "react-bootstrap";
import {
    getGatewayUART,
    updateGatewayUART,
    getUARTnoticeConfig,
    updateUARTnoticeConfig,
} from "service/gatewayService";
import ripple from "assets/img/ripple.gif";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import {
    BaudRateOptions,
    CharacterLimit,
    HttpStatus,
    ParityOptions,
} from "constant";
import OrganisationService from "service/organisationService";
import { isHttpSuccess, orgId } from "utils/functions";
import { get, isEmpty } from "lodash";
import { styled } from "@mui/system";
import styledConst from "styles";
import { useAppContext } from "context/appContext";
import { WalletAlertComponent, useWalletStore } from "hooks/wallet";
import {
    UartConfigFlowcontrol,
    UartConfigPost,
    UartConfigPostParity,
} from "generated/models";
import {
    convertIdToUserInfoArray,
    extractedIdList,
} from "utils/gatewayFunctions";
import { AlertConfig, MessageType } from "types/Gateways";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { MemberDto, MemberStatus } from "types";
import AlertNoticeConfig, {
    getDefaultAlertConfig,
} from "components/gateways/AlertNoticeConfig";

const WrapperInner = styled(`div`)`
    .recipientFooter {
        margin-top: 15px;
    }

    .recipientAlert {
        background: ${styledConst.secondBackgroundColor};
        padding: 10px 15px;
        gap: 5px;
        max-width: fit-content;
        border-radius: 5px;

        span {
            color: white;
        }
        a {
            color: ${styledConst.linkColor};
        }
        p {
            margin: 0px;
        }
    }
`;

const defaultMessage: MessageType = {
    subject: "",
    text: "",
};

const defaultUartConfiguration: UartConfigPost = {
    baudrate: 115200,
    parity: "NONE",
    flowcontrol: "NONE",
    stopbits: 1,
    databits: 8,
};

const Uart = () => {
    // ========= STORE ============
    const { storeData } = useAppContext();
    const { isDefault, owner } = storeData.organization.currentOrgInfo;

    const history = useHistory();
    const params: any = useParams();
    const { wallet } = useWalletStore();

    // ======= USESTATES ========
    const [uartDetails, setUartDetails] = useState<UartConfigPost>(
        defaultUartConfiguration
    );
    const [initialMemberList, setInitialMemberList] = useState<
        Array<MemberDto>
    >([]);
    const [alertConfig, setAlertConfig] = useState<AlertConfig>(
        getDefaultAlertConfig()
    );
    const [defaultAlertConfig, setDefaultAlertConfig] = useState<AlertConfig>(
        getDefaultAlertConfig()
    );

    const [isLoadingModalShow, setIsLoadingModalShow] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [isForbiddenResource, setIsForbiddenResource] = useState(false);

    const [message, setMessage] = useState<MessageType>(defaultMessage);

    const userIsNotSubscribed = !isEmpty(wallet) && !wallet.isSubscribe;

    // ===== USE-EFFECT =====

    // Pre-populate gateway name (breadcrumb), UART settings, message, alerts, organization selection list
    useEffect(() => {
        (async () => {
            const [UARTconfigRes, UARTnoticeRes, membersRes] =
                await Promise.all([
                    getGatewayUART(params.gatewayId),
                    getUARTnoticeConfig(params.gatewayId),
                    OrganisationService.getOrgMembers(orgId()),
                ]);

            if (
                isHttpSuccess(UARTconfigRes.status) &&
                isHttpSuccess(UARTnoticeRes.status) &&
                isHttpSuccess(membersRes.status)
            ) {
                const { baudrate, parity, flowcontrol, stopbits, databits } =
                    !isEmpty(UARTconfigRes.data)
                        ? UARTconfigRes.data
                        : defaultUartConfiguration;

                const {
                    enabled_email = false,
                    enabled_sms = false,
                    enabled_push = false,
                    subject = "",
                    text = "",
                    recipients_email = isDefault ? [owner] : [],
                    recipients_push = isDefault ? [owner] : [],
                    recipients_sms = isDefault ? [owner] : [],
                } = UARTnoticeRes.data ?? {};

                const members: MemberDto[] = (membersRes.data ?? [])
                    .toCamelCase<MemberDto>()
                    .filter(
                        (member: MemberDto) =>
                            [
                                MemberStatus.Accepted,
                                MemberStatus.Owner,
                            ].includes(member.status),
                        []
                    );

                setUartDetails({
                    baudrate,
                    parity,
                    flowcontrol,
                    stopbits,
                    databits,
                });

                setMessage({
                    subject: subject,
                    text: text,
                });

                setAlertConfig({
                    Email: {
                        isEnabled: enabled_email,
                        recipientMode: "Email",
                        recipients: convertIdToUserInfoArray(
                            members,
                            recipients_email
                        ),
                    },
                    SMS: {
                        isEnabled: enabled_sms,
                        recipientMode: "SMS",
                        recipients: convertIdToUserInfoArray(
                            members,
                            recipients_sms
                        ),
                    },
                    Notification: {
                        isEnabled: enabled_push,
                        recipientMode: "Notification",
                        recipients: convertIdToUserInfoArray(
                            members,
                            recipients_push
                        ),
                    },
                });
                setDefaultAlertConfig({
                    Email: {
                        isEnabled: false,
                        recipientMode: "Email",
                        recipients: convertIdToUserInfoArray(
                            members,
                            recipients_email
                        ),
                    },
                    SMS: {
                        isEnabled: false,
                        recipientMode: "SMS",
                        recipients: convertIdToUserInfoArray(
                            members,
                            recipients_sms
                        ),
                    },
                    Notification: {
                        isEnabled: false,
                        recipientMode: "Notification",
                        recipients: convertIdToUserInfoArray(
                            members,
                            recipients_push
                        ),
                    },
                });

                setInitialMemberList(members);
                setIsPageLoading(false);
            } else {
                if (UARTconfigRes.status === HttpStatus.FORBIDDEN) {
                    setIsForbiddenResource(true);
                }

                setIsPageLoading(false);
            }
        })();
    }, [params.gatewayId, isDefault, owner]);

    // ====== FUNCTIONS ======

    const isFormValid = () => {
        const alertEnabled =
            alertConfig.Email.isEnabled ||
            alertConfig.SMS.isEnabled ||
            alertConfig.Notification.isEnabled;
        if (
            alertEnabled &&
            (message.subject.trim().length < 1 ||
                message.subject.trim().length > CharacterLimit.UART_SUBJECT_MAX)
        ) {
            showErrorAlert({
                message: `Subject must be between 1 to ${CharacterLimit.UART_SUBJECT_MAX} characters.`,
            });
            return false;
        }
        if (
            alertEnabled &&
            (message.text.trim().length < 1 ||
                message.text.trim().length > CharacterLimit.UART_MESSAGE_MAX)
        ) {
            showErrorAlert({
                message: `Message must be between 1 to ${CharacterLimit.UART_MESSAGE_MAX} characters.`,
            });
            return false;
        }

        if (
            alertConfig.Email.isEnabled &&
            alertConfig.Email.recipients.length === 0
        ) {
            showErrorAlert({
                message: "Unable to send email to empty recipients.",
            });
            return false;
        }

        if (alertConfig.SMS.isEnabled) {
            if (
                (isDefault &&
                    !alertConfig.SMS.recipients[0].phoneNumberVerified) ||
                (!isDefault && alertConfig.SMS.recipients.length === 0)
            ) {
                showErrorAlert({
                    message: "Unable to send SMS to empty recipients.",
                });
                return false;
            }
        }

        if (
            alertConfig.Notification.isEnabled &&
            alertConfig.Notification.recipients.length === 0
        ) {
            showErrorAlert({
                message: "Unable to send notification to empty recipients.",
            });
            return false;
        }
        return true;
    };

    const save = async () => {
        if (!isFormValid()) return;

        setIsLoading(true);

        const [updateUARTconfigRes, updateUARTnoticeRes] = await Promise.all([
            updateGatewayUART({
                gatewayId: params.gatewayId,
                body: uartDetails,
            }),
            updateUARTnoticeConfig({
                gatewayId: params.gatewayId,
                data: {
                    subject: message.subject.trim(),
                    text: message.text.trim(),
                    enabled_push: alertConfig.Notification.isEnabled,
                    recipients_push: extractedIdList(
                        alertConfig.Notification.recipients
                    ),
                    enabled_email: alertConfig.Email.isEnabled,
                    recipients_email: extractedIdList(
                        alertConfig.Email.recipients
                    ),
                    enabled_sms: alertConfig.SMS.isEnabled,
                    recipients_sms: extractedIdList(alertConfig.SMS.recipients),
                },
            }),
        ]);

        if (
            isHttpSuccess(updateUARTconfigRes.status) &&
            isHttpSuccess(updateUARTnoticeRes.status)
        ) {
            setIsLoading(false);
            showSuccessAlert({ message: "UART properties set successfully." });
            history.push(`/gateway-details/${params.gatewayId}`);
        } else {
            setIsLoading(false);
            const message = get(
                updateUARTconfigRes.data ?? updateUARTnoticeRes.data,
                "description",
                "Unable to update UART properties."
            );
            showErrorAlert({
                message,
            });
        }
    };

    const resetToDefault = () => {
        setAlertConfig(defaultAlertConfig);
        setUartDetails(defaultUartConfiguration);
        setMessage(defaultMessage);
    };

    const onAlertConfigChange = (config: AlertConfig) => {
        setAlertConfig(config);
    };

    const renderBaudRateOptions = () => {
        return BaudRateOptions.map((value: string) => (
            <option key={value} value={value}>
                {value}
            </option>
        ));
    };

    const renderParityOptions = () => {
        return ParityOptions.map((value: string) => (
            <option key={value} value={value.toUpperCase()}>
                {value}
            </option>
        ));
    };

    return (
        <ContentWrapper
            isForbiddenResource={isForbiddenResource}
            title="UART"
            isLoading={isPageLoading}
        >
            <WrapperInner className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={12} className="UART-header">
                            <h5 className="page-title">UART</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item
                                    href={`/gateway-details/${params.gatewayId}`}
                                >
                                    Gateway
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>UART</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="12" className="uart-box mt-2">
                            <WalletAlertComponent showWarningWhen="Unsubscribed" />
                            <Form>
                                <fieldset disabled={userIsNotSubscribed}>
                                    <div className="form-box-head uart-config-subheader d-flex justify-content-between">
                                        <h5>Configuration</h5>
                                        <div className="uart-reset">
                                            <div>
                                                <h6>Reset to default</h6>
                                                <p>
                                                    Reset all fields to default
                                                    values
                                                </p>
                                            </div>
                                            <Button
                                                variant="primary"
                                                className="reset-btn"
                                                onClick={resetToDefault}
                                            >
                                                RESET
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="form-box">
                                        <Form.Group
                                            controlId="ControlSelect1"
                                            className="mb-4"
                                        >
                                            <Form.Label>Baud Rate</Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                aria-label="baud-rate"
                                                value={uartDetails.baudrate}
                                                onChange={(e) => {
                                                    setUartDetails({
                                                        ...uartDetails,
                                                        baudrate: Number(
                                                            e.target.value
                                                        ),
                                                    });
                                                }}
                                            >
                                                {renderBaudRateOptions()}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group
                                            controlId="ControlSelect2"
                                            className="mb-4"
                                        >
                                            <Form.Label>Parity</Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                value={uartDetails.parity}
                                                onChange={(e) => {
                                                    setUartDetails({
                                                        ...uartDetails,
                                                        parity: e.target
                                                            .value as UartConfigPostParity,
                                                    });
                                                }}
                                            >
                                                {renderParityOptions()}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group
                                            controlId="ControlSelect3"
                                            className="mb-4"
                                        >
                                            <Form.Label>
                                                Flow Control
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                value={uartDetails.flowcontrol}
                                                onChange={(e) => {
                                                    setUartDetails({
                                                        ...uartDetails,
                                                        flowcontrol: e.target
                                                            .value as UartConfigFlowcontrol,
                                                    });
                                                }}
                                            >
                                                <option value="NONE">
                                                    None
                                                </option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group
                                            controlId="ControlSelect4"
                                            className="mb-4"
                                        >
                                            <Form.Label>Stop Bits</Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                value={uartDetails.stopbits}
                                                onChange={(e) => {
                                                    setUartDetails({
                                                        ...uartDetails,
                                                        stopbits: Number(
                                                            e.target.value
                                                        ),
                                                    });
                                                }}
                                            >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group
                                            controlId="ControlSelect5"
                                            className="mb-4"
                                        >
                                            <Form.Label>Data Bits</Form.Label>
                                            <Form.Control
                                                as="select"
                                                custom
                                                value={uartDetails.databits}
                                                onChange={(e) => {
                                                    setUartDetails({
                                                        ...uartDetails,
                                                        databits: Number(
                                                            e.target.value
                                                        ),
                                                    });
                                                }}
                                            >
                                                <option value="7">7</option>
                                                <option value="8">8</option>
                                            </Form.Control>
                                        </Form.Group>
                                        <hr />

                                        <Form.Group
                                            controlId="ControlTextarea1"
                                            className="mt-4 mb-4"
                                        >
                                            <Form.Label>
                                                Default Message
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                aria-label="alert-subject"
                                                value={message.subject}
                                                onChange={(e) => {
                                                    setMessage({
                                                        ...message,
                                                        subject: e.target.value,
                                                    });
                                                }}
                                                placeholder="Type subject header here"
                                                className="mb-2"
                                                required
                                            />
                                            <Form.Control
                                                as="textarea"
                                                aria-label="alert-message"
                                                placeholder="Type message here"
                                                value={message.text}
                                                onChange={(e) => {
                                                    setMessage({
                                                        ...message,
                                                        text: e.target.value,
                                                    });
                                                }}
                                                rows={4}
                                            />
                                        </Form.Group>
                                        <hr />
                                        {alertConfig && (
                                            <AlertNoticeConfig
                                                config={alertConfig}
                                                initialRecipients={
                                                    initialMemberList
                                                }
                                                onChange={onAlertConfigChange}
                                            />
                                        )}
                                    </div>
                                    <div className="form-box-footer">
                                        <HoverAuthorizeTooltip permission="gateway:update">
                                            <Button
                                                className="save-btn"
                                                onClick={save}
                                            >
                                                SAVE
                                            </Button>
                                        </HoverAuthorizeTooltip>
                                    </div>
                                </fieldset>
                            </Form>
                        </Col>
                    </Row>
                    <Modal
                        centered
                        show={isLoadingModalShow}
                        onHide={() => setIsLoadingModalShow(false)}
                        backdrop="static"
                        keyboard={false}
                        className="border-0"
                    >
                        <img
                            className={
                                isLoading === true
                                    ? "loader-gif d-block"
                                    : "loader-gif d-none"
                            }
                            src={ripple}
                            alt="loading"
                        />
                    </Modal>
                </Container>
            </WrapperInner>
        </ContentWrapper>
    );
};

export default Uart;
