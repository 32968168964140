import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";

const DescriptorTab = (props: any) => {
    const [gatewayDescriptor, setGatewayDescriptor]: any = useState({});
    useEffect(() => {
        const fetch = async () => {
            setGatewayDescriptor(props.registryDetails);
        };
        fetch();
    }, [props.registryDetails]);
    return (
        <Row className="justify-content-md-center">
            <Col sm={9} className="mt-4 mb-5">
                <Row>
                    <Col lg xs={12} className="pl-5 pr-5 discriptor-box">
                        <div className="descriptor-item">
                            <h6 className="float-left">UUID</h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor?.info?.UUID || "-"}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">Serial Number</h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor?.info?.SNO || "-"}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">Ethernet MAC Address</h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor?.info?.PMAC || "-"}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">WiFi MAC Address</h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor?.info?.WMAC || "-"}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">Model Number</h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor.info?.MOD || "-"}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">Product Version</h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor.info?.PRV || "-"}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">Firmware Version</h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor.info?.FWV || "-"}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">
                                Sensor Cache Storage Size
                            </h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor.info?.ICAC || "-"}
                            </h6>
                        </div>
                    </Col>
                    <Col lg xs={12} className="pl-5 pr-5 discriptor-box">
                        <div className="descriptor-item">
                            <h6 className="float-left">Number of LDS Ports</h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor.info?.IPRT || "-"}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">
                                Configuration Storage
                            </h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor.info?.ICFG || "-"}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">
                                Maximum LDSUs per Gateway
                            </h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor.info?.MLG || "-"}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">
                                Machine to Machine Config
                            </h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor.info?.M2M || "-"}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">GPS Location</h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor.location
                                    ? `${gatewayDescriptor.location[0]}, ${gatewayDescriptor.location[1]}`
                                    : "-"}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">Auto-Scan</h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor.info?.WASC || "-"}
                            </h6>
                        </div>
                        <div className="descriptor-item">
                            <h6 className="float-left">Sensor Cache Status</h6>
                            <h6 className="ginfo float-right">
                                {gatewayDescriptor.info?.WSCS || "-"}
                            </h6>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default DescriptorTab;
