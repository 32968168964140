import { Link } from "react-router-dom";

import {
    Container,
    Row,
    Form,
    Col,
    FormControl,
    InputGroup,
    Button,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useModal } from "components/modals/ModalTemplate";
import { ReactComponent as CopySvg } from "assets/svg/copy2.svg";
import { ReactComponent as InfoSvg } from "assets/svg/info.svg";
import { useState, useEffect } from "react";
import {
    generateAccessKey,
    getAllAccessKeys,
    removeAccessKey,
    revokeAccessKey,
} from "service/settingsService";
import { isHttpSuccess } from "utils/functions";
import {
    AccessKeyScope,
    AccessTokenScopeOptions,
    DATE_FORMAT,
    ExpirationAccessTokenOptions,
    Patterns,
} from "constant";
import { capitalize, cloneDeep } from "lodash";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import moment from "moment";
import { AccessKeyCreation, AccessKeyServerRes } from "types/Settings";
import useCollectSort from "hooks/useCollectSort";

const ManageAccessKeys = () => {
    // HOOKS, STATES
    const {
        openModal,
        modal: ActionModal,
        setModalShow,
        setModalData,
    } = useModal();
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);
    const defaultNewAccessToken: AccessKeyCreation = {
        name: "",
        expiration: 7,
        scope: [],
    };

    const [toolTipMessage, setToolTipMessage] =
        useState<string>("Copy to clipboard");
    const tooltip = <Tooltip id="tooltip">{toolTipMessage}</Tooltip>;
    const [accessKeys, setAccessKeys] = useState<AccessKeyServerRes[]>([]);

    const [initialAccessKeys, setInitialAccessKeys] = useState<
        AccessKeyServerRes[]
    >([]);
    const { data: accessKeyCollection, sortIcon } = useCollectSort(accessKeys);
    const [isPageLoaded, setIsPageLoaded] = useState(false);

    // USE-EFFECT
    useEffect(() => {
        (async () => {
            const allAccessKeysRes = await getAllAccessKeys();
            if (isHttpSuccess(allAccessKeysRes?.status)) {
                setAccessKeys(allAccessKeysRes?.data);
                setInitialAccessKeys(allAccessKeysRes?.data);
                setIsPageLoaded(true);
            } else {
                setIsPageLoaded(true);
                showErrorAlert({
                    message:
                        "Page failed to load properly. Please refresh again.",
                });
            }
        })();
    }, []);

    // FUNCTIONS
    const generateKey = async (openedModalData: any) => {
        const generateKeyRes = await generateAccessKey({
            ...openedModalData,
            name: openedModalData?.name?.trim(),
        });

        if (isHttpSuccess(generateKeyRes.status)) {
            showSuccessAlert({
                message: "A new API access key is generated successfully.",
            });
            setModalShow(false);
            setAccessKeys([generateKeyRes.data, ...accessKeys]);
            setInitialAccessKeys([generateKeyRes.data, ...accessKeys]);
        } else {
            setSimpleModalData({
                resObj: generateKeyRes,
            } as SimpleModalDataType);
        }
    };

    const openScope = (access_key_uuid: string) => {
        const accessToken = accessKeys.find(
            ({ uuid }: { uuid: string }) => uuid === access_key_uuid
        );

        openModal({
            key: "",
            modalType: "content",
            modalTitle: "Scope of API Access Key",
            width: 600,
            modalContent: (openedModalData: any) => (
                <>
                    {accessToken?.scope?.map((name: string) => {
                        return (
                            <div key={name}>
                                <h6 className="text-primary-blue-3">
                                    {AccessKeyScope[name].scopeHeader}
                                </h6>
                                <p className="text-primary-blue-4">
                                    {AccessKeyScope[name].scopeDescription}
                                </p>
                            </div>
                        );
                    })}
                </>
            ),
            modalFooter: "",
        });
    };

    const copyToClipboard = (text: any) => {
        navigator.clipboard.writeText(text);
    };

    const removeAccessKeyHandler = async (token_uuid: string) => {
        const removeKeyRes = await removeAccessKey(token_uuid);

        if (isHttpSuccess(removeKeyRes.status)) {
            const removedAccessKeysArray: any = accessKeys.filter(
                ({ uuid }: { uuid: string }) => uuid !== token_uuid
            );
            setAccessKeys(removedAccessKeysArray);
            setInitialAccessKeys(removedAccessKeysArray);
            showSuccessAlert({
                message: "API access key is removed successfully.",
            });
        } else {
            setSimpleModalData({
                resObj: removeKeyRes,
            } as SimpleModalDataType);
        }
    };

    const revokeAccessKeyHandler = async (token_uuid: string) => {
        const revokeKeyRes = await revokeAccessKey(token_uuid);

        if (isHttpSuccess(revokeKeyRes.status)) {
            showSuccessAlert({
                message: "API access key is revoked successfully.",
            });

            const updatedAccessKeys = accessKeys.map((accessKey: any) => {
                if (accessKey.uuid === token_uuid) {
                    return revokeKeyRes.data;
                } else {
                    return accessKey;
                }
            });
            setAccessKeys(updatedAccessKeys);
            setInitialAccessKeys(updatedAccessKeys);
        } else {
            setSimpleModalData({
                resObj: revokeKeyRes,
            } as SimpleModalDataType);
        }
    };

    const searchAccessKey = (query: string) => {
        if (!query) {
            setAccessKeys(initialAccessKeys);
        }
        const accessKeys_ = cloneDeep(initialAccessKeys).filter(
            ({ name }: { name: string }) => {
                return name.toLowerCase().includes(query.toLowerCase());
            }
        );
        setAccessKeys(accessKeys_);
    };

    const openUpdateKeyActionConfirmation = (
        action: string,
        token_uuid: string
    ) => {
        openModal({
            key: "",
            modalType: "updateConfirm",
            modalTitle: "Confirmation",
            modalIcon: "help_outline",
            modalContent: (
                <span
                    dangerouslySetInnerHTML={{
                        __html: `Are you sure you want to ${action} this API access key?<br/>`,
                    }}
                />
            ),
            resendFunction: () => {
                action === "revoke"
                    ? revokeAccessKeyHandler(token_uuid)
                    : removeAccessKeyHandler(token_uuid);
            },
        });
    };
    const renderAccessKeysList = () => {
        const lst = accessKeyCollection.map(
            ({
                name,
                token,
                uuid,
                scope,
                create_time,
                expire_time,
                last_use,
                status,
            }: AccessKeyServerRes) => {
                const scopeDescriptionArray = scope.reduce(
                    (acc: any, scopeName: string) => {
                        acc.push(AccessKeyScope[scopeName].scopeHeader);
                        return acc;
                    },
                    []
                );
                return (
                    <div
                        className="table-row access-key-list"
                        key={uuid}
                        aria-label="each-access-key"
                    >
                        <Row className="no-checkbox">
                            <Col xl={2}>{name}</Col>
                            <Col xl={3} className="d-flex align-items-center">
                                <div className="transaction-id-col">
                                    <span className="access-key-label">
                                        API Access Key:{" "}
                                    </span>
                                    {token}
                                </div>
                                <div className="ml-3 mr-5 copy">
                                    <OverlayTrigger
                                        placement="auto-start"
                                        overlay={tooltip}
                                    >
                                        <CopySvg
                                            onClick={() => {
                                                copyToClipboard(token);
                                                setToolTipMessage("Copied");
                                            }}
                                            onMouseLeave={() => {
                                                setToolTipMessage(
                                                    "Copy to clipboard"
                                                );
                                            }}
                                            aria-label="copyToClipboard"
                                        />
                                    </OverlayTrigger>
                                </div>
                            </Col>
                            <Col xl={2} className="d-flex align-items-center">
                                <div className="access-key-scope">
                                    <span className="access-key-label">
                                        Scope:{" "}
                                    </span>
                                    {scopeDescriptionArray.join(", \n")}
                                </div>
                                <div className="ml-1 mr-5">
                                    {" "}
                                    <InfoSvg
                                        onClick={() => {
                                            openScope(uuid);
                                        }}
                                        className="scopeInfo"
                                        aria-label="scopeInfo"
                                    />
                                </div>
                            </Col>
                            <Col xl={1}>
                                <span className="access-key-label">
                                    Created:{" "}
                                </span>
                                {moment(create_time).format(DATE_FORMAT)}
                            </Col>
                            <Col xl={1}>
                                <span className="access-key-label">
                                    Expires:{" "}
                                </span>
                                {moment(expire_time).format(DATE_FORMAT)}
                            </Col>
                            <Col xl={1}>
                                {" "}
                                <span className="access-key-label">
                                    Last used:{" "}
                                </span>
                                {last_use
                                    ? moment.unix(last_use).format(DATE_FORMAT)
                                    : "-"}
                            </Col>
                            <Col
                                xl={1}
                                className={
                                    status === "ACTIVE"
                                        ? "default-green-color"
                                        : "default-red-color"
                                }
                            >
                                {capitalize(status)}
                            </Col>
                            <Col xl={1}>
                                <Button
                                    id="access-key-button"
                                    variant="danger"
                                    onClick={() => {
                                        status === "ACTIVE"
                                            ? openUpdateKeyActionConfirmation(
                                                  "revoke",
                                                  uuid
                                              )
                                            : openUpdateKeyActionConfirmation(
                                                  "remove",
                                                  uuid
                                              );
                                    }}
                                >
                                    {status === "ACTIVE" ? "Revoke" : "Remove"}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                );
            }
        );
        return lst;
    };

    const generateAccessKeyHandler = () => {
        setModalData(defaultNewAccessToken);
        openModal({
            key: "",
            modalType: "content",
            modalTitle: "New API Access Key",
            width: 600,
            modalContent: (openedModalData: any) => (
                <Form>
                    <Form.Group className="text-gray input-right-text">
                        <Form.Label className="text-left w-100 text-primary-blue-3">
                            Name for Access Key
                        </Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Control
                                type="text"
                                name="access-key-name"
                                value={openedModalData.name}
                                placeholder="Enter a name for access key"
                                isInvalid={
                                    openedModalData?.name?.trim().length < 1 ||
                                    openedModalData?.name?.trim().length > 32 ||
                                    !Patterns.asciiPattern.test(
                                        openedModalData?.name?.trim()
                                    )
                                }
                                onChange={(e) => {
                                    setModalData({
                                        ...openedModalData,
                                        name: e.target.value,
                                    });
                                }}
                                aria-label="input access key name"
                            />
                            <Form.Control.Feedback type="invalid">
                                {`Name should consist of 1 to 32 ASCII characters only.`}
                            </Form.Control.Feedback>
                        </InputGroup>

                        <Form.Label className="text-left w-100 text-primary-blue-3">
                            Expiration
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                as="select"
                                name="access-key-expiration-options"
                                custom
                                value={openedModalData.expiration}
                                onChange={(e) => {
                                    setModalData({
                                        ...openedModalData,
                                        expiration: Number(e.target.value),
                                    });
                                }}
                                aria-label="select expiration"
                            >
                                {ExpirationAccessTokenOptions.map(
                                    (days: number) => (
                                        <option value={days} key={days}>
                                            {days}-Days
                                        </option>
                                    )
                                )}
                            </Form.Control>
                        </InputGroup>
                        <Form.Label className="text-left w-100 mt-3 text-primary-blue-3">
                            Select Scopes
                        </Form.Label>
                        <InputGroup className="lightCheckbox">
                            <Form.Check
                                type="checkbox"
                                custom
                                id={AccessTokenScopeOptions.SENSOR}
                                checked={openedModalData?.scope?.includes(
                                    AccessTokenScopeOptions.SENSOR
                                )}
                                onChange={(e: any) => {
                                    let cloned = cloneDeep(
                                        openedModalData.scope
                                    );

                                    if (
                                        openedModalData?.scope?.includes(
                                            AccessTokenScopeOptions.SENSOR
                                        )
                                    ) {
                                        const cloned_ = cloned.filter(
                                            (o: string) =>
                                                o !==
                                                AccessTokenScopeOptions.SENSOR
                                        );
                                        cloned = cloned_;
                                    } else {
                                        cloned.push(
                                            AccessTokenScopeOptions.SENSOR
                                        );
                                    }
                                    setModalData({
                                        ...openedModalData,
                                        scope: cloned,
                                    });
                                }}
                                value={AccessTokenScopeOptions.SENSOR}
                                label={
                                    <span className="mr-sm-2 ml-1">
                                        Retrieve sensor data
                                    </span>
                                }
                            />

                            <Form.Check
                                type="checkbox"
                                custom
                                id={AccessTokenScopeOptions.ACTUATOR}
                                checked={openedModalData?.scope?.includes(
                                    AccessTokenScopeOptions.ACTUATOR
                                )}
                                onChange={(e: any) => {
                                    let cloned = cloneDeep(
                                        openedModalData.scope
                                    );

                                    if (
                                        openedModalData?.scope?.includes(
                                            AccessTokenScopeOptions.ACTUATOR
                                        )
                                    ) {
                                        const cloned_ = cloned.filter(
                                            (o: string) =>
                                                o !==
                                                AccessTokenScopeOptions.ACTUATOR
                                        );
                                        cloned = cloned_;
                                    } else {
                                        cloned.push(
                                            AccessTokenScopeOptions.ACTUATOR
                                        );
                                    }
                                    setModalData({
                                        ...openedModalData,
                                        scope: cloned,
                                    });
                                }}
                                value={AccessTokenScopeOptions.ACTUATOR}
                                label={
                                    <span className="mr-sm-2 ml-1">
                                        Send commands to actuators
                                    </span>
                                }
                            />
                        </InputGroup>
                    </Form.Group>
                </Form>
            ),
            modalFooter: (openedModalData: any) => (
                <div className="w-100 d-flex justify-content-between align-items-center m-0">
                    {!openedModalData?.scope?.length && (
                        <span className="text-gray scope-warning-msg">
                            {`Please select at least one scope.`}
                        </span>
                    )}

                    <Button
                        className="generate-key-btn"
                        disabled={
                            openedModalData?.name?.length < 1 ||
                            openedModalData?.name?.length > 32 ||
                            !openedModalData?.scope?.length
                        }
                        onClick={() => {
                            generateKey(openedModalData);
                        }}
                    >
                        GENERATE KEY
                    </Button>
                </div>
            ),
        });
    };
    return (
        <>
            <ContentWrapper
                isLoading={!isPageLoaded}
                // isForbiddenResource={isForbiddenResource}
                title="Manage API Access Keys"
                simpleModalData={simpleModalData}
            >
                <div className="page-content gateway-list-page">
                    <Container fluid>
                        <Row>
                            <Col sm={12}>
                                <h5 className="page-title">
                                    Manage API Access Keys
                                </h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Breadcrumb className="w-100">
                                    <Breadcrumb.Item linkAs="span">
                                        <Link to="/settings">Settings</Link>{" "}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item active>
                                        Manage API Access Keys
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                sm={12}
                                className="d-md-flex justify-content-end order-md-2 order-1 "
                            >
                                <Button
                                    className="mr-3"
                                    id="generate-key-button"
                                    onClick={generateAccessKeyHandler}
                                >
                                    Generate API Access Key
                                </Button>

                                <div className="search">
                                    <InputGroup>
                                        <FormControl
                                            type="text"
                                            placeholder="Search.."
                                            aria-describedby="button-addon2"
                                            onChange={(e) => {
                                                searchAccessKey(e.target.value);
                                            }}
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row className="cstm-table mt-4">
                            <Col sm={12}>
                                <div className="table-head access-key-header">
                                    <Row className="no-checkbox ">
                                        <Col xl={2}>
                                            Name {sortIcon("name")}
                                        </Col>
                                        <Col xl={3}>
                                            API Access Key {sortIcon("token")}
                                        </Col>
                                        <Col xl={2}>
                                            Scope {sortIcon("scope")}
                                        </Col>

                                        <Col xl={1}>
                                            Created{sortIcon("create_time")}
                                        </Col>
                                        <Col xl={1}>
                                            Expires{sortIcon("expire_time")}
                                        </Col>
                                        <Col xl={1}>
                                            Last used{sortIcon("last_use")}
                                        </Col>
                                        <Col xl={1}>
                                            Status{sortIcon("status")}
                                        </Col>
                                        <Col xl={1}>Action</Col>
                                    </Row>
                                </div>
                                {renderAccessKeysList()}
                                <div
                                    className={
                                        !accessKeys.length
                                            ? "mt-5 text-center d-block"
                                            : "mt-5 text-center d-none"
                                    }
                                >
                                    No API Access Keys.
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ContentWrapper>
            {ActionModal}
        </>
    );
};

export default ManageAccessKeys;
