import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Collapse from "react-bootstrap/Collapse";
import create from "zustand";
import * as _ from "lodash";
import { useHistory, useParams, Link } from "react-router-dom";
import {
    CHART_ERROR_MESSAGE,
    CHART_NAME_PATTERN,
    ColorPicker,
    ContainerStyled,
    UnitPicker,
    useCreateDashboardStore,
} from "../create";
import { GatewayChartFor, GateWayChartType } from "../create/GatewayCharts";
import {
    getPanelDashboardV2,
    updatePanelV2,
    deletePanelV2,
} from "service/dashboardService";
import DropdownItem from "react-bootstrap/DropdownItem";
import { AppContext } from "context/appContext";
import { useContext } from "react";
import { CHART_DUPLICATE_COLOR_MESSAGE, HttpStatus } from "constant";
import { OtherChartFor } from "../create/OtherCharts";
import deleteSvg from "assets/svg/delete.svg";
import { getMQTTQueryByDevice } from "../create";
import { showErrorAlert } from "utils/alert";
import { MultipleChartBlock } from "../create/MultipleChartBlock";
import { fetchDevices } from "utils/gatewayFunctions";
import { cloneDeep } from "lodash";
import { NoInformationColumn } from "components/common/NoInformationColumn";
import { useMetricDefined } from "hooks/useMetricDefined";

enum ChartSource {
    GATEWAY,
    SENSOR,
    ACTUATOR,
    OTHER,
}

export enum SensorChartType {
    TIME = "TIME",
    STACKED = "STACKED",
}

type ChartRowColumn = {
    key: string;
    name: string;
    render?: (data: any) => React.ReactNode;
};

type UpdateChartRowProps = {
    [key: string]: {
        type: "row" | "template";
        filter: (data: any) => boolean;
        columns?: ChartRowColumn[];
        template?: (data: any) => React.ReactNode;
    };
};

const dataStore = create<{
    data: any[];
    setData: (data: any[]) => void;
    updateValue: (uuid: string, key: string, value: any) => void;
    deleteChart: (uuid: string) => void;
    devices: {
        allRegistry: any[];
        deviceList: any[];
    };
    setDevices: (devices: any) => void;
    chartUpdated: string[];
    chartDeleted: string[];
}>((set, get) => ({
    data: [],
    setData: (data) => set({ data }),
    updateValue: (uuid, key, value) => {
        const currentData = get().data.find((item) => item.uuid === uuid);

        if (
            typeof _.get(currentData, key) === "undefined" ||
            _.get(currentData, key) === value
        )
            return;

        set((state) => ({
            data: state.data.map((item) =>
                item.uuid === uuid ? _.set(item, key, value) : item
            ),
            chartUpdated: _.uniq([...state.chartUpdated, uuid]),
        }));
    },
    deleteChart: (uuid) =>
        set((state) => ({
            data: state.data.filter((item) => item.uuid !== uuid),
            chartDeleted: _.uniq([...state.chartDeleted, uuid]),
        })),
    devices: {
        allRegistry: [],
        deviceList: [],
    },
    setDevices: (devices) => set({ devices }),
    chartUpdated: [],
    chartDeleted: [],
}));

const saveStore = create<{
    dashboardId: string;
    setDashboardId: (dashboardId: string) => void;
    isSaving: boolean;
    setIsSaving: (isSaving: boolean) => void;
}>((set) => ({
    dashboardId: "",
    setDashboardId: (dashboardId) => set({ dashboardId }),
    isSaving: false,
    setIsSaving: (isSaving) => set({ isSaving }),
}));

export const EditDropdownLink = ({
    panelId,
    ...props
}: { panelId: string } & JSX.IntrinsicAttributes) => {
    const history = useHistory();
    const {
        storeData: {
            dashboard: { currentDashboard },
        },
    } = useContext(AppContext);

    return (
        <DropdownItem
            {...props}
            onClick={() => {
                history.push(
                    `/dashboard/${currentDashboard.key}/update/${panelId}`
                );
            }}
        >
            Edit
        </DropdownItem>
    );
};

export const DeviceMapRequest = {
    SENSOR: {
        attributes: (attributes: any[]) =>
            attributes.map((item) =>
                objectFluent(
                    {
                        color: "",
                        device_id: (device_id: string) =>
                            device_id.split("/")[0],
                        gateway_id: () => item.gateway_id || item.gatewayId,
                        name: () => item.sensor_type || item.name,
                        said: () => item.said ?? item.SAID,
                        show_alert: false,
                        time_series: getMQTTQueryByDevice(item),
                        unit: "na",
                    },
                    item
                )
            ),
        source: "SENSOR",
        chart: "",
        name: "",
    },
    ACTUATOR: {
        attributes: (attributes: any[]) =>
            attributes.map((item) =>
                objectFluent(
                    {
                        color: "",
                        device_id: (device_id: string) =>
                            device_id.split("/")[0],
                        gateway_id: () => item.gateway_id || item.gatewayId,
                        name: () => item.device_name || item.name,
                        said: () => item.said ?? item.SAID,
                        show_alert: false,
                        time_series: getMQTTQueryByDevice(item),
                        unit: "na",
                    },
                    item
                )
            ),
        source: "ACTUATOR",
        name: "",
        chart: "TIME",
    },
    GATEWAY: {
        attributes: (attributes: any[]) =>
            attributes.map((item) =>
                objectFluent(
                    {
                        type_: "",
                        gateway_id: "",
                    },
                    item
                )
            ),
        source: "",
        chart: "",
        name: "",
    },
    OTHER: {
        attributes: (attributes: any[]) =>
            attributes.map((item) =>
                objectFluent(
                    {
                        organization_id: "",
                        type_: "",
                    },
                    item
                )
            ),
        chart: "",
        name: "",
        source: "",
    },
};

const TextWrapComponent = ({ width, children }: any) => (
    <div
        style={{
            whiteSpace: "nowrap",
            maxWidth: width || 180,
            textOverflow: "ellipsis",
            overflow: "hidden",
        }}
    >
        {children}
    </div>
);

const UpdateChartRowObj: UpdateChartRowProps = {
    "Gateway charts": {
        type: "row",
        filter: (item: any) => item.source === ChartSource[ChartSource.GATEWAY],
        columns: [
            {
                key: "name",
                name: "Chart Name",
                render: (data: any) => {
                    const { updateValue } = dataStore();

                    return (
                        <Form.Control
                            type="text"
                            placeholder="Enter chart name"
                            defaultValue={data.name}
                            onChange={(e) =>
                                updateValue(data.uuid, "name", e.target.value)
                            }
                        />
                    );
                },
            },
            {
                key: "attributes[0].gateway_id",
                name: "Gateway",
                render: (data: any) => {
                    const {
                        devices: { allRegistry },
                    } = dataStore();

                    return allRegistry?.find(
                        (device) =>
                            device.gateway_id ===
                            _.get(data, "attributes[0].gateway_id")
                    )?.name;
                },
            },
            {
                key: "attributes[0].type_",
                name: "Chart For",
                render: (data: any) =>
                    GatewayChartFor[
                        _.get(
                            data,
                            "attributes[0].type_",
                            "STATUS"
                        ).capitalize() as keyof typeof GatewayChartFor
                    ],
            },
            {
                key: "chart",
                name: "Chart Type",
                render: (data: any) => {
                    const { updateValue } = dataStore();

                    return (
                        <Form.Control
                            as="select"
                            defaultValue={String(data.chart).capitalize()}
                            onChange={(e) =>
                                updateValue(
                                    data.uuid,
                                    "chart",
                                    e.target.value.toUpperCase()
                                )
                            }
                        >
                            {Object.keys(GateWayChartType).map((key) => (
                                <option key={key} value={key}>
                                    {
                                        GateWayChartType[
                                            key as keyof typeof GateWayChartType
                                        ]
                                    }
                                </option>
                            ))}
                        </Form.Control>
                    );
                },
            },
        ],
    },
    "Single sensor charts": {
        type: "row",
        filter: (item: any) =>
            item.source === ChartSource[ChartSource.SENSOR] &&
            SensorChartType.TIME === item.chart,
        columns: [
            {
                key: "name",
                name: "Chart Name",
                render: (data: any) => {
                    const { updateValue } = dataStore();

                    return (
                        <Form.Control
                            type="text"
                            placeholder="Enter chart name"
                            defaultValue={data.name}
                            onChange={(e) =>
                                updateValue(data.uuid, "name", e.target.value)
                            }
                        />
                    );
                },
            },
            {
                key: "sensor",
                name: "Sensor",
                render: (data: any) => {
                    const {
                        devices: { deviceList },
                    } = dataStore();

                    const sensorName = deviceList?.find(
                        (device) =>
                            device.device_id ===
                            _.get(data, "attributes[0].device_id") +
                                "/" +
                                _.get(data, "attributes[0].said")
                    )?.device_name;

                    if (!sensorName) {
                        return <NoInformationColumn />;
                    }

                    return sensorName;
                },
            },
            {
                key: "attributes[0].name",
                name: "Type",
                render: (data: any) => {
                    const {
                        devices: { deviceList },
                    } = dataStore();

                    const device = deviceList?.find(
                        (device) =>
                            device.device_id ===
                            _.get(data, "attributes[0].device_id") +
                                "/" +
                                _.get(data, "attributes[0].said")
                    );

                    return device?.sensor_type || data.attributes[0].name;
                },
            },
            {
                key: "gateway",
                name: "Gateway",
                render: (data: any) => {
                    const {
                        devices: { allRegistry },
                    } = dataStore();

                    const displayedGateway = allRegistry?.find(
                        (device) =>
                            device.gateway_id ===
                            _.get(data, "attributes[0].gateway_id")
                    );

                    return displayedGateway?.name;
                },
            },
            {
                key: "attributes[0].device_id",
                name: "LDSU UUID",
                render: (data: any) => (
                    <TextWrapComponent>
                        {_.get(data, "attributes[0].device_id")}
                    </TextWrapComponent>
                ),
            },
            {
                key: "unit",
                name: "Unit",
                render: (data: any) => {
                    const {
                        updateValue,
                        devices: { deviceList },
                    } = dataStore();
                    const device = deviceList?.find(
                        (device) =>
                            device.device_id ===
                            _.get(data, "attributes[0].device_id") +
                                "/" +
                                _.get(data, "attributes[0].said")
                    );

                    return (
                        <UnitPicker
                            defaultValue={_.get(data, "attributes[0].unit")}
                            data={device?.units || device?.unit.split(",")}
                            onChange={(e, unit) =>
                                updateValue(data.uuid, "unit", unit)
                            }
                        />
                    );
                },
            },
            {
                key: "attributes[0].color",
                name: "Color",
                render: (data: any) => {
                    const { updateValue } = dataStore();
                    return (
                        <ColorPicker
                            defaultValue={data.attributes[0].color}
                            onChange={(event, color) => {
                                updateValue(
                                    data.uuid,
                                    "attributes[0].color",
                                    color
                                );
                            }}
                        />
                    );
                },
            },
            {
                key: "attributes[0].show_alert",
                name: "Alert line",
                render: (data: any) => {
                    const {
                        updateValue,
                        devices: { deviceList },
                    } = dataStore();
                    const device = deviceList?.find(
                        (device) =>
                            device.device_id ===
                            _.get(data, "attributes[0].device_id") +
                                "/" +
                                _.get(data, "attributes[0].said")
                    );

                    return (
                        <Form.Check
                            type="switch"
                            id={`switch-alert-${device?.device_id}`}
                            defaultChecked={data.attributes[0].show_alert}
                            disabled={!device?.alert_available}
                            onChange={(e) =>
                                updateValue(
                                    data.uuid,
                                    "attributes[0].show_alert",
                                    e.target.checked
                                )
                            }
                            custom
                        />
                    );
                },
            },
        ],
    },
    "Multiple sensor charts": {
        type: "template",
        filter: (item: any) =>
            item.source === ChartSource[ChartSource.SENSOR] &&
            SensorChartType.STACKED === item.chart,
        template: (data: any) => <MultipleChartUpdateTemplate data={data} />,
    },
    "Actuator charts": {
        type: "row",
        filter: (item: any) =>
            item.source === ChartSource[ChartSource.ACTUATOR],
        columns: [
            {
                key: "name",
                name: "Chart Name",
                render: (data: any) => {
                    const { updateValue } = dataStore();

                    return (
                        <Form.Control
                            type="text"
                            placeholder="Enter chart name"
                            defaultValue={data.name}
                            onChange={(e) =>
                                updateValue(data.uuid, "name", e.target.value)
                            }
                        />
                    );
                },
            },
            {
                key: "name",
                name: "Actuator",
                render: (data: any) => {
                    const {
                        devices: { deviceList },
                    } = dataStore();

                    const device = deviceList?.find(
                        (device) =>
                            device.device_id ===
                            _.get(data, "attributes[0].device_id") +
                                "/" +
                                _.get(data, "attributes[0].said")
                    );

                    if (!device) {
                        return <NoInformationColumn />;
                    }

                    return device?.device_name;
                },
            },
            {
                key: "type",
                name: "Type",
                render: (data: any) => {
                    const {
                        devices: { deviceList },
                    } = dataStore();

                    const device = deviceList?.find(
                        (device) =>
                            device.device_id ===
                            _.get(data, "attributes[0].device_id") +
                                "/" +
                                _.get(data, "attributes[0].said")
                    );

                    return device?.sensor_type || data.attributes[0].name;
                },
            },
            {
                key: "gateway",
                name: "Gateway",
                render: (data: any) => {
                    const {
                        devices: { allRegistry },
                    } = dataStore();

                    return allRegistry?.find(
                        (device) =>
                            device.gateway_id ===
                            _.get(data, "attributes[0].gateway_id")
                    )?.name;
                },
            },
            {
                key: "attributes[0].device_id",
                name: "LDSU UUID",
            },
            {
                key: "attributes[0].color",
                name: "Color",
                render: (data: any) => {
                    const { updateValue } = dataStore();

                    return (
                        <ColorPicker
                            defaultValue={_.get(data, "attributes[0].color")}
                            onChange={(event, color) => {
                                updateValue(
                                    data.uuid,
                                    "attributes[0].color",
                                    color
                                );
                            }}
                        />
                    );
                },
            },
        ],
    },
    "Other charts": {
        type: "row",
        filter: (item: any) => item.source === ChartSource[ChartSource.OTHER],
        columns: [
            {
                key: "name",
                name: "Chart Name",
                render: (data: any) => {
                    const { updateValue } = dataStore();

                    return (
                        <Form.Control
                            type="text"
                            placeholder="Enter chart name"
                            defaultValue={data.name}
                            onChange={(e) =>
                                updateValue(data.uuid, "name", e.target.value)
                            }
                        />
                    );
                },
            },
            {
                key: "attributes[0].type_",
                name: "Chart Type",
                render: (data: any) =>
                    _.get(data, "attributes[0].type_") === "COUNT_ALERT"
                        ? OtherChartFor.Notification
                        : OtherChartFor.Event,
            },
        ],
    },
};

const UpdateChartRow = React.memo(
    ({ dataChart, columns }: { dataChart: any; columns: any[] }) => {
        const { deleteChart } = dataStore();
        const renderMemo = React.useMemo(
            () => (fn: Function) => fn(dataChart),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [dataChart.uuid]
        );

        return (
            <div className="table-row chart-row px-3">
                <Row className="align-items-center">
                    {columns.map((column, index) => (
                        <Col key={index}>
                            {column.render ? (
                                renderMemo(column.render)
                            ) : (
                                <>{_.get(dataChart, `${column.key}`)}</>
                            )}
                        </Col>
                    ))}
                    <Col
                        md={{ span: 1 }}
                        className="d-flex justify-content-end"
                    >
                        <img
                            src={deleteSvg}
                            alt="delete"
                            className="cursor-pointer"
                            onClick={() => {
                                deleteChart(dataChart.uuid);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
);

const MultipleChartUpdateTemplate: React.FunctionComponent<{
    data: any;
}> = ({ data }) => {
    const {
        updateValue,
        deleteChart,
        devices: { deviceList },
    } = dataStore();
    const [defaultValue, setDefaultValue] = React.useState<any>({
        multipleChartType: "same",
        chart_name: "",
        data: {},
        uuid: "",
    });

    React.useEffect(() => {
        const attributeList = _.get(data, "attributes", []).map((item: any) => {
            const device = deviceList?.find(
                (device) =>
                    device.device_id === item.device_id + "/" + item.said
            );

            return {
                key: device?.device_id,
                ...device,
                ...item,
            };
        });

        const deviceTypes = _.groupBy(attributeList, "sensor_type");
        const multipleChartType =
            _.size(deviceTypes) > 1 ? "different" : "same";
        const deviceData = _.map(deviceTypes, (value, key) => ({
            sensor_type: key !== "undefined" ? key : value[0].name,
            devices: value.pluck("key"),
        }));

        const allDeviceData = cloneDeep(deviceData).reduce(
            (acc: any, curr: any) => {
                const checkIfSensorTypeExist = acc.find(
                    ({ sensor_type }: { sensor_type: string }) =>
                        sensor_type === curr.sensor_type
                );
                if (!checkIfSensorTypeExist) {
                    acc.push(curr);
                } else {
                    const undefinedDevice = curr.devices;
                    checkIfSensorTypeExist.devices = {
                        ...checkIfSensorTypeExist.devices,
                        ...undefinedDevice,
                    };
                }
                return acc;
            },
            []
        );

        setDefaultValue({
            multipleChartType,
            chart_name: _.get(data, "name", ""),
            data: { ...allDeviceData },
            uuid: data.uuid,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.uuid, deviceList]);

    return (
        <MultipleChartBlock
            isEdit
            defaultValue={defaultValue}
            blockKey={{ key: data.uuid }}
            onDelete={({ key }) => deleteChart(key)}
            onChange={(updateData) => {
                if (!_.isEmpty(updateData.data)) {
                    updateValue(data.uuid, "name", updateData.chart_name);
                    const updateDataDevices = {
                        ...(_.get(updateData, "data[0].devices") || {}),
                        ...(_.get(updateData, "data[1].devices") || {}),
                    };

                    updateValue(
                        data.uuid,
                        "attributes",
                        Object.values(updateDataDevices)
                    );
                }
            }}
        />
    );
};

const UpdateChartPanel: React.FunctionComponent<{ title: string }> = (
    props
) => {
    const [open, setOpen] = React.useState(true);
    const { columns, filter, type, template } = UpdateChartRowObj[props.title];
    const data = dataStore((state) => state.data);
    const { isSaving } = saveStore();
    const dataCharts = data.filter(filter);

    if (!dataCharts.length) return null;

    return (
        <fieldset disabled={isSaving}>
            <Row>
                <Col sm={12}>
                    <p
                        onClick={() => setOpen(!open)}
                        className="d-flex align-items-center cursor-pointer chart-panel-title"
                    >
                        <i className={`material-icons ${open ? "active" : ""}`}>
                            expand_more
                        </i>
                        {props.title}
                        <small>{dataCharts.length} Chart(s)</small>
                    </p>
                </Col>

                <Collapse in={open}>
                    <div className="w-100 mb-5">
                        <Col sm={12} className="form-box">
                            {type === "row" ? (
                                <Row className="cstm-table">
                                    <Col sm={12}>
                                        <div className="table-head border-0 pt-0">
                                            <Row className="no-checkbox text-white">
                                                {columns?.map(
                                                    (column, index) => (
                                                        <Col key={index}>
                                                            {column.name}
                                                        </Col>
                                                    )
                                                )}
                                                <Col md={{ span: 1 }}></Col>
                                            </Row>
                                        </div>
                                        {dataCharts.map((dataChart: any) => (
                                            <UpdateChartRow
                                                key={dataChart.uuid}
                                                dataChart={dataChart}
                                                columns={columns || []}
                                            />
                                        ))}
                                    </Col>
                                </Row>
                            ) : (
                                dataCharts.map((dataChart: any) => (
                                    <React.Fragment key={dataChart.uuid}>
                                        {template && template(dataChart)}
                                    </React.Fragment>
                                ))
                            )}
                        </Col>
                    </div>
                </Collapse>
            </Row>
        </fieldset>
    );
};

const PanelSaveButton: React.FunctionComponent = () => {
    const { data, chartUpdated, chartDeleted } = dataStore();
    const { dashboardId, isSaving, setIsSaving } = saveStore();
    const history = useHistory();
    useMetricDefined();

    const onSaveHandler = async () => {
        setIsSaving(true);
        const validates = [];
        try {
            for (const chartId of chartUpdated) {
                const saveData = data.find((item) => item.uuid === chartId);
                const paramsTemplate = _.cloneDeep(
                    DeviceMapRequest[
                        saveData.source as keyof typeof DeviceMapRequest
                    ]
                );

                const params = objectFluent(paramsTemplate, saveData);

                const uniqueColors = _.unionBy(params.attributes, ({ color }) =>
                    _.lowerCase(color)
                );

                if (!CHART_NAME_PATTERN.test(params.name.trim())) {
                    validates.push({
                        message: CHART_ERROR_MESSAGE,
                    });
                }

                if (uniqueColors.length < params.attributes.length) {
                    validates.push({
                        message: CHART_DUPLICATE_COLOR_MESSAGE,
                    });
                }

                if (!validates.length) {
                    await updatePanelV2(dashboardId, chartId, {
                        ...params,
                        name: _.trim(params.name.replace(/\s+/g, " ")),
                    });
                }
            }
        } catch (error) {
            //
        }

        try {
            for (const chartId of chartDeleted) {
                await deletePanelV2(dashboardId, chartId);
            }
        } catch (error) {
            //
        }

        setIsSaving(false);

        if (validates.length > 0) {
            validates.forEach((validate) => {
                showErrorAlert({
                    message: validate.message,
                });
            });
        } else {
            history.push(`/dashboard`);
        }
    };

    return (
        <>
            <Button
                className="ml-2 text-uppercase"
                variant="primary"
                disabled={isSaving}
                onClick={onSaveHandler}
            >
                Save
            </Button>
        </>
    );
};

const DashboardUpdateCharts = (props: any) => {
    const history = useHistory();
    const { dashboardId } = useParams() as { dashboardId: string };
    const [loading, setLoading] = React.useState<boolean>(false);
    const [errorFetch, setErrorFetch] = React.useState<boolean>(false);
    const { setData, setDevices } = dataStore();
    const { setDashboardId, setIsSaving } = saveStore();

    React.useEffect(() => {
        fetchDevices().then((res) => {
            setDevices(res);
            useCreateDashboardStore.setState({
                deviceList: res.deviceList || [],
                allRegistry: res.allRegistry || [],
            });
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (!dashboardId) history.push("/dashboard");

        setDashboardId(dashboardId);
        setIsSaving(false);
        setLoading(true);
        getPanelDashboardV2(dashboardId, {}).then((res: any) => {
            if (res.status === HttpStatus.OK) {
                setData(_.get(res, "data"));
            } else {
                setErrorFetch(true);
            }

            setLoading(false);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dashboardId]);

    return (
        <>
            <div className="page-content">
                {loading ? (
                    <Spinner animation="border" variant="primary" />
                ) : (
                    <ContainerStyled>
                        <Row>
                            <Col sm="12">
                                <h5 className="page-title">
                                    Edit Dashboard Charts
                                </h5>
                            </Col>
                        </Row>

                        {errorFetch ? (
                            <Alert
                                variant="danger"
                                className={`w-100 d-flex justify-content-center ${
                                    props.className || ""
                                }`}
                            >
                                Can not fetch dashboard data from server!&nbsp;
                                <Link className="alert-danger" to="/dashboard">
                                    <strong>Back to dashboard!</strong>
                                </Link>
                            </Alert>
                        ) : (
                            <>
                                <Row>
                                    <Col sm="12">
                                        {Object.keys(UpdateChartRowObj).map(
                                            (key: string, index) => (
                                                <UpdateChartPanel
                                                    key={key}
                                                    title={key}
                                                />
                                            )
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col
                                        sm="12"
                                        className="d-flex justify-content-center mt-4"
                                    >
                                        <Button
                                            className="text-uppercase"
                                            variant="secondary"
                                            onClick={() => {
                                                history.push("/dashboard");
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <PanelSaveButton />
                                    </Col>
                                </Row>
                            </>
                        )}
                    </ContainerStyled>
                )}
            </div>
            <style>{`
                .chart-panel-title {
                    font-size: 18px;
                }

                .chart-panel-title i {
                    transform: rotate(270deg);
                }

                .chart-panel-title i.active {
                    transform: rotate(0deg);
                }

                .chart-panel-title small {
                    margin-left: 10px;
                    color: #828FAB;
                    font-size: 13px;
                    line-height: 18px;
                }

                .cstm-table .chart-row {
                    background-color: var(--create-chart-from-background);
                }

                fieldset[disabled] i,
                fieldset[disabled] button,
                fieldset[disabled] input,
                fieldset[disabled] select,
                fieldset[disabled] img {
                    pointer-events: none;
                }
            `}</style>
        </>
    );
};

export default DashboardUpdateCharts;
