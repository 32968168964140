import {
    orgId,
    createErrorResponseStandard,
    createResponseStandard,
} from "utils/functions";
import AxiosInstance from "./axiosInstance";
import { apiFactory } from "generated";
import { AxiosError } from "axios";
import {
    DashboardPatch,
    DashboardPost,
    PanelV2Post,
    GetPanelsByDashboardIdParamsSource,
    Dashboard,
    PanelOrderPatch,
} from "generated/models";
import { ERROR_BEFORE_OR_AFTER } from "constant";
const APP_DASHBOARD_API = process.env.REACT_APP_DASHBOARD_API;

const dashboardClient = apiFactory.getDashboardsInstance(AxiosInstance);

export type PanelSource = GetPanelsByDashboardIdParamsSource;

export const addNewDashBoard = async (name: string, iconColor: string) => {
    const body: DashboardPost = {
        name: name,
        icon_color: iconColor,
        organization_id: orgId(),
    };

    try {
        const response = await dashboardClient.createDashboard(body);
        return createResponseStandard<Dashboard>(response);
    } catch (error) {
        return createErrorResponseStandard(error as AxiosError);
    }
};

export const getDashboards = async (organizationId: string) => {
    try {
        const response = await dashboardClient.getDashboards({
            organization_id: organizationId,
        });
        return createResponseStandard<Dashboard[]>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getSingleDashboard = async (dashboardId: string) => {
    try {
        const response = await dashboardClient.getDashboardById(dashboardId);
        return createResponseStandard<Dashboard>(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const deleteDashboard = async (dashboardId: string) => {
    try {
        const response = await dashboardClient.deleteDashboardById(dashboardId);
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getSelectedDashboardDetails = async (dashboardId: string) => {
    try {
        const response = await dashboardClient.getDashboardById(dashboardId);
        return createResponseStandard<Dashboard>(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const updateDashboardDetails = async (
    dashboardId: string,
    iconColor: string,
    name: string
) => {
    const body: DashboardPatch = {
        icon_color: iconColor,
        name: name,
    };

    try {
        const response = await dashboardClient.updateDashboardById(
            dashboardId,
            body
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const createSingleSensorPanel = async (
    dashboardId: string,
    body: PanelV2Post
) => {
    try {
        const response = await dashboardClient.createPanelByDashboardId(
            dashboardId,
            body
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getPanelDashboardV2 = async (
    dashboardId: string,
    params: {
        offset?: number | undefined;
        limit?: number | undefined;
        source?: PanelSource;
    }
) => {
    try {
        const response = await dashboardClient.getPanelsByDashboardId(
            dashboardId,
            params
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const getSinglePanelV2 = async (
    dashboard_uuid: string,
    panel_uuid: string
) => {
    try {
        const response = await AxiosInstance.get(
            APP_DASHBOARD_API +
                `/dashboards/${dashboard_uuid}/panels_v2/${panel_uuid}`
        );
        return response;
    } catch (err) {
        return err;
    }
};

export const updatePanelV2 = async (
    dashboard_uuid: string,
    panel_uuid: string,
    body: any
) => {
    try {
        const response = await dashboardClient.updatePanelById(
            dashboard_uuid,
            panel_uuid,
            body
        );

        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(ERROR_BEFORE_OR_AFTER);
    }
};

export const deletePanelV2 = async (dashboardId: string, panelId: string) => {
    try {
        const response = await dashboardClient.deletePanelById(
            dashboardId,
            panelId
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error);
    }
};

export const patchOrderPanelV2 = async (
    dashboard_uuid: string,
    data: PanelOrderPatch
) => {
    try {
        await dashboardClient.updatePanelOrderById(dashboard_uuid, data);
    } catch (err) {
        return err;
    }
};
