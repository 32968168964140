import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
    Row,
    Col,
    Button,
    InputGroup,
    FormControl,
    Form,
} from "react-bootstrap";
import { useDebounce } from "hooks/common";
import { ReactComponent as DeleteIcon } from "assets/svg/delete-white.svg";
import dlt from "assets/svg/delete.svg";
import {
    FAILED_ACTION_MESSAGE,
    ForbiddenErrorMessage,
    HttpStatus,
} from "constant";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { MemberGroupDto } from "types";
import { isHttpSuccess } from "utils/functions";
import useCollectSort from "hooks/useCollectSort";

const defaultSelectIds: Array<string> = [];
const defaultSearchRef: any = {};
const GroupsList = (props: any) => {
    const { groupsData, orgId, onDelete } = props;
    const [groups, setGroups] = useState<MemberGroupDto[]>([]);

    const [selectedIds, setSelectedIds] = useState(defaultSelectIds);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const searchRef = useRef(defaultSearchRef);
    const customDebounce = useDebounce((value: string) => {
        const _searchStr = value
            .toLowerCase()
            .replace(/\s{2,}/, "")
            .trim();

        if (!_searchStr) setGroups(groupsData);

        const results = groupsData.filter((group: any) => {
            const _name = group.name.toLowerCase();
            return _name.includes(_searchStr);
        });
        setGroups(results);
    });
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);
    const { data: groupCollect, sortIcon } = useCollectSort(groups);

    useEffect(() => {
        setGroups(groupsData);
    }, [groupsData]);

    const handleSearchInput = (e: any) => {
        const { value } = e.target;
        customDebounce(value);
    };

    const handleSelectAll = (e: any) => {
        const { checked } = e.target;
        let deleteIds: Array<string> = [];

        if (checked) {
            deleteIds = groups
                .filter((group) => !group.isDefault)
                .reduce(
                    (results: Array<string>, item) => [...results, item.uuid],
                    []
                );
        } else {
            deleteIds = [];
        }

        setSelectedIds(deleteIds);
        setIsSelectAll(checked);
    };

    const handleSelectDeleteGroup = (e: any, id: string) => {
        const { checked } = e.target;
        let deleteIds: Array<string> = [...selectedIds];

        if (checked) {
            deleteIds = [...deleteIds, id];
        } else {
            deleteIds = deleteIds.filter((deleteId: string) => deleteId !== id);
        }

        setSelectedIds(deleteIds);
        setIsSelectAll(deleteIds.length === groups.length - 2);
    };

    const handleDelete = (group?: MemberGroupDto) => {
        let message = `Do you want to delete ${selectedIds.length} selected group(s)?`;
        let title = "Delete Group(s)?";
        if (group) {
            message = `Do you want to delete group  <strong>${group?.name}</strong>?`;
            title = "Delete Group?";
        }

        setSimpleModalData({
            resObj: {
                data: {
                    title,
                },
                message,
            },
            icon: "delete",
            renderModalButtons: (setErrModalVisible: any) => {
                return (
                    <>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setErrModalVisible(false);
                                handleCloseConfirmModal("cancel");
                            }}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => {
                                setErrModalVisible(false);
                                handleCloseConfirmModal("ok", group);
                            }}
                        >
                            DELETE
                        </Button>
                    </>
                );
            },
        } as SimpleModalDataType);
    };

    const handleCloseConfirmModal = async (
        status = "cancel",
        group?: MemberGroupDto
    ) => {
        if (status === "ok") {
            const requestList = (group ? [group.uuid] : selectedIds).map((id) =>
                onDelete(id)
            );
            const deleteResponses = await Promise.all(requestList);
            const isAllDeleteSuccess = deleteResponses.every((item: any) =>
                isHttpSuccess(item.status)
            );
            let mainMessage = "Success";
            let subMessage = `${requestList.length} group(s) deleted successfully.`;
            let _type = "success";

            if (group) {
                subMessage = "Group has been deleted successfully.";
            }

            if (isAllDeleteSuccess) {
                setSelectedIds([]);
                setIsSelectAll(false);
            } else if (deleteResponses[0].status === HttpStatus.FORBIDDEN) {
                mainMessage = "error";
                subMessage = ForbiddenErrorMessage;
                _type = "error";
            } else {
                mainMessage = "error";
                _type = "error";
                subMessage = FAILED_ACTION_MESSAGE;
            }

            setSimpleModalData({
                title: mainMessage,
                type: _type,
                body: subMessage,
            } as SimpleModalDataType);
        }
    };

    return (
        <ContentWrapper simpleModalData={simpleModalData}>
            <Row>
                <Col
                    md={{ span: 4, order: 1 }}
                    xs={{ span: 12, order: 2 }}
                    className="mt-4"
                >
                    {selectedIds.length > 0 && (
                        <Button
                            variant="danger"
                            className="px-2 px-sm-3"
                            onClick={() => handleDelete()}
                        >
                            <DeleteIcon className="delete-icon" /> (
                            {selectedIds.length})
                        </Button>
                    )}
                </Col>
                <Col
                    md={{ span: 8, order: 2 }}
                    xs={{ span: 12, order: 1 }}
                    className="d-md-flex justify-content-end table-head-options mt-4"
                >
                    <HoverAuthorizeTooltip
                        permission="organisation:create"
                        className="mr-3 mb-3"
                    >
                        <Link
                            to={`/new-org-group/${orgId}`}
                            className="btn btn-primary"
                        >
                            + NEW GROUP
                        </Link>
                    </HoverAuthorizeTooltip>
                    <div className="search">
                        <InputGroup>
                            <FormControl
                                type="text"
                                placeholder="Search.."
                                aria-describedby="button-addon2"
                                ref={searchRef}
                                onChange={handleSearchInput}
                            />
                        </InputGroup>
                    </div>
                </Col>
            </Row>
            <Row className="cstm-table">
                <Col sm={12}>
                    <div className="table-head">
                        <Row>
                            <Col sm="1">
                                <HoverAuthorizeTooltip permission="organisation:delete">
                                    <span className="d-inline-block">
                                        <Form.Check
                                            className="mr-sm-2"
                                            type="checkbox"
                                            id="select-all-groups"
                                            checked={isSelectAll}
                                            onChange={handleSelectAll}
                                            custom
                                        />
                                    </span>
                                </HoverAuthorizeTooltip>
                            </Col>
                            <Col>Group Name {sortIcon("name")}</Col>
                            <Col>Type {sortIcon("isDefault")}</Col>
                            <Col>Members {sortIcon("members")}</Col>
                            <Col>Roles {sortIcon("roles")}</Col>
                            <Col sm="1">Action</Col>
                            <Col sm="1"></Col>
                        </Row>
                    </div>
                    {groupCollect.map((group, index: number) => (
                        <div className="table-row" key={group.uuid}>
                            <Row>
                                <Col xs={{ span: 1, order: 1 }}>
                                    <Form.Check
                                        className="mr-sm-2"
                                        type="checkbox"
                                        id={`group-${index}`}
                                        custom
                                        checked={selectedIds.includes(
                                            group.uuid
                                        )}
                                        disabled={group.isDefault}
                                        onChange={(e) =>
                                            handleSelectDeleteGroup(
                                                e,
                                                group.uuid
                                            )
                                        }
                                    />
                                </Col>
                                <Col
                                    className="overflow-text"
                                    md
                                    xs={{ span: 10, order: 2 }}
                                >
                                    <Link
                                        to={`/organisation/${orgId}/group/${group.uuid}`}
                                    >
                                        {group.name}
                                    </Link>
                                </Col>
                                <Col
                                    md={{ offset: 0 }}
                                    xs={{ span: 11, offset: 1, order: 4 }}
                                >
                                    <span className="reading-label">
                                        Type:{" "}
                                    </span>
                                    {group.isDefault ? (
                                        <span className="default">
                                            Default Group
                                        </span>
                                    ) : (
                                        <span className="custom">
                                            Custom Group
                                        </span>
                                    )}
                                </Col>
                                <Col
                                    md={{ offset: 0 }}
                                    xs={{ span: 11, offset: 1, order: 5 }}
                                >
                                    <span className="reading-label">
                                        Members:{" "}
                                    </span>
                                    {group.members.length}
                                </Col>
                                <Col
                                    md={{ offset: 0 }}
                                    xs={{ span: 10, offset: 1, order: 6 }}
                                >
                                    <span className="reading-label">
                                        Roles:{" "}
                                    </span>
                                    {group.roles.length}
                                </Col>

                                <Col xs={{ span: 1, order: 6 }}>
                                    {group.isDefault ? (
                                        ""
                                    ) : (
                                        <HoverAuthorizeTooltip permission="organisation:delete">
                                            <span
                                                className="btn"
                                                onClick={() =>
                                                    handleDelete(group)
                                                }
                                            >
                                                <img
                                                    src={dlt}
                                                    alt="Delete Group"
                                                />
                                            </span>
                                        </HoverAuthorizeTooltip>
                                    )}
                                </Col>
                                <Col
                                    md={{ span: 1, order: "last" }}
                                    xs={{ span: 1, order: 3 }}
                                >
                                    <Link
                                        to={`/organisation/${orgId}/group/${group.uuid}`}
                                    >
                                        <i className="material-icons right">
                                            keyboard_arrow_right
                                        </i>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    ))}

                    {groups.length === 0 && (
                        <div className="table-row">
                            <Row>
                                <Col className="text-center">
                                    No items to manage here.
                                </Col>
                            </Row>
                        </div>
                    )}
                </Col>
            </Row>
        </ContentWrapper>
    );
};

export default GroupsList;
