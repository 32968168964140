import moment from "moment";
import { showErrorAlert } from "./alert";
const getEventInfo = (
    schedule: any,
    range: any,
    points: any,
    interval: any
) => {
    switch (schedule) {
        case "all_day":
            return <p>All day</p>;
        case "between":
            let btwTimeStr = "";
            if (range.length > 1) {
                for (let t = 1; t < range.length; t++) {
                    btwTimeStr =
                        btwTimeStr +
                        `, ${moment(range[t][0], `HH:mm:ss`).format(
                            "h:mm A"
                        )} - ${moment(range[t][1], `HH:mm:ss`).format(
                            "h:mm A"
                        )}`;
                }
            }
            return (
                <p>
                    Between{" "}
                    {`${moment(range[0][0], `HH:mm:ss`).format(
                        "h:mm A"
                    )} - ${moment(range[0][1], `HH:mm:ss`).format("h:mm A")}`}
                    {btwTimeStr}
                </p>
            );
        case "before":
            return (
                <p>Before {moment(range[0][1], `HH:mm:ss`).format("h:mm A")}</p>
            );
        case "before_or_after":
            return (
                <p>
                    Before {moment(range[0][1], `HH:mm:ss`).format("h:mm A")} or
                    after {moment(range[1][0], `HH:mm:ss`).format("h:mm A")}
                </p>
            );
        case "every":
            return <p>{`Every ${interval} min(s)`}</p>;
        case "at":
            let timeStr = "";
            if (points.length > 1) {
                for (let t = 1; t < points.length; t++) {
                    timeStr =
                        timeStr +
                        `, ${moment(points[t], `HH:mm:ss`).format(`h:mm A`)}`;
                }
            }
            return (
                <p>
                    At {moment(points[0], `HH:mm:ss`).format("h:mm A")}
                    {timeStr}
                </p>
            );

        case "after":
            return (
                <p>After {moment(range[0][0], `HH:mm:ss`).format("h:mm A")}</p>
            );
    }
};

export const isValid = (event: any, gateways: any) => {
    const invalid = event.conditions
        .filter(({ resource: { device } }: any) => !!device)
        .some((condition: any) => {
            const {
                type_,
                resource: { device: { gateway_id = "", device_id = "" } = {} },
            } = condition;

            const gateway: any = gateways.find(
                ({ gateway_id: g_id }: any) => g_id === gateway_id
            );
            const ldsu: any = gateway
                ? gateway.configs.find((l: any) => {
                      return l.UID === device_id;
                  })
                : null;

            let valid = false;

            if (!gateway || (type_ !== "GATEWAY" && !ldsu)) {
                valid = true;
            }

            return valid;
        });

    return !invalid;
};

export const showWarning = () => {
    return showErrorAlert({
        message: "Encounter some error with Event condition(s) or Action(s).",
    });
};

export default getEventInfo;
