import { useEffect, useState, useRef, KeyboardEvent } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    Modal,
    InputGroup,
    FormControl,
    Spinner,
    Breadcrumb,
} from "react-bootstrap";
import { ReactComponent as EditIcon } from "assets/svg/edit.svg";
import { useAppContext } from "context/appContext";
import {
    HttpStatus,
    MinNameChar,
    MaxNameChar,
    PHONE_NUMBER_VALIDATION_MESSAGE,
    Patterns,
    SecondsToCountdown,
} from "constant";
import AccountService from "service/accountService";
import { storeUserAction } from "store/actions";
import { renderCountdown } from "utils/functions";
import DefaultModal from "components/modals/ModalTemplate";
import "./UserProfile.css";
import { set, get } from "lodash";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { styled } from "@mui/system";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import { UserDto } from "types";
import { showErrorAlert, showSuccessAlert } from "utils/alert";

const StyledPhoneInput = styled(PhoneInput)`
    input {
        border-color: transparent;
    }
`;

const CustomSwitch = styled(Form.Switch)`
    display: inline-block;
    padding-left: 0;

    .custom-control-input {
        right: 0;
        left: auto;
    }

    .custom-control-label {
        &::before {
            right: -2.5rem;
            left: auto;
        }

        &::after {
            right: -0.9rem;
            left: auto;
        }
    }
`;

const getUserData = (data: any) => ({
    givenName: get(data, "givenName", ""),
    familyName: get(data, "familyName", ""),
    email: get(data, "email", ""),
    phoneNumber: get(data, "phoneNumber", ""),
    emailVerified: get(data, "emailVerified", false),
    phoneNumberVerified: get(data, "phoneNumberVerified", false),
});

const UserProfile = () => {
    const { storeData, storeDispatchActions } = useAppContext();
    const { user } = storeData;
    const { isLoaded: isUserLoaded, userData } = user;
    const mfaRef: any = useRef(null);
    const [isEdit, updateIsEdit] = useState(false);
    const [localUserData, updateLocalUserData] = useState<UserDto>(
        getUserData(userData)
    );
    const [isMFAEnable, updateIsMFAEnable] = useState(false);
    const [verifyCode, updateVerifyCode] = useState("");
    const [codeError, updateCodeError] = useState("");
    const [isShow, updateIsShow] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [isModalLoaded, setIsModalLoaded] = useState(true);
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);

    const phoneNumRef: any = useRef();
    const changeWithDebounce = (e: any) => {
        const { value, name } = e.target;

        if (value.startsWith(" ")) return;

        updateLocalUserData((current) => ({
            ...current,
            [name]: value,
        }));
    };

    useEffect(() => {
        updateLocalUserData(getUserData(userData));
        updateIsMFAEnable(
            userData.userMfaSettingList?.includes("SMS_MFA") || false
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserLoaded, userData]);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => {
                clearInterval(timer);
            };
        }
    }, [countdown, setCountdown]);

    const transformLocalUserData = () => {
        set(localUserData, "givenName", String(localUserData.givenName).trim());
        set(
            localUserData,
            "familyName",
            String(localUserData.familyName).trim()
        );

        return localUserData;
    };

    const handleClickEdit = async () => {
        updateIsEdit(true);
    };

    const handleMFAChange = async (e: any) => {
        const { checked } = e.target;

        if (!localUserData.phoneNumber) {
            mfaRef.current.checked = false;
            return setSimpleModalData({
                type: "error",
                body: "Phone number is required",
            } as SimpleModalDataType);
        }

        const mfaRes = await AccountService.updateMFAStatus({
            name: "SMS",
            enable: checked,
        });
        let messageType = "success";
        let message = `MFA was ${
            checked ? "enabled" : "disabled"
        } successfully!`;

        if (mfaRes.status === HttpStatus.NO_CONTENT) {
            const mfaSettings = userData.userMfaSettingList || [];
            if (!userData.userMfaSettingList?.includes("SMS_MFA")) {
                mfaSettings.push("SMS_MFA");
            }

            updateIsMFAEnable(checked);
            storeDispatchActions(
                storeUserAction({
                    userData: {
                        ...userData,
                        userMfaSettingList: mfaSettings,
                    },
                })
            );
        } else {
            updateIsMFAEnable(!checked);
            messageType = "error";
            message = "Something went wrong!";
        }

        setSimpleModalData({
            type: messageType,
            body: message,
        } as SimpleModalDataType);
    };

    const validateFormData = () => {
        const minNameLength = 1;
        const maxNameLength = 255;

        const checkNameLength = (name: string) =>
            name.length >= MinNameChar && name.length <= MaxNameChar;

        let isValid = true;
        let errorMess = "";
        const { givenName, familyName, phoneNumber } = localUserData;

        if (!checkNameLength(givenName)) {
            isValid = false;
            errorMess = `First name must be between ${minNameLength} and ${maxNameLength} characters`;
        } else if (!checkNameLength(familyName)) {
            isValid = false;
            errorMess = `Last name must be between ${minNameLength} and ${maxNameLength} characters`;
        } else if (
            phoneNumber &&
            (!Patterns.phoneNumberPattern.test(phoneNumber) ||
                phoneNumRef.current.classList.contains("is-invalid"))
        ) {
            isValid = false;
            errorMess = "Invalid mobile number";
        }
        return { isValid, errorMess };
    };

    const handleSave = async () => {
        const { givenName, familyName, phoneNumber } = transformLocalUserData();

        if (
            familyName === userData.familyName &&
            givenName === userData.givenName &&
            phoneNumber === userData.phoneNumber
        ) {
            setSimpleModalData({
                type: "error",
                body: `Nothing changed`,
            } as SimpleModalDataType);
            return;
        }
        const { isValid, errorMess }: any = validateFormData();
        if (!isValid) {
            setSimpleModalData({
                type: "error",
                body: errorMess,
            } as SimpleModalDataType);
            updateIsEdit(true);
            return;
        }
        let updateRes: any;

        if (!phoneNumber || phoneNumber !== userData.phoneNumber) {
            updateRes = await AccountService.updateAccountInfo({
                first_name: givenName,
                last_name: familyName,
                phone: phoneNumber || "",
            });
        } else {
            updateRes = await AccountService.updateAccountInfo({
                first_name: givenName,
                last_name: familyName,
            });
        }

        if (updateRes.status === HttpStatus.NO_CONTENT) {
            // Remove verified icon if number is being updated or removed
            if (phoneNumber !== userData.phoneNumber) {
                updateLocalUserData({
                    ...localUserData,
                    phoneNumber,
                    phoneNumberVerified: false,
                });
                storeDispatchActions(
                    storeUserAction({
                        userData: {
                            ...userData,
                            phone_number: phoneNumber,
                            phone_number_verified: "false",
                        },
                    })
                );
            }
            const userInfoRes: any = await AccountService.getAccountInfo();
            if (userInfoRes.status === 200) {
                updateIsEdit(false);
                storeDispatchActions(
                    storeUserAction({
                        userData: userInfoRes.data,
                        isLoaded: true,
                    })
                );

                setSimpleModalData({
                    resObj: userInfoRes,
                    body: `User profile information has been updated.`,
                } as SimpleModalDataType);
            }
            return;
        }

        const message = get(
            updateRes,
            "data.description",
            "Something went wrong."
        );

        setSimpleModalData({
            type: "error",
            title: "Error",
            body: message,
        } as SimpleModalDataType);
    };
    const handleCancel = async () => {
        updateLocalUserData(userData);
        updateIsEdit(false);
    };

    const handleCloseModal = () => {
        setCountdown(0);
        updateIsShow(false);
        updateVerifyCode("");
    };

    const handleSubmitCode = async () => {
        const res: any = await AccountService.submitVerifyCode({
            code: verifyCode,
        });

        if (res.status === HttpStatus.NO_CONTENT) {
            updateIsShow(false);
            updateVerifyCode("");

            setSimpleModalData({
                type: "success",
                body: `Phone number has been verified.`,
            } as SimpleModalDataType);

            updateLocalUserData({
                ...localUserData,
                phoneNumberVerified: true,
            });
            storeDispatchActions(
                storeUserAction({
                    userData: {
                        ...userData,
                        phone_number_verified: "true",
                    },
                })
            );
        } else {
            setSimpleModalData({
                resObj: res,
            } as SimpleModalDataType);
            updateCodeError("Invalid verification code.");
        }
    };

    const handleClickVerify = async () => {
        setIsModalLoaded(false);
        updateCodeError("");
        const codeRes: any = await AccountService.getVerifyCode();

        setIsModalLoaded(true);
        if (codeRes?.status === HttpStatus.OK) {
            showSuccessAlert({
                message: "A new Verification Code has been sent!",
            });
            updateIsShow(true);
            setCountdown(SecondsToCountdown);
            return;
        } else {
            showErrorAlert({
                message: codeRes?.data?.description || "Something went wrong.",
            });
        }
    };

    const handleChangeVerifyCode = (e: any) => {
        updateVerifyCode(e.target.value);
    };

    const openConfirmationResendModal = () => {
        setModalShow(true);
        setModalType("resendConfirm");
        setModalContent("Do you want to resend Verification Code?");
    };

    const submitForm = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSave();
        }
    };

    return (
        <ContentWrapper simpleModalData={simpleModalData}>
            <div className="page-content user-profile">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h5 className="page-title">Profile</h5>
                            {!isEdit && (
                                <Button
                                    variant="primary"
                                    className="pr-4 float-right"
                                    onClick={handleClickEdit}
                                >
                                    <span>
                                        <EditIcon className="mr-2 ml-1" />
                                        Edit
                                    </span>
                                </Button>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item href="/user/settings">
                                    Account Settings
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Profile
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Form>
                                <div className="form-box">
                                    <Row>
                                        <Form.Group as={Col} className="mb-4">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                aria-label="first_name"
                                                name="givenName"
                                                value={localUserData.givenName}
                                                onChange={changeWithDebounce}
                                                disabled={!isEdit}
                                                onKeyPress={submitForm}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} className="mb-4">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                aria-label="last_name"
                                                name="familyName"
                                                value={localUserData.familyName}
                                                onChange={changeWithDebounce}
                                                disabled={!isEdit}
                                                onKeyPress={submitForm}
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Form.Group className="mb-4">
                                        <Form.Label>Email </Form.Label>
                                        <InputGroup className="mb-3">
                                            <FormControl
                                                type="text"
                                                aria-label="email"
                                                name="email"
                                                value={localUserData.email}
                                                disabled
                                            />
                                            {localUserData.emailVerified ===
                                                true && (
                                                <InputGroup.Append>
                                                    <InputGroup.Text id="basic-addon2">
                                                        <span className="material-icons green">
                                                            check_circle
                                                        </span>
                                                    </InputGroup.Text>
                                                </InputGroup.Append>
                                            )}
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mb-4">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                hidden
                                                isInvalid={
                                                    !!localUserData.phoneNumber &&
                                                    !isValidPhoneNumber(
                                                        localUserData.phoneNumber
                                                    )
                                                }
                                                ref={phoneNumRef}
                                            />
                                            <StyledPhoneInput
                                                disabled={!isEdit}
                                                international
                                                className="form-control"
                                                defaultCountry="SG"
                                                value={
                                                    localUserData.phoneNumber
                                                }
                                                aria-label="phoneNumber"
                                                name="phoneNumber"
                                                onChange={(e: any) =>
                                                    updateLocalUserData({
                                                        ...localUserData,
                                                        phoneNumber: e,
                                                    })
                                                }
                                                onKeyPress={submitForm}
                                            />
                                            {localUserData.phoneNumberVerified && (
                                                <InputGroup.Append>
                                                    <InputGroup.Text id="basic-addon2">
                                                        <span className="material-icons green">
                                                            check_circle
                                                        </span>
                                                    </InputGroup.Text>
                                                </InputGroup.Append>
                                            )}
                                            {userData.phoneNumber &&
                                                !userData.phoneNumberVerified && (
                                                    <Button
                                                        variant="primary"
                                                        className="ml-2 py-0"
                                                        onClick={
                                                            handleClickVerify
                                                        }
                                                    >
                                                        Verify
                                                    </Button>
                                                )}
                                            <Form.Control.Feedback type="invalid">
                                                {
                                                    PHONE_NUMBER_VALIDATION_MESSAGE
                                                }
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </div>

                                {/* Will enable this if phone_number is set */}
                                {userData.phoneNumber &&
                                    userData.phoneNumberVerified && (
                                        <div className="form-box mt-3">
                                            <CustomSwitch
                                                name="mfa"
                                                id={"mfa"}
                                                checked={isMFAEnable}
                                                onChange={handleMFAChange}
                                                ref={mfaRef}
                                                label={
                                                    <span className="mr-4">
                                                        Multifactor
                                                        Authentication
                                                    </span>
                                                }
                                            />
                                        </div>
                                    )}
                            </Form>
                            {isEdit && (
                                <>
                                    <Button
                                        variant="secondary"
                                        className="mt-4 mr-2"
                                        onClick={handleCancel}
                                    >
                                        CANCEL
                                    </Button>
                                    <Button
                                        variant="primary"
                                        className="mt-4"
                                        onClick={handleSave}
                                    >
                                        SAVE
                                    </Button>
                                </>
                            )}
                        </Col>
                    </Row>
                </Container>

                <Modal
                    show={isShow}
                    onHide={handleCloseModal}
                    keyboard={false}
                    centered
                    size="sm"
                    className="verify-phone-number"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Verification</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="main-message mb-3">
                            Please enter OTP code sent to your mobile number.
                        </p>
                        <Form.Group className="mb-3" controlId="otp">
                            <Form.Control
                                type="text"
                                placeholder="Enter OTP"
                                aria-label="otp"
                                onChange={handleChangeVerifyCode}
                                autoComplete="off"
                            />
                            {codeError && (
                                <p
                                    role="alert"
                                    aria-label="errorMessage"
                                    className="error-message my-0 mt-1"
                                >
                                    {codeError}
                                </p>
                            )}
                        </Form.Group>
                        <div className="mt-4 resend-code">
                            Didn’t get code?
                            {countdown > 0 ? (
                                <span className="pl-1 green">
                                    {renderCountdown(countdown)}
                                </span>
                            ) : (
                                <Button
                                    variant="link"
                                    className="px-1"
                                    onClick={openConfirmationResendModal}
                                >
                                    Resend code
                                </Button>
                            )}
                        </div>
                        <Button
                            variant="primary"
                            onClick={handleSubmitCode}
                            className="mt-4 w-100 py-2"
                        >
                            Verify
                        </Button>
                    </Modal.Body>
                </Modal>
                <Modal
                    centered
                    show={!isModalLoaded}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="example-modal-sizes-title-sm"
                    className="no-header"
                >
                    <Modal.Body className="text-center mt-3 mb-5 mr-4">
                        <Spinner
                            className="centered-spinner"
                            animation="border"
                            variant="primary"
                        />
                    </Modal.Body>
                </Modal>
                <DefaultModal
                    modalType={modalType}
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    resendFunction={handleClickVerify}
                    modalContent={modalContent}
                />
            </div>
        </ContentWrapper>
    );
};

export default UserProfile;
