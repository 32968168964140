import { useAppContext } from "context/appContext";
import { WalletAlertComponent } from "hooks/wallet";
import { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { MemberDto, RecipientMode } from "types";
import SMSRecipientNoVerified from "./SMSRecipientNoVerified";
import { getMemberName } from "utils/organisationFunction";
import SelectRecipientModal from "components/modals/SelectRecipientModal";
import { AlertConfig } from "types/Gateways";
import {
    NOT_FOUND_PHONE_NUMBER_MESSAGE,
    SMS_RECIPIENT_MESSAGE,
} from "constant";

type AlertNoticeConfigProps = {
    config: AlertConfig;
    disabled?: boolean;
    onChange?: (config: AlertConfig) => void;
    initialRecipients: MemberDto[];
};

const Recipient = ({
    givenName,
    familyName,
    recipient,
}: {
    givenName: string;
    familyName: string;
    recipient: string;
}) => (
    <div className="added-item" style={{ position: "relative" }}>
        <p className="mb-0 mr-4 added-item-recipient">{`${givenName} ${familyName}`}</p>{" "}
        <h6 className="ginfo">{recipient}</h6>
    </div>
);

const AlertNoticeConfig = ({
    config: defaultConfig,
    initialRecipients = [],
    onChange,
    disabled,
}: AlertNoticeConfigProps) => {
    const { storeData } = useAppContext();
    const { phoneNumber, phoneNumberVerified, givenName, familyName } =
        storeData.user.userData;
    const { isDefault, owner } = storeData.organization.currentOrgInfo;

    const [config, setConfig] = useState<AlertConfig>(getDefaultAlertConfig());
    const [recipientMode, setRecipientMode] = useState<RecipientMode>("Email");
    const [selectRecipientShow, setSelectRecipientShow] = useState(false);

    useEffect(() => {
        setConfig(defaultConfig);
    }, [defaultConfig]);

    const updateConfig = (config: AlertConfig) => {
        setConfig(config);
        onChange?.(config);
    };

    const toggleAlert = (mode: RecipientMode) => {
        const _config = { ...config };
        _config[mode].isEnabled = !config[mode].isEnabled;
        if (isDefault) {
            _config[mode].recipients = [...initialRecipients];
        }
        updateConfig(_config);
    };

    const setRecipientList = (mode: RecipientMode, recipients: MemberDto[]) => {
        const _config = { ...config };
        _config[mode].recipients = recipients;
        updateConfig(_config);
    };

    const removeRecipientFromList = (
        mode: RecipientMode,
        recipient: string
    ) => {
        const _config = { ...config };
        _config[mode].recipients = config[mode].recipients.filter(
            (r) => r.username !== recipient
        );
        updateConfig(_config);
    };

    const renderRecipient = ({
        recipientList,
        recipientMode,
    }: {
        recipientList: MemberDto[];
        recipientMode: RecipientMode;
    }) => {
        const filtered = recipientList.filter((e: MemberDto) => e);

        if (isDefault) {
            if (["Email", "Notification"].includes(recipientMode)) {
                return (
                    <Recipient
                        givenName={givenName}
                        familyName={familyName}
                        recipient={owner}
                    />
                );
            } else if (recipientMode === "SMS") {
                if (phoneNumberVerified) {
                    return (
                        <Recipient
                            givenName={givenName}
                            familyName={familyName}
                            recipient={phoneNumber ?? ""}
                        />
                    );
                }

                return <SMSRecipientNoVerified />;
            }
        }

        const returnUserinfo = (username: string) => {
            if (isDefault) {
                return recipientMode === "SMS" ? phoneNumber : username;
            } else {
                return username;
            }
        };

        if (filtered) {
            const lst = filtered.map((item) => {
                const { username, uuid, phoneNumberVerified } = item;
                return (
                    <div
                        className="added-item"
                        style={{
                            position: "relative",
                        }}
                        key={uuid}
                    >
                        <p className="mb-0 added-item-recipient">
                            {getMemberName(item)}
                        </p>{" "}
                        <h6 className="ginfo">{returnUserinfo(username)}</h6>
                        <span
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                            }}
                            className="material-icons"
                            onClick={() => {
                                removeRecipientFromList(
                                    recipientMode,
                                    username
                                );
                            }}
                        >
                            close
                        </span>
                        {recipientMode === "SMS" &&
                            phoneNumberVerified === false && (
                                <span className="d-flex align-items-center ml-0">
                                    <i className="material-icons">
                                        error_outline
                                    </i>
                                    <span
                                        style={{
                                            fontSize: "0.8rem",
                                        }}
                                    >
                                        {NOT_FOUND_PHONE_NUMBER_MESSAGE}
                                    </span>
                                </span>
                            )}
                    </div>
                );
            });
            return lst;
        }
    };

    const renderSelectRecipient = (recipientMode: RecipientMode) => {
        return (
            <div key={recipientMode}>
                <Row>
                    <Col sm={4}>
                        <p>
                            {`${
                                recipientMode === "Notification"
                                    ? `Push Notification`
                                    : recipientMode
                            } Recipients`}
                        </p>
                    </Col>
                </Row>
                {!isDefault && (
                    <Row style={{ marginBottom: "20px" }}>
                        <Col sm={4}>
                            <InputGroup>
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        setRecipientMode(recipientMode);
                                        setSelectRecipientShow(true);
                                    }}
                                >
                                    Add +
                                </Button>
                            </InputGroup>
                        </Col>
                    </Row>
                )}
            </div>
        );
    };

    return (
        <>
            <Form.Label className="mt-3 mb-3">Send Alert</Form.Label>

            <WalletAlertComponent showWarningWhen="LowBalance" />

            <div className="recipients email">
                <div className="recipient-option">
                    <Form.Switch
                        name="enable-email"
                        id="enable-email"
                        checked={config.Email.isEnabled}
                        custom
                        label={<span className="ml-4">Send Email</span>}
                        onChange={() => toggleAlert("Email")}
                        disabled={disabled}
                    />
                </div>
                <div
                    className={
                        config.Email.isEnabled
                            ? "recipient-box show"
                            : "recipient-box"
                    }
                >
                    <Row>
                        <Col sm={12}>{renderSelectRecipient("Email")}</Col>
                    </Row>
                    <Row>
                        {renderRecipient({
                            recipientMode: "Email",
                            recipientList: config.Email.recipients,
                        })}
                    </Row>
                </div>
            </div>

            <div className="recipients sms mt-3">
                <div
                    className="recipient-option"
                    onClick={() => toggleAlert("SMS")}
                >
                    <Form.Switch
                        name="enable-sms"
                        id="enable-sms"
                        checked={config.SMS.isEnabled}
                        custom
                        label={<span className="ml-4">Send SMS</span>}
                        onChange={() => toggleAlert("SMS")}
                        disabled={disabled}
                    />
                </div>
                <div
                    className={
                        config.SMS.isEnabled
                            ? "recipient-box show"
                            : "recipient-box"
                    }
                >
                    <Row>
                        <Col sm={12}>{renderSelectRecipient("SMS")}</Col>
                    </Row>
                    <Row>
                        <Col>
                            {renderRecipient({
                                recipientMode: "SMS",
                                recipientList: config.SMS.recipients,
                            })}
                        </Col>
                    </Row>
                    {!isDefault && (
                        <Row
                            style={{
                                color: "#828FAB",
                                fontSize: 12,
                            }}
                        >
                            <Col className="mt-12 recipientFooter">
                                {SMS_RECIPIENT_MESSAGE}
                            </Col>
                        </Row>
                    )}
                </div>
            </div>

            <div className="recipients notification mt-3">
                <div className="recipient-option">
                    <Form.Switch
                        name="enable-notification"
                        id="enable-notification"
                        checked={config.Notification.isEnabled}
                        custom
                        type="switch"
                        label={<span className="ml-4">Send Notification</span>}
                        onChange={() => toggleAlert("Notification")}
                        disabled={disabled}
                    />
                </div>
                <div
                    className={
                        config.Notification.isEnabled
                            ? "recipient-box show"
                            : "recipient-box"
                    }
                >
                    <Row>
                        <Col sm={12}>
                            {renderSelectRecipient("Notification")}
                        </Col>
                    </Row>
                    <Row>
                        {renderRecipient({
                            recipientMode: "Notification",
                            recipientList: config.Notification.recipients,
                        })}
                    </Row>
                </div>
            </div>

            <SelectRecipientModal
                {...{
                    show: selectRecipientShow,
                    recipientMode,
                    emailList: config.Email.recipients,
                    setEmailList: (recipients: MemberDto[]) =>
                        setRecipientList("Email", recipients),
                    notificationList: config.Notification.recipients,
                    setNotificationList: (recipients: MemberDto[]) =>
                        setRecipientList("Notification", recipients),
                    smsList: config.SMS.recipients,
                    setSMSList: (recipients: MemberDto[]) =>
                        setRecipientList("SMS", recipients),
                    onClose: () => setSelectRecipientShow(false),
                    initialMemberList: initialRecipients,
                }}
            />
        </>
    );
};

export const getDefaultAlertConfig = () =>
    ({
        Email: {
            isEnabled: false,
            recipientMode: "Email",
            recipients: [],
        },
        SMS: {
            isEnabled: false,
            recipientMode: "SMS",
            recipients: [],
        },
        Notification: {
            isEnabled: false,
            recipientMode: "Notification",
            recipients: [],
        },
    } as AlertConfig);

export default AlertNoticeConfig;
