import { useState, useEffect, ChangeEvent } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import {
    updateGatewayGroupName,
    setGatewaysToGroup,
    deleteGatewayFromGroup,
    getRegistryGroups,
    getOneGroupDetails,
    addRegistryToGroup,
    getGWReading,
    deleteRegistryGroup,
} from "service/gatewayService";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    InputGroup,
    Button,
    FormControl,
    Form,
    Modal,
} from "react-bootstrap";
import {
    HttpStatus,
    Patterns,
    UNGROUPED_NAME,
    GATEWAY_GROUP_NAME_MESSAGE,
} from "constant";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import { checkActive } from "utils/gatewayFunctions";
import { styled } from "@mui/system";
import GroupsListFirmwareUpdateModal from "components/organizations/GroupsListFirmwareUpdateModal";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { faker } from "@faker-js/faker";
import SelectGatewayModal from "components/modals/SelectGatewayModal";
import { Group, Registry } from "generated/models";
import { isHttpSuccess } from "utils/functions";
import { showSuccessAlert, showErrorAlert } from "utils/alert";
import { ReactComponent as DeleteIcon } from "assets/svg/delete-white.svg";
import DefaultModal from "components/modals/ModalTemplate";
import { get } from "lodash";
import ResourceNameField from "../../components/common/ResourceNameField";

type HeartBeat = {
    heartbeat: number;
    gateway_id: string;
};

const StyledContainer = styled("div")`
    .search {
        display: flex;
        width: auto !important;

        button {
            margin-right: 10px;
        }

        .input-group {
            width: 250px;
        }
    }
`;

const GatewayGroupDetails = () => {
    const params: any = useParams();
    const history = useHistory();
    const [gatewayGroupName, setGatewayGroupName] = useState<string>("");
    const [gatewayList, setGatewayList] = useState<Array<any>>([]);
    const [initialGatewayList, setInitialGatewayList] = useState<Array<any>>(
        []
    );
    const [isNameEditing, setIsNameEditing] = useState<boolean>(false);
    const [editableName, setEditableName] = useState<string>("");
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalType, setModalType] = useState<string>("");
    const [modalContent, setModalContent] = useState<string>("");
    const [selectGatewayShow, setSelectGatewayShow] = useState<boolean>(false);
    const [ungroupedGateways, setUngroupedGateways] = useState<Array<Registry>>(
        []
    );
    const [gatewayToDelete, setGatewayToDelete] = useState<string>("");
    const [contentType, setContentType] = useState<string>("");
    const [selectGatewayGroupShow, setSelectGatewayGroupShow] =
        useState<boolean>(false);
    const [otherGatewayGroup, setOtherGatewayGroup] = useState<Array<Group>>(
        []
    );
    const [initialOtherGatewayGroup, setInitialOtherGatewayGroup] = useState<
        Array<Group>
    >([]);
    const [selectedGatewayGroup, setSelectedGatewayGroup] =
        useState<string>("");
    const [gatewayToTransfer, setGatewayToTransfer] = useState<string>("");
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const [isFirmwareUpdateVisible, setIsFirmwareUpdateVisible] =
        useState<boolean>(false);
    const [isForbiddenResource, setIsForbiddenResource] =
        useState<boolean>(false);
    const [ungrouped, setUngrouped] = useState<Group[]>([]);
    const [deleteGroupModal, setDeleteGroupModal] = useState<boolean>(false);
    const onboardedSelectedGatewayIds = selectedIds.filter((sI) => {
        const gateway = initialGatewayList.find(
            ({ gateway_id }: { gateway_id: string }) => {
                return sI === gateway_id;
            }
        );
        return !!gateway?.info;
    });

    const handleSelectAll = (e: any) => {
        const { checked } = e.target;
        let selectedIds: Array<string> = [];

        if (checked) {
            selectedIds = gatewayList.reduce(
                (results: Array<string>, item: any) => [
                    ...results,
                    item.gateway_id,
                ],
                []
            );
        } else {
            selectedIds = [];
        }

        setSelectedIds(selectedIds);
    };

    const handleSelectDeleteMember = (e: any, id: string) => {
        const { checked } = e.target;
        let deleteIds: Array<string> = [...selectedIds];

        if (checked) {
            deleteIds = [...deleteIds, id];
        } else {
            deleteIds = deleteIds.filter((deleteId: string) => deleteId !== id);
        }

        setSelectedIds(deleteIds);
    };

    const updateOtherGroups = (groupList: Group[], groupDetail: Group) => {
        const otherGroups = groupList.filter(
            (group: Group) =>
                group.name !== UNGROUPED_NAME && group.name !== groupDetail.name
        );

        if (otherGroups.length) {
            setOtherGatewayGroup(otherGroups);
            setInitialOtherGatewayGroup(otherGroups);
        }
    };

    const updateUngrouped = async (
        groupList: Group[],
        heartbeatMap: Map<string, number | string>
    ) => {
        const ungrouped = groupList.filter(
            (group: Group) => group.name === UNGROUPED_NAME
        );

        setUngrouped(ungrouped);

        if (ungrouped.length) {
            const lst = ungrouped[0].gateways.map(
                (gateway: Registry) => gateway.gateway_id
            );
            if (lst.length) {
                const updatedGateways = ungrouped[0].gateways.map(
                    (gateway: Registry) => {
                        return {
                            ...gateway,
                            heartbeat:
                                heartbeatMap.get(gateway.gateway_id) ?? "",
                        };
                    }
                );

                setUngroupedGateways(updatedGateways);
            }
        }
    };

    const updateGroupDetails = async (
        groupDetail: Group,
        heartbeatMap: Map<string, number | string>
    ) => {
        const { name, gateways } = groupDetail;

        const updatedGateways = gateways.map((gateway: Registry) => {
            return {
                ...gateway,
                heartbeat: heartbeatMap.get(gateway.gateway_id) ?? "",
            };
        });

        setGatewayList(updatedGateways);
        setInitialGatewayList(updatedGateways);
        setGatewayGroupName(name);
        setEditableName(name);
    };

    const getHeartBeatMap = async () => {
        const { status: heartBeatsStatus, data: heartBeats } =
            await getGWReading();

        const heartbeatMap = new Map<string, number | string>();
        if (isHttpSuccess(heartBeatsStatus)) {
            heartBeats.forEach(({ heartbeat, gateway_id }: HeartBeat) => {
                heartbeatMap.set(gateway_id, heartbeat);
            });
        }

        return heartbeatMap;
    };

    useEffect(() => {
        (async () => {
            const [
                { status: groupDetailStatus, data: groupDetail },
                { status: groupListStatus, data: groupList },
            ] = await Promise.all([
                getOneGroupDetails(params.groupId),
                getRegistryGroups(),
            ]);

            if (
                groupListStatus === HttpStatus.FORBIDDEN ||
                groupDetailStatus === HttpStatus.FORBIDDEN
            ) {
                setIsForbiddenResource(true);
            }

            if (
                isHttpSuccess(groupListStatus) &&
                isHttpSuccess(groupDetailStatus)
            ) {
                const heartbeatMap = await getHeartBeatMap();
                updateOtherGroups(groupList, groupDetail);
                await updateUngrouped(groupList, heartbeatMap);
                await updateGroupDetails(groupDetail, heartbeatMap);
            }

            setIsPageLoading(false);
        })();
    }, [params.groupId]);

    useEffect(() => {
        (async () => {
            const { status, data } = await getRegistryGroups();

            if (isHttpSuccess(status)) {
                const ungrouped = data.filter(
                    (group: Group) => group.name === UNGROUPED_NAME
                );
                setUngrouped(ungrouped);
            }
        })();
    }, [gatewayList]);

    const handleClickEdit = () => {
        setIsNameEditing(true);
    };

    const handleClickUpdate = async () => {
        const trimmedEditableName = editableName.trim();
        if (trimmedEditableName === gatewayGroupName) {
            setIsNameEditing(false);
            return;
        }

        const updateResponse = await updateGatewayGroupName(
            params.groupId,
            trimmedEditableName
        );

        if (isHttpSuccess(updateResponse.status)) {
            setIsNameEditing(false);
            setGatewayGroupName(trimmedEditableName);
            showSuccessAlert({
                message: "Device Group name updated successfully.",
            });
        } else {
            showErrorAlert({
                message: get(
                    updateResponse,
                    "message",
                    "Unable to update Device Group name. Please try again."
                ),
            });
        }
    };

    const handleSearch = (query: string) => {
        if (query !== "") {
            const filtered = initialGatewayList.filter((gateway) =>
                gateway.name.toLowerCase().includes(query.toLowerCase())
            );
            setGatewayList(filtered);
        } else {
            setGatewayList(initialGatewayList);
        }
    };

    const handleSearchOtherGatewayGroup = (query: string) => {
        if (query !== "") {
            const filtered = initialOtherGatewayGroup.filter((gatewayGroup) =>
                gatewayGroup.name.toLowerCase().includes(query.toLowerCase())
            );
            setOtherGatewayGroup(filtered);
        } else {
            setOtherGatewayGroup(initialOtherGatewayGroup);
        }
    };

    const handleAddGateway = async (listSelectedIds: string[]) => {
        setSelectGatewayShow(false);
        if (!listSelectedIds.length) {
            return;
        }

        const addResponse = await setGatewaysToGroup(
            params.groupId,
            listSelectedIds
        );

        if (isHttpSuccess(addResponse.status)) {
            // remove selected GW from ungrouped GW
            const remainingUngrouped = ungroupedGateways.filter(
                (g: Registry) => {
                    return !listSelectedIds.includes(g.gateway_id);
                }
            );

            setUngroupedGateways(remainingUngrouped);

            // add selected GW to GW list
            const selectedGatewaysDetails = ungroupedGateways.filter(
                (g: Registry) => {
                    return listSelectedIds.includes(g.gateway_id);
                }
            );

            setGatewayList([...gatewayList, ...selectedGatewaysDetails]);
            setInitialGatewayList([...gatewayList, ...selectedGatewaysDetails]);

            showSuccessAlert({
                message: "Devices set to device group successfully.",
            });
        } else {
            showErrorAlert({
                message: get(
                    addResponse,
                    "message",
                    "Unable to add Devices set to Device Group. Please try again."
                ),
            });
        }
    };

    const handleDelete = async () => {
        const deleteResponse = await deleteGatewayFromGroup(
            params.groupId,
            gatewayToDelete
        );

        if (isHttpSuccess(deleteResponse.status)) {
            // Remove the gateway from the list
            const cloned = [...gatewayList].filter(
                (gateway: Registry) => gateway.gateway_id !== gatewayToDelete
            );

            const initialCloned = [...initialGatewayList].filter(
                (gateway: Registry) => gateway.gateway_id !== gatewayToDelete
            );
            const removedRegistryDetails = [...initialGatewayList].filter(
                (gateway: Registry) => gateway.gateway_id === gatewayToDelete
            );

            setInitialGatewayList(initialCloned);
            setGatewayList(cloned);
            // Add the gateway to the "other" ungrouped list
            const addCloned: Registry[] = [
                ...ungroupedGateways,
                removedRegistryDetails[0],
            ];

            setUngroupedGateways(addCloned);
            setSelectedIds((selectedIds: string[]) =>
                selectedIds.filter((item) => item !== gatewayToDelete)
            );

            showSuccessAlert({
                message: "Device removed from device group successfully.",
            });
        } else {
            showErrorAlert({
                message: get(
                    deleteResponse,
                    "message",
                    "Unable to remove Device from Device Group. Please try again."
                ),
            });
        }
    };

    const handleTransfer = async () => {
        if (!selectedGatewayGroup) {
            closeGatewayGroupModal();
            return;
        }

        const deleteResponse = await deleteGatewayFromGroup(
            params.groupId,
            gatewayToTransfer
        );

        if (!isHttpSuccess(deleteResponse.status)) {
            showErrorAlert({
                message: get(
                    deleteResponse,
                    "message",
                    "Unable to transfer Gateway. Please try again."
                ),
            });
            closeGatewayGroupModal();
            return;
        }

        const addResponse = await addRegistryToGroup(selectedGatewayGroup, [
            gatewayToTransfer,
        ]);

        if (!isHttpSuccess(addResponse.status)) {
            showErrorAlert({
                message: get(
                    addResponse,
                    "message",
                    "Unable to transfer Gateway. Please try again."
                ),
            });
            closeGatewayGroupModal();
            return;
        }

        const cloned = [...gatewayList].filter(
            (gateway: Registry) => gateway.gateway_id !== gatewayToTransfer
        );

        const initialCloned = [...initialGatewayList].filter(
            (gateway: Registry) => gateway.gateway_id !== gatewayToTransfer
        );

        const removedRegistryDetails = [...initialGatewayList].filter(
            (gateway: Registry) => gateway.gateway_id === gatewayToTransfer
        );
        setInitialGatewayList(initialCloned);
        setGatewayList(cloned);
        // Add to the otherGatewayGroup gateways
        const addCloned = [...initialOtherGatewayGroup];
        const index = addCloned.findIndex(
            (group: Group) => group.uuid === selectedGatewayGroup
        );

        addCloned[index].gateways.push(removedRegistryDetails[0]);

        setOtherGatewayGroup(addCloned);
        setInitialOtherGatewayGroup(addCloned);

        showSuccessAlert({
            message: "Gateway has been transferred successfully.",
        });
        closeGatewayGroupModal();
    };

    const openGatewayGroupModal = () => {
        setOtherGatewayGroup(initialOtherGatewayGroup);
        setSelectGatewayGroupShow(true);
    };

    const closeGatewayGroupModal = () => {
        setSelectedGatewayGroup("");
        setSelectGatewayGroupShow(false);
    };

    const openConfirmationDeleteModal = (gatewayName: string) => {
        setModalShow(true);
        setModalType("confirmation");
        setContentType("delete");
        setModalContent(
            `Are you sure you want to remove ${gatewayName} from the group?`
        );
    };

    const openConfirmationTransferModal = (gatewayName: string) => {
        setModalShow(true);
        setModalType("confirmation");
        setModalContent(
            `Are you sure you want to transfer ${gatewayName} to another group?`
        );
    };

    const renderGatewayGroup = () => {
        const lst = otherGatewayGroup.map((gatewayGroup) => {
            return (
                <div className="modal-option" key={faker.datatype.uuid()}>
                    <Form.Check
                        name="gatewayGroups"
                        value={gatewayGroup.uuid}
                        checked={selectedGatewayGroup === gatewayGroup.uuid}
                        onChange={(e) => {
                            setSelectedGatewayGroup(e.target.value);
                        }}
                        custom
                        type="radio"
                        id={gatewayGroup.uuid}
                        label={
                            <div>
                                <h4>{gatewayGroup.name}</h4>{" "}
                                <p>{`${gatewayGroup.gateways.length} members`}</p>
                            </div>
                        }
                        className="float-left"
                    />
                </div>
            );
        });
        return lst;
    };

    const handleCloseConfirmModal = () => {
        setModalShow(false);
        setContentType("");
        setModalContent("");
    };

    const handleRemoveGateway = () => {
        handleDelete();
        handleCloseConfirmModal();
    };

    const handleTransferGateway = () => {
        handleCloseConfirmModal();
        openGatewayGroupModal();
    };

    const renderModalButton = () => {
        if (modalType === "confirmation") {
            if (contentType === "delete") {
                return (
                    <>
                        <Button
                            variant="secondary"
                            onClick={handleCloseConfirmModal}
                        >
                            CANCEL
                        </Button>

                        <Button variant="danger" onClick={handleRemoveGateway}>
                            REMOVE
                        </Button>
                    </>
                );
            } else {
                return (
                    <>
                        <Button
                            variant="danger"
                            onClick={handleCloseConfirmModal}
                        >
                            NO
                        </Button>

                        <Button
                            variant="primary"
                            onClick={handleTransferGateway}
                        >
                            YES
                        </Button>
                    </>
                );
            }
        }
    };

    const displayGroupMember = () => {
        if (!initialGatewayList.length) {
            return (
                <div className="mt-2 no-gateways-text">
                    No gateways to display.
                </div>
            );
        }
        if (!gatewayList.length) {
            return <div className="mt-5 text-center">No gateways found.</div>;
        }

        return renderGateways();
    };

    const renderGateways = () => {
        const lst = gatewayList.map((gateway) => {
            return (
                <div className="table-row" key={gateway.gateway_id}>
                    <Row className="checkbox">
                        <Col sm="1">
                            <Form.Check
                                aria-label="selected-gateway-checkbox"
                                className="mr-sm-2"
                                type="checkbox"
                                id={`customControlAutosizing-${gateway.gateway_id}`}
                                custom
                                checked={selectedIds.includes(
                                    gateway.gateway_id
                                )}
                                onChange={(e) =>
                                    handleSelectDeleteMember(
                                        e,
                                        gateway.gateway_id
                                    )
                                }
                            />
                        </Col>
                        <Col md sm="12">
                            <Link
                                to={{
                                    pathname: `/gateway-details/${gateway.gateway_id}`,
                                }}
                            >
                                {gateway.name}
                            </Link>
                        </Col>
                        <Col md sm="12">
                            {gateway.gateway_id}
                        </Col>

                        <Col md sm="12">
                            {checkActive(gateway.heartbeat, !!gateway.info)}
                        </Col>

                        <Col sm="2">
                            <HoverAuthorizeTooltip permission="gateway:update">
                                <Button
                                    variant="primary"
                                    onClick={() => {
                                        openConfirmationTransferModal(
                                            gateway.name
                                        );
                                        setGatewayToTransfer(
                                            gateway.gateway_id
                                        );
                                    }}
                                >
                                    Transfer
                                </Button>
                            </HoverAuthorizeTooltip>
                            <HoverAuthorizeTooltip permission="gateway:update">
                                <Button
                                    variant="danger"
                                    className="ml-2"
                                    onClick={() => {
                                        openConfirmationDeleteModal(
                                            gateway.name
                                        );
                                        setGatewayToDelete(gateway.gateway_id);
                                    }}
                                >
                                    Remove
                                </Button>
                            </HoverAuthorizeTooltip>
                        </Col>
                        <Col sm="1">
                            <Link
                                to={{
                                    pathname: `/gateway-details/${gateway.gateway_id}`,
                                }}
                            >
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </Link>
                        </Col>
                    </Row>
                </div>
            );
        });
        return lst;
    };

    const handleChangeGroupName = (e: ChangeEvent<HTMLInputElement>) => {
        setEditableName(e.target.value);
    };

    const closeDeleteGroupModal = () => {
        setDeleteGroupModal(false);
    };

    const handleDeleteGroup = async () => {
        closeDeleteGroupModal();
        const deleteResponse = await deleteRegistryGroup(params.groupId);

        if (isHttpSuccess(deleteResponse.status)) {
            showSuccessAlert({
                message: "Gateway group has been deleted successfully.",
            });
            history.push("/gateways-group");
        } else {
            showErrorAlert({
                message: get(
                    deleteResponse,
                    "message",
                    "Unable to delete this group. Please try again."
                ),
            });
        }
    };

    return (
        <ContentWrapper
            isLoading={isPageLoading}
            isForbiddenResource={isForbiddenResource}
        >
            <StyledContainer className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={10}>
                            <h5
                                className="page-title"
                                dangerouslySetInnerHTML={{
                                    __html: gatewayGroupName.replace(
                                        / /g,
                                        "&nbsp;"
                                    ),
                                }}
                            ></h5>
                        </Col>
                        <Col
                            sm={2}
                            className="d-flex justify-content-end align-items-center"
                        >
                            <Button
                                variant="danger"
                                className="delete-button d-flex justify-content-center align-items-center"
                                onClick={() => {
                                    setDeleteGroupModal(true);
                                }}
                            >
                                <DeleteIcon className="h-100 mr-2" />
                                <span className="h-100">Delete</span>
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item active>
                                    <Link to="/gateways-group">
                                        Gateway Groups
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Group Details
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ResourceNameField
                                input={editableName}
                                updateField={handleChangeGroupName}
                                isNameEditing={isNameEditing}
                                toggleEditButton={handleClickEdit}
                                toggleUpdateButton={handleClickUpdate}
                                resourceTitle="Group Name"
                                resourcePermission="gateway:update"
                                validationMessage={GATEWAY_GROUP_NAME_MESSAGE}
                                validationRegex={Patterns.orgNamePattern}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col sm={12}>
                            <h5 className="page-title">Group Members</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <HoverAuthorizeTooltip permission="gateway:update">
                                <Button
                                    variant="primary"
                                    className={
                                        selectedIds.length > 0
                                            ? "mb-2 opacity-btn-enable"
                                            : "mb-2 opacity-btn-disable"
                                    }
                                    onClick={() => {
                                        setIsFirmwareUpdateVisible(true);
                                    }}
                                    disabled={!selectedIds.length}
                                >
                                    Firmware Update ({selectedIds.length})
                                </Button>
                            </HoverAuthorizeTooltip>
                            <div className="search float-right">
                                <HoverAuthorizeTooltip permission="gateway:update">
                                    <Button
                                        variant="primary"
                                        className="h-100"
                                        onClick={() => {
                                            setSelectGatewayShow(true);
                                        }}
                                    >
                                        + Add Gateway to Group
                                    </Button>
                                </HoverAuthorizeTooltip>
                                <InputGroup>
                                    <FormControl
                                        aria-label="search-gateway"
                                        type="text"
                                        placeholder="Search.."
                                        aria-describedby="button-addon2"
                                        onChange={(e) => {
                                            handleSearch(e.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </div>
                        </Col>
                    </Row>
                    <Row className="cstm-table">
                        <Col sm={12}>
                            <div className="table-head">
                                <Row>
                                    <Col sm="1">
                                        <Form.Check
                                            className="mr-sm-2"
                                            type="checkbox"
                                            id="select-all-gateway-groups"
                                            custom
                                            checked={
                                                selectedIds.length ===
                                                    gatewayList.length &&
                                                gatewayList.length !== 0
                                            }
                                            onChange={handleSelectAll}
                                        />
                                    </Col>
                                    <Col>Gateway Name</Col>
                                    <Col>UUID</Col>
                                    <Col>Last Active</Col>

                                    <Col sm="2">Action</Col>
                                    <Col sm="1"></Col>
                                </Row>
                            </div>
                            {displayGroupMember()}
                        </Col>
                    </Row>
                    <Modal
                        centered
                        show={modalShow}
                        onHide={handleCloseConfirmModal}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="example-modal-sizes-title-sm"
                        className={"no-header danger"}
                    >
                        <Modal.Body className="text-center mt-3 mb-3">
                            <div className="modal-icon-box">
                                <span className="material-icons">warning</span>
                            </div>
                            <h3 className="mb-3">Confirmation</h3>
                            <p className="mb-4">{modalContent}</p>
                            {renderModalButton()}
                        </Modal.Body>
                    </Modal>

                    <SelectGatewayModal
                        isShow={selectGatewayShow}
                        initGroups={ungrouped}
                        onClose={() => setSelectGatewayShow(false)}
                        handleAddGWsToGroup={handleAddGateway}
                    />

                    <DefaultModal
                        modalShow={deleteGroupModal}
                        setModalShow={closeDeleteGroupModal}
                        modalType="dlt"
                        modalTitle="Delete Gateway Group?"
                        modalContent={`Are you sure you want to delete gateway group ${gatewayGroupName}?`}
                        deleteFunction={handleDeleteGroup}
                    />

                    <Modal
                        show={selectGatewayGroupShow}
                        onHide={closeGatewayGroupModal}
                        animation={false}
                        centered
                    >
                        <Modal.Header>
                            <Modal.Title>Select Gateway Group</Modal.Title>
                            <Button
                                variant=""
                                className="close-button"
                                onClick={closeGatewayGroupModal}
                            >
                                <span className="material-icons">close</span>
                            </Button>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <FormControl
                                    aria-label="search-other-gw-group"
                                    type="text"
                                    placeholder="Search.."
                                    className="mb-4"
                                    onChange={(e) => {
                                        handleSearchOtherGatewayGroup(
                                            e.target.value
                                        );
                                    }}
                                ></FormControl>
                                <div className="scroll-list float-left"></div>
                            </Form>
                            {renderGatewayGroup()}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleTransfer}>
                                DONE
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <GroupsListFirmwareUpdateModal
                        {...{
                            selectedIds,
                            setSelectedIds: setSelectedIds,
                            onboardedSelectedGatewayIds,
                            isVisible: isFirmwareUpdateVisible,
                            setVisible: setIsFirmwareUpdateVisible,
                            groupId: params.groupId,
                        }}
                    />
                </Container>
            </StyledContainer>
        </ContentWrapper>
    );
};

export default GatewayGroupDetails;
