import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Button, Form } from "react-bootstrap";

import DefaultModal from "components/modals/ModalTemplate";
import { renderCountdown } from "utils/functions";

const EnterMFACode = (props: any) => {
    const {
        onSubmit,
        onBack,
        errorMessage,
        handleLogin,
        countdown,
        setCountdown,
    } = props;

    const [code, updateCode] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");

    const handleSubmitCode = () => {
        onSubmit(code);
    };

    const handleCodeChange = (e: any) => {
        updateCode(e.target.value);
    };

    const handleBack = (e: any) => {
        e.preventDefault();
        onBack();
    };

    const handlePressEnter = (e: any) => {
        const isPressEnter = (e.which || e.keyCode) === 13;
        if (isPressEnter && code) onSubmit(code);
    };

    const handleResendCode = () => {
        setModalShow(true);
        setModalType("resendConfirm");
        setModalContent(`Do you want to resend code?`);
    };

    useEffect(() => {
        if (countdown > 0) {
            const timer = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => {
                clearInterval(timer);
            };
        }
    }, [countdown, setCountdown]);

    return (
        <>
            <Col lg="6" sm="12" className="login-form">
                <div className="login-box">
                    <div className="login-table">
                        <div className="login-text">
                            <h4>Confirm with OTP</h4>
                        </div>
                        <div className="mt-5 mb-5">
                            Please enter the OTP sent to your mobile number
                        </div>
                        <div>
                            <Form.Group>
                                <Form.Label>OTP</Form.Label>
                                <Form.Control
                                    type="text"
                                    aria-label="verification-code"
                                    onChange={handleCodeChange}
                                    onKeyPress={handlePressEnter}
                                    required
                                />
                            </Form.Group>

                            <Button
                                variant="primary"
                                onClick={handleSubmitCode}
                                disabled={!code}
                            >
                                SUBMIT
                            </Button>
                            <div className="loginf">
                                {errorMessage && (
                                    <p aria-label="errorMessage">
                                        {errorMessage}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="mt-5">
                            Didn't get code?
                            {countdown > 0 ? (
                                <span className="pl-1 green">
                                    {renderCountdown(countdown)}
                                </span>
                            ) : (
                                <Link
                                    to=""
                                    className="link pl-1"
                                    href={"#"}
                                    onClick={handleResendCode}
                                >
                                    Resend code
                                </Link>
                            )}
                        </div>

                        <div className="mt-5">
                            <Link
                                to="/"
                                className="link"
                                href={"#"}
                                onClick={handleBack}
                            >
                                Back to login
                            </Link>
                        </div>
                    </div>
                </div>
            </Col>
            <DefaultModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                modalType={modalType}
                modalContent={modalContent}
                okAction={() => {
                    setModalShow(false);
                }}
                resendFunction={handleLogin}
            />
        </>
    );
};

export default EnterMFACode;
