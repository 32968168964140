import { useState, useEffect } from "react";
import ReactECharts, { EChartsOption } from "echarts-for-react";
import moment, { Moment } from "moment";
import {
    Dropdown,
    Form,
    FormControl,
    InputGroup,
    Button,
    FormLabel,
    Modal,
} from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";

import { getSampleQuery } from "service/proqueryService";

import { deletePanelV2 } from "service/dashboardService";
import {
    getSensorConfig,
    getAllAlertNotification,
    getRegistryConfig,
} from "service/gatewayService";
import aggregateIcon from "assets/img/aggregateIcon.png";
import { get, set, cloneDeep, startCase } from "lodash";
import {
    LastTimeSelection,
    ForbiddenErrorMessage,
    TimeGapToNowList,
    HttpStatus,
    Operator,
    UnknownSensor,
    DEFAULT_OPTION,
    CHART_DELETE_SUCCESS_MESSAGE,
} from "constant";
import {
    addDecimalPlace,
    inputNumberWithMinMax,
    isHttpSuccess,
    parseColorToHex,
} from "utils/functions";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import { EditDropdownLink } from "components/dashboard/update";
import refreshIcon from "assets/svg/refresh-green.svg";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { DownloadChartModal } from "components/modals/DownloadChartModal";
import ChartBox from "./ChartBox";
import { faker } from "@faker-js/faker";
import { DISABLE_MISSING_DATA } from "utils/config";
import LocalStorageService from "service/localStorageService";
import { BasicSensorChartProps, ChartState, RefreshRate } from "types/Charts";
import { showSuccessAlert } from "utils/alert";
import styledConst from "styles";
import { getMode } from "./ActuatorChart";

const time_range_2_disable_missing_data = Number.MAX_VALUE;

// ! ----------------- type -----------------
const defaultRefreshRate = 5;

const refreshRateArr: RefreshRate[] = [
    { label: "Pause", seconds: 0 },
    { label: "Auto", seconds: defaultRefreshRate },
    { seconds: 5 },
    { seconds: 30 },
    { seconds: 60 },
    { seconds: 300 },
];

const BasicSensorChart = (props: BasicSensorChartProps) => {
    const jsonList = LocalStorageService.getItem("listOfJSON");

    // ! ----------------- props -----------------
    const {
        panel,
        isVisible,
        visibleRef,
        customEChartConfig,
        customEChartData,
        displayName,
        onDelete,
        zoomLevel,
    } = props;

    // ----------------- chart -----------------
    const [chartState, setChartState] = useState<ChartState>({
        option: DEFAULT_OPTION,
        panelQuery: panel.queries[0],
        sensorType: panel.attributes[0].name,
        chartColor: parseColorToHex(panel.attributes[0].color),
        sensorUnit: panel.attributes[0].unit,
        panelToken: panel.token[0] || "",
        hasFetchedConfig: false,
        ldsuId: panel.attributes[0].device_id,
        deviceId: panel.attributes[0].gateway_id,
        sensorConfigRes: undefined,
    });

    // ----------------- alertArea -----------------
    const [alertState, setAlertState] = useState<{
        showAlertArea: string;
        isAlertEnabled: boolean;
        ops: any;
        vars_: string;
    }>({
        showAlertArea: panel.attributes[0].show_alert,
        isAlertEnabled: false,
        ops: [],
        vars_: "",
    });
    // ----------------- time range -----------------
    const [lastTimeRangeSelect, setLastTimeRangeSelect] = useState<{
        startDate: Moment;
        endDate: Moment;
        selectLabel: string;
        timeValue: number;
        lastTimestamp: Moment | null;
        showAggregatedEnable: boolean;
        showAggregated: boolean;
        averageBy: string;
        INTVL: number;
    }>({
        startDate: moment().subtract(5, "minutes"),
        endDate: moment(),
        selectLabel: "Last 5 minutes",
        timeValue: 300,
        lastTimestamp: null,
        showAggregatedEnable: false,
        showAggregated: false,
        averageBy: "",
        INTVL: DISABLE_MISSING_DATA ? time_range_2_disable_missing_data : 5000,
    });
    // ----------------- panel -----------------
    const [panelCardState, setPanelCardState] = useState<any>({
        fullWidth: false,
        errMess: "",
    });
    // ----------------- refresh rate -----------------
    const [refreshRateState, setRefreshRateState] = useState<any>({
        label: refreshRateArr[1].label,
        seconds: refreshRateArr[1].seconds,
        customRateSeconds: refreshRateArr[1].seconds,
    });
    // ----------------- delete modal -----------------
    const [deleteModalState, setDeleteModalState] = useState<any>({
        isVisible: false,
        errMess: "",
    });
    // ----------------- run out of token -----------------
    const [errorState, setErrorState] = useState({
        state: false,
        msg: "",
    });

    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [boxWidth, setBoxWidth] = useState(100);
    // ----------------- http status -----------------
    const { FORBIDDEN, PAYMENT_REQUIRED } = HttpStatus;
    // ! -----------------------------------------
    const getLDSUConfig = async (timeGapObj: any = TimeGapToNowList[0]) => {
        const { deviceId, ldsuId } = chartState;
        const newLastTimeRangeSelect: any = {};
        const { showAggregate, INTVL, averageBy, isReplaceINTVL } = timeGapObj;

        if (!DISABLE_MISSING_DATA) {
            if (isReplaceINTVL && deviceId) {
                const gatewayConfig = await getRegistryConfig(deviceId);
                newLastTimeRangeSelect.INTVL =
                    get(gatewayConfig, `data`, []).find(
                        (i: any) => i.UID === ldsuId
                    )?.INTVL * 1000;
            } else {
                newLastTimeRangeSelect.INTVL = (INTVL || 5) * 1000;
            }
        }

        newLastTimeRangeSelect.showAggregated = showAggregate;
        newLastTimeRangeSelect.showAggregatedEnable = false;
        newLastTimeRangeSelect.averageBy = averageBy;
        return newLastTimeRangeSelect;
    };
    // ! ----------------- functions -----------------
    const handleLastData = async (__: any, i: any) => {
        const { chosenLabel, endDate, startDate } = i;
        const timeValue = moment.duration(endDate.diff(startDate)).asSeconds();
        const timeGapObj = TimeGapToNowList.find(({ min, max }) => {
            if (
                !(min === undefined || (min !== undefined && timeValue >= min))
            ) {
                return false;
            }
            if (
                !(max === undefined || (max !== undefined && timeValue < max))
            ) {
                return false;
            }
            return true;
        });
        const newLastTimeRangeSelect: any = {
            ...lastTimeRangeSelect,
            startDate,
            endDate,
            selectLabel: chosenLabel,
            timeValue: Math.round(timeValue),
        };
        if (timeGapObj) {
            const _newLastTimeRangeSelect = await getLDSUConfig(timeGapObj);
            Object.keys(_newLastTimeRangeSelect).forEach((k) => {
                newLastTimeRangeSelect[k] = _newLastTimeRangeSelect[k];
            });
        }
        if (chosenLabel === "Custom Range") {
            newLastTimeRangeSelect.lastTimestamp = endDate;
        } else {
            newLastTimeRangeSelect.lastTimestamp = null;
        }

        setLastTimeRangeSelect(newLastTimeRangeSelect);
    };

    const sensorChartNoDataStyleOption = (
        sensorType: string,
        sensorUnit: string
    ) => [
        {
            path: "title.subtext",
            value: `{s6|${sensorType}}\n{s2|${`- ${sensorUnit}`}} {s5|    Min/Max} {s3| ${"-"}/${"-"}${
                sensorUnit || ""
            }}\n{s1|${moment().format("DD/MM/YYYY hh:mm:ss")}}`,
        },
        {
            path: "title.subtextStyle.rich",
            value: {
                s1: {
                    color: styledConst.Primary_Blue_4,
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: 14,
                    height: 25,
                },
                s2: {
                    color: chartState.chartColor,
                    fontSize: 22,
                    fontWeight: "bold",
                },
                s3: {
                    color: "white",
                    fontSize: 15,
                    fontFamily: "Roboto",
                    height: 25,
                },

                s5: {
                    color: styledConst.Primary_Blue_4,
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: 15,
                    height: 25,
                },
                s6: {
                    color: styledConst.Primary_Blue_4,
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: 14,
                    height: 25,
                },
            },
        },
    ];

    const actuatorChartNoDataStyleOption = (
        sensorType: string,
        sensorUnit: string,
        sensorConfigRes: any
    ) => [
        {
            path: "title.subtext",
            value: `{s6|${startCase(sensorType)}}\n{s3|Mode: }{s5|${getMode(
                sensorConfigRes
            )}}\n{s2|${"-" + (sensorUnit || "")}}{s1|    ${moment().format(
                "DD/MM/YYYY hh:mm:ss"
            )}}`,
        },
        {
            path: "title.subtextStyle.rich",
            value: {
                s1: {
                    color: styledConst.Primary_Blue_4,
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: 14,
                    height: 25,
                },
                s2: {
                    color: chartState.chartColor,
                    fontSize: 22,
                    fontWeight: "bold",
                },
                s3: {
                    color: styledConst.Primary_Blue_4,
                    fontFamily: "Roboto",
                    fontWeight: 600,
                    fontSize: 14,
                    height: 25,
                },
                s5: {
                    color: styledConst.Primary_Blue_4,
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: 14,
                    height: 25,
                },
                s6: {
                    color: styledConst.Primary_Blue_4,
                    fontFamily: "Roboto",
                    fontWeight: 400,
                    fontSize: 14,
                    height: 25,
                },
            },
        },
    ];

    const getNewOptions = (
        baseOption: EChartsOption,
        newConfigArr: any = []
    ) => {
        const newOption = cloneDeep(baseOption); // immutable

        newOption.title.subtextStyle.rich.s2.color = chartState.chartColor;
        newConfigArr.map(({ path, value }: any) => set(newOption, path, value));

        return newOption;
    };

    const getDisplaySensorName = () => {
        return get(chartState, "sensorConfigRes.data.sensor_name", UnknownSensor);
    };

    const fetchNewData = async () => {
        if (!isVisible) return;
        if (chartState.hasFetchedConfig) {
            const lastTimestamp =
                lastTimeRangeSelect.lastTimestamp?.unix().toString() ||
                moment().unix().toString();
            const sampleRes: any = await getSampleQuery(
                chartState.panelQuery,
                chartState.chartColor,
                lastTimeRangeSelect.timeValue,
                lastTimeRangeSelect.showAggregated,
                chartState.sensorType,
                chartState.panelToken,
                lastTimeRangeSelect.INTVL,
                lastTimestamp
            );

            if (Array.isArray(sampleRes.series)) {
                let {
                    series = [],
                    aggregatedSeries = [],
                    info: { object, said },
                }: any = sampleRes ?? {};

                let accuracy =  1;

                if(object) {
                    const sensor = jsonList.find(
                        ({ OBJ }: { OBJ: string }) => OBJ === object
                    ).SNS[said];
    
                    accuracy = get(sensor, "ACCURACY", sensor?.MODE?.[0]?.ACCURACY);
                }

                const { showAggregatedEnable } = lastTimeRangeSelect;
                const { sensorUnit } = chartState;
                const { showAlertArea, isAlertEnabled, ops, vars_ } =
                    alertState;
                const now = moment().unix() * 1000;

                const sensorName = displayName? displayName(chartState) : getDisplaySensorName();
                series.forEach((element: { name: any; data: any }) => {
                    element.name = sensorName;
                    element.data = element.data.map(
                        ([timestamp, value]: [number, string]) => [
                            timestamp,
                            addDecimalPlace(value, accuracy),
                        ]
                    );
                });

                const liveData: any = addDecimalPlace(
                    get(
                        series,
                        `[${series.length - 1}].data[${
                            get(series, `[${series.length - 1}].data`, [])
                                .length - 1
                        }][1]`,
                        "-"
                    ),
                    accuracy
                );
                const unix_timestamp = get(
                    series,
                    `[${series.length - 1}].data[${
                        get(series, `[${series.length - 1}].data`, []).length -
                        1
                    }][0]`,
                    now
                );
                const liveDate = new Date(unix_timestamp).toLocaleDateString(
                    "en-GB"
                );
                const liveTime = new Date(unix_timestamp).toLocaleTimeString(
                    "en-US",
                    { hour12: false }
                );
                let min: number = Math.min(),
                    max: number = Math.max();

                // show alert area if sensor alert is enabled
                const thresholdLine: any = [];
                const thresholdArea: any = [];
                let position = "";

                if (showAlertArea && isAlertEnabled) {
                    if (ops.length === 1) {
                        // ops.length === 1 means single alert value
                        if (ops[0] === Operator.LESS_THAN) {
                            position = "insideStartTop";
                            thresholdArea.push([
                                {
                                    name: `${vars_[1]}`,
                                    yAxis: `${vars_[1]}`,
                                },
                                {
                                    yAxis: `${Number.MIN_SAFE_INTEGER}`,
                                },
                            ]);
                        } else if (ops[0] === Operator.GREATER_THAN) {
                            position = "insideStartBottom";
                            thresholdArea.push([
                                {
                                    name: `${vars_[1]}`,
                                    yAxis: `${vars_[1]}`,
                                },
                                {
                                    yAxis: `${Number.MAX_SAFE_INTEGER}`,
                                },
                            ]);
                        }
                        thresholdLine.push({
                            yAxis: Number(vars_[1]),
                            label: {
                                show: true,
                                position: position,
                                color: "#E86881",
                                formatter: `{c} ${sensorUnit}`,
                            },
                        });
                    } else {
                        // ops.length !== 1 means dual alert value
                        position = "insideTopLeft";
                        if (ops.includes("and")) {
                            // and - within range
                            thresholdArea.push([
                                {
                                    yAxis: `${vars_[1]}`,
                                },
                                {
                                    yAxis: `${vars_[3]}`,
                                },
                            ]);
                        } else if (ops.includes("or")) {
                            // or - out of range
                            thresholdArea.push(
                                [
                                    {
                                        yAxis: `${vars_[1]}`,
                                    },
                                    {
                                        yAxis: `${Number.MIN_SAFE_INTEGER}`,
                                    },
                                ],
                                [
                                    {
                                        yAxis: `${vars_[3]}`,
                                    },
                                    {
                                        yAxis: `${Number.MAX_SAFE_INTEGER}`,
                                    },
                                ]
                            );
                        }
                        thresholdLine.push(
                            {
                                yAxis: Number(vars_[1]),
                                label: {
                                    show: true,
                                    position: "insideStartBottom",
                                    color: "#E86881",
                                    formatter: `{c} ${sensorUnit}`,
                                },
                            },
                            {
                                yAxis: Number(vars_[3]),
                                label: {
                                    show: true,
                                    position: "insideStartTop",
                                    color: "#E86881",
                                    formatter: `{c} ${sensorUnit}`,
                                },
                            }
                        );
                    }
                    series.push({
                        markLine: {
                            symbol: "none",
                            data: thresholdLine,
                            animationDuration: 0,
                            lineStyle: {
                                color: "#E86881",
                                width: 2,
                                type: "solid",
                            },
                            silent: true,
                        },
                        markArea: {
                            itemStyle: {
                                color: "rgba(255, 173, 177, 0.2)",
                            },

                            label: {
                                show: false,
                            },
                            silent: true,
                            data: thresholdArea,
                        },
                        smooth: true,
                        type: "line",
                        data: [],
                    });
                }

                // showAggregated -> toggle to candlestick (aggregated time 6hr, 12hr etc)
                if (showAggregatedEnable && aggregatedSeries) {
                    const maxLst = cloneDeep(aggregatedSeries[1].data);
                    const minLst = cloneDeep(aggregatedSeries[2].data);
                    min = minLst.sort((a: any, b: any) => a[1] - b[1])[0][1];
                    max = maxLst.sort((a: any, b: any) => b[1] - a[1])[0][1];

                    if (showAlertArea && isAlertEnabled) {
                        series = aggregatedSeries.map((a: any) => {
                            const markLine = {
                                label: {
                                    show: false,
                                },
                                animationDuration: 0,
                                symbol: "none",
                                data: thresholdLine,
                                lineStyle: {
                                    color: "#E86881",
                                    width: 2,
                                    type: "solid",
                                },
                                silent: true,
                            };

                            const markArea = {
                                itemStyle: {
                                    color: "rgba(255, 173, 177, 0.05)",
                                },

                                label: {
                                    show: false,
                                },
                                silent: true,
                                data: thresholdArea,
                            };
                            return { ...a, markLine, markArea };
                        });
                    } else {
                        series = aggregatedSeries;
                    }

                    min = parseFloat(addDecimalPlace(min, accuracy));
                    max = parseFloat(addDecimalPlace(max, accuracy));
                } else {
                    let value;
                    const arrayData = series.reduce(
                        (arr = [], e: any) => [...arr, ...get(e, "data", [])],
                        []
                    );
                    if (arrayData.length > 0) {
                        arrayData.forEach(
                            ([time, vString]: [number, string]) => {
                                value = parseFloat(vString);
                                const displayValue = value;
                                if (value > max && value < min) {
                                    max = displayValue;
                                    min = displayValue;
                                } else if (value > max) {
                                    max = displayValue;
                                } else if (value < min) {
                                    min = displayValue;
                                }
                            }
                        );
                    } else {
                        max = NaN;
                        min = NaN;
                    }

                    min = parseFloat(addDecimalPlace(min, accuracy));
                    max = parseFloat(addDecimalPlace(max, accuracy));
                }

                const _options = [
                    {
                        path: "series",
                        value: series,
                    },
                    {
                        path: "legend.data",
                        value: lastTimeRangeSelect.showAggregatedEnable
                            ? ""
                            : [sensorName],
                    },
                    {
                        path: "legend.selectedMode",
                        value: lastTimeRangeSelect.showAggregatedEnable,
                    },
                    {
                        path: "title.subtext",
                        value: `{s2|${
                            (isNaN(liveData) || liveData === "NaN"
                                ? "-"
                                : liveData) +
                            (sensorUnit === undefined ? "" : sensorUnit)
                        }} {s5|    Min/Max} {s3| ${
                            !isNaN(min) ? addDecimalPlace(min, accuracy) : "-"
                        }/${
                            !isNaN(max) ? addDecimalPlace(max, accuracy) : "-"
                        }${sensorUnit || ""}}\n{s4| }\n{s1|${
                            liveDate + " " + liveTime
                        }}`,
                    },
                    {
                        path: "tooltip",
                        value: lastTimeRangeSelect.showAggregatedEnable
                            ? {
                                  trigger: "axis",

                                  formatter: (params: any) => {
                                      return `
                          ${new Date(params[0].value[0]).toLocaleString(
                              "en-GB"
                          )}<br/>
                          <div class="mt-1 mb-1">
                            <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
                                params[0].color
                            };"></span>
                            ${params[0].seriesName}
                            \u00A0\u00A0\u00A0
                            <b>${params[0].value[1]}</b><br />
                          </div>
                          Min ${params[2]?.value?.[1]} \u00A0\u00A0\u00A0
                          Max ${params[1]?.value?.[1]} <br />
                          `;
                                  },
                              }
                            : {
                                  trigger: "axis",
                                  formatter: (params: any) => {
                                      return `
                        ${new Date(params[0].value[0]).toLocaleString(
                            "en-GB"
                        )}<br/>
                        <div class="mt-1 mb-1">
                          <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
                              params[0].color
                          };"></span>
                          ${params[0].seriesName}
                          \u00A0\u00A0\u00A0
                          <b>${addDecimalPlace(
                              params[0].value[1],
                              accuracy
                          )}</b><br />
                        </div>
                        `;
                                  },
                              },
                    },
                    {
                        path: "xAxis.min",
                        value: lastTimeRangeSelect.lastTimestamp
                            ? Number(
                                  lastTimeRangeSelect.startDate
                                      .clone()
                                      .format("x")
                              )
                            : Number(
                                  moment()
                                      .subtract(
                                          lastTimeRangeSelect.timeValue,
                                          "seconds"
                                      )
                                      .format("x")
                              ),
                    },
                    {
                        path: "xAxis.max",
                        value: lastTimeRangeSelect.lastTimestamp
                            ? Number(
                                  lastTimeRangeSelect.endDate
                                      .clone()
                                      .format("x")
                              )
                            : Number(moment().format("x")),
                    },
                    ...(customEChartData?.({
                        liveData,
                        chartState,
                        liveTime,
                        liveDate,
                        sensorUnit,
                        min,
                        max,
                        accuracy,
                    }) ?? []),
                ];

                if (vars_?.[1]) {
                    const _max = Math.max(
                        max,
                        // single
                        ...(vars_?.[1] ? [Number(vars_[1])] : []),
                        // dual
                        ...(vars_?.[3] ? [Number(vars_[3])] : [])
                    );
                    const _min = Math.min(
                        min,
                        // single
                        ...(vars_?.[1] ? [Number(vars_[1])] : []),
                        // dual
                        ...(vars_?.[3] ? [Number(vars_[3])] : [])
                    );
                    _options.push(
                        {
                            path: "yAxis.max",
                            value:
                                _max !== undefined
                                    ? (_max + (_max - _min) * 0.1).toFixed(2)
                                    : undefined,
                        },
                        {
                            path: "yAxis.min",
                            value:
                                _min !== undefined
                                    ? (_min - (_max - _min) * 0.1).toFixed(2)
                                    : undefined,
                        }
                    );
                } else {
                    _options.push(
                        {
                            path: "yAxis.max",
                            value: max,
                        },
                        {
                            path: "yAxis.min",
                            value: min,
                        }
                    );
                }

                const newOption: any = getNewOptions(
                    chartState.option,
                    _options
                );

                setChartState({
                    ...chartState,
                    option: newOption,
                });
            }
            //!error handler
            if (sampleRes.response?.status === FORBIDDEN) {
                const status = sampleRes.response.headers["x-authpoint-status"];
                const { description } = sampleRes.response?.data || "";

                switch (Number(status)) {
                    case PAYMENT_REQUIRED:
                        setErrorState({
                            state: true,
                            msg: description,
                        });
                        break;
                }
                return;
            }
        }
    };

    const handleDeletePanel = async () => {
        try {
            deletePanelV2(props.selectedDashboardUUID, panel.uuid).then(
                (result: any) => {
                    if (isHttpSuccess(result.status)) {
                        onDelete(props.selectedDashboardUUID);
                        setDeleteModalState({
                            ...deleteModalState,
                            isVisible: false,
                        });
                        showSuccessAlert({
                            message: CHART_DELETE_SUCCESS_MESSAGE,
                        });
                    } else if (result.status === FORBIDDEN) {
                        setDeleteModalState({
                            ...deleteModalState,
                            errMess: ForbiddenErrorMessage,
                        });
                    } else {
                        setDeleteModalState({
                            ...deleteModalState,
                            errMess:
                                "Unable to delete panel. Please try again.",
                        });
                    }
                }
            );
            if (panelCardState.fullWidth) {
                setPanelCardState({
                    ...panelCardState,
                    fullWidth: !panelCardState.fullWidth,
                });
            }
        } catch (err) {
            console.error(err);
        }
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalState({
            ...deleteModalState,
            isVisible: false,
        });
    };

    // ! ----------------- useEffect -----------------

    useEffect(() => {
        const box = visibleRef?.current;
        if (box) {
            setBoxWidth(box.clientWidth - 190);
            const resizeObserver = new ResizeObserver((entries) => {
                setBoxWidth(entries[0]?.target.clientWidth - 190);
            });

            resizeObserver.observe(box);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [visibleRef]);

    useEffect(() => {
        if (refreshRateState.seconds > 0 && !errorState.state) {
            if (chartState.hasFetchedConfig) {
                fetchNewData();
            }

            const timer = setInterval(() => {
                fetchNewData();
            }, refreshRateState.seconds * 1000);
            return () => clearInterval(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isVisible,
        chartState.hasFetchedConfig,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify({ lastTimeRangeSelect, refreshRateState, alertState }),
        errorState.state,
    ]);

    useEffect(() => {
        (async () => {
            if (!isVisible || chartState.hasFetchedConfig) return;
            const { attributes, source } = panel;

            const {
                gateway_id,
                device_id,
                said,
                name: sensorType,
                unit: sensorUnit,
            } = attributes[0];
            const sensorConfigRes = await getSensorConfig(
                gateway_id,
                device_id,
                said
            );

            let newOption = {};

            if (isHttpSuccess(sensorConfigRes.status)) {
                newOption = getNewOptions(chartState.option, [
                    ...(source === "SENSOR"
                        ? sensorChartNoDataStyleOption(sensorType, sensorUnit)
                        : actuatorChartNoDataStyleOption(
                              sensorType,
                              sensorUnit,
                              sensorConfigRes
                          )),
                    {
                        path: "title.subtextStyle.rich.s2.color",
                        value: chartState.chartColor,
                    },
                    {
                        path: "grid.top",
                        value: "135px",
                    },
                    ...(customEChartConfig?.({
                        sensorConfigRes,
                    }) ?? []),
                    {
                        path: "title.text",
                        value: panel.name || sensorConfigRes.data?.sensor_name,
                    },
                ]);

                setErrorState({
                    state: false,
                    msg: "",
                });
            } else {
                newOption = getNewOptions(chartState.option, [
                    {
                        path: "title.text",
                        value: panel.name ?? UnknownSensor,
                    },
                    ...(source === "SENSOR"
                        ? sensorChartNoDataStyleOption(sensorType, sensorUnit)
                        : actuatorChartNoDataStyleOption(
                              sensorType,
                              sensorUnit,
                              sensorConfigRes
                          )),
                    ...(customEChartConfig?.({
                        sensorConfigRes,
                    }) ?? []),
                    {
                        path: "grid.top",
                        value: "135px",
                    },
                ]);
            }

            setChartState({
                ...chartState,
                hasFetchedConfig: true,
                option: newOption,
                sensorConfigRes,
            });
        })();

        return () => {};
        //TODO: Refine dependency when new charts are implemented with new API
        /* eslint-disable */
    }, [chartState, isVisible, errorState.state]);

    useEffect(() => {
        const { gateway_id, device_id, said } = panel.attributes[0];
        const checkIfAlertEnabled = async () => {
            const alertRes: any = await getAllAlertNotification(
                gateway_id,
                device_id,
                said
            );
            if (isHttpSuccess(alertRes.status)) {
                if (alertRes.data.length > 0) {
                    const alert = alertRes.data[0];
                    const { enabled, ops, vars_ } = alert;
                    if (enabled) {
                        setAlertState({
                            ...alertState,
                            isAlertEnabled: true,
                            ops,
                            vars_,
                        });
                    }
                }
            }
        };
        checkIfAlertEnabled();
    }, []);

    useEffect(() => {
        getLDSUConfig().then((_newLastTimeRangeSelect) => {
            setLastTimeRangeSelect({
                ...lastTimeRangeSelect,
                ..._newLastTimeRangeSelect,
            });
        });
    }, []);

    // Delta min max and change split for I/O Chart
    const changeAxisYMaxMin = (
        option: EChartsOption,
        deltaPercent: number = 2
    ) => {
        const { yAxis } = option;

        if (yAxis.max === 1 && yAxis.min === 0) {
            yAxis.splitNumber = 1;
        } else {
            const devi = Number(yAxis.max) - Number(yAxis.min);
            const roundedDecimal =
                String(yAxis.max).split(".")?.[1]?.length ||
                String(yAxis.min).split(".")?.[1]?.length ||
                0;
            // delta rounded with percent and roundedDecimal must always > 0
            const delta: number =
                Math.ceil(devi * deltaPercent * 10 ** (roundedDecimal - 2)) /
                10 ** roundedDecimal;
            yAxis.max =
                Number(yAxis.max) === 0
                    ? 0
                    : Number(Number(yAxis.max) + delta).toFixed(roundedDecimal);
            yAxis.min =
                Number(yAxis.min) === 0
                    ? 0
                    : Number(Number(yAxis.min) - delta).toFixed(roundedDecimal);
        }

        set(option, "title.textStyle.width", boxWidth);
        set(option, "title.subtextStyle.width", boxWidth);

        return option;
    };

    return (
        <>
            <ChartBox
                className={
                    panelCardState.fullWidth
                        ? "widget-box temperature full-width"
                        : "widget-box temperature"
                }
                ref={visibleRef}
                style={{
                    height: panelCardState.fullWidth
                        ? "50vh"
                        : zoomLevel <= 0.5
                        ? "30vh"
                        : 400,
                    width: "100%",
                }}
            >
                <div className="widget">
                    <Dropdown alignRight>
                        <Dropdown.Toggle variant="" className="more-icon">
                            <i className="material-icons">more_vert</i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <HoverAuthorizeTooltip permission="dashboard:update">
                                <EditDropdownLink panelId={panel.uuid} />
                            </HoverAuthorizeTooltip>
                            <HoverAuthorizeTooltip permission="dashboard:delete">
                                <Dropdown.Item
                                    onClick={() =>
                                        setDeleteModalState({
                                            ...deleteModalState,
                                            isVisible: true,
                                        })
                                    }
                                >
                                    Delete
                                </Dropdown.Item>
                            </HoverAuthorizeTooltip>
                            <HoverAuthorizeTooltip permission="dashboard:update">
                                <Dropdown.Item
                                    onClick={() => setShowDownloadModal(true)}
                                >
                                    Download Data
                                </Dropdown.Item>
                            </HoverAuthorizeTooltip>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown alignRight>
                        <DateRangePicker
                            initialSettings={{
                                timePicker: true,
                                startDate: lastTimeRangeSelect.startDate,
                                endDate: lastTimeRangeSelect.endDate,
                                maxDate: moment(),
                                locale: {
                                    format: "DD/MM/YYYY hh:mm A",
                                },

                                ranges: LastTimeSelection.reduce<any>(
                                    (dict, item, i) => {
                                        const { time, timeGapToNow } = item;
                                        dict[`Last ${time}`] = [
                                            moment().subtract(
                                                timeGapToNow,
                                                "seconds"
                                            ),
                                            moment(),
                                        ];
                                        return dict;
                                    },
                                    {}
                                ),
                            }}
                            onApply={handleLastData}
                        >
                            <Dropdown.Toggle variant="" className="last-data">
                                {lastTimeRangeSelect.selectLabel}
                            </Dropdown.Toggle>
                        </DateRangePicker>
                    </Dropdown>
                    <img
                        onClick={() => {
                            setLastTimeRangeSelect({
                                ...lastTimeRangeSelect,
                                showAggregatedEnable:
                                    !lastTimeRangeSelect.showAggregatedEnable,
                            });
                        }}
                        className={
                            lastTimeRangeSelect.showAggregated
                                ? "aggregate-icon d-block"
                                : "aggregate-icon d-none"
                        }
                        src={aggregateIcon}
                        alt="aggregate-icon"
                    />
                    <div className="restricted-chart">
                        {errorState.state ? (
                            <>
                                {errorState.msg}
                                <p>
                                    <img
                                        className={
                                            errorState.msg === ""
                                                ? "btn refresh-animation"
                                                : "btn"
                                        }
                                        src={refreshIcon}
                                        alt="refresh"
                                        onClick={() => {
                                            setErrorState({
                                                state: false,
                                                msg: "",
                                            });
                                        }}
                                    />
                                </p>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="refresh-drop">
                        <span>Refresh Rate:</span>
                        <Dropdown alignRight>
                            <Dropdown.Toggle
                                variant=""
                                className="refresh-rate"
                            >
                                {refreshRateState.label ||
                                    `${refreshRateState.seconds} Sec`}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {refreshRateArr.map(
                                    (refreshRate: RefreshRate) => {
                                        const { seconds, label } = refreshRate;
                                        return (
                                            <Dropdown.Item
                                                key={faker.datatype.uuid()}
                                                onClick={() =>
                                                    setRefreshRateState({
                                                        ...refreshRateState,
                                                        seconds,
                                                        label,
                                                    })
                                                }
                                                value={seconds}
                                            >
                                                {label ?? `${seconds} Sec`}
                                            </Dropdown.Item>
                                        );
                                    }
                                )}
                                <div className="custom-refresh">
                                    <Form>
                                        <FormLabel>
                                            Custom Rate (Sec):{" "}
                                        </FormLabel>
                                        <InputGroup>
                                            <FormControl
                                                type="number"
                                                min="0"
                                                max="3600"
                                                defaultValue={5}
                                                name="customValue"
                                                aria-label="customValue"
                                                onKeyPress={(e: any) => {
                                                    if (
                                                        e.which === 13 ||
                                                        e.keyCode === 13
                                                    ) {
                                                        e.preventDefault();
                                                        setRefreshRateState({
                                                            ...refreshRateState,
                                                            label: refreshRateArr[1]
                                                                .label,
                                                            customRateSeconds:
                                                                refreshRateState.customRateSeconds,
                                                        });
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    inputNumberWithMinMax(e);
                                                    const value = parseInt(
                                                        e.target.value
                                                    );

                                                    if (e.target.value === "") {
                                                        return setRefreshRateState(
                                                            refreshRateArr[1]
                                                        );
                                                    }

                                                    if (value === 0) {
                                                        setRefreshRateState({
                                                            ...refreshRateState,
                                                            ...refreshRateArr[0],
                                                        });
                                                    } else {
                                                        setRefreshRateState({
                                                            ...refreshRateState,
                                                            label: "",
                                                            seconds: parseInt(
                                                                e.target.value
                                                            ),
                                                        });
                                                    }
                                                }}
                                            />
                                            <InputGroup.Append>
                                                <Button
                                                    variant="secondary"
                                                    onClick={() => {
                                                        setPanelCardState({
                                                            ...panelCardState,
                                                            errMess: "",
                                                        });
                                                        setRefreshRateState({
                                                            ...refreshRateState,
                                                            customRateSeconds:
                                                                refreshRateState.customRateSeconds,
                                                        });
                                                    }}
                                                >
                                                    <span className="material-icons done">
                                                        done
                                                    </span>
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                        {panelCardState.errMess ? (
                                            <p className="pt-2 panel-card-error">
                                                {panelCardState.errMess}
                                            </p>
                                        ) : (
                                            ""
                                        )}
                                    </Form>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <ContentWrapper
                        key={JSON.stringify({
                            lastTimeRangeSelect,
                            refreshRateState,
                        })}
                        isLoading={
                            errorState.state
                                ? false
                                : !chartState.hasFetchedConfig
                        }
                    >
                        <ReactECharts
                            className="chart-box"
                            theme={"dark"}
                            option={changeAxisYMaxMin(chartState.option)}
                            notMerge={true}
                            lazyUpdate={true}
                            onChartReady={(chartInstance) => {
                                chartInstance.getZr().on("click", (a: any) => {
                                    if (a?.target?.__title === "Restore") {
                                        fetchNewData();
                                    }
                                });
                            }}
                            //! this way will force echart re-render
                            // onEvents={{
                            //     restore: fetchNewData
                            // }}
                        />
                    </ContentWrapper>
                </div>
            </ChartBox>
            <Modal
                centered
                show={deleteModalState.isVisible}
                onHide={handleCloseDeleteModal}
                backdrop="static"
                keyboard="false"
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header danger"
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">
                        <span className="material-icons">delete</span>
                    </div>
                    <h3 className="mb-3">Confirm Delete</h3>
                    <p className="mb-4">
                        Do you really want to delete this chart? <br />
                        This cannot be undone.
                    </p>

                    <Button
                        variant="secondary"
                        onClick={handleCloseDeleteModal}
                    >
                        CANCEL
                    </Button>
                    <Button variant="danger" onClick={handleDeletePanel}>
                        DELETE
                    </Button>
                    <div className="text-danger" role="alert">
                        {deleteModalState.errMess}
                    </div>
                </Modal.Body>
            </Modal>
            <DownloadChartModal
                show={showDownloadModal}
                panel={panel}
                deviceConfig={
                    chartState.sensorConfigRes?.data ||
                    chartState.sensorConfigRes?.data.data
                }
                onClose={() => setShowDownloadModal(false)}
            ></DownloadChartModal>
        </>
    );
};

export default BasicSensorChart;
