import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import { TimeFormat, TimeInputProps } from "types/TimeInput";
import TimeInputSeparate from "./TimeInputSeparate";
import { padTimeFormat } from "utils/functions";

const getInputFormat = (
    format: TimeFormat = "mm:ss"
): {
    inputFormat: TimeFormat;
    mask: "__:__" | "__:__:__";
    views: ("hours" | "minutes" | "seconds")[];
} => ({
    inputFormat: format,
    mask: format === "mm:ss" ? "__:__" : "__:__:__",
    views:
        format === "mm:ss"
            ? ["minutes", "seconds"]
            : ["hours", "minutes", "seconds"],
});

const toTimeValue = (value: number) => {
    const momentObj = Number(value || 0).toMoment();
    return {
        hours: padTimeFormat(String(momentObj.hours())),
        minutes: padTimeFormat(String(momentObj.minutes())),
        seconds: padTimeFormat(String(momentObj.seconds())),
    };
};

const TimeInput: React.FunctionComponent<TimeInputProps> = (props) => {
    const { format, value, formatType, onChange, ...rest } = props;
    const { inputFormat, mask, views } = getInputFormat(format);

    const [timeValue, setTimeValue] = useState<{
        hours: string;
        minutes: string;
        seconds: string;
    }>(toTimeValue(value));

    useEffect(() => {
        setTimeValue(toTimeValue(value));
    }, [value]);

    const fieldRef = React.useRef<HTMLInputElement>(null);

    if (formatType === "split") {
        return <TimeInputSeparate {...{ ...props, timeValue, setTimeValue }} />;
    }

    return format === "seconds" ? (
        <TextField
            label=""
            type="number"
            defaultValue={value}
            InputLabelProps={{
                shrink: false,
            }}
            onChange={(e) => onChange?.(Number(e.target.value), e)}
            {...rest}
        />
    ) : (
        <LocalizationProvider dateAdapter={DateAdapter}>
            <TimePicker
                ref={fieldRef}
                label=""
                ampm={false}
                ampmInClock={false}
                inputFormat={inputFormat}
                views={views}
                mask={mask}
                value={timeValue}
                onChange={(newValue: any) => {
                    if (newValue?._isValid) {
                        setTimeValue(newValue);
                        onChange?.(
                            newValue.toSeconds(),
                            new Event("change", { bubbles: true })
                        );
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label=""
                        InputLabelProps={{
                            shrink: false,
                        }}
                    />
                )}
            />
        </LocalizationProvider>
    );
};

export default TimeInput;
