class LocalStorageService {
    protected localStorage = window.localStorage;

    /**
     * Get storage item by key
     * @param {string} - Key name
     */
    getItem = (key: string): any => {
        if (!key) return null;
        const result = this.localStorage.getItem(key) || "";

        try {
            return JSON.parse(result);
        } catch (e) {
            return result;
        }
    };

    /**
     * Add/update item
     * @param {string} - Key name
     * @param {any} - Value
     */
    setItem(key: string, value: any): void {
        this.localStorage.setItem(key, JSON.stringify(value));
        window.dispatchEvent(new Event("storage"));
    }

    /**
     * Remove storage item by key
     * @param {string} - Key name
     */
    removeItem(key: string): void {
        this.localStorage.removeItem(key);
        window.dispatchEvent(new Event("storage"));
    }

    /**
     * Clear all storage
     * @param
     */
    clearAll(): void {
        this.localStorage.clear();
        window.dispatchEvent(new Event("storage"));
    }
}

const localStorageService = new LocalStorageService();

export default localStorageService;
