import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, InputGroup, FormControl, Button } from "react-bootstrap";
import refreshIcon from "assets/svg/refresh-green.svg";
import moment from "moment";
import {
    updateActuatorCommand,
    getSensorLastReading,
    getAllLastBusReading,
    toggleActuatorActivation,
} from "service/gatewayService";
import {
    LEVEL_MODE,
    LOOP_MODE,
    PULSE_MODE,
    PWM_MODE,
    ANALOG_MODE,
    CalendarFormat,
    ACTION_FAIL_MESSAGE,
    ACTUATOR_CONFIG_ACTIVATION_SUCCESS_MESSAGE,
    ACTUATOR_CONFIG_SUCCESS_MESSAGE,
} from "constant";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import useCollectSort from "hooks/useCollectSort";
import DefaultModal from "components/modals/ModalTemplate";
import { getPrimaryUnit, isHttpSuccess } from "utils/functions";
import { faker } from "@faker-js/faker";
import { capitalize, get } from "lodash";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";

export type ActuatorListProps = {
    gatewayName?: string;
    actuators: any[];
};
const ActuatorList = ({ actuators, gatewayName }: ActuatorListProps) => {
    const params: any = useParams();
    const [modalShow, setModalShow] = useState(false);
    const [actuatorToOverride, setActuatorToOverride]: any = useState({});

    const ACTIVATE = "activated";
    const DEACTIVATE = "deactivated";

    const [actuatorList, setActuatorList] = useState<Array<any>>([]);
    const [initialActuatorList, setInitialActuatorList] = useState<Array<any>>(
        []
    );

    const [isRefreshing, setIsRefreshing] = useState(false);
    const { data: actuatorCollect, sortIcon } = useCollectSort(
        actuatorList,
        (item) => ({
            ...item,
            _status: get(item, `status[${item.said}]`),
        })
    );

    useEffect(() => {
        setActuatorList(actuators);
        setInitialActuatorList(actuators);
    }, [actuators]);

    const formatDateTime = (date: number, isLowerCase = false) => {
        const timeString = moment(date, "X").calendar(CalendarFormat);

        if (isLowerCase) {
            return timeString[0].toLowerCase() + timeString.slice(1);
        }

        return timeString;
    };

    const renderActuators = () => {
        const renderControl = (a: any) => {
            return a.info.auto ? "Auto" : "Manual";
        };

        const renderMode = (a: any) => {
            switch (a.info.mode) {
                case LOOP_MODE:
                    return capitalize(LOOP_MODE);
                case LEVEL_MODE:
                    if (a.APP && ["8192"].includes(a.APP[a.said].APPID)) {
                        return "-";
                    }
                    return capitalize(LEVEL_MODE);
                case PULSE_MODE:
                    return capitalize(PULSE_MODE);
                case PWM_MODE:
                    return PWM_MODE;
                case ANALOG_MODE:
                    return "-";
            }
        };

        const renderStatus = (a: any) => {
            const { timestamp, status, said, APP } = a;

            const renderHighLow = () => {
                switch (status[said]) {
                    case 1:
                        return (
                            <>
                                <i className="material-icons circle online">
                                    circle
                                </i>
                                <span className="reading">High</span>
                            </>
                        );
                    case 0:
                        return (
                            <>
                                <i className="material-icons circle offline">
                                    circle
                                </i>
                                <span className="reading">Low</span>
                            </>
                        );
                    default:
                        if (APP) {
                            return (
                                <span className="reading">
                                    {" "}
                                    {status[said]} {getPrimaryUnit(APP[said])}
                                </span>
                            );
                        }
                }
            };

            if (timestamp) {
                return (
                    <>
                        {renderHighLow()}
                        {` ${formatDateTime(timestamp, true)}`}
                    </>
                );
            } else {
                return <>Offline</>;
            }
        };

        const renderLastActivated = (a: any) => {
            if (a.info.activate_ts) {
                return (
                    <Col
                        md={2}
                        xs={{ span: 9, offset: 0, order: 7 }}
                        className="d-flex align-items-center"
                    >
                        {formatDateTime(a.info.activate_ts)}
                    </Col>
                );
            }
            return (
                <Col md xs={{ span: 9, offset: 0, order: 7 }}>
                    -
                </Col>
            );
        };

        const showOverrideConfirmation = (actuator: any) => {
            setModalShow(true);
            setActuatorToOverride(actuator);
        };

        const renderOverrideBtn = (a: any) => {
            if (a.info.auto) {
                return (
                    <Col
                        className="mb-sm-2 mb-md-0 d-flex"
                        md={{ span: 1 }}
                        xs={{ span: 9, offset: 0, order: 8 }}
                    >
                        <HoverAuthorizeTooltip permission="gateway:update">
                            <Button
                                className="override h-100"
                                onClick={() => {
                                    showOverrideConfirmation(a);
                                }}
                            >
                                Override
                            </Button>
                        </HoverAuthorizeTooltip>
                    </Col>
                );
            } else {
                return <Col xs={{ span: 1, offset: 0, order: 7 }}></Col>;
            }
        };

        const renderActivateBtn = (a: any) => {
            return (
                <Col
                    className="d-flex p-1 align-items-center"
                    md={{ span: 2 }}
                    xs={{ span: 12, order: "last" }}
                >
                    <HoverAuthorizeTooltip
                        permission="gateway:update"
                        className="pr-2"
                    >
                        <Button
                            variant="primary"
                            disabled={a.info.auto}
                            onClick={() => {
                                toggleActivation(a, ACTIVATE);
                            }}
                        >
                            Activate
                        </Button>
                    </HoverAuthorizeTooltip>
                    <HoverAuthorizeTooltip
                        permission="gateway:update"
                        className="pr-2"
                    >
                        <Button
                            variant="danger"
                            disabled={a.info.auto}
                            onClick={() => {
                                toggleActivation(a, DEACTIVATE);
                            }}
                        >
                            Deactivate
                        </Button>
                    </HoverAuthorizeTooltip>
                    <HoverAuthorizeTooltip
                        permission="gateway:update"
                        className="pr-2"
                    >
                        <Button
                            className="btn btn-abort"
                            hidden={
                                a.CLS === "32769" || a.info.mode === LEVEL_MODE
                            }
                            disabled={a.info.auto}
                            onClick={() => {
                                abort(a);
                            }}
                        >
                            Abort
                        </Button>
                    </HoverAuthorizeTooltip>
                </Col>
            );
        };

        return actuatorCollect.map((a: any, index: number) => {
            const { type, name } = a;

            return (
                <div className="table-row" key={faker.datatype.uuid()}>
                    <Row className="no-checkbox">
                        <Col
                            md="1"
                            sm="1"
                            xs={{ span: 11, order: 2 }}
                            className="d-flex align-items-center"
                        >
                            <Link
                                to={{
                                    pathname: `/actuator-details/${params.gatewayId}/${params.busNumber}/${a.UID}/${a.said}`,
                                    state: {
                                        type: type,
                                        gatewayName: gatewayName,
                                        name: name,
                                    },
                                }}
                            >
                                {a.name}
                            </Link>
                        </Col>
                        <Col
                            md={{ order: 3, offset: 0, span: 1 }}
                            sm={1}
                            xs={{ span: 11, offset: 0, order: 4 }}
                            className="d-flex align-items-center"
                        >
                            {a.type}
                        </Col>
                        <Col
                            md={{ order: 3, offset: 0, span: 1 }}
                            sm={1}
                            xs={{ span: 11, offset: 0, order: 4 }}
                            className="d-flex align-items-center"
                        >
                            {renderControl(a)}
                        </Col>
                        <Col
                            md={{ order: 3, offset: 0, span: 1 }}
                            sm={1}
                            xs={{ span: 11, offset: 0, order: 4 }}
                            className="d-flex align-items-center"
                        >
                            {renderMode(a)}
                        </Col>
                        <Col
                            md={2}
                            xs={{ span: 9, offset: 0, order: 7 }}
                            className="mt-1"
                        >
                            {renderStatus(a)}
                            <img
                                className="mr-2 refresh-actuator"
                                src={refreshIcon}
                                alt="refresh"
                                onClick={(e) => {
                                    e.currentTarget.classList.add(
                                        "refresh-state"
                                    );
                                    refreshOneStatus(a, index);
                                }}
                            ></img>
                        </Col>
                        {renderLastActivated(a)}

                        {renderOverrideBtn(a)}

                        {renderActivateBtn(a)}

                        <Col
                            md={{ span: 1, order: "last" }}
                            xs={{ span: 1, order: 3 }}
                            className="d-flex align-items-center justify-content-end"
                        >
                            <Link
                                to={{
                                    pathname: `/actuator-details/${params.gatewayId}/${params.busNumber}/${a.UID}/${a.said}`,
                                    state: {
                                        type: type,
                                        gatewayName: gatewayName,
                                        name: name,
                                    },
                                }}
                            >
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </Link>
                        </Col>
                    </Row>
                </div>
            );
        });
    };

    const abort = async (actuator: any) => {
        const { said, UID: ldsuId } = actuator;

        const abortRes = await toggleActuatorActivation({
            gatewayId: params.gatewayId,
            deviceId: ldsuId,
            said,
            activate: false,
            immediately: true,
        });

        if (isHttpSuccess(abortRes.status)) {
            const updatedList = actuatorList.map((a: any) => {
                if (a.UID === ldsuId && a.said === said) {
                    return { ...a, info: abortRes.data };
                }
                return a;
            });

            setActuatorList(updatedList);
            showSuccessAlert({
                message: ACTUATOR_CONFIG_ACTIVATION_SUCCESS_MESSAGE.fill({
                    state: "aborted",
                }),
            });
        } else {
            showErrorAlert(getAPIError(abortRes, ACTION_FAIL_MESSAGE));
        }
    };

    const toggleActivation = async (
        actuator: any,
        activation_state: string
    ) => {
        const { said, UID: ldsuId } = actuator;

        const res = await toggleActuatorActivation({
            gatewayId: params.gatewayId,
            deviceId: ldsuId,
            said,
            activate: activation_state === ACTIVATE,
        });

        if (isHttpSuccess(res.status)) {
            if (activation_state === ACTIVATE) {
                const { activate_ts } = res.data;
                const updatedList = actuatorList.map((a: any) => {
                    if (a.UID === ldsuId && a.said === said) {
                        const info = {
                            ...a.info,
                            activate_ts,
                        };

                        return { ...a, info };
                    }
                    return a;
                });

                setActuatorList(updatedList);
            }
            showSuccessAlert({
                message: ACTUATOR_CONFIG_ACTIVATION_SUCCESS_MESSAGE.fill({
                    state: activation_state,
                }),
            });
        } else {
            showErrorAlert(getAPIError(res, ACTION_FAIL_MESSAGE));
        }
    };

    const refreshOneStatus = async (a: any, i: number) => {
        const { UID, said } = a;

        const refreshOneStatusRes: any = await getSensorLastReading(
            params.gatewayId,
            UID
        );

        if (isHttpSuccess(refreshOneStatusRes.status)) {
            document
                .querySelectorAll(".refresh-actuator")
                [i].classList.remove("refresh-state");
            const { value, ts } = refreshOneStatusRes.data;

            const updatedStatusActuatorList = actuatorList.map((s: any) => {
                if (s.UID === UID && s.said === said) {
                    return { ...s, status: value, timestamp: ts };
                }
                return s;
            });

            setActuatorList(updatedStatusActuatorList);
        }
    };

    const refreshAllStatus = async () => {
        setIsRefreshing(true);
        const refreshAllStatusRes = await getAllLastBusReading(
            params.gatewayId,
            params.busNumber
        );
        if (isHttpSuccess(refreshAllStatusRes.status)) {
            const {
                data: { devices: allStatus },
            } = refreshAllStatusRes;
            // eslint-disable-next-line array-callback-return
            const updatedAllStatusActuatorList = actuatorList.map((s: any) => {
                for (const t of allStatus) {
                    if (s.UID === t.UID) {
                        return { ...s, status: t.value, timestamp: t.ts };
                    }
                }
            });

            setActuatorList(updatedAllStatusActuatorList);
            setIsRefreshing(false);
        }
        setIsRefreshing(false);
    };

    const override = async () => {
        const { UID, said, info, name } = actuatorToOverride;

        const body = {
            ...info,
            name,
            auto: false,
        };

        delete body.activate_ts;
        delete body.modify_ts;

        const overrideRes: any = await updateActuatorCommand(
            params.gatewayId,
            UID,
            said,
            body
        );

        if (isHttpSuccess(overrideRes.status)) {
            const updatedList = actuatorList.map((a: any) => {
                if (a.UID === UID && a.said === said) {
                    const info = { ...a.info, ...body };

                    return { ...a, info };
                }
                return a;
            });

            setActuatorList(updatedList);
            showSuccessAlert({
                message: ACTUATOR_CONFIG_SUCCESS_MESSAGE,
            });
        } else {
            showErrorAlert(getAPIError(overrideRes, ACTION_FAIL_MESSAGE));
        }

        setModalShow(false);
    };

    const searchActuator = (query: string) => {
        if (query !== "") {
            const filtered: any = [];
            initialActuatorList.forEach((i: any) => {
                if (i.name.toLowerCase().includes(query.toLowerCase())) {
                    filtered.push(i);
                }
            });
            setActuatorList(filtered);
        } else {
            setActuatorList(initialActuatorList);
        }
    };

    return (
        <>
            <Row className="mt-4">
                <Col className="table-head-options">
                    <div className="search float-right">
                        <InputGroup>
                            <FormControl
                                type="text"
                                placeholder="Search.."
                                aria-describedby="button-addon2"
                                onChange={(e: any) => {
                                    searchActuator(e.target.value);
                                }}
                            />
                        </InputGroup>
                    </div>
                </Col>
            </Row>
            <Row className="cstm-table mt-4">
                <Col sm={12}>
                    <div className="table-head">
                        <Row className="no-checkbox">
                            <Col sm={1} md={1}>
                                Actuator Name {sortIcon("name")}
                            </Col>
                            <Col sm={1} md={1}>
                                Type {sortIcon("sensor_type")}
                            </Col>
                            <Col sm={1} md={1}>
                                Control {sortIcon("info.action_mode")}
                            </Col>
                            <Col sm={1} md={1}>
                                Mode {sortIcon("info.config_mode")}
                            </Col>
                            <Col sm={2} md={2}>
                                Status
                                <img
                                    className={
                                        isRefreshing
                                            ? "mr-2 bigRefresh refresh-animation"
                                            : "mr-2 bigRefresh"
                                    }
                                    src={refreshIcon}
                                    alt="refresh"
                                    onClick={refreshAllStatus}
                                ></img>
                                {sortIcon("_status")}
                            </Col>
                            <Col md={2}>
                                Last Activated {sortIcon("timestamp")}
                            </Col>
                            <Col sm={1} md={1}></Col>

                            <Col sm={1} md={2}>
                                Action
                            </Col>
                        </Row>
                    </div>

                    {actuatorList.length > 0 ? (
                        renderActuators()
                    ) : (
                        <div className="mt-5 text-center">
                            No Actuators to display
                        </div>
                    )}
                </Col>
            </Row>
            <DefaultModal
                modalShow={modalShow}
                setModalShow={() => setModalShow(false)}
                modalType="cancelConfirm"
                modalIcon="help_outline"
                modalTitle="Confirmation"
                modalContent={
                    <>
                        Override will change AUTO to MANUAL<br></br>and
                        Deactivate the Actuator. Do you want to Override?
                    </>
                }
                resendFunction={override}
            />
        </>
    );
};

export default ActuatorList;
