import AddOnInformation from "./AddOnInformation";
import BraintreeInformation from "./BraintreeInformation";
import CurrentPlanInformation from "./CurrentPlanInformation";

const ManagePaymentInfoTab = () => {
    return (
        <>
            <CurrentPlanInformation />
            <AddOnInformation />
            <BraintreeInformation />
        </>
    );
};

export default ManagePaymentInfoTab;
