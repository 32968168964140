import { useState, useEffect, KeyboardEvent } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    Row,
    Col,
    InputGroup,
    FormControl,
    Button,
    Form,
    Modal,
    Container,
} from "react-bootstrap";

import moment from "moment";
import { get, orderBy } from "lodash";

import dltImg from "assets/svg/delete.svg";
import ActionEmail from "assets/svg/event-action-email.svg";
import ActionSMS from "assets/svg/event-action-sms.svg";
import ActionNotification from "assets/svg/event-action-notification.svg";
import ActionActuator from "assets/svg/event-action-actuator.svg";

import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import DefaultModal from "components/modals/ModalTemplate";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import Pagination from "components/shared/Pagination";
import {
    EventNameMaxCharacter,
    FAILED_ACTION_MESSAGE,
    FETCH_GATEWAY_FAIL_MESSAGE,
    HttpStatus,
    Patterns,
} from "constant/index";

import { getAllRegistry } from "service/gatewayService";
import EventService, { EventBy } from "service/eventService";

import { isValid, showWarning } from "utils/eventFunctions";
import { WalletAlertComponent, walletModalTrigger } from "hooks/wallet";
import { canAccess } from "utils/authorize-action";

import useCollectSort from "hooks/useCollectSort";

import "assets/css/event.css";
import { useAppContext } from "context/appContext";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { isHttpSuccess } from "utils/functions";

const { FORBIDDEN } = HttpStatus;

const EventList = () => {
    const history = useHistory();
    const {
        storeData: {
            organization: { currentOrgId },
        },
    } = useAppContext();
    const [smShow, setSmShow] = useState(false);
    const handleClose = () => setSmShow(false);

    const [eventName, setEventName] = useState("");
    const [eventList, setEventList]: any = useState([]);
    const [initialEventList, setInitialEventList] = useState([]);
    const [toDelete, setToDelete] = useState("");

    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");

    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    const [query, setQuery] = useState("");
    const { sortBy, sortIcon } = useCollectSort();

    const [pagination, setPagination] = useState({
        page: 0,
        rowsPerPage: 10,
        count: 0,
        loading: true,
    });
    const [allGateways, setAllGateways] = useState([]);

    const fetchEventList = async () => {
        const eventList: any = await EventService.readEvent({
            offset: query ? 0 : pagination.page * pagination.rowsPerPage,
            limit: pagination.rowsPerPage,
            by: (sortBy.key || "create_time") as EventBy,
            order: sortBy.direction,
            ...(query && { filter: "name", name: query.toLowerCase() }),
        });

        if (isHttpSuccess(eventList.status)) {
            setEventList(eventList.data);
            setInitialEventList(eventList.data);
        } else if (eventList.status === FORBIDDEN) {
            setIsForbiddenResource(true);
        }

        setPagination({
            ...pagination,
            page: query ? 0 : pagination.page,
            count:
                typeof eventList?.total === "number"
                    ? Number(eventList.total)
                    : 0,
            loading: false,
        });
        setIsPageLoading(false);
    };

    useEffect(() => {
        if (!canAccess("event:read")) {
            setIsPageLoading(false);
            setIsForbiddenResource(true);
        } else {
            fetchEventList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        window.authorizationConfig,
        pagination.page,
        pagination.rowsPerPage,
        sortBy,
        currentOrgId,
    ]);

    useEffect(() => {
        (async () => {
            const allGatewaysRes: any = await getAllRegistry();
            if (isHttpSuccess(allGatewaysRes.status)) {
                const { data: gateways = [] } = allGatewaysRes;
                setAllGateways(gateways);
            } else {
                showErrorAlert(
                    getAPIError(allGatewaysRes, FETCH_GATEWAY_FAIL_MESSAGE)
                );
            }
        })();
    }, []);

    const updateField = (e: any) => {
        setEventName(e.target.value);
    };

    const createEvent = async () => {
        const eventNameTrim = String(eventName).trim();
        setEventName(eventNameTrim);

        if (!Patterns.eventNamePattern.test(eventNameTrim)) {
            showErrorAlert({
                title: `Invalid Name`,
                message: `Please enter a valid name without special characters, and ensure it is no longer than ${EventNameMaxCharacter} characters.`,
            });
            return;
        }

        const response: any = await EventService.createEvent(eventNameTrim);
        if (isHttpSuccess(response.status)) {
            showSuccessAlert({
                message:
                    response.message ||
                    `New Event has been created successfully.`,
            });
            history.push(`/event-details/${response.data.uuid}`);
        } else {
            showErrorAlert({
                message: response.message ?? FAILED_ACTION_MESSAGE,
            });
        }
    };

    const deleteEvent = async (eventId: string) => {
        const response = await EventService.deleteEvent(eventId);
        if (isHttpSuccess(response.status)) {
            fetchEventList();
            showSuccessAlert({
                message: `Event has been deleted successfully.`,
            });
            setModalShow(false);
        } else {
            showErrorAlert({
                message: response.message ?? FAILED_ACTION_MESSAGE,
            });
        }
    };

    const toggleEventStatus = async (event: any) => {
        // check the device of condition is valid
        if (!isValid(event, allGateways)) {
            return showWarning();
        }

        const { name, uuid: event_id, activate: active } = event;
        const response = await EventService.updateEvent(event_id, {
            name: name,
            activate: !active,
        });
        if (isHttpSuccess(response.status)) {
            const updatedEventList = eventList.map((e: any) => {
                if (event_id === e.uuid) {
                    e.activate = !e.activate;
                }
                return e;
            });
            setEventList(updatedEventList);

            showSuccessAlert({
                message: `Event has been ${
                    active ? "disabled" : "enabled"
                } successfully.`,
            });
        } else {
            showErrorAlert({
                title: get(response, "data.title", "Error"),
                message: get(
                    response,
                    "data.description",
                    FAILED_ACTION_MESSAGE
                ),
            });
        }
    };

    const showDeleteModal = () => {
        setModalShow(true);
        setModalType("dlt");
        setModalContent(`Do you want to delete this event?`);
    };

    const renderEventList = () => {
        if (initialEventList.length > 0) {
            if (eventList.length) {
                return renderEvents();
            }
            return <div className="mt-5 text-center">No event found</div>;
        } else {
            return (
                <div className="mt-5 text-center">No Events to display.</div>
            );
        }
    };

    const renderEvents = () => {
        const sortedEventListByDate = orderBy(
            eventList,
            (e: any) => moment(e.created_at),
            "asc"
        );

        let events = sortedEventListByDate.map((event: any, index: number) => {
            const renderActionIcon = () => {
                let actions: any = [];
                for (let action of event.actions) {
                    const { type_ } = action;
                    if (type_ === "EMAIL" && !actions.includes("EMAIL")) {
                        actions.push("EMAIL");
                    } else if (type_ === "SMS" && !actions.includes("SMS")) {
                        actions.push("SMS");
                    } else if (type_ === "PUSH" && !actions.includes("PUSH")) {
                        actions.push("PUSH");
                    } else if (
                        type_ === "ACTUATOR" &&
                        !actions.includes("ACTUATOR")
                    ) {
                        actions.push("ACTUATOR");
                    }
                }

                if (!actions.length) {
                    return (
                        <div className="text-secondary-red-1">
                            No Actions added
                        </div>
                    );
                }
                // eslint-disable-next-line array-callback-return
                return actions.map((action: any) => {
                    if (action === "EMAIL")
                        return (
                            <img
                                src={ActionEmail}
                                alt="action-email"
                                key={action}
                            />
                        );
                    else if (action === "SMS")
                        return (
                            <img
                                src={ActionSMS}
                                alt="action-sms"
                                key={action}
                            />
                        );
                    else if (action === "PUSH")
                        return (
                            <img
                                src={ActionNotification}
                                alt="action-notification"
                                key={action}
                            />
                        );
                    else if (action === "ACTUATOR")
                        return (
                            <img
                                src={ActionActuator}
                                alt="action-actuator"
                                key={action}
                            />
                        );
                });
            };

            const renderEventCondition = () => {
                if (!event.conditions.length)
                    return (
                        <Col
                            md={{ offset: 0 }}
                            xs={{ span: 12, order: 3 }}
                            className="text-secondary-red-1"
                        >
                            No Conditions added
                        </Col>
                    );
                else if (event.conditions.length === 1)
                    return (
                        <Col md={{ offset: 0 }} xs={{ span: 12, order: 3 }}>
                            Single Condition
                        </Col>
                    );
                else {
                    let slotCounter: Record<string, number> = {};
                    for (let condition of event.conditions) {
                        let count = slotCounter[condition.slot] ?? 0;
                        slotCounter[condition.slot] = ++count;
                    }

                    const slots = Object.values(slotCounter);

                    if (slots.some((i: any) => i > 1) && slots.length > 1) {
                        return (
                            <Col md={{ offset: 0 }} xs={{ span: 12, order: 3 }}>
                                AND and OR Conditions
                            </Col>
                        );
                    } else if (!slots.find((i: any) => i !== 1)) {
                        return (
                            <Col md={{ offset: 0 }} xs={{ span: 12, order: 3 }}>
                                OR Conditions
                            </Col>
                        );
                    }

                    return (
                        <Col md={{ offset: 0 }} xs={{ span: 12, order: 3 }}>
                            AND Conditions
                        </Col>
                    );
                }
            };

            return (
                <div className="table-row" key={event.uuid}>
                    <Row className="no-checkbox">
                        <Col md={{ offset: 0 }} xs={{ span: 11, order: 1 }}>
                            <Link
                                to={{
                                    pathname: `/event-details/${event.uuid}`,
                                }}
                            >
                                {event.name}
                            </Link>
                        </Col>
                        {renderEventCondition()}
                        <Col
                            className="event-action-col"
                            md={{ offset: 0, order: 3 }}
                            xs={{ span: 12, order: 3 }}
                        >
                            <div className="event-actions">
                                {renderActionIcon()}
                            </div>
                        </Col>
                        <Col
                            md={{ span: 1, offset: 0 }}
                            xs={{ span: 2, order: 4 }}
                        >
                            <HoverAuthorizeTooltip permission="event:update">
                                <span className="d-inline-block">
                                    <Form.Check
                                        type="switch"
                                        id={`switch_${event.uuid}`}
                                        label=""
                                        checked={event.activate}
                                        onChange={() =>
                                            toggleEventStatus(event)
                                        }
                                    />
                                </span>
                            </HoverAuthorizeTooltip>
                        </Col>
                        <Col
                            md={{ offset: 0, span: 1 }}
                            xs={{ offset: 8, span: 2, order: 5 }}
                        >
                            <HoverAuthorizeTooltip permission="event:delete">
                                <img
                                    src={dltImg}
                                    className="btn"
                                    aria-label="delete-event"
                                    alt="delete"
                                    onClick={() => {
                                        showDeleteModal();
                                        setToDelete(event.uuid);
                                    }}
                                />
                            </HoverAuthorizeTooltip>
                        </Col>
                        <Col
                            md={{ span: 1, order: "last" }}
                            xs={{ span: 1, order: 2 }}
                        >
                            <Link
                                to={{
                                    pathname: `/event-details/${event.uuid}`,
                                }}
                            >
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </Link>
                        </Col>
                    </Row>
                </div>
            );
        });
        return events;
    };

    return (
        <ContentWrapper
            isLoading={isPageLoading}
            isForbiddenResource={isForbiddenResource}
        >
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm="12">
                            <h5 className="page-title">Events</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col
                            sm={12}
                            className="d-md-flex justify-content-end table-head-options"
                        >
                            <HoverAuthorizeTooltip
                                permission="event:create"
                                className="mr-3 mb-2"
                            >
                                <Button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        walletModalTrigger(
                                            () => setSmShow(true),
                                            true
                                        );
                                    }}
                                >
                                    + New Event
                                </Button>
                            </HoverAuthorizeTooltip>
                            <div className="search">
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        placeholder="Search for.."
                                        onChange={(e: any) => {
                                            setQuery(e.target.value);
                                        }}
                                        aria-describedby="button-addon2"
                                        onKeyPress={(
                                            e: KeyboardEvent<HTMLInputElement>
                                        ) => {
                                            if (e.key === "Enter") {
                                                e.preventDefault();
                                                fetchEventList();
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </div>
                            <Button
                                className="btn btn-primary mb-2"
                                onClick={fetchEventList}
                            >
                                Search
                            </Button>
                        </Col>
                    </Row>
                    <Row className="cstm-table events-list mt-4">
                        <Col sm={12}>
                            <WalletAlertComponent showWarningWhen="LowBalance" />
                        </Col>
                        <Col sm={12}>
                            <div className="table-head">
                                <Row className="no-checkbox">
                                    <Col>Event Name {sortIcon("name")}</Col>
                                    <Col>
                                        Event Conditions{" "}
                                        {sortIcon("conditions")}
                                    </Col>
                                    <Col>
                                        Event Actions {sortIcon("actions")}
                                    </Col>
                                    <Col md={{ span: 1 }}>
                                        Status {sortIcon("activate")}
                                    </Col>
                                    <Col md={{ span: 1 }}>Action</Col>
                                    <Col md={{ span: 1 }}></Col>
                                </Row>
                            </div>
                            {renderEventList()}
                        </Col>
                    </Row>
                    {initialEventList.length > 0 && (
                        <Pagination
                            pagination={pagination}
                            setPagination={setPagination}
                        />
                    )}
                </Container>
            </div>

            <Modal
                className="new-dash"
                show={smShow}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title>New Event</Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={handleClose}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FormControl
                            type="text"
                            placeholder="Event Name"
                            autoFocus
                            value={eventName || ""}
                            onChange={(e) => {
                                updateField(e);
                            }}
                            onKeyPress={(
                                e: KeyboardEvent<HTMLInputElement>
                            ) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    createEvent();
                                }
                            }}
                        ></FormControl>
                        <Button
                            variant="primary"
                            className="btn-create w-100"
                            onClick={createEvent}
                        >
                            CREATE
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <DefaultModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                modalType={modalType}
                modalContent={modalContent}
                deleteFunction={() => {
                    deleteEvent(toDelete);
                }}
            />
        </ContentWrapper>
    );
};

export default EventList;
