import {useEffect, useState} from "react";
import { useParams } from "react-router-dom";

import EventService from "service/eventService";

import LDSUStatus from "components/events/ConditionDetail/LDSUStatus";
import SensorValue from "components/events/ConditionDetail/SensorValue";
import GatewayStatus from "components/events/ConditionDetail/GatewayStatus";
import Recurrence from "components/events/ConditionDetail/Recurrence";
import OnDate from "components/events/ConditionDetail/OnDate";

import ContentWrapper from "components/content-wrapper/ContentWrapper";
import { canAccess } from "utils/authorize-action";

import "assets/css/event.css";

const ConditionDetails = (props: any) => {
    const params: any = useParams();
    const [isForbiddenResource, updateIsForbiddenResource] = useState(false);

    useEffect(() => {
        const fetchOneEvent = async() =>{
            const res:any = await EventService.readOneEvent(params.eventId);
            if (res.status === 403){
                updateIsForbiddenResource(true)
            }
    
        }

        if (canAccess("event:read") !== undefined) {
            if(!canAccess("event:read")){
                updateIsForbiddenResource(true);
            } else {
                fetchOneEvent()
            }
        }

    }, [params.eventId]);

    const renderCondition = () => {
        if (params.condition === "ldsu-status") {
            return <LDSUStatus />;
        } else if (params.condition === "sensor-value") {
            return <SensorValue />;
        } else if (params.condition === "gateway-status") {
            return <GatewayStatus />;
        } else if (params.condition === "recurrence") {
            return <Recurrence />;
        } else if (params.condition === "on-date") {
            return <OnDate />;
        }
    };

    return (
        <ContentWrapper  isForbiddenResource={isForbiddenResource}
        >
            <div className="page-content">{renderCondition()}</div>
        </ContentWrapper>
    );
};

export default ConditionDetails;
