import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as ManageAPIAccessKeysSvg } from "assets/svg/manage-api-access-key.svg";
import "assets/css/settings.css";
import { useContext } from "react";
import { AppContext } from "context/appContext";

export const pathToManageAccessKeys: string =
    "/settings/manage-api-access-keys";

const Settings = () => {
    const {
        storeData: {
            organization: {
                currentOrgInfo: { isDefault, name },
            },
        },
    } = useContext(AppContext);

    const renderText = () => {
        if (isDefault) {
            return `You can use this Access Keys for your personal account only. For your organisations, please switch to an organisation and create Access Keys under that organisation.`;
        } else {
            return (
                <>
                    You can use this Access Keys for your organisation{" "}
                    <span className="default-white-color">{name}</span> only.
                    For other organisations or personal account, please switch
                    to it and create Access Keys under that
                    organisation/personal account.
                </>
            );
        }
    };

    return (
        <div className="page-content">
            <Container>
                <Row>
                    <Col sm={12}>
                        <h5 className="page-title">Settings</h5>
                    </Col>
                </Row>
                <Row className="cstm-table">
                    <Col sm="12">
                        <div className="table-row">
                            <Row className="no-checkbox">
                                <Col
                                    md={{ span: 1 }}
                                    xs={{ span: 2 }}
                                    className="align-self-start text-center"
                                >
                                    <ManageAPIAccessKeysSvg />
                                </Col>

                                <Col className="height-auto">
                                    <Link
                                        to={{
                                            pathname: pathToManageAccessKeys,
                                        }}
                                    >
                                        Manage API Access Keys{" "}
                                    </Link>
                                    <p className="reusable-description mt-4">
                                        Use API access key to retrieve and
                                        analyze the sensor data of gateways and
                                        send commands to actuators on your
                                        application or script.{" "}
                                    </p>
                                    <p className="reusable-description">
                                        {renderText()}
                                    </p>
                                </Col>
                                <Col
                                    md={{ span: 1, order: "last" }}
                                    xs={{ span: 1, order: 2 }}
                                >
                                    <Link
                                        to={{
                                            pathname: pathToManageAccessKeys,
                                        }}
                                    >
                                        <i className="material-icons right">
                                            keyboard_arrow_right
                                        </i>
                                    </Link>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Settings;
