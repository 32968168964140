import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import { Container, Row, Col, Button } from "react-bootstrap";

import { DashboardHeader } from "components/dashboard/DashboardHeader";
import SensorChart from "components/dashboard/elements/charts/SensorChart";
import StackedChart from "components/dashboard/elements/charts/StackedChart";
import StatusChart from "components/dashboard/elements/charts/StatusChart";
import StorageChart from "components/dashboard/elements/charts/StorageChart";
import ActuatorChart from "components/dashboard/elements/charts/ActuatorChart";
import MetricChart from "components/dashboard/elements/charts/MetricChart";
import ContentWrapper from "components/content-wrapper/ContentWrapper";

import {
    addNewDashBoard,
    getDashboards,
    getPanelDashboardV2,
    patchOrderPanelV2,
} from "service/dashboardService";

import { isHttpSuccess, tagOptions } from "utils/functions";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { storeDashboardAction } from "store/actions";
import { AppContext } from "context/appContext";

import {
    FormType,
    CREATED_DASHBOARD_MESSAGE,
    HttpStatus,
    FETCH_GATEWAY_FAIL_MESSAGE,
} from "constant";

import VisibleView from "components/hoc/VisibleView";
import { WalletAlertComponent, walletModalTrigger } from "hooks/wallet";
import { canAccess } from "utils/authorize-action";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {
    Dashboard as DashboardModel,
    GetPanelsByDashboardIdParamsSource,
} from "generated/models";
import { faker } from "@faker-js/faker";
import GridStackElement, { GridStackChangeEvent } from "utils/gridstack";
import DashboardGridStack from "components/hoc/DashboardGridStack";
import { getAllRegistry } from "service/gatewayService";
import { DashboardModal } from "components/modals/DashboardModal";
import LocalStorageService from "service/localStorageService";

export const NUMBER_OF_SCROLL = 100;

const sourceDict: { [id: string]: undefined | string } = {
    All: undefined,
    Gateways: "GATEWAY",
    Sensors: "SENSOR",
    Actuators: "ACTUATOR",
    Others: "OTHER",
};

const Dashboard: React.FC = () => {
    const history = useHistory();
    const { storeData, storeDispatchActions } = useContext(AppContext);
    const { organization, dashboard } = storeData;
    const [allGatewaysList, setAllGatewaysList] = useState([]);
    const { currentOrgId } = organization;
    const { dashboardList, currentSelectedTab, currentDashboard } = dashboard;
    const [zoomLevel, setZoomLevel] = useState(window.devicePixelRatio);
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    const [isDashboardLoading, setIsDashboardLoading] = useState(true);
    const [isPanelLoading, setIsPanelLoading] = useState(false);
    const [infinityState, setInfinityState] = useState<{
        items: any;
        hasMore: null | boolean;
        count: null | number;
        page: number;
    }>({
        items: [],
        hasMore: null,
        count: null,
        page: 0,
    });
    const [showDashboardForm, setShowDashboardForm] = useState(false);
    const [formType, setFormType] = useState<FormType>(FormType.DEFAULT);

    useEffect(() => {
        (async () => {
            const response = await getAllRegistry();
            if (isHttpSuccess(response.status)) {
                setAllGatewaysList(response.data);
            } else {
                showErrorAlert(
                    getAPIError(response, FETCH_GATEWAY_FAIL_MESSAGE)
                );
            }
        })();
    }, []);

    const handleOnChangeDashboardUUID = async (value: string) => {
        if (value) {
            setIsPanelLoading(true);
            await fetchNextInfiniteScroll();
        }
    };

    const handleAddChart = async () => {
        walletModalTrigger(() => {
            history.push(
                `/dashboard/${currentDashboard.key}/new-charts?organization_id=${currentOrgId}`
            );
        });
    };

    const fetchNextInfiniteScroll = async () => {
        const _infinityState: any = {
            ...infinityState,
        };

        _infinityState.items = [];
        _infinityState.count = 0;
        _infinityState.page = 0;

        const { status, data, total } = await getPanelDashboardV2(
            currentDashboard.key,
            {
                offset: NUMBER_OF_SCROLL * _infinityState.page,
                limit: NUMBER_OF_SCROLL,
                source: sourceDict[
                    currentSelectedTab
                ] as GetPanelsByDashboardIdParamsSource,
            }
        );

        setIsPanelLoading(false);
        if (isHttpSuccess(status)) {
            _infinityState.items = [
                ..._infinityState.items,
                ...(data?.sort((a: any, b: any) => a.order - b.order) || []),
            ];
            _infinityState.count = total;

            _infinityState.hasMore =
                _infinityState.items.length < _infinityState.count;
            setInfinityState(_infinityState);
        }
    };

    const onGridChange = ({
        data: dataItems,
        items: targetItems,
    }: GridStackChangeEvent) => {
        const data = {
            order: {} as any,
            size: {} as any,
        };

        dataItems
            .sort((a: any, b: any) => {
                return a.y * 1e6 + a.x - (b.y * 1e6 + b.x);
            })
            .forEach((item: any, index: number) => {
                data.order[item.id] = index;
            });

        // For change items only
        targetItems.forEach((item: any) => {
            data.size[item.el.id] = item.w;
        });

        patchOrderPanelV2(currentDashboard.key, data);
    };

    const renderDashboardPanels = () => (
        <>
            {infinityState.items.length > 0 && renderPanels()}
            {infinityState.items.length === 0 && renderEmpty()}
        </>
    );

    const renderPanels = () => {
        return (
            <>
                <WalletAlertComponent showWarningWhen="LowBalance" />
                <GridStackElement
                    className="grid w-100"
                    id="dashboard-grid"
                    key={faker.datatype.uuid()}
                    itemCount={infinityState.items.length}
                    gap={0}
                    cellHeight={410}
                    reRender={false}
                    options={{
                        disableResize: false,
                        autoPosition: true,
                        alwaysShowResizeHandle: true,
                        resizable: {
                            handles: "e, w",
                        },
                        draggable: {
                            handle: ".widget-drag",
                        },
                    }}
                    onChange={onGridChange}
                >
                    {infinityState.items.map(
                        (
                            items: {
                                source: string;
                                chart: string;
                                attributes: {
                                    [x: string]: any;
                                    time_series: any;
                                    gateway_id: any;
                                    device_id: any;
                                    said: any;
                                    type_: string;
                                }[];
                            },
                            index: React.Key | null | undefined
                        ) => {
                            if (
                                items.source.toUpperCase() === "SENSOR" &&
                                items.chart.toUpperCase() === "TIME" &&
                                (currentSelectedTab === "Sensors" ||
                                    currentSelectedTab === "All")
                            ) {
                                return (
                                    <DashboardGridStack
                                        key={faker.datatype.uuid()}
                                        role="gridcell"
                                    >
                                        <VisibleView
                                            key={faker.datatype.uuid()}
                                        >
                                            <SensorChart
                                                zoomLevel={zoomLevel}
                                                panel={items}
                                                index={index}
                                                selectedDashboardUUID={
                                                    currentDashboard.key
                                                }
                                                handleOnChangeDashboardUUID={
                                                    handleOnChangeDashboardUUID
                                                }
                                            />
                                        </VisibleView>
                                    </DashboardGridStack>
                                );
                            } else if (
                                items.source.toUpperCase() === "SENSOR" &&
                                items.chart.toUpperCase() === "STACKED" &&
                                (currentSelectedTab === "Sensors" ||
                                    currentSelectedTab === "All")
                            ) {
                                return (
                                    <DashboardGridStack
                                        key={faker.datatype.uuid()}
                                        role="gridcell"
                                    >
                                        <VisibleView
                                            key={JSON.stringify(items)}
                                        >
                                            <StackedChart
                                                zoomLevel={zoomLevel}
                                                panel={items}
                                                index={index}
                                                selectedDashboardUUID={
                                                    currentDashboard.key
                                                }
                                                handleOnChangeDashboardUUID={
                                                    handleOnChangeDashboardUUID
                                                }
                                                allGatewaysList={
                                                    allGatewaysList
                                                }
                                            />
                                        </VisibleView>
                                    </DashboardGridStack>
                                );
                            } else if (
                                items.source.toUpperCase() === "GATEWAY" &&
                                items.attributes[0].type_?.toUpperCase() ===
                                    "STORAGE" &&
                                (currentSelectedTab === "Gateways" ||
                                    currentSelectedTab === "All")
                            ) {
                                return (
                                    <DashboardGridStack
                                        key={faker.datatype.uuid()}
                                        role="gridcell"
                                    >
                                        <VisibleView
                                            key={faker.datatype.uuid()}
                                        >
                                            <StorageChart
                                                zoomLevel={zoomLevel}
                                                panel={items}
                                                index={index}
                                                selectedDashboardUUID={
                                                    currentDashboard.key
                                                }
                                                handleOnChangeDashboardUUID={
                                                    handleOnChangeDashboardUUID
                                                }
                                            />
                                        </VisibleView>
                                    </DashboardGridStack>
                                );
                            } else if (
                                items.source.toUpperCase() === "GATEWAY" &&
                                items.attributes[0].type_?.toUpperCase() ===
                                    "STATUS" &&
                                (currentSelectedTab === "Gateways" ||
                                    currentSelectedTab === "All")
                            ) {
                                return (
                                    <DashboardGridStack
                                        key={faker.datatype.uuid()}
                                        role="gridcell"
                                    >
                                        <VisibleView
                                            key={faker.datatype.uuid()}
                                        >
                                            <StatusChart
                                                zoomLevel={zoomLevel}
                                                panel={items}
                                                index={JSON.stringify(items)}
                                                selectedDashboardUUID={
                                                    currentDashboard.key
                                                }
                                                handleOnChangeDashboardUUID={
                                                    handleOnChangeDashboardUUID
                                                }
                                            />
                                        </VisibleView>
                                    </DashboardGridStack>
                                );
                            } else if (
                                items.source.toUpperCase() === "ACTUATOR" &&
                                (currentSelectedTab === "Actuators" ||
                                    currentSelectedTab === "All")
                            ) {
                                return (
                                    <DashboardGridStack
                                        key={faker.datatype.uuid()}
                                        role="gridcell"
                                    >
                                        <VisibleView
                                            key={faker.datatype.uuid()}
                                        >
                                            <ActuatorChart
                                                zoomLevel={zoomLevel}
                                                panel={items}
                                                index={JSON.stringify(items)}
                                                selectedDashboardUUID={
                                                    currentDashboard.key
                                                }
                                                handleOnChangeDashboardUUID={
                                                    handleOnChangeDashboardUUID
                                                }
                                            />
                                        </VisibleView>
                                    </DashboardGridStack>
                                );
                            } else if (
                                items.source.toUpperCase() === "OTHER" &&
                                items.attributes[0].type_?.toUpperCase() ===
                                    "COUNT_ALERT_TRIGGER" &&
                                (currentSelectedTab === "Others" ||
                                    currentSelectedTab === "All")
                            ) {
                                return (
                                    <DashboardGridStack
                                        key={faker.datatype.uuid()}
                                        role="gridcell"
                                    >
                                        <VisibleView
                                            key={faker.datatype.uuid()}
                                        >
                                            <MetricChart
                                                zoomLevel={zoomLevel}
                                                panel={items}
                                                index={JSON.stringify(items)}
                                                selectedDashboardUUID={
                                                    currentDashboard.key
                                                }
                                                handleOnChangeDashboardUUID={
                                                    handleOnChangeDashboardUUID
                                                }
                                                displayConfig={{
                                                    title: `Count of events`,
                                                    subTitle: `Count of Events enabled vs triggered`,
                                                    chartColor: [
                                                        "#FDA8A8",
                                                        "#D25A5A",
                                                    ],
                                                    getChartSeries: (
                                                        monthlyData: any
                                                    ) => [
                                                        {
                                                            name: "Events enabled",
                                                            type: "bar",
                                                            stack: "one",
                                                            barWidth: 20,
                                                            data: monthlyData.eventsEnabledData,
                                                        },
                                                        {
                                                            name: "Events triggered",
                                                            type: "bar",
                                                            stack: "two",
                                                            barWidth: 20,
                                                            data: monthlyData.eventsTriggeredData,
                                                        },
                                                    ],
                                                }}
                                                showLabels={[
                                                    "Events enabled",
                                                    "Events triggered",
                                                ]}
                                            />
                                        </VisibleView>
                                    </DashboardGridStack>
                                );
                            } else if (
                                items.source.toUpperCase() === "OTHER" &&
                                items.attributes[0].type_?.toUpperCase() ===
                                    "COUNT_ALERT" &&
                                (currentSelectedTab === "Others" ||
                                    currentSelectedTab === "All")
                            ) {
                                return (
                                    <DashboardGridStack
                                        key={faker.datatype.uuid()}
                                        role="gridcell"
                                    >
                                        <VisibleView
                                            key={faker.datatype.uuid()}
                                        >
                                            <MetricChart
                                                zoomLevel={zoomLevel}
                                                panel={items}
                                                index={JSON.stringify(items)}
                                                selectedDashboardUUID={
                                                    currentDashboard.key
                                                }
                                                handleOnChangeDashboardUUID={
                                                    handleOnChangeDashboardUUID
                                                }
                                                displayConfig={{
                                                    title: `Count of Email, SMS, Notification`,
                                                    subTitle: `Count of emails, SMS, and notifications sent`,
                                                    chartColor: [
                                                        "#EE9EC6",
                                                        "#F6C39D",
                                                        "#FFEFB5",
                                                    ],
                                                    getChartSeries: (
                                                        monthlyData: any
                                                    ) => [
                                                        {
                                                            name: "Email",
                                                            type: "bar",
                                                            stack: "one",
                                                            barWidth: 25,
                                                            data: monthlyData.emailData,
                                                        },
                                                        {
                                                            name: "SMS",
                                                            type: "bar",
                                                            stack: "one",
                                                            barWidth: 25,
                                                            data: monthlyData.smsData,
                                                        },
                                                        {
                                                            name: "Notification",
                                                            type: "bar",
                                                            stack: "one",
                                                            barWidth: 25,
                                                            data: monthlyData.notificationData,
                                                        },
                                                    ],
                                                }}
                                                showLabels={[
                                                    "Email",
                                                    "SMS",
                                                    "Notification",
                                                ]}
                                            />
                                        </VisibleView>
                                    </DashboardGridStack>
                                );
                            } else {
                                return <></>;
                            }
                        }
                    )}
                </GridStackElement>
            </>
        );
    };

    const renderEmpty = () => (
        <Col sm="12" className="mt-2">
            <div className="empty-widget text-center">
                <h3 className="mb-3">No Charts added</h3>
                <h5 className="mb-3">Add a new chart to your dashboard.</h5>
                <Button
                    variant="primary"
                    className="mt-3"
                    onClick={handleAddChart}
                >
                    Add Chart
                </Button>
            </div>
        </Col>
    );

    const checkPermission = () => {
        if (!canAccess("dashboard:read")) {
            return setIsForbiddenResource(true);
        }

        setIsDashboardLoading(false);
        setIsForbiddenResource(false);
    };

    useEffect(() => {
        const handleResize = () => {
            const newZoomLevel = window.devicePixelRatio;
            if (newZoomLevel !== zoomLevel) setZoomLevel(newZoomLevel);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [zoomLevel]);
    useEffect(() => {
        if (currentDashboard?.key) {
            handleOnChangeDashboardUUID(currentDashboard.key);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentDashboard, currentSelectedTab]);

    useEffect(() => {
        (async () => {
            if (!currentOrgId) return;

            if (!canAccess("dashboard:read")) {
                setIsDashboardLoading(false);
                return setIsForbiddenResource(true);
            }

            const dashboardsResp = await getDashboards(currentOrgId);

            if (dashboardsResp.status === HttpStatus.FORBIDDEN) {
                setIsForbiddenResource(true);
                return;
            }

            if (!isHttpSuccess(dashboardsResp.status)) {
                showErrorAlert(
                    getAPIError(
                        dashboardsResp,
                        "Unable to fetch dashboards. Please try again."
                    )
                );
                return;
            }

            dashboardsResp.data.forEach((dashboard: DashboardModel) => {
                if (!dashboard.icon_color.includes("#")) {
                    dashboard.icon_color = `#${dashboard.icon_color}`;
                }
            });

            const dbInfo = LocalStorageService.getItem("dbInfo");
            const hasActiveDashboard = dashboardsResp.data.some(
                (dashboard: DashboardModel) => dashboard.uuid === dbInfo?.key
            );

            if (hasActiveDashboard) {
                storeDispatchActions(
                    storeDashboardAction({
                        dashboardList: tagOptions(dashboardsResp.data),
                        targetId: dbInfo.key,
                    })
                );
            } else {
                storeDispatchActions(
                    storeDashboardAction({
                        dashboardList: tagOptions(dashboardsResp.data),
                    })
                );
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrgId]);

    useEffect(() => {
        checkPermission();
    }, [currentOrgId]);

    const handleCloseDashboardModal = () => {
        setShowDashboardForm(false);
    };

    const updateDashboardState = async (dashboardId?: string) => {
        const { status, data, message } = await getDashboards(currentOrgId);

        if (!isHttpSuccess(status)) {
            showErrorAlert({
                message:
                    message ?? "Unable to get Dashboards. Please try again.",
            });
            return;
        }

        storeDispatchActions(
            storeDashboardAction({
                dashboardList: tagOptions(data),
                targetId: dashboardId,
            })
        );

        handleCloseDashboardModal();
    };

    const createDashboard = async (name: string, color: string) => {
        const { status, data, message } = await addNewDashBoard(name, color);

        if (!isHttpSuccess(status)) {
            showErrorAlert({
                message:
                    message ?? "Unable to add Dashboard. Please try again.",
            });
            return;
        }

        LocalStorageService.setItem("dbInfo", tagOptions([data]));

        showSuccessAlert({
            message: CREATED_DASHBOARD_MESSAGE,
        });

        await updateDashboardState(data.uuid);
    };

    return (
        <ContentWrapper
            isForbiddenResource={isForbiddenResource}
            title="Dashboard"
            isLoading={isDashboardLoading}
        >
            {dashboardList.length !== 0 && (
                <div className="page-head">
                    <Container fluid>
                        <DashboardHeader />
                    </Container>
                </div>
            )}

            <div className="page-content">
                <Container fluid>
                    <Row>
                        {isPanelLoading ? (
                            <ContentWrapper isLoading={isPanelLoading} />
                        ) : dashboardList.length > 0 ? (
                            renderDashboardPanels()
                        ) : (
                            <Col sm="12" className="mt-2">
                                <div className="empty-widget text-center">
                                    <h3 className="mb-3">
                                        No Dashboards created yet
                                    </h3>
                                    <h5 className="mb-3">
                                        Create Dashboards to visualize your
                                        data.
                                    </h5>
                                    <Button
                                        variant="primary"
                                        className="mt-3"
                                        onClick={() =>
                                            walletModalTrigger(() => {
                                                setShowDashboardForm(true);
                                                setFormType(FormType.CREATE);
                                            })
                                        }
                                    >
                                        Create New Dashboard
                                    </Button>
                                </div>
                                <WalletAlertComponent />
                                <DashboardModal
                                    show={showDashboardForm}
                                    onClose={handleCloseDashboardModal}
                                    type={formType}
                                    onSubmit={createDashboard}
                                />
                            </Col>
                        )}
                    </Row>
                </Container>
            </div>
        </ContentWrapper>
    );
};

export default Dashboard;
