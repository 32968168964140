import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ripple from "assets/img/ripple.gif";
import { Spinner } from "react-bootstrap";
import { faker } from "@faker-js/faker";
import styled from "@emotion/styled";
import { DefaultModalProps, ModalType } from "types/Modal";

export const CustomModal = styled(Modal)`
    & .modal-dialog {
        max-width: ${(props) => (props.width || 500) + 2 + "px"};
    }

    & .modal-date-picker .MuiFormControl-root {
        width: 100%;
        background: #f0f0f0 !important;
        min-width: 100% !important;
        border-radius: 4px !important;

        & .MuiOutlinedInput-root {
            height: 36px;

            & input,
            & svg {
                color: #4c4c4c !important;
            }
        }

        & fieldset {
            border: none !important;
        }
    }

    &.updateConfirm .modal-body {
        .btn {
            &.btn-danger,
            &.btn-primary {
                text-transform: uppercase;
            }
        }
    }
`;

const DefaultModal = ({
    modalShow,
    setModalShow,
    modalType = "",
    modalHeaderButton,
    modalContent,
    modalButton,
    deleteFunction,
    resendFunction,
    okAction,
    errorArray,
    modalTitle,
    isLoading,
    modalIcon,
    iconVariant = "primary",
    modalFooter,
    modalData,
    width,
}: DefaultModalProps) => {
    /*
    Props
    modalShow: boolean,
    setModalShow: function(boolean),
    modalType: string
        "ok" - Green tick icon, "OK" button to close modal
        "err" - Red Cross icon, "OK" button to close modal
        "dlt" - Trash icon,"Cancel" button to close modal and "Delete" button to execute deleteFunction
        "dltok" - Green tick icon, "OK" button to trigger okAction
    modalContent: string,
    deleteFunction: function(),
    okAction: any
        Eg, history.push() or setModalShow(false);
    errorArray: array,
    modalTitle: string,
    isLoading: boolean,
    modalIcon: string
    */
    const renderModalIcon = () => {
        if (modalIcon) {
            return (
                <span
                    className={
                        iconVariant === "primary"
                            ? "material-icons bg-primary-green-1"
                            : "material-icons bg-secondary-red-1"
                    }
                >
                    {modalIcon}
                </span>
            );
        }
        if (modalType === "err") {
            return <span className="material-icons">close</span>;
        }
        if (modalType === "dlt") {
            return <span className="material-icons">delete</span>;
        }
        if (modalType === "resendConfirm") {
            return <span className="material-icons">forward_to_inbox</span>;
        }
        if (modalType === "resendConfirmOk") {
            return <span className="material-icons">done</span>;
        }
        if (modalType === "updateConfirm") {
            return <span className="material-icons">help</span>;
        }

        return <span className="material-icons bg-primary-green-1">done</span>;
    };
    const renderModalTitle = () => {
        if (modalTitle) {
            return <h3 className="mb-3">{modalTitle}</h3>;
        }
        if (modalType === "err") {
            return <h3 className="mb-3">Error</h3>;
        }
        if (modalType === "dlt") {
            return <h3 className="mb-3">Confirm Delete</h3>;
        }
        if (modalType === "resendConfirm") {
            return <h3 className="mb-3">Confirm</h3>;
        }
        if (modalType === "resendConfirmOk") {
            return <h3 className="mb-3">New Code Sent</h3>;
        }
        if (modalType === "updateConfirm") {
            return <h3 className="mb-3">Confirmation</h3>;
        }

        if (modalType === "content") {
            return "";
        }

        return <h3 className="mb-3">Success</h3>;
    };
    const renderModalButtons = () => {
        if (modalType === "dlt") {
            return (
                <>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setModalShow?.(false);
                        }}
                    >
                        CANCEL
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => {
                            deleteFunction?.();
                        }}
                    >
                        DELETE
                    </Button>
                </>
            );
        } else if (
            ["dltok", "resendConfirmOk", "cancelConfirmOk", "scanok"].includes(
                modalType
            )
        ) {
            return (
                <Button variant="primary" onClick={okAction}>
                    OK
                </Button>
            );
        } else if (
            ["resendConfirm", "cancelConfirm", "updateConfirm"].includes(
                modalType
            )
        ) {
            return (
                <>
                    <Button
                        variant={
                            modalType === "updateConfirm"
                                ? "danger"
                                : "secondary"
                        }
                        onClick={() => {
                            setModalShow?.(false);
                        }}
                    >
                        NO
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setModalShow?.(false);
                            resendFunction?.();
                        }}
                    >
                        YES
                    </Button>
                </>
            );
        }
        return (
            <Button
                variant="primary"
                onClick={() => {
                    setModalShow?.(false);
                }}
            >
                OK
            </Button>
        );
    };

    const renderErrorArray = () => {
        if (errorArray) {
            return errorArray.map((error: any) => {
                return (
                    <p
                        role="alert"
                        className="mb-4"
                        key={faker.datatype.uuid()}
                    >
                        {error}
                    </p>
                );
            });
        }
    };

    return (
        <CustomModal
            centered
            show={modalShow}
            onHide={() => setModalShow?.(false)}
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-sm"
            className={`${modalType} ${
                modalType !== "content" && "no-header"
            } ${
                [
                    "ok",
                    "resendConfirm",
                    "cancelConfirm",
                    "resendConfirmOk",
                    "updateConfirm",
                ].includes(modalType)
                    ? "primary"
                    : "danger"
            }`}
            width={width}
        >
            {isLoading ? (
                <img className="loader-gif" src={ripple} alt="loading" />
            ) : (
                <>
                    {modalType === "content" && (
                        <Modal.Header>
                            <Modal.Title>{modalTitle}</Modal.Title>
                            {typeof modalHeaderButton === "function" ? (
                                modalHeaderButton(modalData)
                            ) : (
                                <Button
                                    variant=""
                                    className="close-button"
                                    onClick={() => setModalShow?.(false)}
                                >
                                    <span className="material-icons">
                                        close
                                    </span>
                                </Button>
                            )}
                        </Modal.Header>
                    )}
                    <Modal.Body
                        className={`${
                            modalType === "content"
                                ? ""
                                : "text-center mt-3 mb-3"
                        }`}
                    >
                        {modalType === "content" ? (
                            typeof modalContent === "function" ? (
                                modalContent(modalData)
                            ) : (
                                modalContent
                            )
                        ) : (
                            <>
                                {modalType === "custom" && !modalIcon ? (
                                    ""
                                ) : (
                                    <div className="modal-icon-box">
                                        {renderModalIcon()}
                                    </div>
                                )}

                                {renderModalTitle()}
                                <div role="alert" className="mb-4">
                                    {modalType === "custom"
                                        ? typeof modalContent === "function"
                                            ? modalContent(modalData)
                                            : modalContent
                                        : modalContent}
                                </div>
                                {renderErrorArray()}
                                {modalType === "custom"
                                    ? typeof modalButton === "function"
                                        ? modalButton(modalData)
                                        : modalButton
                                    : renderModalButtons()}
                            </>
                        )}
                    </Modal.Body>
                    {modalType === "content" && !!modalFooter && (
                        <Modal.Footer>
                            {typeof modalFooter === "function"
                                ? modalFooter(modalData)
                                : modalFooter}
                        </Modal.Footer>
                    )}
                </>
            )}
        </CustomModal>
    );
};

const LoadingModal = ({ showModal, title = "" }: any) => {
    return (
        <Modal
            centered
            show={showModal}
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-sm"
            className="no-header"
        >
            <div className="spinner-container">
                <Modal.Title className="loading-modal-title">
                    {title}
                </Modal.Title>
                <Spinner
                    animation="border"
                    variant="primary"
                    className="grey-spinner"
                />
            </div>
        </Modal>
    );
};

export const useModal = (initProps?: Partial<DefaultModalProps>) => {
    const DEFAULT_MODAL_WIDTH = 500;
    const [modalShow, setModalShow] = React.useState<boolean>(false);
    const [modalProps, setModalProps] = React.useState<DefaultModalProps>({
        ...initProps,
        modalShow,
        setModalShow,
    });
    const [modal, setModal] = React.useState<any>(null);
    const [modalData, setModalData] = React.useState<Record<string, any>>({});

    React.useEffect(() => {
        setModalProps((prevProps) => ({ ...prevProps, modalShow, modalData }));
    }, [modalShow, modalData]);

    React.useEffect(() => {
        setModal(<DefaultModal key={modalProps.key} {...modalProps} />);
    }, [modalProps]);

    const openModal = (
        newProps: Partial<DefaultModalProps> & {
            key: string | number;
            modalType: ModalType;
        }
    ) => {
        setModalShow(true);
        setModalProps({
            ...modalProps,
            ...newProps,
            modalShow: true,
            width: newProps.width ?? DEFAULT_MODAL_WIDTH,
        });
    };

    return {
        modal,
        openModal,
        setModalShow,
        modalData,
        setModalData,
    };
};

export default DefaultModal;
export { LoadingModal };
