import AxiosInstance from "./axiosInstance";
import {
    createErrorResponseStandard,
    createResponseStandard,
} from "utils/functions";
import { orgId } from "utils/functions";
import moment from "moment";
import { TimeSchedule } from "constant/index";
import { apiFactory } from "generated";
import {
    Action,
    ActionPatch,
    ActionPost,
    Condition,
    ConditionOrderPatch,
    ConditionPatch,
    ConditionPost,
    EventPatch,
    EventPost,
    GetEventsByOrganizationIdParamsBy,
    GetEventsByOrganizationIdParamsFilter,
    GetEventsByOrganizationIdParamsOrder,
    Event
} from "generated/models";

export type EventFilter = GetEventsByOrganizationIdParamsFilter;
export type EventBy = GetEventsByOrganizationIdParamsBy;
export type EventOrder = GetEventsByOrganizationIdParamsOrder;

class EventService {
    private eventClient = apiFactory.getEventsInstance(AxiosInstance);

    async createEvent(name: string) {
        const body: EventPost = {
            name: name,
            organization_id: orgId(),
        };

        try {
            const response = await this.eventClient.createEvent(body);
            return createResponseStandard<Event>(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async readEvent(searchParams?: {
        /** Offset for paginated result */
        offset?: number;
        /** Limit for paginated result */
        limit?: number;
        /** Filtering option */
        filter?: EventFilter;
        /** Event name for filtering */
        name?: string;
        /** Gateway ID for filtering */
        gateway_id?: string;
        /** Device (LDSU) ID for filtering */
        device_id?: string;
        /** SAID for filtering */
        said?: number;
        /** Recipient for filtering */
        recipient?: string;
        /** Ordering column */
        by?: EventBy;
        /** Ordering method */
        order?: EventOrder;
    }) {
        try {
            const response = await this.eventClient.getEventsByOrganizationId({
                offset: 0,
                limit: 100,
                ...searchParams,
                organization_id: orgId(),
            });
            return createResponseStandard<Event[]>(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async readOneEvent(eventId: string) {
        try {
            const response = await this.eventClient.getEventById(eventId);
            return createResponseStandard<Event>(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async updateEvent(eventId: string, body: EventPatch) {
        try {
            const response = await this.eventClient.updateEventById(
                eventId,
                body
            );
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async deleteEvent(eventId: string) {
        try {
            const response = await this.eventClient.deleteEventById(eventId);
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async createCondition(eventId: string, body: ConditionPost) {
        try {
            const response = await this.eventClient.createEventCondition(
                eventId,
                body
            );
            return createResponseStandard<Condition>(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async readOneCondition(conditionId: string, eventId: string) {
        try {
            const response = await this.eventClient.getEventConditionById(
                eventId,
                conditionId
            );
            return createResponseStandard<Condition>(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async updateCondition(
        eventId: string,
        conditionId: string,
        body: ConditionPatch
    ) {
        try {
            const response = await this.eventClient.updateEventConditionById(
                eventId,
                conditionId,
                body
            );
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async deleteCondition(eventId: string, conditionId: string) {
        try {
            const response = await this.eventClient.deleteEventConditionById(
                eventId,
                conditionId
            );
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async sortOrderCondition(
        eventId: string,
        order: Record<string, number>,
        slot: Record<string, number>
    ) {
        try {
            const body: ConditionOrderPatch = {
                order,
                slot,
            };
            const response =
                await this.eventClient.updateEventConditionsByEventId(
                    eventId,
                    body
                );
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async createAction(eventId: string, body: ActionPost) {
        try {
            const response = await this.eventClient.createEventAction(
                eventId,
                body
            );
            return createResponseStandard<Action>(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async readOneAction(eventId: string, actionId: string) {
        try {
            const response = await this.eventClient.getEventActionById(
                eventId,
                actionId
            );
            return createResponseStandard<Action>(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async updateAction(eventId: string, actionId: string, body: ActionPatch) {
        try {
            const response = await this.eventClient.updateEventActionById(
                eventId,
                actionId,
                body
            );
            return createResponseStandard<Action>(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async deleteAction(eventId: string, actionId: string) {
        try {
            const response = await this.eventClient.deleteEventActionById(
                eventId,
                actionId
            );
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    async sortOrderAction(eventId: string, order: Record<string, number>) {
        try {
            const response = await this.eventClient.updateEventActionOrderById(
                eventId,
                { order }
            );
            return createResponseStandard(response);
        } catch (error) {
            return createErrorResponseStandard(error);
        }
    }

    getEventTimeRange(
        selectedTimeOption: any,
        priTime: any,
        secTime: any,
        timeList: any,
        timeRangeList: any,
        duration: any
    ) {
        let timeField: any;

        switch (selectedTimeOption) {
            case TimeSchedule.ALL_DAY:
                timeField = {
                    schedule: "all_day",
                    ranges: [
                        [
                            moment().startOf("day").format(`HH:mm:ss`),
                            moment().endOf("day").format(`HH:mm:ss`),
                        ],
                    ],
                };

                break;

            case TimeSchedule.BEFORE:
                timeField = {
                    schedule: "before",
                    ranges: [
                        [
                            moment().startOf("day").format(`HH:mm:ss`),
                            priTime.format("HH:mm:ss"),
                        ],
                    ],
                };
                break;
            case TimeSchedule.AFTER:
                timeField = {
                    schedule: "after",
                    ranges: [
                        [
                            priTime.format("HH:mm:ss"),
                            moment().endOf("day").format(`HH:mm:ss`),
                        ],
                    ],
                };
                break;
            case TimeSchedule.BEFORE_OR_AFTER:
                timeField = {
                    schedule: "before_or_after",
                    ranges: [
                        [
                            moment().startOf("day").format(`HH:mm:ss`),
                            priTime.format("HH:mm:ss"),
                        ],
                        [
                            secTime.format("HH:mm:ss"),
                            moment().endOf("day").format(`HH:mm:ss`),
                        ],
                    ],
                };
                break;
            case TimeSchedule.AT:
                timeField = {
                    schedule: "at",
                    points: timeList,
                };
                break;
            case TimeSchedule.BETWEEN:
                timeField = {
                    schedule: "between",
                    ranges: timeRangeList,
                };
                break;
            case TimeSchedule.EVERY:
                timeField = {
                    schedule: "every",
                    interval: duration,
                };
                break;
        }
        return timeField;
    }
}

const eventService = new EventService();

export default eventService;
