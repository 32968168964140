import { useState, useRef, ChangeEvent } from "react";
import { Link } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { styled } from "@mui/system";
import * as yup from "yup";
import { useFormik } from "formik";
import { Col, Button, Modal, Form, Spinner } from "react-bootstrap";

import { resendConfirmationCode, signUp } from "service/authenticationService";

import {
    HttpStatus,
    MaxNameChar,
    MinNameChar,
    PHONE_NUMBER_VALIDATION_MESSAGE,
    Patterns,
    TERMS_OF_SERVICE_LINK,
} from "constant";
import "react-phone-number-input/style.css";
import "assets/css/authentication.css";
import { isHttpSuccess } from "utils/functions";
import { showErrorAlert } from "utils/alert";
import { get } from "lodash";
import { PasswordField } from "components/common/form";

yup.addMethod<yup.StringSchema>(
    yup.string,
    "phone",
    function (message = PHONE_NUMBER_VALIDATION_MESSAGE) {
        return this.test(
            "phone",
            message,
            (value) => !value || isValidPhoneNumber(value)
        );
    }
);

export const Messages = {
    firstName: "First Name should not contain leading and trailing space.",
    lastName: "Last Name should not contain leading and trailing space.",
    email: "Invalid email address format.",
    password: `Password must be between 8 - 32 characters. Inclusive of an uppercase character, lowercase character, special character and number`,
    confirmPassword: "Password and confirm password do not match",
    phone: PHONE_NUMBER_VALIDATION_MESSAGE,
    firstNameLength: `First name must be between ${MinNameChar} to ${MaxNameChar} characters`,
    lastNameLength: `Last name must be between ${MinNameChar} to ${MaxNameChar} characters`,
    emailLength: `Email address must be 128 characters or fewer.`,
    terms: "Please accept the Terms of Service and Privacy Policy",
};

const schema = yup.object().shape({
    firstName: yup
        .string()
        .required(Messages.firstName)
        .min(1, Messages.firstNameLength)
        .max(255, Messages.firstNameLength)
        .matches(Patterns.namePattern, {
            message: Messages.firstName,
        }),
    lastName: yup
        .string()
        .required(Messages.lastName)
        .min(1, Messages.lastNameLength)
        .max(255, Messages.lastNameLength)
        .matches(Patterns.namePattern, {
            message: Messages.lastName,
        }),
    email: yup
        .string()
        .required(Messages.email)
        .test({
            name: "matches",
            message: Messages.email,
            test: (value) => {
                return Patterns.emailPattern.test(value || "");
            },
        })
        .max(128, Messages.emailLength),
    password: yup.string().test({
        name: "matches",
        message: Messages.password,
        test: (value) => {
            return Patterns.passwordPattern.test(value || "");
        },
    }),
    confirmPassword: yup.string().test({
        name: "confirm-password",
        message: Messages.confirmPassword,
        test: (value, context) => {
            return value === context.parent.password;
        },
    }),
    //@ts-ignore
    phone: yup.string().optional().phone(Messages.phone),
    terms: yup.bool().required(Messages.terms).oneOf([true], Messages.terms),
});

const StyledPhoneInput = styled(PhoneInput)`
    height: -webkit-fill-available;
    padding: 10px !important;

    input[type="tel"] {
        border-color: transparent;
        background-color: transparent !important;
        color: black;
    }
`;

export type SignUpFormValues = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword: string;
};

export type SignUpFormProps = {
    onSuccess?: (form: SignUpFormValues) => void;
};

const SignUpForm = ({ onSuccess }: SignUpFormProps) => {
    const [isActionPending, setIsActionPending] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [isInit, setInit] = useState(true);

    const formRef: any = useRef(null);

    const phoneRef: any = useRef(null);

    const initialValues: { terms: boolean } & SignUpFormValues = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        terms: false,
    };

    const {
        handleSubmit,
        handleChange,
        values,
        isValid,
        errors,
        setValues,
        resetForm,
    } = useFormik({
        validationSchema: schema,
        onSubmit: (values) => {
            handleSignUp(values);
        },

        initialValues: initialValues,
        validateOnChange: true,
    });

    const closeSendVerifyModal = () => {
        setModalShow(false);
    };

    const handleSignUp = async (form: SignUpFormValues) => {
        const {
            firstName: first_name,
            lastName: last_name,
            email,
            phone,
            password,
        } = form;
        setIsLoading(true);
        setErrorMessage("");
        const response = await signUp({
            phone,
            email: email.toLowerCase(),
            password,
            first_name,
            last_name,
        });

        setIsLoading(false);
        if (isHttpSuccess(response.status)) {
            setErrorMessage("");
            onSuccess?.(form);
        } else if (response.status === HttpStatus.UNPROCESSABLE_ENTITY) {
            setModalShow(true);
            setErrorMessage(
                "The email address has already been registered. Do you want to resend the verification code?"
            );
        } else {
            setErrorMessage(
                get(
                    response,
                    "data.description",
                    "Something went wrong! Please try again"
                )
            );
        }
    };

    const handleInputChange = (e: ChangeEvent) => {
        if (isInit) {
            setInit(false);
        }
        handleChange(e);
    };

    const handleResendVerification = async () => {
        setModalShow(false);
        setIsActionPending(true);

        const response: any = await resendConfirmationCode(
            values.email.toLowerCase()
        );
        if (isHttpSuccess(response.status)) {
            onSuccess?.(values);
        } else {
            showErrorAlert({
                message: get(
                    response,
                    "data.description",
                    "Something went wrong with the server. Please try again later."
                ),
            });
        }

        setIsActionPending(false);
    };

    return (
        <>
            <Col lg="6" sm="12" className="login-form">
                <div className="login-box">
                    <div className="login-table">
                        <div className="login-text">
                            <h4>Sign Up</h4>
                        </div>
                        {/* TODO: remove manual handle data https://react-bootstrap-v4.netlify.app/components/forms/#forms-validation-tooltips */}
                        <Form noValidate onSubmit={handleSubmit} ref={formRef}>
                            <Form.Group>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    aria-label="firstName"
                                    placeholder="Enter First Name"
                                    required
                                    value={values.firstName}
                                    isInvalid={!!errors.firstName}
                                    onChange={handleInputChange}
                                    className="pr-5"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.firstName}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    aria-label="lastName"
                                    placeholder="Enter Last Name"
                                    required
                                    value={values.lastName}
                                    isInvalid={!!errors.lastName}
                                    onChange={handleInputChange}
                                    className="pr-5"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.lastName}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    aria-label="email"
                                    placeholder="Enter Email Address"
                                    required
                                    isInvalid={!!errors.email}
                                    onChange={handleInputChange}
                                    value={values.email}
                                    className="pr-5"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Mobile Number (Optional)
                                </Form.Label>
                                <Form.Control
                                    ref={phoneRef}
                                    hidden
                                    name="phone"
                                    aria-label="phone"
                                    value={values.phone}
                                    isInvalid={!!errors.phone}
                                    onChange={handleInputChange}
                                />
                                <StyledPhoneInput
                                    international
                                    className="form-control"
                                    defaultCountry="SG"
                                    value={values.phone}
                                    onChange={(e: any) => {
                                        setValues({ ...values, phone: e });
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.phone}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <PasswordField
                                    name="password"
                                    aria-label="password"
                                    placeholder="Enter Password"
                                    required
                                    value={values.password}
                                    isInvalid={!!errors.password}
                                    onChange={handleInputChange}
                                    autoComplete="new-password"
                                    error={errors.password}
                                    className="pr-5"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Confirm Password</Form.Label>
                                <PasswordField
                                    name="confirmPassword"
                                    aria-label="confirmPassword"
                                    placeholder="Re-enter Password"
                                    required
                                    value={values.confirmPassword}
                                    isInvalid={!!errors.confirmPassword}
                                    onChange={handleInputChange}
                                    autoComplete="confirm-password"
                                    error={errors.confirmPassword}
                                    className="pr-5"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Check
                                    onChange={handleChange}
                                    checked={values.terms}
                                    className="mt-4 mb-3"
                                    type="checkbox"
                                    aria-label="terms"
                                    id="terms"
                                    custom
                                    label={
                                        <Form.Label>
                                            By creating an account, you are
                                            agreeing with our{" "}
                                            <a
                                                href={TERMS_OF_SERVICE_LINK}
                                                target="_blank"
                                                rel="noreferrer"
                                                className="link text-decoration-line"
                                            >
                                                Terms of Service
                                            </a>{" "}
                                            and{" "}
                                            <a
                                                href="https://brtsys.com/privacy-policy-2/"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="link text-decoration-line"
                                            >
                                                Privacy Policy
                                            </a>{" "}
                                            and you confirm that you are above
                                            13 years of age.
                                        </Form.Label>
                                    }
                                />
                            </Form.Group>

                            {!isLoading ? (
                                <Button
                                    type="submit"
                                    className="sign-up"
                                    variant={
                                        !isInit && isValid
                                            ? "primary"
                                            : "secondary"
                                    }
                                    disabled={isInit || !isValid}
                                >
                                    SIGN UP
                                </Button>
                            ) : (
                                <Button className="sign-up" variant="primary">
                                    Loading...
                                </Button>
                            )}

                            <div className="loginf">
                                {errors.terms && <p>{errors.terms}</p>}
                                {errorMessage && (
                                    <p role="alert">{errorMessage}</p>
                                )}
                            </div>
                        </Form>

                        <div className="mt-5">
                            Already have an account?{" "}
                            <Link className="link" to="/">
                                Sign In
                            </Link>
                        </div>
                    </div>
                </div>
            </Col>
            <Modal
                centered
                show={modalShow}
                onHide={closeSendVerifyModal}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`no-header danger`}
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">
                        <span className="material-icons">error_outline</span>
                    </div>
                    <h3 className="mb-3">Sign up error</h3>
                    <p className="mb-4" role="alert">
                        {errorMessage}
                    </p>
                    <>
                        <Button
                            variant="primary"
                            onClick={handleResendVerification}
                        >
                            RESEND VERIFICATION CODE
                        </Button>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                closeSendVerifyModal();
                                resetForm();
                            }}
                        >
                            CREATE ANOTHER ACCOUNT
                        </Button>
                    </>
                </Modal.Body>
            </Modal>
            <Modal
                centered
                show={isActionPending}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header"
            >
                <Modal.Body className="text-center mt-3 mb-5 mr-4">
                    <Spinner
                        className="centered-spinner"
                        animation="border"
                        variant="primary"
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SignUpForm;
