import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    InputGroup,
    FormControl,
    Button,
} from "react-bootstrap";
import moment from "moment";
import {
    getSubscriptionInfo,
    getAllTransactions,
    getFiles,
} from "service/subscriptionService";
import pdfImg from "assets/svg/pdf.svg";
import "assets/css/subscription.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import { showErrorAlert } from "utils/alert";
import { BillingHistoryDict } from "constant";
import useCollectSort from "hooks/useCollectSort";
import { isHttpSuccess } from "utils/functions";

const paymentDateFormat = `MM/dd/yyyy`;

const BillingHistory = () => {
    const [initialTransactions, setInitialTransactions] = useState<Array<any>>(
        []
    );
    const [filteredTransactions, setFilteredTransactions] = useState<
        Array<any>
    >([]);
    const [transactions, setTransactions] = useState<Array<any>>([]);
    const [subscriptions, setSubscriptions] = useState<Array<any>>([]);
    const [showCalendar, setShowCalendar] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [isSearched, setIsSearched] = useState(false);
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - 30);
    const [selectionRange, setSelectionRange] = useState({
        startDate: startDate,
        endDate: new Date(),
        key: "selection",
    });
    const location = useLocation();

    const { data: transactionCollect, sortIcon } = useCollectSort(
        transactions,
        (item) => {
            const subscription =
                subscriptions.find(
                    (sub) => sub.uuid === item.subscription_uuid
                ) || {};

            return {
                ...item,
                _subscription: {
                    ...subscription,
                    _name:
                        subscription.type_ === "SUBSCRIPTION"
                            ? subscription.plan.name
                            : subscription.plan?.addons[0].name,
                },
                _period:
                    subscription.type_ === "SUBSCRIPTION"
                        ? item.create_time
                        : "-",
                _amount: parseFloat(item.amount),
            };
        }
    );

    useEffect(() => {
        const closeCalendar = (e: any) => {
            if (
                !document.querySelector("#calendar")?.contains(e.target) &&
                !document.querySelector("#displayBox")?.contains(e.target) &&
                showCalendar
            ) {
                setShowCalendar(false);
            }
        };

        document.addEventListener("click", closeCalendar);

        return () => {
            document.removeEventListener("click", closeCalendar);
        };
    });

    useEffect(() => {
        const fetch = async () => {
            const [transactionRes, subscriptionRes]: any = await Promise.all([
                getAllTransactions(),
                getSubscriptionInfo(),
            ]);

            if (
                isHttpSuccess(transactionRes.status) &&
                isHttpSuccess(subscriptionRes.status)
            ) {
                setInitialTransactions(transactionRes.data);
                setTransactions(transactionRes.data);
                setSubscriptions(subscriptionRes.data);
            } else {
                showErrorAlert({
                    message: "Something went wrong. Please try again.",
                });
            }
        };
        fetch();

        const downloadCallback = async () => {
            if (location.search.includes("subscription_uuid")) {
                const [subscription_uuid, paymentDate] = location.search
                    .split("subscription_uuid=")[1]
                    .split("&date=");

                await handleDownload(subscription_uuid, paymentDate);
            }
        };
        downloadCallback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    const filterPaymentDates = (startDate: any, endDate: any) => {
        let filtered: any = [];
        let start = Date.parse(format(startDate, paymentDateFormat));
        let end = Date.parse(format(endDate, paymentDateFormat));
        for (let transaction of isSearched
            ? transactions
            : initialTransactions) {
            let paymentDate = Date.parse(
                format(new Date(transaction.modify_time), paymentDateFormat)
            );
            if (paymentDate >= start && paymentDate <= end) {
                filtered.push(transaction);
            }
        }

        setFilteredTransactions(filtered);
        setIsFiltered(true);
        setTransactions(filtered);
    };

    const searchTransaction = (query: string) => {
        if (query) {
            const filtered: any = (
                isFiltered ? filteredTransactions : initialTransactions
            ).reduce((acc: any, t: any) => {
                subscriptions.forEach((s: any) => {
                    if (s.uuid === t.subscription_uuid) {
                        if (
                            s.type_ === "SUBSCRIPTION" &&
                            BillingHistoryDict[s.plan.name]
                                .toLowerCase()
                                .includes(query.toLowerCase())
                        ) {
                            acc.push(t);
                        } else if (
                            s.type_ === "SALE" &&
                            BillingHistoryDict[s.plan.addons[0].name]
                                .toLowerCase()
                                .includes(query.toLowerCase())
                        ) {
                            acc.push(t);
                        }
                    }
                });
                return acc;
            }, []);

            setTransactions(filtered);
        } else {
            if (isFiltered) {
                setTransactions(filteredTransactions);
                return;
            }
            setTransactions(initialTransactions);
        }
    };

    const handleDownload = async (
        subscription_uuid: string,
        paymentDate: any
    ) => {
        const reformatSubscriptionId = subscription_uuid.replace(/-/g, "");
        const invoiceFile = `${reformatSubscriptionId}_${paymentDate}.pdf`;

        const response: any = await getFiles(subscription_uuid, invoiceFile);

        if (isHttpSuccess(response?.status)) {
            const blob = new Blob([response.data], {
                type: "application/pdf",
            });
            const href = URL.createObjectURL(blob);
            const a = Object.assign(document.createElement("a"), {
                href,
                style: "display:none",
                download: `${invoiceFile}`,
            });
            a.click();
            URL.revokeObjectURL(href);
        } else {
            showErrorAlert({
                message: "Invoice is not available for download yet.",
            });
        }
    };

    const renderStatus = (status: any) => {
        if (status === "settled") {
            return "Success";
        } else {
            return "Pending";
        }
    };

    const renderTransactions = () => {
        return transactionCollect.map((transaction: any) => {
            let paymentDate = new Date(
                transaction.modify_time
            ).toLocaleDateString("en-GB");

            let subscriptionStartDate = moment(
                transaction.billing_period_start_date || transaction.create_time
            ).format("DD/MM/YY");

            let subscriptionEndDate = transaction.billing_period_end_date
                ? moment(transaction.billing_period_end_date).format("DD/MM/YY")
                : moment(transaction.create_time)
                      .add(1, "M")
                      .subtract(1, "d")
                      .format("DD/MM/YY");

            return (
                <div
                    className="table-row"
                    key={transaction.uuid}
                    aria-label="each-transaction"
                >
                    <Row className="no-checkbox">
                        {transaction._subscription.type_ === "SUBSCRIPTION" ? (
                            <Col lg xs={{ order: 2, span: 12 }}>
                                {
                                    BillingHistoryDict[
                                        transaction._subscription.plan.name
                                    ]
                                }
                            </Col>
                        ) : (
                            <Col lg xs={{ order: 2, span: 12 }}>
                                {
                                    BillingHistoryDict[
                                        transaction._subscription.plan
                                            ?.addons[0].name
                                    ]
                                }
                            </Col>
                        )}

                        {transaction._subscription.type_ === "SUBSCRIPTION" ? (
                            <Col lg={{ offset: 0 }} xs={{ span: 12, order: 3 }}>
                                <span className="reading-label">Period: </span>
                                {subscriptionStartDate} - {subscriptionEndDate}
                            </Col>
                        ) : (
                            <Col lg={{ offset: 0 }} xs={{ span: 12, order: 3 }}>
                                {" "}
                                <span className="reading-label">Period: </span>-
                            </Col>
                        )}

                        <Col
                            lg={{ offset: 0 }}
                            xs={{ order: 4, span: 12 }}
                            className="pr-3 transaction-id-col"
                        >
                            <span className="reading-label">ID: </span>
                            {transaction.uuid}
                        </Col>
                        <Col lg={{ offset: 0 }} xs={{ order: 5, span: 12 }}>
                            {" "}
                            <span className="reading-label">
                                Payment Date:{" "}
                            </span>
                            {paymentDate}
                        </Col>
                        <Col lg={{ offset: 0 }} xs={{ order: 6, span: 12 }}>
                            <span className="amount">
                                USD {transaction.amount}
                            </span>
                        </Col>
                        <Col
                            lg={{ span: 1, offset: 0 }}
                            xs={{ order: 7, span: 6 }}
                        >
                            <span className="success" aria-label="status">
                                {renderStatus(transaction.status)}
                            </span>
                        </Col>
                        <Col
                            lg={{ span: 1, offset: 0 }}
                            xs={{ order: 8, span: 6 }}
                        >
                            <Button
                                className="download-pdf"
                                onClick={() => {
                                    const formattedPaymentDate = moment(
                                        paymentDate,
                                        `DD/MM/YYYY`
                                    ).format(`YYYYMMDD`);
                                    handleDownload(
                                        transaction.subscription_uuid,
                                        formattedPaymentDate
                                    );
                                }}
                            >
                                <img src={pdfImg} alt="download" /> Download
                            </Button>
                        </Col>
                    </Row>
                </div>
            );
        });
    };

    const displayTransactions = () => {
        if (!transactions.length) {
            return (
                <div className="mt-5 text-center">No transaction found.</div>
            );
        } else if (!initialTransactions.length) {
            return (
                <div className="mt-5 text-center">
                    No existing transactions.
                </div>
            );
        }

        return renderTransactions();
    };

    return (
        <div className="subscription-page billing-history-component">
            <Container className="subscription-container">
                <Row>
                    <Col sm={12} className="justify-content-end d-md-flex">
                        <div className="filter">
                            <div className="mr-md-2 d-flex">
                                <InputGroup>
                                    <FormControl
                                        id="displayBox"
                                        aria-label="date-selection-input"
                                        type="text"
                                        className={
                                            isFiltered
                                                ? "default-white-color"
                                                : "default-grey-color"
                                        }
                                        aria-describedby="button-addon2"
                                        onClick={() => {
                                            setShowCalendar(!showCalendar);
                                        }}
                                        readOnly
                                        value={`${format(
                                            selectionRange.startDate,
                                            "dd/MM/yyyy"
                                        )} - ${format(
                                            selectionRange.endDate,
                                            "dd/MM/yyyy"
                                        )}`}
                                    />
                                    {showCalendar ? (
                                        <div
                                            id="calendar"
                                            aria-label="calendar"
                                        >
                                            <DateRange
                                                onChange={(i: any) =>
                                                    setSelectionRange(
                                                        i.selection
                                                    )
                                                }
                                                moveRangeOnFirstSelection={
                                                    false
                                                }
                                                ranges={[selectionRange]}
                                                rangeColors={["#345f8d"]}
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <InputGroup.Append>
                                        <Button
                                            className="btn btn-primary mr-md-3 filter-button"
                                            onClick={() => {
                                                filterPaymentDates(
                                                    selectionRange.startDate,
                                                    selectionRange.endDate
                                                );
                                                setShowCalendar(false);
                                            }}
                                        >
                                            Filter
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="search">
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    placeholder="Search.."
                                    aria-describedby="button-addon2"
                                    onChange={(e) => {
                                        setIsSearched(!!e.target.value);
                                        searchTransaction(e.target.value);
                                    }}
                                />
                            </InputGroup>
                        </div>
                    </Col>
                </Row>
                <Row className="cstm-table biliing-history mt-4">
                    <Col sm={12}>
                        <div className="table-head">
                            <Row className="no-checkbox">
                                <Col>
                                    Receipt Type{" "}
                                    {sortIcon("_subscription._name")}
                                </Col>
                                <Col>Period {sortIcon("_period")}</Col>
                                <Col>Transaction ID {sortIcon("uuid")}</Col>
                                <Col>
                                    Payment Date {sortIcon("create_time")}
                                </Col>
                                <Col>Amount {sortIcon("_amount")}</Col>
                                <Col md={{ span: 1 }}>
                                    Status {sortIcon("status")}
                                </Col>
                                <Col md={{ span: 1 }}></Col>
                            </Row>
                        </div>
                        {displayTransactions()}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default BillingHistory;
