import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import * as _ from "lodash";
import {
    useCreateDashboardStore,
    ColorPicker,
    getMQTTQueryByDevice,
    CreateChartState,
    CHART_NAME_PATTERN,
    CHART_ERROR_MESSAGE,
} from ".";
import DashboardCreateSelectActuatorModal from "./modal/SelectDevices";
import CreateChartModal from "./modal/CreateChart";
import { COLORS } from "constant";
import { startCase } from "lodash";

const ActuatorCreateData: CreateChartState = {
    title: "Creating Actuator Charts...",
    data: [],
    columns: [
        {
            key: "chart_name",
            name: "Chart Name",
        },
        {
            key: "device_name",
            name: "Actuator",
        },
        {
            key: "sensor_type",
            name: "Type",
        },
        {
            key: "gatewayName",
            name: "Gateway",
        },
        {
            key: "ldsu_uuid",
            name: "LDSU UUID",
        },
        {
            key: "color",
            name: "Color",
            render: (data: any) => (
                <div className="color-picker__toggle">
                    <div style={{ backgroundColor: data.color }} />
                </div>
            ),
        },
    ],
    transform: (data: any) => ({
        attributes: [
            {
                color: data.color,
                device_id: data.ldsu_uuid,
                gateway_id: data.gatewayId,
                name: data.sensor_type,
                said: data.SAID,
                show_alert: false,
                time_series: getMQTTQueryByDevice(data),
                unit: data.unit ?? "na",
            },
        ],
        source: "ACTUATOR",
        name: data.chart_name || "Single Chart",
        chart: "TIME",
    }),
    validate() {
        return this.data.map((item) => ({
            path: "",
            isValid: CHART_NAME_PATTERN.test(item.chart_name.trim()),
            message: CHART_ERROR_MESSAGE,
        }));
    },
};

const DashboardCreateActuatorComponent: React.FunctionComponent = () => {
    const [showSelectModal, setShowSelectModal] = React.useState(false);
    const [selectedDevices, setSelectedDevices] = React.useState<{
        [key: string]: any;
    }>({});
    const [formValues, setFormValues] = React.useState<{
        [key: string]: any;
    }>({});
    const { createModalShow, setCreateModalShow, createData } =
        useCreateDashboardStore();

    React.useEffect(() => {
        const createData: any[] = [];
        setCreateModalShow(false);

        const formValuesObject = {} as any;

        for (const key in formValues) {
            _.set(formValuesObject, String(key), formValues[key]);
        }

        for (const device_id in formValuesObject) {
            const device = selectedDevices[device_id];
            device &&
                createData.push({
                    ...device,
                    ...formValuesObject[device_id],
                });
        }

        useCreateDashboardStore.setState({
            createData: {
                ...ActuatorCreateData,
                data: createData,
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues]);

    React.useEffect(() => {
        useCreateDashboardStore.setState({
            createData: undefined,
        });

        // Setup default values for each devices
        for (const device_id in selectedDevices) {
            setFormValues((prev) => ({
                ...prev,
                [`${device_id}.chart_name`]:
                    selectedDevices[device_id].device_name,
                [`${device_id}.color`]: COLORS[0],
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDevices]);

    return (
        <React.Fragment>
            <Row>
                <Col sm="12">
                    <p>Actuator charts</p>
                    <Button
                        className="mt-3"
                        variant="primary"
                        onClick={() => setShowSelectModal(true)}
                    >
                        Select Actuators
                    </Button>
                    <DashboardCreateSelectActuatorModal
                        show={showSelectModal}
                        onHide={() => setShowSelectModal(false)}
                        values={selectedDevices}
                        onSave={(event, data) => setSelectedDevices(data)}
                        deviceType="Actuator"
                    />
                </Col>
            </Row>

            <Row className="mt-4 cstm-table">
                <Col sm={12}>
                    <Form id="chart-edit-form">
                        {!!Object.values(selectedDevices).length && (
                            <div className="table-head" key="head">
                                <Row className="no-checkbox">
                                    <Col>Actuator</Col>
                                    <Col>Chart Name</Col>
                                    <Col>Type</Col>
                                    <Col>Gateway</Col>
                                    <Col>LDSU UUID</Col>
                                    <Col>Color</Col>
                                    <Col md={{ span: 1 }}></Col>
                                </Row>
                            </div>
                        )}
                        {Object.values(selectedDevices).map((device, index) => (
                            <div
                                key={index}
                                id={device.device_id}
                                className="table-row"
                            >
                                <Row className="no-checkbox align-items-center">
                                    <Col>{device.device_name}</Col>
                                    <Col>
                                        <Form.Control
                                            name={`${device.device_id}.chart_name`}
                                            type="text"
                                            defaultValue={device.device_name}
                                            placeholder="Chart Name"
                                            onChange={(event) =>
                                                setFormValues((prev) => ({
                                                    ...prev,
                                                    [`${device.device_id}.chart_name`]:
                                                        event.target.value,
                                                }))
                                            }
                                        />
                                    </Col>
                                    <Col>{startCase(device.sensor_type)}</Col>
                                    <Col>{device.gatewayName}</Col>
                                    <Col>{device.ldsu_uuid}</Col>
                                    <Col>
                                        <ColorPicker
                                            onChange={(event, color) =>
                                                setFormValues({
                                                    ...formValues,
                                                    [`${device.device_id}.color`]:
                                                        color,
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col
                                        md={{ span: 1 }}
                                        className="d-flex justify-content-end"
                                    >
                                        <i
                                            className="material-icons cursor-pointer mr-2 remove-button-red"
                                            onClick={() => {
                                                setSelectedDevices(
                                                    _.omit(
                                                        selectedDevices,
                                                        device.device_id
                                                    )
                                                );
                                            }}
                                        >
                                            close
                                        </i>
                                    </Col>
                                </Row>
                            </div>
                        ))}
                    </Form>
                </Col>
            </Row>
            <CreateChartModal
                show={createModalShow && !_.isEmpty(createData)}
                onHide={() => setCreateModalShow(false)}
            />
        </React.Fragment>
    );
};

export default React.memo(DashboardCreateActuatorComponent);
