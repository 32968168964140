import React, { useEffect, useState, useContext, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Tab,
    Nav,
    InputGroup,
    Button,
    FormControl,
} from "react-bootstrap";
import SelectMemberModal from "components/modals/SelectMemberModal/SelectMemberModal";
import SelectRoleModal from "components/modals/SelectRoleModal/SelectRoleModal";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import { AppContext } from "context/appContext";
import UserGroupsService from "service/userGroupsService";
import { formatDate, isHttpSuccess } from "utils/functions";
import { HttpStatus, ORG_GROUP_NAME_MESSAGE, Patterns } from "constant";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { MemberDto, MemberGroupDto, RoleDto } from "types";
import { getMemberName } from "utils/organisationFunction";
import DefaultModal from "components/modals/ModalTemplate";
import useCollectSort from "hooks/useCollectSort";
import { get } from "lodash";
import ResourceNameField from "components/common/ResourceNameField";

type ContentDetailProps = {
    group: MemberGroupDto;
    onAddMember: (members: string[]) => void;
    onAddRole: (roles: string[]) => void;
    onRemoveMember: (member: string) => void;
    onRemoveRole: (role: string) => void;
    orgId: string;
};

type DeleteData = {
    name: string;
    id: string;
    type: "member" | "role";
};

const DefaultGroup: MemberGroupDto = {
    name: "",
    createTime: new Date(),
    modifyTime: new Date(),
    isDefault: false,
    uuid: "",
    members: [],
    roles: [],
};

const GroupDetails = () => {
    const params: any = useParams();
    const [group, setGroup] = useState<MemberGroupDto>(DefaultGroup);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isForbidden, setIsForbidden] = useState(false);
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);
    const { orgId, groupId } = params;
    const [isNameEditing, setIsNameEditing] = useState(false);
    const [groupName, setGroupName] = useState("");

    useEffect(() => {
        fetchGroup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupId, orgId]);

    useEffect(() => {
        setGroupName(group.name);
    }, [group]);

    const fetchGroup = async () => {
        const { status, data } = await UserGroupsService.getGroupById(
            orgId,
            groupId
        );
        if (status === HttpStatus.FORBIDDEN) {
            setIsForbidden(true);
        } else if (status === HttpStatus.OK) {
            const group = toCamelCaseObject<MemberGroupDto>(data);
            setGroup(group);
        }

        setIsPageLoading(false);
    };

    const handleClickEdit = () => {
        setIsNameEditing(true);
    };

    const handleGroupNameChange = (e: any) => {
        setGroupName(e.target.value);
    };

    const handleUpdateName = async () => {
        if (groupName === group.name) {
            setIsNameEditing(false);
            return;
        }
        const res = await UserGroupsService.updateGroupName(
            orgId,
            groupId,
            groupName
        );
        let message = "";
        if (isHttpSuccess(res.status)) {
            const group = toCamelCaseObject<MemberGroupDto>(res.data);
            setGroup(group);
            message = "The Group name has been updated successfully!";
        }

        setSimpleModalData({
            resObj: res,
            body: message,
        } as SimpleModalDataType);
    };

    const handleAddMember = async (members: string[]) => {
        const res = await UserGroupsService.addMembers(orgId, groupId, members);
        const { status, data } = res;
        let message = "";
        if (status === HttpStatus.CREATED) {
            fetchGroup();
            message = `${members.length} member(s) has been added successfully.`;
        } else {
            message = get(data, "description", "Unable to add member to group")
                //break down the email to new line
                .replaceAll("\n", "<br/>");
        }

        setSimpleModalData({
            resObj: res,
            body: message,
        } as SimpleModalDataType);
    };

    const handleAddRole = async (roleIds: string[]) => {
        const res = await UserGroupsService.addRoles(orgId, groupId, roleIds);
        let message = "";

        if (res.status === HttpStatus.CREATED) {
            await fetchGroup();
            message = `${roleIds.length} role(s) has been added successfully.`;
        }

        setSimpleModalData({
            resObj: res,
            body: message,
        } as SimpleModalDataType);
    };

    const handleRemoveMember = async (memberEmail: string) => {
        const removeRes: any = await UserGroupsService.removeMembers(
            orgId,
            groupId,
            [memberEmail]
        );
        let message = "";

        if (removeRes.status === HttpStatus.CREATED) {
            await fetchGroup();
            message = "Member has been removed successfully.";
        }

        setSimpleModalData({
            resObj: removeRes,
            body: message,
        } as SimpleModalDataType);
    };

    const handleRemoveRole = async (id: string) => {
        const removeRes: any = await UserGroupsService.removeRoles(
            orgId,
            groupId,
            [id]
        );
        let message = "";

        if (isHttpSuccess(removeRes.status)) {
            await fetchGroup();
            message = "Role has been removed successfully.";
        }

        setSimpleModalData({
            resObj: removeRes,
            body: message,
        } as SimpleModalDataType);
    };

    return (
        <ContentWrapper
            simpleModalData={simpleModalData}
            title={group.name || "Group Details"}
            isLoading={isPageLoading}
            isForbiddenResource={isForbidden}
            routes={[
                {
                    name: "Organisation",
                    url: `/organisation/${orgId}`,
                },
                {
                    name: "Group",
                },
            ]}
        >
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm="12" className="group-detail-head">
                            <h5 className="page-title overflow-text">
                                {group.name || "-"}
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item
                                    href={`/organisation/${orgId}`}
                                >
                                    Organisation
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>Group</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ResourceNameField
                                input={groupName}
                                updateField={handleGroupNameChange}
                                isNameEditing={isNameEditing}
                                toggleEditButton={handleClickEdit}
                                toggleUpdateButton={handleUpdateName}
                                resourceTitle="Group Name"
                                resourcePermission="organisation:update"
                                validationMessage={ORG_GROUP_NAME_MESSAGE}
                                validationRegex={Patterns.orgNamePattern}
                                shouldDisableEdit={group.isDefault}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <div className="page-tabs">
                                <Tab.Container
                                    id="left-tabs-example"
                                    defaultActiveKey="members"
                                >
                                    <Row>
                                        <Col sm={12}>
                                            <Nav>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="members">
                                                        <h6>
                                                            Members{" "}
                                                            <span className="count">
                                                                {
                                                                    group
                                                                        .members
                                                                        .length
                                                                }
                                                            </span>
                                                        </h6>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="roles">
                                                        <h6>
                                                            Roles{" "}
                                                            <span className="count">
                                                                {
                                                                    group.roles
                                                                        .length
                                                                }
                                                            </span>
                                                        </h6>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>

                                        <ContentDetails
                                            group={group}
                                            onAddMember={handleAddMember}
                                            onAddRole={handleAddRole}
                                            onRemoveMember={handleRemoveMember}
                                            onRemoveRole={handleRemoveRole}
                                            orgId={orgId}
                                        />
                                    </Row>
                                </Tab.Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ContentWrapper>
    );
};

const defaultSearchRef: any = {};

const ContentDetails = (props: ContentDetailProps) => {
    const {
        onAddMember,
        onAddRole,
        onRemoveMember,
        onRemoveRole,
        group,
        orgId,
    } = props;
    const { members, roles, isDefault } = group;
    const { storeData } = useContext(AppContext);
    const [showModalMember, setShowModalMember] = useState(false);
    const [showModalRole, setShowModalRole] = useState(false);
    const [memberIds, setMemberIds] = useState<string[]>([]);
    const [roleIds, setRoleIds] = useState<string[]>([]);
    const [orgRoles, updateOrgRoles] = useState<any[]>([]);
    const [memberList, setMemberList] = useState<MemberDto[]>(members);
    const [roleList, setRoleList] = useState<RoleDto[]>(roles);
    const searchRef = useRef(defaultSearchRef);
    const searchRefRole = useRef(defaultSearchRef);
    const { organization } = storeData;
    const { owner } = organization.currentOrgInfo;
    const { userData: loginUser } = storeData.user;

    const [showConfirmModalForOne, updateShowConfirmModalForOne] =
        useState(false);
    const [confirmModalTitle, setConfirmModalTitle] = useState("");
    const [confirmModalContent, setConfirmModalContent] = useState<
        string | JSX.Element
    >("");
    const [toDelete, updateToDelete] = useState<DeleteData>({
        name: "",
        id: "",
        type: "member",
    });
    const { data: memberCollect, sortIcon } = useCollectSort(memberList);
    const { data: roleCollect, sortIcon: sortRoleIcon } =
        useCollectSort(roleList);

    useEffect(() => {
        const ids = members.map((member) => member.username);
        setMemberIds(ids);
        setMemberList(members);
    }, [members]);

    useEffect(() => {
        const ids = roles.map((role) => role.uuid);
        setRoleIds(ids);
        setRoleList(roles);
    }, [roles]);

    useEffect(() => {
        const validRoles = organization.currentOrgRoles.filter(
            (role: any) => !role.isDefault
        );
        updateOrgRoles(validRoles);
    }, [organization.currentOrgRoles]);

    const handleModalAddMemberClose = (data: any) => {
        setShowModalMember(false);
        if (data.length === 0) return;
        onAddMember(data);
    };

    const handleModalAddRoleClose = (data: any) => {
        setShowModalRole(false);
        if (data.length === 0) return;
        onAddRole(data);
    };

    const handleSearchMembers = (value: string) => {
        const _searchStr = value
            .toLowerCase()
            .replace(/\s{2,}/, "")
            .trim();
        if (!_searchStr) {
            setMemberList(members);
            return;
        }
        const results = members.filter((member) => {
            return getMemberName(member).toLowerCase().includes(_searchStr);
        });
        setMemberList(results);
    };

    const handleSearchRole = (value: string) => {
        const _searchStr = value
            .toLowerCase()
            .replace(/\s{2,}/, "")
            .trim();
        if (!_searchStr) {
            setRoleList(roles);
            return;
        }
        const results = roles.filter((group: any) => {
            const _name = group.name.toLowerCase();
            return _name.includes(_searchStr);
        });
        setRoleList(results);
    };

    const handleCloseConfirmModalForOne = async (status = "cancel") => {
        updateShowConfirmModalForOne(false);
        if (status === "ok") {
            if (toDelete.type === "member") {
                await onRemoveMember(toDelete.id);
                return;
            }
            await onRemoveRole(toDelete.id);
        }
    };

    const showConfirmModal = (data: DeleteData) => {
        updateShowConfirmModalForOne(true);
        setConfirmModalTitle(`Remove ${data.type.capitalize()} from Group`);
        const deleteType = data.type === "member" ? "member" : "the role";
        setConfirmModalContent(() => (
            <>
                Do you want to remove {deleteType} <strong>{data.name}</strong>{" "}
                from this group?
            </>
        ));
        updateToDelete(data);
    };

    return (
        <React.Fragment>
            <Col sm={12}>
                <Tab.Content>
                    <Tab.Pane eventKey="members">
                        <>
                            <Row className="mt-4">
                                <Col
                                    sm={12}
                                    className="d-md-flex justify-content-end table-head-options order-md-2 order-1"
                                >
                                    <HoverAuthorizeTooltip
                                        permission="organisation:update"
                                        className="mr-3 mb-3"
                                    >
                                        <Button
                                            variant="primary"
                                            onClick={() =>
                                                setShowModalMember(true)
                                            }
                                        >
                                            + ADD MEMBERS
                                        </Button>
                                    </HoverAuthorizeTooltip>
                                    <div className="search">
                                        <InputGroup>
                                            <FormControl
                                                type="text"
                                                placeholder="Search.."
                                                ref={searchRef}
                                                onChange={(e: any) => {
                                                    handleSearchMembers(
                                                        e.target.value
                                                    );
                                                }}
                                                aria-describedby="button-addon2"
                                            />
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="cstm-table mt-4">
                                <Col sm={12}>
                                    <div className="table-head">
                                        <Row className="align-items-center group-item">
                                            <Col
                                                md
                                                xs={{
                                                    span: 10,
                                                    order: 1,
                                                }}
                                                className="group-item-name"
                                            >
                                                Name {sortIcon("name")}
                                            </Col>
                                            <Col
                                                md={{ order: 2 }}
                                                xs={{
                                                    span: 12,
                                                    order: 3,
                                                }}
                                            >
                                                Added to this group on
                                                {sortIcon("modifyTime")}
                                            </Col>
                                            <Col
                                                md={{
                                                    span: 1,
                                                    order: 3,
                                                }}
                                                xs={{
                                                    span: 2,
                                                    order: 2,
                                                }}
                                                className="text-center"
                                            ></Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            {memberCollect.map((member) => (
                                <Row
                                    className="cstm-table mt-1"
                                    key={member.uuid}
                                >
                                    <Col sm={12}>
                                        <div className="table-row">
                                            <Row className="align-items-center group-item">
                                                <Col
                                                    md
                                                    xs={{
                                                        span: 10,
                                                        order: 1,
                                                    }}
                                                    className="group-item-name overflow-text"
                                                >
                                                    <Link
                                                        to={`/organisation/${orgId}/member/${member.uuid}`}
                                                    >
                                                        {getMemberName(member)}
                                                    </Link>
                                                </Col>
                                                <Col
                                                    md={{ order: 2 }}
                                                    xs={{
                                                        span: 12,
                                                        order: 3,
                                                    }}
                                                >
                                                    <div className="ginfo">
                                                        <span className="reading-label default">
                                                            Added to this group
                                                            on{" "}
                                                        </span>
                                                        {formatDate(
                                                            member.modifyTime
                                                        )}
                                                    </div>
                                                </Col>
                                                <Col
                                                    md={{
                                                        span: 1,
                                                        order: 3,
                                                    }}
                                                    xs={{
                                                        span: 2,
                                                        order: 2,
                                                    }}
                                                    className="text-center"
                                                >
                                                    {(member.username === owner
                                                        ? false
                                                        : loginUser.email ===
                                                          member.username
                                                        ? false
                                                        : true) && (
                                                        <HoverAuthorizeTooltip
                                                            permission="organisation:update"
                                                            className="text-left"
                                                        >
                                                            <Button
                                                                variant=""
                                                                className="btn-remove remove-group-member"
                                                                onClick={() =>
                                                                    showConfirmModal(
                                                                        {
                                                                            name: getMemberName(
                                                                                member
                                                                            ),
                                                                            id: member.username,
                                                                            type: "member",
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                <span className="material-icons">
                                                                    close
                                                                </span>
                                                            </Button>
                                                        </HoverAuthorizeTooltip>
                                                    )}
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            ))}

                            {memberList.length === 0 && (
                                <Row className="cstm-table mt-1">
                                    <Col className="text-center">
                                        <div className="table-row">
                                            <Row className="align-items-center">
                                                <Col>
                                                    No members to display.
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </>
                    </Tab.Pane>
                    <Tab.Pane eventKey="roles">
                        <Row className="mt-4">
                            <Col
                                sm={12}
                                className="d-md-flex justify-content-end table-head-options order-md-2 order-1"
                            >
                                <HoverAuthorizeTooltip
                                    permission="organisation:update"
                                    className="mr-3 mb-3"
                                >
                                    <Button
                                        variant="primary"
                                        className={
                                            isDefault ? "d-none" : "d-block"
                                        }
                                        onClick={() => setShowModalRole(true)}
                                    >
                                        + ADD ROLES
                                    </Button>
                                </HoverAuthorizeTooltip>
                                <div className="search">
                                    <InputGroup>
                                        <FormControl
                                            type="text"
                                            placeholder="Search.."
                                            ref={searchRefRole}
                                            onChange={(e: any) => {
                                                handleSearchRole(
                                                    e.target.value
                                                );
                                            }}
                                            aria-describedby="button-addon2"
                                        />
                                    </InputGroup>
                                </div>
                            </Col>
                        </Row>
                        <Row className="cstm-table mt-4">
                            <Col sm={12}>
                                <div className="table-head">
                                    <Row className="align-items-center group-item">
                                        <Col
                                            md={{ order: 1 }}
                                            xs={{
                                                span: 10,
                                                order: 1,
                                            }}
                                            className="overflow-text group-item-name"
                                        >
                                            Role Name {sortRoleIcon("name")}
                                        </Col>
                                        <Col
                                            md={{ order: 2 }}
                                            xs={{
                                                span: 12,
                                                order: 3,
                                            }}
                                        >
                                            Type {sortRoleIcon("name")}
                                        </Col>
                                        <Col
                                            md={{
                                                span: 1,
                                                order: 3,
                                            }}
                                            xs={{
                                                span: 2,
                                                order: 2,
                                            }}
                                        >
                                            <div></div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        {roleCollect.map((role) => (
                            <Row className="cstm-table mt-1" key={role.uuid}>
                                <Col sm={12}>
                                    <div className="table-row">
                                        <Row className="align-items-center group-item">
                                            <Col
                                                md={{ order: 1 }}
                                                xs={{
                                                    span: 10,
                                                    order: 1,
                                                }}
                                                className="overflow-text group-item-name"
                                            >
                                                <Link
                                                    to={`/organisation/${orgId}/role/${role.uuid}`}
                                                >
                                                    {role.name}
                                                </Link>
                                            </Col>
                                            <Col
                                                md={{ order: 2 }}
                                                xs={{
                                                    span: 12,
                                                    order: 3,
                                                }}
                                            >
                                                <div className="ginfo">
                                                    {role.isDefault ? (
                                                        <span className="default">
                                                            Default Role
                                                        </span>
                                                    ) : (
                                                        <span className="custom">
                                                            Custom Role
                                                        </span>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col
                                                md={{
                                                    span: 1,
                                                    order: 3,
                                                }}
                                                xs={{
                                                    span: 2,
                                                    order: 2,
                                                }}
                                                className="text-center"
                                            >
                                                {!role.isDefault && (
                                                    <HoverAuthorizeTooltip permission="organisation:update">
                                                        <Button
                                                            variant=""
                                                            className="btn-remove remove-group-role"
                                                            onClick={() =>
                                                                showConfirmModal(
                                                                    {
                                                                        name: role.name,
                                                                        id: role.uuid,
                                                                        type: "role",
                                                                    }
                                                                )
                                                            }
                                                        >
                                                            <span className="material-icons">
                                                                close
                                                            </span>
                                                        </Button>
                                                    </HoverAuthorizeTooltip>
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        ))}

                        {roleList.length === 0 && (
                            <Row className="cstm-table mt-1">
                                <Col className="text-center">
                                    <div className="table-row">
                                        <Row className="align-items-center">
                                            <Col>No roles to display.</Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </Tab.Pane>
                </Tab.Content>
            </Col>
            <SelectMemberModal
                members={organization.currentOrgMembers}
                values={memberIds}
                show={showModalMember}
                onClose={handleModalAddMemberClose}
            />
            <SelectRoleModal
                roles={orgRoles}
                values={roleIds}
                show={showModalRole}
                onClose={handleModalAddRoleClose}
            />
            {/* Modal Confirm Delete*/}
            <DefaultModal
                modalShow={showConfirmModalForOne}
                setModalShow={updateShowConfirmModalForOne}
                modalType="custom"
                modalTitle={confirmModalTitle}
                modalIcon="help_outline"
                modalContent={confirmModalContent}
                modalButton={() => (
                    <>
                        <Button
                            variant="secondary"
                            onClick={() => handleCloseConfirmModalForOne()}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => handleCloseConfirmModalForOne("ok")}
                        >
                            REMOVE
                        </Button>
                    </>
                )}
            ></DefaultModal>
        </React.Fragment>
    );
};

export default GroupDetails;
