import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { ResponseDto } from "types";
import {
    createResponseStandard,
    createErrorResponseStandard,
} from "utils/functions";
import AxiosInstance from "./axiosInstance";
import { apiFactory } from "generated";
import { GetSubscriptionsData } from "generated/models";
import { BillingAddressRequestBody } from "types/Subscription";

let subscriptionClient = apiFactory.getSubscriptionsInstance(AxiosInstance);
let paymentClient = apiFactory.getPaymentInstance(AxiosInstance);
let walletClient = apiFactory.getWalletInstance(AxiosInstance);

const getSubscriptionClient = () => {
    if (
        !subscriptionClient.http.instance.defaults.headers.common[
            "Authorization"
        ]
    ) {
        subscriptionClient = apiFactory.getSubscriptionsInstance(AxiosInstance);
    }

    return subscriptionClient;
};

const getWalletClient = () => {
    if (!walletClient.http.instance.defaults.headers.common["Authorization"]) {
        walletClient = apiFactory.getWalletInstance(AxiosInstance);
    }

    return walletClient;
};

const getPaymentClient = () => {
    if (!paymentClient.http.instance.defaults.headers.common["Authorization"]) {
        paymentClient = apiFactory.getPaymentInstance(AxiosInstance);
    }

    return paymentClient;
};

export const getAllPlans = async () => {
    try {
        const response = await getSubscriptionClient().getAvailablePlans();
        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const getClientToken = async () => {
    try {
        const response = await getPaymentClient().getBtClientToken();
        return response;
    } catch (err) {
        return err;
    }
};

export const getSubscriptionInfo = async (): Promise<
    AxiosResponse<GetSubscriptionsData> | any
> => {
    try {
        const response = await getSubscriptionClient().getSubscriptions();
        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const getAllSubscription = async () => {
    const res = await getSubscriptionInfo();
    const subscriptions = res?.data?.filter(
        (s: any) => s.type_ === "SUBSCRIPTION"
    );
    return subscriptions;
};

export const getLatestSubscription = async () => {
    const res: any = await getAllSubscription();
    const latestSubscription = _.orderBy(res, ["create_time"], ["desc"])[0];
    return latestSubscription;
};

export const getSubscriptionById = async (subscriptionId: string) => {
    try {
        const res = await getSubscriptionClient().getSubscriptionById(
            subscriptionId
        );
        return res;
    } catch (err) {
        return err;
    }
};

export const updatePaymentDetails = async (body: {
    subscriptionId: string;
    nonce: string;
}) => {
    try {
        const res = await getSubscriptionClient().updateSubscriptionPaymentById(
            body.subscriptionId,
            { nonce: body.nonce }
        );
        return res;
    } catch (err) {
        return err;
    }
};

export const getSubscriptionPrice = async (
    subscriptionId: string,
    country: string
) => {
    try {
        const response = await getSubscriptionClient().getSubscriptionPriceById(
            subscriptionId,
            {
                country,
            }
        );
        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const createSubscription = async (params: {
    addressUUID: string;
    customer_uuid: string;
}) => {
    try {
        const response = await getSubscriptionClient().createSubscription({
            address_uuid: params.addressUUID,
            customer_uuid: params.customer_uuid,
        });
        return response;
    } catch (err) {
        return err;
    }
};

export const activateSubscription = async (
    subscriptionUUID: string,
    nonce: string
) => {
    try {
        const response = await getSubscriptionClient().paySubscriptionById(
            subscriptionUUID,
            {
                nonce,
            }
        );
        return response;
    } catch (err) {
        return err;
    }
};

export const createAddress = async (body: BillingAddressRequestBody) => {
    try {
        const response = await getPaymentClient().createAddress(body);
        return response;
    } catch (err) {
        return err;
    }
};

export const getAddress = async () => {
    try {
        const response = await getPaymentClient().getAddresses();
        return response;
    } catch (err) {
        return err;
    }
};

export const updateAddress = async (
    address_uuid: string,
    body: BillingAddressRequestBody
) => {
    try {
        const response = await getPaymentClient().updateAddressById(
            address_uuid,
            { ...body, update_template: true }
        );
        return response;
    } catch (err) {
        return err;
    }
};

export const getAllTransactions = async () => {
    try {
        const response = await getPaymentClient().getTransactions();
        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const getAllAddOns = async () => {
    try {
        const response = await getSubscriptionClient().getAvailableAddOn();
        return response;
    } catch (err) {
        return err;
    }
};

export const getAddonPrice = async (body: {
    addon_uuid: string;
    country: string;
    coupon?: string;
    quantity?: string;
}) => {
    try {
        const response = await getSubscriptionClient().getAvailableAddOnById(
            body.addon_uuid,
            {
                country: body.country,
                ...(body.coupon && { coupon: body.coupon }),
                ...(body.quantity && { quantity: body.quantity }),
            }
        );

        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const buyAddon = async (
    addonUUID: string,
    body: {
        quantity: number;
        nonce: string;
        address_uuid: string;
        coupon?: string;
    }
) => {
    try {
        const response = await getSubscriptionClient().createStandaloneAddOn(
            addonUUID,
            body
        );
        return response;
    } catch (err) {
        return err;
    }
};

export const getWallet = async () => {
    try {
        const response = await getWalletClient().getWallet();
        return response;
    } catch (err) {
        return err;
    }
};

export const getWalletStatus = async (
    organization_id: string
): Promise<ResponseDto<"normal" | "low" | null>> => {
    try {
        const response =
            await getWalletClient().getWalletStatusByOrganizationId({
                organization_id,
            });

        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err as AxiosError);
    }
};

export const getFiles = async (
    subscription_uuid: string,
    file_name: string
) => {
    try {
        const response = await getSubscriptionClient().getInvoiceByFileName(
            subscription_uuid,
            file_name,
            { format: "arraybuffer" }
        );
        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const cancelSubscription = async (subscription_uuid: string) => {
    try {
        const response = await getSubscriptionClient().cancelSubscriptionById(
            subscription_uuid
        );
        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const deleteUnpaidSubscription = async (subscription_uuid: string) => {
    try {
        const response = await getSubscriptionClient().deleteSubscriptionById(
            subscription_uuid
        );
        return response;
    } catch (err) {
        return err;
    }
};

export const getAllCoupons = async () => {
    try {
        const response = await getSubscriptionClient().getCoupons();
        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const getCouponInfoByName = async (coupon_name: string) => {
    try {
        const response = await getSubscriptionClient().getCouponByName(
            coupon_name
        );
        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const updateCoupon = async (params: {
    subscription_uuid: string;
    add: any;
    remove: any;
}) => {
    try {
        const payload = {
            add: params.add,
            remove: params.remove,
        };
        const response =
            await getSubscriptionClient().updateSubscriptionDiscountById(
                params.subscription_uuid,
                payload
            );
        return createResponseStandard(response);
    } catch (err) {
        return createErrorResponseStandard(err);
    }
};

export const validateCustomerCodePRN = async (params: {
    id: string;
    prn: string;
}) => {
    try {
        const response = await getSubscriptionClient().validateCustomer({
            code: params.id,
            project: params.prn,
        });
        return response;
    } catch (err) {
        return err;
    }
};

export const getTokenConversionRate = async (): Promise<AxiosResponse<any>> => {
    try {
        const response = await AxiosInstance.get(
            `/metering/info/metering.json`
        );
        return response;
    } catch (err: any) {
        return err;
    }
};

export const updateSubscriptionAddonById = async (params: {
    subscriptionId: string;
    addonId: string;
    action: string;
    quantity?: number;
}) => {
    let data: any;

    if (params.action === "add") {
        data = {
            add: [
                {
                    uuid: params.addonId,
                    billing_cycles: -1,
                    quantity: params.quantity,
                },
            ],
        };
    } else if (params.action === "remove") {
        data = {
            remove: [
                {
                    uuid: params.addonId,
                },
            ],
        };
    }

    try {
        const response =
            await getSubscriptionClient().updateSubscriptionAddonById(
                params.subscriptionId,
                data
            );

        return createResponseStandard(response);
    } catch (err: any) {
        return createErrorResponseStandard(err);
    }
};
