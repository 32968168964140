import { Col, Dropdown } from "react-bootstrap";
import ReactECharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { getFirstSubscriptionInfo, isHttpSuccess } from "../../utils/functions";
import moment from "moment";
import "assets/css/subscription.css";
import { meteringService } from "service/meteringService";
import { useAppContext } from "context/appContext";
import { faker } from "@faker-js/faker";
import { getAPIError, showErrorAlert } from "utils/alert";
interface MonthlyDataState {
    emailData: number[];
    smsData: number[];
    notificationData: number[];
    dashboardData: number[];
    offloadData: number[];
}

interface MonthlyDataBody {
    [key: string]: number[];
}

interface TokenLookup {
    [key: string]: string;
    emailData: string;
    smsData: string;
    notificationData: string;
    dashboardData: string;
    offloadData: string;
}
const OverviewSpending = (props: any) => {
    const { storeData } = useAppContext();

    const [monthlyData, setMonthlyData] = useState<MonthlyDataState>({
        emailData: [],
        smsData: [],
        notificationData: [],
        dashboardData: [],
        offloadData: [],
    });
    const yearStart = moment().utcOffset(0, true).startOf("year");
    const yearEnd = moment().utcOffset(0, true).endOf("year");
    const [yearDropdown, setYearDropdown] = useState([]);
    const [displayedYear, setDisplayedYear] = useState({
        yearStart: yearStart,
        yearEnd: yearEnd,
    });

    useEffect(() => {
        const selected: any = {
            year: displayedYear.yearStart.year(),
        };
        const getOverviewSpend: any = async () => {
            const res: any = await meteringService.getMeteringInfo({
                year: selected.year,
                in_token: true,
            });

            if (isHttpSuccess(res.status)) {
                const listOfOwnerOrg = Object.keys(res.data);

                const monthlyDataBody: MonthlyDataBody = {
                    emailData: [],
                    smsData: [],
                    notificationData: [],
                    dashboardData: [],
                    offloadData: [],
                };

                const tokenLookup: TokenLookup = {
                    emailData: "email",
                    smsData: "sms",
                    notificationData: "notification",
                    dashboardData: "dashboard",
                    offloadData: "data offload",
                };

                listOfOwnerOrg.forEach((orgId: string) => {
                    for (const m in res.data[orgId]) {
                        for (const t in tokenLookup) {
                            monthlyDataBody[t].length === 0
                                ? monthlyDataBody[t].push(
                                      res.data[orgId][m][tokenLookup[t]] || 0
                                  )
                                : (monthlyDataBody[t][Number(m) - 1] =
                                      (monthlyDataBody[t][Number(m) - 1] || 0) +
                                      (res.data[orgId][m][tokenLookup[t]] ||
                                          0));
                        }
                    }
                });

                setMonthlyData((prevState) => ({
                    ...prevState,
                    emailData: monthlyDataBody.emailData,
                    smsData: monthlyDataBody.smsData,
                    notificationData: monthlyDataBody.notificationData,
                    dashboardData: monthlyDataBody.dashboardData,
                    offloadData: monthlyDataBody.offloadData,
                }));
            } else {
                showErrorAlert(
                    getAPIError(
                        res,
                        "Unable to fetch metering information. Please try again."
                    )
                );
            }
        };
        getOverviewSpend();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayedYear]);

    useEffect(() => {
        const { subList } = storeData.subscription;
        const { startYear: firstSubYear } = getFirstSubscriptionInfo(subList);

        const subDateStart = moment({
            year: firstSubYear,
        })
            .utcOffset(0, true)
            .startOf("year");

        const subDateEnd = moment({
            year: firstSubYear,
        })
            .utcOffset(0, true)
            .endOf("year");

        const dropdownOptions: any = [
            {
                yearStart: subDateStart,
                yearEnd: subDateEnd,
            },
        ];

        let cloneYearStart = subDateStart.clone();
        while (cloneYearStart.isBefore(yearStart)) {
            cloneYearStart = cloneYearStart.clone().add(1, "year");
            dropdownOptions.push({
                yearStart: cloneYearStart,
                yearEnd: cloneYearStart.clone().endOf("year"),
            });
        }
        setYearDropdown(dropdownOptions);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const option = {
        backgroundColor: "#2f3a51",
        color: ["#EE9EC6", "#F6C39D", "#FFEFB5", "#50DAF4", "#84F5E8"],
        legend: {
            data: [
                "Email",
                "SMS",
                "Notification",
                "Dashboard Data",
                "Data Download",
            ],
            bottom: 0,
            left: 10,
            textStyle: {
                color: "#ffffff",
                fontFamily: "Roboto",
                fontSize: 14,
                fontWeight: 400,
            },
            padding: 0,
            itemGap: 10,
            itemWidth: 10,
            itemHeight: 10,
        },

        tooltip: {},
        xAxis: {
            data: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
            boundaryGap: true,
            axisLine: {
                lineStyle: {
                    color: "#48597D",
                },
            },
        },
        yAxis: {
            splitLine: {
                show: true,
                lineStyle: {
                    color: "#4B5569",
                },
            },
        },
        grid: {
            bottom: 50,
            left: 10,
            right: 30,
            top: 30,
            containLabel: true,
        },

        series: [
            {
                name: "Email",
                type: "bar",
                stack: "one",
                barWidth: 25,
                data: monthlyData.emailData,
            },
            {
                name: "SMS",
                type: "bar",
                stack: "one",
                barWidth: 25,
                data: monthlyData.smsData,
            },
            {
                name: "Notification",
                type: "bar",
                stack: "one",
                barWidth: 25,
                data: monthlyData.notificationData,
            },
            {
                name: "Dashboard Data",
                type: "bar",
                stack: "one",
                barWidth: 25,
                data: monthlyData.dashboardData,
            },
            {
                name: "Data Download",
                type: "bar",
                stack: "one",
                barWidth: 25,
                data: monthlyData.offloadData,
            },
        ],
    };

    const renderYear = () => {
        const dropdown = yearDropdown.map((year: any) => {
            return (
                <Dropdown.Item
                    key={faker.datatype.uuid()}
                    onClick={() => {
                        setDisplayedYear({
                            yearStart: year.yearStart,
                            yearEnd: year.yearEnd,
                        });
                    }}
                >
                    {year.yearStart.format("YYYY")}
                </Dropdown.Item>
            );
        });
        return dropdown;
    };
    return (
        <>
            <Col lg={8} md={6} sm={6} xs={12} className="mb-3">
                <div className="monthly-spending form-box overview">
                    <h5>Overview</h5>
                    <Dropdown alignRight className="monthlyYearlyDropdown">
                        <Dropdown.Toggle variant="" className="date-filter">
                            <span className="mr-1">Show Overview</span>{" "}
                            {displayedYear.yearStart.format("YYYY")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>{renderYear()}</Dropdown.Menu>
                    </Dropdown>
                    <ReactECharts
                        className="chart-box"
                        option={option}
                        theme={"dark"}
                    />
                </div>
            </Col>
        </>
    );
};

export default OverviewSpending;
