import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Button, Form, Modal, FormControl, Row, Col } from "react-bootstrap";
import {
    getAllRegistry,
    getLDSUListByGatewayIds,
} from "service/gatewayService";
import { updateFormFields, getGeneralColProps } from "utils/react-bootstrap";
import { styled } from "@mui/system";
import styledConst from "styles";
import { LDSUTypeDict } from "constant";
import { isHttpSuccess } from "utils/functions";

const StyledContainer = styled(Modal)`
    .modal-body {
        .filter-container {
            color: ${styledConst.thirdTextColor};

            .filterBy {
                background: #f8f8f8;
                border: 1px solid #eaeaea;
                border-radius: 4px;
                margin: 10px 0px;
                align-items: center;
                padding: 10px;

                select {
                    height: fit-content;
                    padding: 5px;
                    background: white;
                    border: 1px solid #ced4da;
                    border-radius: 3px;
                }
            }
        }

        .scroll-multiple-list {
            max-height: 55vh;
        }

        .warning-message {
            color: ${styledConst.warningTextColor};
        }
    }
`;

const serviceDict: { [key: string]: any } = {
    [LDSUTypeDict.LDSU.label]: {
        title: "Select LDSU",
        // allDeviceLabel: "All Sensors",
        getListByGwId: getLDSUListByGatewayIds,
        getListType: () => [],
    },
};

type LDSUSelectModalProps = {
    isVisible: boolean;
    onClose: () => void;
    onFinish: (unit: any) => void;
    defaultSearchValue: any;
    unitType: string;
};

const LDSUSelectModal = (props: LDSUSelectModalProps) => {
    const {
        isVisible,
        onClose,
        onFinish,
        defaultSearchValue = {
            gatewayId: "",
            sensorType: "",
            search: "",
        },
        unitType = LDSUTypeDict.LDSU.label,
    } = props;

    const { getListByGwId, title } = serviceDict[unitType];

    const [searchValues, setSearchValue] = useState(defaultSearchValue);
    const [isLoading, setIsLoading] = useState(true);
    const [registries, setRegistries] = useState([]);
    const [units, setUnits] = useState([]);
    const [_units, _setUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState<null | any>(null);
    const fetchAll = async () => {
        setIsLoading(true);
        getAllRegistry().then((resAllRedisTry: any) => {
            let gatewayIds = [];
            if (isHttpSuccess(resAllRedisTry.status)) {
                const { data = [] } = resAllRedisTry;
                const onboardedGateways = data.filter(
                    ({ info }: { info: any }) => info
                );
                gatewayIds = onboardedGateways.map(
                    ({ gateway_id }: any) => gateway_id
                );
                setRegistries(onboardedGateways);
            }
            getListByGwId(gatewayIds).then((_units: any) => {
                setUnits(_units);
                _setUnits(_units);
                setIsLoading(false);
            });
        });
    };
    //
    useEffect(() => {
        if (isVisible) {
            fetchAll();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    useEffect(() => {
        const { gatewayId = "", sensorType = "", search = "" } = searchValues;
        setSelectedUnit(null);
        _setUnits(
            [...(units || [])]
                .filter(
                    ({ gatewayId: _gatewayId }) =>
                        gatewayId === "" || gatewayId === _gatewayId
                )
                .filter(
                    ({ CLS: _sensorType }) =>
                        sensorType === "" || sensorType === _sensorType
                )
                .filter(
                    ({ name }: any) =>
                        search === "" ||
                        name.toLowerCase().indexOf(search.toLowerCase()) >= 0
                )
        );
    }, [searchValues, units]);

    const selectSensor = (i: string) => {
        setSelectedUnit(i);
    };
    return (
        <StyledContainer
            show={isVisible}
            onHide={onClose}
            animation={false}
            centered
        >
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <Button variant="" className="close-button" onClick={onClose}>
                    <span className="material-icons">close</span>
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Form className="filter-container">
                    <Row className="filterBy">
                        <Col {...getGeneralColProps(2)}>Filter by:</Col>
                        <Col {...getGeneralColProps(5)}>
                            <FormControl
                                as="select"
                                aria-label="org-name"
                                name="gatewayId"
                                disabled={isLoading}
                                // placeholder={allDeviceLabel}
                                value={searchValues.gatewayId}
                                onChange={(e: any) =>
                                    updateFormFields(
                                        e,
                                        searchValues,
                                        setSearchValue
                                    )
                                }
                            >
                                <option value="">All Gateways</option>

                                {(registries || []).map(
                                    ({ name, gateway_id: gatewayId }: any) => {
                                        return (
                                            <option
                                                key={gatewayId}
                                                value={gatewayId}
                                            >
                                                {name}
                                            </option>
                                        );
                                    }
                                )}
                            </FormControl>
                        </Col>
                    </Row>
                    <Row>
                        <FormControl
                            type="text"
                            name="search"
                            placeholder="Search"
                            className="mb-4"
                            onChange={(e) =>
                                updateFormFields(
                                    e,
                                    searchValues,
                                    setSearchValue
                                )
                            }
                        />
                    </Row>
                </Form>

                {isLoading ? (
                    <div className="warning-message">loading...</div>
                ) : units.length === 0 ? (
                    <div className="error-message">
                        You don't have any device connected yet. Please ensure
                        your gateway is onboarded.
                    </div>
                ) : (
                    <div className="scroll-multiple-list float-left">
                        {_units.map((sensor: any = {}, i: number) => {
                            const { ldsu_uuid: _ldsu_uuid, SAID, bus } = sensor;
                            return (
                                <div
                                    key={`${bus}_${_ldsu_uuid}_${SAID}`}
                                    className="modal-option"
                                >
                                    <Form.Check
                                        name="selected-sensor"
                                        value={`${bus}_${_ldsu_uuid}_${SAID}`}
                                        checked={
                                            selectedUnit &&
                                            `${bus}_${_ldsu_uuid}_${SAID}` ===
                                                `${selectedUnit.bus}_${selectedUnit.ldsu_uuid}_${selectedUnit.SAID}`
                                        }
                                        custom
                                        type="radio"
                                        id={`${bus}_${_ldsu_uuid}_${SAID}`}
                                        onChange={(e) => {
                                            selectSensor(sensor);
                                        }}
                                        label={
                                            <div
                                                onClick={(e) => {
                                                    selectSensor(sensor);
                                                }}
                                            >
                                                <h4>{sensor.name}</h4>
                                                <p>
                                                    {sensor.gatewayName} - LDS
                                                    BUS {sensor.bus}
                                                </p>
                                                <p>UUID: {sensor.ldsu_uuid}</p>
                                                <p>{sensor.sensor_type}</p>
                                            </div>
                                        }
                                        className="float-left"
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
            </Modal.Body>
            {selectedUnit !== null && (
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => onFinish(selectedUnit)}
                    >
                        DONE
                    </Button>
                </Modal.Footer>
            )}
        </StyledContainer>
    );
};

LDSUSelectModal.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    defaultSearchValue: PropTypes.object,
};

export default LDSUSelectModal;
