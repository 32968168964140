import { useEffect, useState, KeyboardEvent } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Row, Col, Breadcrumb, Button, Form } from "react-bootstrap";
import {
    updateLDSUName,
    identifyLDSU,
    deleteOneLDSU,
    getDeviceConfigById,
    updateDeviceConfigById,
    fetchDetailDevice,
} from "service/gatewayService";
import dltWhiteIcon from "assets/svg/delete-white.svg";
import searchIcon from "assets/svg/search.svg";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import {
    ConfigLimit,
    DELETE_LDSU_SUCCESS_MESSAGE,
    HttpStatus,
    LDSU_NAME_MESSAGE,
    Patterns,
    UPDATE_REPORT_RATE_SUCCESS_MESSAGE,
} from "constant";
import { DownloadDataUI } from "components/modals/DownloadDataUI";
import DefaultModal from "components/modals/ModalTemplate";
import "assets/css/gateway.css";
import { HoverAuthorizeTooltip } from "components/authorize/AuthorizeTooltip";
import { canAccess } from "utils/authorize-action";
import { isHttpSuccess } from "utils/functions";
import { LdsuStateType } from "types/Ldsu";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import moment from "moment";
import { WalletAlertComponent } from "hooks/wallet";
import ResourceNameField from "components/common/ResourceNameField";

const DOWNLOAD_LDSU = "downloadLDSU";

const LdsuDetails = () => {
    const history = useHistory();
    const params: any = useParams();
    const { gatewayId, ldsuId } = params;
    const [ldsuDetails, setLDSUDetails] = useState<LdsuStateType>({
        UID: "",
        SNO: "",
        PRV: "",
        MFG: "",
        name: "",
        NAME: "",
        DID: "",
        TERM: "",
    });

    const [ldsuName, setLDSUName] = useState("");
    const [reportRate, setReportRate] = useState(0);
    const [initialReportRate, setInitialReportRate] = useState(0);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingReportRate, setIsEditingReportRate] = useState(false);
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    const [modalType, setModalType] = useState<string>("");
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalContent, setModalContent] = useState<string>("");
    const [modalTitle, setModalTitle] = useState<string>("");
    const isUnknownDevice = !ldsuDetails?.name;

    useEffect(() => {
        const fetch = async () => {
            const { data, status }: any = await getDeviceConfigById(
                gatewayId,
                ldsuId
            );
            const { INTVL: sensorReportRate } = data;

            if (isHttpSuccess(status)) {
                const ldsuContent = await fetchDetailDevice({
                    gateway_id: gatewayId,
                    device_id: ldsuId,
                });

                const { name, NAME, UID, SNO, MFG, PRV, DID, TERM } =
                    ldsuContent;

                setLDSUDetails({
                    name,
                    NAME,
                    UID,
                    SNO,
                    MFG,
                    PRV,
                    DID,
                    TERM,
                });
                setLDSUName(ldsuContent.name ?? ldsuContent.NAME);
                setReportRate(sensorReportRate);
                setInitialReportRate(sensorReportRate);
            } else if (status === HttpStatus.FORBIDDEN) {
                setIsForbiddenResource(true);
            }

            setIsPageLoading(false);
        };

        if (!canAccess("gateway:read")) {
            setIsPageLoading(false);
            return setIsForbiddenResource(true);
        } else {
            fetch();
        }
    }, [gatewayId, ldsuId]);

    const saveNewReportRate = async () => {
        if (initialReportRate === reportRate) {
            setIsEditingReportRate(false);
            return;
        }

        if (
            !reportRate ||
            reportRate < ConfigLimit.SENSOR_REPORT_RATE_MIN ||
            reportRate > ConfigLimit.SENSOR_REPORT_RATE_MAX
        ) {
            showErrorAlert({
                message: `Please enter a sensor report rate that is between ${ConfigLimit.SENSOR_REPORT_RATE_MIN} to ${ConfigLimit.SENSOR_REPORT_RATE_MAX} secs.`,
            });

            return;
        }

        if (reportRate % 5 !== 0) {
            showErrorAlert({
                message:
                    "Please enter a sensor report rate that is multiple of 5 secs.",
            });

            return;
        }

        const payload = {
            INTVL: reportRate,
        };

        const updateReportRateRes: any = await updateDeviceConfigById(
            gatewayId,
            ldsuId,
            payload
        );

        if (isHttpSuccess(updateReportRateRes.status)) {
            setInitialReportRate(reportRate);
            setIsEditingReportRate(false);
            showSuccessAlert({
                message: UPDATE_REPORT_RATE_SUCCESS_MESSAGE,
            });
        } else {
            showErrorAlert({
                message:
                    updateReportRateRes.data?.description ||
                    "Unable to update sensor report rate. Please try again",
            });
        }
    };

    const saveNewLDSUName = async () => {
        if (ldsuName === ldsuDetails.name) {
            setIsEditing(false);
            return;
        }

        const updateResponse: any = await updateLDSUName(
            params.gatewayId,
            params.ldsuId,
            ldsuName
        );
        if (isHttpSuccess(updateResponse.status)) {
            showSuccessAlert({
                message: "LDSU name has been updated.",
            });

            setLDSUDetails({ ...ldsuDetails, name: ldsuName });
        } else {
            showErrorAlert({
                message:
                    updateResponse.data?.description ||
                    "Unable to update LDSU name. Please try again",
            });
        }
        setIsEditing(false);
    };

    const identifyDevice = async () => {
        const identifyResponse: any = await identifyLDSU(
            params.gatewayId,
            params.ldsuId
        );
        if (isHttpSuccess(identifyResponse.status)) {
            showSuccessAlert({
                message: identifyResponse.message,
            });
        } else {
            showErrorAlert({
                message:
                    identifyResponse.data?.description ||
                    "Unable to identify device. Please try again.",
            });
        }
    };

    const handleClickEdit = () => {
        setIsEditing(true);
    };

    const handleClickEditReportRate = () => {
        setIsEditingReportRate(true);
    };

    const handleClickDelete = () => {
        setModalType("dlt");
        setModalTitle("Delete LDSU?");
        setModalShow(true);
        setModalContent(
            `Are you sure you want to delete LDSU ${
                ldsuDetails.name ?? ldsuDetails.NAME
            }?`
        );
    };

    const deleteThisLdsu = async () => {
        const deleteRes: any = await deleteOneLDSU(
            params.gatewayId,
            params.ldsuId
        );
        if (isHttpSuccess(deleteRes.status)) {
            showSuccessAlert({
                message: DELETE_LDSU_SUCCESS_MESSAGE,
            });
            backToLDSUList();
        } else {
            showErrorAlert({
                message:
                    deleteRes.data?.description ||
                    `Unable to delete LDSU. Please try again`,
            });
        }

        setModalShow(false);
        setModalTitle("");
    };

    const backToLDSUList = () => {
        history.push(
            `/gateway-details/${params.gatewayId}/LDSBus/${params.busNumber}`
        );
    };

    const getMFG = (MFG: string) => {
        return moment(MFG, "DDMMYYYY").format("DD/MM/YYYY");
    };

    return (
        <ContentWrapper
            isLoading={isPageLoading}
            isForbiddenResource={isForbiddenResource}
        >
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            <Row>
                                <Col>
                                    <h5 className="page-title">
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: (
                                                    ldsuDetails.name ||
                                                    ldsuDetails.NAME
                                                )?.replace(/ /g, "&nbsp;"),
                                            }}
                                        />
                                    </h5>
                                </Col>
                                <Col sm="12">
                                    <Breadcrumb className="w-100">
                                        <Breadcrumb.Item
                                            href={`/gateway-details/${params.gatewayId}`}
                                        >
                                            Gateway
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item
                                            as="li"
                                            href={`/gateway-details/${params.gatewayId}/LDSBus/${params.busNumber}`}
                                        >
                                            All LDSUs
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active>
                                            LDSU
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={6} className="ldsu-buttons-container">
                            <div className="ldsu-buttons">
                                <HoverAuthorizeTooltip permission="gateway:delete">
                                    <Button
                                        variant="primary"
                                        onClick={identifyDevice}
                                        hidden={isUnknownDevice}
                                    >
                                        <span>
                                            <img
                                                className="mr-2 ml-1"
                                                src={searchIcon}
                                                alt="search"
                                            />
                                        </span>
                                        {""}
                                        Identify Device
                                    </Button>
                                </HoverAuthorizeTooltip>
                            </div>
                            <div className="ldsu-buttons">
                                <HoverAuthorizeTooltip permission="gateway:delete">
                                    <Button
                                        variant="danger"
                                        onClick={() => {
                                            handleClickDelete();
                                        }}
                                    >
                                        <span>
                                            <img
                                                className="mr-2 ml-2"
                                                src={dltWhiteIcon}
                                                alt="delete"
                                            />
                                        </span>
                                        {""}
                                        Delete
                                    </Button>
                                </HoverAuthorizeTooltip>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <ResourceNameField
                                input={ldsuName}
                                updateField={(e) => {
                                    setLDSUName(e.target.value);
                                }}
                                isNameEditing={isEditing}
                                toggleEditButton={handleClickEdit}
                                toggleUpdateButton={saveNewLDSUName}
                                resourceTitle="LDSU Name"
                                resourcePermission="gateway:update"
                                validationMessage={LDSU_NAME_MESSAGE}
                                validationRegex={Patterns.orgNamePattern}
                                shouldDisableEdit={isUnknownDevice}
                            />
                        </Col>
                        <Col md={6}>
                            <div className="form-box descriptor-box mt-3 pb-5">
                                <Form.Row>
                                    <Col lg="12" xs="12">
                                        <div className="descriptor-item">
                                            <h6 className="float-left">UUID</h6>
                                            <h6 className="ginfo float-right">
                                                {ldsuDetails.UID}
                                            </h6>
                                        </div>
                                        <div className="descriptor-item">
                                            <h6 className="float-left">
                                                Serial Number
                                            </h6>
                                            <h6 className="ginfo float-right">
                                                {!isUnknownDevice
                                                    ? ldsuDetails.SNO
                                                    : "-"}
                                            </h6>
                                        </div>
                                        <div className="descriptor-item">
                                            <h6 className="float-left">
                                                Product Name
                                            </h6>
                                            <h6 className="ginfo float-right">
                                                {!isUnknownDevice
                                                    ? ldsuDetails.NAME
                                                    : "-"}
                                            </h6>
                                        </div>
                                        <div className="descriptor-item">
                                            <h6 className="float-left">
                                                Manufacturing Date
                                            </h6>
                                            <h6 className="ginfo float-right">
                                                {!isUnknownDevice
                                                    ? getMFG(ldsuDetails.MFG)
                                                    : "-"}
                                            </h6>
                                        </div>
                                        <div className="descriptor-item">
                                            <h6 className="float-left">
                                                Product Version
                                            </h6>
                                            <h6 className="ginfo float-right">
                                                {!isUnknownDevice
                                                    ? ldsuDetails.PRV
                                                    : "-"}
                                            </h6>
                                        </div>
                                        <div className="descriptor-item">
                                            <h6 className="float-left">
                                                LDSU DID
                                            </h6>
                                            <h6 className="ginfo float-right">
                                                {!isUnknownDevice
                                                    ? ldsuDetails.DID
                                                    : "-"}
                                            </h6>
                                        </div>
                                        <div className="descriptor-item">
                                            <h6 className="float-left">
                                                Termination Status
                                            </h6>
                                            <h6 className="ginfo float-right">
                                                {!isUnknownDevice
                                                    ? ldsuDetails.TERM.toUpperCase()
                                                    : "-"}
                                            </h6>
                                        </div>
                                    </Col>
                                </Form.Row>
                            </div>
                        </Col>

                        {!isUnknownDevice && (
                            <Col md={6}>
                                <Form>
                                    <div className="form-box mt-3 sensor-report-rate">
                                        <Form.Row>
                                            <Col md="12">
                                                <Form.Label>
                                                    Sensor Report Rate
                                                </Form.Label>
                                            </Col>
                                            <Col
                                                lg={10}
                                                xs={12}
                                                className="mb-2"
                                            >
                                                <Form.Group className="mb-0">
                                                    <Form.Control
                                                        type="text"
                                                        required
                                                        aria-label="report-rate"
                                                        defaultValue={
                                                            reportRate
                                                        }
                                                        disabled={
                                                            !isEditingReportRate
                                                        }
                                                        onChange={(e) => {
                                                            setReportRate(
                                                                Number(
                                                                    e.target
                                                                        .value
                                                                )
                                                            );
                                                        }}
                                                        onKeyPress={(
                                                            e: KeyboardEvent<HTMLInputElement>
                                                        ) => {
                                                            if (
                                                                e.key ===
                                                                "Enter"
                                                            ) {
                                                                e.preventDefault();
                                                                saveNewReportRate();
                                                            }
                                                        }}
                                                    />
                                                    <div className="report-rate-unit">
                                                        sec(s)
                                                    </div>
                                                </Form.Group>
                                            </Col>

                                            <Col lg={2} xs={12}>
                                                <HoverAuthorizeTooltip
                                                    permission="gateway:update"
                                                    className="w-100"
                                                >
                                                    {!isEditingReportRate ? (
                                                        <Button
                                                            type="button"
                                                            className="w-100"
                                                            onClick={
                                                                handleClickEditReportRate
                                                            }
                                                        >
                                                            Edit
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            type="button"
                                                            className="w-100"
                                                            onClick={
                                                                saveNewReportRate
                                                            }
                                                        >
                                                            Update
                                                        </Button>
                                                    )}
                                                </HoverAuthorizeTooltip>
                                            </Col>
                                            <Col
                                                className="mt-2 text-primary-blue-4"
                                                lg={12}
                                            >{`Minimum - 5 seconds, Maximum - 1800 seconds (30 minutes) and value should be multiple of 5 seconds`}</Col>
                                        </Form.Row>
                                    </div>
                                </Form>
                                <div className="form-box mt-3 pb-5 download-data-box text-left">
                                    <h5>Download LDSU data</h5>
                                    <DownloadDataUI
                                        ldsuId={ldsuDetails.UID}
                                        gatewayId={params.gatewayId}
                                        downloadType={DOWNLOAD_LDSU}
                                    />
                                </div>
                            </Col>
                        )}
                    </Row>
                    <DefaultModal
                        modalShow={modalShow}
                        setModalShow={setModalShow}
                        modalType={modalType}
                        modalContent={modalContent}
                        modalTitle={modalTitle}
                        deleteFunction={deleteThisLdsu}
                    />
                    <WalletAlertComponent />
                </Container>
            </div>
        </ContentWrapper>
    );
};

export default LdsuDetails;
