import { useState, useEffect, useContext, KeyboardEvent } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Button,
    Form,
    Modal,
    FormControl,
} from "react-bootstrap";
import { getRegistryGroups, getAllRegistry } from "service/gatewayService";
import LocalStorageService from "service/localStorageService";
import {
    DaysMap,
    DeviceTypeDict,
    LDSUTypeDict,
    MonthsMap,
    EventNameMaxCharacter,
    HttpStatus,
    AUTO_MODE,
    MANUAL_MODE,
    NEW_ACTION_MESSAGE,
    FETCH_GATEWAY_FAIL_MESSAGE,
    PWM_MODE,
    ANALOG_MODE,
} from "constant";
import {
    capitalize,
    startCase,
    set,
    find,
    sortBy,
    isEmpty,
    cloneDeep,
    get,
} from "lodash";

import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";

import EventService from "service/eventService";
import sensorStatusIcon from "assets/svg/sensor-status.svg";
import sensorValueIcon from "assets/svg/sensor-value.svg";
import gatewayStatusIcon from "assets/svg/gateway-status.svg";
import recurrenceIcon from "assets/svg/recurrence.svg";
import dateIcon from "assets/svg/on-date.svg";

import ActionSMS from "assets/svg/event-action-sms.svg";
import ActionEmail from "assets/svg/event-action-email.svg";
import ActionNotification from "assets/svg/event-action-notification.svg";
import ActionActuator from "assets/svg/event-action-actuator.svg";
import DelayIcon from "assets/svg/event-action-delay.svg";

import dltWhiteIcon from "assets/svg/delete-white.svg";
import editIcon from "assets/svg/edit.svg";
import eventEditIcon from "assets/svg/event-edit.svg";
import drag from "assets/svg/drag.svg";

import DefaultModal from "components/modals/ModalTemplate";
import moment from "moment";

import OrganisationService from "service/organisationService";
import { AppContext } from "context/appContext";
import { getPrimaryUnit, isHttpSuccess, orgId } from "utils/functions";
import SensorActuatorSelectModal from "components/modals/SensorActuatorSelectModal";
import SetDelayModal, {
    secondToTimeString,
    defaultDelayValue,
} from "components/modals/SetDelayModal";
import LDSUSelectModal from "components/modals/LDSUSelectModal";
import getEventInfo, { isValid, showWarning } from "utils/eventFunctions";
import GridStackElement from "utils/gridstack";
import { WalletAlertComponent } from "hooks/wallet";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { canAccess } from "utils/authorize-action";
import { faker } from "@faker-js/faker";

import "assets/css/event.css";
import { MemberDto, MemberStatus } from "types";
import { getMemberName } from "utils/organisationFunction";
import { Group } from "generated/models";
import { getAPIError, showErrorAlert } from "utils/alert";

const spacePlaceHolder = "　";

export const getDisplayedActuatorMode = (configMode: any) => {
    let configMode_ = "";

    if (configMode === ANALOG_MODE) {
        configMode_ = "";
    } else if (configMode === PWM_MODE) {
        configMode_ = configMode;
    } else {
        configMode_ = capitalize(configMode);
    }

    return configMode_;
};

const EventDetails = (props: any) => {
    const metricCLSList = LocalStorageService.getItem("metricInfo").cls;
    const [selectCondition, setSelectCondition] = useState(false);
    const [selectGateway, setSelectGateway] = useState(false);
    const [selectModalVisible, setSelectModalVisible] = useState(false);
    const [selectedCondition, setSelectedCondition] = useState("");
    const [selectedOperator, setSelectedOperator] = useState("");
    const [selectedSlotIndex, setSelectedSlotIndex] = useState(0);
    const [unitType, setUnitType] = useState(DeviceTypeDict.sensor.label);
    const [radioBtnSelected, setRadioBtnSelected] = useState(false);

    const [orgSelectionList, setOrgSelectionList] = useState<Array<MemberDto>>(
        []
    );
    const { storeData } = useContext(AppContext);
    const { isDefault } = storeData.organization.currentOrgInfo;
    const { phoneNumber } = storeData.user.userData;

    const [toDelete, setToDelete] = useState({
        type: "",
        id: "",
    });

    const [selectedSensorDetails] = useState({
        name: "",
        gatewayName: "",
        bus: "",
        ldsuUUID: "",
        type: "",
        gatewayId: "",
        unit: "",
        said: "",
        min: "",
        max: "",
    });

    const handleConditionsClose = () => setSelectCondition(false);

    const [selectAction, setSelectAction] = useState(false);
    const params: any = useParams();
    const [eventDetail, setEventDetail]: any = useState({});

    const [gatewayGroupList, setGatewayGroupList] = useState<Group[]>([]);
    const [initialGatewayGroupList, setInitialGatewayGroupList] = useState<
        Group[]
    >([]);
    const [allGateways, setAllGateways] = useState([]);

    const [selectedGateway, setSelectedGateway] = useState("");
    const [conditionList, setConditionList] = useState([]);
    const [actionList, setActionList] = useState<any[]>([]);
    const [isNameEditing, setIsNameEditing] = useState(false);
    const [editableName, setEditableName] = useState("");
    const [slotCount, setSlotCount] = useState(0);
    const history = useHistory();

    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");

    const [isPageLoading, setIsPageLoading] = useState(true);
    const [eventActive, setEventActive] = useState(false);
    const [editDelayObj, setEditDelayObj] = useState<{
        delay: number;
        action?: any;
    }>({ delay: defaultDelayValue });
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);

    const CONDITION_SENSOR_VALUE = "sensor-value";
    const CONDITION_LDSU_STATUS = "ldsu-status";
    const CONDITION_SENSOR_STATUS = "sensor-status";
    const CONDITION_GATEWAY_STATUS = "gateway-status";
    const CONDITION_RECURRENCE = "recurrence";
    const CONDITION_ONDATE = "on-date";

    const ACTION_SMS = "sms";
    const ACTION_EMAIL = "email";
    const ACTION_NOTIF = "notification";
    const ACTION_ACTUATOR = "actuator";
    const ACTION = "action";
    const CONDITION = "condition";
    const EVENT = "event";
    useEffect(() => {
        const fetchAllGateways = async () => {
            const allGatewaysRes = await getAllRegistry();
            const { status, data: gateways = [] } = allGatewaysRes;
            if (status === HttpStatus.FORBIDDEN) {
                setIsPageLoading(false);
                setIsForbiddenResource(true);
            } else if (isHttpSuccess(status)) {
                const onBoardedGateways = gateways.filter(
                    ({ info }: { info: any }) => info
                );
                setAllGateways(onBoardedGateways);
                setIsLoaded(true);
            } else {
                showErrorAlert(
                    getAPIError(allGatewaysRes, FETCH_GATEWAY_FAIL_MESSAGE)
                );
            }
        };
        fetchAllGateways();
    }, []);

    useEffect(() => {
        const fetch = async () => {
            const response: any = await getRegistryGroups();

            if (response.status === HttpStatus.OK) {
                const { data: groups } = response;
                const groupsWithOnboardedGateways: Group[] = groups.map(
                    (group: Group) => {
                        const updatedGateways = group.gateways.filter(
                            (gateway: any) => gateway.info
                        );
                        return {
                            ...group,
                            gateways: updatedGateways,
                        };
                    }
                );
                setGatewayGroupList(groupsWithOnboardedGateways);
                setInitialGatewayGroupList(groupsWithOnboardedGateways);
            }
        };
        fetch();
    }, []);

    const handleActionsClose = () => setSelectAction(false);

    const fetchEvent = async () => {
        const { status, data } = await EventService.readOneEvent(
            params.eventId
        );

        if (status === HttpStatus.OK) {
            setIsPageLoading(false);
            setEventDetail(data);
            setEditableName(data.name);

            // for conditions
            const max = Math.max.apply(
                Math,
                data.conditions.map((c: any) => c.slot)
            );
            setSlotCount(max + 1);
            setConditionList(data.conditions);

            // for actions
            setActionList(data.actions);

            // De-active edit event when event is active
            setEventActive(data.activate);
        } else if (status === HttpStatus.FORBIDDEN) {
            setIsPageLoading(false);
            setIsForbiddenResource(true);
        }
    };

    useEffect(() => {
        if (!canAccess("event:read")) {
            setIsPageLoading(false);
            setIsForbiddenResource(true);
        } else {
            fetchEvent();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.eventId, window.authorizationConfig]);

    useEffect(() => {
        const fetch = async () => {
            const { status, data = [] } =
                await OrganisationService.getOrgMembers(orgId());
            if (isHttpSuccess(status)) {
                // filter userInformation out for selection purpose.
                const orgList = data
                    .toCamelCase<MemberDto>()
                    .filter((curr) => curr.status !== MemberStatus.Pending);
                setOrgSelectionList(orgList);
            }
        };

        fetch();
    }, []);

    const showError = () => {
        setModalShow(true);
        setModalType("err");
        setModalContent("Event in activated status cannot be edited.");
    };

    const addFirstConditionGroup = () => {
        setSelectCondition(true);
        setUnitType(DeviceTypeDict.sensor.label);
    };

    const addNewConditionGroup = () => {
        if (eventActive) {
            return showError();
        }
        setSelectCondition(true);
        setUnitType(DeviceTypeDict.sensor.label);
        setSelectedOperator("OR");
    };

    const addToSameConditionGroup = (index: number) => {
        if (eventActive) {
            return showError();
        }
        setSelectCondition(true);
        setUnitType(DeviceTypeDict.sensor.label);
        setSelectedOperator("AND");
        setSelectedSlotIndex(index);
    };

    const addNewAction = () => {
        if (eventActive) {
            return showError();
        }
        setSelectAction(true);
    };

    const showLDSUSelection = () => {
        setUnitType(LDSUTypeDict.LDSU.label);
        setSelectedCondition(CONDITION_LDSU_STATUS);
        handleConditionsClose();
        setSelectModalVisible(true);
    };

    const showSensorSelection = () => {
        setUnitType(DeviceTypeDict.sensor.label);
        setSelectedCondition(CONDITION_SENSOR_VALUE);
        setSelectCondition(false);
        setSelectModalVisible(true);
    };

    const showGatewaySelection = () => {
        setSelectedCondition(CONDITION_GATEWAY_STATUS);
        handleConditionsClose();
        setSelectGateway(true);
    };

    const showActuatorSelection = () => {
        setSelectModalVisible(true);
        setUnitType(DeviceTypeDict.actuator.label);
        handleActionsClose();
    };

    const showDelayModal = () => {
        setSelectModalVisible(true);
        setUnitType("delay");
        handleActionsClose();
    };

    const editDelayModal = (action: any) => {
        if (eventActive) return;
        setSelectModalVisible(true);
        setUnitType("delay");
        setSelectAction(false);
        setEditDelayObj({
            delay: Number(action.resource?.seconds),
            action,
        });
    };

    const handleEditDelayAction = async (data: any) => {
        if (!editDelayObj.action) {
            // Create event delay action
            const response = await EventService.createAction(params.eventId, {
                type_: "DELAY",
                resource: {
                    seconds: Number(data),
                },
            });

            if (response.status === HttpStatus.CREATED) {
                setActionList([...actionList, response.data]);
                setSimpleModalData({
                    resObj: response,
                    body: NEW_ACTION_MESSAGE,
                } as SimpleModalDataType);
            } else {
                setSimpleModalData({
                    resObj: response,
                } as SimpleModalDataType);
            }
        } else {
            if (Number(editDelayObj.delay) === Number(data)) {
                return;
            }
            // Patch event delay action
            const response = await EventService.updateAction(
                params.eventId,
                editDelayObj.action.uuid,
                {
                    type_: "DELAY",
                    resource: {
                        seconds: Number(data),
                    },
                }
            );

            if (response.status !== HttpStatus.NO_CONTENT) {
                setModalShow(true);
                setModalType("err");
                setModalContent(response.data.description);
                return;
            }

            const actionListCloned = [...actionList];
            set(
                find(actionListCloned, {
                    uuid: editDelayObj.action.uuid,
                }),
                "resource.seconds",
                Number(data)
            );
            setActionList(actionListCloned);
        }

        setEditDelayObj({ delay: defaultDelayValue });
    };

    const proceedToRecurrencePage = () => {
        handleConditionsClose();
        showCondition(CONDITION_RECURRENCE);
    };
    const proceedToOnDatePage = () => {
        handleConditionsClose();
        showCondition(CONDITION_ONDATE);
    };

    const showDeleteConfirmation = (type: string, uuid: string) => {
        setToDelete({
            type: type,
            id: uuid,
        });
        showDeleteModal(type);
    };

    const showDeleteEventConfirmation = () => {
        setToDelete({
            ...toDelete,
            type: EVENT,
        });
        showDeleteModal(EVENT);
    };

    const closeGatewaySelection = () => {
        searchGateways("");
        setSelectGateway(false);
        setRadioBtnSelected(false);
    };

    const searchGateways = (query: string) => {
        if (query !== "") {
            const filteredGatewayGroupList = cloneDeep(initialGatewayGroupList);

            let group: any;
            for (group of filteredGatewayGroupList) {
                const filteredGateways = [];
                for (let q = 0; q < group.gateways.length; q++) {
                    if (
                        group.gateways[q].name
                            .toLowerCase()
                            .includes(query.toLowerCase())
                    ) {
                        filteredGateways.push(group.gateways[q]);
                    }
                }
                group.gateways = filteredGateways;
            }
            setGatewayGroupList(
                filteredGatewayGroupList.filter(
                    (g: any) => g.gateways.length > 0
                )
            );
        } else {
            setGatewayGroupList(initialGatewayGroupList);
        }
    };

    const showDeleteModal = (type: string) => {
        setModalShow(true);
        setModalType("dlt");
        setModalContent(`Do you want to delete this ${type}?`);
    };

    const showAction = (actionType: string, config: any = {}) => {
        history.push(`/event-details/${params.eventId}/action/${actionType}`, {
            ...config,
        });
    };

    const showCondition = (condition?: string, config = {}) => {
        if (
            condition === CONDITION_ONDATE ||
            condition === CONDITION_RECURRENCE
        ) {
            history.push(
                `/event-details/${params.eventId}/condition/${condition}`,
                {
                    selectedOperator,
                    slotCount,
                    selectedSlotIndex,
                }
            );
        } else {
            history.push(
                `/event-details/${params.eventId}/condition/${selectedCondition}`,
                {
                    selectedSensorDetails,
                    selectedOperator,
                    slotCount,
                    selectedSlotIndex,
                    selectedGateway,
                    // selectedGatewayName,
                    ...config,
                }
            );
        }
    };

    const editName = (e: any) => {
        if (isNameEditing) {
            setEditableName(e.target.value);
        }
    };

    const handleNameEditUpdate = async () => {
        // validation for blank names and special chars
        const editableNameTrim = String(editableName).trim();
        setEditableName(editableNameTrim);

        setIsNameEditing(!isNameEditing);
        if (isNameEditing) {
            if (editableNameTrim === eventDetail.name) {
                setIsNameEditing(false);
                return;
            }

            if (
                !editableNameTrim ||
                editableNameTrim.length > EventNameMaxCharacter
            ) {
                setIsNameEditing(true);

                setSimpleModalData({
                    type: "error",
                    body: `Event name must be between 1-${EventNameMaxCharacter} characters long.`,
                });

                return;
            }

            const response = await EventService.updateEvent(params.eventId, {
                name: editableNameTrim,
            });
            if (response.status === 204) {
                setSimpleModalData({
                    type: "success",
                    body: `Event name has been updated successfully.`,
                });
                setEventDetail({ ...eventDetail, name: editableNameTrim });
            } else {
                setSimpleModalData({
                    resObj: response,
                } as SimpleModalDataType);
            }
        }
    };

    const toggleEventStatus = async (event_id: string, active: boolean) => {
        // call get Event API

        if (!isValid(eventDetail, allGateways)) {
            return showWarning();
        }

        const response = await EventService.updateEvent(event_id, {
            activate: !active,
        });

        if (response.status === 204) {
            setSimpleModalData({
                type: "success",
                body: `Event has been ${
                    active ? "disabled" : "enabled"
                } successfully.`,
            });
            setEventActive(!eventActive);
        } else {
            setSimpleModalData({
                resObj: response,
            } as SimpleModalDataType);
        }
    };

    const deleteCondition = async () => {
        const response = await EventService.deleteCondition(
            params.eventId,
            toDelete.id
        );
        if (response.status === 204) {
            setSimpleModalData({
                resObj: response,
                body: `Condition has been deleted successfully.`,
            } as SimpleModalDataType);
            setModalShow(false);

            const element = document.getElementById("gs-" + toDelete.id);
            const elementGrid = element?.parentNode as any;
            elementGrid.gridstack?.removeWidget(element, false);

            const remainingConditions: any = conditionList.filter(
                (c: any) => c.uuid !== toDelete.id
            );
            setConditionList(remainingConditions);

            !document.querySelectorAll(".condition-item").length &&
                setConditionList([]);

            fetchEvent();
        } else {
            setSimpleModalData({
                resObj: response,
            } as SimpleModalDataType);
        }
    };

    const deleteAction = async () => {
        const response = await EventService.deleteAction(
            params.eventId,
            toDelete.id
        );
        if (response.status === 204) {
            setSimpleModalData({
                resObj: response,
                body: `Action has been deleted successfully.`,
            } as SimpleModalDataType);
            setModalShow(false);

            const clone = [...actionList];
            const filtered = clone.filter((action: any) => {
                return action.uuid !== toDelete.id;
            });
            setActionList(filtered);
        } else {
            setSimpleModalData({
                resObj: response,
            } as SimpleModalDataType);
        }
    };

    const deleteEvent = async () => {
        const response = await EventService.deleteEvent(params.eventId);
        if (response.status === 204) {
            setSimpleModalData({
                resObj: response,
                body: `Event has been deleted successfully.`,
            } as SimpleModalDataType);
            setModalShow(false);
            history.push(`/events`);
        } else {
            setSimpleModalData({
                resObj: response,
            } as SimpleModalDataType);
        }
    };

    const renderEditCondition = (conditionType: string, id: string) => (
        <HoverAuthorizeTooltip permission="event:update" className="event-edit">
            <Link
                to={{
                    pathname: `/event-details/${params.eventId}/condition/${conditionType}/${id}`,
                }}
            >
                <img
                    width={19}
                    height={19}
                    src={eventEditIcon}
                    alt="Edit condition or event"
                />
            </Link>
        </HoverAuthorizeTooltip>
    );

    const renderEditAction = (actionType: string, id: string) => (
        <HoverAuthorizeTooltip permission="event:update" className="event-edit">
            <Link
                to={{
                    pathname: `/event-details/${params.eventId}/action/${actionType}/${id}`,
                }}
            >
                <img
                    width={19}
                    height={19}
                    src={eventEditIcon}
                    alt="Edit condition or event"
                    aria-label="edit-action"
                />
            </Link>
        </HoverAuthorizeTooltip>
    );

    const renderActions = () => {
        const actions = sortBy(actionList, "order").map((action: any) => {
            const jsonList = LocalStorageService.getItem("listOfJSON");
            const {
                resource: {
                    content,
                    recipients,
                    status,
                    gateway_id,
                    device_id,
                    said,
                },
                type_,
                uuid,
            } = action;

            const deviceOBJ: any = allGateways.reduce((acc: any, g: any) => {
                if (g.gateway_id === gateway_id && g.info) {
                    const deviceList = g.info.devices;

                    const selectedSensor = deviceList.find((d: any) => {
                        return d.UID === device_id;
                    });

                    if (selectedSensor) {
                        acc += selectedSensor.OBJ;
                    }
                }
                return acc;
            }, "");

            const getClassAndUnitFromOBJandSAID = (
                obj: string,
                said: string
            ) => {
                const selectedSensor = jsonList.find((j: any) => {
                    return j.OBJ === obj;
                });
                const unit: any = selectedSensor?.SNS[said]?.UNIT;
                const cls: any = selectedSensor?.SNS[said]?.CLS;
                const type: string = metricCLSList?.[cls]?.name;

                return { unit, type };
            };

            const getActionAndConfigMode = allGateways.reduce(
                (acc: any, g: any) => {
                    if (g.gateway_id === gateway_id && g.configs) {
                        const gatewayConfigList = g.configs;

                        const selectedSensor = gatewayConfigList.find(
                            (d: any) => {
                                return d.UID === device_id;
                            }
                        );

                        if (selectedSensor) {
                            acc.actionMode = selectedSensor.CMDS[said].auto
                                ? AUTO_MODE
                                : MANUAL_MODE;
                            acc.configMode = selectedSensor.CMDS[said].mode;
                        }
                    }
                    return acc;
                },
                {}
            );
            const { actionMode = "MANUAL", configMode } =
                getActionAndConfigMode;

            const sensorName: any = allGateways.reduce((acc: any, g: any) => {
                if (g.gateway_id === gateway_id && g.configs.length > 0) {
                    const sensorList = g.configs;
                    const selectedSensor = sensorList.find((s: any) => {
                        return s.UID === device_id;
                    });

                    if (selectedSensor) {
                        acc += selectedSensor.sensor_names?.[said];
                    }
                }
                return acc;
            }, "");

            let recipientListBody: MemberDto[] = [];

            if (!isEmpty(orgSelectionList) && !isEmpty(recipients)) {
                for (const savedRecipient of recipients || []) {
                    const temp = orgSelectionList.find((member) => {
                        return (
                            member?.username?.toLowerCase() ===
                            savedRecipient?.toLowerCase()
                        );
                    });
                    recipientListBody.push(temp!);
                }
            }

            const renderActionHeader = () => {
                if (type_ === "EMAIL") {
                    return (
                        <>
                            <h6>
                                <img src={ActionEmail} alt="action-email" />
                                Email
                            </h6>
                            {renderEditAction(ACTION_EMAIL, uuid)}
                        </>
                    );
                } else if (type_ === "SMS") {
                    return (
                        <>
                            <h6>
                                <img src={ActionSMS} alt="action-sms" />
                                SMS
                            </h6>
                            {renderEditAction(ACTION_SMS, uuid)}
                        </>
                    );
                } else if (type_ === "PUSH") {
                    return (
                        <>
                            <h6>
                                <img
                                    src={ActionNotification}
                                    alt="action-notification"
                                />
                                Notification
                            </h6>
                            {renderEditAction(ACTION_NOTIF, uuid)}
                        </>
                    );
                } else if (type_ === "ACTUATOR") {
                    return (
                        <>
                            <h6>
                                <img
                                    src={ActionActuator}
                                    alt="action-actuator"
                                />
                                <Link
                                    to={{
                                        pathname: `/event-details/${params.eventId}/action/${ACTION_ACTUATOR}/${uuid}`,
                                    }}
                                >
                                    Actuator
                                </Link>
                            </h6>
                            {renderEditAction(ACTION_ACTUATOR, uuid)}
                        </>
                    );
                } else if (type_ === "DELAY") {
                    return (
                        <>
                            <h6>
                                <img src={DelayIcon} alt="action-delay" />
                                Delay
                            </h6>
                            <HoverAuthorizeTooltip
                                permission="event:update"
                                className="event-edit"
                            >
                                <span
                                    onClick={() => {
                                        editDelayModal(action);
                                    }}
                                    aria-label="edit-action"
                                >
                                    <img
                                        width={19}
                                        height={19}
                                        src={eventEditIcon}
                                        alt="Edit condition or event"
                                    />
                                </span>
                            </HoverAuthorizeTooltip>
                        </>
                    );
                }
            };

            const renderDescription = () => {
                if (type_ === "DELAY") {
                    return (
                        <span className="delay-event__desc">
                            {secondToTimeString(action.resource?.seconds)}
                        </span>
                    );
                }

                if (type_ !== "ACTUATOR") {
                    return <p className="content-desc">{content}</p>;
                }
                let status_ = "Deactivate";
                if (status !== "off") {
                    status_ = "Activate";
                }

                if (!sensorName) {
                    return (
                        <span className="warning-message actuator">
                            <i className="material-icons outlined">
                                error_outline
                            </i>
                            <span>This Actuator is not found</span>
                        </span>
                    );
                }

                return (
                    <>
                        <h6>{sensorName}</h6>
                        <p>
                            {startCase(
                                getClassAndUnitFromOBJandSAID(deviceOBJ, said)
                                    .type || spacePlaceHolder
                            )}
                        </p>

                        {getDisplayedActuatorMode(configMode) && (
                            <h6>
                                Mode:
                                <span className="text-primary-blue-4 ml-2">
                                    {getDisplayedActuatorMode(configMode)}
                                </span>
                            </h6>
                        )}

                        <h6>
                            Control:
                            <span className="text-primary-blue-4 ml-2">
                                {capitalize(actionMode)}
                            </span>
                        </h6>

                        <h6 className="mt-4">{`Action: ${status_}`} </h6>

                        {actionMode && actionMode.toLowerCase() === "manual" && (
                            <span className="warning-message actuator">
                                <i className="material-icons outlined">
                                    error_outline
                                </i>
                                <span>
                                    This Actuator control changed into Manual
                                </span>
                            </span>
                        )}
                    </>
                );
            };

            const renderRecipientPreview = () => {
                if (type_ === "DELAY") {
                    return;
                }

                if (type_ !== "ACTUATOR") {
                    if (recipientListBody.length > 1) {
                        if (isDefault) {
                            return (
                                <h6>
                                    To ‘{recipientListBody[0].username}’ and{" "}
                                    {recipientListBody.length - 1} more
                                </h6>
                            );
                        }
                        return (
                            <h6>
                                To ‘{getMemberName(recipientListBody[0])}’ and{" "}
                                {recipientListBody.length - 1} more
                            </h6>
                        );
                    }
                    if (type_ === "SMS") {
                        if (isDefault) {
                            return <h6>To ‘{phoneNumber}’</h6>;
                        }
                    }

                    if (isDefault) {
                        return <h6>To ‘{recipientListBody[0]?.username}’</h6>;
                    }
                    return <h6>To ‘{getMemberName(recipientListBody[0])}’</h6>;
                }
            };

            return (
                <div
                    className="grid-stack-item"
                    id={`gs-${uuid}`}
                    gs-w="12"
                    key={`action-${uuid}`}
                    gs-no-resize="true"
                >
                    <div className="action-item">
                        {renderActionHeader()}
                        <div className="action-details">
                            {renderDescription()}
                            {renderRecipientPreview()}
                        </div>
                        <div>
                            <HoverAuthorizeTooltip
                                permission="event:update"
                                className="drag-action"
                            >
                                <img
                                    className="grid-stack-item-content"
                                    width={24}
                                    height={24}
                                    src={drag}
                                    alt="drag"
                                />
                            </HoverAuthorizeTooltip>
                            <HoverAuthorizeTooltip
                                permission="event:delete"
                                className="remove-action"
                            >
                                <span
                                    className="material-icons"
                                    aria-label="delete-action"
                                    onClick={() => {
                                        showDeleteConfirmation(ACTION, uuid);
                                    }}
                                >
                                    close
                                </span>
                            </HoverAuthorizeTooltip>
                        </div>
                    </div>
                </div>
            );
        });
        return (
            <GridStackElement
                id="actions-list-gridstack"
                itemCount={Number(actionList.length)}
                options={{
                    disableDrag: eventActive,
                }}
                onChange={(res) => {
                    const orders: any = {};
                    sortBy(res.data, "y").forEach(
                        (item, index) =>
                            (orders[String(item.id).replace("gs-", "")] = index)
                    );

                    orders &&
                        EventService.sortOrderAction(params.eventId, orders);
                }}
            >
                {actions}
            </GridStackElement>
        );
    };

    const renderConditions = (conditions: any) => {
        const list = sortBy(conditions, "order").map((condition: any) => {
            const { uuid, type_, resource } = condition;

            const renderConditionIcon = () => {
                switch (type_) {
                    case "LDSU":
                        return <img src={sensorStatusIcon} alt="" />;
                    case "GATEWAY":
                        return <img src={gatewayStatusIcon} alt="" />;
                    case "SENSOR":
                        return <img src={sensorValueIcon} alt="" />;
                    case "DATETIME":
                        if (resource.date2?.repeat)
                            return <img src={recurrenceIcon} alt="" />;
                        return <img src={dateIcon} alt="" />;
                }
            };

            const renderConditionContent = () => {
                const jsonList = LocalStorageService.getItem("listOfJSON");

                const {
                    type_,
                    resource: {
                        device: {
                            for_ = null,
                            gateway_id = "",
                            device_id = "",
                            said = "",
                            operator = null,
                            status = null,
                            thresholds = null,
                        } = {},
                        date1: {
                            day = "",
                            time_: {
                                ranges: ranges1 = null,
                                points: points1 = null,
                                schedule: schedule1 = "",
                                interval: interval1 = "",
                            } = {},
                        } = {},
                        date2: {
                            repeat = "",
                            [repeat]: {
                                ranges: ranges2 = null,
                                points: points2 = null,
                                interval: interval2 = "",
                            } = {},
                            weekly: { weekdays: weeklyWeekdays = {} } = {},
                            monthly: {
                                weekdays: monthlyWeekdays = {},
                                days: monthlyDays = {},
                            } = {},
                            yearly: { months = null } = {},
                        } = {},
                    },
                } = condition;

                const gwName: string = allGateways.reduce(
                    (acc: any, g: any) => {
                        if (g.gateway_id === gateway_id) {
                            acc += g.name;
                        }
                        return acc;
                    },
                    ""
                );

                if (type_ === "LDSU") {
                    const ldsuName = allGateways.reduce(
                        // eslint-disable-next-line array-callback-return
                        (acc: any, curr: any) => {
                            if (curr.configs) {
                                const ldsu: any = curr.configs.find(
                                    (l: any) => {
                                        return l.UID === device_id;
                                    }
                                );
                                if (ldsu) acc += ldsu?.name;
                                return acc;
                            }
                        },
                        ""
                    );

                    if (!ldsuName) {
                        return (
                            <span className="warning-message">
                                <i className="material-icons outlined">
                                    error_outline
                                </i>
                                <span>This LDSU is not found</span>
                            </span>
                        );
                    }

                    return (
                        <>
                            {ldsuName}
                            {renderEditCondition(CONDITION_LDSU_STATUS, uuid)}
                            <p>UUID: {device_id}</p>
                            <h6>
                                {status === "online" ? "Comes" : "Goes"}{" "}
                                {status} for {for_} sec(s)
                            </h6>
                        </>
                    );
                } else if (type_ === "GATEWAY") {
                    if (!gwName) {
                        return (
                            <span className="warning-message">
                                <i className="material-icons outlined">
                                    error_outline
                                </i>
                                <span>This Gateway is not found</span>
                            </span>
                        );
                    }

                    return (
                        <>
                            {gwName || spacePlaceHolder}
                            {renderEditCondition(
                                CONDITION_GATEWAY_STATUS,
                                uuid
                            )}
                            <p>{gateway_id}</p>
                            <h6>
                                {status === "online" ? "Comes" : "Goes"}{" "}
                                {status} for {for_} sec(s)
                            </h6>
                        </>
                    );
                } else if (type_ === "SENSOR") {
                    let sensor: any = {};
                    const deviceOBJ: any = allGateways.reduce(
                        (acc: any, g: any) => {
                            if (g.gateway_id === gateway_id && g.info) {
                                const deviceList = g.info.devices;

                                const selectedSensor = deviceList.find(
                                    (d: any) => {
                                        return d.UID === device_id;
                                    }
                                );

                                if (selectedSensor) {
                                    acc += selectedSensor.OBJ;
                                    sensor = selectedSensor;
                                }
                            }
                            return acc;
                        },
                        ""
                    );

                    const sensorName: any = allGateways.reduce(
                        (acc: any, g: any) => {
                            if (g.configs.length > 0) {
                                const sensorList = g.configs;
                                const selectedSensor = sensorList.find(
                                    (s: any) => {
                                        return s.UID === device_id;
                                    }
                                );

                                if (selectedSensor)
                                    acc += selectedSensor.sensor_names?.[said];
                            }
                            return acc;
                        },
                        ""
                    );

                    const getClassAndUnitFromOBJandSAID = (
                        obj: string,
                        said: string
                    ) => {
                        const selectedSensor = jsonList.find((j: any) => {
                            return j.OBJ === obj;
                        });

                        const unit: any = sensor.APP?.length
                            ? getPrimaryUnit(sensor.APP[said])
                            : selectedSensor?.SNS[said]?.UNIT;
                        const cls: any = selectedSensor?.SNS[said]?.CLS;
                        const type: string = sensor.APP
                            ? sensor.APP[said].NAME
                            : metricCLSList?.[cls]?.name;

                        return { unit, type };
                    };

                    let message: any;
                    let unit: any = getClassAndUnitFromOBJandSAID(
                        deviceOBJ,
                        said
                    ).unit;

                    if (unit === "C") {
                        unit = "°C";
                    } else if (unit === "na") {
                        unit = "";
                    }

                    if (operator === ">")
                        message = `Rises above or Is above ${thresholds[0]} ${unit}`;
                    else if (operator === "<")
                        message = `Falls below or Is below ${thresholds[0]} ${unit}`;
                    else if (operator === "==")
                        message = `Equals ${thresholds[0]} ${unit}`;
                    else if (operator === "in")
                        message = `Enters into or Is within a range of ${thresholds[0]} ${unit} to ${thresholds[1]} ${unit}`;
                    else if (operator === "not in")
                        message = `Exits or Is outside a range of ${thresholds[0]} ${unit} to ${thresholds[1]} ${unit}`;

                    if (!sensorName) {
                        return (
                            <span className="warning-message">
                                <i className="material-icons outlined">
                                    error_outline
                                </i>
                                <span>This Sensor is not found</span>
                            </span>
                        );
                    }

                    return (
                        <>
                            {sensorName}
                            {renderEditCondition(CONDITION_SENSOR_VALUE, uuid)}
                            <p>{device_id}</p>
                            <p>
                                {getClassAndUnitFromOBJandSAID(deviceOBJ, said)
                                    .type || spacePlaceHolder}
                            </p>
                            <h6>
                                {message} and remains for {for_} sec(s)
                            </h6>
                        </>
                    );
                } else if (type_ === "DATETIME" && !repeat) {
                    const renderTimeStatement = () => {
                        return getEventInfo(
                            schedule1,
                            ranges1,
                            points1,
                            interval1
                        );
                    };

                    return (
                        <>
                            <h6>On {moment(day).format(`DD/MM/YYYY`)}</h6>
                            {renderEditCondition(CONDITION_ONDATE, uuid)}
                            {renderTimeStatement()}
                        </>
                    );
                } else if (type_ === `DATETIME` && repeat) {
                    const renderTimeStatement = () => {
                        const renderDailyTimeStatement = () => {
                            return getEventInfo(
                                resource.date2[repeat].schedule,
                                ranges2,
                                points2,
                                interval2
                            );
                        };

                        const renderWeeklyTimeStatement = () => {
                            let days = "On";
                            let numOfDays = 0;
                            for (let d = 1; d < 8; d++) {
                                if (
                                    Object.keys(weeklyWeekdays).includes(`${d}`)
                                ) {
                                    numOfDays += 1;
                                    if (numOfDays > 1) {
                                        days += `, ${DaysMap[d]}`;
                                    } else {
                                        days += ` ${DaysMap[d]}`;
                                    }
                                }
                            }
                            return days;
                        };

                        const renderMonthlyTimeStatement = () => {
                            const formattedDays: any = [];
                            const formattedWeekdays: any = [];
                            let days = "On ";

                            if (!isEmpty(monthlyDays)) {
                                formattedDays.push(
                                    ...Object.keys(monthlyDays).map(
                                        (d: any) => {
                                            switch (d) {
                                                case "-1":
                                                    return `last day`;
                                                case "1":
                                                case "21":
                                                case "31":
                                                    return `${d}st`;
                                                case "2":
                                                case "22":
                                                    return `${d}nd`;
                                                case "3":
                                                case "23":
                                                    return `${d}rd`;
                                                default:
                                                    return `${d}th`;
                                            }
                                        }
                                    )
                                );
                            }

                            if (!isEmpty(monthlyWeekdays)) {
                                formattedWeekdays.push(
                                    ...Object.keys(monthlyWeekdays).map(
                                        (d: any) => {
                                            if (Number(d) > 0) {
                                                return `first ${DaysMap[d]}`;
                                            } else {
                                                d = Number(d) * -1;
                                                return `last ${DaysMap[d]}`;
                                            }
                                        }
                                    )
                                );
                            }
                            const combined: any =
                                formattedDays.concat(formattedWeekdays);
                            days += combined.toString().replaceAll(",", ", ");

                            return days;
                        };

                        const renderYearlyTimeStatement = () => {
                            let days = "On ";
                            const formattedDays: any = [];
                            const formattedWeekdays: any = [];
                            for (const m in months) {
                                if (!isEmpty(months[m].days)) {
                                    formattedDays.push(
                                        ...Object.keys(months[m].days).map(
                                            (d: any) => {
                                                switch (d) {
                                                    case "-1":
                                                        return `last day of ${MonthsMap[m]}`;
                                                    case "1":
                                                    case "21":
                                                    case "31":
                                                        return `${MonthsMap[m]} ${d}st`;
                                                    case "2":
                                                    case "22":
                                                        return `${MonthsMap[m]} ${d}nd`;
                                                    case "3":
                                                    case "23":
                                                        return `${MonthsMap[m]} ${d}rd`;
                                                    default:
                                                        return `${MonthsMap[m]} ${d}th`;
                                                }
                                            }
                                        )
                                    );
                                }

                                if (!isEmpty(months[m].weekdays)) {
                                    formattedWeekdays.push(
                                        ...Object.keys(months[m].weekdays).map(
                                            (d: any) => {
                                                if (Number(d) > 0) {
                                                    return `first ${DaysMap[d]} of ${MonthsMap[m]}`;
                                                } else {
                                                    d = Number(d) * -1;
                                                    return `last ${DaysMap[d]} of ${MonthsMap[m]}`;
                                                }
                                            }
                                        )
                                    );
                                }
                            }

                            const combined: any =
                                formattedDays.concat(formattedWeekdays);
                            days += combined.toString().replaceAll(",", ", ");

                            return days;
                        };
                        switch (repeat) {
                            case "daily":
                                return renderDailyTimeStatement();
                            case "weekly":
                                return renderWeeklyTimeStatement();
                            case "monthly":
                                return renderMonthlyTimeStatement();
                            case "yearly":
                                return renderYearlyTimeStatement();
                        }
                    };

                    return (
                        <>
                            <h6>{capitalize(repeat)}</h6>
                            {renderEditCondition(CONDITION_RECURRENCE, uuid)}

                            <p>Recurrence</p>
                            {renderTimeStatement()}
                        </>
                    );
                }
            };

            return (
                <div
                    className="grid-stack-item"
                    id={`gs-${uuid}`}
                    key={`condition-${uuid}`}
                    gs-w="12"
                    gs-no-resize="true"
                >
                    <div className="condition-item">
                        <div className="condition-box">
                            {renderConditionIcon()}

                            <div className="condition-texts">
                                {renderConditionContent()}
                            </div>
                            <HoverAuthorizeTooltip
                                permission="event:update"
                                className="drag-action"
                            >
                                <img
                                    className="grid-stack-item-content"
                                    width={24}
                                    height={24}
                                    src={drag}
                                    alt="drag"
                                />
                            </HoverAuthorizeTooltip>
                        </div>
                        <HoverAuthorizeTooltip
                            permission="event:delete"
                            className="remove-action"
                        >
                            <span
                                className="material-icons"
                                aria-label="delete-condition"
                                onClick={() => {
                                    showDeleteConfirmation(CONDITION, uuid);
                                }}
                            >
                                close
                            </span>
                        </HoverAuthorizeTooltip>
                        <div className="and-section">
                            <div className="and-btn">AND</div>
                        </div>
                    </div>
                </div>
            );
        });
        return list;
    };

    const renderSlots = () => {
        const slots: any = [];
        let condition: any;
        for (let i = 0; i < slotCount; i++) {
            slots[i] = [];
            for (condition of conditionList) {
                if (condition.slot === i) {
                    slots[i].push(condition);
                }
            }
        }

        const list = slots.map((slot: any, index: any) => {
            // eslint-disable-next-line array-callback-return
            if (!slot.length) return;

            return (
                <div key={faker.datatype.uuid()}>
                    <GridStackElement
                        id={`condition-slot-${index}`}
                        key={faker.datatype.uuid()}
                        itemCount={slot?.length}
                        gap={0}
                        options={{
                            acceptWidgets: ".conditions-list .grid-stack-item",
                            disableDrag: eventActive,
                        }}
                        setItemHeight={(element) => element.offsetHeight - 40}
                        onChange={(res) => {
                            const orders: any = {};
                            const slots: any = {};
                            const itemSlots: { [key: string]: any[] } = {};

                            document
                                .querySelectorAll(
                                    ".conditions-list .grid-stack-item"
                                )
                                .forEach((item) => {
                                    const slot = get(
                                        item,
                                        "parentNode.id"
                                    ).replace("condition-slot-", "");
                                    const id = item.id.replace("gs-", "");
                                    const order = item.getAttribute("gs-y");
                                    itemSlots[slot] = [
                                        ...(itemSlots[slot] || []),
                                        { id, order },
                                    ];
                                });

                            for (const slot in Object.values(itemSlots)) {
                                sortBy(
                                    Object.values(itemSlots)[slot],
                                    "order"
                                ).forEach((item, index) => {
                                    orders[item.id] = index;
                                    slots[item.id] = Number(slot);
                                });
                            }

                            document
                                .querySelectorAll(`[id^="condition-slot-"]`)
                                .forEach((ele: any) => {
                                    if (!ele.innerHTML) {
                                        try {
                                            ele.nextElementSibling.remove();
                                            ele.nextElementSibling.remove();
                                            ele.parentNode.removeChild(ele);
                                        } catch (err) {}
                                    }
                                });

                            EventService.sortOrderCondition(
                                params.eventId,
                                orders,
                                slots
                            );
                        }}
                    >
                        {renderConditions(slot)}
                    </GridStackElement>
                    <div className="add-condition-item">
                        <HoverAuthorizeTooltip permission="event:create">
                            <Button
                                variant="primary add-item"
                                onClick={() => {
                                    addToSameConditionGroup(index);
                                }}
                            >
                                +
                            </Button>
                        </HoverAuthorizeTooltip>
                    </div>

                    <div className="or-section">
                        <div className="or-btn">OR</div>
                    </div>
                </div>
            );
        });
        return list;
    };

    const renderGateways = () => {
        // eslint-disable-next-line array-callback-return
        const groups = gatewayGroupList.map((group: any) => {
            if (group.gateways.length) {
                return (
                    <div key={group.uuid}>
                        {group.name === "ungrouped" ? (
                            <h4>Standalone</h4>
                        ) : (
                            <h4>{group.name}</h4>
                        )}

                        {group.gateways.map((gateway: any) => {
                            return (
                                <div
                                    className="modal-option"
                                    key={gateway.uuid}
                                >
                                    <Form.Check
                                        name="selected-gateway"
                                        value={gateway.gateway_id}
                                        onChange={(e) => {
                                            setSelectedGateway(e.target.value);
                                            setRadioBtnSelected(true);
                                        }}
                                        id={gateway.gateway_id}
                                        custom
                                        type="radio"
                                        label={
                                            <div>
                                                <h4>{gateway.name}</h4>{" "}
                                                <p>
                                                    UUID: {gateway.gateway_id}
                                                </p>
                                            </div>
                                        }
                                        className="float-left"
                                    />
                                </div>
                            );
                        })}
                    </div>
                );
            }
        });
        return groups;
    };

    return (
        <ContentWrapper
            key={eventActive}
            isLoading={isPageLoading}
            simpleModalData={simpleModalData}
            isForbiddenResource={isForbiddenResource}
        >
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs="6">
                            <h5 className="page-title overflow-text">
                                {eventDetail.name}
                            </h5>
                        </Col>
                        <Col xs="6" className="text-right">
                            <HoverAuthorizeTooltip permission="event:delete">
                                <Button
                                    variant="danger"
                                    onClick={showDeleteEventConfirmation}
                                >
                                    <span>
                                        <img
                                            className="mr-2 ml-2"
                                            src={dltWhiteIcon}
                                            alt="dlt"
                                        />
                                    </span>
                                    Delete
                                </Button>
                            </HoverAuthorizeTooltip>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item href="/events">
                                    Events
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Event Details
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="3">
                            <div className="form-box enable-event-box">
                                <span>Enable this event</span>
                                <HoverAuthorizeTooltip permission="event:update">
                                    <div>
                                        <Form.Check
                                            type="switch"
                                            id={`switch_${params.eventId}`}
                                            label=""
                                            checked={eventActive}
                                            disabled={
                                                !canAccess("event:update")
                                            }
                                            onChange={() => {
                                                toggleEventStatus(
                                                    params.eventId,
                                                    eventActive
                                                );
                                            }}
                                        />
                                    </div>
                                </HoverAuthorizeTooltip>
                            </div>
                        </Col>
                        <Col>
                            <Form>
                                <div className="form-box mb-2 mt-3 mt-md-0">
                                    <Form.Row className="align-items-center">
                                        <Col md="1">
                                            <Form.Label>Event Name</Form.Label>
                                        </Col>
                                        <Col md="9" xs="12">
                                            <Form.Group className="mb-0">
                                                <Form.Control
                                                    type="text"
                                                    required
                                                    aria-label="name"
                                                    name="eventName"
                                                    value={editableName}
                                                    onChange={editName}
                                                    disabled={!isNameEditing}
                                                    onKeyPress={(
                                                        e: KeyboardEvent<HTMLInputElement>
                                                    ) => {
                                                        if (e.key === "Enter") {
                                                            e.preventDefault();
                                                            handleNameEditUpdate();
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="2" xs="12">
                                            <HoverAuthorizeTooltip
                                                permission="event:update"
                                                className="w-100 mt-2 mt-md-0"
                                            >
                                                <Button
                                                    type="button"
                                                    variant={"primary"}
                                                    className="w-100"
                                                    onClick={() => {
                                                        handleNameEditUpdate();
                                                    }}
                                                >
                                                    {isNameEditing ? (
                                                        "Update"
                                                    ) : (
                                                        <>
                                                            <span>
                                                                <img
                                                                    className="mr-2 ml-1"
                                                                    src={
                                                                        editIcon
                                                                    }
                                                                    alt="Edit Event Name"
                                                                />
                                                            </span>
                                                            Edit
                                                        </>
                                                    )}
                                                </Button>
                                            </HoverAuthorizeTooltip>
                                        </Col>
                                    </Form.Row>
                                </div>
                            </Form>
                        </Col>
                    </Row>

                    <Row className="my-3">
                        <Col sm={12}>
                            <WalletAlertComponent showWarningWhen="LowBalance" />
                        </Col>
                    </Row>
                    {isLoaded && (
                        <Row className="conditions-actions">
                            <Col lg="6">
                                <div className="when-do-title">When</div>

                                {/* If No Conditions to list  */}
                                {!conditionList.length ? (
                                    <div className="conditions-list-empty">
                                        <p>
                                            Add conditions to trigger this event
                                        </p>
                                        <HoverAuthorizeTooltip permission="event:create">
                                            <Button
                                                variant="outline-primary"
                                                onClick={addFirstConditionGroup}
                                            >
                                                + ADD CONDITION
                                            </Button>
                                        </HoverAuthorizeTooltip>
                                    </div>
                                ) : (
                                    ""
                                )}

                                {/* If actions to list  */}
                                <div className="conditions-list">
                                    {/* //////////////Condition Groups//////////////// */}
                                    <div className="condition-group">
                                        {conditionList.length
                                            ? renderSlots()
                                            : ""}
                                    </div>

                                    {/* ////////////////New Condition Group////////////////// */}
                                    {conditionList.length ? (
                                        <HoverAuthorizeTooltip permission="event:create">
                                            <Button
                                                variant="primary add-item"
                                                onClick={addNewConditionGroup}
                                            >
                                                +
                                            </Button>
                                        </HoverAuthorizeTooltip>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="divider"></div>
                            </Col>
                            <Col lg="6">
                                <div className="arrow-circle">
                                    <i className="material-icons right">
                                        keyboard_arrow_right
                                    </i>
                                </div>

                                <div className="when-do-title">Do</div>
                                {/* If No actions to list  */}
                                {!actionList.length ? (
                                    <div className="actions-list-empty">
                                        <p>
                                            Add the actions this event will run.
                                        </p>
                                        <HoverAuthorizeTooltip permission="event:create">
                                            <Button
                                                variant="outline-primary"
                                                onClick={() =>
                                                    setSelectAction(true)
                                                }
                                            >
                                                + ADD ACTION
                                            </Button>
                                        </HoverAuthorizeTooltip>
                                    </div>
                                ) : (
                                    ""
                                )}
                                {/* If actions to list  */}
                                <div className="actions-list">
                                    {renderActions()}

                                    {actionList.length ? (
                                        <HoverAuthorizeTooltip permission="event:create">
                                            <Button
                                                variant="primary add-item"
                                                onClick={addNewAction}
                                            >
                                                +
                                            </Button>
                                        </HoverAuthorizeTooltip>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
            {/* Select Condition Modal */}
            <Modal
                show={selectCondition}
                onHide={handleConditionsClose}
                animation={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Add Condition</Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={handleConditionsClose}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Modal.Header>

                <Modal.Body>
                    <Form className="condition-options-list">
                        <div className="scroll-list float-left">
                            <div
                                className="modal-option"
                                onClick={showLDSUSelection}
                            >
                                <img
                                    src={sensorStatusIcon}
                                    alt="sensor-status"
                                />
                                <div className="float-left">
                                    <h4>LDSU Status</h4>
                                    <p>E.g. “When LDSU goes offline”</p>
                                </div>
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </div>

                            <div
                                className="modal-option"
                                onClick={showSensorSelection}
                            >
                                <img src={sensorValueIcon} alt="sensor-value" />
                                <div className="float-left">
                                    <h4>Sensor Value</h4>
                                    <p>
                                        E.g. “When sensor value rises above
                                        30°C”
                                    </p>
                                </div>
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </div>
                            <div
                                className="modal-option"
                                onClick={showGatewaySelection}
                            >
                                <img
                                    src={gatewayStatusIcon}
                                    alt="gateway-status"
                                />
                                <div className="float-left">
                                    <h4>Gateway Status</h4>
                                    <p>E.g. “When gateway comes online”</p>
                                </div>
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </div>
                            <div
                                className="modal-option"
                                onClick={proceedToRecurrencePage}
                            >
                                <img src={recurrenceIcon} alt="recurrence" />
                                <div className="float-left">
                                    <h4>Recurrence</h4>
                                    <p>E.g. “Every Sunday and Monday”</p>
                                </div>
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </div>
                            <div
                                className="modal-option"
                                onClick={proceedToOnDatePage}
                            >
                                <img
                                    className="float-left mr-3"
                                    src={dateIcon}
                                    alt="date"
                                />
                                <div className="float-left">
                                    <h4>On a Date</h4>
                                    <p>E.g. “On 1st of January”</p>
                                </div>
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Select Action Modal */}
            <Modal
                show={selectAction}
                onHide={handleActionsClose}
                animation={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Add Action</Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={handleActionsClose}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Modal.Header>

                <Modal.Body>
                    <Form className="condition-options-list">
                        <div className="scroll-list float-left">
                            <div
                                className="modal-option"
                                onClick={() => {
                                    showAction(ACTION_SMS);
                                }}
                            >
                                <img src={ActionSMS} alt="action-sms" />
                                <div className="float-left">
                                    <h4>Send SMS</h4>
                                    <p>Send SMS to one or more phone numbers</p>
                                </div>
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </div>
                            <div
                                className="modal-option"
                                onClick={() => {
                                    showAction(ACTION_EMAIL);
                                }}
                            >
                                <img src={ActionEmail} alt="action-email" />
                                <div className="float-left">
                                    <h4>Send Email</h4>
                                    <p>
                                        Send email to one or more email
                                        addresses
                                    </p>
                                </div>
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </div>
                            <div
                                className="modal-option"
                                onClick={() => {
                                    showAction(ACTION_NOTIF);
                                }}
                            >
                                <img
                                    src={ActionNotification}
                                    alt="action-notification"
                                />
                                <div className="float-left">
                                    <h4>Send Notification</h4>
                                    <p>
                                        Send notification to one or more users
                                    </p>
                                </div>
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </div>
                            <div
                                className="modal-option"
                                onClick={showActuatorSelection}
                            >
                                <img
                                    src={ActionActuator}
                                    alt="action-actuator"
                                />
                                <div className="float-left">
                                    <h4>Trigger Actuator</h4>
                                    <p>Add an action to trigger Actuator</p>
                                </div>
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </div>
                            <div
                                className="modal-option"
                                onClick={showDelayModal}
                            >
                                <img src={DelayIcon} alt="delay event" />
                                <div className="float-left">
                                    <h4>Add Delay</h4>
                                    <p>
                                        Add a delay before an action gets
                                        triggered
                                    </p>
                                </div>
                                <i className="material-icons right">
                                    keyboard_arrow_right
                                </i>
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* Select Gateway Modal */}
            {[CONDITION_GATEWAY_STATUS].indexOf(selectedCondition) >= 0 && (
                <Modal
                    show={selectGateway}
                    onHide={closeGatewaySelection}
                    animation={false}
                    centered
                >
                    <Modal.Header>
                        <Modal.Title>Select Gateway</Modal.Title>
                        <Button
                            variant=""
                            className="close-button"
                            onClick={closeGatewaySelection}
                        >
                            <span className="material-icons">close</span>
                        </Button>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="floar-left">
                            <FormControl
                                type="text"
                                placeholder="Search.."
                                className="mb-4"
                                onChange={(e: any) => {
                                    searchGateways(e.target.value);
                                }}
                            ></FormControl>
                            <div className="scroll-multiple-list float-left">
                                {renderGateways()}
                            </div>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {radioBtnSelected ? (
                            <Button
                                variant="primary"
                                onClick={() => showCondition()}
                            >
                                {selectedCondition === CONDITION_GATEWAY_STATUS
                                    ? "DONE"
                                    : "NEXT"}
                            </Button>
                        ) : (
                            ""
                        )}
                    </Modal.Footer>
                </Modal>
            )}
            {/* Select LDSU Modal */}
            {selectModalVisible &&
                [CONDITION_LDSU_STATUS].indexOf(selectedCondition) >= 0 && (
                    <LDSUSelectModal
                        {...{
                            unitType,
                            isVisible: selectModalVisible,
                            onClose: () => {
                                setSelectModalVisible(false);
                            },
                            onFinish: (device: any) => {
                                if (unitType === LDSUTypeDict.LDSU.label) {
                                    showCondition(undefined, {
                                        selectedSensorDetails: {
                                            ...selectedSensorDetails,
                                            name: device.name,
                                            ldsuUUID: device.ldsu_uuid,
                                            gatewayId: device.gatewayId,
                                            gatewayName: device.gatewayName,
                                            bus: device.bus,
                                        },
                                    });
                                }
                            },
                        }}
                    />
                )}
            {/* Select device Modal */}
            {((selectModalVisible &&
                [ACTION_ACTUATOR].indexOf(unitType) >= 0) ||
                [CONDITION_SENSOR_VALUE, CONDITION_SENSOR_STATUS].indexOf(
                    selectedCondition
                ) >= 0) && (
                <SensorActuatorSelectModal
                    {...{
                        unitType,
                        isVisible: selectModalVisible,
                        onClose: () => {
                            setSelectModalVisible(false);
                        },
                        onFinish: (device: any) => {
                            switch (unitType) {
                                case DeviceTypeDict.sensor.label:
                                    showCondition(undefined, {
                                        selectedSensorDetails: {
                                            ...selectedSensorDetails,
                                            name: device.sensor_name,
                                            bus: device.bus,
                                            ldsuUUID: device.ldsu_uuid,
                                            said: device.SAID,
                                            gatewayId: device.gatewayId,
                                            type: device.sensor_type,
                                            gatewayName: device.gatewayName,
                                            unit: device.unit,
                                            min: device.min,
                                            max: device.max,
                                        },
                                    });
                                    break;

                                case DeviceTypeDict.actuator.label:
                                    showAction(ACTION_ACTUATOR, {
                                        selectedActuatorDetails: {
                                            name: device.sensor_name,
                                            ldsuUUID: device.ldsu_uuid,
                                            said: device.SAID,
                                            gatewayId: device.gatewayId,
                                            type: device.sensor_type,
                                            // TODO: update actuator status/ config when using new event service
                                            // config_mode,
                                            // actionMode
                                        },
                                    });
                                    break;

                                default:
                                    break;
                            }
                        },
                    }}
                />
            )}
            {/* Set delay modal */}
            <SetDelayModal
                delay={editDelayObj.delay}
                isOpen={selectModalVisible && unitType === "delay"}
                onClose={() => {
                    setSelectModalVisible(false);
                    setUnitType("");
                    setEditDelayObj({ delay: defaultDelayValue });
                }}
                onError={(error) => {
                    setModalShow(true);
                    setModalType("err");
                    setModalContent(error);
                }}
                onSave={handleEditDelayAction}
            />

            <DefaultModal
                modalShow={modalShow}
                setModalShow={setModalShow}
                modalType={modalType}
                modalContent={modalContent}
                okAction={() => {
                    if (toDelete.type === EVENT) {
                        history.push(`/events`);
                    } else {
                        setModalShow(false);
                    }
                }}
                deleteFunction={() => {
                    if (toDelete.type === CONDITION) {
                        deleteCondition();
                    } else if (toDelete.type === ACTION) {
                        deleteAction();
                    } else if (toDelete.type === EVENT) {
                        deleteEvent();
                    }
                }}
            />
        </ContentWrapper>
    );
};

export default EventDetails;
