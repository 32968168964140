import { isObject, isString } from "lodash";
import AxiosInstance from "./axiosInstance";
import LocalStorageService from "service/localStorageService";
const APP_DASHBOARD_API = process.env.REACT_APP_DASHBOARD_API;

export const getAllJSON = async () => {
    try {
        // Get Master List of JSON File
        const response: any = await AxiosInstance.get(
            APP_DASHBOARD_API + `/objinfo/master_list.json`
        );
        if (response.status === 200) {
            // Filter to get the list of OBJ ID that needs to be called
            const listOfObjId = response.data.devices.map((i: any) => i.obj);

            // Loop calls based on the list and push into an array.
            const listOfIndiJSON = [];

            for (const objId of listOfObjId) {
                const oneJSONRes: any = await AxiosInstance.get(
                    APP_DASHBOARD_API + `/objinfo/${objId}.json`
                );
                if (isString(oneJSONRes.data)) {
                    try {
                        listOfIndiJSON.push(JSON.parse(oneJSONRes.data));
                    } catch (err) {
                        continue;
                    }
                } else if (isObject(oneJSONRes.data)) {
                    listOfIndiJSON.push(oneJSONRes.data);
                }
            }

            // Store the array of json files in local storage
            LocalStorageService.setItem("listOfJSON", listOfIndiJSON);
        }
        return response;
    } catch (err) {
        return err;
    }
};

type MetricInfo = {
    [key: string]: {
        name: string;
        metric: string;
        metrics: {
            [unit: string]: string;
        };
    };
};

export type MetricInfoResponse = {
    cls: MetricInfo;
    app: MetricInfo;
};

export const getMetricInfo = async (): Promise<MetricInfoResponse> => {
    try {
        const masterMetricList = await AxiosInstance.get(
            APP_DASHBOARD_API + `/metricinfo/metrics_info.json`
        );

        LocalStorageService.setItem(
            "metricInfo",
            masterMetricList.data.metrics
        );
        return masterMetricList.data.metrics;
    } catch (err) {
        return {
            cls: {},
            app: {},
        };
    }
};
