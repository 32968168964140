import { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { getActuatorCommand, fetchDetailDevice } from "service/gatewayService";
import ActuatorConfiguration from "components/gateways/actuators/ActuatorConfiguration";
import RelatedEvents from "components/gateways/actuators/RelatedEvents";
import ActuatorInfo from "components/gateways/actuators/ActuatorInfo";
import ContentWrapper from "components/content-wrapper/ContentWrapper";
import { isEqual, isEmpty, get } from "lodash";
import { HttpStatus, LDSUTabPanel } from "constant";
import {
    getPrimaryUnit,
    getPrimaryUnitName,
    isHttpSuccess,
} from "utils/functions";
import { ActuatorConfigurationContext } from "context/ActuatorConfigurationContext";
import { getActuatorConfigTemplate } from "utils/actuatorFunctions";

const { FORBIDDEN } = HttpStatus;

const ActuatorDetails = () => {
    const {
        gatewayId,
        ldsuId,
        said,
        busNumber,
    }: { gatewayId: string; ldsuId: string; said: string; busNumber: string } =
        useParams();
    const location: any = useLocation();
    const [initState, setInitState] = useState<any>(location.state || {});
    const { type, gatewayName } = initState;
    const [ldsuName, setLdsuName] = useState("");
    const [actuator, setActuator]: any = useState({});
    const [editedActuator, setEditedActuator]: any = useState({});
    const [editMode, setEditMode] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isForbiddenResource, setIsForbiddenResource] = useState(false);
    const [isIOControllerState, setIsIOControllerState]: any = useState({
        isIOController: false,
        isAnalog: false,
        unit: "",
        minmax: [],
    });

    useEffect(() => {
        const editedActuatorConfig = getActuatorConfigTemplate(editedActuator);
        const actuatorConfig = getActuatorConfigTemplate(actuator);

        setEditMode(!isEqual(actuatorConfig, editedActuatorConfig));
    }, [editedActuator, actuator]);

    useEffect(() => {
        fetchDetailDevice({
            gateway_id: gatewayId,
            device_id: ldsuId,
            said: said,
        }).then((res) => {
            if (get(res, "status") === FORBIDDEN) {
                setIsForbiddenResource(true);
                return;
            }

            let _type = type;

            if (!res) {
                return;
            }

            if (isEmpty(initState)) {
                setInitState({
                    type: res.type,
                    gatewayName: res.gateway_name,
                    name: res.NAME,
                });
                _type = res.type;
            }

            setLdsuName(res.NAME);
            if (res.APP) {
                //if ldsu is IO controller

                const IOControllerCLS = res.infoJson.SNS[said].CLS;

                const defaultUnitObj: any = getPrimaryUnitName(res.APP[said]);

                const unit = getPrimaryUnit(res.APP[said]);

                // CLS = 32769 is Analog IO controller, otherwise digital IO controller
                if (IOControllerCLS === "32769") {
                    setIsIOControllerState({
                        ...isIOControllerState,
                        isIOController: true,
                        isAnalog: true,
                        isGenericAnalogOutput:
                            _type.toLowerCase() === "generic analog output",
                        unit: unit,
                        minmax: [defaultUnitObj.MIN, defaultUnitObj.MAX],
                        format: defaultUnitObj.VALUETYPE,
                        accuracy: defaultUnitObj.ACCURACY,
                        appId: res.APP[said].APPID,
                    });
                } else {
                    setIsIOControllerState({
                        ...isIOControllerState,
                        isIOController: true,
                        isAnalog: false,
                        appId: res.APP[said].APPID,
                    });
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gatewayId, ldsuId, said]);

    useEffect(() => {
        const fetchActuator = async () => {
            const actuatorRes: any = await getActuatorCommand(
                gatewayId,
                ldsuId,
                said
            );

            if (isHttpSuccess(actuatorRes.status)) {
                const { data: actuator } = actuatorRes;

                setActuator(actuator);
                setEditedActuator(actuator);
            }
            setIsPageLoading(false);
        };
        fetchActuator();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gatewayId, ldsuId, said]);

    return (
        <ContentWrapper
            isLoading={isPageLoading}
            isForbiddenResource={isForbiddenResource}
        >
            <ActuatorConfigurationContext.Provider
                value={{
                    actuator,
                    setActuator,
                    gatewayName,
                    ldsuName,
                    type,
                    editedActuator,
                    setEditedActuator,
                    editMode,
                    isIOControllerState,
                    setEditMode,
                }}
            >
                <div className="page-content">
                    <Container fluid>
                        <Row>
                            <Col sm={12} className="sensor-header">
                                <h5 className="page-title">{actuator.name}</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <Breadcrumb className="w-100">
                                    <li className="breadcrumb-item">
                                        <Link
                                            to={{
                                                pathname: `/gateway-details/${gatewayId}`,
                                            }}
                                        >
                                            Gateway
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link
                                            to={{
                                                pathname: `/gateway-details/${gatewayId}/LDSBus/${busNumber}`,
                                                state: {
                                                    gatewayName: gatewayName,
                                                    panel: LDSUTabPanel.Actuator,
                                                },
                                            }}
                                        >
                                            All Actuators
                                        </Link>
                                    </li>
                                    <Breadcrumb.Item active>
                                        Actuator
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Row className="flex-row-reverse">
                            <ActuatorInfo />

                            <ActuatorConfiguration />
                        </Row>

                        <RelatedEvents />
                    </Container>
                </div>
            </ActuatorConfigurationContext.Provider>
        </ContentWrapper>
    );
};
export default ActuatorDetails;
