import { useState, useEffect, useContext } from "react";
import TopMenu from "./TopNav";
import SideNav from "./SideNav";
import GlobalModal from "../modals/GlobalModal/GlobalModal";
import { Redirect } from "react-router-dom";
import { useAuth } from "context/auth";
import { useHistory, useParams } from "react-router-dom";
import OrganisationService from "service/organisationService";
import LocalStorageService from "service/localStorageService";
import AccountService from "service/accountService";
import {
    getLatestSubscription,
    getWalletStatus,
} from "service/subscriptionService";
import { getAllTokens } from "utils/functions";
import {
    changeOrganizationAction,
    storeOrganizationAction,
    storeUserAction,
    storeOgrActiveTabAction,
    verifyOrgOwnerAction,
    restoreData,
    updateModalDataAction,
    storePanelsAction,
    storeTokenBalanceAction,
    updateSubscriptionStatus,
    storeSubscriptionID,
    storeOrgMembersAction,
    storeOrgGroupsAction,
    storeOrgRolesAction,
} from "store/actions";
import { EventKey, SubscriptionStatus } from "constant";
import { AppContext } from "context/appContext";
import useOrganizationVanilla, {
    changeToOrganization,
} from "hooks/organization";
import OrganizationWrapperModal from "../modals/OrganizationWrapperModal";
import { useWalletStore } from "hooks/wallet";
import { usePermissions } from "hooks/common";
import { OrganisationDto } from "types";

const Layout = (props: any) => {
    const [showSideMenu, sideMenu] = useState(false);
    const setSideMenu = () => {
        sideMenu(!showSideMenu);
    };
    const history = useHistory();
    const params: any = useParams();
    const [isLoggedOut, setLoggedOut] = useState(false);
    const { context: { setAuthTokens }, signOut } = useAuth();
    const [isOrgLoaded, updateOrgLoadedStatus] = useState(false);

    const { storeData, storeDispatchActions } = useContext(AppContext);

    const { organization, user, globalModal, subscription } = storeData;

    usePermissions();

    const currentWallet = useWalletStore.getState().wallet;

    useEffect(() => {
        const { userData } = user;
        if (userData.email && !isOrgLoaded && !organization.isUserOrgLoaded) {
            OrganisationService.getUserOrgs().then((res) => {
                if (res.status === 200) {
                    const { owner, member } = res.data;

                    let currentOrg;
                    let orgList = [...owner, ...member].toCamelCase();

                    if (params.orgId) {
                        currentOrg = orgList.find(
                            (org: any) => org.uuid === params.orgId
                        );
                    }

                    if (!currentOrg) {
                        const orgId =
                            organization.currentOrgId ||
                            LocalStorageService.getItem("orgId");
                        currentOrg = orgList.find(
                            (org: any) => org.uuid === orgId
                        );

                        // If can not find anything will get from api response
                        if (!currentOrg) {
                            currentOrg = orgList[0];
                        }
                    }

                    LocalStorageService.setItem("orgIdList", orgList);
                    LocalStorageService.setItem("orgId", currentOrg.uuid);

                    updateOrgLoadedStatus(true);
                    storeDispatchActions(storeOrganizationAction({ orgList }));
                    storeDispatchActions(
                        changeOrganizationAction({
                            orgInfo: currentOrg,
                        })
                    );

                    storeDispatchActions(
                        verifyOrgOwnerAction({
                            isOwner: userData.email === currentOrg.owner,
                        })
                    );

                    storeDispatchActions(
                        storeOrgMembersAction({
                            orgMembers: currentOrg.members,
                        })
                    );

                    storeDispatchActions(
                        storeOrgGroupsAction({
                            orgGroups: currentOrg.groups,
                        })
                    );

                    storeDispatchActions(
                        storeOrgRolesAction({
                            orgRoles: currentOrg.roles,
                        })
                    );
                }
            });
        }
    }, [
        isOrgLoaded,
        storeDispatchActions,
        user,
        params.orgId,
        organization.currentOrgId,
        organization.isUserOrgLoaded,
    ]);

    useEffect(() => {
        !user.isLoaded &&
            AccountService.getAccountInfo().then((response: any) => {
                if (response.status === 200) {
                    storeDispatchActions(
                        storeUserAction({
                            userData: response.data as object,
                            isLoaded: true,
                        })
                    );
                }
            });
    }, [user.isLoaded, storeDispatchActions]);

    useEffect(() => {
        if (!subscription.isLoaded) {
            getAllTokens().then((total: any) => {
                storeDispatchActions(
                    storeTokenBalanceAction({
                        tokenBalance: total,
                        isLoaded: true,
                    })
                );
            });
        }
    }, [subscription.isLoaded, storeDispatchActions, subscription]);

    useEffect(() => {
        getLatestSubscription()
            .then((latestSubscription: any) => {
                if (
                    [
                        SubscriptionStatus.ACTIVE,
                        SubscriptionStatus.CANCELED,
                        SubscriptionStatus.PENDING,
                    ].includes(latestSubscription.status)
                ) {
                    storeDispatchActions(
                        updateSubscriptionStatus({
                            isSubscribed: true,
                            hasSubscribed: true,
                        })
                    );
                    storeDispatchActions(
                        storeSubscriptionID(latestSubscription.uuid)
                    );
                } else {
                    storeDispatchActions(
                        updateSubscriptionStatus({
                            isSubscribed: false,
                            hasSubscribed: true,
                        })
                    );
                }
            })
            .catch((err: any) => {
                storeDispatchActions(
                    updateSubscriptionStatus({
                        isSubscribed: false,
                        hasSubscribed: false,
                    })
                );
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeDispatchActions]);

    useEffect(() => {
        // TODO: Find alternative method instead of using setTimeout
        const sessionStatus = setTimeout(() => {
            if (LocalStorageService.getItem("sessionStatus") === "expired") {
                storeDispatchActions(
                    updateModalDataAction({
                        isShow: true,
                        mainMessage: "Session Expired",
                        subMessage: ["Session has expired. Please relogin."],
                    })
                );
            }
        }, 1000);

        return () => {
            clearTimeout(sessionStatus);
        };
    }, [storeDispatchActions]);

    useEffect(() => {
        if (organization.currentOrgId) {
            getWalletStatus(organization.currentOrgId);
        }
    }, [organization.currentOrgId, currentWallet]);

    if (isLoggedOut) {
        return <Redirect to="/" />;
    }

    const logout = async () => {
        setLoggedOut(true);
        signOut();
    };

    const handleChangeOrg = (
        currentOrg: OrganisationDto,
        isRedirect = true
    ) => {
        if (organization.currentOrgId === currentOrg.uuid) return;

        LocalStorageService.setItem("orgId", currentOrg.uuid);
        window.loadAuthorizationConfig().then(() => {
            switchTopNavUser(currentOrg);
            useOrganizationVanilla.setState({ orgId: currentOrg.uuid });
            changeToOrganization.setState({
                toOrganization: undefined,
            });

            isRedirect && history.push("/dashboard");
        });
    };

    useOrganizationVanilla.subscribe((state) => {
        if (state.isChangeOrganization === true && state.orgId) {
            state.setIsChangeOrganization(undefined);
            const organization =
                state.organizationList.find(
                    (org) => org.uuid === state.orgId
                ) || {};
            organization && handleChangeOrg(organization, false);
        }
    });

    const switchTopNavUser = (organization: OrganisationDto) => {
        const { userData } = user;
        storeDispatchActions(
            changeOrganizationAction({
                orgInfo: organization,
            })
        );

        storeDispatchActions(
            verifyOrgOwnerAction({
                isOwner: userData.email === organization.owner,
            })
        );

        storeDispatchActions(
            storeOgrActiveTabAction({
                tab: EventKey.members,
            })
        );

        storeDispatchActions(storePanelsAction({ panels: [] }));
    };

    const handleCloseModal = () => {
        if (LocalStorageService.getItem("sessionStatus") === "expired") {
            const cacheOrgId: string = organization.currentOrgId;
            storeDispatchActions(
                updateModalDataAction({
                    isShow: false,
                    mainMessage: "",
                    subMessage: [],
                })
            );
            setAuthTokens("");
            setLoggedOut(true);
            storeDispatchActions(restoreData());
            LocalStorageService.clearAll();
            LocalStorageService.setItem("lastLoggedOrgId", cacheOrgId);
        } else if (globalModal.redirectTo) history.push(globalModal.redirectTo);
        storeDispatchActions(
            updateModalDataAction({
                isShow: false,
                mainMessage: "",
                subMessage: [],
            })
        );
    };

    return (
        <div>
            <TopMenu
                logout={logout}
                sideMenu={showSideMenu}
                setSideMenu={setSideMenu}
                userInfo={user.userData}
                orgList={organization.orgList}
                currentOrgId={organization.currentOrgId}
                currentOrgInfo={organization.currentOrgInfo}
                onOrgChange={handleChangeOrg}
                subscription={subscription}
            />

            <SideNav sideMenu={showSideMenu} setSideMenu={setSideMenu}>
                <OrganizationWrapperModal>
                    {props.children}
                </OrganizationWrapperModal>
            </SideNav>

            <GlobalModal
                isShow={globalModal.isShow}
                mainMessage={globalModal.mainMessage}
                subMessage={globalModal.subMessage}
                onClose={() => handleCloseModal()}
            />
        </div>
    );
};

export default Layout;
