import { ACTION_FAIL_MESSAGE, HttpStatus, SubscriptionDict } from "constant";
import { useSubscriptionContext } from "context/subscriptionContext";
import { useEffect, useState } from "react";
import { Col, Button, Form, Row, Modal, Spinner } from "react-bootstrap";
import {
    getAllAddOns,
    updateSubscriptionAddonById,
} from "service/subscriptionService";
import styledConst from "styles";
import { getAPIError, showErrorAlert, showSuccessAlert } from "utils/alert";
import { isHttpSuccess } from "utils/functions";
import styled from "@emotion/styled";

// ========================= STYLING =========================

const CustomFormStyle = styled(Form.Label)`
    color: ${styledConst.Primary_Blue_4};
    display: flex;
    width: 115px;
    height: 100%;
    align-items: center;
    margin-bottom: 0;
`;

const AddOnInformation = () => {
    // ========================= CONTEXT/STATES =========================
    const {
        subscription: { plan: { addons = [] } = {}, uuid, status },
        setSubscription,
    }: any = useSubscriptionContext();

    const [purchasedQuantity, setPurchasedQuantity] = useState("1");
    const [showPurchaseComponent, setShowPurchaseComponent] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [modalContent, setModalContent] = useState("");
    const [isActionPending, setIsActionPending] = useState(false);
    const [purchasedTokenAddon, setPurchasedTokenAddon] = useState<any>({});

    // ========================= DEFINITIONS =========================
    const MODAL_ERROR = "err";
    const MODAL_CONFIRM = "confirm";
    const MODAL_ADD_ADDON_CONFIRM = "addonConfirm";
    const MODAL_RESUBSCRIPTION_CONFIRM = "resubscriptionConfirm";
    const MODAL_SUBSCRIPTION_CONFIRM = "subscriptionConfirm";
    const MODAL_CANCELLATION_CONFIRM = "cancellationConfirm";
    const MODAL_CANCELLATION_PRECONFIRM = "cancellationPreconfirm";
    const MODAL_SUCCESS = "success";
    const MODAL_PENDING = "pending";

    // ========================= USEEFFECT =========================
    useEffect(() => {
        const fetchAvailableAddons = async () => {
            const addOnRes: any = await getAllAddOns();

            if (addOnRes.status === HttpStatus.OK) {
                const purchasedTokenAddon = addOnRes.data.data.find(
                    ({
                        bt_id,
                        content: {
                            token: { type_ },
                        },
                    }: {
                        bt_id: string;
                        content: { token: { type_: string } };
                    }) => bt_id === "token_1m" && type_ === "PURCHASED"
                );

                setPurchasedTokenAddon(purchasedTokenAddon);
            }
        };
        fetchAvailableAddons();
    }, []);

    useEffect(() => {
        let showAddon: boolean;

        const purchasedTokenAddOn_ = addons.find(
            ({
                bt_id,
                content: {
                    token: { type_ },
                },
            }: {
                bt_id: string;
                content: { token: { type_: string } };
            }) => bt_id === "token_1m" && type_ === "PURCHASED"
        );

        if (purchasedTokenAddOn_ || status !== "ACTIVE") {
            showAddon = false;
        } else {
            showAddon = true;
        }

        setShowPurchaseComponent(showAddon);
    }, [addons, showPurchaseComponent, status]);

    // ========================= FUNCTIONS =========================
    const addPurchasedTokenConfirmation = () => {
        setModalShow(true);
        setModalType(MODAL_ADD_ADDON_CONFIRM);
    };

    const addPurchasedToken = async () => {
        setModalShow(false);
        setIsActionPending(true);

        const response = await updateSubscriptionAddonById({
            subscriptionId: uuid,
            addonId: purchasedTokenAddon.uuid,
            quantity: Number(purchasedQuantity),
            action: "add",
        });
        if (isHttpSuccess(response.status)) {
            setSubscription(response.data);
            showSuccessAlert({ message: `Add-on purchased successfully.` });
            setPurchasedQuantity("1");
            setIsActionPending(false);
        } else {
            showErrorAlert(getAPIError(response, ACTION_FAIL_MESSAGE));
            setIsActionPending(false);
        }
    };

    const renderModalIcon = () => {
        if (
            modalType === MODAL_ERROR ||
            modalType === MODAL_CONFIRM ||
            modalType === MODAL_PENDING ||
            modalType === MODAL_CANCELLATION_PRECONFIRM
        ) {
            return <span className="material-icons">warning</span>;
        } else if (
            modalType === MODAL_RESUBSCRIPTION_CONFIRM ||
            modalType === MODAL_CANCELLATION_CONFIRM ||
            modalType === MODAL_SUBSCRIPTION_CONFIRM ||
            modalType === MODAL_ADD_ADDON_CONFIRM
        ) {
            return <span className="material-icons">help_outline</span>;
        }
        return <span className="material-icons">done</span>;
    };

    const renderModalTitle = () => {
        if (modalType === MODAL_ERROR) {
            return <h3 className="mb-3">Error</h3>;
        } else if (modalType === MODAL_ADD_ADDON_CONFIRM) {
            return <h3 className="mb-3">Confirm</h3>;
        } else if (modalType === MODAL_PENDING) {
            return <h3 className="mb-3">Pending</h3>;
        }
        return <h3 className="mb-3">Success</h3>;
    };

    const renderModalButton = () => {
        if (modalType === MODAL_ERROR || modalType === MODAL_PENDING) {
            return (
                <Button variant="primary" onClick={() => setModalShow(false)}>
                    OK
                </Button>
            );
        } else if (modalType === MODAL_ADD_ADDON_CONFIRM) {
            return (
                <>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setModalShow(false);
                        }}
                    >
                        NO
                    </Button>

                    <Button variant="primary" onClick={addPurchasedToken}>
                        YES
                    </Button>
                </>
            );
        }
    };

    const qtyField: any = document.querySelector("#qtyField");
    qtyField?.addEventListener("keydown", (e: any) => {
        if (
            !(
                (e.keyCode > 95 && e.keyCode < 106) ||
                (e.keyCode > 47 && e.keyCode < 58) ||
                e.keyCode === 8
            )
        ) {
            e.preventDefault();
        }
    });

    return (
        <>
            {showPurchaseComponent && (
                <Row className="manage-subscription-info mt-5">
                    <Col className="subscr-details" sm={12}>
                        <p>Add-on</p>
                    </Col>

                    <div className="purchase-tokens">
                        <div>
                            <p>{SubscriptionDict[purchasedTokenAddon.bt_id]}</p>
                            <p className="period">
                                Monthly renewing additional tokens, starting
                                from next billing date.
                            </p>
                        </div>
                        <div className="mt-2 d-flex">
                            <Form className="form d-flex align-items-center add-on-form">
                                <CustomFormStyle className="mr-2">
                                    <span className="add-form-qty-label">
                                        Qty
                                    </span>
                                    {"  "}
                                    (Max 100)
                                </CustomFormStyle>

                                <Form.Control
                                    type="number"
                                    className="float-left"
                                    min={1}
                                    max={100}
                                    maxLength={3}
                                    id="qtyField"
                                    value={purchasedQuantity}
                                    onKeyDown={(e: any) => {
                                        if (
                                            e.key === "Backspace" &&
                                            e.target.value.length === 1
                                        ) {
                                            e.preventDefault();
                                        }
                                    }}
                                    onChange={(e) => {
                                        if (
                                            Number(e.target.value) > 100 ||
                                            Number(e.target.value) < 1
                                        ) {
                                            return;
                                        }
                                        setPurchasedQuantity(e.target.value);
                                    }}
                                ></Form.Control>
                                <div>
                                    USD{" "}
                                    {(
                                        Number(purchasedTokenAddon.amount) *
                                        Number(purchasedQuantity)
                                    ).toFixed(2)}
                                    /mo
                                </div>
                            </Form>
                            <Button
                                onClick={addPurchasedTokenConfirmation}
                                disabled={!purchasedQuantity}
                            >
                                BUY ADD-ON
                            </Button>
                        </div>
                    </div>
                </Row>
            )}
            <Modal
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`no-header ${
                    [MODAL_SUCCESS, MODAL_ADD_ADDON_CONFIRM].includes(modalType)
                        ? "primary"
                        : "danger"
                }`}
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">{renderModalIcon()}</div>
                    {renderModalTitle()}

                    {modalType === MODAL_ADD_ADDON_CONFIRM ? (
                        <div>
                            <p>
                                Your added payment method will charge additional
                                <span className="font-weight-bold">
                                    {" "}
                                    USD{" "}
                                    {(
                                        Number(purchasedTokenAddon?.amount) *
                                        Number(purchasedQuantity)
                                    ).toFixed(2)}{" "}
                                    every month
                                </span>{" "}
                                together with your monthly subscription charge.
                            </p>
                            <p>
                                The new billing will start on the next billing
                                date and your account will be credited
                                additional{" "}
                                <span className="font-weight-bold">
                                    {" "}
                                    {purchasedQuantity} x{" "}
                                    {purchasedTokenAddon?.content?.token?.amount.toLocaleString()}{" "}
                                    purchased tokens together with your
                                    subscription plan from the next billing date
                                    onwards
                                </span>{" "}
                                until you cancel.
                            </p>
                            <p>Do you want to continue?</p>
                        </div>
                    ) : (
                        <p className="mb-4">{modalContent}</p>
                    )}

                    {renderModalButton()}
                </Modal.Body>
            </Modal>
            <Modal
                centered
                show={isActionPending}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header"
            >
                <Modal.Body className="text-center mt-3 mb-5 mr-4">
                    <Spinner
                        className="centered-spinner"
                        animation="border"
                        variant="primary"
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AddOnInformation;
