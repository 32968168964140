import React, { ChangeEvent } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import * as _ from "lodash";
import {
    useCreateDashboardStore,
    CreateChartState,
    CHART_NAME_PATTERN,
    CHART_ERROR_MESSAGE,
} from ".";
import DashboardCreateSelectGatewayModal from "./modal/SelectGateways";
import CreateChartModal from "./modal/CreateChart";

export enum GatewayChartFor {
    Storage = "Storage Usage",
    Status = "Online/Offline Status",
}

export enum GateWayChartType {
    Donut = "Donut Chart",
    Pie = "Pie Chart",
}

const GatewayChartCreateData: CreateChartState = {
    title: "Create Gateway Charts...",
    data: [],
    columns: [
        {
            key: "chart_name",
            name: "Chart Name",
        },
        {
            key: "gateway_name",
            name: "Gateway",
        },
        {
            key: "chart_for",
            name: "Chart for",
            render: (data: any) => (
                <>
                    {
                        GatewayChartFor[
                            data.chart_for.capitalize() as keyof typeof GatewayChartFor
                        ]
                    }
                </>
            ),
        },
        {
            key: "chart_type",
            name: "Chart Type",
        },
    ],
    transform: (data: any) => ({
        attributes: [
            {
                type_: data.chart_for,
                gateway_id: data.gateway_id,
            },
        ],
        source: "GATEWAY",
        name: data.chart_name,
        chart: String(data.chart_type).toUpperCase(),
    }),
    validate() {
        return this.data.map((item) => ({
            path: `[name="${item.gateway_id}.${item.chart_for}.chart_name"]`,
            isValid: CHART_NAME_PATTERN.test(item.chart_name.trim()),
            message: CHART_ERROR_MESSAGE,
        }));
    },
};

const DashboardCreateGatewayComponent: React.FunctionComponent = () => {
    const [showSelectModal, setShowSelectModal] = React.useState(false);
    const [selectedGateways, setSelectedGateways] = React.useState<{
        [key: string]: any;
    }>({});
    const [formValues, setFormValues] = React.useState<{
        [key: string]: any;
    }>({});
    const { createModalShow, setCreateModalShow, createData } =
        useCreateDashboardStore();

    React.useEffect(() => {
        const createData: any[] = [];
        setCreateModalShow(false);

        const formValuesObject = {} as any;

        for (const key in formValues) {
            _.set(formValuesObject, String(key), formValues[key]);
        }

        for (const gateway_id in formValuesObject) {
            const chartDataList = formValuesObject[gateway_id];
            for (let chartFor in chartDataList) {
                if (GatewayChartFor.hasOwnProperty(chartFor)) {
                    const chartData = chartDataList[chartFor];
                    chartData.chart_for &&
                        createData.push({
                            ...chartDataList[chartFor],
                            chart_for: String(chartFor).toUpperCase(),
                            gateway_id,
                        });
                }
            }
        }

        useCreateDashboardStore.setState({
            createData: {
                ...GatewayChartCreateData,
                data: createData,
            },
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues]);
    
    const onGatewayChange = (event: any, data: Record<string, any>) => {
        
        useCreateDashboardStore.setState({
            createData: undefined,
        });

        const value = Object.values(data).reduce(
            (newValue, gateway) => {
               
                Object.keys(GatewayChartFor).forEach(
                    (key) => {
                        const chartName = `${gateway.gateway_id}.${key}.chart_name`;
                        const gatewayNameKey = `${gateway.gateway_id}.${key}.gateway_name`;
                        const chartFor =  `${gateway.gateway_id}.${key}.chart_for`;
                        const chartType = `${gateway.gateway_id}.${key}.chart_type`;
                        newValue = {
                            ...newValue,
                            [chartName]:
                                gateway.name +
                                " - " +
                                key,
                            [gatewayNameKey]:
                                String(
                                    gateway.name
                                ).capitalize(),
                            [chartFor]: true,
                            [chartType]: "Donut"
                        }
                });

                return newValue;
        }, {});

        setFormValues(value);
        setSelectedGateways(data);
    } 

    const formChange = (e: ChangeEvent<HTMLFormElement>) => {
        const { name, value, checked, type } = e.target;

        if (["checkbox"].includes(type)) {
            _.set(formValues, name, checked);
        } else {
            _.set(formValues, name, value);
        }

        setFormValues({ ...formValues });
    }

    return (
        <React.Fragment>
            <Row>
                <Col sm="12">
                    <p>Gateway charts</p>
                    <Button
                        className="mt-3"
                        variant="primary"
                        onClick={() => setShowSelectModal(true)}
                    >
                        Select Gateways
                    </Button>

                    <DashboardCreateSelectGatewayModal
                        show={showSelectModal}
                        onHide={() => setShowSelectModal(false)}
                        values={selectedGateways}
                        onSave={onGatewayChange}
                    />
                </Col>
            </Row>

            <Row className="mt-4 cstm-table">
                <Col sm={12}>
                    <Form
                        id="chart-edit-form"
                        onChange={formChange}
                    >
                        {!!Object.values(selectedGateways).length && (
                            <div className="table-head" key="head">
                                <Row className="no-checkbox">
                                    <Col>Gateway</Col>
                                    <Col>Chart Name</Col>
                                    <Col>Chart for</Col>
                                    <Col>Chart Type</Col>
                                    <Col md={{ span: 1 }}></Col>
                                </Row>
                            </div>
                        )}

                        {Object.values(selectedGateways).map(
                            (gateway, index) => (
                                <div
                                    key={index}
                                    id={gateway.gateway_id}
                                    className="table-row"
                                >
                                    <Row className="no-checkbox">
                                        <Col>{gateway.name}</Col>
                                        <Col>
                                            {Object.keys(GatewayChartFor).map(
                                                (key) => {
                                                    const name = `${gateway.gateway_id}.${key}.chart_name`;
                                                    return (
                                                        <Form.Control
                                                            key={key}
                                                            name={name}
                                                            type="text"
                                                            placeholder="Chart Name"
                                                            defaultValue={_.get(
                                                                formValues,
                                                                name
                                                            )}
                                                        />
                                                    );
                                                }
                                            )}
                                        </Col>
                                        <Col>
                                            {Object.keys(GatewayChartFor).map(
                                                (key) => {
                                                    const name = `${gateway.gateway_id}.${key}.chart_for`;

                                                    return (
                                                        <Form.Check
                                                            key={key}
                                                            id={name}
                                                            name={name}
                                                            type="checkbox"
                                                            defaultChecked={_.get(
                                                                formValues,
                                                                name
                                                            )}
                                                            label={
                                                                GatewayChartFor[
                                                                    key as keyof typeof GatewayChartFor
                                                                ]
                                                            }
                                                            custom
                                                        />
                                                    );
                                                }
                                            )}
                                        </Col>
                                        <Col>
                                            {Object.keys(GatewayChartFor).map(
                                                (key) => {
                                                    const name = `${gateway.gateway_id}.${key}.chart_type`;

                                                    return (
                                                        <Form.Control
                                                            key={key}
                                                            id={name}
                                                            name={name}
                                                            as="select"
                                                            defaultValue={_.get(
                                                                formValues,
                                                                name
                                                            )}
                                                        >
                                                            {Object.keys(
                                                                GateWayChartType
                                                            ).map((key) => (
                                                                <option
                                                                    key={key}
                                                                    value={key}
                                                                >
                                                                    {
                                                                        GateWayChartType[
                                                                            key as keyof typeof GateWayChartType
                                                                        ]
                                                                    }
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    );
                                                }
                                            )}
                                        </Col>
                                        <Col
                                            md={{ span: 1 }}
                                            className="d-flex justify-content-end"
                                        >
                                            <i
                                                className="material-icons cursor-pointer mr-2 remove-button-red"
                                                onClick={() => {
                                                    setSelectedGateways(
                                                        _.omit(
                                                            selectedGateways,
                                                            gateway.gateway_id
                                                        )
                                                    );
                                                }}
                                            >
                                                close
                                            </i>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        )}
                    </Form>
                </Col>
            </Row>
            <CreateChartModal
                show={createModalShow && !_.isEmpty(createData)}
                onHide={() => setCreateModalShow(false)}
            />
        </React.Fragment>
    );
};

export default React.memo(DashboardCreateGatewayComponent);
