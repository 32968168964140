import { Col, Form } from "react-bootstrap";
import { useActuatorConfigurationContext } from "context/ActuatorConfigurationContext";
import { LEVEL_MODE, LOOP_MODE, PULSE_MODE, PWM_MODE } from "constant";

const ModeOptions = () => {
    const {
        editedActuator,
        editedActuator: { mode },
        setEditedActuator,
        isIOControllerState,
    } = useActuatorConfigurationContext();
    return (
        <>
            {!isIOControllerState.isAnalog && (
                <>
                    <Form.Label className="mt-2 mb-3">Mode</Form.Label>
                    <Form.Row className="sensor-mode mb-5">
                        <Col md={2}>
                            <Form.Check
                                name="mode"
                                id={"level"}
                                checked={mode === LEVEL_MODE}
                                custom
                                type="radio"
                                label={<span className="ml-2">Level</span>}
                                onChange={() => {
                                    delete editedActuator["timing"];
                                    setEditedActuator({
                                        ...editedActuator,
                                        mode: LEVEL_MODE,
                                    });
                                }}
                            />
                        </Col>
                        <Col md={2}>
                            <Form.Check
                                name="mode"
                                id={"pulse"}
                                checked={mode === PULSE_MODE}
                                custom
                                type="radio"
                                label={<span className="ml-2">Pulse</span>}
                                onChange={() => {
                                    setEditedActuator({
                                        ...editedActuator,
                                        timing: [[0, 0]],
                                        mode: PULSE_MODE,
                                    });
                                }}
                            />
                        </Col>
                        {isIOControllerState.isIOController && (
                            <>
                                <Col md={2}>
                                    <Form.Check
                                        name="mode"
                                        id={"loop"}
                                        checked={mode === LOOP_MODE}
                                        custom
                                        type="radio"
                                        label={
                                            <span className="ml-2">Loop</span>
                                        }
                                        onChange={() => {
                                            setEditedActuator({
                                                ...editedActuator,
                                                timing: [[0, 0]],
                                                mode: LOOP_MODE,
                                            });
                                        }}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Form.Check
                                        name="mode"
                                        id={"pwm"}
                                        checked={mode === PWM_MODE}
                                        custom
                                        type="radio"
                                        label={
                                            <span className="ml-2">PWM</span>
                                        }
                                        onChange={() => {
                                            setEditedActuator({
                                                ...editedActuator,
                                                timing: [
                                                    [0, 0],
                                                    [0, 0],
                                                ],

                                                mode: PWM_MODE,
                                            });
                                        }}
                                    />
                                </Col>
                            </>
                        )}
                    </Form.Row>
                    <hr />
                </>
            )}
        </>
    );
};

export default ModeOptions;
