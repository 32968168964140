import PolarityOptions from "./PolarityOptions";
export const Level = () => {
    return (
        <>
            <PolarityOptions />
        </>
    );
};

export default Level;
