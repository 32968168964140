import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    useMap,
    useMapEvent,
} from "react-leaflet";
import L from "leaflet";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";

import icon from "assets/img/green-marker-icon.png";
import redIcon from "assets/img/red-marker-icon.png";
import blueIcon from "assets/img/blue-marker-icon.png";
import ripple from "assets/img/ripple.gif";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import { updateOneRegistry, fetchGWStatus } from "service/gatewayService";

import { ForbiddenErrorMessage } from "constant";
import useGeolocation from "hooks/geoLocation";
import { OverlayAuthorizeTooltip } from "components/authorize/AuthorizeTooltip";
import { canAccess } from "utils/authorize-action";

import "assets/css/location.css";
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";

const DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    shadowAnchor: [13, 20],
    iconSize: [25, 41],
    popupAnchor: [0, -10],
});

const BlueIcon = L.icon({
    iconUrl: blueIcon,
    shadowUrl: iconShadow,
    shadowAnchor: [13, 20],
    iconSize: [25, 41],
    popupAnchor: [0, -10],
});

const RedIcon = L.icon({
    iconUrl: redIcon,
    shadowUrl: iconShadow,
    shadowAnchor: [13, 20],
    iconSize: [25, 41],
    popupAnchor: [0, -10],
});

const LocationTab = (props: any) => {
    const { registryDetails, loadMap } = props;
    const [hasLoaded, setHasLoaded] = useState(false);
    const [initialCoordinates, setInitialCoordinates] = useState({
        lat: 0,
        lng: 0,
    });
    const [coordinates, setCoordinates] = useState({
        lat: 0,
        lng: 0,
    });
    const [newCoordinates, setNewCoordinates] = useState({
        lat: 0,
        lng: 0,
    });
    const [confirmedCoordinates, setConfirmedCoordinates] = useState({
        lat: 0,
        lng: 0,
    });
    const [markerInfo, setMarkerInfo] = useState<any>({
        gatewayName: "",
        gatewayId: "",
        status: false,
    });
    const [editMode, updateEditMode] = useState(false);
    const [isLoading, updateIsLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [showTooltip, setShowTooltip] = useState(false);
    const [tooltipTarget, setTarget] = useState<any>(null);
    const MODAL_CONFIRM = "confirm";
    const MODAL_SUCCESS = "success";
    const MODAL_ERROR = "err";
    const browserLocation = useGeolocation();

    L.Marker.prototype.options.icon =
        markerInfo.status === true ? DefaultIcon : RedIcon;

    useEffect(() => {
        const fetch = async () => {
            if (registryDetails.location) {
                setCoordinates({
                    lat: registryDetails.location[0],
                    lng: registryDetails.location[1],
                });
                setInitialCoordinates({
                    lat: registryDetails.location[0],
                    lng: registryDetails.location[1],
                });
            }

            setMarkerInfo({
                gatewayName: registryDetails.name,
                gatewayId: registryDetails.gateway_id,
                status: await fetchGWStatus(registryDetails.gateway_id),
            });

            setHasLoaded(true);
        };

        fetch();
    }, [loadMap, registryDetails]);

    useEffect(() => {
        if (
            !browserLocation.loading &&
            !coordinates.lat &&
            !coordinates.lng &&
            browserLocation.latitude &&
            browserLocation.longitude
        ) {
            setCoordinates({
                lat: browserLocation.latitude,
                lng: browserLocation.longitude,
            });

            setInitialCoordinates({
                lat: browserLocation.latitude,
                lng: browserLocation.longitude,
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [browserLocation.loading]);

    const updateLocation = async () => {
        try {
            const body = {
                location: [confirmedCoordinates.lat, confirmedCoordinates.lng],
            };
            const response: any = await updateOneRegistry(
                registryDetails.gateway_id,
                body
            );
            if (response.status === 204) {
                updateIsLoading(false);
                updateEditMode(false);
                setCoordinates({
                    lat: confirmedCoordinates.lat,
                    lng: confirmedCoordinates.lng,
                });
                setInitialCoordinates({
                    lat: confirmedCoordinates.lat,
                    lng: confirmedCoordinates.lng,
                });
                setModalShow(true);
                setModalType(MODAL_SUCCESS);
                setModalContent(
                    response.data?.message || "Gateway location updated."
                );
            } else if (response.status === 403) {
                updateIsLoading(false);
                setModalShow(true);
                setModalType(MODAL_ERROR);
                setModalContent(ForbiddenErrorMessage);
            } else {
                updateIsLoading(false);
                setModalShow(true);
                setModalType(MODAL_ERROR);
                setModalContent(
                    response.data?.message ||
                        "Something went wrong. Please try again."
                );
            }
        } catch (err: any) {
            updateIsLoading(false);
            setModalShow(true);
            setModalType(MODAL_ERROR);
            setModalContent(
                err.response?.data?.message ||
                    "Something went wrong. Please try again."
            );
        }
    };

    const renderModalIcon = () => {
        if (modalType === MODAL_ERROR || modalType === MODAL_CONFIRM) {
            return <span className="material-icons">warning</span>;
        }
        return <span className="material-icons">done</span>;
    };

    const renderModalTitle = () => {
        if (modalType === MODAL_ERROR) {
            return <h3 className="mb-3">Error</h3>;
        } else if (modalType === MODAL_CONFIRM) {
            return <h3 className="mb-3">Confirmation</h3>;
        }
        return <h3 className="mb-3">Success</h3>;
    };

    const renderModalButton = () => {
        if (modalType === MODAL_ERROR || modalType === MODAL_SUCCESS) {
            return (
                <Button variant="primary" onClick={() => setModalShow(false)}>
                    OK
                </Button>
            );
        }
        return (
            <>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setModalShow(false);
                    }}
                >
                    Cancel
                </Button>

                <Button
                    variant="primary"
                    onClick={() => {
                        setModalShow(false);
                        setCoordinates({
                            lat: newCoordinates.lat,
                            lng: newCoordinates.lng,
                        });
                        updateEditMode(true);
                        setConfirmedCoordinates({
                            lat: newCoordinates.lat,
                            lng: newCoordinates.lng,
                        });
                    }}
                >
                    OK
                </Button>
            </>
        );
    };

    const Layer = () => {
        const map = useMap();
        let isDragging = false;
        let mousedownTimer: any;
        map.invalidateSize();
        const provider: any = new OpenStreetMapProvider();

        // @ts-ignore
        const searchControl: any = new GeoSearchControl({
            provider: provider,
            style: "bar",
            marker: {
                icon: BlueIcon,
                draggable: false,
            },
            maxMarkers: 1,
            showPopup: true,
            autoClose: true,
            keepResult: true,
        });

        useEffect(() => {
            map.addControl(searchControl);
            return () => {
                map.removeControl(searchControl);
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const handleLongPress = (e: any) => {
            if (isDragging) return;
            setNewCoordinates({
                lat: e.latlng.lat,
                lng: e.latlng.lng,
            });

            if (!canAccess("gateway:update")) {
                setTarget(e.target?._container);
                setShowTooltip(true);
                setTimeout(() => {
                    setShowTooltip(false);
                }, 2000);
            } else {
                setModalShow(true);
                setModalType(MODAL_CONFIRM);
                setModalContent(
                    `Do you want to change the gateway location to the new coordinates? You still need to click on "Save" to finalise this change.`
                );
            }
        };

        useMapEvent("mousedown", (e) => {
            mousedownTimer = setTimeout(() => {
                handleLongPress(e);
            }, 800);

            isDragging = false;
        });

        useMapEvent("contextmenu", (e) => {
            mousedownTimer = setTimeout(() => {
                handleLongPress(e);
            }, 200);

            isDragging = false;
        });

        useMapEvent("mousemove", (e) => {
            isDragging = true;
        });

        useMapEvent("mouseup", (e) => {
            if (mousedownTimer) {
                clearTimeout(mousedownTimer);
            }
            isDragging = false;
        });

        const addNewMarker = () => {
            return (
                <Marker
                    position={[
                        confirmedCoordinates.lat,
                        confirmedCoordinates.lng,
                    ]}
                >
                    <Popup
                        position={[
                            confirmedCoordinates.lat,
                            confirmedCoordinates.lng,
                        ]}
                    >
                        {" "}
                        <div className="w-100 h-100 text-center">
                            {markerInfo.gatewayName}
                        </div>
                        <div>{markerInfo.gatewayId}</div>
                    </Popup>
                </Marker>
            );
        };

        return (
            <>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <Marker position={[coordinates.lat, coordinates.lng]}>
                    <Popup position={[coordinates.lat, coordinates.lng]}>
                        {" "}
                        <div className="w-100 h-100 text-center">
                            {markerInfo.gatewayName}
                        </div>
                        <div>{markerInfo.gatewayId}</div>
                    </Popup>
                </Marker>
                {confirmedCoordinates && editMode ? addNewMarker() : ""}
            </>
        );
    };

    return (
        <div className="location-box">
            <div className="pt-5 pb-5">
                {hasLoaded ? (
                    <MapContainer
                        center={[coordinates.lat, coordinates.lng]}
                        zoom={17}
                        scrollWheelZoom={true}
                        className="map-container"
                    >
                        <Layer />
                    </MapContainer>
                ) : (
                    ""
                )}
            </div>
            <div className="text-center">
                <h6>
                    Long tap to any location to change the marker to that
                    location.
                </h6>
                {editMode ? (
                    <>
                        <Button
                            variant="danger"
                            className="mt-2 mb-1 ml-1 mr-1 edit-mode-button"
                            onClick={() => {
                                setConfirmedCoordinates({
                                    lat: 0,
                                    lng: 0,
                                });
                                setCoordinates({
                                    lat: initialCoordinates.lat,
                                    lng: initialCoordinates.lng,
                                });
                                updateEditMode(false);
                            }}
                        >
                            BACK
                        </Button>
                        <Button
                            className="mt-2 mb-1 ml-1 mr-1 edit-mode-button"
                            variant="primary"
                            onClick={() => {
                                updateIsLoading(true);
                                updateLocation();
                            }}
                        >
                            SAVE
                        </Button>
                    </>
                ) : (
                    ""
                )}
            </div>
            <Modal
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`no-header ${
                    modalType === MODAL_SUCCESS ? "primary" : "danger"
                }`}
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">{renderModalIcon()}</div>
                    {renderModalTitle()}
                    <p className="mb-4">{modalContent}</p>
                    {renderModalButton()}
                </Modal.Body>
            </Modal>
            <img
                className={
                    isLoading ? "loader-gif d-block" : "loader-gif d-none"
                }
                src={ripple}
                alt="loading"
            />
            <OverlayAuthorizeTooltip
                target={tooltipTarget}
                show={showTooltip}
            />
        </div>
    );
};

export default LocationTab;
