import MinuteSecondPicker, {
    MinuteSecondPickerProps,
} from "components/common/MinuteSecondPicker";
import { useActuatorConfigurationContext } from "context/ActuatorConfigurationContext";
import PolarityOptions from "./PolarityOptions";
import { DeactivateOption } from "constant";
import { DeactivateOptions } from "./DeactivateOptions";

export const Pulse = () => {
    const { editedActuator, setEditedActuator } =
        useActuatorConfigurationContext();

    const minuteSecondPickerProps: MinuteSecondPickerProps = {
        editedActuator,
        setEditedActuator,
    };

    return (
        <>
            <PolarityOptions />
            <MinuteSecondPicker {...minuteSecondPickerProps} />
            <DeactivateOptions options={DeactivateOption.PULSE} />
        </>
    );
};

export default Pulse;
