import { Button, Col, Modal, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import styled from "@emotion/styled";
import styledConst from "styles";
import { getTokenConversionRate } from "service/subscriptionService";
import { HttpStatus } from "constant";
import { max, min } from "lodash";
import { faker } from "@faker-js/faker";

type TokenRateModalProps = {
    show: boolean;
    onClose?: () => void;
};

type TokenRateItem = {
    token: number;
    unit: string;
    perUnit: string;
    discount?: boolean;
};

type TokenRateSMSItem = {
    minToken: number;
    maxToken: number;
    unit: string;
    perUnit?: string;
};

type TokenRateState = {
    Email: TokenRateItem;
    SMS: TokenRateSMSItem;
    Notification: TokenRateItem;
    Report: TokenRateItem;
    Command: TokenRateItem;
    DashboardRequest: TokenRateItem;
    OffloadRequest: TokenRateItem;
    Event: TokenRateItem;
};

type SMSRegion = {
    name: string;
    token: number;
};

const KeyMaps = {
    Email: "email_count",
    Notification: "push_count",
    Report: "sample_counts",
    Command: "mqtt_counts",
    DashboardRequest: "query_count",
    OffloadRequest: "takeout_count",
    Event: "event_activate",
};

const SamplePerByte = 4;
const BaseUnit = 1048576;

export const TokenConversionRateModal = (props: TokenRateModalProps) => {
    const { show, onClose } = props;
    const [showModal, setShow] = useState(false);
    const [showSMS, setShowSMS] = useState(false);
    const [smsRegions, setSmsRegions] = useState<SMSRegion[]>([]);
    const [tokenRate, setTokenRate] = useState<TokenRateState>(
        {} as TokenRateState
    );

    const processData = (data: any) => {
        const {
            discount,
            by_count: {
                email_count: [EmailToken, EmailUnit],
                push_count: [NotificationToken, NotificationUnit],
                mqtt_counts: [CommandToken, CommandUnit],
            },
            by_n_count: {
                sample_counts: [ReportToken, ReportUnit],
                query_count: [DashboardToken, DashboardUnit],
                takeout_count: [OffloadToken, OffloadUnit],
            },
            by_count_day: {
                event_activate: [EventToken, EventUnit],
            },
            sms,
            sms_region_list,
        } = data;

        const discountList: string[] = discount;
        const smsMin = min(Object.values(sms) as number[]) || 0;
        const smsMax = max(Object.values(sms) as number[]) || 10;

        const state: TokenRateState = {
            Email: {
                token: EmailToken,
                perUnit: EmailUnit > 1 ? EmailUnit : "",
                unit: "Email",
                discount: !!discountList.includes(KeyMaps.Email),
            },
            SMS: {
                minToken: smsMin,
                maxToken: smsMax,
                unit: "SMS",
            },
            Notification: {
                token: NotificationToken,
                perUnit: NotificationUnit > 1 ? NotificationUnit : "",
                unit: "Notification",
                discount: !!discountList.includes(KeyMaps.Notification),
            },
            Report: {
                token: ReportToken,
                perUnit:
                    (SamplePerByte * ReportUnit) / BaseUnit > 1
                        ? ((SamplePerByte * ReportUnit) / BaseUnit).toString()
                        : "",
                unit: "MiB",
                discount: !!discountList.includes(KeyMaps.Report),
            },
            Command: {
                token: CommandToken,
                perUnit:
                    CommandUnit / BaseUnit > 1
                        ? (CommandUnit / BaseUnit).toString()
                        : "",
                unit: "MiB",
                discount: !!discountList.includes(KeyMaps.Command),
            },
            DashboardRequest: {
                token: DashboardToken,
                perUnit:
                    DashboardUnit / BaseUnit > 1
                        ? (DashboardUnit / BaseUnit).toString()
                        : "",
                unit: "MiB",
                discount: !!discountList.includes(KeyMaps.DashboardRequest),
            },
            OffloadRequest: {
                token: OffloadToken,
                perUnit:
                    OffloadUnit / BaseUnit > 1
                        ? (OffloadUnit / BaseUnit).toString()
                        : "",
                unit: "MiB",
                discount: !!discountList.includes(KeyMaps.OffloadRequest),
            },
            Event: {
                token: EventToken,
                perUnit: EventUnit > 1 ? EventUnit : "",
                unit: "Event / Day per enable",
                discount: !!discountList.includes(KeyMaps.Event),
            },
        };

        setTokenRate(state);
        setSmsRegions(sms_region_list);
    };

    useEffect(() => {
        const fetchTokenConversionRate = async () => {
            const resp = await getTokenConversionRate();

            if (resp.status === HttpStatus.OK) {
                processData(resp.data);
            }
        };

        fetchTokenConversionRate();
    }, []);

    useEffect(() => {
        setShow(show);
    }, [show]);

    const handleClose = () => {
        setShow(false);
        setShowSMS(false);
        onClose && onClose();
    };

    const showSMSModal = () => {
        setShowSMS(true);
        setShow(false);
    };

    const renderRateItem = (item: TokenRateItem) => {
        return (
            <>
                <TextBlue>
                    <span>
                        <span className={`${item?.discount ? "strict" : ""}`}>
                            {item?.token}
                        </span>
                        {item?.discount && <span> 0</span>}
                    </span>

                    <UnitText>
                        / {item?.perUnit}
                        {item?.unit}
                    </UnitText>
                </TextBlue>
                {item?.discount && (
                    <>
                        <TextHighlight>100% Discount</TextHighlight>
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <TokenRateModal
                show={showModal}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="token-conversion-rate-modal"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Token Conversion Rate
                    </Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={handleClose}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <TextRow>
                        <Col xs={"5"} className="header title">
                            <TextBlue className="title">Resource</TextBlue>
                        </Col>
                        <Col className="header">
                            <TextBlue className="title">
                                Token Consumed
                            </TextBlue>
                        </Col>
                    </TextRow>
                    <TextRow>
                        <TextCol xs={"5"} className="title">
                            <TextBlue className="title">Email</TextBlue>
                        </TextCol>
                        <TextCol>{renderRateItem(tokenRate.Email)}</TextCol>
                    </TextRow>
                    <TextRow>
                        <TextCol xs={"5"} className="title">
                            <TextBlue className="title">SMS</TextBlue>
                        </TextCol>
                        <TextCol>
                            <TextBlue>
                                <span>
                                    {tokenRate.SMS?.minToken} to{" "}
                                    {tokenRate.SMS?.maxToken}*
                                </span>
                                <UnitText>
                                    / {tokenRate.SMS?.perUnit}
                                    {tokenRate.SMS?.unit}
                                </UnitText>
                            </TextBlue>
                            <SubText>
                                *Depends on{" "}
                                <span className="link" onClick={showSMSModal}>
                                    destination and the SMS rate.
                                </span>
                            </SubText>
                        </TextCol>
                    </TextRow>
                    <TextRow>
                        <TextCol xs={"5"} className="title">
                            <TextBlue className="title">Notification</TextBlue>
                        </TextCol>
                        <TextCol>
                            {renderRateItem(tokenRate.Notification)}
                        </TextCol>
                    </TextRow>
                    <TextRow>
                        <TextCol xs={"5"} className="title">
                            <TextBlue className="title">
                                Ingress Report
                            </TextBlue>
                        </TextCol>
                        <TextCol>{renderRateItem(tokenRate.Report)}</TextCol>
                    </TextRow>
                    <TextRow>
                        <TextCol xs={"5"} className="title">
                            <TextBlue className="title">
                                Egress Commands
                            </TextBlue>
                        </TextCol>
                        <TextCol>{renderRateItem(tokenRate.Command)}</TextCol>
                    </TextRow>
                    <TextRow>
                        <TextCol xs={"5"} className="title">
                            <TextBlue className="title">
                                Dashboard Data Request
                            </TextBlue>
                        </TextCol>
                        <TextCol>
                            {renderRateItem(tokenRate.DashboardRequest)}
                        </TextCol>
                    </TextRow>
                    <TextRow>
                        <TextCol xs={"5"} className="title">
                            <TextBlue className="title">
                                Data Download Request
                            </TextBlue>
                        </TextCol>
                        <TextCol>
                            {renderRateItem(tokenRate.OffloadRequest)}
                        </TextCol>
                    </TextRow>
                    <TextRow>
                        <TextCol xs={"5"} className="title">
                            <TextBlue className="title">Active Event</TextBlue>
                        </TextCol>
                        <TextCol>{renderRateItem(tokenRate.Event)}</TextCol>
                    </TextRow>
                </Modal.Body>
            </TokenRateModal>
            <TokenRateModal
                show={showSMS}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="token-conversion-rate-sms-modal"
            >
                <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Token Conversion Rate for SMS
                    </Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={handleClose}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <TextRow>
                        <Col xs={"5"} className="header title">
                            <TextBlue className="title">Region</TextBlue>
                        </Col>
                        <Col className="header">
                            <TextBlue className="title">
                                Tokens per SMS
                            </TextBlue>
                        </Col>
                    </TextRow>
                    {smsRegions.map((region, index) => (
                        <TextRow key={faker.datatype.uuid()}>
                            <TextCol xs={"5"} className="title">
                                <TextBlue className="title">
                                    {region.name}
                                </TextBlue>
                            </TextCol>
                            <TextCol>
                                <TextBlue>{region.token}</TextBlue>
                            </TextCol>
                        </TextRow>
                    ))}
                </Modal.Body>
            </TokenRateModal>
        </>
    );
};

export const TokenRateModal = styled(Modal)`
    @media (min-width: 768px) {
        .modal-dialog {
            min-width: 780px;
        }
    }
`;

export const TextRow = styled(Row)`
    /* padding: 0 0 10px 20px; */

    .header {
        padding: 10px 20px;
        background-color: ${styledConst.Neutral_Grey_1};

        &.title {
            margin-right: 5px;
        }
    }
`;

export const TextCol = styled(Col)`
    padding: 0 0 10px 20px;
    border-bottom: 1px solid #efefef;

    &.title {
        margin-right: 5px;
    }
`;

export const TextBlue = styled.span`
    color: ${styledConst.Primary_Blue_2};
    font-size: 1.25rem;
    line-height: 1.8;
    font-weight: 600;
    display: flex;
    justify-content: space-between;

    &.title {
        font-weight: 400;
    }

    span.strict {
        color: ${styledConst.Primary_Blue_4};
        font-size: 1.25rem;
        text-decoration: line-through;
        margin-right: 10px;
    }
`;

const TextHighlight = styled.span`
    color: ${styledConst.Primary_Blue_1};
    line-height: 1.6;
`;

const SubText = styled.span`
    display: block;
    color: ${styledConst.Primary_Blue_4};

    .link {
        color: ${styledConst.Primary_Green_1};
        text-decoration: underline;
        cursor: pointer;
    }
`;

const UnitText = styled.span`
    align-self: flex-end;
    line-height: 2.25;
    font-size: 1rem;
    font-weight: 400;
`;
