import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Breadcrumb, Button } from "react-bootstrap";
import PermissionList, {
    DefaultPermissions,
} from "components/organizations/PermissionList";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import RolesService from "service/rolesService";
import { ROLE_NAME_MESSAGE, HttpStatus, Patterns } from "constant";
import { isHttpSuccess } from "utils/functions";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { RoleDto } from "types";
import ResourceNameField from "components/common/ResourceNameField";

const defaultDetails: any = {};

const RoleDetails = () => {
    const params: any = useParams();
    const [roleDetails, setRoleDetails] = useState<RoleDto>(defaultDetails);
    const [permissions, setPermissions] = useState(DefaultPermissions);
    const [isForbidden, setIsForbidden] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const { orgId, roleId } = params;
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);
    const [isNameEditing, updateIsNameEditing] = useState(false);
    const [roleName, setRoleName] = useState("");

    useEffect(() => {
        const fetch = async () => {
            const { status, data } = await RolesService.getRoleById(
                orgId,
                roleId
            );
            if (status === HttpStatus.FORBIDDEN) {
                setIsForbidden(true);
                setIsPageLoading(false);
                return;
            }
            if (isHttpSuccess(status)) {
                const role = toCamelCaseObject<RoleDto>(data);
                setRoleDetails(role);
                setRoleName(role.name);
                setPermissions(role.permission);
            }
            setIsForbidden(false);
            setIsPageLoading(false);
        };
        roleId && orgId && fetch();
    }, [roleId, orgId]);

    const handleClickEdit = () => {
        updateIsNameEditing(true);
    };

    const handleNameChange = (e: any) => {
        setRoleName(e.target.value);
    };

    const handleClickUpdate = async () => {
        if (roleDetails.name === roleName) {
            updateIsNameEditing(false);
            return;
        }

        const res = await RolesService.updateRole(orgId, roleId, {
            name: roleName,
        });

        let message = "";

        if (isHttpSuccess(res.status)) {
            message = "The Role has been updated successfully!";
            setRoleDetails(toCamelCaseObject<RoleDto>(res.data));
            updateIsNameEditing(false);
        }

        setSimpleModalData({
            resObj: res,
            body: message,
        } as SimpleModalDataType);
    };

    const handleSave = async () => {
        const roleRes: any = await RolesService.updateRole(orgId, roleId, {
            permissions: permissions,
        });
        let message = "";

        if (isHttpSuccess(roleRes.status)) {
            message = "The Role has been updated successfully!";
            const role = toCamelCaseObject<RoleDto>(roleRes.data);
            setRoleDetails(role);
            setPermissions(role.permission);
        }

        setSimpleModalData({
            resObj: roleRes,
            body: message,
        } as SimpleModalDataType);
    };

    const handlePermissionsChange = (permissions: any) => {
        setPermissions(permissions);
    };

    return (
        <ContentWrapper
            simpleModalData={simpleModalData}
            isForbiddenResource={isForbidden}
            title={roleDetails.name || "Role Details"}
            isLoading={isPageLoading}
            routes={[
                {
                    name: "Organisation",
                    url: `/organisation/${orgId}`,
                },
                {
                    name: "Role",
                },
            ]}
        >
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm="12" className="role-details-header">
                            <h5 className="page-title overflow-text">
                                {roleDetails.name || "-"}
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item
                                    href={`/organisation/${orgId}`}
                                >
                                    Organisation
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>Role</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <ResourceNameField
                        input={roleName}
                        updateField={handleNameChange}
                        isNameEditing={isNameEditing}
                        toggleEditButton={handleClickEdit}
                        toggleUpdateButton={handleClickUpdate}
                        resourceTitle="Role Name"
                        resourcePermission="organisation:update"
                        validationMessage={ROLE_NAME_MESSAGE}
                        validationRegex={Patterns.orgNamePattern}
                        shouldDisableEdit={roleDetails.isDefault}
                    />

                    <Row>
                        <Col xs="12">
                            <h5 className="page-title mt-4 w-100">
                                Permissions
                            </h5>
                            <PermissionList
                                values={permissions}
                                isDefault={roleDetails.isDefault}
                                onChange={handlePermissionsChange}
                            />
                            {roleDetails.isDefault ? (
                                ""
                            ) : (
                                <HoverAuthorizeTooltip
                                    permission="organisation:update"
                                    className="mt-4"
                                >
                                    <Button
                                        variant="primary"
                                        onClick={handleSave}
                                    >
                                        SAVE
                                    </Button>
                                </HoverAuthorizeTooltip>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </ContentWrapper>
    );
};

export default RoleDetails;
