import { ChangeEvent, useState } from "react";
import { useHistory } from "react-router";
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    Modal,
    Breadcrumb,
} from "react-bootstrap";
import { changeProfilePassword } from "service/authenticationService";
import ripple from "assets/img/ripple.gif";
import {
    CONFIRM_NEW_PASSWORD_MESSAGE,
    ENTER_CURRENT_PASSWORD_MESSAGE,
    ENTER_NEW_PASSWORD_MESSAGE,
    PASSWORD_DO_NOT_MATCH_ERROR_MESSAGE,
    PASSWORD_ERROR_MESSAGE,
    Patterns,
    SAME_PASSWORD_ERROR_MESSAGE,
} from "constant";
import "./UserProfile.css";
import { useAuth } from "context/auth";
import { isHttpSuccess } from "utils/functions";
import { PasswordField } from "components/common/form";

const DefaultForm = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
};

const ChangePassword = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [errorModalMessage, setErrorModalMessage] = useState("");
    const [successModalShow, setSuccessModalShow] = useState(false);
    const [errorModalShow, setErrorModalShow] = useState(false);
    const [isLoadingModalShow, setIsLoadingModalShow] = useState(false);
    const [form, setForm] = useState(DefaultForm);

    const { signOut } = useAuth();

    const history = useHistory();

    const closeErrorModal = () => {
        setErrorModalShow(false);
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {
            target: { name, value },
        } = e;
        setForm({ ...form, [name]: value });
    };

    const handleChangePassword = async () => {
        setIsLoadingModalShow(true);
        const { currentPassword, newPassword, confirmNewPassword } = form;

        if (!currentPassword) {
            setIsLoadingModalShow(false);
            setErrorMessage(ENTER_CURRENT_PASSWORD_MESSAGE);
            return;
        }
        if (!newPassword) {
            setIsLoadingModalShow(false);
            setErrorMessage(ENTER_NEW_PASSWORD_MESSAGE);
            return;
        }

        if (currentPassword === newPassword) {
            setIsLoadingModalShow(false);
            setErrorMessage(SAME_PASSWORD_ERROR_MESSAGE);
            return;
        }

        if (!confirmNewPassword) {
            setIsLoadingModalShow(false);
            setErrorMessage(CONFIRM_NEW_PASSWORD_MESSAGE);
            return;
        }

        if (
            currentPassword.length < 8 ||
            currentPassword.length > 32 ||
            Patterns.passwordPattern.test(currentPassword) === false ||
            newPassword.length < 8 ||
            newPassword.length > 32 ||
            Patterns.passwordPattern.test(newPassword) === false
        ) {
            setIsLoadingModalShow(false);
            setErrorMessage(PASSWORD_ERROR_MESSAGE);
            return;
        }

        if (newPassword !== confirmNewPassword) {
            setIsLoadingModalShow(false);
            setErrorMessage(PASSWORD_DO_NOT_MATCH_ERROR_MESSAGE);
            return;
        }

        const response: any = await changeProfilePassword({
            old: currentPassword,
            new: newPassword,
        });

        setIsLoadingModalShow(false);
        setErrorMessage("");

        if (isHttpSuccess(response.status)) {
            setSuccessModalShow(true);
            setForm(DefaultForm);
        } else {
            setErrorModalShow(true);
            const errorString =
                response.data.description ||
                "Something went wrong. Please try again later.";
            setErrorModalMessage(errorString);
        }
    };

    const logoutAfterPasswordChanged = () => {
        setSuccessModalShow(false);
        signOut();
    };

    return (
        <div className="page-content">
            <Container>
                <Row>
                    <Col sm={12}>
                        <h5 className="page-title">Change Password</h5>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Breadcrumb className="w-100">
                            <Breadcrumb.Item href="/user/settings">
                                Account Settings
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                Change Password
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <Form className="d-inline-block w-100">
                            <div className="form-box">
                                <Form.Group className="mb-4">
                                    <Form.Label>Current Password</Form.Label>
                                    <PasswordField
                                        name="currentPassword"
                                        aria-label="current-password"
                                        placeholder="Enter Password"
                                        required
                                        value={form.currentPassword}
                                        onChange={handleInputChange}
                                        autoComplete="new-password"
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label>New Password</Form.Label>
                                    <PasswordField
                                        name="newPassword"
                                        aria-label="new-password"
                                        placeholder="Enter New Password"
                                        required
                                        value={form.newPassword}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <Form.Label>
                                        Confirm New Password
                                    </Form.Label>
                                    <PasswordField
                                        name="confirmNewPassword"
                                        aria-label="confirm-password"
                                        placeholder="Enter Confirm New Password"
                                        required
                                        value={form.confirmNewPassword}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </div>
                        </Form>
                        <div role="alert" className="text-danger">
                            {errorMessage}
                        </div>
                        <Button
                            variant="secondary"
                            className="mt-4 mr-2"
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="primary"
                            className="mt-4"
                            onClick={handleChangePassword}
                        >
                            SAVE
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Modal
                centered
                show={isLoadingModalShow}
                backdrop="static"
                keyboard={false}
                className="border-0"
            >
                <img className="loader-gif" src={ripple} alt="loading" />
            </Modal>
            <Modal
                centered
                show={successModalShow}
                backdrop="static"
                keyboard="false"
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header"
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">
                        <span className="material-icons">done</span>
                    </div>
                    <h3 className="mb-3">Success</h3>
                    <p className="mb-4">
                        Your password has been changed successfully.
                    </p>

                    <Button
                        variant="primary"
                        onClick={logoutAfterPasswordChanged}
                    >
                        OK
                    </Button>
                </Modal.Body>
            </Modal>
            <Modal
                centered
                show={errorModalShow}
                onHide={closeErrorModal}
                backdrop="static"
                keyboard="false"
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header danger"
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">
                        <span className="material-icons">warning</span>
                    </div>
                    <h3 className="mb-3">Error</h3>
                    <p role="alert" className="mb-4">
                        {errorModalMessage}
                    </p>

                    <Button variant="primary" onClick={closeErrorModal}>
                        OK
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ChangePassword;
