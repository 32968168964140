import { useState, useEffect } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";

import {
    Container,
    Row,
    Col,
    Breadcrumb,
    InputGroup,
    Button,
    FormControl,
    Form,
} from "react-bootstrap";

import EventService from "service/eventService";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import { getOneRegistry } from "service/gatewayService";
import { HttpStatus } from "constant";
import { showErrorAlert } from "utils/alert";

const GatewayStatus = (props: any) => {
    const history: any = useHistory();
    const params: any = useParams();
    const location: any = useLocation();

    const selectedGateway = location?.state?.selectedGateway;
    const selectedOperator = location?.state?.selectedOperator;
    const slotCount = location?.state?.slotCount;
    const selectedSlotIndex = location?.state?.selectedSlotIndex;

    const OFFLINE = "offline";
    const ONLINE = "online";
    const [checkedOption, setCheckedOption] = useState(OFFLINE);
    const [duration, setDuration] = useState("");

    const [gatewayDetail, setGatewayDetail]: any = useState({});
    const [currentGatewayDetails, setCurrentGatewayDetails]: any = useState({});
    const [isPageLoading, updateIsPageLoading] = useState(true);
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);

    const fetchGateway = async (gatewayId: string) => {
        const response: any = await getOneRegistry(gatewayId);
        const { name, gateway_id } = response?.data || {};
        if (response.status === 200) {
            setGatewayDetail({
                name: name,
                gateway_id: gateway_id,
            });
        }
        updateIsPageLoading(false);
    };

    useEffect(() => {
        // this condition should only run if the condition is already set
        if (params.conditionId) {
            const fetchGatewayCondition = async () => {
                const response = await EventService.readOneCondition(
                    params.conditionId,
                    params.eventId
                );
                if (response.status === HttpStatus.OK) {
                    const { data: gateway } = response;
                    const { for_, gateway_id, status } =
                        gateway.resource.device;
                    fetchGateway(gateway_id);
                    if (status === ONLINE) {
                        setCheckedOption(ONLINE);
                    } else {
                        setCheckedOption(OFFLINE);
                    }

                    setDuration(for_.toString());
                    setCurrentGatewayDetails(gateway);
                    updateIsPageLoading(false);
                }
            };

            fetchGatewayCondition();
        } else {
            fetchGateway(selectedGateway);
        }
    }, [selectedGateway, params.conditionId, params.eventId]);

    const handleAddUpdateCondition = async () => {
        let response: any;

        if (
            Number(duration) < 5 ||
            Number(duration) > 3600 ||
            Number(duration) % 5 !== 0
        ) {
            showErrorAlert({
                message:
                    "Duration must be a multiple of 5 and between 5 - 3600s.",
            });

            return;
        }

        let slot = 0;
        if (selectedOperator === "AND") {
            slot = selectedSlotIndex;
        } else if (selectedOperator === "OR") {
            slot = slotCount;
        }

        if (!params.conditionId) {
            response = await EventService.createCondition(params.eventId, {
                slot: slot,
                type_: "GATEWAY",
                resource: {
                    device: {
                        gateway_id: gatewayDetail.gateway_id,
                        status:
                            checkedOption === OFFLINE ? "offline" : "online",
                        for_: Number(duration),
                    },
                },
            });
        } else {
            response = await EventService.updateCondition(
                params.eventId,
                params.conditionId,
                {
                    slot: currentGatewayDetails.slot,
                    type_: "GATEWAY",
                    resource: {
                        device: {
                            gateway_id: gatewayDetail.gateway_id,
                            status:
                                checkedOption === OFFLINE
                                    ? "offline"
                                    : "online",
                            for_: Number(duration),
                        },
                    },
                }
            );
        }

        if (response.status === 201 || response.status === 204) {
            closeCondition();
        } else {
            setSimpleModalData({
                resObj: response,
            } as SimpleModalDataType);
        }
    };

    const closeCondition = () => {
        history.push(`/event-details/${params.eventId}`);
    };

    const renderGatewayInfo = () => {
        const { name, gateway_id } = gatewayDetail;

        return (
            <div className="action-device-details">
                <h6>{name}</h6>
                <p>UUID: {gateway_id}</p>
            </div>
        );
    };

    return (
        <>
            <ContentWrapper
                isLoading={isPageLoading}
                simpleModalData={simpleModalData}
            >
                <Container>
                    <Row>
                        <Col sm="12" className="event-detail-head">
                            <h5 className="page-title overflow-text">
                                Gateway Status
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item>
                                    <Link to="/events">Events</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link
                                        to={`/event-details/${params.eventId}`}
                                    >
                                        Event Details
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Condition
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row className="action-detail-box">
                        <Col>
                            <div className="form-box mb-3">
                                <h5 className="mb-4">When Gateway</h5>
                                {renderGatewayInfo()}
                            </div>
                            <div className="form-box  mb-3">
                                <h5 className="mb-4">Status</h5>
                                <Form.Row>
                                    <Col sm={2}>
                                        <Form.Check
                                            name="mode"
                                            id={"offline"}
                                            custom
                                            type="radio"
                                            onChange={() => {
                                                setCheckedOption(OFFLINE);
                                            }}
                                            checked={checkedOption === OFFLINE}
                                            label={
                                                <span className="ml-2">
                                                    Goes offline
                                                </span>
                                            }
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <Form.Check
                                            name="mode"
                                            id={"online"}
                                            custom
                                            type="radio"
                                            onChange={() => {
                                                setCheckedOption(ONLINE);
                                            }}
                                            checked={checkedOption === ONLINE}
                                            label={
                                                <span className="ml-2">
                                                    Comes online
                                                </span>
                                            }
                                        />
                                    </Col>
                                </Form.Row>
                            </div>
                            <div className="form-box mb-3">
                                <h5 className="mb-4">Last for (Duration)</h5>
                                <InputGroup className="mt-2">
                                    <FormControl
                                        defaultValue=""
                                        value={duration}
                                        onChange={(e: any) => {
                                            setDuration(e.target.value);
                                        }}
                                    />
                                    <InputGroup.Text>sec(s)</InputGroup.Text>
                                </InputGroup>
                            </div>
                            <Button
                                variant="secondary"
                                className="mr-2"
                                onClick={closeCondition}
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="primary"
                                className="pl-4 pr-4"
                                onClick={handleAddUpdateCondition}
                            >
                                {params.conditionId ? "UPDATE" : "ADD"}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </ContentWrapper>
        </>
    );
};

export default GatewayStatus;
