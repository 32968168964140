import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router";
import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Modal,
    Spinner,
} from "react-bootstrap";
import { isEqual, get } from "lodash";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import "assets/css/subscription.css";

import {
    createAddress,
    getAllPlans,
    getAddress,
    updateAddress,
    createSubscription,
    getLatestSubscription,
} from "service/subscriptionService";

import VerifyCustomer from "../../components/subscriptions/VerifyCustomer";
import PurchasePreview from "../../components/subscriptions/PurchasePreview";
import BillingAddressCheckoutForm from "../../components/subscriptions/BillingAddressCheckoutForm";
import {
    billingStartDate,
    CheckoutType,
    HttpStatus,
    SubscriptionStatus,
} from "constant";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import { AddressPostCountry } from "generated/models";
import { AppContext } from "context/appContext";
import {
    BillingAddressProp,
    BillingAddressRequestBody,
} from "types/Subscription";
import {
    initBillingAddress,
    isBillingAddressFormValid,
} from "utils/subscriptionFunctions";
import { isHttpSuccess } from "utils/functions";
import { useSubscriptionContext } from "context/subscriptionContext";

const CheckoutBillingAddress = (props: any) => {
    const history = useHistory();
    const location: any = useLocation();
    const {
        storeData: {
            subscription: { currentSubscriptionId },
        },
    } = useContext(AppContext);
    const { subscription }: any = useSubscriptionContext();

    const MODAL_ERROR = "error";
    const {
        checkoutType,
        planUUID,
        status,
        addonUUID,
        subscriptionUUID = currentSubscriptionId,
    } = location.state;

    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);

    const [customer, setCustomer] = useState({
        id: location?.state?.customer?.id || "",
        prn: location?.state?.customer?.prn || "",
        verified: location?.state?.customer?.verified || false,
        uuid: location?.state?.customer?.uuid || "",
        billing_cycles: location?.state?.customer?.billing_cycles || 0,
    });

    const [initialBillingAddress, setInitialBillingAddress] =
        useState<BillingAddressProp>(initBillingAddress());

    const [billingAddress, setBillingAddress] = useState<BillingAddressProp>(
        initBillingAddress()
    );

    const [plan, setPlan] = useState(Object);
    const [bundleQty, setBundleQty] = useState(location.state.bundleQty);
    const bundleInfo = {
        amount: location.state.bundleInfo?.amount,
        tokenQty: location.state.bundleInfo?.tokenQty,
    };
    const [monthlyChargePreview, setMonthlyChargePreview] = useState(
        location?.state?.monthlyChargePreview || ""
    );
    const [hasAddressTemplate, setHasAddressTemplate] = useState(false);
    const [currentAddressUUID, setCurrentAddressUUID] = useState("");
    const [pageLoaded, setPageLoaded] = useState(false);
    const [isUpdatingAddress, setIsUpdatingAddress] = useState(false);
    const { OK, CREATED, NO_CONTENT } = HttpStatus;

    useEffect(() => {
        const fetch = async () => {
            const haveSubscription = await getLatestSubscription();

            if (haveSubscription) {
                let currentAddress: any = haveSubscription?.address;
                if (checkoutType === CheckoutType.ADDON) {
                    const address: any = await getAddress();
                    if (isHttpSuccess(address.status)) {
                        currentAddress = get(address, "data.data[0]", {});
                    }
                }
                const {
                    first_name,
                    last_name,
                    city,
                    street,
                    line3,
                    country,
                    code,
                    extended,
                    region,
                    uuid,
                } = currentAddress;

                setInitialBillingAddress({
                    first_name,
                    last_name,
                    city,
                    street,
                    line3,
                    country,
                    code,
                    extended: extended || "",
                    region: region || "",
                });
                setBillingAddress({
                    first_name,
                    last_name,
                    city,
                    street,
                    line3,
                    country,
                    code,
                    extended: extended || "",
                    region: region || "",
                });
                setCurrentAddressUUID(uuid);
                setHasAddressTemplate(true);
            } else {
                const addressRes: any = await getAddress();
                if (addressRes.status === OK) {
                    if (addressRes.data.data.length) {
                        const templateAddress = addressRes?.data?.data?.[0];
                        const {
                            first_name,
                            last_name,
                            city,
                            street,
                            line3,
                            country,
                            code,
                            extended,
                            region,
                            uuid,
                        } = templateAddress;
                        setInitialBillingAddress({
                            first_name,
                            last_name,
                            city,
                            street,
                            line3,
                            country,
                            code,
                            extended: extended || "",
                            region: region || "",
                        });
                        setBillingAddress({
                            first_name,
                            last_name,
                            city,
                            line3,
                            street,
                            country,
                            code,
                            extended: extended || "",
                            region: region || "",
                        });
                        setCurrentAddressUUID(uuid);
                        setHasAddressTemplate(true);
                    }
                } else {
                    showErrorAlert({
                        message: "Something went wrong. Please try again.",
                    });
                }
            }

            if (checkoutType === CheckoutType.PLAN) {
                const planRes: any = await getAllPlans();
                for (const plan of planRes.data) {
                    if (planUUID === plan.uuid) {
                        setPlan(plan);
                        setPageLoaded(true);
                        return;
                    }
                }
            } else if (checkoutType === CheckoutType.ADDON) {
                setPageLoaded(true);
                return;
            }
        };
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkoutType, planUUID]);

    const qtyField: any = document.querySelector("#qtyField");
    qtyField?.addEventListener("keydown", (e: any) => {
        if (
            !(
                (e.keyCode > 95 && e.keyCode < 106) ||
                (e.keyCode > 47 && e.keyCode < 58) ||
                e.keyCode === 8
            )
        ) {
            e.preventDefault();
        }
    });

    qtyField?.addEventListener("paste", (e: any) => {
        const pastedData = e.clipboardData.getData("text/plain");
        if (pastedData.match(/[^0-9]/)) e.preventDefault();
    });

    const proceedToCheckoutPage = async (addressUUID: string) => {
        if (checkoutType === CheckoutType.PLAN) {
            let subscriptionRes: any;

            subscriptionRes = await createSubscription({
                addressUUID: addressUUID,
                ...(customer.uuid && {
                    customer_uuid: customer.uuid,
                }),
            });

            if (subscriptionRes.status === CREATED) {
                history.push("/checkout", {
                    checkoutType,
                    planUUID,
                    plan,
                    addonUUID,
                    addressUUID,
                    bundleQty,
                    bundleInfo,
                    subscriptionUUID: subscriptionRes.data.data.uuid,
                    status,
                    billingStartDate,
                    customer,
                    monthlyChargePreview,
                });

                showSuccessAlert({
                    message: `Successfully created billing address.`,
                });
            }
        } else {
            history.push("/checkout", {
                checkoutType,
                planUUID,
                plan,
                addonUUID,
                addressUUID: currentAddressUUID,
                bundleQty,
                bundleInfo,
            });
        }
    };

    const saveAddress = async () => {
        if (!isBillingAddressFormValid(billingAddress)) return;

        setIsUpdatingAddress(true);

        const {
            first_name,
            last_name,
            city,
            line3,
            street,
            country,
            code,
            extended,
            region,
        } = billingAddress;
        const body: BillingAddressRequestBody = {
            first_name: first_name.trim(),
            last_name: last_name.trim(),
            city: city.trim(),
            line3: line3.trim(),
            street: street.trim(),
            country: country.trim() as AddressPostCountry,
            code: code.trim(),
            extended: extended?.trim(),
            region: region?.trim(),
        };

        if (!hasAddressTemplate) {
            const response: any = await createAddress(body);

            if (response.status === CREATED) {
                setIsUpdatingAddress(false);
                setCurrentAddressUUID(response.data.data.uuid);
                proceedToCheckoutPage(response.data.data.uuid);
            } else {
                setIsUpdatingAddress(false);
                setSimpleModalData({
                    resObj: response,
                } as SimpleModalDataType);
            }
        } else {
            if (isEqual(initialBillingAddress, billingAddress)) {
                if (checkoutType === CheckoutType.PLAN) {
                    if (
                        subscriptionUUID &&
                        ![
                            SubscriptionStatus.CANCELED,
                            SubscriptionStatus.EXPIRED,
                        ].includes(subscription?.status)
                    ) {
                        history.push("/checkout", {
                            checkoutType,
                            planUUID,
                            plan,
                            addonUUID,
                            addressUUID: currentAddressUUID,
                            bundleQty,
                            bundleInfo,
                            subscriptionUUID,
                            status: location.state.status,
                            billingStartDate,
                            customer,
                            monthlyChargePreview,
                        });
                    } else {
                        let subscriptionRes: any;

                        subscriptionRes = await createSubscription({
                            addressUUID: currentAddressUUID,
                            ...(customer.uuid && {
                                customer_uuid: customer.uuid,
                            }),
                        });

                        if (subscriptionRes.status === CREATED) {
                            history.push("/checkout", {
                                checkoutType,
                                planUUID,
                                plan,
                                addonUUID,
                                addressUUID: currentAddressUUID,
                                bundleQty,
                                bundleInfo,
                                subscriptionUUID:
                                    subscriptionRes.data.data.uuid,
                                status: location.state.status,
                                billingStartDate,
                                customer,
                                monthlyChargePreview,
                            });
                        }
                    }
                } else {
                    history.push("/checkout", {
                        checkoutType,
                        planUUID,
                        plan,
                        addonUUID,
                        addressUUID: currentAddressUUID,
                        bundleQty,
                        bundleInfo,
                    });
                }
            } else {
                const latestSubscriptionRes = await getLatestSubscription();

                if (!latestSubscriptionRes) {
                    const updateAddressTemplateRes: any = await updateAddress(
                        currentAddressUUID,
                        body
                    );

                    if (isHttpSuccess(updateAddressTemplateRes.status)) {
                        if (checkoutType === CheckoutType.PLAN) {
                            let subscriptionRes: any = await createSubscription(
                                {
                                    addressUUID: currentAddressUUID,
                                    ...(customer.uuid && {
                                        customer_uuid: customer.uuid,
                                    }),
                                }
                            );

                            if (subscriptionRes.status === CREATED) {
                                setTimeout(() => {
                                    history.push("/checkout", {
                                        checkoutType,
                                        planUUID,
                                        plan,
                                        addonUUID,
                                        addressUUID: currentAddressUUID,
                                        bundleQty,
                                        bundleInfo,
                                        subscriptionUUID:
                                            subscriptionRes.data.data.uuid,
                                        status: location.state.status,
                                        billingStartDate,
                                        customer,
                                    });
                                }, 0);

                                showSuccessAlert({
                                    message: `Successfully created billing address.`,
                                });
                            }
                        }
                    }
                } else {
                    const response: any = await updateAddress(
                        currentAddressUUID,
                        body
                    );

                    if (response.status === NO_CONTENT) {
                        setIsUpdatingAddress(false);

                        history.push("/checkout", {
                            checkoutType,
                            planUUID,
                            plan,
                            addonUUID,
                            addressUUID: currentAddressUUID,
                            subscriptionUUID,
                            bundleQty,
                            bundleInfo,
                            status,
                            billingStartDate,
                            customer,
                            monthlyChargePreview,
                        });

                        showSuccessAlert({
                            message: "Successfully updated billing address.",
                        });
                    } else {
                        setIsUpdatingAddress(false);
                        setSimpleModalData({
                            resObj: response,
                        } as SimpleModalDataType);
                    }
                }
            }
        }
    };

    const purchasePreviewTokenProps: any = {
        checkoutType,
        location,
        billingStartDate,
        plan,
        bundleInfo,
        setBundleQty,
        bundleQty,
        billingAddress,
        MODAL_ERROR,
        saveAddress,
    };
    const purchasePreviewSubscriptionPlanProps: any = {
        checkoutType,
        customer,
        saveAddress,
        monthlyChargePreview,
        location,
    };

    const billingAddressCheckoutFormProps: any = {
        billingAddress,
        setBillingAddress,
    };

    const verifyCustomerProps: any = {
        customer,
        setCustomer,
        setMonthlyChargePreview,
    };

    return (
        <ContentWrapper
            isLoading={!pageLoaded}
            simpleModalData={simpleModalData}
        >
            <div className="page-content subscription-page">
                <Container fluid>
                    <Row>
                        <Col sm="12">
                            <h5 className="page-title">Checkout</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item>
                                    <Link to="/manage-subscription">
                                        Subscriptions
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Billing Address
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8} className="d-flex flex-column ">
                            {checkoutType === CheckoutType.PLAN && (
                                <VerifyCustomer {...verifyCustomerProps} />
                            )}
                            <BillingAddressCheckoutForm
                                {...billingAddressCheckoutFormProps}
                            />
                        </Col>
                        <Col md={4}>
                            <PurchasePreview
                                {...(checkoutType === CheckoutType.ADDON
                                    ? purchasePreviewTokenProps
                                    : purchasePreviewSubscriptionPlanProps)}
                            />
                        </Col>
                    </Row>

                    <Modal
                        centered
                        show={isUpdatingAddress}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="example-modal-sizes-title-sm"
                        className="no-header"
                    >
                        <Modal.Body className="text-center mt-3 mb-5 mr-4">
                            <Spinner
                                className="centered-spinner"
                                animation="border"
                                variant="primary"
                            />
                        </Modal.Body>
                    </Modal>
                </Container>
            </div>
        </ContentWrapper>
    );
};

export default CheckoutBillingAddress;
