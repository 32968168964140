import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import { useState } from "react";
import { Col, Form, Button, Row } from "react-bootstrap";
import { validateCustomerCodePRN } from "service/subscriptionService";
import { showErrorAlert } from "utils/alert";
import { Patterns } from "constant";
import profileIcon from "assets/svg/icon-park-outline_id-card-v.svg";

import "assets/css/subscription.css";

const VerifyCustomer = (props: any) => {
    const { customer, setCustomer, setMonthlyChargePreview } = props;

    const [verifyingInProgress, setVerifyingInProgress] = useState(false);
    const [verificationError, setVerificationError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);

    const updateField = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setCustomer({
            ...customer,
            verified: false,
            [e.target.name]: e.target.value,
        });
    };

    const handleVerify = async (e: any) => {
        e.preventDefault();
        setVerificationError(false);
        setCustomer({ ...customer, verified: false });
        // 1. Field verification
        if (
            !customer.id ||
            !customer.prn ||
            customer.id.length > 128 ||
            customer.prn.length > 128
        ) {
            showErrorAlert({
                message: `Customer ID/PRN should be between 1 - 128 characters.`,
            });

            return;
        }

        if (
            !Patterns.asciiPattern.test(customer.id) ||
            !Patterns.asciiPattern.test(customer.prn)
        ) {
            showErrorAlert({
                message: `Customer ID/PRN should not contain any special characters.`,
            });

            return;
        }

        // 2. If verification pass, call API
        setVerifyingInProgress(true);

        const validateCustomer: any = await validateCustomerCodePRN({
            id: customer.id,
            prn: customer.prn,
        });

        if (validateCustomer.status === 200) {
            if (validateCustomer.data) {
                setSimpleModalData({
                    resObj: validateCustomer,
                } as SimpleModalDataType);
                setCustomer({
                    id: customer.id,
                    prn: customer.prn,
                    uuid: validateCustomer.data.data.uuid,
                    verified: true,
                    billing_cycles: validateCustomer.data.data.billing_cycles,
                });
                setMonthlyChargePreview(validateCustomer?.data?.data?.price);
            }
        } else {
            let _errors = validateCustomer?.response?.data?.["errors"] || [];

            const errorMsg = _errors
                .map((item: { value: string[] }) => item.value[0])
                .join(" ");

            setErrorMessage(errorMsg || `Invalid Customer ID or PRN`);
            setVerificationError(true);
        }
        setVerifyingInProgress(false);
    };

    return (
        <ContentWrapper simpleModalData={simpleModalData}>
            <div className="form-box mb-3">
                <div className="customer-id-title">
                    <img
                        src={profileIcon}
                        className="customer-id-img"
                        alt="customer-id-PRN"
                    />
                    <h5>Customer ID and PRN</h5>
                    <p>Enter your Customer ID and PRN.</p>
                </div>
                <Form
                    onSubmit={(e: any) => {
                        handleVerify(e);
                    }}
                >
                    <Form.Group as={Row} className="d-flex align-items-center">
                        <Form className="m-2">Customer ID</Form>
                        <Col sm={3}>
                            <Form.Control
                                type="text"
                                required
                                aria-label="owner-name"
                                name="id"
                                onInvalid={() => {
                                    setCustomer({
                                        ...customer,
                                        verified: false,
                                    });
                                }}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ): void => {
                                    updateField(e);
                                }}
                                value={customer.id}
                            />
                        </Col>
                        <Form className="m-2">PRN </Form>
                        <Col sm={3} className="d-flex">
                            <Form.Control
                                type="text"
                                required
                                aria-label="owner-name"
                                name="prn"
                                onInvalid={() => {
                                    setCustomer({
                                        ...customer,
                                        verified: false,
                                    });
                                }}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ): void => {
                                    updateField(e);
                                }}
                                value={customer.prn}
                            />
                        </Col>
                        <Form.Group className="d-flex align-items-center mb-0">
                            <Col>
                                {!verifyingInProgress ? (
                                    <Button type="submit" className="h-100">
                                        VERIFY
                                    </Button>
                                ) : (
                                    <Button className="d-flex justify-content-center verify-loading-btn">
                                        <div
                                            className="spinner-border verify-spinner text-light"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </div>
                                    </Button>
                                )}
                            </Col>
                            {customer.verified && (
                                <Col className="d-flex">
                                    <span className="material-icons green d-block mr-1">
                                        check_circle
                                    </span>
                                    Verified
                                </Col>
                            )}
                            {verificationError && !customer.verified && (
                                <span className="verify-error-msg">
                                    {errorMessage}
                                </span>
                            )}
                        </Form.Group>
                    </Form.Group>
                </Form>
            </div>
        </ContentWrapper>
    );
};

export default VerifyCustomer;
