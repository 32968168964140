import { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Form,
    Alert,
    Button,
    InputGroup,
    Breadcrumb,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import OrganisationService from "service/organisationService";
import { AppContext } from "context/appContext";
import { HttpStatus, Patterns } from "constant";

import "assets/css/inviteMembers.css";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import { canAccess } from "utils/authorize-action";
import { isHttpSuccess } from "utils/functions";

type EmailItem = {
    value: string;
    errorMessage: string | null;
};

const InviteMembers = () => {
    const defaultEmails: EmailItem[] = [
        {
            value: "",
            errorMessage: null,
        },
    ];
    const { storeData } = useContext(AppContext);
    const history = useHistory();
    const [emails, updateEmails] = useState(defaultEmails);
    const { organization } = storeData;
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);
    const params: any = useParams();
    const [isForbiddenResource, updateIsForbiddenResource] = useState(false);

    useEffect(() => {
        const fetchOrg = async () => {
            const res: any = await OrganisationService.getOrgById(params.orgId);

            if (res.status === HttpStatus.FORBIDDEN)
                updateIsForbiddenResource(true);
        };

        if (canAccess("organisation:read")) {
            fetchOrg();
        } else {
            updateIsForbiddenResource(true);
        }
    }, [params.orgId]);

    const handleAddNewEmail = () => {
        updateEmails([
            ...emails,
            {
                value: "",
                errorMessage: null,
            },
        ]);
    };

    const handleRemoveEmail = (i: number) => {
        if (emails.length === 1) return;

        const newEmailList = [...emails.slice(0, i), ...emails.slice(i + 1)];
        validateForm(newEmailList);
    };

    const validateForm = (newEmails: EmailItem[]) => {
        updateEmails(
            newEmails.map(({ value }: any, z: number) => {
                const errorMessages = [];
                if (!value) {
                    errorMessages.push("You need input the email.");
                } else if (!Patterns.emailPattern.test(value)) {
                    errorMessages.push("This email is invalid.");
                }

                if (
                    newEmails
                        .filter((_: any, j: number) => j !== z)
                        .find((e: any) => e.value === value)
                ) {
                    errorMessages.push("Already added.");
                }
                return {
                    value,
                    errorMessage: errorMessages.join(" "),
                };
            })
        );
    };

    const handleChangeEmailValue = (newValue: any, i: number) => {
        const newEmails = [...emails];
        newEmails[i].value = newValue;

        validateForm(newEmails);
    };

    const isAllValid =
        !emails.find((e) => e.errorMessage || !e.value) && emails.length > 0;

    const handleInvite = async () => {
        const res = await OrganisationService.inviteMembers(
            organization.currentOrgId,
            emails.map((e) => e.value.toLowerCase())
        );

        let body = "";

        if (isHttpSuccess(res.status)) {
            updateEmails(defaultEmails);
            body = "The Invitation has been sent successfully!";
        }

        setSimpleModalData({
            resObj: res,
            body,
        } as SimpleModalDataType);
    };

    const handleCancel = () => {
        history.push(`/organisation/${organization.currentOrgId}`);
    };

    return (
        <ContentWrapper
            simpleModalData={simpleModalData}
            isForbiddenResource={isForbiddenResource}
        >
            <div className="page-content">
                <Container>
                    <Row>
                        <Col sm="12" className="invite-user-header">
                            <h5 className="page-title">Invite Users</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item
                                    href={`/organisation/${organization.currentOrgId}`}
                                >
                                    Organisation
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Invite Users
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <div className="form-box">
                                {emails.map((email: any, index: number) => (
                                    <Form.Group key={email}>
                                        <Form.Label>Email Address</Form.Label>
                                        <InputGroup className="mb-3">
                                            <Form.Control
                                                type="text"
                                                required
                                                aria-label="email-address"
                                                value={email.value}
                                                className="invite-email"
                                                onChange={(e: any) =>
                                                    handleChangeEmailValue(
                                                        e.target.value,
                                                        index
                                                    )
                                                }
                                            />
                                            <InputGroup.Append className="align-items-center">
                                                <Button
                                                    variant="primary"
                                                    className="p-0 px-2 border-0 remove-email"
                                                    onClick={() =>
                                                        handleRemoveEmail(index)
                                                    }
                                                >
                                                    <span className="material-icons pt-1">
                                                        close
                                                    </span>
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                        {/* <Form.Control
                                            type="text"
                                            required
                                            aria-label="email-address"
                                            defaultValue={email.value}
                                            onChange={(e: any) => handleChangeEmailValue(e.target.value, index)}
                                        /> */}
                                        {email.errorMessage && (
                                            <Alert
                                                variant="danger"
                                                className="d-inline-block my-0 mt-1 px-2 py-0 border-0 error-message"
                                            >
                                                {email.errorMessage}
                                            </Alert>
                                        )}
                                    </Form.Group>
                                ))}

                                <Button
                                    variant="primary"
                                    onClick={handleAddNewEmail}
                                    disabled={!isAllValid}
                                >
                                    Add More Emails
                                </Button>
                            </div>
                            <Button
                                variant="secondary"
                                className="mt-4 mr-2"
                                onClick={handleCancel}
                            >
                                CANCEL
                            </Button>
                            <Button
                                variant="primary"
                                className="mt-4"
                                onClick={handleInvite}
                                disabled={!isAllValid}
                            >
                                INVITE
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ContentWrapper>
    );
};

export default InviteMembers;
