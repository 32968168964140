import { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { getOneRegistry, downloadStorage } from "service/gatewayService";
import downloadIcon from "assets/svg/download.svg";
import { walletModalTrigger } from "hooks/wallet";
import { HoverAuthorizeTooltip } from "components/authorize/AuthorizeTooltip";
import ContentWrapper, {
    SimpleModalDataType,
} from "components/content-wrapper/ContentWrapper";
import { HttpStatus, InputDateTimeFormat } from "constant";

const { OK, CREATED } = HttpStatus;

export const DownloadDataUI = ({
    registryDetails,
    gatewayId,
    ldsuId,
    said,
    downloadType,
}: any) => {
    const [startDateTime, setStartDateTime] = useState<any>(moment());
    const [endDateTime, setEndDateTime] = useState<any>(moment());
    const [onboardTime, setOnboardTime] = useState(0);
    const [downloadOption, setDownloadOption] = useState("downloadAll");

    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");
    const MODAL_CONFIRM = "confirm";
    const MODAL_SUCCESS = "success";
    const MODAL_ERROR = "err";
    const DOWNLOAD_BY_DATE = "downloadByDate";
    const [error, setError] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [simpleModalData, setSimpleModalData] =
        useState<null | SimpleModalDataType>(null);

    useEffect(() => {
        if (registryDetails) {
            const gatewayOnboardTime = moment(
                registryDetails.create_time
            ).unix();
            setOnboardTime(gatewayOnboardTime);
            const twentyFourHoursAgo = moment().subtract(24, "hours").unix();

            if (gatewayOnboardTime > twentyFourHoursAgo) {
                setStartDateTime(registryDetails.create_time);
            } else {
                setStartDateTime(moment().subtract(24, "hours"));
            }
        } else {
            const fetch = async () => {
                const response: any = await getOneRegistry(gatewayId);
                const { data: registry, status } = response;
                if (status === OK) {
                    const gatewayOnboardTime = moment(
                        registry.create_time
                    ).unix();
                    setOnboardTime(gatewayOnboardTime);
                    const twentyFourHoursAgo = moment()
                        .subtract(24, "hours")
                        .unix();

                    if (gatewayOnboardTime > twentyFourHoursAgo) {
                        setStartDateTime(registry.create_time);
                    } else {
                        setStartDateTime(moment().subtract(24, "hours"));
                    }
                }
            };
            fetch();
        }
    }, [gatewayId, registryDetails]);

    useEffect(() => {
        validate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDateTime, endDateTime]);

    const updateOption = (e: any) => {
        setDownloadOption(e.target.value);
    };

    const handleDownload = async () => {
        let response: any = await downloadStorage({
            start:
                downloadOption === DOWNLOAD_BY_DATE
                    ? moment(startDateTime).unix()
                    : onboardTime,
            end:
                downloadOption === DOWNLOAD_BY_DATE
                    ? moment(endDateTime).unix()
                    : moment().unix(),
            attributes: [
                {
                    gateway_id:
                        downloadType === "downloadGateway"
                            ? registryDetails.gateway_id
                            : gatewayId,
                    ...(downloadType === "downloadLDSU" ||
                    downloadType === "downloadSensor"
                        ? { device_id: ldsuId }
                        : {}),
                    ...(downloadType === "downloadSensor"
                        ? { said: Number(said) }
                        : {}),
                },
            ],
        });

        if (response.status === CREATED) {
            setSimpleModalData({
                resObj: response,
                body: `An email will be sent to you shortly once it is ready to download.`,
            } as SimpleModalDataType);
        } else {
            setSimpleModalData({
                resObj: response,
            } as SimpleModalDataType);
        }
    };

    const renderModalIcon = () => {
        if (modalType === MODAL_ERROR) {
            return <span className="material-icons">warning</span>;
        } else if (modalType === MODAL_CONFIRM) {
            return <span className="material-icons">forward_to_inbox</span>;
        }
        return <span className="material-icons">done</span>;
    };

    const renderModalTitle = () => {
        if (modalType === MODAL_ERROR) {
            return <h3 className="mb-3">Error</h3>;
        } else if (modalType === MODAL_CONFIRM) {
            return <h3 className="mb-3">Confirmation</h3>;
        }
        return <h3 className="mb-3">Success</h3>;
    };

    const renderModalButton = () => {
        if (modalType === MODAL_ERROR || modalType === MODAL_SUCCESS) {
            return (
                <Button variant="primary" onClick={() => setModalShow(false)}>
                    OK
                </Button>
            );
        }
        return (
            <>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setModalShow(false);
                    }}
                >
                    Cancel
                </Button>

                <Button
                    variant="primary"
                    onClick={() => {
                        setModalShow(false);
                        handleDownload();
                    }}
                >
                    OK
                </Button>
            </>
        );
    };

    const validate = () => {
        if (!endDateTime.isAfter(startDateTime)) {
            setError(true);
            setErrMsg("End time must be after start time");
        } else if (endDateTime.isAfter(moment())) {
            setErrMsg("End time must be before future time");
        } else {
            setError(false);
            setErrMsg("");
        }
    };

    const handleChangeStartDate = (newValue: any) => {
        newValue && setStartDateTime(moment(newValue._d));
    };

    const handleChangeEndDate = (newValue: any) => {
        newValue && setEndDateTime(moment(newValue._d));
    };

    return (
        <ContentWrapper simpleModalData={simpleModalData}>
            <HoverAuthorizeTooltip permission="gateway:update">
                <div className="download-data-option mt-5">
                    <Form.Check
                        name="download-data"
                        custom
                        type="radio"
                        value="downloadAll"
                        id={"download-all"}
                        checked={downloadOption === "downloadAll"}
                        onChange={(e: any) => {
                            updateOption(e);
                        }}
                        label={<span className="ml-2">Download all data</span>}
                    />
                </div>
            </HoverAuthorizeTooltip>
            <HoverAuthorizeTooltip permission="gateway:update">
                <div className="download-data-option">
                    <Form.Check
                        name="download-data"
                        custom
                        type="radio"
                        value={DOWNLOAD_BY_DATE}
                        id={"pick-date"}
                        checked={downloadOption === DOWNLOAD_BY_DATE}
                        onChange={(e: any) => {
                            updateOption(e);
                        }}
                        label={<span className="ml-2">Pick date range</span>}
                    />
                    {downloadOption === DOWNLOAD_BY_DATE ? (
                        <div className="mt-4 mb-4">
                            <LocalizationProvider dateAdapter={MomentUtils}>
                                <Stack spacing={3}>
                                    <div className="dateTimeFieldWrapper">
                                        <div className="dateTimeField">
                                            From
                                        </div>

                                        <DateTimePicker
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    label=""
                                                    InputLabelProps={{
                                                        shrink: false,
                                                    }}
                                                    onChange={(e) => {
                                                        handleChangeStartDate(
                                                            moment(
                                                                e.target.value,
                                                                InputDateTimeFormat
                                                            )
                                                        );
                                                    }}
                                                />
                                            )}
                                            PopperProps={{
                                                placement: "top",
                                            }}
                                            value={startDateTime}
                                            inputFormat={InputDateTimeFormat}
                                            onChange={handleChangeStartDate}
                                            maxDateTime={moment()}
                                        />
                                    </div>

                                    <div className="dateTimeFieldWrapper">
                                        <div className="dateTimeField">To</div>
                                        <DateTimePicker
                                            renderInput={(props) => (
                                                <TextField
                                                    {...props}
                                                    label=""
                                                    InputLabelProps={{
                                                        shrink: false,
                                                    }}
                                                    onChange={(e) => {
                                                        handleChangeEndDate(
                                                            moment(
                                                                e.target.value,
                                                                InputDateTimeFormat
                                                            )
                                                        );
                                                    }}
                                                />
                                            )}
                                            PopperProps={{
                                                placement: "top",
                                            }}
                                            value={endDateTime}
                                            inputFormat={InputDateTimeFormat}
                                            onChange={handleChangeEndDate}
                                            minDateTime={moment(startDateTime)}
                                            maxDateTime={moment()}
                                        />
                                    </div>
                                    {error && (
                                        <div className="dateTimeFieldWrapper">
                                            <div className="dateTimeField" />
                                            <p className="text-danger">
                                                {errMsg}
                                            </p>
                                        </div>
                                    )}
                                </Stack>
                            </LocalizationProvider>
                        </div>
                    ) : null}
                </div>
            </HoverAuthorizeTooltip>
            <HoverAuthorizeTooltip
                permission="gateway:update"
                className="w-100"
            >
                <Button
                    disabled={error && downloadOption === DOWNLOAD_BY_DATE}
                    className="w-100 mt-3"
                    onClick={() => {
                        walletModalTrigger(() => {
                            setModalShow(true);
                            setModalType(MODAL_CONFIRM);
                            setModalContent(
                                " By confirming, an email will be sent to your email address with instructions on how to download. Do you want to continue?"
                            );
                        });
                    }}
                >
                    <img src={downloadIcon} alt="" />
                    Download Data
                </Button>
            </HoverAuthorizeTooltip>
            <Modal
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-sm"
                className={`no-header ${
                    modalType === MODAL_ERROR ? "danger" : "primary"
                }`}
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">{renderModalIcon()}</div>
                    {renderModalTitle()}
                    <p className="mb-4">{modalContent}</p>
                    {renderModalButton()}
                </Modal.Body>
            </Modal>
        </ContentWrapper>
    );
};
