import styled from "@emotion/styled";
import { useState } from "react";
import { FormControlProps } from "react-bootstrap";
import { InputGroup, Form } from "react-bootstrap";

const PasswordInput = styled(Form.Control)`
    padding: 1.5rem 1rem;

    &::-ms-reveal {
        display: none;
    }
`;

export const PasswordIcon = styled.i`
    position: absolute;
    display: flex;
    align-items: center;
    background: inherit;
    height: 48px;
    margin: 1px;
    right: 0.5rem;
    cursor: pointer;
    z-index: 3;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    .is-invalid ~ & {
        background: #ffffff;
    }
`;

PasswordIcon.defaultProps = {
    className: "material-icons",
};

type PasswordFieldProps = FormControlProps &
    React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > & {
        error?: string;
    };

export const PasswordField = ({ error, name, ...restProps }: PasswordFieldProps) => {
    const [passwordShow, setPasswordShow] = useState(false);

    const toggleShowPassword = () => {
        setPasswordShow(!passwordShow);
    };

    return (
        <InputGroup>
            <PasswordInput
                {...restProps}
                type={passwordShow ? "text" : "password"}
                name={name}
            />
            <Form.Control.Feedback type="invalid">
                {error}
            </Form.Control.Feedback>
            <PasswordIcon
                aria-label={`hidden-${name}`}
                onClick={toggleShowPassword}
            >
                {passwordShow ? "visibility_off" : "visibility"}
            </PasswordIcon>
        </InputGroup>
    );
};

export default PasswordField;
