import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getAllJSON, getMetricInfo } from "service/jsonService";

const Loading = () => {
    useEffect(() => {
        const fetch = async () => {
            getAllJSON();
            getMetricInfo();
        };
        fetch();
    }, []);
    return (
        <div>
            <h1>Loading...</h1>
            <Link to="/">Reload</Link>
            <br />
            <Link
                to="/"
                onClick={() => {
                    localStorage.clear();
                }}
            >
                No response
            </Link>
        </div>
    );
};

export default Loading;
