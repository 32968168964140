import { useContext, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import { AppContext } from "context/appContext";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import { DashboardModal } from "components/modals/DashboardModal";
import { FormType } from "constant";
import {
    updateDashboardDetails,
    getDashboards,
    deleteDashboard,
} from "service/dashboardService";
import { isHttpSuccess, tagOptions } from "utils/functions";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import { storeDashboardAction } from "store/actions";
import LocalStorageService from "service/localStorageService";
import DefaultModal from "components/modals/ModalTemplate";

export const DashboardActionDropdown = () => {
    const { storeData, storeDispatchActions } = useContext(AppContext);
    const { dashboard, organization } = storeData;
    const { currentOrgId } = organization;
    const { currentDashboard } = dashboard;
    const [showDashboardForm, setShowDashboardForm] = useState(false);
    const [formType, setFormType] = useState<FormType>(FormType.DEFAULT);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    const handleCloseDashboardModal = () => {
        setShowDashboardForm(false);
    };

    const updateDashboardState = async (dashboardId?: string) => {
        const dashboardResp = await getDashboards(currentOrgId);

        if (!isHttpSuccess(dashboardResp.status)) {
            showErrorAlert({
                message: _.get(
                    dashboardResp,
                    "message",
                    "Unable to get Dashboards. Please try again."
                ),
            });
            return;
        }

        storeDispatchActions(
            storeDashboardAction({
                dashboardList: tagOptions(dashboardResp.data),
                targetId: dashboardId,
            })
        );

        return dashboardResp.data;
    };

    const updateDashboard = async (name: string, color: string) => {
        handleCloseDashboardModal();
        const updateResp = await updateDashboardDetails(
            currentDashboard.key,
            color,
            name
        );

        if (!isHttpSuccess(updateResp.status)) {
            showErrorAlert({
                message: _.get(
                    updateResp,
                    "message",
                    "Unable to update Dashboard. Please try again."
                ),
            });
            return;
        }

        showSuccessAlert({
            message: "Dashboard details have been updated successfully.",
        });

        const [currentDashboardOption] = tagOptions([updateResp.data]);
        LocalStorageService.setItem("dbInfo", currentDashboardOption);

        await updateDashboardState(currentDashboard.key);
    };

    const handleShowDashboardModal = () => {
        setFormType(FormType.UPDATE);
        setShowDashboardForm(true);
    };

    const closeConfirmationModal = () => {
        setShowConfirmationModal(false);
    };

    const handleDeleteDashboard = async () => {
        closeConfirmationModal();
        const deleteResp = await deleteDashboard(currentDashboard.key);

        if (!isHttpSuccess(deleteResp.status)) {
            showErrorAlert({
                message: _.get(
                    deleteResp,
                    "message",
                    "Unable to delete Dashboard. Please try again."
                ),
            });

            await updateDashboardState(currentDashboard.key);
            return;
        }

        showSuccessAlert({
            message: "Dashboard has been deleted successfully.",
        });

        const dashboards = await updateDashboardState();
        const [currentDashboardOption] = tagOptions(dashboards);
        LocalStorageService.setItem("dbInfo", currentDashboardOption);
    };

    return (
        <>
            <Dropdown className="dash-more float-left ml-2">
                <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                    <span className="material-icons">more_horiz</span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <HoverAuthorizeTooltip permission="dashboard:update">
                        <Dropdown.Item
                            as={Link}
                            to={`/dashboard/${currentDashboard.key}/update?organization_id=${currentOrgId}`}
                        >
                            Edit Dashboard Charts
                        </Dropdown.Item>
                    </HoverAuthorizeTooltip>

                    <HoverAuthorizeTooltip permission="dashboard:update">
                        <Dropdown.Item onClick={handleShowDashboardModal}>
                            Edit Dashboard Details
                        </Dropdown.Item>
                    </HoverAuthorizeTooltip>

                    <HoverAuthorizeTooltip permission="dashboard:delete">
                        <Dropdown.Item
                            onClick={() => setShowConfirmationModal(true)}
                        >
                            Delete Dashboard
                        </Dropdown.Item>
                    </HoverAuthorizeTooltip>
                </Dropdown.Menu>
            </Dropdown>
            <DashboardModal
                show={showDashboardForm}
                onClose={handleCloseDashboardModal}
                type={formType}
                onSubmit={updateDashboard}
                currentDashboard={currentDashboard}
            />

            <DefaultModal
                modalShow={showConfirmationModal}
                setModalShow={closeConfirmationModal}
                modalType="dlt"
                modalTitle="Confirm Delete"
                modalContent={
                    <>
                        Do you really want to delete this Dashboard? <br />
                        This cannot be undone.
                    </>
                }
                deleteFunction={handleDeleteDashboard}
            />
        </>
    );
};
