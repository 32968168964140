import { useEffect, useState, memo, useRef } from "react";
import ReactECharts from "echarts-for-react";
import { Dropdown, Button, Modal } from "react-bootstrap";
import { deletePanelV2 } from "service/dashboardService";
import { CHART_DELETE_SUCCESS_MESSAGE, ForbiddenErrorMessage } from "constant";
import { meteringService } from "service/meteringService";
import { humanReadableFileSize, orgId } from "utils/functions";
import { getOneRegistry } from "service/gatewayService";
import { EditDropdownLink } from "components/dashboard/update";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import ChartBox from "./ChartBox";
import { showSuccessAlert } from "utils/alert";

const StorageChart = (props: any) => {
    const {
        panel,
        selectedDashboardUUID,
        handleOnChangeDashboardUUID,
        zoomLevel,
    } = props;
    const thisGatewayId = panel.attributes[0]?.gateway_id;
    const thisPanelId = panel.uuid;
    const chartType = panel.chart;
    const [gatewayName, setGatewayName] = useState("");
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const [storageUsedByGateway, setStorageUsedByGateway] = useState(0);
    const [storageUsedByOtherGateway, setStorageUsedByOtherGateway] =
        useState(0);
    const bytePerSample = 4;
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [boxWidth, setBoxWidth] = useState(100);
    const totalStrInBytes = 5368709120;
    const boxElementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchStorage = async () => {
            const [registryRes, storageRes]: any = await Promise.all([
                getOneRegistry(thisGatewayId),
                meteringService.getStorageInfo(thisGatewayId),
            ]);
            if (registryRes.status === 200 && storageRes.status === 200) {
                const { name } = registryRes.data;
                const totalBytesUsed: number =
                    storageRes?.data?.[orgId()] * bytePerSample || 0;
                const currGWBytesUsed: number =
                    storageRes?.data?.gateway?.[thisGatewayId] *
                        bytePerSample || 0;
                setStorageUsedByOtherGateway(
                    Number(totalBytesUsed - currGWBytesUsed)
                );
                setStorageUsedByGateway(Number(currGWBytesUsed));
                setGatewayName(name);
            }
        };
        fetchStorage();
    }, [thisGatewayId]);

    useEffect(() => {
        const box = boxElementRef.current;
        if (box) {
            setBoxWidth(box.clientWidth - 80);
            const resizeObserver = new ResizeObserver((entries) => {
                setBoxWidth(entries[0]?.target.clientWidth - 80);
            });

            resizeObserver.observe(box);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [boxElementRef]);

    const setShowDeleteModal = () => {
        setError(false);
        setDeleteModalShow(true);
    };
    const handleCloseDeleteModal = () => {
        setDeleteModalShow(false);
    };

    const handleDeletePanel = async () => {
        deletePanelV2(selectedDashboardUUID, thisPanelId).then(
            (result: any) => {
                if (result.status === 204) {
                    handleOnChangeDashboardUUID(selectedDashboardUUID);
                    setDeleteModalShow(false);
                    showSuccessAlert({
                        message: CHART_DELETE_SUCCESS_MESSAGE,
                    });
                } else if (result.response?.status === 403) {
                    setError(true);
                    setErrorMessage(ForbiddenErrorMessage);
                } else {
                    setError(true);
                    setErrorMessage(
                        "Unable to delete panel. Please try again."
                    );
                }
            }
        );
    };

    const option: any = {
        backgroundColor: "#2F3A51",
        responsive: true,
        maintainAspectRatio: false,
        // tooltip: {
        //     trigger: "item",
        // },
        title: {
            show: true,
            left: 14,
            top: 15,
            text: panel.name || `${gatewayName} Storage Chart`,
            textStyle: {
                color: "#ffffff",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 18,
                overflow: "truncate",
                ellipsis: "...",
                width: boxWidth,
            },
            subtext: `Storage usage`,
            subtextStyle: {
                color: "#828FAB",
                fontFamily: "Roboto",
                fontWeight: 400,
                fontSize: 14,
                overflow: "breakAll",
                ellipsis: "...",
            },
        },
        legend: {
            show: false,
        },
        color: ["#00CBAF", "#128D7C", "#3E4B67"],
        // series: [
        //     {
        //         type: "pie",
        //         left: "50%",
        //         radius: ["40%", "70%"],
        //         data: [
        //             {
        //                 value: 1000,
        //                 name: "Storaged used by this gateway",
        //                 label: {
        //                     position: "inside",
        //                     formatter: `{d}%`,
        //                     color: "#FFFFFF",
        //                 },
        //             },
        //             {
        //                 value: 300,
        //                 name: "Storaged used by other gateways",
        //                 label: {
        //                     position: "inside",
        //                     formatter: `{d}%`,
        //                     color: "#FFFFFF",
        //                 },
        //             },
        //             {
        //                 value: 5000 - 144.3,
        //                 name: "Storage left",
        //                 label: {
        //                     position: "inside",
        //                     formatter: `{d}%`,
        //                     color: "#FFFFFF",
        //                 },
        //             },
        //         ],
        //     },
        // ],
        series: [
            {
                type: "pie",
                minAngle:
                    storageUsedByGateway === 0 ||
                    storageUsedByOtherGateway === 0
                        ? 0
                        : 5,
                radius:
                    chartType.toUpperCase() === "PIE" ? "55%" : ["30%", "55%"],
                avoidLabelOverlap: true,
                label: {
                    formatter: (params: any) => {
                        const {
                            percent,
                            data: { value: bytes, name },
                        } = params;
                        const dataUsed: string = humanReadableFileSize(
                            bytes,
                            false,
                            2
                        );

                        return `${name}: \n\n ${dataUsed} (${percent}%)`;
                    },
                    show: true,
                    position: "outside",
                    fontSize: 12,
                },
                labelLine: {
                    show: true,
                    length: 20,
                    length2: 20,
                    showAbove: true,
                },

                data: [
                    {
                        value: storageUsedByGateway,
                        name: "Sensor cache usage by this Gateway",
                    },
                    {
                        value: storageUsedByOtherGateway,
                        name: "Sensor cache usage by all other Gateway(s)",
                    },
                    {
                        value:
                            totalStrInBytes -
                            storageUsedByGateway -
                            storageUsedByOtherGateway,
                        name: "Free sensor cache storage capacity",
                    },
                ],
            },
        ],
    };
    return (
        <>
            <ChartBox
                className={"widget-box temperature"}
                style={{
                    height: zoomLevel <= 0.5 ? "30vh" : 400,
                    width: "100%",
                }}
                ref={boxElementRef}
            >
                <div className="widget">
                    <Dropdown alignRight>
                        <Dropdown.Toggle variant="" className="more-icon">
                            <i className="material-icons">more_vert</i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <HoverAuthorizeTooltip permission="dashboard:update">
                                <EditDropdownLink panelId={panel.uuid} />
                            </HoverAuthorizeTooltip>
                            <HoverAuthorizeTooltip permission="dashboard:delete">
                                <Dropdown.Item onClick={setShowDeleteModal}>
                                    Delete
                                </Dropdown.Item>
                            </HoverAuthorizeTooltip>
                        </Dropdown.Menu>
                    </Dropdown>
                    <ReactECharts
                        className="chart-box"
                        option={option}
                        theme={"dark"}
                    />
                </div>
            </ChartBox>
            <Modal
                centered
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                backdrop="static"
                keyboard="false"
                aria-labelledby="example-modal-sizes-title-sm"
                className="no-header danger"
            >
                <Modal.Body className="text-center mt-3 mb-3">
                    <div className="modal-icon-box">
                        <span className="material-icons">delete</span>
                    </div>
                    <h3 className="mb-3">Confirm Delete</h3>
                    <p className="mb-4">
                        Do you really want to delete this chart? <br />
                        This cannot be undone.
                    </p>

                    <Button
                        variant="secondary"
                        onClick={handleCloseDeleteModal}
                    >
                        CANCEL
                    </Button>
                    <Button variant="danger" onClick={handleDeletePanel}>
                        DELETE
                    </Button>
                    <div className="text-danger">
                        {error ? errorMessage : ""}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default memo(StorageChart);
