import AxiosInstance from "./axiosInstance";
import _ from "lodash";
import { combineResultArrays, isHttpSuccess } from "utils/functions";
import { UnknownSensor } from "constant";
import { getSensorConfig } from "./gatewayService";
const APP_DASHBOARD_API = process.env.REACT_APP_DASHBOARD_API;

const changeDataTo2DP = (data: any, chart_type: string) => {
    for (let i = 0; i < data.values.length; i++) {
        if (chart_type === "SINGLE") {
            data.values[i][0] = data.values[i][0] * 1000;
        }
        data.values[i][1] = Number.parseFloat(data.values[i][1]).toFixed(2);
    }
    return data;
};

const splitSeriesMissing = (
    _result: any[],
    gapTime: number,
    chartOptions: any,
    timeValue: string,
    isMillisecond = false,
    chartMissingOption: any = {
        type: "line",
        itemStyle: {
            color: "background: rgba(243, 199, 173, 0.05)",
        },
        lineStyle: {
            color: "rgba(251, 108, 89, 1)",
            type: "dashed",
        },
        smooth: true,
        showSymbol: false,
        areaStyle: {},
        markLine: {
            symbol: ["none", "none"],
            lineStyle: {
                color: "#ECA48E",
                type: "dashed",
            },
            label: {
                show: false,
            },
            data: [
                {
                    xAxis: 1652672793000,
                },
                {
                    xAxis: 1652672862000,
                },
            ],
        },
    }
) => {
    const result = (_result || []).reduce(
        (arr, { metric = {}, values = [] }) => {
            if (arr[0]) {
                arr[0].metric = metric;
                arr[0].values = [...arr[0].values, ...values];
            } else {
                arr.push({
                    metric,
                    values,
                });
            }
            return arr;
        },
        []
    );
    if (result?.[0]?.values) {
        _.set(
            result,
            "[0].values",
            _.orderBy(result?.[0]?.values, ["[0]"], ["asc"])
        );
    }

    const configData = {
        ...chartOptions,
    };
    const configMissingData = {
        ...chartOptions,
        ...chartMissingOption,
    };
    let metric_name = chartOptions.name;
    const names = [];
    let seriesGroup = [];
    let seriesGroupItem: any[] = [];
    let isMissing = false;
    let curItem;
    const multiTime = isMillisecond ? 1 : 1000;
    gapTime = gapTime * 1.5;
    for (let i = 0; i < result.length; i++) {
        metric_name = result[i]?.metric?.name ?? metric_name;
        // reset vars
        seriesGroup = [];
        seriesGroupItem = [];
        curItem = [...(result[i].values?.[0] || [])];
        curItem[0] = curItem[0] * multiTime;
        let cur1 = curItem,
            cur2 = curItem;
        names.push(metric_name);

        for (let j = 0; j < result[i].values.length; j++) {
            curItem = [...result[i].values[j]];
            curItem[0] = curItem[0] * multiTime;
            if (isMissing === false) {
                // non-missing data
                if (
                    curItem[0] === null ||
                    cur2[0] === null ||
                    curItem[0] - cur2[0] > gapTime
                ) {
                    // => missing
                    // 1. push cur1-> cur2 (seriesGroupItem) as non-missing
                    if (cur1[0] !== cur2[0]) {
                        seriesGroup.push({
                            ...configData,
                            name: metric_name,
                            data: [...seriesGroupItem],
                            debug: 0,
                        });
                    } else {
                        // do nothing
                    }
                    // console.debug("🚀 ~ file: proqueryService.ts ~ line 105 ~ do nothing")
                    // 2. reset all vars
                    isMissing = true;
                    seriesGroupItem = [cur2];
                    cur1 = cur2;
                    j = j - 1;
                } else {
                    cur2 = curItem;
                    seriesGroupItem.push(curItem);
                }
            } else {
                if (
                    curItem[0] === null ||
                    cur2[0] === null ||
                    curItem[0] - cur2[0] <= gapTime
                ) {
                    // => non-missing
                    // 1. push cur1-> cur2 (seriesGroupItem) as non-missing
                    if (cur1[0] !== cur2[0]) {
                        const missingData = _.cloneDeep(seriesGroupItem);
                        missingData[0][0] = missingData[0][0] + 10;
                        missingData[missingData.length - 1][0] =
                            missingData[missingData.length - 1][0] - 10;
                        seriesGroup.push({
                            ...configMissingData,
                            name: metric_name,
                            data: missingData,
                            debug: 1,
                        });
                    } else {
                        // do nothing
                        // console.debug("🚀 ~ file: proqueryService.ts ~ line 131 ~ do nothing")
                    }
                    // 2. reset all vars
                    isMissing = false;
                    seriesGroupItem = [cur2];
                    cur1 = cur2;
                    j = j - 1;
                } else {
                    cur2 = curItem;
                    seriesGroupItem.push(curItem);
                }
            }
        }

        if (isMissing) {
            const missingData = _.cloneDeep(seriesGroupItem);
            missingData[0][0] = missingData[0][0] + 10;
            missingData[missingData.length - 1][0] = missingData[0][0] - 10;
            seriesGroup.push({
                ...configMissingData,
                name: metric_name,
                data: missingData,
                debug: 2,
            });
        } else {
            seriesGroup.push({
                name: metric_name,
                data: [...seriesGroupItem],
                ...configData,
                debug: 3,
            });
        }
    }

    return {
        names,
        series: seriesGroup,
    };
};

const _getSampleQuery = ({
    response,
    gapTime,
    chartColor,
    timeValue,
    prepareAggregated,
    minResponse,
    maxResponse,
    sensorType,
}: any) => {
    const funcRet: {
        info: any;
        names: any;
        series: any;
        aggregatedSeries: any;
    } = {
        info: _.get(response.data.data.result, "[0].metric", {}),
        names: [],
        series: [],
        aggregatedSeries: undefined,
    };

    const { names: seriesGroupNames, series: seriesGroup } = splitSeriesMissing(
        response.data.data.result,
        gapTime,
        {
            type: "line",
            smooth: true,
            showSymbol: false,
            itemStyle: {
                color: chartColor,
            },
        },
        timeValue
    );

    if (prepareAggregated) {
        if (
            !response.data.data.result[0] ||
            !minResponse.data.data.result[0] ||
            !maxResponse.data.data.result[0]
        ) {
            return {
                info: {},
                names: [""],
                series: [],
            };
        }

        // BE may return multiple data arrays. Combine them and remove duplicate timestamps
        combineResultArrays(response);
        combineResultArrays(minResponse);
        combineResultArrays(maxResponse);

        const results = changeDataTo2DP(response.data.data.result[0], "SINGLE");

        const minResults = changeDataTo2DP(
            minResponse.data.data.result[0],
            "SINGLE"
        );
        const maxResults = changeDataTo2DP(
            maxResponse.data.data.result[0],
            "SINGLE"
        );
        const series: any = [];
        const resBody = {
            symbolSize: 15,
            color: chartColor,
            type: "scatter",
            name: sensorType,
            data: results.values,
        };

        const maxBody = {
            symbolSize: 7,
            color: "#9BD88C",
            type: "scatter",
            name: "Max",
            data: maxResults.values,
        };
        const minBody = {
            symbolSize: 7,
            color: "#ED8787",
            type: "scatter",
            name: "Min",
            data: minResults.values,
        };
        const lineDataList = (
            readinglst: any,
            maxReadinglst: any,
            minReadingLst: any
        ) => {
            const body = [];
            for (let i = 0; i < readinglst.values.length; i++) {
                body.push(_.cloneDeep(readinglst.values[i]));
                body.push(maxReadinglst.values[i]);
                body.push(minReadingLst.values[i]);
            }

            for (let i = 0; i < body.length; i = i + 3) {
                body[i][1] = NaN;
            }

            return body;
        };
        const lineBody = {
            color: "#BECAE3",
            type: "line",
            zlevel: -1,
            data: lineDataList(results, maxResults, minResults),
        };
        series.push(resBody, maxBody, minBody, lineBody);
        funcRet.names = [results.metric.name, "Max", "Min"];
        funcRet.aggregatedSeries = series;
        funcRet.series = seriesGroup;
    }

    funcRet.names = seriesGroupNames;
    funcRet.series = seriesGroup;

    return funcRet;
};

export const getSampleQuery = async (
    query: string,
    chartColor: any,
    timeValue: number,
    prepareAggregated: boolean,
    sensorType: string,
    panelToken: string,
    gapTime: number,
    lastTimestamp: string
) => {
    let response: any;
    let minResponse: any;
    let maxResponse: any;
    //! keep for debugging purposes
    // const sample = {"data":{"status":"success","data":{"resultType":"matrix","result":[{"metric":{"__name__":"avg_1h_temperature_degree_c","container":"mqtt2tsdb","endpoint":"metrics","gateway":"PH80XXRR07052178645","instance":"192.168.9.144:8000","job":"backend/mqtt2tsdb-ph80xxrr07052178645","name":"4in1_sensor","namespace":"backend","object":"32768","ops":"avg_over_time","pod":"mqtt2tsdb-694667b554-r6hfk","prometheus":"backend/kprom-kube-prometheus-prometheus","said":"1","type":"hourly","uid":"PH80XXRR0705217864500"},"values":[[1655879641.378,"65.51356521739132"],[1655886841.378,"65.51356521739132"]]}]}}}
    // response = sample
    // minResponse = sample
    // maxResponse = sample
    if (
        !prepareAggregated // timeValue < 21600 // 6h
    ) {
        try {
            response = await AxiosInstance.get(
                APP_DASHBOARD_API +
                    `/query?query=${query}[${timeValue}s]&time=${lastTimestamp}&token=${panelToken}`
            );
        } catch (err) {
            // TO BE ADDED: Handle error obj
            return err;
        }
    } else {
        let averageBy: string;
        query = query.replace("mqtt2tsdb", "");
        if (timeValue < 604800) {
            //7d
            averageBy = "1h";
        } else {
            averageBy = "1d";
        }
        [response, minResponse, maxResponse] = await Promise.all([
            AxiosInstance.get(
                APP_DASHBOARD_API +
                    `/query?query=avg_${averageBy}${query}[${timeValue}s]&time=${lastTimestamp}&token=${panelToken}`
            ),
            AxiosInstance.get(
                APP_DASHBOARD_API +
                    `/query?query=min_${averageBy}${query}[${timeValue}s]&time=${lastTimestamp}&token=${panelToken}`
            ),
            AxiosInstance.get(
                APP_DASHBOARD_API +
                    `/query?query=max_${averageBy}${query}[${timeValue}s]&time=${lastTimestamp}&token=${panelToken}`
            ),
        ]);
    }

    return _getSampleQuery({
        response,
        gapTime,
        chartColor,
        timeValue,
        prepareAggregated,
        minResponse,
        maxResponse,
        sensorType,
    });
};

export const getStackedChartQuery = async (
    panels: any,
    timeValue: number,
    namesAry: any,
    yAxisAry: any,
    panelToken: any,
    gapTime = 5000,
    lastTimestamp: string
) => {
    let newQueries: any = _.cloneDeep(panels.queries);
    const promiseAllAry: any = [];
    for (let i = 0; i < newQueries.length; i++) {
        if (timeValue < 21600) {
            promiseAllAry.push(
                APP_DASHBOARD_API +
                    `/query?query=${newQueries[i]}[${timeValue}s]&time=${lastTimestamp}&token=${panelToken[i]}`
            );
        } else {
            let averageBy: string;
            newQueries[i] = newQueries[i].replace("mqtt2tsdb", "");
            if (timeValue < 604800) {
                averageBy = "1h";
            } else {
                averageBy = "1d";
            }
            promiseAllAry.push(
                APP_DASHBOARD_API +
                    `/query?query=avg_${averageBy}${newQueries[i]}[${timeValue}s]&time=${lastTimestamp}&token=${panelToken[i]}`
            );
        }
    }
    try {
        newQueries = await Promise.all(
            promiseAllAry.map((i: any) => AxiosInstance.get(i))
        );
    } catch (err) {
        // TO BE ADDED: Handle error obj
        return err;
    }

    let result: any;
    let series: any = [];
    for (let h = 0; h < newQueries.length; h++) {
        if (timeValue < 21600) {
            result = newQueries[h].data.data.result;
        } else {
            combineResultArrays(newQueries[h]);

            result = [
                changeDataTo2DP(newQueries[h].data.data.result[0], "STACKED"),
            ];
        }

        const thisIndex = yAxisAry.findIndex(
            (info: any) =>
                info.time_series === panels.attributes[h]?.time_series
        );

        let dataResult = [];

        for (let i = 0; i < result.length; i++) {
            for (let j = 0; j < result[i].values.length; j++) {
                result[i].values[j][0] = result[i].values[j][0] * 1000;
            }

            dataResult = result[i].values;
        }

        const sample = {
            name: namesAry[h] ? `${namesAry[h]}` : "",
            type: "line",
            itemStyle: {
                color: panels.attributes[h]?.color,
            },
            yAxisIndex: thisIndex !== -1 ? thisIndex : 0,
            lineStyle: {
                type: thisIndex === 0 ? "solid" : [10, 2],
            },
            data: dataResult,
            smooth: true,
            showSymbol: false,
        };

        series = [...series, sample];
    }
    const transformedData = {
        series: series,
    };

    return transformedData;
};

export const getStackedChartNames = async (panels: any) => {
    const { attributes = []  } = panels;

    const _names = await Promise.all(
        attributes.map(
            async ({
                gateway_id,
                device_id,
                said,
            }: {
                gateway_id: string;
                device_id: string;
                said: string;
            }) => {
                const sensorConfigRes = await getSensorConfig(
                    gateway_id,
                    device_id,
                    said
                );
                if (isHttpSuccess(sensorConfigRes.status)) {
                    const {
                        data: { sensor_name = UnknownSensor },
                    } = sensorConfigRes;
                    return sensor_name;
                } else {
                    return UnknownSensor;
                }
            }
        )
    );
    return _names;
};

export const getOnlineOfflineValue = async (
    gateway_id: string,
    timeValue: string,
    panelToken: string
) => {
    try {
        const res: any = await AxiosInstance.get(
            APP_DASHBOARD_API +
                `/query?query=sum(count_over_time(mqtt2tsdb_heartbeat_na{gateway="${gateway_id}"}[${timeValue}]))&token=${panelToken}`
        );
        return res;
    } catch (err) {
        return err;
    }
};
