import {
    Container,
    Row,
    Col,
    Breadcrumb,
    Button,
    Tab,
    Nav,
    Modal,
    Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import BillingHistory from "./BillingHistory";
import BillingAddress from "./BillingAddress";
import ManagePaymentInfoTab from "components/subscriptions/ManagePaymentInfoTab";
import { useSubscriptionContext } from "context/subscriptionContext";
import {
    HttpStatus,
    SUBSCRIPTION_CANCELLATION_CONFIRMATION_MESSAGE,
    SUBSCRIPTION_DATE_FORMAT,
} from "constant";
import moment from "moment";
import { useEffect, useState } from "react";
import {
    cancelSubscription,
    getLatestSubscription,
} from "service/subscriptionService";
import { isEmpty } from "lodash";

// ========================= DEFINITIONS =========================
const MODAL_ERROR = "err";
const MODAL_CONFIRM = "confirm";
const MODAL_RESUBSCRIPTION_CONFIRM = "resubscriptionConfirm";
const MODAL_SUBSCRIPTION_CONFIRM = "subscriptionConfirm";
const MODAL_CANCELLATION_CONFIRM = "cancellationConfirm";
const MODAL_CANCELLATION_PRECONFIRM = "cancellationPreconfirm";
const MODAL_SUCCESS = "success";
const MODAL_PENDING = "pending";
const { CREATED } = HttpStatus;

const ManageSubscription = () => {
    // ========================= CONTEXT/STATES =========================

    const {
        subscription,
        subscription: { billing_period_end_date, uuid, next_billing_date },
        setSubscription,
    }: any = useSubscriptionContext();

    const [modalShow, setModalShow] = useState(false);
    const [modalType, setModalType] = useState("");
    const [modalContent, setModalContent] = useState("");
    const [isActionPending, updateIsActionPending] = useState(false);

    // ========================= USEEFFECT =========================

    useEffect(() => {
        const fetchLatestSubscription = async () => {
            const latestSubscription = await getLatestSubscription();
            setSubscription(latestSubscription);
        };
        if (isEmpty(subscription)) {
            fetchLatestSubscription();
        }
    }, [subscription, setSubscription]);

    // ========================= FUNCTIONS =========================

    const handleCancelSubscription = async () => {
        setModalShow(false);
        updateIsActionPending(true);

        const response: any = await cancelSubscription(uuid);
        if (response.status === CREATED) {
            updateIsActionPending(false);
            setModalShow(true);
            setModalType(MODAL_SUCCESS);
            setModalContent("Succesfully cancelled subscription.");
        } else {
            updateIsActionPending(false);
            setModalShow(true);
            setModalType(MODAL_ERROR);
            setModalContent("Something went wrong. Please try again later.");
        }
    };

    // ========================= MODAL POPUP =========================

    const confirmToCancelSubscription = () => {
        setModalType(MODAL_CANCELLATION_CONFIRM);
        setModalContent(
            SUBSCRIPTION_CANCELLATION_CONFIRMATION_MESSAGE.fill({
                billing_period_end_date: moment(billing_period_end_date).format(
                    SUBSCRIPTION_DATE_FORMAT
                ),
                next_billing_date: moment(next_billing_date)
                    .add(1, "M")
                    .format(SUBSCRIPTION_DATE_FORMAT),
            })
        );
    };

    const renderModalIcon = () => {
        if (
            modalType === MODAL_ERROR ||
            modalType === MODAL_CONFIRM ||
            modalType === MODAL_PENDING ||
            modalType === MODAL_CANCELLATION_PRECONFIRM
        ) {
            return <span className="material-icons">warning</span>;
        } else if (
            modalType === MODAL_RESUBSCRIPTION_CONFIRM ||
            modalType === MODAL_CANCELLATION_CONFIRM ||
            modalType === MODAL_SUBSCRIPTION_CONFIRM
        ) {
            return <span className="material-icons">help_outline</span>;
        }
        return <span className="material-icons">done</span>;
    };

    const renderModalTitle = () => {
        if (modalType === MODAL_ERROR) {
            return <h3 className="mb-3">Error</h3>;
        } else if (
            modalType === MODAL_CONFIRM ||
            modalType === MODAL_SUBSCRIPTION_CONFIRM ||
            modalType === MODAL_CANCELLATION_PRECONFIRM
        ) {
            return <h3 className="mb-3">Confirmation</h3>;
        } else if (modalType === MODAL_CANCELLATION_CONFIRM) {
            return <h3 className="mb-3">Cancel subscription?</h3>;
        } else if (modalType === MODAL_PENDING) {
            return <h3 className="mb-3">Pending</h3>;
        }
        return <h3 className="mb-3">Success</h3>;
    };

    const renderModalButton = () => {
        if (modalType === MODAL_ERROR || modalType === MODAL_PENDING) {
            return (
                <Button variant="primary" onClick={() => setModalShow(false)}>
                    OK
                </Button>
            );
        } else if (modalType === MODAL_CANCELLATION_PRECONFIRM) {
            return (
                <>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setModalShow(false);
                        }}
                    >
                        NO
                    </Button>

                    <Button
                        variant="primary"
                        onClick={confirmToCancelSubscription}
                    >
                        YES
                    </Button>
                </>
            );
        } else if (modalType === MODAL_CANCELLATION_CONFIRM) {
            return (
                <>
                    <Button
                        variant="primary"
                        onClick={() => {
                            setModalShow(false);
                        }}
                    >
                        Keep subscription
                    </Button>

                    <Button variant="danger" onClick={handleCancelSubscription}>
                        Cancel subscription
                    </Button>
                </>
            );
        }
    };

    return (
        <>
            <div className="page-content subscription-page">
                <Container fluid>
                    <Row>
                        <Col>
                            <h5 className="page-title">Manage Subscription</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <Breadcrumb className="w-100">
                                <Breadcrumb.Item>
                                    <Link
                                        to={{
                                            pathname: `/manage-subscription`,
                                        }}
                                    >
                                        Subscription
                                    </Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    Manage Subscription
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="page-tabs">
                                <Tab.Container
                                    id="left-tabs-example"
                                    defaultActiveKey="payment-info"
                                >
                                    <Row>
                                        <Col sm={12}>
                                            <Nav>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="payment-info">
                                                        <h6>Manage</h6>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="billing-history">
                                                        <h6>Billing History</h6>
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="billing-address">
                                                        <h6>Billing Address</h6>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Col>
                                        <Col sm={12}>
                                            <Tab.Content>
                                                <Tab.Pane eventKey="payment-info">
                                                    <ManagePaymentInfoTab />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="billing-history">
                                                    <BillingHistory />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="billing-address">
                                                    <BillingAddress />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Col>
                                    </Row>
                                </Tab.Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Modal
                    centered
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="example-modal-sizes-title-sm"
                    className={`no-header ${
                        modalType === MODAL_SUCCESS ? "primary" : "danger"
                    }`}
                >
                    <Modal.Body className="text-center mt-3 mb-3">
                        <div className="modal-icon-box">
                            {renderModalIcon()}
                        </div>
                        {renderModalTitle()}
                        <p className="mb-4">{modalContent}</p>
                        {renderModalButton()}
                    </Modal.Body>
                </Modal>
                <Modal
                    centered
                    show={isActionPending}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="example-modal-sizes-title-sm"
                    className="no-header"
                >
                    <Modal.Body className="text-center mt-3 mb-5 mr-4">
                        <Spinner
                            className="centered-spinner"
                            animation="border"
                            variant="primary"
                        />
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
};

export default ManageSubscription;
