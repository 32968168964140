import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    Col,
    Button,
    Form,
    FormControl,
    InputGroup,
    Modal,
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { isEmpty } from "lodash";

import {
    getAssociationConfig,
    updateAssociationConfig,
    getRegistryConfig,
    getRegistryInfo,
    transformDeviceList,
} from "service/gatewayService";

import { isHttpSuccess } from "utils/functions";
import HoverAuthorizeTooltip from "components/authorize/AuthorizeTooltip";
import FormDeviceValueControl from "components/form-control/FormDeviceValueControl";
import { CanBeAssociatedSensorsCLS } from "constant";
import { showErrorAlert, showSuccessAlert } from "utils/alert";
import { AssociatedSensor } from "types/Sensors";

const SensorAssociation = ({
    gatewayName,
    postIsLoading,
    associatedSensors,
}: {
    gatewayName: string;
    postIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    associatedSensors: AssociatedSensor[];
}) => {
    const params: any = useParams();
    const { gatewayId, ldsuId, said } = params;
    const [mode, setMode] = useState<number>(0);
    const [fixedValue, setFixedValue] = useState<string>("");
    const [sensorList, setSensorList] = useState<Array<any>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [query, setQuery] = useState<string>("");
    const [selectSensorShow, setSelectSensorShow] = useState<boolean>(false);
    const [selectedRadioButton, setSelectedRadioButton] = useState<string>("");
    const [selectedSensorValue, setSelectedSensorValue] = useState<string>("");
    const [errorSensor, setErrorSensor] = useState<boolean>(false);
    const [postError, setPostError] = useState<boolean>(false);

    const [associatedTemperatureSensor] = associatedSensors.filter(
        ({ type }: { type: string }) => type === "temperature"
    );
    const {
        accuracy: associated_accuracy,
        max: associated_max,
        min: associated_min,
    } = associatedTemperatureSensor;

    const [selectedSensorLdsuId, selectedSensorSaid] =
        selectedSensorValue.split("_");

    const {
        sensor_type = "",
        device_name = "",
        bus = "",
    } = sensorList.find(
        (s) =>
            s.ldsu_uuid === selectedSensorLdsuId &&
            s.SAID === Number(selectedSensorSaid)
    ) ?? {};

    useEffect(() => {
        const fetchAssociationConfig = async () => {
            const res: any = await getAssociationConfig(
                gatewayId,
                ldsuId,
                said
            );

            if (isHttpSuccess(res.status)) {
                const config = res.data.ASSOC[0];

                if (!isEmpty(config)) {
                    const {
                        source,
                        attributes: { device_id = "", said = "", value = "" },
                    } = config;

                    if (source === "FIXED") {
                        setMode(1);
                        setFixedValue(String(value));
                    }
                    if (source === "SENSOR") {
                        setMode(0);
                        setSelectedSensorValue(`${device_id}_${said}`);
                        setSelectedRadioButton(`${device_id}_${said}`);
                    }
                }
            }
        };
        fetchAssociationConfig();
    }, [gatewayId, ldsuId, said]);

    useEffect(() => {
        let ignore = false;
        const fetchInfoConfig = async () => {
            const [infoRes, configRes] = await Promise.all([
                getRegistryInfo(gatewayId),
                getRegistryConfig(gatewayId),
            ]);
            if (
                !ignore &&
                isHttpSuccess(configRes.status) &&
                isHttpSuccess(infoRes.status)
            ) {
                const sensors = transformDeviceList(
                    infoRes.data.devices,
                    configRes.data,
                    infoRes.data.registry
                ).filter((i: any) => CanBeAssociatedSensorsCLS.includes(i.CLS));

                setSensorList(sensors);
            }
        };
        fetchInfoConfig();
        return () => {
            ignore = true;
        };
    }, [gatewayId]);

    const handleSelectSensor = async () => {
        setIsLoading(true);
        setErrorSensor(false);
        setSelectSensorShow(true);
        setIsLoading(false);
    };

    const renderSensorList = () => {
        const lst = sensorList
            .filter((s) => {
                return query
                    ? s.device_name.toLowerCase().includes(query)
                    : true;
            })
            .map(({ ldsu_uuid, device_name, SAID, bus, sensor_type }) => {
                return (
                    <div key={`${ldsu_uuid}_${SAID}`} className="modal-option">
                        <Form.Check
                            name="selected-sensor"
                            value={`${ldsu_uuid}_${SAID}`}
                            checked={
                                `${ldsu_uuid}_${SAID}` === selectedRadioButton
                            }
                            onChange={(e) => {
                                setSelectedRadioButton(e.target.value);
                            }}
                            custom
                            type="radio"
                            id={`${ldsu_uuid}_${SAID}`}
                            label={
                                <div>
                                    <h4>{device_name}</h4>{" "}
                                    <p className="selectionSubtext">{`${gatewayName} - LDS BUS ${bus}`}</p>
                                    <p className="selectionSubtext">{`LDSU UUID: ${ldsu_uuid}`}</p>
                                    <p className="selectionSubtext">
                                        {sensor_type}
                                    </p>
                                </div>
                            }
                            className="float-left"
                        />
                    </div>
                );
            });
        return lst;
    };

    const handleSensorDone = () => {
        if (!selectedRadioButton) {
            setErrorSensor(true);
            return;
        }
        setSelectedSensorValue(selectedRadioButton);
        setSelectSensorShow(false);
        setPostError(false);
        setQuery("");
    };

    const handleModeChange = (value: string) => {
        setPostError(false);
        setMode(Number(value));
    };

    const hideSensorSelectionModal = () => {
        setSelectSensorShow(false);
        setQuery("");
    };

    const handleUpdateAssoc = async () => {
        setPostError(false);
        let payLoadDetails: any;
        if (mode === 0) {
            if (selectedSensorValue) {
                payLoadDetails = {
                    ASSOC: [
                        {
                            source: "SENSOR",
                            attributes: {
                                device_id: selectedSensorLdsuId,
                                gateway_id: gatewayId,
                                said: Number(selectedSensorSaid),
                            },
                        },
                    ],
                };
            }
            if (!selectedSensorValue) {
                // If no sensor is selected means remove sensor association send empty array.
                payLoadDetails = {
                    ASSOC: [],
                };
            }
        }
        if (mode === 1) {
            if (!fixedValue || isNaN(Number(fixedValue))) {
                showErrorAlert({
                    message: "Please enter a valid sensor value",
                });

                return;
            }

            if (
                Number(fixedValue) < Number(associated_min) ||
                Number(fixedValue) > Number(associated_max)
            ) {
                showErrorAlert({
                    message: `Value cannot exceed the range. Range for sensor value should be ${associated_min} to ${associated_max}.`,
                });
                return;
            }

            payLoadDetails = {
                ASSOC: [
                    {
                        source: "FIXED",
                        attributes: {
                            value: fixedValue,
                        },
                    },
                ],
            };
        }

        postIsLoading(true);
        const res: any = await updateAssociationConfig(
            gatewayId,
            ldsuId,
            said,
            payLoadDetails
        );

        if (isHttpSuccess(res?.status)) {
            postIsLoading(false);
            showSuccessAlert({
                message: `Sensor association has been updated successfully.`,
            });
        } else {
            postIsLoading(false);
            showErrorAlert({
                message:
                    res?.data?.description ||
                    `Unable to associate sensor. Please try again.`,
            });
        }
    };
    return (
        <>
            <div className="form-box mt-2 pb-3">
                <Form.Row>
                    <Col md="12">
                        <h5>Sensor Association</h5>
                    </Col>
                </Form.Row>
                <Form.Row className="mt-3">
                    <Col xl="5" md="12" className="mt-2">
                        <h6>Sensor Type</h6>
                    </Col>
                    <Col xl="7" md="12">
                        <Form.Control as="select" custom>
                            <option value="Temperature">Temperature</option>
                        </Form.Control>
                    </Col>
                </Form.Row>
                <Form.Row className="mt-3">
                    <Col xl="5" md="12" className="mt-2">
                        <h6>Value</h6>
                    </Col>
                    <Col xl="7" md="12" className="mt-2">
                        <Form.Check
                            className="float-left pr-3"
                            name="sensor-association-value"
                            id={"sensorAssoc"}
                            custom
                            type="radio"
                            value={0}
                            checked={mode === 0}
                            onChange={(e) => {
                                handleModeChange(e.target.value);
                            }}
                            label={
                                <span className="ml-2">Associate a sensor</span>
                            }
                        />
                        <Form.Check
                            className="float-left"
                            name="sensor-association-value"
                            id={"fixed"}
                            custom
                            type="radio"
                            value={1}
                            checked={mode === 1}
                            onChange={(e) => {
                                handleModeChange(e.target.value);
                            }}
                            label={<span className="ml-2">Fixed</span>}
                        />
                    </Col>
                </Form.Row>
                {mode === 0 ? (
                    // For radio selection - associate a sensor
                    <Form.Row className="mt-3">
                        <Col md="12" className="mt-2">
                            <h6>Associate a sensor</h6>
                        </Col>
                        <Col md="12" className="mt-2">
                            {!selectedSensorValue ? (
                                <HoverAuthorizeTooltip permission="gateway:update">
                                    <Button onClick={handleSelectSensor}>
                                        SELECT A SENSOR
                                    </Button>
                                </HoverAuthorizeTooltip>
                            ) : (
                                <div className="selected-sensor">
                                    {/* Note: This section is to allow sensor to be de-associated or removed */}
                                    <span
                                        className="material-icons sensor-close float-right text-secondary-red-1"
                                        onClick={() => {
                                            setSelectedSensorValue("");
                                        }}
                                    >
                                        close
                                    </span>
                                    <h6>{device_name}</h6>
                                    <p className="selectionSubtext">
                                        {gatewayName} - LDS BUS {bus}
                                    </p>
                                    <p className="selectionSubtext">
                                        LDSU UUID: {selectedSensorLdsuId}
                                    </p>
                                    <p className="selectionSubtext">
                                        {sensor_type}
                                    </p>
                                </div>
                            )}

                            <p className="mt-3">
                                Associate another sensor’s value from the same
                                Gateway
                            </p>
                            {selectedSensorValue ? (
                                <HoverAuthorizeTooltip permission="gateway:update">
                                    <Button
                                        variant="primary"
                                        className="mt-3"
                                        onClick={handleSelectSensor}
                                    >
                                        CHANGE SENSOR
                                    </Button>
                                </HoverAuthorizeTooltip>
                            ) : (
                                ""
                            )}
                        </Col>
                    </Form.Row>
                ) : (
                    // For radio selection - fixed
                    <Form.Row className="mt-3">
                        <Col xl="5" md="12" className="mt-2">
                            <h6>Fixed value</h6>
                        </Col>
                        <Col xl="7" md="12">
                            <InputGroup>
                                <FormDeviceValueControl
                                    {...{
                                        format: "float",
                                        accuracy: associated_accuracy,
                                        min: Number(associated_min),
                                        max: Number(associated_max),
                                    }}
                                    value={fixedValue}
                                    onChange={(e) => {
                                        setFixedValue(e.target.value);
                                    }}
                                    width="calc(100% - 41px)"
                                />
                                <InputGroup.Append className="p-2 unit-container">
                                    °C
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Form.Row>
                )}
            </div>
            <div className="form-box-footer d-flex justify-content-end align-items-center">
                <p
                    className={
                        postError
                            ? "mr-3 text-secondary-red-1 d-block"
                            : "mr-3 text-secondary-red-1 d-none"
                    }
                ></p>
                <HoverAuthorizeTooltip permission="gateway:update">
                    <Button
                        className="save-btn"
                        variant="primary"
                        onClick={handleUpdateAssoc}
                    >
                        SAVE
                    </Button>
                </HoverAuthorizeTooltip>
            </div>
            {/* Loader */}
            <Modal show={isLoading} animation={false} centered>
                <Spinner animation="border" variant="primary" />
            </Modal>
            {/* Select Sensor Modal */}
            <Modal
                show={selectSensorShow}
                onHide={hideSensorSelectionModal}
                animation={false}
                centered
            >
                <Modal.Header>
                    <Modal.Title>Select Sensor</Modal.Title>
                    <Button
                        variant=""
                        className="close-button"
                        onClick={hideSensorSelectionModal}
                    >
                        <span className="material-icons">close</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <FormControl
                            type="text"
                            placeholder="Search"
                            className="mb-4"
                            onChange={(e) => setQuery(e.target.value)}
                        ></FormControl>
                        <div className="scroll-list float-left">
                            {renderSensorList()}
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <p
                        className={
                            errorSensor
                                ? "select-sensor-error-msg d-block"
                                : "select-sensor-error-msg d-none"
                        }
                    >
                        Please select a sensor.
                    </p>
                    <Button variant="primary" onClick={handleSensorDone}>
                        DONE
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SensorAssociation;
