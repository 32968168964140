import axios from "axios";
import LocalStorageService from "./localStorageService";
import axiosResponseHook from "hooks/axiosResponse";

const tokens = LocalStorageService.getItem("tokens");
const axiosInstance = axios.create({
    headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "PUT, POST, PATCH, DELETE, GET"
    },
    baseURL: process.env.REACT_APP_DASHBOARD_API,
});

// Initial Authorization
if (tokens) {
    axiosInstance.defaults.headers.common[
        "Authorization"
    ] = `Bearer ${tokens.AccessToken}`;
}

let retryCounter = 0;

//add response interceptors
axiosInstance.interceptors.response.use(
    (response) => {
        return axiosResponseHook(response);
    },
    (error) => {
        const originalRequest = error.config;
        const { url } = originalRequest;
        const exceptPath =
            url.includes("/login") ||
            url.includes("account/terminate") ||
            url.includes("permission/");
        const err = { error };

        axiosResponseHook(error?.response);

        if (
            // NOTE: Commented out to prevent unnecessary token calls
            // !err.error.response ||
            error.response &&
            error.response.status === 401 &&
            // eslint-disable-next-line no-mixed-operators
            !originalRequest._retry &&
            !exceptPath
        ) {
            if (
                err.error.response?.data?.description ===
                "Refresh Token has been revoked"
            ) {
                LocalStorageService.setItem("sessionStatus", "expired");
                return;
            }

            const { RefreshToken } =
                LocalStorageService.getItem("tokens") || {};

            if (!RefreshToken || retryCounter >= 3) {
                return Promise.reject(error);
            }

            originalRequest._retry = true;
            retryCounter++;

            return axiosInstance
                .post(
                    `${process.env.REACT_APP_DASHBOARD_API}/account/token`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${RefreshToken}`,
                        },
                    }
                )
                .then((res: any) => {
                    LocalStorageService.setItem("tokens", {
                        ...LocalStorageService.getItem("tokens"),
                        AccessToken: res.data.data.AccessToken,
                    });
                    // Update the new token to axiosInstance
                    axiosInstance.defaults.headers.common[
                        "Authorization"
                    ] = `Bearer ${res.data.data.AccessToken}`;
                    // Update the new token to originalRequest
                    originalRequest.headers[
                        "Authorization"
                    ] = `Bearer ${res.data.data.AccessToken}`;

                    retryCounter = 0;

                    // reload page after update refresh token
                    window.location.reload();

                    return axiosInstance(originalRequest);
                })
                .catch((e) => {
                    LocalStorageService.clearAll();
                    window.location.replace("/callback?session=expired");
                    return Promise.reject(e.response || e);
                });
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
