import AxiosInstance from "./axiosInstance";
import { ResponseDto } from "types";
import {
    createResponseStandard,
    createErrorResponseStandard,
} from "../utils/functions";
import { apiFactory } from "generated";
import {
    ChangePassword,
    ConfirmForgot,
    ConfirmSignUp,
    Login,
    LoginData,
    RespondAuth,
    SignUp,
} from "generated/models";
import { AxiosError } from "axios";

const accountClient = apiFactory.getAccountInstance(AxiosInstance);

export const login = async (body: Login): Promise<ResponseDto<LoginData>> => {
    try {
        const response = await accountClient.login(body);
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error as AxiosError);
    }
};

export const sendForgotPasswordEmail = async (email: string) => {
    try {
        const response = await accountClient.forgotPassword({
            email,
        });
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error as AxiosError);
    }
};

export const changePassword = async (body: ConfirmForgot) => {
    try {
        const response = await accountClient.confirmForgotPasswordWithCode(
            body
        );
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error as AxiosError);
    }
};

export const signUp = async (body: SignUp) => {
    try {
        const response = await accountClient.signUp(body);
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error as AxiosError);
    }
};

export const submitSignUpVerificationCode = async (body: ConfirmSignUp) => {
    try {
        const response = await accountClient.confirmSighUpWithCode(body);
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error as AxiosError);
    }
};

export const submitMFACode = async (body: RespondAuth) => {
    try {
        const response = await accountClient.loginWithChallenge(body);
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error as AxiosError);
    }
};

export const changeProfilePassword = async (body: ChangePassword) => {
    try {
        const response = await accountClient.changePassword(body);
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error as AxiosError);
    }
};

export const resendConfirmationCode = async (email: string) => {
    try {
        const response = await accountClient.reSendConfirmationCode({
            email: email,
        });
        return createResponseStandard(response);
    } catch (error) {
        return createErrorResponseStandard(error as AxiosError);
    }
};
